import React, { useMemo, useState, useCallback, useEffect } from "react";
import SearchComponent from "../../components/Search";
import PaginationComponent from "../../components/Pagination/Pagination";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchLoanOfficers } from "../../redux/Loans/loanActions";
import { useDispatch } from "react-redux";

const AgentPortfolioTable = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const { loading, loanofficers,loanofficersCount } = useSelector((state) => state.loan);

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
  };

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        // dispatch(
        //     searchCallRequests({
        //       val: val,
        //       status: 'all',
        //     }),
        //   )
        console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    dispatch(fetchLoanOfficers());
  }, [dispatch]);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Agent Loan Portfolio
          </p>
          <h6 className="text-[#7C8D7E] font-medium text-sm mt-1">
            Monitoring and control centre for loan approval and tracking from
            Gather
          </h6>

          {/* search */}
          <div className="mt-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold">
                  First Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Last Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Loans
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  State
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Groups
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Overdue Loan
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {loanofficers.length > 0
                  ? loanofficers.map((item) => (
                      <tr
                        key={item.id}
                        onClick={() =>
                          navigate(
                            `/agrofastcash/loan-agent/${item.officer_details.id}`
                          )
                        }
                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      >
                        <th
                          scope="row"
                          className="py-4 px-4 text-[#5C715E] whitespace-nowrap"
                        >
                          {item.officer_details.first_name ? (item.officer_details.first_name === "" ? 'N/A': item.officer_details.first_name) : 'N/A'}
                        </th>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.officer_details.last_name ? (item.officer_details.last_name === "" ? 'N/A': item.officer_details.last_name) : 'N/A'}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.total_loans_disbursed}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                  
                          {item.state.name ? (item.state.name === "" ? 'N/A': item.state.name) : 'N/A'}

                        </td>
                        <td className="py-4 px-4">{item.total_groups}</td>
                        <td className="py-4 px-4">{item.overdue_loans}</td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            )}
          </table>

          {loading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={loanofficersCount}
        />
      </div>
    </>
  );
};

export default AgentPortfolioTable;
