import React from "react";

const EmptyState = ({ text }) => {
  return (
    <div className="w-full pb-[80px] mt-[80px] flex items-center justify-center">
      <p>{text}</p>
    </div>
  );
};

export default EmptyState;
