import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl, finagApiUrl } from "../config";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

/////////////////////////////////////////////// ALL COOPERATIVES SECTION
export const getCooperatives = createAsyncThunk(
  "getCooperatives",
  async ({ page, value, search }, { rejectWithValue, dispatch }) => {
    if (value === "all") {
      try {
        const res = await axios.get(
          `${apiUrl}cooperatives?page=${page}&search=${search}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}cooperatives?page=${page}&search=${search}&status=${value?.toUpperCase()}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

/////////////////////////////////////////////// ALL COOPERATIVES SECTION
export const getACooperative = createAsyncThunk(
  "getACooperative",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}/cooperatives/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////////// ALL COOPERATIVES SECTION
export const getCooperativesStats = createAsyncThunk(
  "getCooperativesStats",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}cooperatives/statistics`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const cooperativeApproval = createAsyncThunk(
  "cooperativeApproval",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(`${apiUrl}cooperatives/${id}`, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        cogoToast.success("The approval status was updated successfully! ");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const createCooperative = createAsyncThunk(
  "createCooperative",
  async ({ values }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}farmer-loan/create-loan-group/`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201 || res.status === 203) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
