import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { getCooperatives } from "../../redux/Cooperative/cooperativeAction";
import EmptyState from "../../components/EmptyState";
import { useNavigate } from "react-router-dom";

const CooperativeTable = () => {
  const [status, setStatus] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cooperativeData, cooperativeTotal, cooperativeLoading } = useSelector(
    (state) => state.cooperatives
  );

  // COMPLAINTS PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(
      getCooperatives({ page: page, value: status, search: searchValue })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        dispatch(getCooperatives({ page: 1, value: status, search: val }));
      }, 750),
    [dispatch, status]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const getStatusBadge = (val) => {
    let result
    switch (val) {
      case 'pending':
        result = 'pending-badge'
        break;
      case 'inactive':
        result = 'error-badge'
        break
      case 'active':
        result = 'success-badge'
        break;
        case "completed":
          result = "success-badge"
          break;
          case "approved":
            result = "success-badge"
            break;
            case "rejected":
              result = "error-badge"
              break;
      default:
        result = 'error-badge'
        break
    }
    return result
  }

  const handleStatusChange = (tabStatus) => {
    setStatus(tabStatus);
    dispatch(
      getCooperatives({ page: 1, value: tabStatus, search: searchValue })
    );
  };

  useEffect(() => {
    dispatch(getCooperatives({ page: 1, value: status, search: searchValue }));
  }, [dispatch, status, searchValue]);

  // console.log(cooperativeData, cooperativeTotal, cooperativeLoading);

  return (
    <div>
      <TableBackground>
        <p className="text-primary font-semibold text-base leading-5 pt-4 px-4">
          Cooperative Profile
        </p>

        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS SECTION */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 cursor-pointer px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "all" ? cooperativeTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "approved"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("approved")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Approved
                </p>
                <div
                  className={
                    status === "approved"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "approved" ? cooperativeTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "unapproved"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("unapproved")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Unapproved
                </p>
                <div
                  className={
                    status === "unapproved"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "unapproved" ? cooperativeTotal : "0"}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {cooperativeLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {cooperativeData.length === 0 ? (
                <EmptyState text="There's no data for this filter yet" />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Members
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Agent
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Phone Number
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        {" "}
                        State
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        LGA
                      </th>

                      <th scope="col" className="px-4 pb-2">
                        Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {cooperativeData.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                          onClick={() => navigate(`/cooperative/${data?.id}`)}
                        >
                          <td className="px-4">{data?.name || "N/A"}</td>
                          <td className="px-4">
                            {data?.member_count || "N/A"}
                          </td>
                          <td className="px-4">{data?.agent || "N/A"}</td>
                          <td className="px-4">
                            {data?.phone_number || "N/A"}
                          </td>

                          <td className="px-4">{data?.state || "N/A"}</td>
                          <td className="px-4">{data?.lga || "N/A"}</td>
                          {/* APPROVAL STATUS */}
                          <td className="">
                            <div
                              className={getStatusBadge(data.status)}
                            >
                              <p className="capitalize">
                                {data.status === "approved"
                                  ? data?.status
                                  : data?.status}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}

          {!cooperativeLoading && cooperativeData.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={cooperativeTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default CooperativeTable;
