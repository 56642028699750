import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import TableBackground from "../../../components/TableBackground";
import moment from "moment";
import PaginationComponent from "../../../components/Pagination/Pagination";
import SearchComponent from "../../../components/Search";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFlaggedFarmers,
  getAllFlaggedFarmersSearch,
  getRestrictedFarmers,
  getRestrictedFarmersSearch,
  getShaddowBannedFarmers,
  getShaddowBannedFarmersSearch,
} from "../../../redux/Farmers/farmersActions";
import { MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import UnsuspendFarmer from "../../../pages/Farmers/FarmersModals/UnsuspendFarmer";
import SuspendFarmer from "../../../pages/Farmers/FarmersModals/SuspendFarmer";
import UnblockFarmer from "../../../pages/Farmers/FarmersModals/UnblockFarmer";
import BlockFarmer from "../../../pages/Farmers/FarmersModals/BlockFarmer";
import { useOutsideAlerter } from "../../../helpers/OutsideClick";

const FlaggedFarmersTable = () => {
  const [status, setStatus] = useState("all");

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [shaddowBannedCurrentPage, setShaddowBannedCurrentPage] = useState(1);
  const [restrictedCurrentPage, setRestrictedCurrentPage] = useState(1);
  const [isClickMore, setIsClickMore] = useState(false);
  const [clickIndex, setIsClickIndex] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsClickMore);

  const {
    allFlaggedAccountsData,
    allFlaggedAcountsTotal,
    allFlaggedAcountsLoading,
    allShaddowBannedAccountsData,
    allShaddowBannedAcountsTotal,
    allShaddowBannedAcountsLoading,
    allRestrictedAccountsData,
    allRestrictedAcountsTotal,
    allRestrictedsAcountsLoading,
  } = useSelector((state) => state.farmer);

  // TO CALL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getAllFlaggedFarmers({
        page: page,
      })
    );
  };

  // CALLED PAGE CHANGE
  const onShaddowBannedPageChange = (val) => {
    const { page } = val;
    setShaddowBannedCurrentPage(page);

    dispatch(
      getShaddowBannedFarmers({
        page: page,
      })
    );
  };

  //MISSED PAGE CHANGE
  const onRestrictedPageChange = (val) => {
    const { page } = val;
    setRestrictedCurrentPage(page);

    dispatch(
      getRestrictedFarmers({
        page: page,
      })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        dispatch(
          getAllFlaggedFarmersSearch({
            value: val,
            page: currentPage,
          })
        );

        dispatch(
          getShaddowBannedFarmersSearch({
            value: val,
            page: shaddowBannedCurrentPage,
          })
        );

        dispatch(
          getRestrictedFarmersSearch({
            value: val,
            page: restrictedCurrentPage,
          })
        );
      }, 750),
    [dispatch, currentPage, shaddowBannedCurrentPage, restrictedCurrentPage]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleMoreVertClick = (index) => {
    setIsClickMore(!isClickMore);
    setIsClickIndex(index);
  };

  useEffect(() => {
    if (status === "all") {
      dispatch(getAllFlaggedFarmers({ page: 1 }));
    } else if (status === "shaddow_ban") {
      dispatch(getShaddowBannedFarmers({ page: 1 }));
    } else if (status === "restricted_accounts") {
      dispatch(getRestrictedFarmers({ page: 1 }));
    }
  }, [dispatch, status]);

  return (
    <div>
      <TableBackground>
        {/*  */}
        <p className="text-primary font-semibold text-base leading-5 px-4 pt-4">
          Flagged Farmers Account ({allFlaggedAcountsTotal})
        </p>

        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4 mt-4 px-4">
          <div className="w-full relative">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS SECTION */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 cursor-pointer px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{allFlaggedAcountsTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "shaddow_ban"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("shaddow_ban")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Shaddow Banned
                </p>
                <div
                  className={
                    status === "shaddow_ban"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{allShaddowBannedAcountsTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "restricted_accounts"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("restricted_accounts")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Restricted Accounts
                </p>
                <div
                  className={
                    status === "restricted_accounts"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{allRestrictedAcountsTotal}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* TABLE SECTION */}

        {status === "all" ? (
          <section className="mt-[30px] mb-[24px]">
            {allFlaggedAcountsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="px-4 pb-2">
                      First Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Banned Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Restriction Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Balance
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allFlaggedAccountsData.map((data, index) => {
                    return (
                      <tr className="relative hover:bg-[#edf7ee]" key={index}>
                        <td className="px-4">{data.first_name}</td>
                        <td className="px-4">{data.last_name}</td>
                        <td className="px-4">{data.phone_number}</td>
                        <td className="px-4">
                          {data.is_shadow_banned
                            ? moment(data.created).format("lll")
                            : "N/A"}
                        </td>

                        <td className="px-4">
                          {" "}
                          {data.is_restricted
                            ? moment(data.created).format("lll")
                            : "N/A"}
                        </td>

                        <td className="px-4">N{data.balance}</td>
                        <td>
                          {" "}
                          <div className="relative">
                            <MoreVert
                              style={{ fontSize: "18px", cursor: "pointer" }}
                              onClick={() => handleMoreVertClick(index)}
                            />

                            <div className="">
                              {isClickMore && clickIndex === index && (
                                <div
                                  className="absolute right-0 w-[187px] shadow-sm bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20"
                                  ref={wrapperRef}
                                >
                                  <p
                                    className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                    onClick={() =>
                                      navigate(`/farmers-profile/${data.id}`)
                                    }
                                  >
                                    View Profile
                                  </p>
                                  <div>
                                    {data.active_status === "Blocked" ? (
                                      <p className="text-[#96A397] cursor-default  py-[12px] px-[16px] text-sm leading-5 font-medium">
                                        Suspend Farmer
                                      </p>
                                    ) : (
                                      <>
                                        {data.active_status === "Suspended" ? (
                                          <UnsuspendFarmer id={data.id} />
                                        ) : (
                                          <SuspendFarmer id={data.id} />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    {data.active_status === "Suspended" ? (
                                      <p className="text-[#96A397] cursor-default py-[12px] px-[16px] text-sm leading-5 font-medium">
                                        Block Farmer
                                      </p>
                                    ) : (
                                      <>
                                        {data.active_status === "Blocked" ? (
                                          <UnblockFarmer id={data.id} />
                                        ) : (
                                          <BlockFarmer id={data.id} />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {!allFlaggedAcountsLoading && allFlaggedAccountsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={allFlaggedAcountsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "shaddow_ban" ? (
          <section className="mt-[30px] mb-[24px]">
            {allShaddowBannedAcountsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="px-4 pb-2">
                      First Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Banned Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Banned Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Restriction Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Restriction Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Balance
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allShaddowBannedAccountsData.map((data, index) => {
                    return (
                      <tr className="relative hover:bg-[#edf7ee]" key={index}>
                        <td className="px-4">{data.first_name}</td>
                        <td className="px-4">{data.last_name}</td>
                        <td className="px-4">{data.phone_number}</td>
                        <td className="px-4">
                          {data.is_shadow_banned
                            ? moment(data.created).format("ll")
                            : "N/A"}
                        </td>
                        <td className="px-4">
                          {" "}
                          {data.is_shadow_banned
                            ? moment(data.created).format("LT")
                            : "N/A"}
                        </td>

                        <td className="px-4">
                          {" "}
                          {data.is_restricted
                            ? moment(data.created).format("ll")
                            : "N/A"}
                        </td>
                        <td className="px-4">
                          {" "}
                          {data.is_restricted
                            ? moment(data.created).format("LT")
                            : "N/A"}
                        </td>
                        <td className="px-4">N{data.balance}</td>
                        <td>
                          {" "}
                          <div className="relative">
                            <MoreVert
                              style={{ fontSize: "18px", cursor: "pointer" }}
                              onClick={() => handleMoreVertClick(index)}
                            />

                            <div className="">
                              {isClickMore && clickIndex === index && (
                                <div
                                  className="absolute right-0 w-[187px] shadow-sm bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20"
                                  ref={wrapperRef}
                                >
                                  <p
                                    className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                    onClick={() =>
                                      navigate(`/farmers-profile/${data.id}`)
                                    }
                                  >
                                    View Profile
                                  </p>
                                  <div>
                                    {data.active_status === "Blocked" ? (
                                      <p className="text-[#96A397] cursor-default  py-[12px] px-[16px] text-sm leading-5 font-medium">
                                        Suspend Farmer
                                      </p>
                                    ) : (
                                      <>
                                        {data.active_status === "Suspended" ? (
                                          <UnsuspendFarmer id={data.id} />
                                        ) : (
                                          <SuspendFarmer id={data.id} />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    {data.active_status === "Suspended" ? (
                                      <p className="text-[#96A397] cursor-default py-[12px] px-[16px] text-sm leading-5 font-medium">
                                        Block Farmer
                                      </p>
                                    ) : (
                                      <>
                                        {data.active_status === "Blocked" ? (
                                          <UnblockFarmer id={data.id} />
                                        ) : (
                                          <BlockFarmer id={data.id} />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {!allShaddowBannedAcountsLoading &&
            allShaddowBannedAccountsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={shaddowBannedCurrentPage}
                  onPageChange={onShaddowBannedPageChange}
                  pageSize={10}
                  totalItems={allShaddowBannedAcountsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "restricted_accounts" ? (
          <section className="mt-[30px] mb-[24px]">
            {allRestrictedsAcountsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="px-4 pb-2">
                      First Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Banned Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Banned Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Restriction Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Restriction Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Balance
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allRestrictedAccountsData.map((data, index) => {
                    return (
                      <tr className="relative hover:bg-[#edf7ee]" key={index}>
                        <td className="px-4">{data.first_name}</td>
                        <td className="px-4">{data.last_name}</td>
                        <td className="px-4">{data.phone_number}</td>
                        <td className="px-4">
                          {data.is_shadow_banned
                            ? moment(data.created).format("ll")
                            : "N/A"}
                        </td>
                        <td className="px-4">
                          {" "}
                          {data.is_shadow_banned
                            ? moment(data.created).format("LT")
                            : "N/A"}
                        </td>

                        <td className="px-4">
                          {" "}
                          {data.is_restricted
                            ? moment(data.created).format("ll")
                            : "N/A"}
                        </td>
                        <td className="px-4">
                          {" "}
                          {data.is_restricted
                            ? moment(data.created).format("LT")
                            : "N/A"}
                        </td>
                        <td className="px-4">N{data.balance}</td>
                        <td>
                          {" "}
                          <div className="relative">
                            <MoreVert
                              style={{ fontSize: "18px", cursor: "pointer" }}
                              onClick={() => handleMoreVertClick(index)}
                            />

                            <div className="">
                              {isClickMore && clickIndex === index && (
                                <div
                                  className="absolute right-0 w-[187px] shadow-sm bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20"
                                  ref={wrapperRef}
                                >
                                  <p
                                    className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                    onClick={() =>
                                      navigate(`/farmers-profile/${data.id}`)
                                    }
                                  >
                                    View Profile
                                  </p>
                                  <div>
                                    {data.active_status === "Blocked" ? (
                                      <p className="text-[#96A397] cursor-default  py-[12px] px-[16px] text-sm leading-5 font-medium">
                                        Suspend Farmer
                                      </p>
                                    ) : (
                                      <>
                                        {data.active_status === "Suspended" ? (
                                          <UnsuspendFarmer id={data.id} />
                                        ) : (
                                          <SuspendFarmer id={data.id} />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    {data.active_status === "Suspended" ? (
                                      <p className="text-[#96A397] cursor-default py-[12px] px-[16px] text-sm leading-5 font-medium">
                                        Block Farmer
                                      </p>
                                    ) : (
                                      <>
                                        {data.active_status === "Blocked" ? (
                                          <UnblockFarmer id={data.id} />
                                        ) : (
                                          <BlockFarmer id={data.id} />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {!allRestrictedsAcountsLoading &&
            allRestrictedAccountsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={restrictedCurrentPage}
                  onPageChange={onRestrictedPageChange}
                  pageSize={10}
                  totalItems={allRestrictedAcountsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : null}
      </TableBackground>
    </div>
  );
};

export default FlaggedFarmersTable;
