import { Block, ErrorOutline } from "@mui/icons-material";
import React, { useState, useRef } from "react";
import ModalComponent from "../../../../components/Modals/modal";
import {
  blockFarmer,
  getAllFarmers,
  getAllFlaggedFarmers,
} from "../../../../redux/Farmers/farmersActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const SuspendFarmer = ({ id }) => {
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const location = useLocation();
  const [isClickSuspendFarmer, setIsClickSuspendFarmer] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);

  const handleClickSuspendFarmer = (e) => {
    e.stopPropagation();
    setIsClickSuspendFarmer(!isClickSuspendFarmer);
  };

  const handleSuspend = () => {
    dispatch(
      blockFarmer({
        inputValue: inputRef.current.value,
        id: id,
        status: "Suspended",
      })
    );

    setTimeout(() => {
      setIsClickSuspendFarmer(false);
      if (location.pathname === "/farmers") {
        dispatch(
          getAllFarmers({
            id: id.id,
            page: 1,
            value: sessionStorage.getItem("farmer_search_value"),
            states: "",
            lgas: "",
            rank: "",
          })
        );
      } else if (location.pathname === "/flagged-farmers") {
        dispatch(getAllFlaggedFarmers({ page: 1 }));
      }
    }, 800);
  };

  return (
    <div>
      <div>
        {" "}
        <p
          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
          onClick={(event) => handleClickSuspendFarmer(event)}
        >
          Suspend Farmer
        </p>
      </div>
      {/* SUSPEND FARMER MODAL */}
      <ModalComponent
        title="Suspend Farmer"
        show={isClickSuspendFarmer}
        showModal={() => setIsClickSuspendFarmer(false)}
        subtitle="When a farmer is suspended he/she wont be able to make bank transactions, make call requests and make purchases."
      >
        <div className="pt-[16px]">
          <div className="mb-[28px]">
            <p className="text-sm font-[400] leading-[18px] text-[#344335] ">
              Suspend Duration (Days)
            </p>
            <input
              type="number"
              min="1"
              placeholder="0"
              className="outline-0 border border-[#96A397] rounded-[8px] w-[30%] py-[12px] px-[16px] mt-[4px]"
            />
          </div>

          <div className="mb-[32px]">
            <p className="text-sm font-[400] leading-[18px] text-[#344335] ">
              Reason for suspension
            </p>
            <textarea
              placeholder="Reason for suspension"
              className="outline-0 border border-[#96A397] h-[134px] rounded-[8px] w-full py-[12px] px-[16px] mt-[4px] resize-none"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={false}
              onClick={handleSuspend}
              className="bg-[#B92043] 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-[102px] h-[44px] text-sm text-white font-medium hover:bg-[#B92043] transition-all mb-[16px]"
            >
              Suspend
            </button>
          </div>
        </div>
      </ModalComponent>

      {/* SUSPEND FARMER ALERT MODAL */}
      <ModalComponent
        title=""
        show={isSuspended}
        showModal={() => setIsSuspended(false)}
      >
        <div className="flex flex-col items-center">
          <Block
            className="text-[#B92043] mb-[10px]"
            style={{ fontSize: "20px" }}
          />
          <p className="mb-[4px] font-semibold text-base leading-5 text-[#344335]">
            Farmer has been suspended
          </p>
          <p className="text-center font-medium text-xs leading-4 text-[#344335] mb-[8px]">
            Jesam Abubakar won’t be able to make bank transactions, make call
            request and make purchase for the period of:
          </p>
          <p className="font-semibold leading-[32px] text-xl text-[#B92043] mb-[12px]">
            24 Days
          </p>

          <div className="flex bg-[#E7FBFE] rounded-[8px] font-medium text-xs p-2 text-[#0066AF] gap-[10px] items-center mb-[44px] h-[32px]">
            <ErrorOutline style={{ fontSize: "16px" }} />
            <p>The suspension would be lifted on 12/12/22</p>
          </div>

          <p
            className="font-medium text-sm  leading-5 text-[#5C715E] mb-[22px]"
            onClick={() => setIsSuspended(false)}
          >
            Close
          </p>
        </div>
      </ModalComponent>
    </div>
  );
};

export default SuspendFarmer;
