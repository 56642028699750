import React, { useState } from "react";
import PaginationComponent from "../../../components/Pagination/Pagination";

const CallLogsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
  };

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Call Category
                </th>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Number of Farmers in Log
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Date of Creation
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Time of Creation
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Log Status
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Call Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Temporary Farmers
                </th>
                <td className="py-4 px-4 text-[#5C715E]">1500</td>
                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>Active</p>
                    </div>
                  </div>
                </td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>250/500</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Temporary Farmers
                </th>
                <td className="py-4 px-4 text-[#5C715E]">1500</td>
                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>Active</p>
                    </div>
                  </div>
                </td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>250/500</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Temporary Farmers
                </th>
                <td className="py-4 px-4 text-[#5C715E]">1500</td>
                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="error-badge">
                      <p>Inactive</p>
                    </div>
                  </div>
                </td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>250/500</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Temporary Farmers
                </th>
                <td className="py-4 px-4 text-[#5C715E]">1500</td>
                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="pending-badge">
                      <p>Paused</p>
                    </div>
                  </div>
                </td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>250/500</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={200}
        />
      </div>
    </>
  );
};

export default CallLogsTable;
