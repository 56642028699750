import { ContentCopy } from "@mui/icons-material";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../../components/TableBackground";
import { useParams } from "react-router-dom";
import DrawerComponent from "../../../components/Drawers/drawers";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  getAllBankTransactions,
  getAllBankTransactionsSearch,
  getCreditTransactions,
  getCreditTransactionsSearch,
  getDebitTransactions,
  getDebitTransactionsSearch,
} from "../../../redux/Farmers/farmersActions";
import SearchComponent from "../../../components/Search";
import moment from "moment";
import PaginationComponent from "../../../components/Pagination/Pagination";
import EmptyState from "../../../components/EmptyState";

const BankTransactionTable = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState("all");
  const [isClickTable, setIsClickTable] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debitCurrentPage, setDebitCurrentPage] = useState(1);
  const [creditCurrentPage, setCreditCurrentPage] = useState(1);
  const {
    allTransactionsData,
    allTransactionsTotal,
    allTransactionsLoading,
    debitTransactionsData,
    debitTransactionsTotal,
    debitTransactionsLoading,
    creditTransactionsData,
    creditTransactionsTotal,
    creditTransactionsLoading,
  } = useSelector((state) => state.farmer);

  // ALL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getAllBankTransactions({
        page: page,
        id: id,
      })
    );
  };

  // DEBIT PAGE CHANGE
  const onDebitPageChange = (val) => {
    const { page } = val;
    setDebitCurrentPage(page);

    dispatch(
      getDebitTransactions({
        page: page,
        id: id,
      })
    );
  };

  //CREDIT PAGE CHANGE
  const onCreditPageChange = (val) => {
    const { page } = val;
    setCreditCurrentPage(page);

    dispatch(
      getCreditTransactions({
        page: page,
        id: id,
      })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        dispatch(
          getAllBankTransactionsSearch({
            id: id,
            value: val,
            page: currentPage,
          })
        );

        dispatch(
          getDebitTransactionsSearch({
            id: id,
            value: val,
            page: debitCurrentPage,
          })
        );

        dispatch(
          getCreditTransactionsSearch({
            id: id,
            value: val,
            page: creditCurrentPage,
          })
        );
      }, 750),
    [dispatch, currentPage, debitCurrentPage, creditCurrentPage, id]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    if (status === "all") {
      dispatch(getAllBankTransactions({ page: 1, id: id }));
    } else if (status === "debit") {
      dispatch(getDebitTransactions({ page: 1, id: id }));
    } else if (status === "credit") {
      dispatch(getCreditTransactions({ page: 1, id: id }));
    }
  }, [dispatch, id, status]);

  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4 mt-4 pt-4 px-4">
          <div className="w-full relative">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS SCETIONS */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 px-4 cursor-pointer">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{allTransactionsTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "debit"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("debit")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Debit
                </p>
                <div
                  className={
                    status === "debit"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{debitTransactionsTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "credit"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("credit")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Credit
                </p>
                <div
                  className={
                    status === "credit"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{creditTransactionsTotal}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {status === "all" ? (
          <section className="mt-[30px] mb-[24px]">
            {allTransactionsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {allTransactionsData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Transaction ID
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Activity
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Previous Balance
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          New Balance
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Transaction Type
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          {" "}
                          Transaction Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allTransactionsData?.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">{data.id}</td>
                            <td className="capitalize px-4">
                              {data.action
                                ?.replace("_", " ")
                                ?.replace("_", " ")}
                            </td>
                            <td className="px-4">N{data.prev_balance}</td>
                            <td className="px-4">N{data.amount}</td>
                            <td className="px-4">N{data.new_balance}</td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                            <td className="px-4">
                              <div
                                className={
                                  data.transaction_type === "debit"
                                    ? "error-badge"
                                    : data.transaction_type === "credit"
                                    ? "success-badge"
                                    : null
                                }
                              >
                                <p>
                                  {data.transaction_type === "debit"
                                    ? "Debit"
                                    : data.transaction_type === "credit"
                                    ? "Credit"
                                    : null}
                                </p>
                              </div>
                            </td>
                            <td className="px-4">
                              <div
                                className={
                                  data.status ? "success-badge" : "error-badge"
                                }
                              >
                                <p>{data.status ? "Successful" : "Failed"}</p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!allTransactionsLoading && allTransactionsData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={allTransactionsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "debit" ? (
          <section className="mt-[30px] mb-[24px]">
            {debitTransactionsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {debitTransactionsData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Transaction ID
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Activity
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Previous Balance
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          New Balance
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Transaction Type
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          {" "}
                          Transaction Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {debitTransactionsData?.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">{data.id}</td>
                            <td className="capitalize px-4">
                              {data.action
                                ?.replace("_", " ")
                                ?.replace("_", " ")}
                            </td>
                            <td className="px-4">N{data.prev_balance}</td>
                            <td className="px-4">N{data.amount}</td>
                            <td className="px-4">N{data.new_balance}</td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                            <td className="px-4">
                              <div
                                className={
                                  data.transaction_type === "debit"
                                    ? "error-badge"
                                    : data.transaction_type === "credit"
                                    ? "success-badge"
                                    : null
                                }
                              >
                                <p>
                                  {data.transaction_type === "debit"
                                    ? "Debit"
                                    : data.transaction_type === "credit"
                                    ? "Credit"
                                    : null}
                                </p>
                              </div>
                            </td>
                            <td className="px-4">
                              <div
                                className={
                                  data.status ? "success-badge" : "error-badge"
                                }
                              >
                                <p>{data.status ? "Successful" : "Failed"}</p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!debitTransactionsLoading && debitTransactionsData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={debitCurrentPage}
                  onPageChange={onDebitPageChange}
                  pageSize={10}
                  totalItems={debitTransactionsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "credit" ? (
          <section className="mt-[30px] mb-[24px]">
            {creditTransactionsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {creditTransactionsData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Transaction ID
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Activity
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Previous Balance
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          New Balance
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Transaction Type
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          {" "}
                          Transaction Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {creditTransactionsData?.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">{data.id}</td>
                            <td className="capitalize px-4">
                              {data.action
                                ?.replace("_", " ")
                                ?.replace("_", " ")}
                            </td>
                            <td className="px-4">N{data.prev_balance}</td>
                            <td className="px-4">N{data.amount}</td>
                            <td className="px-4">N{data.new_balance}</td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                            <td className="px-4">
                              <div
                                className={
                                  data.transaction_type === "debit"
                                    ? "error-badge"
                                    : data.transaction_type === "credit"
                                    ? "success-badge"
                                    : null
                                }
                              >
                                <p>
                                  {data.transaction_type === "debit"
                                    ? "Debit"
                                    : data.transaction_type === "credit"
                                    ? "Credit"
                                    : null}
                                </p>
                              </div>
                            </td>
                            <td className="px-4">
                              <div
                                className={
                                  data.status ? "success-badge" : "error-badge"
                                }
                              >
                                <p>{data.status ? "Successful" : "Failed"}</p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!creditTransactionsLoading &&
            creditTransactionsData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={creditCurrentPage}
                  onPageChange={onCreditPageChange}
                  pageSize={10}
                  totalItems={creditTransactionsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : null}
      </TableBackground>

      {/* BANK TRANSACTION INFORMATION SECTION     */}
      <section>
        <DrawerComponent
          show={isClickTable}
          showDrawer={() => setIsClickTable(false)}
          title="Transaction Info"
        >
          <div className="mt-[40px]">
            {/* TRANSACTION ID */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Transaction ID
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  #123454HG
                </p>
                <ContentCopy
                  className="text-[#5C715E] cursor-pointer"
                  style={{ fontSize: "14px" }}
                />
              </div>
            </div>

            {/* TRANSACTION TYPE */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Transaction Type
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] error-badge">
                  Debit
                </p>
              </div>
            </div>

            {/* TRANSACTION DATE */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Date
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  10 Aug 22
                </p>
              </div>
            </div>

            {/* TRANSACTION TIME */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Time
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  2:20 PM
                </p>
              </div>
            </div>

            {/* TRANSACTION ACTIVITY */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Activity
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  Transfer
                </p>
              </div>
            </div>

            {/* TRANSACTION AMOUNT */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Amount
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] error-badge">
                  ₦4,700
                </p>
              </div>
            </div>

            {/* TRANSACTION  Amount Charged */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Amount Charged
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  ₦9,400
                </p>
              </div>
            </div>

            {/* TRANSACTION Previous Balance */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Previous Balance
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  ₦9,400
                </p>
              </div>
            </div>

            {/* TRANSACTION Available Balance */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Available Balance
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  ₦4,700
                </p>
              </div>
            </div>

            {/* TRANSACTION Transaction Status */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Transaction Status
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] success-badge">
                  Successful
                </p>
              </div>
            </div>

            {/* TRANSACTION Recipient*/}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Recipient
              </p>
              <div className="flex flex-col gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  Adewale Tomiwa
                </p>
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  First Bank | 3112092538
                </p>
              </div>
            </div>

            {/* TRANSACTION Narration */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Narration
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px]">
                  OPAY DIGITAL SERVICES LLA LANG
                </p>
              </div>
            </div>
          </div>
        </DrawerComponent>
      </section>
    </div>
  );
};

export default BankTransactionTable;
