import React from 'react'
import Header from '../../components/Header'
import IVRTable from '../../tables/IVR'

const IVR = () => {
  return (
    <div>
      <Header text="IVR" />

      {/* TABLE SECTION */}
      <section className="mt-[24px]">
        <IVRTable />
      </section>
    </div>
  )
}

export default IVR
