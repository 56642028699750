import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { resetPasswordValidator } from '../../validationSchema/validator'
import warning from '../../assets/icons/warning.svg'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import OnboardingSidebar from '../../components/OnboardingSidebar/sidebar'
import eye_off from '../../assets/icons/eye-off.svg'
import eye from '../../assets/icons/eye.svg'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../../redux/Auth/authActions'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const search = useLocation().search

  const { isReset, loading } = useSelector((state) => state.auth)

  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false)

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const toggleConfirmPasswordVisiblity = () => {
    setPasswordConfirmShown(passwordConfirmShown ? false : true)
  }

  const handleSubmit = (values) => {
    const otp = new URLSearchParams(search).get('otp')
    const userId = new URLSearchParams(search).get('id')
    var creds = {
      otp,
      userId: userId,
      password: values.password,
    }
    dispatch(resetPassword(creds))
  }

  useEffect(() => {
    if (isReset) {
      navigate('/reset-success')
    }
  }, [isReset, navigate])

  return (
    <>
      <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[400px] h-[400px] rounded-full blur-[150px]"></div>
        <OnboardingSidebar />
        <div className="w-full md:w-[60%] px-4 md:px-4 flex justify-center flex-col">
          <div className="min-w-full md:min-w-[32rem] mx-auto">
            <h6 className="text-primary font-semibold text-lg">
              Set New Password
            </h6>
            <p className="text-[#5C715E] text-sm font-medium max-w-lg">
              Looks like second chances do exist. Type in your new password
            </p>

            <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={resetPasswordValidator}
              initialValues={{
                password: '',
                confirm_password: '',
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="mt-8" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordShown ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        className={
                          touched.password && errors.password
                            ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                            : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                        }
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={togglePasswordVisiblity}
                        src={passwordShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.password && errors.password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.password && errors.password}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-5">
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordConfirmShown ? 'text' : 'password'}
                        name="confirm_password"
                        placeholder="Password"
                        className={
                          touched.confirm_password && errors.confirm_password
                            ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                            : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                        }
                        value={values.confirm_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={toggleConfirmPasswordVisiblity}
                        src={passwordConfirmShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.confirm_password && errors.confirm_password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.confirm_password && errors.confirm_password}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-8">
                    <button
                      type="submit"
                      disabled={loading}
                      className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Continue
                    </button>
                  </div>

                  <div className="flex items-center justify-center mt-[12px] font-[600] text-sm leading-5 text-secondary">
                    <p>
                      Have an account?{' '}
                      <Link
                        to="/"
                        className="text-secondary font-medium text-sm"
                      >
                        <span className="text-primary">Log In</span>
                      </Link>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
