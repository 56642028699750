import { createSlice } from "@reduxjs/toolkit";
import {
  editComplaints,
  getComplaints,
  getRetailComplaints,
  getSearchComplaints,
  getSearchRetailComplaints,
} from "./complaintsAction";
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  editComplaintsLoading: false,
  complaintsData: [],
  complaintsTotal: 0,
  complaintsLoading: false,

  totalCSTData: 0,
  totalRetailData: 0,
  allComplaintTotal: 0,

  retailComplaintsData: [],
  retailComplaintsTotal: 0,
  retailComplaintsLoading: false,
};

const complaintsPersistConfig = {
  key: 'complaints',
  storage,
}

export const complaintSlice = createSlice({
  name: "complaints",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
  extraReducers: {
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////// GET COMPLAINTS  ----------
    [getComplaints.pending]: (state) => {
      state.complaintsLoading = true;
    },
    [getComplaints.fulfilled]: (state, { payload }) => {
      state.complaintsLoading = false;
      state.complaintsData = payload.data;
      state.complaintsTotal = payload.total;
      state.totalCSTData = payload.total_cst;
      state.totalRetailData = payload.total_retail;
    },
    [getSearchComplaints.rejected]: (state) => {
      state.complaintsLoading = false;
    },

    //////////////////////////////////COMPLAINT SEARCH
    [getSearchComplaints.pending]: (state) => {
      state.complaintsLoading = true;
    },
    [getSearchComplaints.fulfilled]: (state, { payload }) => {
      state.complaintsLoading = false;
      state.complaintsData = payload.data;
      state.complaintsTotal = payload.total;
    },
    [getSearchComplaints.rejected]: (state) => {
      state.complaintsLoading = false;
    },

    /////////////////////////////////////////////// EDIT COMPLAINTS
    [editComplaints.pending]: (state) => {
      state.editComplaintsLoading = true;
    },
    [editComplaints.fulfilled]: (state, { payload }) => {
      state.editComplaintsLoading = false;
    },
    [editComplaints.rejected]: (state) => {
      state.editComplaintsLoading = false;
    },

    ////////////////////////////////////// GET RETAIL COMPLAINTS  ----------
    [getRetailComplaints.pending]: (state) => {
      state.retailComplaintsLoading = true;
    },
    [getRetailComplaints.fulfilled]: (state, { payload }) => {
      state.retailComplaintsLoading = false;
      state.retailComplaintsData = payload.data;
      state.retailComplaintsTotal = payload.total;
    },
    [getRetailComplaints.rejected]: (state) => {
      state.retailComplaintsLoading = false;
    },

    //////////////////////////////////RETAIL COMPLAINT SEARCH
    [getSearchRetailComplaints.pending]: (state) => {
      state.retailComplaintsLoading = true;
    },
    [getSearchRetailComplaints.fulfilled]: (state, { payload }) => {
      state.retailComplaintsLoading = false;
      state.retailComplaintsData = payload.data;
      state.retailComplaintsTotal = payload.total;
    },
    [getSearchRetailComplaints.rejected]: (state) => {
      state.retailComplaintssLoading = false;
    },
  },
});

const complaintsReducer = persistReducer(complaintsPersistConfig, complaintSlice.reducer);


// Action creators are generated for each case reducer function
export const { increment, decrement } = complaintSlice.actions;

export default complaintsReducer;
