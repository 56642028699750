import React from "react";
import Header from "../../components/Header";
import { CardWithoutSeeMore } from "../../components/Cards";
import TableBackground from "../../components/TableBackground";
import PointsTable from "../../tables/Points";
import { Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Points = () => {
  const navigate = useNavigate();
  const { leaderboardData } = useSelector((state) => state.points);

  console.log(leaderboardData);

  return (
    <div>
      <Header text="Points">
        <div
          className="flex gap-[6px] cursor-pointer items-center"
          onClick={() => navigate("/point-settings")}
        >
          <Settings style={{ fontSize: "19px", color: "#5C715E" }} />
          <p className="font-medium text-sm text-[#5C715E]">Settings</p>
        </div>
      </Header>

      {/* CARDS SECTION */}
      <section>
        <div className="grid grid-cols-4 gap-6 mt-7">
          <>
            <CardWithoutSeeMore
              title="Total Farmers Rewarded"
              subtitle={
                leaderboardData?.totalFarmersRewarded
                  ? leaderboardData?.totalFarmersRewarded
                  : 0
              }
            />

            <CardWithoutSeeMore
              title="Points Allocated"
              subtitle={
                leaderboardData?.totalAllocatedPoints
                  ? leaderboardData?.totalAllocatedPoints
                  : 0
              }
            />
          </>
        </div>
      </section>

      {/* GRAPH/PROGRESS BAR */}
      <section className="mt-7">
        <TableBackground classname={"px-4 pt-4 pb-6 rounded-lg"}>
          <p className="font-semibold text-sm leading-[18px] text-[#5C715E] mb-3">
            Total Awarded Points
          </p>
          <p className="font-semibold text-[30px] leading-[36px] text-[#344335]  mb-7">
            {leaderboardData?.totalAwardedPoints
              ? leaderboardData?.totalAwardedPoints
              : 0}
          </p>

          <div className="flex flex-col gap-6">
            {/* Credit Transaction */}
            <div className="flex w-full">
              <div className="w-[20%]">
                <p className="font-semibold text-sm leading-[18px] text-[#5C715E]">
                  Credit Transaction
                </p>
              </div>
              <div className="w-[80%]">
                <div
                  style={{
                    width: `${
                      leaderboardData?.pointActions
                        ? (Number(
                            leaderboardData?.pointActions[0]?.total_points
                          ) /
                            Number(
                              leaderboardData?.pointActions[0]?.max_attainable
                            )) *
                          100
                        : "0"
                    }%`,
                  }}
                  className={`bg-[#FFF3CE] border-r-[4px] border-r-[#D4A407] h-6 flex items-center`}
                >
                  <p className="font-semibold text-sm leading-[18px] text-[#D4A407] pl-[9px]">
                    {leaderboardData?.pointActions
                      ? Number(leaderboardData?.pointActions[0]?.total_points)
                      : "0"}
                  </p>
                </div>
              </div>
            </div>

            {/* Airtime Purchase*/}
            <div className="flex w-full">
              <div className="w-[20%]">
                <p className="font-semibold text-sm leading-[18px] text-[#5C715E]">
                  Airtime Purchase
                </p>
              </div>
              <div className="w-[80%]">
                <div
                  style={{
                    width: `${
                      leaderboardData?.pointActions
                        ? (Number(
                            leaderboardData?.pointActions[1]?.total_points
                          ) /
                            Number(
                              leaderboardData?.pointActions[1]?.max_attainable
                            )) *
                          100
                        : "0"
                    }%`,
                  }}
                  className={`  bg-[#DBFFDF] border-r-[4px] border-r-[#2B8C34] h-6 flex items-center`}
                >
                  <p className="font-semibold text-sm leading-[18px] text-[#2B8C34] pl-[9px]">
                    {leaderboardData?.pointActions
                      ? Number(leaderboardData?.pointActions[1]?.total_points)
                      : "0"}
                  </p>
                </div>
              </div>
            </div>

            {/* Input Purchase Referee */}
            <div className="flex w-full">
              <div className="w-[20%]">
                <p className="font-semibold text-sm leading-[18px] text-[#5C715E]">
                  Input Purchase Referee
                </p>
              </div>
              <div className="w-[80%]">
                <div
                  style={{
                    width: `${
                      leaderboardData?.pointActions
                        ? (Number(
                            leaderboardData?.pointActions[2]?.total_points
                          ) /
                            Number(
                              leaderboardData?.pointActions[2]?.max_attainable
                            )) *
                          100
                        : 0
                    }%`,
                  }}
                  className={`bg-[#EDF2FE] border-r-[4px] border-r-[#2661FB] h-6 flex items-center`}
                >
                  <p className="font-semibold text-sm leading-[18px] text-[#2661FB] pl-[9px]">
                    {leaderboardData?.pointActions
                      ? Number(leaderboardData?.pointActions[2]?.total_points)
                      : "0"}
                  </p>
                </div>
              </div>
            </div>
            {/* Input Purchase */}
            <div className="flex w-full">
              <div className="w-[20%]">
                <p className="font-semibold text-sm leading-[18px] text-[#5C715E]">
                  Input Purchase
                </p>
              </div>
              <div className="w-[80%]">
                <div
                  style={{
                    width: `${
                      leaderboardData?.pointActions
                        ? (Number(
                            leaderboardData?.pointActions[3]?.total_points
                          ) /
                            Number(
                              leaderboardData?.pointActions[3]?.max_attainable
                            )) *
                          100
                        : 0
                    }%`,
                  }}
                  className={`bg-[#E7FBFE] border-r-[4px] border-r-[#004C92] h-6 flex items-center`}
                >
                  <p className="font-semibold text-sm leading-[18px] text-[#004C92] pl-[9px]">
                    {leaderboardData?.pointActions
                      ? Number(leaderboardData?.pointActions[3]?.total_points)
                      : "0"}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </TableBackground>
      </section>

      {/* TABLE SECTION */}
      <section className="mt-7">
        <PointsTable />
      </section>
    </div>
  );
};

export default Points;
