import React, { useState, useRef, useEffect } from "react";
import DrawerComponent from "../../components/Drawers/drawers";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import moment from "moment";
import user_green from "../../assets/icons/user_green.svg";
import { FilterList, MoreVert } from "@mui/icons-material";
import {
  getMessages,
  getPendingMessages,
  messageApproval,
} from "../../redux/Messages/messageActions";
import { useDispatch, useSelector } from "react-redux";
import { useOutsideAlerter } from "../../helpers/OutsideClick";
import ModalComponent from "../../components/Modals/modal";

const PendingMessagesTable = () => {
  const dispatch = useDispatch();
  const { pendingMessages, loading, approvalLoading, pendingTotal } =
    useSelector((state) => state.message);
  const [isClickMore, setIsClickMore] = useState(false);
  const [clickIndex, setIsClickIndex] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const [show, setShow] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [reason, setReason] = useState("");

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsClickMore);

  const showModal = () => {
    setShow(!show);
  };

  const showRejectModal = () => {
    setShowReject(!showReject);
  };

  // const getMessageBg = (val) => {
  //   let result;
  //   switch (val) {
  //     case "failed":
  //       result = "error-badge";
  //       break;
  //     case "delivered":
  //       result = "success-badge";
  //       break;
  //     default:
  //       result = "pending-badge";
  //       break;
  //   }
  //   return result;
  // };

  // const getMessageText = (val) => {
  //   let result;
  //   switch (val) {
  //     case "failed":
  //       result = "Failed";
  //       break;
  //     case "delivered":
  //       result = "Delivered";
  //       break;
  //     default:
  //       result = "Failed";
  //       break;
  //   }
  //   return result;
  // };

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [messageInfo] = useState({});

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(
      getMessages({
        page: page,
        type: "all",
      })
    );
  };

  const closeMessage = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [messageInfoo, setMessageInfoo] = useState({});

  const toggleRequest = (item) => {
    setMessageInfoo({});
    setIsDrawerOpen(!isDrawerOpen);
    // find the request selected
    // let message = pendingMessages.find((val) => val.id === id);
    setMessageInfoo(item);
  };

  const handleMoreVertClick = (index, e) => {
    e.stopPropagation();
    setIsClickMore(!isClickMore);
    setIsClickIndex(index);
  };

  console.log(messageInfo);

  const handleStatus = (status) => {
    console.log(status);
    const value = {
      msg_ids: recipients,
    };
    dispatch(messageApproval({ id: value })).then((res) => {
      if (res.type === "messageApproval/fulfilled") {
        setShow(!show);
        dispatch(
          getPendingMessages({
            page: 1,
          })
        );
      }
    });
  };

  useEffect(() => {
    dispatch(
      getPendingMessages({
        page: 1,
      })
    );
  }, [dispatch]);

  return (
    <>
      {/* message info drawer */}
      <DrawerComponent
        title="Message Info"
        show={isDrawerOpen}
        showDrawer={closeMessage}
      >
        <div className="mt-8">
          <div className="flex justify-between items-center">
            {" "}
            <div>
              <h5 className="text-xs text-[#5C715E]">Query</h5>
              <p className="font-medium text-[#5C715E] text-sm mt-1">
                <>
                  {messageInfoo?.query?.state ||
                  messageInfoo?.query?.lgas ||
                  messageInfoo?.query?.crops ? (
                    <div className="flex flex-col gap-2 capitalize">
                      {messageInfoo?.query?.state ? (
                        <>
                          <p className="text-sm font-medium">
                            State: <span>{messageInfoo?.query?.state} </span>
                          </p>
                        </>
                      ) : null}
                      {messageInfoo?.query?.lgas ? (
                        <>
                          <p className="text-sm font-medium">
                            Lga: <span>{messageInfoo?.query?.lgas} </span>
                          </p>
                        </>
                      ) : null}
                      {messageInfoo?.query?.crops ? (
                        <>
                          <p className="text-sm font-medium">
                            Crops: <span>{messageInfoo?.query?.crops} </span>
                          </p>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    "Nill"
                  )}
                </>
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Date</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {messageInfoo
              ? moment(messageInfoo.created__date).format("Do, MMM YYYY")
              : "N/A"}
          </p>
        </div>
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {messageInfoo
              ? moment(messageInfoo.created__date).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>{" "}
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Message</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1 max-w-md capitalize">
            {messageInfoo ? `${messageInfoo.message}` : "N/A"}
          </p>
        </div>
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Sender</h5>
          <div className="flex gap-2 items-center mt-2">
            <div className="bg-[#DBFFDF] w-[24px] h-[24px] rounded-full flex justify-center items-center">
              <img src={user_green} alt="user" />
            </div>
            <div>
              <p className="font-medium text-[#5C715E] text-sm capitalize">
                {messageInfoo ? `${messageInfoo.user__username}` : "N/A"}{" "}
              </p>
            </div>
          </div>
        </div>
      </DrawerComponent>

      <div className="mt-4 bg-white rounded-[8px] relative">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Messages
          </p>
          {/* search */}
          <div className="flex gap-4 mt-4">
            <div className="w-full">
              <SearchComponent
                placeholder="Search..."
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>
            <div>
              <div className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer">
                <p className="">Filters</p>
                <FilterList style={{ fontSize: "18px" }} />
              </div>
            </div>
          </div>{" "}
        </div>

        {/* tables */}
        <div className="overflow-x-auto ">
          <table className="w-full text-left ">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Message
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Sender
                </th>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Query
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Date/Time
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Status
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {pendingMessages.length > 0
                  ? pendingMessages.map((item, index) => (
                      <tr
                        onClick={() => toggleRequest(item)}
                        className="relative bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      >
                        <td className="py-4 px-4 text-[#5C715E]">
                          {truncateString(item.message, 40)}
                        </td>{" "}
                        <td className="py-4 px-4 text-[#5C715E] capitalize">
                          {item.user__username}
                        </td>{" "}
                        <td className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap ">
                          <>
                            {item?.query?.state ||
                            item?.query?.lgas ||
                            item?.query?.crops ? (
                              <div className="flex gap-2 capitalize">
                                {item?.query?.state ? (
                                  <>
                                    <p className="text-sm font-medium">
                                      State: <span>{item?.query?.state} </span>
                                    </p>
                                  </>
                                ) : null}
                                {item?.query?.lgas ? (
                                  <>
                                    <p className="text-sm font-medium">
                                      Lga: <span>{item?.query?.lgas} </span>
                                    </p>
                                  </>
                                ) : null}
                                {item?.query?.crops ? (
                                  <>
                                    <p className="text-sm font-medium">
                                      Crops: <span>{item?.query?.crops} </span>
                                    </p>
                                  </>
                                ) : null}
                              </div>
                            ) : (
                              "Nill"
                            )}
                          </>
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.created__date
                            ? moment(item.created__date).format(
                                "Do, MMM YYYY hh:mm:ss a"
                              )
                            : "N/A"}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          <div className="relative">
                            <MoreVert
                              style={{ fontSize: "18px", cursor: "pointer" }}
                              onClick={(event) =>
                                handleMoreVertClick(index, event)
                              }
                            />
                          </div>
                        </td>
                        <div className="">
                          {isClickMore && clickIndex === index && (
                            <div
                              className="absolute right-0 w-[147px] shadow-sm bg-white px-2 rounded-md padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20"
                              ref={wrapperRef}
                            >
                              <div
                                className=" rounded-md py-[12px] px-2 text-primary hover:text-white hover:bg-primary"
                                onClick={(e) => {
                                  setShow(!show);
                                  setRecipients(item?.message_ids);
                                  e.stopPropagation();
                                }}
                              >
                                <p className="text-sm  font-semibold leading-5 ">
                                  Approve
                                </p>
                              </div>

                              {/* <div
                                className="text-[#b92043] hover:text-white hover:bg-[#b92043] rounded-md py-[12px] px-2 mt-1"
                                onClick={(e) => {
                                  setShowReject(!showReject);
                                  setRecipients(item?.message_ids);
                                  e.stopPropagation();
                                }}
                              >
                                <p className="text-sm font-semibold leading-5 ">
                                  Reject
                                </p>
                              </div> */}
                            </div>
                          )}
                        </div>
                      </tr>
                    ))
                  : ""}
              </tbody>
            )}{" "}
          </table>

          {loading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={pendingTotal}
        />
      </div>

      {/* MODALS */}
      {/* Approve Agent */}
      <ModalComponent
        show={show}
        showModal={showModal}
        title="Approve Pending Message"
        subtitle="Are you sure you want to approve this pending message?"
      >
        <div className="flex justify-end mt-8 mb-3">
          <button
            disabled={approvalLoading}
            type="submit"
            onClick={() => handleStatus("approve")}
            className="bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Approve
          </button>
          <button
            onClick={() => setShow(false)}
            type="submit"
            className="bg-transparent
                    rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
          >
            Cancel
          </button>
        </div>
      </ModalComponent>

      {/* Reject Agent */}
      <ModalComponent
        show={showReject}
        showModal={showRejectModal}
        title="Reject Pending Message"
        subtitle="Are you sure you want to reject this pending message?"
      >
        <div className="mt-4">
          <p className="text-[#344335] text-sm">Reason for Rejection</p>
          <textarea
            id="reason"
            rows="8"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="appearance-none w-full
                        placeholder:text-[#96A397] mt-1 placeholder:text-sm
                        text-secondary1
                        text-sm border border-[#96A397]
                        focus:border-[#ABB6AC]
                        focus:border outline-none
                        rounded-lg resize-none
                        bg-[#FFFFFF] py-3 px-4"
            placeholder="State the reason for rejection"
          ></textarea>
        </div>

        <div className="mt-5 mb-3">
          <button
            onClick={() => handleStatus("reject")}
            // disabled={reason === "" || agentApprovalLoading}
            disabled={reason === ""}
            type="submit"
            className="bg-[#B92043] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7] w-full
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-opacity-[0.9] hover:bg-[#B92043] transition-all"
          >
            Reject
          </button>
        </div>
      </ModalComponent>
    </>
  );
};

export default PendingMessagesTable;
