import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TableBackground from "../../../components/TableBackground";
import { getFarmersCallHistory } from "../../../redux/Farmers/farmersActions";
import { ExpandMore } from "@mui/icons-material";
import moment from "moment";
import { updateCallRequest } from "../../../redux/CallRequest/requestActions";
import DrawerComponent from "../../../components/Drawers/drawers";
import PaginationComponent from "../../../components/Pagination/Pagination";
import EmptyState from "../../../components/EmptyState";

const CallHistoryTable = () => {
  const [status, setStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [isClickStatus, setIsClickStatus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [summaryValue, setSummaryValue] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [requestInfo, setRequestInfo] = useState({});
  const { loading } = useSelector((state) => state.request);
  const dispatch = useDispatch();
  const { id } = useParams();
  const call_status = [
    { key: "CALLED", label: "Called" },
    { key: "MISSED", label: "Missed" },
    { key: "TO_CALL", label: "To Call" },
  ];

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getFarmersCallHistory({
        id: id,
        page: page,
        type: status,
      })
    );
  };

  const getCallBg = (val) => {
    let result;
    switch (val) {
      case "TO_CALL":
        result = "error-badge";
        break;
      case "MISSED":
        result = "pending-badge";
        break;
      case "CALLED":
        result = "success-badge";
        break;
      default:
        result = "error-badge";
        break;
    }
    return result;
  };

  const getCallText = (val) => {
    let result;
    switch (val) {
      case "TO_CALL":
        result = "To Call";
        break;
      case "MISSED":
        result = "Missed";
        break;
      case "CALLED":
        result = "Called";
        break;
      default:
        result = "To Call";
        break;
    }
    return result;
  };

  const handleSummaryChange = (e) => {
    setSummaryValue(e.target.value);
  };

  const closeOrder = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleClickStatus = (key, requestId, e) => {
    e.stopPropagation();
    dispatch(
      updateCallRequest({
        status: key,
        summary: summaryValue,
        id: requestId,
      })
    );
    setTimeout(() => {
      setIsClickStatus(false);
      dispatch(
        getFarmersCallHistory({
          id: id,
          page: 1,
          type: status,
        })
      );
    }, 2000);
  };

  const {
    farmersCallHistoryData,
    farmersCallHistoryTotal,
    farmersCallHistoryLoading,
  } = useSelector((state) => state.farmer);

  const handleChangeCallStatus = (e, index, status) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setIsClickStatus(!isClickStatus);
    setSummaryValue(status);
  };

  const toggleRequest = (id) => {
    setIsDrawerOpen(!isDrawerOpen);
    // find the request selected
    let request = farmersCallHistoryData?.find((val) => val.id === id);
    setRequestInfo(request);
    setSummaryValue(request.summary);
  };

  const handleSave = () => {
    dispatch(
      updateCallRequest({
        status: requestInfo.status,
        summary: summaryValue,
        id: requestInfo.id,
      })
    );

    setTimeout(() => {
      setIsDrawerOpen(!isDrawerOpen);
      dispatch(
        getFarmersCallHistory({
          id: id,
          page: 1,
          type: status,
        })
      );
    }, 2000);
  };

  useEffect(() => {
    dispatch(
      getFarmersCallHistory({
        id: id,
        page: 1,
        type: status,
      })
    );
  }, [dispatch, id, status]);

  console.log(requestInfo, summaryValue);

  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        {/* <section className="flex gap-4 mt-4 pt-4 px-4">
          <div className="w-full relative">
            <div className=" absolute flex items-center h-[44px] px-[18px]">
              <Search style={{ fontSize: "18px", color: "#96A397" }} />
            </div>
            <input
              type="search"
              className="w-full border border-[#96A397] rounded-[8px] h-[44px] text-primary text-sm font-medium leading-5 cursor-pointer pl-[41px] px-[18px] outline-none focus:border-primary placeholder:text-[#96A397] serach"
              placeholder="Search"
            />
          </div>
        </section> */}

        {/* TABS SCETIONS */}
        <section>
          {/* --------  TABS -------------- */}
          <div className="flex gap-4 pt-4 mt-4 mb-5 cursor-pointer  px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "all" ? farmersCallHistoryTotal : "0"}</p>
                </div>
              </div>
            </div>

            {/* <div
              className={
                status === "called"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("called")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Called
                </p>
                <div
                  className={
                    status === "called"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "called" ? farmersCallHistoryTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "missed"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("missed")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Missed
                </p>
                <div
                  className={
                    status === "missed"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "missed" ? farmersCallHistoryTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "to_call"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("to_call")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  To Call
                </p>
                <div
                  className={
                    status === "to_call"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "to_call" ? farmersCallHistoryTotal : "0"}</p>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        {/* --------  TABLE -------------- */}
        <section className="mt-[30px] mb-[24px]">
          {farmersCallHistoryLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {farmersCallHistoryData.length === 0 ? (
                <div>
                  <EmptyState text="There's no data for this filter yet" />
                </div>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date Created
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date Updated
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Call Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {farmersCallHistoryData?.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            onClick={() => toggleRequest(data?.id)}
                          >
                            <td className="px-4">
                              {data?.farmer?.first_name}{" "}
                              {data?.farmer?.last_name}
                            </td>
                            <td className="px-4">
                              {data?.farmer?.phone_number}
                            </td>
                            <td className="px-4">
                              {moment(data?.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data?.created).format("LT")}
                            </td>

                            <td className="py-4 px-4">
                              <div className="relative">
                                <div
                                  className={
                                    data.status === "CALLED"
                                      ? "success-badge flex gap-3 items-center"
                                      : data.status === "MISSED"
                                      ? "pending-badge flex gap-3 items-center"
                                      : data.status === "TO_CALL"
                                      ? "error-badge flex gap-3 items-center"
                                      : "na-badge flex gap-3 items-center"
                                  }
                                  onClick={(event) =>
                                    handleChangeCallStatus(
                                      event,
                                      index,
                                      data?.summary
                                    )
                                  }
                                >
                                  <p>
                                    {data.status === "CALLED"
                                      ? "Called"
                                      : data.status === "MISSED"
                                      ? "Missed"
                                      : data.status === "TO_CALL"
                                      ? "To Call"
                                      : "N/A"}
                                  </p>
                                  <ExpandMore
                                    className={` ${
                                      data.status === "CALLED"
                                        ? "text-primary"
                                        : data.status === "MISSED"
                                        ? "text-[#d5a407]"
                                        : data.status === "TO_CALL"
                                        ? "text-[#b92043]"
                                        : "text-[#96A397]"
                                    }  cursor-pointer`}
                                    style={{ fontSize: "12px" }}
                                  />
                                </div>
                                {isClickStatus && selectedIndex === index ? (
                                  <div
                                    className="absolute rounded-[8px] p-[8px] w-full bg-white z-10"
                                    style={{
                                      boxShadow:
                                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                                    }}
                                  >
                                    {call_status.map((callData, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                                          onClick={(event) =>
                                            handleClickStatus(
                                              callData.key,
                                              data?.id,
                                              event
                                            )
                                          }
                                        >
                                          <p className="font-medium text-sm leading-5 text-[#344335]">
                                            {callData.label}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {farmersCallHistoryData.length > 0 ? (
                    <div className="mt-1">
                      <PaginationComponent
                        page={currentPage}
                        onPageChange={(page) => onPageChange({ page })}
                        pageSize={10}
                        totalItems={farmersCallHistoryTotal}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
        </section>
      </TableBackground>

      {/* request info drawer */}
      <DrawerComponent
        title="Request Info"
        show={isDrawerOpen}
        showDrawer={closeOrder}
      >
        <div className="mt-8">
          <div className="flex justify-between items-center">
            <div>
              <h5 className="text-xs text-[#5C715E]">Name</h5>
              <p className="font-medium text-[#5C715E] text-sm mt-1">
                {requestInfo && requestInfo.farmer
                  ? `${requestInfo.farmer.first_name} ${requestInfo.farmer.last_name}`
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Phone Number</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.farmer
              ? `${requestInfo.farmer.phone_number}`
              : "N/A"}
          </p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Call Status</h5>
          <div className="flex mt-2">
            <div
              className={getCallBg(
                requestInfo && requestInfo.status
                  ? requestInfo.status
                  : "TO_CALL"
              )}
            >
              <p>
                {getCallText(
                  requestInfo && requestInfo.status
                    ? requestInfo.status
                    : "TO_CALL"
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Request Date</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.created
              ? moment(requestInfo.created).format("Do, MMM YYYY")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Request Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.created
              ? moment(requestInfo.created).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Call Date</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.updated
              ? moment(requestInfo.updated).format("Do, MMM YYYY")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Call Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.updated
              ? moment(requestInfo.updated).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8 flex gap-6">
          <div className="flex items-center">
            <input
              checked
              id="default-radio-1"
              type="radio"
              value=""
              name="default-radio"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0"
            />
            <label
              for="default-radio-1"
              className="ml-2 text-sm font-medium text-[#5C715E]"
            >
              Call summary
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="default-radio-2"
              type="radio"
              value=""
              name="default-radio"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
            />
            <label
              for="default-radio-2"
              className="ml-2 text-sm font-medium text-[#344335]"
            >
              Complaints
            </label>
          </div>
        </div>

        <div className="mt-8">
          <label
            htmlFor="message"
            className="block mb-2 text-xs font-medium text-secondary1"
          >
            Summary
          </label>
          <textarea
            id="message"
            rows="8"
            value={summaryValue}
            onChange={handleSummaryChange}
            className="appearance-none w-full
                placeholder:text-[#96A397] placeholder:text-sm
                    text-[#5C715E]
                    text-sm resize-none
                    focus:border-[#2B8C34] focus:outline-none  
                    focus:shadow-input rounded-lg border border-[#96A397]
                    bg-white py-3 px-4"
            placeholder="Leave a summary..."
          ></textarea>
        </div>

        <div className="flex justify-between mt-4 items-center">
          <div>
            <button
              onClick={handleSave}
              disabled={loading}
              type="button"
              className="text-white bg-primary py-3 px-6
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] font-medium
                         rounded-lg text-sm flex "
            >
              {" "}
              Save
            </button>
          </div>
          <div>
            {/* <p className="text-[#96A397] text-xs font-semibold">
              Edited by <span className="text-[#2B8C34]">Celina Avong</span>
            </p>
            <p className="text-[#96A397] text-xs font-medium">
              02:20 PM | 10 Aug 22
            </p> */}
          </div>
        </div>
      </DrawerComponent>
    </div>
  );
};

export default CallHistoryTable;
