import React, { useState, useMemo, useCallback, useEffect } from 'react'
import SearchComponent from '../../components/Search';
import { debounce } from "lodash";
import { FilterList } from "@mui/icons-material";
import single_icon from '../../assets/icons/person.svg';
import grouped_icon from '../../assets/icons/people_alt.svg'
import PaginationComponent from '../../components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleLoanRecovery } from "../../redux/Loans/loanActions";
import EmptyState from "../../components/EmptyState";
import moment from 'moment'


const LoanRecoveryTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch()
  const { loanRecoveryLoading, loanRecoveryData, loanRecoveryTotal } = useSelector((state) => state.loan);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(handleLoanRecovery({ channel: typeID, page: val }))
  };

  const [typeID, setTypeID] = useState("all")
  const callTypes = [
    {
      id: "all",
      title: 'All',
    },
    {
      id: "bank_transfer",
      title: 'Bank Transfers',
    },
    {
      id: "farmer_wallet",
      title: 'Farmer Wallet',
    },
    {
      id: "agent_wallet",
      title: 'Agent Wallet',
    },
  ]

  const toggleType = (id) => {
    setTypeID(id)
    dispatch(handleLoanRecovery({ channel: id, page: currentPage }))
  }

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        // dispatch(handleLoanRecovery({ channel: typeID, page: val }))
        // dispatch(
        //     searchCallRequests({
        //       val: val,
        //       status: 'all',
        //     }),
        //   )
        console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    dispatch(handleLoanRecovery({ channel: typeID, page: currentPage }))
  }, [dispatch, typeID, currentPage])


  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className='text-[#2B8C34] font-semibold text-base'>Loan Recovery Log</p>
          {/* SEARCH AND FILTER SECTION */}
          <div className="flex gap-4 mt-2">
            <div className="w-full relative">
              <SearchComponent
                placeholder="Search..."
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>

            <div className="flex justify-center items-center gap-2 border border-primary rounded-[8px] min-h-[50px] py-3 px-6 text-primary text-sm font-medium leading-5 cursor-pointer">
              <p className="">Filter</p>
              <FilterList style={{ fontSize: "18px" }} />
            </div>
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {callTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? 'flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer'
                    : 'flex gap-2 items-center pb-1 cursor-pointer'
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? 'text-primary text-sm font-semibold'
                      : 'text-[#96A397] text-sm font-semibold'
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? 'bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]'
                      : ''
                  }
                >
                  {typeID === item.id ? loanRecoveryLoading ? "..." : loanRecoveryTotal : ''}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          {loanRecoveryLoading ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {loanRecoveryData?.length === 0 ? (
                <EmptyState text="There's no data for this filter yet" />
              ) : (
                <table className="w-full text-left">
                  <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                    <tr>
                      <th scope="col" className="px-4 py-4 font-semibold ">
                        Farmer
                      </th>

                      <th scope="col" className="px-4 py-4 font-semibold">
                        Amount
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Date/Time
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Channel
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Type
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loanRecoveryData?.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() =>
                            navigate(
                              `/loan-recovery-log/${data?.id}`
                            )
                          }
                          className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                          <th
                            scope="row"
                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                          >
                            {data?.farmer || "N/A"}
                          </th>

                          <td className="py-4 px-4 text-[#5C715E]">₦{data?.amount_repaid || "N/A"}</td>
                          <td className="py-4 px-4 text-[#5C715E]">{moment(data.created).format("lll") || "N/A"}</td>
                          <td className="py-4 px-4 capitalize">{data?.repayment_channel?.replace("_", " ") || "N/A"}</td>
                          <td className="py-4 px-4">
                            <div className='flex gap-1 items-center'>
                              <img alt="grouped icon" src={data?.repayment_type === "single" ? single_icon : grouped_icon} />
                              < p className='text-[#5C715E] text-sm font-medium'>{data?.repayment_type === "single" ? "Single" : "Grouped"}</p>
                            </div>

                          </td>
                          <td className="py-4 px-4">
                            <div className="flex">
                              <div className={`${data?.approval_status === "approved" ? "success-badge" : data?.approval_status === "rejected" ? "error-badge" : data?.approval_status === "pending" ? "pending-badge" : "na-badge"}`}>
                                <p className='capitalize'>{`${data?.approval_status}`}</p>
                              </div>
                            </div>
                          </td>

                        </tr>
                      )
                    })}





                  </tbody>
                </table>)
              }
            </>
          )}


        </div>
      </div >
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={loanRecoveryTotal}
        />
      </div>
    </>
  );
}

export default LoanRecoveryTable;