import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { refreshToken } from "../../redux/Auth/authActions";
import ModalComponent from "../../components/Modals/modal";
import { useIdleTimer } from "react-idle-timer";
import { Logout } from "../../redux/Auth/auth";

const UserRoute = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(!show);
    reset();
  };

  const onPrompt = () => {
    setShow(true);
  };

  const onIdle = () => {
    setShow(false);
    dispatch(Logout());
  };

  const handleSignOut = () => {
    setShow(false);
    dispatch(Logout());
  };

  const handleStayIn = () => {
    setShow(false);
    reset();
  };

  const { reset } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout: 1000 * 60 * 30,
    promptBeforeIdle: 1000 * 60 * 25,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const { exp } = jwt_decode(token);
        const currentTime = Date.now() / 1000; // Get current time in seconds
        const timeUntilExpiration = exp - currentTime; // Time remaining until token expiration in seconds
        const refreshThreshold = 300; // Refresh the token if it's about to expire within 5 minutes (300 seconds)

        if (timeUntilExpiration < refreshThreshold) {
          dispatch(refreshToken(localStorage.getItem("refreshToken")));
        }
      }
    };

    // Set up interval to periodically check token expiration
    const interval = setInterval(checkTokenExpiration, 60000); // Check every minute
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <>
      <ModalComponent
        show={show}
        showModal={showModal}
        title="Session Expiry"
        subtitle=""
      >
        <div className="mt-5 flex gap-4 flex-wrap">
          <p className="text-sm font-medium text-[#5C715E]">
            Your session is about to expire. You'll be automatically signed out
          </p>
          <p className="text-sm font-medium text-[#5C715E]">
            Do you want to stay signed in?
          </p>
        </div>

        <div className="flex justify-end mt-10 mb-3">
          <div className="flex gap-2">
            <button
              type="submit"
              onClick={handleSignOut}
              className="bg-[#B92043] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7]
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-opacity-[0.9] hover:bg-[#B92043] transition-all"
            >
              Sign out now
            </button>
            <button
              type="submit"
              onClick={handleStayIn}
              className="bg-primary 
                        disabled:bg-[#ABB6AC] 
                        rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
            >
              Stay signed in
            </button>
          </div>
        </div>
      </ModalComponent>

      {!isAuthenticated ? (
        <Navigate to="/" replace state={{ path: location.pathname }} />
      ) : (
        children
      )}
    </>
  );
};

export default UserRoute;
