import React, { useState } from "react";
import ModalComponent from "../../../../components/Modals/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  farnersGeneratePin,
  tempFarnersGeneratePin,
} from "../../../../redux/Farmers/farmersActions";
import { ThreeDots } from "react-loader-spinner";

const GeneratePin = ({ phoneNumber, type }) => {
  const dispatch = useDispatch();
  const { loadingResetPin } = useSelector((state) => state.farmer);
  const [isClickGeneratePin, setIsClickGeneratePin] = useState(false);
  console.log(type);
  const handleGeneratePin = () => {
    if (type === "Temporary") {
      dispatch(
        tempFarnersGeneratePin({
          value: phoneNumber,
        })
      ).then((res) => {
        if (res.type === "tempFarnersGeneratePin/fulfilled") {
          setIsClickGeneratePin(false);
        }
      });
    } else {
      dispatch(
        farnersGeneratePin({
          value: phoneNumber,
        })
      ).then((res) => {
        if (res.type === "farnersGeneratePin/fulfilled") {
          setIsClickGeneratePin(false);
        }
      });
    }
  };

  return (
    <div>
      {" "}
      <p
        className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]"
        onClick={() => setIsClickGeneratePin(true)}
      >
        Generate Pin
      </p>
      {/* GENERATE PIN MODAL */}
      <ModalComponent
        title="Generate Default Pin"
        show={isClickGeneratePin}
        showModal={() => setIsClickGeneratePin(false)}
        subtitle="Are you sure you sure you want to generate a default pin?"
      >
        <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
          <p
            className="text-sm text-medium leading-5 text-[#5C715E]"
            onClick={() => setIsClickGeneratePin(false)}
          >
            No
          </p>
          <button
            disabled={loadingResetPin}
            className="outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] flex justify-center items-center text-sm text-medium leading-5 text-white disabled:bg-[#ABB6AC] "
            onClick={handleGeneratePin}
          >
            {loadingResetPin ? (
              <ThreeDots
                height={20}
                width={20}
                color="#FFF"
                visible={true}
                secondaryColor="#FFF"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              "Yes"
            )}
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default GeneratePin;
