import React from "react";
import close_icon from "../../assets/icons/close_black.svg";
import { CSSTransition } from "react-transition-group";
import "./modal.css";

const ModalComponent = ({
  showModal,
  show,
  children,
  title,
  subtitle,
  style,
}) => {
  return (
    <>
      <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
        <div
          className="modal 
                    overflow-y-auto overflow-x-hidden bg-[#344335]
                    bg-opacity-50 fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full
                    opacity-0 transition-all duration-[0.3s]"
          onClick={showModal}
        >
          <div
            style={style}
            className="relative p-4 w-full max-w-xl h-full md:h-auto mx-auto my-32 modal-content translate-y-[-200px] transition-all duration-[0.3s]"
            onClick={(e) => e.stopPropagation()}
          >
            {/* modal content */}
            <div className="relative bg-white rounded-lg py-4 px-5">
              {/* modal header */}
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-base font-semibold text-[#344335]">
                    {title}
                  </h3>
                  <p className="text-[#96A397] text-xs font-medium mt-1">
                    {subtitle}
                  </p>
                </div>

                <div className="cursor-pointer" onClick={showModal}>
                  <img src={close_icon} alt="close" />
                </div>
              </div>

              {/* modal body */}
              <div>{children}</div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default ModalComponent;
