import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import DebitFarmerTable from "../../tables/DebitFarmer";
import { ButtonPrimary, ButtonSecondary } from "../../components/Button";
import ModalComponent from "../../components/Modals/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkDebitFarmers,
  debitFarmers,
  getAllFarmersSearch,
} from "../../redux/Farmers/farmersActions";
import { ThreeDots } from "react-loader-spinner";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  getAllDebitActions,
  getWalletDeductions,
} from "../../redux/Location/locationAction";
import { Upload } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useOutsideAlerter } from "../../helpers/OutsideClick";

const DebitFarmer = () => {
  const [isShow, setShow] = useState(false);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isShowBulk, setShowBulk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [farmers, setFarmers] = useState([]);
  const [amount, setAmount] = useState(0);
  const [number, setNumber] = useState("");
  const [toggleIcon, setToggleIcon] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const dispatch = useDispatch();
  const { debitActionsData } = useSelector((state) => state.location);
  const { debitFarmerLoading, bulkDebitloading } = useSelector(
    (state) => state.farmer
  );
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setToggleIcon);

  const handlePhoneNumber = (value) => {
    setFarmers([]);
    let result;

    if (value.startsWith("0")) {
      let data = value.replace("0", "");
      result = "234" + data.toString();
    } else if (value.includes("+")) {
      result = value.replace("+", "");
    } else {
      result = value;
    }

    // console.log(result.length)
    if (`${result}`.length === 13) {
      setNumber(result);
      fetchData(result);
    }
  };

  const fetchData = useCallback(
    async (result) => {
      setLoading(true);
      const data = await getAllFarmersSearch({ value: result }); // fetch data from your redux store

      dispatch(data)
        .then((result) => {
          let data = result?.payload?.data;
          if (data.length !== 0) {
            setFarmers(data);
            setLoading(false);
          } else {
            let value = [
              {
                first_name: "Farmer doesn't exist",
                last_name: "",
              },
            ];

            setFarmers(value);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch({ type: "auth/Logout" });
          }
        });
    },
    [dispatch]
  );

  const handleDebit = () => {
    const values = {
      farmer_phone_number: number,
      debit_amount: Number(amount),
      action: selectedAction,
    };
    dispatch(debitFarmers(values)).then((res) => {
      if (res.type === "debitFarmers/fulfilled") {
        setShowReject(false);

        dispatch(getWalletDeductions({ page: 1, value: "all" }));
        setFarmers([]);
        setNumber("");
        setAmount(0);
        setSelectedAction("");
      }
    });
  };

  const handleSetFile = (event) => {
    setFile(null);
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      fileInputRef.current.value = null;
      // let reader = new FileReader();
      // reader.onload = function (e) {
      //   // setFile(e?.target?.result);
      // };
      // reader.readAsDataURL(event.target.files[0]);
    }
  };

  const bulkDebitAllFarmers = () => {
    const values = {
      farmers_list: file,
    };
    dispatch(bulkDebitFarmers(values)).then((res) => {
      if (res.type === "bulkDebitFarmers/fulfilled") {
        setShowBulk(false);
        setFarmers([]);
        dispatch(getWalletDeductions({ page: 1, value: "all" }));
        setFile("");
      }
    });
  };

  useEffect(() => {
    setFarmers([]);
    dispatch(getAllDebitActions());
  }, [dispatch]);

  return (
    <div>
      <div>
        <Header text="Debit Farmer">
          <div className="flex gap-4">
            <ButtonSecondary onclick={() => setShowBulk(true)}>
              Bulk Debit
            </ButtonSecondary>
            <ButtonPrimary onclick={() => setShow(true)}>Debit</ButtonPrimary>
          </div>
        </Header>
        {/* TABLE SECTION */}
        <section className="mt-[24px]">
          <DebitFarmerTable />
        </section>
      </div>

      {/* BULK UPLOAD */}
      <ModalComponent
        title="Bulk Debit"
        show={isShowBulk}
        showModal={() => setShowBulk(false)}
      >
        <div className="mt-6 w-full ">
          <div>
            <label htmlFor="file-upload" className="mt-3">
              {file ? (
                <div className="flex flex-col gap-3 justify-center items-center h-[134px]  w-full border border-primary border-dashed rounded-lg">
                  <p className="text-sm text-[#344335]  font-[400]  leading-[20px]">
                    File uploaded successfully
                  </p>
                  <div className="flex justify-center items-center h-[30px] w-fit px-3 border-primary border rounded-lg cursor-pointer ">
                    <p
                      onClick={() => {
                        setFile(null);
                        fileInputRef.current.value = null;
                      }}
                      className="text-sm text-primary font-[500]  leading-[20px]"
                    >
                      Reupload
                    </p>
                  </div>
                </div>
              ) : (
                <div className="h-[134px]  cursor-pointer gap-2 flex flex-col items-center justify-center rounded-[8px] border-[1px] border-[#A6A08C] bg-[#FFFFFF]">
                  <Upload className="text-primary" />
                  <p className="text-sm text-primary font-[600]  leading-[20px]">
                    Choose file to upload
                  </p>
                  <p className="text-sm font-[400] text-[#344335]  leading-[18px] cursor-pointer">
                    Upload a CSV file only
                  </p>
                </div>
              )}

              <input
                id="file-upload"
                ref={fileInputRef}
                accept=".csv"
                required
                onChange={(event) => handleSetFile(event)}
                type="file"
                className=" w-full"
              />
            </label>
            <div className="flex justify-between items-center pt-6 pb-4">
              <Tooltip
                placement="top-start"
                title="Make a copy and download as csv"
              >
                <button className="outline-none bg-white border border-primary text-primary font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px] hover:border hover:border-primary transition ease-in-out delay-100">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1cHaS5g8jkuc9Sx7-rt8PZxyboQs9AnEArPgtfBamWZU/edit#gid=0"
                  >
                    Download csv template
                  </a>
                </button>
              </Tooltip>
              <button
                disabled={
                  file !== null && bulkDebitloading !== true ? false : true
                }
                type="submit"
                onClick={() => bulkDebitAllFarmers()}
                className="bg-[#B92043] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7] w-fit
                    rounded-lg px-6 py-3 text-[#FEF0EC] text-sm font-medium hover:bg-opacity-[0.9] transition-all"
              >
                Debit Farmers
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title="Debit Farmer"
        show={isShow}
        showModal={() => {
          setFarmers([]);
          setShow(false);
        }}
      >
        <div className="mt-6 w-full ">
          <div>
            <div className="mb-5">
              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                Farmer's Phone Number
              </p>
              <input
                type="number"
                className="w-full outline-none py-[12px] px-[16px] border border-[#96A397] rounded-[8px] text-medium text-sm leading-5 text-[#96A397] focus:border-[#2B8C34] focus:outline-none  
              focus:shadow-input"
                placeholder="Phone Number"
                onChange={(event) => handlePhoneNumber(event.target.value)}
                pattern="[0-9]"
              />
              {loading ? (
                <ThreeDots
                  height={25}
                  width={25}
                  color="#2B8C34"
                  visible={true}
                  secondaryColor="#2B8C34"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                <>
                  {farmers?.map((data, index) => {
                    return (
                      <div key={index}>
                        <p className="pt-1 font-medium text-xs leading-[16px] text-[#5C715E]">
                          {data?.first_name + " " + data?.last_name}
                        </p>
                        <p className="pt-1 font-medium text-xs leading-[16px] text-[#5C715E]">
                          Available Wallet Balance:{" "}
                          <span className="text-primary">
                            ₦{data?.available_wallet_balance}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            <div className="mb-5">
              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                Debit Amount(₦)
              </p>
              <input
                type="number"
                className="outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
                placeholder="Debit Amount"
                onChange={(event) => setAmount(event.target.value)}
                value={amount}
              />
            </div>

            <div className="mb-5">
              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                Action
              </p>
              <div>
                <div
                  className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[10px]"
                  onClick={() => setToggleIcon(!toggleIcon)}
                >
                  <p>{selectedAction || "Select an action"}</p>

                  {toggleIcon ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>

                {toggleIcon && (
                  <div
                    className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                    ref={wrapperRef}
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    {debitActionsData?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                          onClick={() => {
                            setSelectedAction(data);
                            setToggleIcon(false);
                          }}
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335] capitalize">
                            {data}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-end items-end pt-6 pb-4">
              <button
                disabled={
                  farmers.length > 0 && amount !== "" && selectedAction !== ""
                    ? false
                    : true
                }
                type="submit"
                onClick={() => {
                  setShowReject(true);
                  setShow(false);
                }}
                className="bg-[#B92043] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7] w-fit
                    rounded-lg px-6 py-3 text-[#FEF0EC] text-sm font-medium hover:bg-opacity-[0.9] transition-all"
              >
                Debit Farmer
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Debit Farmer*/}
      <ModalComponent
        show={showReject}
        showModal={() => setShowReject(false)}
        title="Debit Farmer"
        subtitle="Are you sure you want to debit this farmer?"
      >
        <div className="flex justify-end mt-8 mb-3">
          <button
            disabled={debitFarmerLoading}
            type="submit"
            onClick={handleDebit}
            className="bg-[#B92043] 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#B92043] transition-all"
          >
            Debit
          </button>
          <button
            onClick={() => setShowReject(false)}
            type="submit"
            className="bg-transparent
                    rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
          >
            Cancel
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default DebitFarmer;
