import {
  Add,
  ErrorOutline,
  ExpandMore,
  Remove,
  ShoppingCart,
} from "@mui/icons-material";
import cogoToast from "cogo-toast";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { ButtonPrimary } from "../../components/Button";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import search_icon from "../../assets/icons/search.svg";
import search_green from "../../assets/icons/search_green.svg";
import go_back from "../../assets/icons/goBack.svg";
import vert_green_icon from "../../assets/icons/vert_green.svg";
import { useParams } from "react-router-dom";
import GoBack from "../../components/GoBack/index";
import Header from "../../components/Header/index";
import ModalComponent from "../../components/Modals/modal";
import TableBackground from "../../components/TableBackground";
import {
  getCallToOrderInfo,
  updateACallToOrder,
} from "../../redux/CallToOrder/callToOrderAction";

import { atlasSearch } from "../../redux/Catalog/catalogActions";
import { getAllLGA, getAllState } from "../../redux/Location/locationAction";

const Cart = () => {
  const dispatch = useDispatch();
  const { stateData, lgaData } = useSelector((state) => state.location);
  const tab = ["Delivery Address", "Payment Method", "Confirm Order"];
  const [clickedTab, setClickedTab] = useState("Delivery Address");
  const { id } = useParams();
  const [cart, setCart] = useState([]);
  const [selectedAtLasTab, setSelectedAtLasTab] = useState("Product_details");
  const [isOpenAtlasSearch, setIsOpenAtlasSearch] = useState(false);
  const { callToOrderInfoData, isShortCodeClick, updateCallToOrderShortCode } =
    useSelector((state) => state.callToOrder);

  const [openRemoveItemModal, setOpenRemoveItemModal] = useState(false);
  const [clickedIndex, setClickedIndex] = useState("");
  const [clickedProduct, setClickedProduct] = useState("");
  const [isClickCheckout, setIsClickCheckout] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [clickedPayment, setClickedPayment] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  ////////////////////////LOCATION STATES
  const [isClickState, setIsClickState] = useState(false);
  const [isClickLGA, setIsClickLGA] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [selectedLGA, setSelectedLGA] = useState({});
  const [clickShortCode, setClickShortCode] = useState(false);
  const [isAddToCart] = useState(false);

  const handleStateClick = () => {
    setIsClickState(!isClickState);
    dispatch(getAllState());
  };

  const handleSelectedState = (data) => {
    setSelectedState({
      key: data.id,
      name: data.display_name,
    });
    setIsClickState(false);

    dispatch(
      getAllLGA({
        id: data.id,
      })
    );
  };

  const handleLgaClick = () => {
    if (Object.keys(selectedState).length !== 0) {
      setIsClickLGA(!isClickLGA);
    } else {
      cogoToast.error("Kindly select a state first");
    }
  };

  const { searchResult, loading, groupByResult, informationCard } = useSelector(
    (state) => state.catalog
  );

  const [types] = useState([
    {
      id: 1,
      tag: "all",
      text: "All",
    },
    {
      id: 2,
      tag: "crop",
      text: "Crop",
    },
    {
      id: 3,
      tag: "livestock",
      text: "Livestock",
    },
    {
      id: 4,
      tag: "advisory",
      text: "Advisory",
    },
    {
      id: 5,
      tag: "insurance",
      text: "Insurance",
    },
  ]);

  const [chipId, setChip] = useState(1);
  const toggleChip = (val) => {
    const { id } = val;
    setChip(id === chipId ? null : id);
  };

  const [searchValue, setSearchValue] = useState("");
  const [isPageOpen, setPageOpen] = useState(true);
  const [productInfo, setProductInfo] = useState({});

  const handleBack = () => {
    setPageOpen(true);
  };

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        setPageOpen(true);
        //  api to dispatch and make the actions for search
        dispatch(atlasSearch({ val }));
      }, 750),
    [dispatch]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleViewProduct = (type, id) => {
    // open next page
    setPageOpen(false);

    const res = searchResult.find(
      (val) =>
        val.product_details.product_type === type &&
        val.vendor_price_details.product_id === id
    );
    setProductInfo(res);
  };

  const handleAddOrder = (
    id,
    product_name,
    vendor_name,
    price,
    product_size,
    measurement,
    product_type
  ) => {
    setCart((prevState) => [
      ...prevState,
      {
        id,
        product_name,
        vendor_name,
        price,
        product_size,
        measurement,
        product_type,
        quantity: 1,
      },
    ]);
    setTotalPrice(price + totalPrice);
    setIsOpenAtlasSearch(false);
    setPageOpen(true);
    setSearchValue("");
  };

  const handleOpenAtlasSearch = () => {
    setIsOpenAtlasSearch(true);
    setSearchValue("");
    setPageOpen(true);
  };

  const handleRemoveItemFromCart = (dataIndex) => {
    // eslint-disable-next-line
    const newState = cart.map((obj, index) => {
      // eslint-disable-next-line
      // 👇️ if id equals 2, update country property
      if (index === dataIndex) {
        setTotalPrice(totalPrice * obj.quantity);
        return {
          ...obj,
          quantity: obj.quantity - 1,
          price: obj.price - obj.price,
        };
      }

      // 👇️ otherwise return the object as is
      return obj;
    });
    setCart(newState);
  };

  const handleAddItemFromCart = (dataIndex) => {
    const newState = cart.map((obj, index) => {
      // 👇️ if id equals 2, update country property

      if (index === dataIndex) {
        return {
          ...obj,
          quantity: obj.quantity + 1,
          price: obj.price + obj.price,
        };
      }

      // 👇️ otherwise return the object as is
      return obj;
    });
    setCart(newState);
  };

  const handleOpenRemoveItemFromCart = (dataIndex, data) => {
    setClickedIndex(dataIndex);
    setClickedProduct(data);
    setOpenRemoveItemModal(true);
  };

  const handleDeleteItemFronCart = () => {
    setOpenRemoveItemModal(false);
    let data = [...cart];
    const newState = data.filter((obj, index) => index !== clickedIndex);

    setCart(newState);
    cogoToast.success("The Item has been removed");
  };

  const handleCheckout = () => {
    let orders = {};
    let inputOrders = [];
    let advisoryOrders = [];
    let insuranceOrders = [];
    let mechanizationOrders = [];
    let livestockOrders = [];

    // eslint-disable-next-line
    cart.map((field) => {
      if (field.product_type === "CROP") {
        inputOrders.push({
          product_id: field.id,
          quantity: field.quantity,
        });
        orders["input_order"] = inputOrders;
      }

      if (field.product_type === "ADVISORY") {
        advisoryOrders.push({
          product_id: field.id,
          quantity: field.quantity,
        });
        orders["advisory_order"] = advisoryOrders;
      }

      if (field.product_type === "INSURANCE") {
        insuranceOrders.push({
          product_id: field.id,
          quantity: field.quantity,
        });
        orders["insurance_order"] = insuranceOrders;
      }

      if (field.product_type === "MECHANIZATION") {
        mechanizationOrders.push({
          product_id: field.id,
          quantity: field.quantity,
          land_size: field.product.landSize,
          work_duration: field.product.workDuration,
        });
        orders["mechanization_order"] = mechanizationOrders;
      }

      if (field.product_type === "LIVESTOCK") {
        livestockOrders.push({
          product_id: field.id,
          quantity: field.quantity,
        });
        orders["livestock_order"] = livestockOrders;
      }
    });

    const value = {
      checkout: true,
      withhold_payment: clickedPayment === "Instant Payment" ? false : true,
      total_display_price: totalPrice,
      farmer_id: callToOrderInfoData?.farmer?.id,
      input_order: [],
      advisory_order: [],
      insurance_order: [],
      mechanization_order: [],
      ...orders,
    };

    dispatch(updateACallToOrder({ value, id: id }));

    setTimeout(() => {
      setIsClickCheckout(false);
      dispatch(
        getCallToOrderInfo({
          id: id,
        })
      );
    }, 800);
  };

  const handleCloseShortCode = () => {
    setClickShortCode(true);
    dispatch(
      getCallToOrderInfo({
        id: id,
      })
    );
    setCart([]);
    setTotalPrice(0);
  };

  useEffect(() => {
    dispatch(
      getCallToOrderInfo({
        id: id,
      })
    );

    let sum = 0;
    cart.forEach((value) => {
      sum += value.price;
    });
    setTotalPrice(sum);
  }, [dispatch, id, cart]);

  return (
    <div>
      <section className="mb-[14px]">
        <GoBack />
      </section>

      <section className="mb-4">
        <Header text="Call To Order (Cart)" />
      </section>

      <section className="flex w-full gap-4">
        <div className="w-full">
          <div className="flex  items-center justify-between gap-[20px] mb-[15px] bg-white rounded-[8px] p-4">
            <div className="">
              <p className="text-sm leading-[18px] font-semibold text-[#7C8D7E]">
                Total Amount
              </p>
              <p className="text-2xl leading-[30px] font-semibold text-[#7C8D7E] pt-[2px]">
                ₦{Number(callToOrderInfoData?.total_display_price) + totalPrice}
              </p>
            </div>

            {callToOrderInfoData.checkout ? (
              <button
                disabled={true}
                className="bg-primary
                              disabled:bg-[#ABB6AC] 
                              disabled:text-white
                              rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
              >
                Checked out
              </button>
            ) : (
              <div className="flex items-center gap-[24px]">
                <button
                  type="submit"
                  disabled={false}
                  onClick={() => setIsClickCheckout(true)}
                  className="bg-primary
                              disabled:bg-[#ABB6AC] 
                              disabled:text-white
                              rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Checkout
                </button>
              </div>
            )}
          </div>

          <>
            <>
              <div>
                {/* 2 */}
                <TableBackground classname="p-[16px] rounded-[8px]">
                  <p className="text-sm font-semibold text-[#7C8D7E] leading-[18px] ">
                    Items Details
                  </p>

                  {cart?.map((data, index) => {
                    return (
                      <>
                        <div
                          className="flex justify-between pt-[16px] pb-[16px]"
                          key={index}
                        >
                          <div className="">
                            <p className="font-semibold text-sm leading-[18px] text-[#344335] pb-[8px]">
                              {data?.product_name}
                            </p>
                            <p className="font-[400] text-sm leadin-[18px] text-[#344335] pb-[8px]">
                              {data?.vendor_name}
                            </p>

                            <div className="flex gap-[8px] items-center">
                              <div className="flex items-center px-[16px] gap-[28px] justify-between w-[118px] h-[36px] bg-[#ECEFEC] rounded-[8px]">
                                <Remove
                                  onClick={() =>
                                    handleRemoveItemFromCart(index, data)
                                  }
                                  style={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    color: "#344335",
                                  }}
                                />
                                <p className="text-[#344335] leading-5 text-semibold text-sm">
                                  {data?.quantity}
                                </p>
                                <Add
                                  onClick={() =>
                                    handleAddItemFromCart(index, data)
                                  }
                                  style={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    color: "#344335",
                                  }}
                                />
                              </div>
                              <div className="border border-l-[#96A397] h-[36px]" />
                              <p
                                className="text-[#9B7000] text-sm font-medium leading-5 text-center cursor-pointer"
                                onClick={() =>
                                  handleOpenRemoveItemFromCart(
                                    index,
                                    data?.product_name
                                  )
                                }
                              >
                                Delete Item
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              ₦{data?.price} | {data?.product_size}
                              {data.measurement}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <>
                    {/* INPUT ORDERS */}
                    {callToOrderInfoData?.input_order?.map((data, index) => {
                      return (
                        <>
                          <div
                            className="flex justify-between pt-[16px] pb-[16px]"
                            key={index}
                          >
                            <div className="">
                              <p className="font-semibold text-sm leading-[18px] text-[#344335] pb-[8px]">
                                {data?.input_product_name}
                              </p>
                              {/* <p className="font-[400] text-sm leadin-[18px] text-[#344335] pb-[8px]">
                                {data?.vendor_name || 'N/A'}
                              </p> */}

                              <div className="flex gap-[8px] items-center">
                                <div className="flex items-center px-[16px] gap-[28px] justify-between w-[118px] h-[36px] bg-[#ECEFEC] rounded-[8px]">
                                  <Remove
                                    // onClick={() =>
                                    //   handleRemoveItemFromCart(index, data)
                                    // }
                                    style={{
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      color: "#344335",
                                    }}
                                  />
                                  <p className="text-[#344335] leading-5 text-semibold text-sm">
                                    {data?.quantity}
                                  </p>
                                  <Add
                                    // onClick={() =>
                                    //   handleAddItemFromCart(index, data)
                                    // }
                                    style={{
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      color: "#344335",
                                    }}
                                  />
                                </div>
                                <div className="border border-l-[#96A397] h-[36px]" />
                                <p
                                  className="text-[#9B7000] text-sm font-medium leading-5 text-center cursor-pointer"
                                  // onClick={() =>
                                  //   handleOpenRemoveItemFromCart(
                                  //     index,
                                  //     data?.product_name,
                                  //   )
                                  // }
                                >
                                  Delete Item
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                ₦{data?.total_display_price}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}

                    {/* LIVESTOCK ORDERS */}
                    {callToOrderInfoData?.livestock_order?.map(
                      (data, index) => {
                        return (
                          <>
                            <div
                              className="flex justify-between pt-[16px] pb-[16px]"
                              key={index}
                            >
                              <div className="">
                                <p className="font-semibold text-sm leading-[18px] text-[#344335] pb-[8px]">
                                  {data?.input_product_name}
                                </p>
                                {/* <p className="font-[400] text-sm leadin-[18px] text-[#344335] pb-[8px]">
                                {data?.vendor_name || 'N/A'}
                              </p> */}

                                <div className="flex gap-[8px] items-center">
                                  <div className="flex items-center px-[16px] gap-[28px] justify-between w-[118px] h-[36px] bg-[#ECEFEC] rounded-[8px]">
                                    <Remove
                                      // onClick={() =>
                                      //   handleRemoveItemFromCart(index, data)
                                      // }
                                      style={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#344335",
                                      }}
                                    />
                                    <p className="text-[#344335] leading-5 text-semibold text-sm">
                                      {data?.quantity}
                                    </p>
                                    <Add
                                      // onClick={() =>
                                      //   handleAddItemFromCart(index, data)
                                      // }
                                      style={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#344335",
                                      }}
                                    />
                                  </div>
                                  <div className="border border-l-[#96A397] h-[36px]" />
                                  <p
                                    className="text-[#9B7000] text-sm font-medium leading-5 text-center cursor-pointer"
                                    // onClick={() =>
                                    //   handleOpenRemoveItemFromCart(
                                    //     index,
                                    //     data?.product_name,
                                    //   )
                                    // }
                                  >
                                    Delete Item
                                  </p>
                                </div>
                              </div>
                              <div className="">
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                  ₦{data?.total_display_price}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}

                    {/* ADVISORY ORDERS */}
                    {callToOrderInfoData?.advisory_order?.map((data, index) => {
                      return (
                        <>
                          <div
                            className="flex justify-between pt-[16px] pb-[16px]"
                            key={index}
                          >
                            <div className="">
                              <p className="font-semibold text-sm leading-[18px] text-[#344335] pb-[8px]">
                                {data?.advisory_product_name}
                              </p>
                              {/* <p className="font-[400] text-sm leadin-[18px] text-[#344335] pb-[8px]">
                                {data?.vendor_name || 'N/A'}
                              </p> */}

                              <div className="flex gap-[8px] items-center">
                                <div className="flex items-center px-[16px] gap-[28px] justify-between w-[118px] h-[36px] bg-[#ECEFEC] rounded-[8px]">
                                  <Remove
                                    // onClick={() =>
                                    //   handleRemoveItemFromCart(index, data)
                                    // }
                                    style={{
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      color: "#344335",
                                    }}
                                  />
                                  <p className="text-[#344335] leading-5 text-semibold text-sm">
                                    {data?.quantity}
                                  </p>
                                  <Add
                                    // onClick={() =>
                                    //   handleAddItemFromCart(index, data)
                                    // }
                                    style={{
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      color: "#344335",
                                    }}
                                  />
                                </div>
                                <div className="border border-l-[#96A397] h-[36px]" />
                                <p
                                  className="text-[#9B7000] text-sm font-medium leading-5 text-center cursor-pointer"
                                  // onClick={() =>
                                  //   handleOpenRemoveItemFromCart(
                                  //     index,
                                  //     data?.product_name,
                                  //   )
                                  // }
                                >
                                  Delete Item
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                ₦{data?.total_display_price}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}

                    {/* INSURANCE ORDERS */}
                    {callToOrderInfoData?.insurance_order?.map(
                      (data, index) => {
                        return (
                          <>
                            <div
                              className="flex justify-between pt-[16px] pb-[16px]"
                              key={index}
                            >
                              <div className="">
                                <p className="font-semibold text-sm leading-[18px] text-[#344335] pb-[8px]">
                                  {data?.insurance_product_name}
                                </p>
                                {/* <p className="font-[400] text-sm leadin-[18px] text-[#344335] pb-[8px]">
                                {data?.vendor_name || 'N/A'}
                              </p> */}

                                <div className="flex gap-[8px] items-center">
                                  <div className="flex items-center px-[16px] gap-[28px] justify-between w-[118px] h-[36px] bg-[#ECEFEC] rounded-[8px]">
                                    <Remove
                                      // onClick={() =>
                                      //   handleRemoveItemFromCart(index, data)
                                      // }
                                      style={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#344335",
                                      }}
                                    />
                                    <p className="text-[#344335] leading-5 text-semibold text-sm">
                                      {data?.quantity}
                                    </p>
                                    <Add
                                      // onClick={() =>
                                      //   handleAddItemFromCart(index, data)
                                      // }
                                      style={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#344335",
                                      }}
                                    />
                                  </div>
                                  <div className="border border-l-[#96A397] h-[36px]" />
                                  <p
                                    className="text-[#9B7000] text-sm font-medium leading-5 text-center cursor-pointer"
                                    // onClick={() =>
                                    //   handleOpenRemoveItemFromCart(
                                    //     index,
                                    //     data?.product_name,
                                    //   )
                                    // }
                                  >
                                    Delete Item
                                  </p>
                                </div>
                              </div>
                              <div className="">
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                  ₦{data?.total_display_price}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}

                    {/* MECHANIZATION ORDERS */}
                    {callToOrderInfoData?.mechanization_order?.map(
                      (data, index) => {
                        return (
                          <>
                            <div
                              className="flex justify-between pt-[16px] pb-[16px]"
                              key={index}
                            >
                              <div className="">
                                <p className="font-semibold text-sm leading-[18px] text-[#344335] pb-[8px]">
                                  {data?.mechanization_product_name}
                                </p>
                                {/* <p className="font-[400] text-sm leadin-[18px] text-[#344335] pb-[8px]">
                                {data?.vendor_name || 'N/A'}
                              </p> */}

                                <div className="flex gap-[8px] items-center">
                                  <div className="flex items-center px-[16px] gap-[28px] justify-between w-[118px] h-[36px] bg-[#ECEFEC] rounded-[8px]">
                                    <Remove
                                      // onClick={() =>
                                      //   handleRemoveItemFromCart(index, data)
                                      // }
                                      style={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#344335",
                                      }}
                                    />
                                    <p className="text-[#344335] leading-5 text-semibold text-sm">
                                      {data?.quantity}
                                    </p>
                                    <Add
                                      // onClick={() =>
                                      //   handleAddItemFromCart(index, data)
                                      // }
                                      style={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: "#344335",
                                      }}
                                    />
                                  </div>
                                  <div className="border border-l-[#96A397] h-[36px]" />
                                  <p
                                    className="text-[#9B7000] text-sm font-medium leading-5 text-center cursor-pointer"
                                    // onClick={() =>
                                    //   handleOpenRemoveItemFromCart(
                                    //     index,
                                    //     data?.product_name,
                                    //   )
                                    // }
                                  >
                                    Delete Item
                                  </p>
                                </div>
                              </div>
                              <div className="">
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                  ₦{data?.total_display_price}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                  </>
                  <div>
                    <hr className="bg-[DDE2DE]" />
                  </div>
                </TableBackground>

                {callToOrderInfoData?.checkout ? null : (
                  <TableBackground classname="p-[16px] mt-[8px] rounded-[8px]">
                    <div
                      className="flex justify-center items-center gap-[9px] cursor-pointer"
                      onClick={() => handleOpenAtlasSearch()}
                    >
                      <Add
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          color: "#2B8C34",
                        }}
                      />
                      <p className="text-sm text-primary font-medium leading-5">
                        {" "}
                        Add more orders
                      </p>
                    </div>
                  </TableBackground>
                )}
              </div>
            </>
          </>
        </div>
      </section>

      {/* MODAL */}
      {/* ATLAS SEACH */}
      <ModalComponent
        title="Create Order"
        show={isOpenAtlasSearch}
        showModal={() => setIsOpenAtlasSearch(false)}
        subtitle="Use atlas search to find products"
      >
        <div className="bg-white rounded-lg mt-[13px]">
          <div className="mt-2">
            <div className="relative">
              <input
                type="text"
                style={{ height: "44px" }}
                onChange={handleChange}
                value={searchValue}
                className="appearance-none w-full placeholder:text-[#96A397] 
                            placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] 
                            focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-10"
                placeholder="Search for products, crops"
              />
              <img
                src={search_icon}
                alt="search"
                className="absolute top-1/2 transform -translate-y-1/2 left-4 cursor-pointer"
              />
            </div>
          </div>

          <div>
            {/* chips */}
            <ul className="mt-4 flex gap-2">
              {types.map((val) => (
                <>
                  <li
                    key={val.id}
                    onClick={() => toggleChip(val)}
                    className={
                      chipId === val.id
                        ? "bg-[#DBFFDF] text-primary text-xs font-medium px-2 py-1 rounded-[4px] cursor-pointer"
                        : "bg-[#ECEEEC] text-[#96A397] text-xs font-medium px-2 py-1 rounded-[4px] cursor-pointer"
                    }
                  >
                    {val.text}
                  </li>
                </>
              ))}
            </ul>

            {searchValue.length > 0 ? (
              <>
                <div className="mt-4">
                  <h5 className="text-[#344335] text-sm">
                    Search results for : "{searchValue}"
                  </h5>
                </div>
                {informationCard.length > 0 && (
                  <div className="mt-4 mb-5 bg-[#EDF7EE] flex gap-4 justify-between rounded-[5px] py-5 px-5">
                    <div>
                      <h6 className="text-[#344335] font-semibold text-sm">
                        {informationCard[0].product_name}
                      </h6>
                      <p className="text-[#344335] text-sm font-medium mt-2">
                        {informationCard[0].product_information}
                      </p>
                    </div>
                    <div className="flex-shrink-0 cursor-pointer">
                      <img src={vert_green_icon} alt="vert" />
                    </div>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            {!loading && searchResult.length === 0 ? (
              <div className="flex justify-center items-center flex-col h-[400px]">
                <div className="flex justify-center">
                  <div className="bg-[#DBFFDF] w-[60px] h-[60px] rounded-full flex justify-center items-center">
                    <img src={search_green} alt="search" />
                  </div>
                </div>

                <div className="text-center mt-3">
                  <p className="text-[#7C8D7E] text-xs font-medium max-w-[16rem] mx-auto">
                    Search for any product using the name of a product or crop
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {loading &&
              ["1", "2", "3", "4", "5"].map(() => (
                <div className="animate-pulse w-full mt-4">
                  <div className="h-2 bg-gray-200 rounded-lg w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                </div>
              ))}

            {/* first section ------------------- */}
            {isPageOpen && (
              <div className="mt-4">
                {/* ---Crop products map */}
                <div className="mb-3">
                  {groupByResult && groupByResult.InputProducts ? (
                    groupByResult.InputProducts.length > 0 ? (
                      <h5 className="text-[#344335] text-sm font-semibold">
                        Crop
                      </h5>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {groupByResult && groupByResult.InputProducts
                    ? groupByResult.InputProducts.length > 0
                      ? groupByResult.InputProducts.map((item, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleViewProduct(
                                item.product_details.product_type,
                                item.vendor_price_details.product_id
                              )
                            }
                            className="mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer"
                          >
                            <div>
                              <h4 className="text-[#344335] text-sm font-semibold">
                                {item.product_details
                                  ? item.product_details.name
                                  : "N/A"}
                              </h4>

                              <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                                <p>
                                  {item.vendor_price_details
                                    ? item.vendor_price_details.vendor_name
                                    : ""}
                                </p>
                                <p>|</p>
                                <p>
                                  <i className="mdi mdi-currency-ngn"></i>
                                  {item.product_details.display_price}/
                                  {item.product_details.product_size}
                                  {item.product_details.measurement}
                                </p>
                              </div>
                            </div>
                            <div>
                              <button
                                className="text-primary ctaView
                                            text-xs font-medium"
                              >
                                View Product
                              </button>
                            </div>
                          </div>
                        ))
                      : ""
                    : ""}
                </div>

                {/* --- Livestock products */}
                <div className="mb-3">
                  {groupByResult && groupByResult.LivestockInputs ? (
                    groupByResult.LivestockInputs.length > 0 ? (
                      <h5 className="text-[#344335] text-sm font-semibold">
                        Livestock
                      </h5>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {groupByResult && groupByResult.LivestockInputs
                    ? groupByResult.LivestockInputs.length > 0
                      ? groupByResult.LivestockInputs.map((item, index) => (
                          <div
                            onClick={() =>
                              handleViewProduct(
                                item.product_details.product_type,
                                item.vendor_price_details.product_id
                              )
                            }
                            key={index}
                            className="mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer"
                          >
                            <div>
                              <h4 className="text-[#344335] text-sm font-semibold">
                                {item.product_details
                                  ? item.product_details.name
                                  : "N/A"}
                              </h4>

                              <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                                <p>
                                  {item.vendor_price_details
                                    ? item.vendor_price_details.vendor_name
                                    : ""}
                                </p>
                                <p>|</p>
                                <p>
                                  <i className="mdi mdi-currency-ngn"></i>
                                  {item.product_details.display_price}/
                                  {item.product_details.product_size}
                                  {item.product_details.measurement}
                                </p>
                              </div>
                            </div>
                            <div>
                              <button
                                className="text-primary ctaView
                                            text-xs font-medium"
                              >
                                View Product
                              </button>
                            </div>
                          </div>
                        ))
                      : ""
                    : ""}
                </div>

                {/* Advisory products */}
                <div className="mb-3">
                  {groupByResult && groupByResult.AdvisoryProducts ? (
                    groupByResult.AdvisoryProducts.length > 0 ? (
                      <h5 className="text-[#344335] text-sm font-semibold">
                        Advisory
                      </h5>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {groupByResult && groupByResult.AdvisoryProducts
                    ? groupByResult.AdvisoryProducts.length > 0
                      ? groupByResult.AdvisoryProducts.map((item, index) => (
                          <div
                            onClick={() =>
                              handleViewProduct(
                                item.product_details.product_type,
                                item.vendor_price_details.product_id
                              )
                            }
                          >
                            <div>
                              <h4 className="text-[#344335] text-sm font-semibold">
                                {item.product_details
                                  ? item.product_details.name
                                  : "N/A"}
                              </h4>

                              <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                                <p>{item.vendor_price_details.vendor_name}</p>
                                <p>|</p>
                                <p>
                                  <i className="mdi mdi-currency-ngn"></i>
                                  {item.product_details.display_price}/
                                  {item.product_details.product_size}
                                  {item.product_details.measurement}
                                </p>
                              </div>
                            </div>
                            <div>
                              <button
                                className="text-primary ctaView
                                            text-xs font-medium"
                              >
                                View Product
                              </button>
                            </div>
                          </div>
                        ))
                      : ""
                    : ""}
                </div>

                {/* Mechanization */}
                <div className="mb-3">
                  {groupByResult && groupByResult.MechanizationProducts ? (
                    groupByResult.MechanizationProducts.length > 0 ? (
                      <h5 className="text-[#344335] text-sm font-semibold">
                        Mechanization
                      </h5>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {groupByResult && groupByResult.MechanizationProducts
                    ? groupByResult.MechanizationProducts.length > 0
                      ? groupByResult.MechanizationProducts.map(
                          (item, index) => (
                            <div
                              key={index}
                              className="mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer"
                            >
                              <div>
                                <h4 className="text-[#344335] text-sm font-semibold">
                                  {item.product_details
                                    ? item.product_details.name
                                    : "N/A"}
                                </h4>

                                <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                                  <p>{item.vendor_price_details.vendor_name}</p>
                                  <p>|</p>
                                  <p>
                                    <i className="mdi mdi-currency-ngn"></i>
                                    {item.product_details.display_price}/
                                    {item.product_details.product_size}
                                    {item.product_details.measurement}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <button
                                  onClick={() =>
                                    handleViewProduct(
                                      item.product_details.product_type,
                                      item.vendor_price_details.product_id
                                    )
                                  }
                                  className="text-primary ctaView
                                            text-xs font-medium"
                                >
                                  View Product
                                </button>
                              </div>
                            </div>
                          )
                        )
                      : ""
                    : ""}
                </div>

                {/* Insurance */}
                <div className="mb-3">
                  {groupByResult && groupByResult.InsuranceProducts ? (
                    groupByResult.InsuranceProducts.length > 0 ? (
                      <h5 className="text-[#344335] text-sm font-semibold">
                        Insurance
                      </h5>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {groupByResult && groupByResult.InsuranceProducts
                    ? groupByResult.InsuranceProducts.length > 0
                      ? groupByResult.InsuranceProducts.map((item, index) => (
                          <div
                            onClick={() =>
                              handleViewProduct(
                                item.product_details.product_type,
                                item.vendor_price_details.product_id
                              )
                            }
                          >
                            <div>
                              <h4 className="text-[#344335] text-sm font-semibold">
                                {item.product_details
                                  ? item.product_details.name
                                  : "N/A"}
                              </h4>

                              <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                                <p>{item.vendor_price_details.vendor_name}</p>
                                <p>|</p>
                                <p>
                                  <i className="mdi mdi-currency-ngn"></i>
                                  {item.product_details.display_price}/
                                  {item.product_details.product_size}
                                  {item.product_details.measurement}
                                </p>
                              </div>
                            </div>
                            <div>
                              <button
                                className="text-primary ctaView
                                            text-xs font-medium"
                              >
                                View Product
                              </button>
                            </div>
                          </div>
                        ))
                      : ""
                    : ""}
                </div>
              </div>
            )}

            {/* ---------------- Second Section ------------------------------------------------- */}
            {/* GO BACK SECTION */}
            {!isPageOpen && (
              <div className="max-h-[300px] overflow-y-auto call2orderOverflow">
                <div>
                  <div
                    onClick={handleBack}
                    className="flex items-center cursor-pointer"
                  >
                    <img
                      src={go_back}
                      alt="go back"
                      className="w-[20px] h-[20px]"
                    />
                    <p className="text-sm font-semibold text-[#5C715E] ml-[4px] ">
                      Go Back
                    </p>
                  </div>

                  <div className="bg-[#edf7ee] rounded-lg mt-4 flex justify-between py-4 px-4">
                    <div>
                      <h6 className="text-[#344335] text-sm font-semibold">
                        {productInfo && productInfo.product_details
                          ? productInfo.product_details.name || productInfo.name
                          : "N/A"}{" "}
                      </h6>
                      <p className="text-[#344335] text-sm font-medium">
                        {productInfo && productInfo.product_details
                          ? productInfo.product_details.description
                          : "N/A"}
                      </p>

                      <div className="mt-3">
                        <p className="text-[#7c8d7e] text-[13px] ">Type</p>
                        <p className="text-[#344335] text-sm font-medium">
                          {productInfo && productInfo.product_details
                            ? productInfo.product_details.product_type
                            : "N/A"}
                        </p>
                      </div>

                      <div className="mt-3">
                        <p className="text-[#7c8d7e] text-[13px] ">Size</p>
                        <p className="text-[#344335] text-sm font-medium">
                          {productInfo && productInfo.product_details
                            ? productInfo.product_details.product_size
                            : "N/A"}
                          {productInfo && productInfo.product_details
                            ? productInfo.product_details.measurement
                            : "N/A"}
                        </p>
                      </div>

                      <div className="mt-3">
                        <p className="text-[#7c8d7e] text-[13px] ">Units</p>
                        <p className="text-[#344335] text-sm font-medium">
                          {productInfo && productInfo.product_details
                            ? productInfo.product_details.units
                            : "N/A"}
                        </p>
                      </div>

                      <div className="mt-3">
                        <p className="text-[#7c8d7e] text-[13px] ">Vendor</p>
                        <p className="text-[#344335] text-sm font-medium">
                          {productInfo && productInfo.vendor_price_details
                            ? productInfo.vendor_price_details.vendor_name
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div></div>
                  </div>

                  <ul className="mt-6 flex gap-4 items-center">
                    <li
                      className={`${
                        selectedAtLasTab === "Product_details"
                          ? "text-[#2B8C34] text-sm border-b-2 border-primary"
                          : "text-[#96A397] text-sm"
                      }  font-semibold cursor-pointer`}
                      onClick={() => setSelectedAtLasTab("Product_details")}
                    >
                      Product Details
                    </li>
                    <li
                      className={`${
                        selectedAtLasTab === "pricing_and_Sizing"
                          ? "text-[#2B8C34] text-sm border-b-2 border-primary"
                          : "text-[#96A397] text-sm"
                      }  font-semibold cursor-pointer`}
                      onClick={() => setSelectedAtLasTab("pricing_and_Sizing")}
                    >
                      Pricing and Sizing
                    </li>
                  </ul>

                  {selectedAtLasTab === "Product_details" ? (
                    <div className="grid grid-cols-2 gap-4 mt-5">
                      <div className=" rounded-lg  py-4 pr-4">
                        <div className="">
                          <p className="text-[#7c8d7e] text-[13px] ">
                            Product Class
                          </p>
                          <p className="text-[#344335] text-sm font-medium">
                            {productInfo?.product_details?.product_class ||
                              "N/A"}
                          </p>
                        </div>

                        <div className="mt-3">
                          <p className="text-[#7c8d7e] text-[13px] ">
                            Dose rate
                          </p>
                          <p className="text-[#344335] text-sm font-medium">
                            {productInfo && productInfo.product_details
                              ? productInfo.product_details.dose_rate
                              : "N/A"}
                          </p>
                        </div>

                        <div className="mt-3">
                          <p className="text-[#7c8d7e] text-[13px] ">
                            Target Crops
                          </p>
                          <p className="text-[#344335] text-sm font-medium">
                            All Crops
                          </p>
                        </div>

                        <div className="mt-3">
                          <p className="text-[#7c8d7e] text-[13px] ">
                            Time of application
                          </p>
                          <p className="text-[#344335] text-sm font-medium">
                            {productInfo?.product_details
                              ?.time_of_application || "N/A"}
                          </p>
                        </div>
                      </div>
                      <div className=" rounded-lg py-4 px-4 h-fit">
                        <div>
                          <p className="text-[#7c8d7e] text-[13px] ">
                            Mixing and application procedure
                          </p>
                          <p className="text-[#344335] text-sm font-medium">
                            {productInfo?.product_details?.procedure || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : selectedAtLasTab === "pricing_and_Sizing" ? (
                    <div className="mt-5">
                      <div className="">
                        <p className="text-primary font-semibold text-sm leading-[18px] pb-[4px]">
                          Location
                        </p>
                        <p className="text-[#344335] font-medium text-sm leading-5 pb-5">
                          N/A
                        </p>
                      </div>
                      <p className="text-primary font-semibold text-sm leading-[18px] pb-[8px]">
                        Pricing
                      </p>
                      <div className="flex gap-[102px]">
                        <div className=" rounded-lg pb-4 pr-4">
                          <div className="">
                            <p className="text-[#7c8d7e] text-[13px] ">
                              Cost Price
                            </p>
                            <p className="text-[#344335] text-sm font-medium">
                              ₦
                              {productInfo?.product_details?.cost_price ||
                                "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className=" rounded-lg pb-4 px-4 h-fit">
                          <div className="">
                            <p className="text-[#7c8d7e] text-[13px] ">
                              Display Price
                            </p>
                            <p className="text-[#344335] text-sm font-medium">
                              ₦
                              {productInfo?.product_details?.display_price ||
                                "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="mt-[12px]">
                  <ButtonPrimary
                    style={{ width: "100%" }}
                    onclick={() =>
                      handleAddOrder(
                        productInfo?.product_details?.id,
                        productInfo?.product_details?.name,
                        productInfo?.vendor_price_details.vendor_name,
                        productInfo?.product_details?.display_price,
                        productInfo?.product_details?.product_size,
                        productInfo?.product_details?.measurement,
                        productInfo?.product_details?.product_type
                      )
                    }
                  >
                    Add Order
                  </ButtonPrimary>
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalComponent>

      {/* DELETE ITEM MODAL */}
      <ModalComponent
        title="You are about to remove an item"
        show={openRemoveItemModal}
        showModal={() => setOpenRemoveItemModal(false)}
        subtitle={
          "If you delete the item" +
          " " +
          `"${clickedProduct}"` +
          " " +
          "it would be remove from the the item summary"
        }
      >
        <div className="flex items-center justify-end gap-[46px] mt-[43px] cursor-pointer">
          <p
            className="font-medium text-sm leading-5 text-[#5C715E]"
            onClick={() => setOpenRemoveItemModal(false)}
          >
            Cancel
          </p>
          <button
            className="outline-none bg-[#B92043] rounded-[8px] font-medium text-sm leading-5 text-white w-[137px] h-[40px]"
            onClick={handleDeleteItemFronCart}
          >
            Delete
          </button>
        </div>
      </ModalComponent>

      {/* CHECKOUT MODAL ONE */}
      <ModalComponent
        title="Checkout"
        show={isClickCheckout}
        showModal={() => setIsClickCheckout(false)}
      >
        <div className="mt-[12px]">
          <div className="flex gap-[12px] mb-5">
            {tab.map((data, index) => {
              return (
                <p
                  className={`font-semibold text-xs leading-4 ${
                    data === clickedTab
                      ? "text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-default"
                      : "text-[#96A397] cursor-pointer "
                  } `}
                  key={index}
                  // onClick={() => setClickedTab(data)}
                >
                  {data}
                </p>
              );
            })}
          </div>
          {clickedTab === "Delivery Address" ? (
            <>
              {/* STATE AND LGA */}
              <div className="flex gap-[24px]">
                <div className="pb-4 w-1/2">
                  <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                    State
                  </p>
                  <div className="relative w-full">
                    <div
                      className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                      onClick={handleStateClick}
                    >
                      <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                        {selectedState.name || "Select a State"}
                      </p>
                      <ExpandMore
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                    {isClickState && (
                      <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                        {stateData?.map((data, index) => {
                          return (
                            <p
                              className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                              key={index}
                              onClick={() => handleSelectedState(data)}
                            >
                              {data.display_name}
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                <div className="pb-4 w-1/2">
                  <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                    LGA
                  </p>
                  <div className="relative w-full">
                    <div
                      className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                      onClick={handleLgaClick}
                    >
                      <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                        {selectedLGA.name || "Select Lga"}
                      </p>
                      <ExpandMore
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                    {isClickLGA && (
                      <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                        {lgaData?.map((data, index) => {
                          return (
                            <p
                              className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                              key={index}
                              onClick={() => {
                                setSelectedLGA({
                                  key: data.id,
                                  name: data.display_name,
                                });
                                setIsClickLGA(false);
                              }}
                            >
                              {data.display_name}
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* DELIVERY STATUS */}
              <div className="flex gap-[24px] mb-[52px]">
                <div className="pb-5 w-full">
                  <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                    Delivery Address
                  </p>
                  <textarea
                    id="address"
                    rows="6"
                    // status={summarystatus}
                    // onChange={handleSummaryChange}
                    className="appearance-none w-full
                  placeholder:text-[#96A397] placeholder:text-sm
                      text-[#5C715E]
                      text-sm resize-none
                      focus:border-[#2B8C34] focus:outline-none  
                      focus:shadow-input rounded-lg border border-[#96A397]
                      bg-white py-3 px-4"
                    placeholder="Delivery Address"
                    onChange={(event) => setDeliveryAddress(event.target.value)}
                  ></textarea>
                </div>
              </div>

              <button
                type="submit"
                disabled={
                  selectedState?.name !== "" &&
                  selectedLGA?.name !== "" &&
                  deliveryAddress !== ""
                    ? false
                    : true
                }
                onClick={
                  selectedState?.name !== "" &&
                  selectedLGA?.name !== "" &&
                  deliveryAddress !== ""
                    ? () => setClickedTab("Payment Method")
                    : null
                }
                className="bg-primary w-full
                              disabled:bg-[#ABB6AC] 
                              disabled:text-white
                              rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
              >
                Next
              </button>
            </>
          ) : clickedTab === "Payment Method" ? (
            <div>
              <div
                className={`rounded-[8px] py-[12px] px-[16px] cursor-pointer mb-[16px] ${
                  clickedPayment === "Instant Payment"
                    ? "bg-[#EDF7EE] border border-[#2B8C34] rounded-[8px] "
                    : "bg-[#F7F7F7] border rounded-[8px]"
                }`}
                onClick={() => setClickedPayment("Instant Payment")}
              >
                <p
                  className={`text-[#344335] text-sm font-semibold pb-[4px] leading-5 ${
                    clickedPayment === "Instant Payment"
                      ? "  text-primary"
                      : " text-[#344335]"
                  }`}
                >
                  Instant Payment
                </p>
                <p
                  className={`text-[#5C715E] text-sm font-medium leading-5 ${
                    clickedPayment === "Instant Payment"
                      ? "  text-primary"
                      : " text-[#344335]"
                  }`}
                >
                  Payment is accepted when order is placed
                </p>
              </div>

              <div
                className={`rounded-[8px] py-[12px] px-[16px] cursor-pointer mb-[16px] ${
                  clickedPayment === "Payment On Delivery"
                    ? "bg-[#EDF7EE] border border-[#2B8C34] rounded-[8px] "
                    : "bg-[#F7F7F7] border rounded-[8px]"
                }`}
                onClick={() => setClickedPayment("Payment On Devlivery")}
              >
                <p
                  className={`text-[#344335] text-sm font-semibold pb-[4px] leading-5 ${
                    clickedPayment === "Payment On Devlivery"
                      ? "  text-primary"
                      : " text-[#344335]"
                  }`}
                >
                  Payment on the delivery
                </p>
                <p
                  className={`text-[#5C715E] text-sm font-medium leading-5 ${
                    clickedPayment === "Payment On Devlivery"
                      ? "  text-primary"
                      : " text-[#344335]"
                  }`}
                >
                  Payment is held then accepted when the order is received
                </p>
              </div>

              <button
                type="submit"
                disabled={clickedPayment !== "" ? false : true}
                onClick={
                  clickedPayment !== ""
                    ? () => setClickedTab("Confirm Order")
                    : null
                }
                className="bg-primary w-full mt-[100px]
                              disabled:bg-[#ABB6AC] 
                              disabled:text-white
                              rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
              >
                Next
              </button>
            </div>
          ) : clickedTab === "Confirm Order" ? (
            <div>
              <div
                className={`rounded-[8px] py-[12px] px-[16px] cursor-pointer bg-[#EDF7EE] mb-[8px]`}
              >
                <p className={`text-sm font-semibold leading-5 text-primary`}>
                  {clickedPayment}
                </p>
              </div>

              <div className="bg-[#F7F7F7] rounded-[8px] p-2 mb-[20px]">
                <p className="font-medium text-xs leading-4 text-[#96A397] pb-[2px]">
                  Delivery Address{" "}
                </p>
                <p className="font-semibold text-sm leading-5 text-[#344335] pb-[2px]">
                  {deliveryAddress}
                </p>
                <p className="font-medium text-xs leading-4 text-[#344335] pb-[2px]">
                  {selectedState?.name} | {selectedLGA?.name}
                </p>
              </div>

              <div className="">
                <>
                  {/* INPUT ORDERS */}
                  {callToOrderInfoData?.input_order?.map((data, index) => {
                    return (
                      <>
                        <div
                          className="flex justify-between mb-[12px]"
                          key={index}
                        >
                          <div className="flex gap-2">
                            <div className="bg-primary rounded-[4px] items-center justify-center flex w-[20px] h-[22px] font-medium text-sm leading-[18px] text-white">
                              <p>{index + 1}</p>
                            </div>
                            <div className="">
                              <p className="font-semibold text-sm leading-5 text-[#344335] pb-1">
                                {data?.input_product_name}
                                {/* <span className="font-medium">
                                  {' '}
                                  | {data?.product_size}
                                  {data.measurement}
                                </span> */}
                              </p>
                              <p className="font-medium text-sm leading-5 text-[#344335] pb-1">
                                Crop2cash
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              ₦{data?.input_product_display_price}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {/* LIVESTOCK ORDERS */}
                  {callToOrderInfoData?.livestock_order?.map((data, index) => {
                    return (
                      <>
                        <div
                          className="flex justify-between mb-[12px]"
                          key={index}
                        >
                          <div className="flex gap-2">
                            <div className="bg-primary rounded-[4px] items-center justify-center flex w-[20px] h-[22px] font-medium text-sm leading-[18px] text-white">
                              <p>{index + 1}</p>
                            </div>
                            <div className="">
                              <p className="font-semibold text-sm leading-5 text-[#344335] pb-1">
                                {data?.input_product_name}
                                {/* <span className="font-medium">
                                  {' '}
                                  | {data?.product_size}
                                  {data.measurement}
                                </span> */}
                              </p>
                              <p className="font-medium text-sm leading-5 text-[#344335] pb-1">
                                Crop2cash
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              ₦{data?.input_product_display_price}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {/* ADVISORY ORDERS */}
                  {callToOrderInfoData?.advisory_order?.map((data, index) => {
                    return (
                      <>
                        <div
                          className="flex justify-between mb-[12px]"
                          key={index}
                        >
                          <div className="flex gap-2">
                            <div className="bg-primary rounded-[4px] items-center justify-center flex w-[20px] h-[22px] font-medium text-sm leading-[18px] text-white">
                              <p>{index + 1}</p>
                            </div>
                            <div className="">
                              <p className="font-semibold text-sm leading-5 text-[#344335] pb-1">
                                {data?.advisory_product_name}
                                {/* <span className="font-medium">
                                  {' '}
                                  | {data?.product_size}
                                  {data.measurement}
                                </span> */}
                              </p>
                              <p className="font-medium text-sm leading-5 text-[#344335] pb-1">
                                Crop2cash
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              ₦{data?.input_product_display_price}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {/* INSURANCE ORDERS */}
                  {callToOrderInfoData?.insurance_order?.map((data, index) => {
                    return (
                      <>
                        <div
                          className="flex justify-between mb-[12px]"
                          key={index}
                        >
                          <div className="flex gap-2">
                            <div className="bg-primary rounded-[4px] items-center justify-center flex w-[20px] h-[22px] font-medium text-sm leading-[18px] text-white">
                              <p>{index + 1}</p>
                            </div>
                            <div className="">
                              <p className="font-semibold text-sm leading-5 text-[#344335] pb-1">
                                {data?.insurance_product_name}
                                {/* <span className="font-medium">
                                  {' '}
                                  | {data?.product_size}
                                  {data.measurement}
                                </span> */}
                              </p>
                              <p className="font-medium text-sm leading-5 text-[#344335] pb-1">
                                Crop2cash
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              ₦{data?.input_product_display_price}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {/* MECHANIZATION ORDERS */}
                  {callToOrderInfoData?.mechanization_order?.map(
                    (data, index) => {
                      return (
                        <>
                          <div
                            className="flex justify-between mb-[12px]"
                            key={index}
                          >
                            <div className="flex gap-2">
                              <div className="bg-primary rounded-[4px] items-center justify-center flex w-[20px] h-[22px] font-medium text-sm leading-[18px] text-white">
                                <p>{index + 1}</p>
                              </div>
                              <div className="">
                                <p className="font-semibold text-sm leading-5 text-[#344335] pb-1">
                                  {data?.mechanization_product_name}
                                  {/* <span className="font-medium">
                                  {' '}
                                  | {data?.product_size}
                                  {data.measurement}
                                </span> */}
                                </p>
                                <p className="font-medium text-sm leading-5 text-[#344335] pb-1">
                                  Crop2cash
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                ₦{data?.input_product_display_price}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    }
                  )}
                </>
                {cart?.map((data, index) => {
                  return (
                    <div className="flex justify-between mb-[12px]" key={index}>
                      <div className="flex gap-2">
                        <div className="bg-primary rounded-[4px] items-center justify-center flex w-[20px] h-[22px] font-medium text-sm leading-[18px] text-white">
                          <p>{index + 1}</p>
                        </div>
                        <div className="">
                          <p className="font-semibold text-sm leading-5 text-[#344335] pb-1">
                            {data?.product_name}
                            <span className="font-medium">
                              {" "}
                              | {data?.product_size}
                              {data.measurement}
                            </span>
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335] pb-1">
                            {data?.vendor_name}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <p className="font-medium text-sm leading-5 text-[#344335]">
                          ₦{data?.price}
                        </p>
                      </div>
                    </div>
                  );
                })}

                <div className="flex justify-end pt-1 border-t-[2px] border-t-[#ECEEEC] mb-[38px]">
                  <p className="font-medium text-sm leading-5 text-[#7C8D7E]">
                    Subtotal:
                    <span className="font-semibold text-primary text-base">
                      {" "}
                      ₦{totalPrice}
                    </span>
                  </p>
                </div>

                <button
                  type="submit"
                  disabled={false}
                  onClick={handleCheckout}
                  className="bg-primary w-full
                              disabled:bg-[#ABB6AC] 
                              disabled:text-white
                              rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Next
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </ModalComponent>

      {/* SHORT CODE MODAL */}
      <ModalComponent
        show={clickShortCode ? false : isShortCodeClick}
        showModal={handleCloseShortCode}
      >
        <div className="flex flex-col justify-center items-center">
          <ShoppingCart style={{ fontSize: "24px", color: "#2B8C34" }} />
          <p className="font-semibold text-base leading-6 text-[#344335] pt-[19px]">
            {isAddToCart
              ? "Order has been added to cart"
              : "  Order has been sent"}
          </p>

          {isAddToCart ? null : (
            <>
              {" "}
              <p className="font-medium text-xs leading-4 text-[#5C715E] pt-[8px]">
                A Short Code has been sent to hold payment
              </p>
              <p className="font-semibold text-xl leading-5 text-primary pt-[8px]">
                *346*46*{updateCallToOrderShortCode}#
              </p>
            </>
          )}

          <div className="flex gap-1 text-[#0066AF] bg-[#E7FBFE] font-medium text-xs leading-4 items-center p-2 mt-5">
            <ErrorOutline style={{ fontSize: "16px" }} />
            <p>You can track the payment status on the order history</p>
          </div>

          <p
            className="font-medium text-sm leading-5 text-[#5C715E] pt-[20px] mb-[44px] cursor-pointer"
            onClick={handleCloseShortCode}
          >
            Close
          </p>
        </div>
      </ModalComponent>
    </div>
  );
};

export default Cart;
