import React, { useMemo, useState, useCallback, useEffect } from "react";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../../components/Pagination/Pagination";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { fetchLoanOfficerLoans } from "../../../redux/Loans/loanActions";
import { useNavigate, useParams } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";
import moment from "moment";

const LoanAgentLoanApplicationPage = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const { loader, loanofficerLoans, loanofficerLoansCount } = useSelector(
    (state) => state.loan
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(
      fetchLoanOfficerLoans({
        id,
        page: page,
      })
    );
  };

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        // dispatch(
        //     searchCallRequests({
        //       val: val,
        //       status: 'all',
        //     }),
        //   )
        console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const getLoanBg = (val) => {
    let result;
    switch (val) {
      case "pending":
        result = "pending-badge";
        break;
      case "inactive":
        result = "error-badge";
        break;
      case "active":
        result = "success-badge";
        break;
      case "completed":
        result = "success-badge";
        break;
      case "approved":
        result = "success-badge";
        break;
      case "rejected":
        result = "error-badge";
        break;
      default:
        result = "error-badge";
        break;
    }
    return result;
  };

  const getLoanText = (val) => {
    let result;
    switch (val) {
      case "pending":
        result = "Pending";
        break;
      case "inactive":
        result = "Inactive";
        break;
      case "active":
        result = "Active";
        break;
      case "completed":
        result = "Completed";
        break;
      case "approved":
        result = "Approved";
        break;
      case "rejected":
        result = "Rejected";
        break;
      default:
        result = "Pending";
        break;
    }
    return result;
  };

  useEffect(() => {
    dispatch(
      fetchLoanOfficerLoans({
        id,
        page: 1,
      })
    );
  }, [dispatch, id]);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Loan Application
          </p>

          {/* search */}
          <div className="mt-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          {loader ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {loanofficerLoans?.length === 0 ? (
                <EmptyState text="You don't have any loan aplication(s) available yet" />
              ) : (
                <table className="w-full text-left">
                  <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                    <tr>
                      <th scope="col" className="px-4 py-4 font-semibold ">
                        Date Created
                      </th>

                      <th scope="col" className="px-4 py-4 font-semibold">
                        Farmer's Name
                      </th>

                      <th scope="col" className="px-4 py-4 font-semibold">
                        Cooperative Name
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Loan Amount
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Loan Package
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Loan Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loanofficerLoans.length > 0
                      ? loanofficerLoans.map((item) => (
                          <tr
                            className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                            onClick={() =>
                              navigate(`/agrofastcash/loan-review/${item?.id}`)
                            }
                          >
                            <th
                              scope="row"
                              className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                            >
                              {moment(item._created).format("Do, MMM YYYY")}
                            </th>

                            <td className="py-4 px-4 text-[#5C715E]">
                              {item.farmer.farmer_name}
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              {item.cooperative.cooperative_name}
                            </td>
                            <td className="py-4 px-4">
                              ₦{item.loan_principal}
                            </td>
                            <td className="py-4 px-4">
                              {item.loan_package.loan_package_name}
                            </td>
                            <td className="py-4 px-4">
                              <div className="flex">
                                <div className={getLoanBg(item.loan_status)}>
                                  <p>{getLoanText(item.loan_status)}</p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={loanofficerLoansCount}
        />
      </div>
    </>
  );
};

export default LoanAgentLoanApplicationPage;
