import { createSlice } from "@reduxjs/toolkit";
import {
  createMessageTemplate,
  deleteTemplate,
  fetchFarmersCountByQuery,
  getMessages,
  getMessageTemplate,
  getMessageTemplateCount,
  getPendingMessages,
  messageApproval,
  sendBulkSMS,
  sendSingleMessage,
  updateMessageTemplate,
} from "./messageActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  value: 0,
  loader: false,
  loading: false,
  approvalLoading: false,
  count: 0,
  messages: [],
  pendingMessages: [],
  templates: [],
  total: 0,
  pendingTotal: 0,
  templateTotal: 0,
  templateLoading: false,
  templateData: [],
  deleteTemplateSuccess: false,
  createTemplateSuccess: false,
  updateTemplateSuccess: false,
  estimateCount: 0,
  smsLoader: false,
  isMessageSent: false,
  sendMessageLoading: false,
};

const messagePersistConfig = {
  key: "messages",
  storage,
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    clearDeleteTemplateSuccess: (state) => {
      state.deleteTemplateSuccess = false;
    },
    clearCreateTemplateSuccess: (state) => {
      state.createTemplateSuccess = false;
    },
    clearUpdateTemplateSuccess: (state) => {
      state.updateTemplateSuccess = false;
    },
    clearIsMessageSuccess: (state) => {
      state.isMessageSent = false;
    },
  },
  extraReducers: {
    // fetch message template count ----------
    [getMessageTemplateCount.pending]: (state) => {
      state.loader = true;
    },
    [getMessageTemplateCount.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.templates = payload.data;
      state.count = payload.count;
    },
    [getMessageTemplateCount.rejected]: (state) => {
      state.loader = false;
    },
    // fetch messages ----------
    [getMessages.pending]: (state) => {
      state.loading = true;
    },
    [getMessages.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.messages = payload.data;
      state.total = payload.count;
    },
    [getMessages.rejected]: (state) => {
      state.loading = false;
    },

    // fetch pending messages ----------
    [getPendingMessages.pending]: (state) => {
      state.loading = true;
    },
    [getPendingMessages.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pendingMessages = payload.data;
      state.pendingTotal = payload.count;
    },
    [getPendingMessages.rejected]: (state) => {
      state.loading = false;
    },

    // messages approval ----------
    [messageApproval.pending]: (state) => {
      state.approvalLoading = true;
    },
    [messageApproval.fulfilled]: (state, { payload }) => {
      state.approvalLoading = false;
    },
    [messageApproval.rejected]: (state) => {
      state.approvalLoading = false;
    },

    // fetch templates ----------
    [getMessageTemplate.pending]: (state) => {
      state.templateLoading = true;
    },
    [getMessageTemplate.fulfilled]: (state, { payload }) => {
      state.templateLoading = false;
      state.templateData = payload.data;
      state.templateTotal = payload.count;
    },
    [getMessageTemplate.rejected]: (state) => {
      state.templateLoading = false;
    },
    // fetch messages ----------
    [getMessages.pending]: (state) => {
      state.loading = true;
    },
    [getMessages.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.messages = payload.data;
      state.total = payload.count;
    },
    [getMessages.rejected]: (state) => {
      state.loading = false;
    },
    // delete template ----------
    [deleteTemplate.pending]: (state) => {
      state.loading = true;
    },
    [deleteTemplate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.deleteTemplateSuccess = true;
    },
    [deleteTemplate.rejected]: (state) => {
      state.loading = false;
    },
    // create message template ----------
    [createMessageTemplate.pending]: (state) => {
      state.loading = true;
    },
    [createMessageTemplate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.createTemplateSuccess = true;
    },
    [createMessageTemplate.rejected]: (state) => {
      state.loading = false;
    },
    // update message template
    [updateMessageTemplate.pending]: (state) => {
      state.loading = true;
    },
    [updateMessageTemplate.fulfilled]: (state) => {
      state.loading = false;
      state.updateTemplateSuccess = true;
    },
    [updateMessageTemplate.rejected]: (state) => {
      state.loading = false;
    },
    // get farmers count
    [fetchFarmersCountByQuery.pending]: (state) => {
      state.loading = true;
    },
    [fetchFarmersCountByQuery.fulfilled]: (state, { payload }) => {
      state.estimateCount = payload.data;
      state.loading = false;
    },
    [fetchFarmersCountByQuery.rejected]: (state) => {
      state.loading = false;
    },
    // send single sms
    [sendSingleMessage.pending]: (state) => {
      state.sendMessageLoading = true;
    },
    [sendSingleMessage.fulfilled]: (state, { payload }) => {
      state.sendMessageLoading = false;
    },
    [sendSingleMessage.rejected]: (state) => {
      state.sendMessageLoading = false;
    },
    // send bulk sms
    [sendBulkSMS.pending]: (state) => {
      state.smsLoader = true;
    },
    [sendBulkSMS.fulfilled]: (state, { payload }) => {
      state.smsLoader = false;
      state.isMessageSent = true;
    },
    [sendBulkSMS.rejected]: (state) => {
      state.smsLoader = false;
    },
  },
});

const messageReducer = persistReducer(
  messagePersistConfig,
  messagesSlice.reducer
);

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  clearDeleteTemplateSuccess,
  clearCreateTemplateSuccess,
  clearUpdateTemplateSuccess,
  clearIsMessageSuccess,
} = messagesSlice.actions;

export default messageReducer;
