import React, { useMemo, useState, useCallback, useEffect } from "react";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../../components/Pagination/Pagination";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { fetchLoanOfficerOverdueLoans } from "../../../redux/Loans/loanActions";
import { useParams } from "react-router-dom";

const LoanAgentOverdueLoanPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const { loader, loanofficerOverdueLoans } = useSelector((state) => state.loan);
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  const {id} = useParams()
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
  };

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        // dispatch(
        //     searchCallRequests({
        //       val: val,
        //       status: 'all',
        //     }),
        //   )
        console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(()=>{
    dispatch(fetchLoanOfficerOverdueLoans({
      id
    }))
  },[dispatch, id])

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Overdue Loan
          </p>

          {/* search */}
          <div className="mt-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Farmer ID
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Loan
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Repaid
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Arrears
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Overdue Period
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Group Name
                </th>
              </tr>
            </thead>
            {!loader && (
              <tbody>
                {loanofficerOverdueLoans.length > 0
                  ? loanofficerOverdueLoans.map((item) => (
                    <tr key={item.farmer_id} className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                    <th
                      scope="row"
                      className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                    >
                      {item.farmer_id}
                    </th>
    
                    <td className="py-4 px-4 text-[#5C715E]">{item.farmer_name}</td>
                    <td className="py-4 px-4 text-[#5C715E]">₦{item.loan_amount}</td>
                    <td className="py-4 px-4">₦{item.amount_repaid}</td>
                    <td className="py-4 px-4">₦{item.loan_amount_expected}</td>
                    <td className="py-4 px-4">{item.overdue_days} day(s)</td>
                    <td className="py-4 px-4">{item.group_name}</td>
                  </tr>
                    ))
                  : ""}
              </tbody>
            )}
            
          </table>

          {loader && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={12}
        />
      </div>
    </>
  );
};

export default LoanAgentOverdueLoanPage;
