import React from "react";

const Loading = () => {
  return (
    <div>
      <div className="my-8 mx-4">
        <p className="font-medium text-sm leading-5 text-[#344335]">
          Loading....
        </p>
      </div>
    </div>
  );
};

export default Loading;
