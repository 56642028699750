import React from 'react'
import breadcrumbIcon from '../../assets/icons/breadcrumb_icon.svg'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ breadcrumb }) => {
  return (
    <div>
      <div className="flex">
        {breadcrumb.map((data, index) => {
          return (
            <div key={index} className="flex">
              <Link
                className={
                  data.disabled ? 'cursor-pointer' : 'pointer-events-none'
                }
                to={data.href}
              >
                <p
                  className={
                    data.disabled
                      ? 'text-[#7C8D7E] font-medium text-sm  cursor-pointer'
                      : 'text-[#344335] font-semibold text-sm cursor-default'
                  }
                >
                  {data.text}
                </p>
              </Link>

              {index < breadcrumb.length - 1 && (
                <img
                  src={breadcrumbIcon}
                  alt="breadcrumb"
                  className="ml-[14px] mr-[11px]"
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Breadcrumb
