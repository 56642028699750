import React, { useState } from "react";
import PaginationComponent from "../../../components/Pagination/Pagination";
import SearchComponent from "../../../components/Search";

const MemberLogTable = () => {
  const [typeID, setTypeID] = useState(1);
  const callTypes = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Called",
    },
    {
      id: 3,
      title: "Not Called",
    },
    {
      id: 4,
      title: "Missed",
    },
  ];

  const toggleType = (id) => {
    setTypeID(id);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
  };

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Call Request
          </p>

          {/* search */}
          <div className="mt-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {callTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : "bg-[#ECEEEC] rounded-[4px] py-1 px-1 text-[#96A397] font-semibold text-[10px]"
                  }
                >
                  25
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  First Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Last Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Phone Number
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Call Category
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Date
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Time
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Call Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Celine
                </th>
                <td className="py-4 px-4 text-[#5C715E]">Avong</td>
                <td className="py-4 px-4 text-[#5C715E]">+234706082563</td>
                <td className="py-4 px-4 text-[#5C715E]">Temporary Farmers</td>
                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>Called</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Celine
                </th>
                <td className="py-4 px-4 text-[#5C715E]">Avong</td>
                <td className="py-4 px-4 text-[#5C715E]">+234706082563</td>
                <td className="py-4 px-4 text-[#5C715E]">Temporary Farmers</td>
                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="error-badge">
                      <p>Not Called</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Celine
                </th>
                <td className="py-4 px-4 text-[#5C715E]">Avong</td>
                <td className="py-4 px-4 text-[#5C715E]">+234706082563</td>
                <td className="py-4 px-4 text-[#5C715E]">Temporary Farmers</td>
                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="pending-badge">
                      <p>Missed</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={200}
        />
      </div>
    </>
  );
};

export default MemberLogTable;
