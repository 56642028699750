import React, { useEffect, useState } from "react";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVendorPayment } from "../../redux/Orders/ordersAction";
import moment from "moment";
const VendorPaymentTable = () => {
  const dispatch = useDispatch();
  const { vendorPaymentLoading, vendorPaymentData, vendorPaymentTotal } =
    useSelector((state) => state.orders);

  const navigate = useNavigate();
  const [typeID, setTypeID] = useState(1);
  const [typeTitle, setTypeTitle] = useState("Unpaid");
  const orderTypes = [
    {
      id: 1,
      title: "Unpaid",
    },
    {
      id: 2,
      title: "Paid",
    },
  ];

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const toggleType = (id, title) => {
    setTypeID(id);
    setTypeTitle(title);

    if (title === "Unpaid") {
      dispatch(getVendorPayment({ page: 1, value: false }));
    } else {
      dispatch(getVendorPayment({ page: 1, value: true }));
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    if (typeTitle === "Unpaid") {
      dispatch(getVendorPayment({ page: page, value: false }));
    } else {
      dispatch(getVendorPayment({ page: page, value: true }));
    }
  };

  const viewOrder = (id, type) => {
    const orderType = type?.toLowerCase();
    navigate(`/orders/vendor-payment/${id}/${orderType}`);
  };

  useEffect(() => {
    dispatch(getVendorPayment({ page: 1, value: false }));
  }, [dispatch]);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="w-full">
            <SearchComponent
              placeholder="Search"
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {orderTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id, item.title)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : "bg-[#ECEEEC] rounded-[4px] py-1 px-1 text-[#96A397] font-semibold text-[10px]"
                  }
                >
                  {item.id === typeID ? vendorPaymentTotal : "0"}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          {vendorPaymentLoading ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <table className="w-full text-left">
              <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                <tr>
                  <th scope="col" className="px-4 py-4 font-semibold ">
                    Order ID
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Order Date
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Vendor
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Cost
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Payment Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {vendorPaymentData?.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => viewOrder(data?.id, data?.order_type)}
                      className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                      >
                        {data?.id}
                      </th>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {moment(data.created).format("lll")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {data?.vendor || "N/A"}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        N{data?.total_cost_price || 0}
                      </td>
                      <td className="py-4 px-4">
                        <div
                          className={
                            data.paid_vendor
                              ? "flex items-center success-badge"
                              : "flex items-center error-badge"
                          }
                        >
                          <p className="capitalize">
                            {data.paid_vendor ? "Paid" : "Unpaid"}
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={vendorPaymentTotal}
        />
      </div>
    </>
  );
};

export default VendorPaymentTable;
