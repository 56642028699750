import React, { useEffect, useMemo, useState } from "react";
import TableBackground from "../../../components/TableBackground";
import { Star, Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ModalComponent from "../../../components/Modals/modal";
import { getAgent } from "../../../redux/Agents/agentsAction";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { Polygon } from "../../../components/Polygon/polygon";

const AgentAccountInformation = ({ id }) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const dispatch = useDispatch();
  const { agentData } = useSelector((state) => state.agents);
  const { is_team_lead } = useSelector((state) => state.auth);
  const [isShowAge, setIsShowAge] = useState(false);
  const [isShowBvn, setIsShowBvn] = useState(false);
  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(!show);
  };

  const center = useMemo(() => {
    if (!agentData?.farm_coordinates || !agentData?.farm_coordinates?.length)
      return { lat: 0, lng: 0 };

    const bounds = agentData?.farm_coordinates.reduce(
      (acc, coord) => {
        acc.minLat = Math.min(acc.minLat, coord.lat);
        acc.maxLat = Math.max(acc.maxLat, coord.lat);
        acc.minLng = Math.min(acc.minLng, coord.lng);
        acc.maxLng = Math.max(acc.maxLng, coord.lng);
        return acc;
      },
      {
        minLat: Infinity,
        maxLat: -Infinity,
        minLng: Infinity,
        maxLng: -Infinity,
      }
    );

    return {
      lat: (bounds.minLat + bounds.maxLat) / 2,
      lng: (bounds.minLng + bounds.maxLng) / 2,
    };
  }, [agentData?.farm_coordinates]);

  useEffect(() => {
    dispatch(getAgent({ id: id }));
  }, [dispatch, id]);

  return (
    <div>
      {" "}
      <div className="flex gap-[16px]">
        <div className="w-[75%] h-auto">
          <TableBackground
            classname="px-4 pt-4"
            style={{ borderRadius: "8px" }}
          >
            <div className="flex gap-[20px]">
              {/* 1 */}
              <div className="w-1/5">
                {/* SEX */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Agent Id
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {id || "N/A"}
                  </p>
                </div>

                {/* LGA */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    State
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.state || "N/A"}
                  </p>
                </div>
              </div>

              {/* 2 */}
              <div className="w-1/5">
                {/* SEX */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Sex
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.gender === "m" ||
                    agentData?.gender?.toLowerCase() === "male"
                      ? "Male"
                      : agentData?.gender === "f" ||
                        agentData?.gender?.toLowerCase() === "female"
                      ? "Female"
                      : "N/A"}
                  </p>
                </div>

                {/* LGA */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    LGA
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.lga || "N/A"}
                  </p>
                </div>
              </div>

              {/* 3 */}
              <div className="w-1/5">
                {/* BVN */}
                <div className="mb-[24px]">
                  <div className="flex items-center gap-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      BVN
                    </p>
                    {is_team_lead && (
                      <>
                        {isShowBvn ? (
                          <Visibility
                            onClick={
                              is_team_lead
                                ? () => setIsShowBvn(!isShowBvn)
                                : null
                            }
                            style={{ fontSize: "18px" }}
                            className="text-[#7C8D7E]"
                          />
                        ) : (
                          <VisibilityOff
                            onClick={
                              is_team_lead
                                ? () => setIsShowBvn(!isShowBvn)
                                : null
                            }
                            style={{ fontSize: "18px" }}
                            className="text-[#7C8D7E]"
                          />
                        )}
                      </>
                    )}
                  </div>
                  {isShowBvn ? (
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {agentData?.bvn || "N/A"}
                    </p>
                  ) : (
                    <p className="font-medium text-sm leading-5 text-[#344335]">
                      {agentData?.bvn ? (
                        <>
                          {" "}
                          {agentData?.bvn?.charAt(0)}**********
                          {agentData?.bvn?.charAt(10)}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </p>
                  )}
                </div>

                {/* AGE */}
                <div className="mb-[24px]">
                  <div className="flex items-center gap-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Age
                    </p>
                    {is_team_lead && (
                      <>
                        {isShowAge ? (
                          <Visibility
                            onClick={
                              is_team_lead
                                ? () => setIsShowAge(!isShowAge)
                                : null
                            }
                            style={{ fontSize: "18px" }}
                            className="text-[#7C8D7E]"
                          />
                        ) : (
                          <VisibilityOff
                            onClick={
                              is_team_lead
                                ? () => setIsShowAge(!isShowAge)
                                : null
                            }
                            style={{ fontSize: "18px" }}
                            className="text-[#7C8D7E]"
                          />
                        )}
                      </>
                    )}
                  </div>
                  {isShowAge ? (
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {agentData?.age || "N/A"}
                    </p>
                  ) : (
                    <p className="font-medium text-sm leading-5 text-[#344335]">
                      **
                    </p>
                  )}
                </div>
              </div>

              {/* 4 */}
              <div className="w-1/5">
                {/* SEX */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Account number
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.account_number || "N/A"}
                  </p>
                </div>

                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Date Of Birth
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.date_of_birth
                      ? moment(agentData?.date_of_birth).format("DD/MM")
                      : "N/A"}
                  </p>
                </div>
              </div>

              {/* 5 */}
              <div className="w-1/5">
                {/* Bank */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Bank
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.bank || "N/A"}
                  </p>
                </div>

                {/* Age */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Crop
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.crop || "N/A"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex gap-[20px]">
              {/* 1 */}
              <div className="w-1/4">
                {/* SEX */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Livestock
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.livestock || "N/A"}
                  </p>
                </div>

                {/* LGA */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Last Seen
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {moment(agentData?.updated).format("lll")}
                  </p>
                </div>
              </div>

              {/* 2 */}
              <div className="w-1/4">
                {/* Land Size */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Land Size
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {"N/A"}
                  </p>
                </div>

                {/* Registration Status */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Registration Status
                  </p>
                  <div
                    className={
                      agentData?.registration_status === "Complete"
                        ? "px-4 success-badge"
                        : agentData?.registration_status === "Incomplete"
                        ? " px-4 error-badge"
                        : " px-4 na-badge"
                    }
                  >
                    <p className="capitalize">
                      {agentData?.registration_status || "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              {/* 3 */}
              <div className="w-1/4">
                {/* Cooperative */}
                <div className="mb-[24px]">
                  <div className="flex items-center gap-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Cooperative
                    </p>
                  </div>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.cooperative_name || "N/A"}
                  </p>
                </div>

                {/*  Status */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Status
                  </p>
                  <div
                    className={
                      agentData?.status === "Active"
                        ? "px-4 success-badge"
                        : agentData?.status === "Inactive"
                        ? " px-4 error-badge"
                        : " px-4 na-badge"
                    }
                  >
                    <p className="capitalize">{agentData?.status || "N/A"}</p>
                  </div>
                </div>
              </div>

              {/* 4 */}
              <div className="w-1/4">
                {/* SEX */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Date Joined
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {moment(agentData?.created).format("lll")}
                  </p>
                </div>

                {/* Loan Status */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Loan Status
                  </p>
                  <div className="font-semibold text-sm leading-5 text-[#344335]">
                    <div
                      className={
                        agentData?.reg_status === "Complete"
                          ? "success-badge"
                          : agentData?.reg_status === "Incomplete"
                          ? "error-badge"
                          : agentData?.reg_status === "Temporary"
                          ? "pending-badge"
                          : "na-badge"
                      }
                    >
                      <p> {"N/A"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TableBackground>
        </div>

        {/* AMOUNTS(BALANCES) */}
        <div className="w-[25%]">
          <div className="h-[84px] w-full p-[16px] text-white bg-primary rounded-[8px] mb-[16px]">
            <p className="pb-[4px] text-sm font-semibold leading-[18px]">
              Total Loan Portfolio
            </p>
            <p className="pb-[4px] text-2xl font-semibold leading-[30px]">
              ₦{agentData?.total_loan_portfolio?.toLocaleString() || 0}
            </p>
          </div>

          <div className="h-[84px] w-full p-[16px] text-white bg-[#D4A407] rounded-[8px] mb-[16px]">
            <p className="pb-[4px] text-sm font-semibold leading-[18px]">
              Total Loan Recovered
            </p>
            <p className="pb-[4px] text-2xl font-semibold leading-[30px]">
              {" "}
              ₦{agentData?.total_loan_recovered?.toLocaleString() || 0}
            </p>
          </div>

          <div className="h-[84px] w-full p-[16px] text-white bg-[#B92043] rounded-[8px] ">
            <p className="pb-[4px] text-sm font-semibold leading-[18px]">
              Overdue Loan
            </p>
            <p className="pb-[4px] text-2xl font-semibold leading-[30px]">
              {" "}
              ₦{agentData?.overdue_loan?.toLocaleString() || 0}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-[28px] flex gap-6">
        <div className="p-4 bg-white w-[27%] rounded-[8px]">
          <p className="text-sm font-semibold leading-[18px] pb-6">
            Farm Details
          </p>

          <div className="mb-4">
            <p className="text-sm mb-2 text-[#7C8D7E] font-normal leading-[18px]">
              State
            </p>
            <p className="text-sm text-[##344335] font-medium leading-[18px]">
              {agentData?.farm?.state || "N/A"}
            </p>
          </div>

          <div className="mb-4">
            <p className="text-sm mb-2 text-[#7C8D7E] font-normal leading-[18px]">
              Local Government
            </p>
            <p className="text-sm text-[##344335] font-medium leading-[18px]">
              {agentData?.farm?.lga || "N/A"}
            </p>
          </div>

          <div className="mb-4">
            <p className="text-sm mb-2 text-[#7C8D7E] font-normal leading-[18px]">
              Size
            </p>
            <p className="text-sm text-[##344335] font-medium leading-[18px]">
              {agentData?.farm?.size || "N/A"}
            </p>
          </div>

          <div className="mb-4">
            <p className="text-sm mb-2 text-[#7C8D7E] font-normal leading-[18px]">
              Location Coordinate
            </p>
            {agentData?.farm?.location_coordinates?.length !== 0 ? (
              <div>
                {agentData?.farm?.location_coordinates?.map((data, index) => {
                  return (
                    <p
                      key={index}
                      className="text-sm text-[##344335] font-medium leading-[18px]"
                    >
                      Lng({data?.lng}) - Lat({data?.lat})
                    </p>
                  );
                })}
              </div>
            ) : (
              "N/A"
            )}
          </div>
        </div>

        <div className="w-[73%] max-h-[200px] bg-primary  rounded-[8px]">
          {agentData?.farm_coordinates ? (
            <APIProvider apiKey={API_KEY}>
              <div className="h-[350px] rounded-[8px] w-full">
                <Map
                  mapId="d10801052e62b784"
                  disableDefaultUI={true}
                  mapTypeId="satellite"
                  gestureHandling={"greedy"}
                  zoom={17}
                  center={center}
                >
                  <Polygon
                    strokeWeight={3}
                    paths={agentData?.farm_coordinates}
                    strokeColor={"#FFC508"}
                    strokeOpacity={1}
                    fillColor={"#FFFFFF66"}
                    fillOpacity={0.7}
                  />

                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      fontWeight: 500,
                      color: "white",
                      transform: "translate(-50%, -50%)",
                      fontSize: 12,
                      zIndex: 999,
                    }}
                  >
                    {/* {parseFloat(farmDetails?.size)?.toFixed(2) || 0} Ha */}
                  </div>
                </Map>
              </div>
            </APIProvider>
          ) : (
            <div className="w-full h-full bg-white flex justify-center items-center">
              Farm has not been captured
            </div>
          )}
        </div>
      </div>
      {/* RANK MODAL */}
      <ModalComponent
        show={show}
        showModal={showModal}
        title="Ranks"
        subtitle=""
      >
        <div className="my-[24px]">
          {/* Platinum */}
          <div className="mb-5">
            <div className="bg-[#4B4E68] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className="text-white leading-5 text-sm font-medium">
                Platinum
              </p>
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
            </div>
            <p className="text-xs font-medium leading-4 text-[#7C8D7E] pt-2">
              Average{" "}
              <span className="text-[#344335]">
                monthly transaction above ₦20,000
              </span>{" "}
              adding up to a{" "}
              <span className="text-[#344335]">
                minimum of ₦600,000 within a 12 month cycle
              </span>
            </p>
          </div>

          {/* Gold */}
          <div className="mb-5">
            <div className="bg-[#F9B43B] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className="text-white leading-5 text-sm font-medium">Gold</p>
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
            </div>
            <p className="text-xs font-medium leading-4 text-[#7C8D7E] pt-2">
              Average{" "}
              <span className="text-[#344335]">
                monthly transaction of ₦10,001 to ₦20,000
              </span>{" "}
              adding up to a{" "}
              <span className="text-[#344335]">
                minimum of ₦240,000 within a 12 month cycle
              </span>
            </p>
          </div>

          {/* Silver */}
          <div className="mb-5">
            <div className="bg-[#4B7295] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className="text-white leading-5 text-sm font-medium">Silver</p>
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
            </div>
            <p className="text-xs font-medium leading-4 text-[#7C8D7E] pt-2">
              Average{" "}
              <span className="text-[#344335]">
                monthly transaction of ₦5,001 to ₦10,000
              </span>{" "}
              adding up to a{" "}
              <span className="text-[#344335]">
                minimum of ₦120,000 within a 12 month cycle
              </span>
            </p>
          </div>

          {/* Bronxe */}
          <div className="mb-5">
            <div className="bg-[#E86454] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className="text-white leading-5 text-sm font-medium">Bronze</p>
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
            </div>
            <p className="text-xs font-medium leading-4 text-[#7C8D7E] pt-2">
              Average{" "}
              <span className="text-[#344335]">
                monthly transaction of 0.00 to ₦5,000
              </span>{" "}
              adding up to a{" "}
              <span className="text-[#344335]">
                minimum of ₦60,000 within a 12 month cycle.
              </span>
            </p>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default AgentAccountInformation;
