import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl, finagApiUrl } from "../config";
import cogoToast from "cogo-toast";


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};



export const refreshToken = createAsyncThunk(
  'refreshToken',
  async (refreshToken, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${finagApiUrl}auth-web-login/refresh/`, {
        refresh: refreshToken,
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // Handle token refresh error
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } 
      else {
         dispatch({ type: 'auth/Logout' })
        return rejectWithValue(error.response.data)
      }
    }
  }
);

export const userLogin = createAsyncThunk(
  "login",
  async ({ username, password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/signin`, {
        username: username,
        password,
      });
      if (res.status === 200) {
        // let role = res.data.user.is_sales_agent
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        cogoToast.error("Please check that the credentials entered is valid!");
        return rejectWithValue(error.response.data);
      }
      else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgot",
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/forgot-password`, {
        email: email,
      });
      if (res.status === 200) {
        cogoToast.success(res.data.message);
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("Please check that the credentials entered is valid!");
        return rejectWithValue(error.response.data);
      }

      else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "reset",
  async ({ otp, userId, password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/reset-password`, {
        otp,
        user_id: userId,
        password: password,
      });
      if (res.status === 200) {
        cogoToast.success("Password successfully changed, Login to continue", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error(
          `The link is either invalid or has expired, please request again!`
        );
        return rejectWithValue(error.response.data);
      } else if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        cogoToast.error(
          `The link is either invalid or has expired, please request again!`
        );
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async ({ old_password, new_password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/change-password`,
        {
          old_password: old_password,
          new_password: new_password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Password successfully changed, Login to continue", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("Check that your old password is correct!");
        return rejectWithValue(error.response.data);
      } else {
        cogoToast.error("Check that your old password is correct!");
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "updateUserDetails",
  async ({ first_name, last_name }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}users`,
        {
          first_name: first_name,
          last_name: last_name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Profile details updated successfully!", {
          position: "top-right",
        });
        return {
          data: res.data,
          firstname: first_name,
          lastname: last_name,
        };
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("Please check that the credentials entered is valid!");
        return rejectWithValue(error.response.data);
      }
    }
  }
);
