import React, { useState, useCallback, useMemo, useEffect } from "react";
import plus_white from "../../assets/icons/plus_white.svg";
import { debounce } from "lodash";
import search_icon from "../../assets/icons/search.svg";
import search_green from "../../assets/icons/search_green.svg";
import go_back from "../../assets/icons/goBack.svg";
import vert_green_icon from "../../assets/icons/vert_green.svg";
import { ThreeDots } from "react-loader-spinner";
import Modal from "../../components/Modals/modal";
import "./catalog.css";
import {
  atlasSearch,
  fetchTypeCategories,
} from "../../redux/Catalog/catalogActions";
import { useDispatch, useSelector } from "react-redux";
import {
  addInfoTypeCategories,
  clearSearchProducts,
} from "../../redux/Catalog/catalog";
import { useNavigate } from "react-router-dom";

const ProductCatalogPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    searchResult,
    loading,
    groupByResult,
    informationCard,
    categories,
    loader,
  } = useSelector((state) => state.catalog);

  const [types] = useState([
    {
      id: 1,
      tag: "all",
      text: "All",
    },
    {
      id: 2,
      tag: "crop",
      text: "Crop",
    },
    {
      id: 3,
      tag: "livestock",
      text: "Livestock",
    },
    {
      id: 4,
      tag: "advisory",
      text: "Advisory",
    },
    {
      id: 5,
      tag: "insurance",
      text: "Insurance",
    },
  ]);

  const [chipId, setChip] = useState(1);
  const toggleChip = (val) => {
    const { id, tag } = val;
    setChip(id === chipId ? null : id);
    console.log(tag);
    // dispatch(filterAtlasSearch(tag))
  };

  const [searchValue, setSearchValue] = useState("");
  const [isPageOpen, setPageOpen] = useState(true);
  const [productInfo, setProductInfo] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  // const [selectedCategoryID,setSelectedCategoryID] = useState(null)

  const handleBack = () => {
    setPageOpen(true);
  };

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        console.log(val);
        setPageOpen(true);
        //  api to dispatch and make the actions for search
        dispatch(atlasSearch({ val }));
      }, 750),
    [dispatch]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(!show);
  };

  const [productType] = useState([
    {
      id: 1,
      tag: "crop",
      text: "Crop",
    },
    {
      id: 2,
      tag: "livestock",
      text: "Livestock",
    },
    {
      id: 3,
      tag: "advisory",
      text: "Advisory",
    },
    {
      id: 4,
      tag: "insurance",
      text: "Insurance",
    },
    {
      id: 5,
      tag: "info",
      text: "Info Card",
    },
  ]);

  const infoType = [
    {
      id: 1,
      name: "Crop",
    },
    {
      id: 2,
      name: "Livestock",
    },
    {
      id: 3,
      name: "Advisory",
    },
    {
      id: 4,
      name: "Insurance",
    },
  ];

  const [typeId, setTypeId] = useState(1);
  const [typeName, setTypeName] = useState("Crop");
  const toggleType = (val) => {
    const { id, text } = val;
    console.log(text);
    setTypeId(id);
    setTypeName(text);
    setSelectedCategory(null);
    // setSelectedCategoryID(null)
    switch (id) {
      case 1:
        dispatch(
          fetchTypeCategories({
            val: "input_product_categories",
          })
        );
        break;
      case 2:
        dispatch(
          fetchTypeCategories({
            val: "livestock_product_categories",
          })
        );
        break;
      case 3:
        dispatch(
          fetchTypeCategories({
            val: "advisory_product_categories",
          })
        );
        break;
      case 4:
        dispatch(
          fetchTypeCategories({
            val: "insurance_product_categories",
          })
        );
        break;
      case 5:
        dispatch(addInfoTypeCategories(infoType));
        break;
      default:
        break;
    }
  };

  const pickCategory = (name, id) => {
    setSelectedCategory(name);
    // setSelectedCategoryID(id)
  };

  const handleAdd = (category) => {
    console.log(category);
    if (category === "Crop") {
      return navigate(`/input/${selectedCategory}`);
    }

    if (category === "Info Card") {
      return navigate(`/product-catalogue/addinfocard/${selectedCategory}`);
    }
  };

  const handleViewProduct = (type, id) => {
    // open next page
    setPageOpen(false);
    // filter from the search products display
    const res = searchResult.find(
      (val) =>
        val.product_details.product_type === type &&
        val.vendor_price_details.product_id === id
    );
    setProductInfo(res);
  };

  useEffect(() => {
    dispatch(
      fetchTypeCategories({
        val: "input_product_categories",
      })
    );
    dispatch(clearSearchProducts());
  }, [dispatch]);

  return (
    <>
      <Modal
        show={show}
        showModal={showModal}
        title="Add Product"
        subtitle="Please select the product type you want to add"
      >
        <ul className="flex gap-4 mt-5">
          {productType.map((val) => (
            <>
              <li
                key={val.id}
                onClick={() => {
                  toggleType(val);
                }}
                className={
                  typeId === val.id
                    ? "text-primary font-medium text-sm border-b-2  border-primary cursor-pointer"
                    : "text-[#96A397] text-sm font-medium cursor-pointer"
                }
              >
                {val.text}
              </li>
            </>
          ))}
        </ul>

        <div className="mt-5 flex gap-4 flex-wrap">
          {!loader && categories.length
            ? categories.map((item) => {
                return (
                  <div
                    key={item.id}
                    onClick={() => pickCategory(item.name, item.id)}
                    className={
                      selectedCategory === item.name
                        ? "rounded-[4px] bg-[#EDF7EE] border border-primary py-2 px-4 cursor-pointer"
                        : "rounded-[4px] border border-[#96A397] py-2 px-4 cursor-pointer"
                    }
                  >
                    <p
                      className={
                        selectedCategory === item.name
                          ? "text-primary text-sm font-medium"
                          : "text-[#96A397] text-sm font-medium"
                      }
                    >
                      {item.name}
                    </p>
                  </div>
                );
              })
            : ""}
        </div>

        {loader && (
          <div className="flex flex-col items-center h-[100px] justify-center">
            <ThreeDots
              height={40}
              width={30}
              color="#4fa94d"
              visible={true}
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}

        <div className="flex justify-end mt-6 mb-3">
          <button
            type="submit"
            onClick={() => handleAdd(typeName)}
            disabled={selectedCategory === null}
            className="bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg px-8 py-4 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Continue
          </button>
        </div>
      </Modal>

      <div className="bg-white rounded-[8px] px-4 py-3 flex justify-between items-center">
        <div>
          <h6 className="text-[#2B8C34] font-semibold text-lg">
            Product Catalogue
          </h6>
        </div>
        <div>
          <button
            onClick={showModal}
            className="w-full py-4 flex items-center justify-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
          >
            <img src={plus_white} alt="plus icon" />
            <p className="text-white font-medium text-sm ml-2">Add Product</p>
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg py-5 px-4 mt-4">
        <div>
          <p className="text-[#7C8D7E] text-sm font-semibold">Atlas</p>
        </div>

        <div className="mt-2">
          <div className="relative">
            <input
              type="text"
              onChange={handleChange}
              value={searchValue}
              className="appearance-none w-full placeholder:text-[#96A397] 
                          placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] 
                          focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-10"
              placeholder="Search for products, crops"
            />
            <img
              src={search_icon}
              alt="search"
              className="absolute top-1/2 transform -translate-y-1/2 left-4 cursor-pointer"
            />
          </div>
        </div>

        <div>
          {/* chips */}
          <ul className="mt-4 flex gap-2">
            {types.map((val) => (
              <>
                <li
                  key={val.id}
                  onClick={() => toggleChip(val)}
                  className={
                    chipId === val.id
                      ? "bg-[#DBFFDF] text-primary text-xs font-medium px-2 py-1 rounded-[4px] cursor-pointer"
                      : "bg-[#ECEEEC] text-[#96A397] text-xs font-medium px-2 py-1 rounded-[4px] cursor-pointer"
                  }
                >
                  {val.text}
                </li>
              </>
            ))}
          </ul>

          {searchValue.length > 0 ? (
            <>
              <div className="mt-4">
                <h5 className="text-[#344335] text-sm">
                  Search results for : "{searchValue}"
                </h5>
              </div>
              {informationCard.length > 0 && (
                <div className="mt-4 bg-[#EDF7EE] flex gap-4 justify-between rounded-[5px] py-5 px-5">
                  <div>
                    <h6 className="text-[#344335] font-semibold text-sm">
                      {informationCard[0].product_name}
                    </h6>
                    <p className="text-[#344335] text-sm font-medium mt-2">
                      {informationCard[0].product_information}
                    </p>
                  </div>
                  <div className="flex-shrink-0 cursor-pointer">
                    <img src={vert_green_icon} alt="vert" />
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )}

          {!loading && searchResult.length === 0 ? (
            <div className="flex justify-center items-center flex-col h-[400px]">
              <div className="flex justify-center">
                <div className="bg-[#DBFFDF] w-[60px] h-[60px] rounded-full flex justify-center items-center">
                  <img src={search_green} alt="search" />
                </div>
              </div>

              <div className="text-center mt-3">
                <p className="text-[#7C8D7E] text-xs font-medium max-w-[16rem] mx-auto">
                  Search for any product using the name of a product or crop
                </p>
              </div>
            </div>
          ) : (
            ""
          )}

          {loading &&
            ["1", "2", "3", "4", "5"].map((data, index) => (
              <div className="animate-pulse w-full mt-4" key={index}>
                <div className="h-2 bg-gray-200 rounded-lg w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              </div>
            ))}

          {/* first section ------------------- */}
          {isPageOpen && (
            <div className="mt-4">
              {/* ---Crop products map */}
              <div className="mb-3">
                {groupByResult && groupByResult.InputProducts ? (
                  groupByResult.InputProducts.length > 0 ? (
                    <h5 className="text-[#344335] text-sm font-semibold">
                      Crop
                    </h5>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {groupByResult && groupByResult.InputProducts
                  ? groupByResult.InputProducts.length > 0
                    ? groupByResult.InputProducts.map((item, index) => (
                        <div
                          key={index}
                          className="mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer"
                        >
                          <div>
                            <h4 className="text-[#344335] text-sm font-semibold">
                              {item.product_details
                                ? item.product_details.name
                                : "N/A"}
                            </h4>

                            <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                              <p>
                                {item.vendor_price_details
                                  ? item.vendor_price_details.vendor_name
                                  : ""}
                              </p>
                              <p>|</p>
                              <p>
                                <i className="mdi mdi-currency-ngn"></i>
                                {item.product_details.display_price}/
                                {item.product_details.product_size}
                                {item.product_details.measurement}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() =>
                                handleViewProduct(
                                  item.product_details.product_type,
                                  item.vendor_price_details.product_id
                                )
                              }
                              className="text-primary ctaView
                                          text-xs font-medium"
                            >
                              View Product
                            </button>
                          </div>
                        </div>
                      ))
                    : ""
                  : ""}
              </div>

              {/* --- Livestock products */}
              <div className="mb-3">
                {groupByResult && groupByResult.LivestockInputs ? (
                  groupByResult.LivestockInputs.length > 0 ? (
                    <h5 className="text-[#344335] text-sm font-semibold">
                      Livestock
                    </h5>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {groupByResult && groupByResult.LivestockInputs
                  ? groupByResult.LivestockInputs.length > 0
                    ? groupByResult.LivestockInputs.map((item, index) => (
                        <div
                          key={index}
                          className="mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer"
                        >
                          <div>
                            <h4 className="text-[#344335] text-sm font-semibold">
                              {item.product_details
                                ? item.product_details.name
                                : "N/A"}
                            </h4>

                            <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                              <p>
                                {item.vendor_price_details
                                  ? item.vendor_price_details.vendor_name
                                  : ""}
                              </p>
                              <p>|</p>
                              <p>
                                <i className="mdi mdi-currency-ngn"></i>
                                {item.product_details.display_price}/
                                {item.product_details.product_size}
                                {item.product_details.measurement}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() =>
                                handleViewProduct(
                                  item.product_details.product_type,
                                  item.vendor_price_details.product_id
                                )
                              }
                              className="text-primary ctaView
                                          text-xs font-medium"
                            >
                              View Product
                            </button>
                          </div>
                        </div>
                      ))
                    : ""
                  : ""}
              </div>

              {/* Advisory products */}
              <div className="mb-3">
                {groupByResult && groupByResult.AdvisoryProducts ? (
                  groupByResult.AdvisoryProducts.length > 0 ? (
                    <h5 className="text-[#344335] text-sm font-semibold">
                      Advisory
                    </h5>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {groupByResult && groupByResult.AdvisoryProducts
                  ? groupByResult.AdvisoryProducts.length > 0
                    ? groupByResult.AdvisoryProducts.map((item, index) => (
                        <div
                          key={index}
                          className="mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer"
                        >
                          <div>
                            <h4 className="text-[#344335] text-sm font-semibold">
                              {item.product_details
                                ? item.product_details.name
                                : "N/A"}
                            </h4>

                            <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                              <p>{item.vendor_price_details.vendor_name}</p>
                              <p>|</p>
                              <p>
                                <i className="mdi mdi-currency-ngn"></i>
                                {item.product_details.display_price}/
                                {item.product_details.product_size}
                                {item.product_details.measurement}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() =>
                                handleViewProduct(
                                  item.product_details.product_type,
                                  item.vendor_price_details.product_id
                                )
                              }
                              className="text-primary ctaView
                                          text-xs font-medium"
                            >
                              View Product
                            </button>
                          </div>
                        </div>
                      ))
                    : ""
                  : ""}
              </div>

              {/* Mechanization */}
              <div className="mb-3">
                {groupByResult && groupByResult.MechanizationProducts ? (
                  groupByResult.MechanizationProducts.length > 0 ? (
                    <h5 className="text-[#344335] text-sm font-semibold">
                      Mechanization
                    </h5>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {groupByResult && groupByResult.MechanizationProducts
                  ? groupByResult.MechanizationProducts.length > 0
                    ? groupByResult.MechanizationProducts.map((item, index) => (
                        <div
                          key={index}
                          className="mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer"
                        >
                          <div>
                            <h4 className="text-[#344335] text-sm font-semibold">
                              {item.product_details
                                ? item.product_details.name
                                : "N/A"}
                            </h4>

                            <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                              <p>{item.vendor_price_details.vendor_name}</p>
                              <p>|</p>
                              <p>
                                <i className="mdi mdi-currency-ngn"></i>
                                {item.product_details.display_price}/
                                {item.product_details.product_size}
                                {item.product_details.measurement}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() =>
                                handleViewProduct(
                                  item.product_details.product_type,
                                  item.vendor_price_details.product_id
                                )
                              }
                              className="text-primary ctaView
                                          text-xs font-medium"
                            >
                              View Product
                            </button>
                          </div>
                        </div>
                      ))
                    : ""
                  : ""}
              </div>

              {/* Insurance */}
              <div className="mb-3">
                {groupByResult && groupByResult.InsuranceProducts ? (
                  groupByResult.InsuranceProducts.length > 0 ? (
                    <h5 className="text-[#344335] text-sm font-semibold">
                      Insurance
                    </h5>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {groupByResult && groupByResult.InsuranceProducts
                  ? groupByResult.InsuranceProducts.length > 0
                    ? groupByResult.InsuranceProducts.map((item, index) => (
                        <div
                          key={index}
                          className="mt-1 ml-2 product hover:bg-[#EDF7EE] flex justify-between items-center hover:rounded-[4px] transition-all py-2 px-3 cursor-pointer"
                        >
                          <div>
                            <h4 className="text-[#344335] text-sm font-semibold">
                              {item.product_details
                                ? item.product_details.name
                                : "N/A"}
                            </h4>

                            <div className="flex gap-2 mt-1 text-[#5C715E] font-medium text-xs">
                              <p>{item.vendor_price_details.vendor_name}</p>
                              <p>|</p>
                              <p>
                                <i className="mdi mdi-currency-ngn"></i>
                                {item.product_details.display_price}/
                                {item.product_details.product_size}
                                {item.product_details.measurement}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() =>
                                handleViewProduct(
                                  item.product_details.product_type,
                                  item.vendor_price_details.product_id
                                )
                              }
                              className="text-primary ctaView
                                          text-xs font-medium"
                            >
                              View Product
                            </button>
                          </div>
                        </div>
                      ))
                    : ""
                  : ""}
              </div>
            </div>
          )}

          {/* ---------------- Second Section ------------------------------------------------- */}
          {/* GO BACK SECTION */}
          {!isPageOpen && (
            <div>
              <div
                onClick={handleBack}
                className="flex items-center cursor-pointer mt-5"
              >
                <img
                  src={go_back}
                  alt="go back"
                  className="w-[20px] h-[20px]"
                />
                <p className="text-sm font-semibold text-[#5C715E] ml-[4px] ">
                  Go Back
                </p>
              </div>

              <div className="bg-[#edf7ee] rounded-lg mt-4 flex justify-between py-4 px-4">
                <div>
                  <h6 className="text-[#344335] text-sm font-semibold">
                    {productInfo && productInfo.product_details
                      ? productInfo.product_details.name || productInfo.name
                      : "N/A"}{" "}
                  </h6>
                  <p className="text-[#344335] text-sm font-medium">
                    {productInfo && productInfo.product_details
                      ? productInfo.product_details.description
                      : "N/A"}
                  </p>

                  <div className="mt-3">
                    <p className="text-[#7c8d7e] text-[13px] ">Type</p>
                    <p className="text-[#344335] text-sm font-medium">
                      {productInfo && productInfo.product_details
                        ? productInfo.product_details.product_type
                        : "N/A"}
                    </p>
                  </div>

                  <div className="mt-3">
                    <p className="text-[#7c8d7e] text-[13px] ">Size</p>
                    <p className="text-[#344335] text-sm font-medium">
                      {productInfo && productInfo.product_details
                        ? productInfo.product_details.product_size
                        : "N/A"}
                      {productInfo && productInfo.product_details
                        ? productInfo.product_details.measurement
                        : "N/A"}
                    </p>
                  </div>

                  <div className="mt-3">
                    <p className="text-[#7c8d7e] text-[13px] ">Units</p>
                    <p className="text-[#344335] text-sm font-medium">
                      {productInfo && productInfo.product_details
                        ? productInfo.product_details.units
                        : "N/A"}
                    </p>
                  </div>

                  <div className="mt-3">
                    <p className="text-[#7c8d7e] text-[13px] ">Vendor</p>
                    <p className="text-[#344335] text-sm font-medium">
                      {productInfo && productInfo.vendor_price_details
                        ? productInfo.vendor_price_details.vendor_name
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div></div>
              </div>

              <ul className="mt-6 flex gap-4 items-center">
                <li className="text-[#2B8C34] text-sm border-b-2 border-primary font-semibold cursor-pointer">
                  Product Details
                </li>
                <li className="text-[#96A397] text-sm font-semibold cursor-pointer">
                  Pricing and Sizing
                </li>
              </ul>

              <div className="grid grid-cols-2 gap-4 mt-5">
                <div className="bg-[#edf7ee] rounded-lg  py-4 px-4">
                  <div className="">
                    <p className="text-[#7c8d7e] text-[13px] ">Product Class</p>
                    <p className="text-[#344335] text-sm font-medium">
                      Paraquat
                    </p>
                  </div>

                  <div className="mt-3">
                    <p className="text-[#7c8d7e] text-[13px] ">Dose rate</p>
                    <p className="text-[#344335] text-sm font-medium">
                      80-160ml/ 15l sprayer, 2-4L
                    </p>
                  </div>

                  <div className="mt-3">
                    <p className="text-[#7c8d7e] text-[13px] ">Target Crops</p>
                    <p className="text-[#344335] text-sm font-medium">
                      All Crops
                    </p>
                  </div>

                  <div className="mt-3">
                    <p className="text-[#7c8d7e] text-[13px] ">
                      Time of application
                    </p>
                    <p className="text-[#344335] text-sm font-medium">
                      Pre-planting
                    </p>
                  </div>
                </div>
                <div className="bg-[#edf7ee] rounded-lg py-4 px-4 h-fit">
                  <div>
                    <p className="text-[#7c8d7e] text-[13px] ">
                      Mixing and application procedure
                    </p>
                    <p className="text-[#344335] text-sm font-medium">
                      Measure out the required quantity of the product in a
                      knapsack sprayer half filled with water and shake to mix,
                      fill the spray tank to the required level and shake to mix
                      before spraying, maintain agitation during the whole
                      spraying operation, apply with a herbicide nozzle and see
                      the product label for further details
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductCatalogPage;
