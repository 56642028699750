import React,{useState} from 'react'
import Header from '../../../components/Header';
import {CardWithBorder} from '../../../components/Cards';
import chevron_down from '../../../assets/icons/chevron_down.svg'
// import chevron_up from '../../assets/icons/chevron_up.svg'
import check_box from '../../../assets/icons/check-box.svg'
import AdminLogTable from '../../../tables/CallLog/TeamLead';
import { Link } from 'react-router-dom';
import plus_white from '../../../assets/icons/plus_white.svg'

const AdminLogPage = () => {

    const [isCallOpen, setIsCallOpen] = useState(false)
    const [callTitle, setCallTitle] = useState('All')
    const [callId, setCallID] = useState(1)
    // const [callTag, setCallTag] = useState('all')

    const [callStatus] = useState([
        {
          id: 1,
          text: 'All',
          tag: 'all',
        },
        {
          id: 2,
          text: 'Not Called',
          tag: 'NOT_CALLED',
        },
        {
          id: 3,
          text: 'Called',
          tag: 'CALLED',
        },
        {
          id: 4,
          text: 'Missed',
          tag: 'MISSED',
        },
        {
          id: 5,
          text: 'Incomplete',
          tag: 'INCOMPLETE',
        },
        {
          id: 6,
          text: 'Follow Up',
          tag: 'FOLLOW_UP',
        },
      ])


  const handleCallStatus = (val) => {
    const { id, text } = val
    setCallTitle(text)
    setCallID(id)
    setIsCallOpen(!isCallOpen)
   }
    
    const handleCallOpen = () => {
    setIsCallOpen(!isCallOpen)
    }
    


    return ( 
        <>
            <Header text="Call Log Overview">
              <div>
                  <Link to="/call-log-admin/createlog"
                      className="w-full py-3 flex items-center justify-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
                    >
                        <img src={plus_white} alt="plus icon" />
                      <p className="text-white font-medium text-sm ml-2">
                      Create New Log
                      </p>
                  </Link>
                  </div>
              </Header>
            <div className='mt-4 flex gap-4 max-w-5xl'>
                <div className='md:w-[240px]'>
                <CardWithBorder title="Call Logs Created" subtitle="5" color="border-primary" href="/call-log-admin/logs" />
                </div>
                <div className="bg-white rounded-lg flex">
                    <div className="flex flex-col px-4 py-6 justify-center border-r-[#CED5CF] border-r">
                    <p className="text-[#96A397] text-sm font-semibold">
                    Call Log Progress Report
                    </p>
                    </div>
                    <div className="py-6 px-6 flex gap-10">
                    <div>
                        <h6 className="text-[#96A397] text-sm font-semibold">
                        Farmers Reached
                        </h6>
                        <h4 className="text-[#344335] font-semibold text-2xl mt-3">
                        400/500
                        </h4>
                    </div>
                    <div>
                        <h6 className="text-[#96A397] text-sm font-semibold">
                        Farmers Engaged
                        </h6>
                        <h4 className="text-[#344335] font-semibold text-2xl mt-3">
                        250/500
                        </h4>
                    </div>
                    <div>
                        <h6 className="text-[#96A397] text-sm font-semibold">
                        Farmers Converted
                        </h6>
                        <h4 className="text-[#344335] font-semibold text-2xl mt-3">
                        250/500
                        </h4>
                    </div>
                    </div>
               </div>  
            </div>
            <div className="mt-5 bg-white rounded-lg px-6 py-6">
              <h4 className="text-primary font-semibold text-lg">Recent Calls Assigned</h4>

              <div className="mt-5 flex gap-6">
                <div className="flex items-center gap-3">
                  <div>
                    <p className="text-[#344335] text-sm font-medium">
                      Call Category:
                    </p>
                  </div>
                  <div
                    className="flex gap-2 items-center cursor-pointer relative"
                    onClick={handleCallOpen}
                  >
                    <p className="text-[#96A397] text-sm font-medium">
                      {callTitle}
                    </p>
                    <img src={chevron_down} className="w-3 h-3" alt="arrow" />
                    <ul
                      className={
                        isCallOpen
                          ? 'absolute bg-white rounded-md shadow-dropdown top-6 w-44 py-3 px-2 z-30'
                          : 'hidden'
                      }
                    >
                      {callStatus.map((val) => (
                        <>
                          <li
                            key={val.id}
                            onClick={() => handleCallStatus(val)}
                            className="px-6 py-2 rounded-md hover:bg-[#EDF7EE]"
                          >
                            <div className="flex gap-3">
                              {callId === val.id && (
                                <img src={check_box} alt="checkbox" />
                              )}
                              <p className="text-[#344335] text-sm font-normal">
                                {val.text}
                              </p>
                            </div>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div> 
                <div className="flex items-center gap-3">
                  <div>
                    <p className="text-[#344335] text-sm font-medium">
                      Agent Name:
                    </p>
                  </div>
                  <div
                    className="flex gap-2 items-center cursor-pointer relative"
                    onClick={handleCallOpen}
                  >
                    <p className="text-[#96A397] text-sm font-medium">
                      {callTitle}
                    </p>
                    <img src={chevron_down} className="w-3 h-3" alt="arrow" />
                    <ul
                      className={
                        isCallOpen
                          ? 'absolute bg-white rounded-md shadow-dropdown top-6 w-44 py-3 px-2 z-30'
                          : 'hidden'
                      }
                    >
                      {callStatus.map((val) => (
                        <>
                          <li
                            key={val.id}
                            onClick={() => handleCallStatus(val)}
                            className="px-6 py-2 rounded-md hover:bg-[#EDF7EE]"
                          >
                            <div className="flex gap-3">
                              {callId === val.id && (
                                <img src={check_box} alt="checkbox" />
                              )}
                              <p className="text-[#344335] text-sm font-normal">
                                {val.text}
                              </p>
                            </div>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div>            
              </div>

            </div>
            <AdminLogTable />
        </>
     );
}
 
export default AdminLogPage;