import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { finagApiUrl } from '../config'
import cogoToast from 'cogo-toast';


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
}


export const fetchLoanOverviewInfo = createAsyncThunk(
  'fetchLoanOverviewInfo',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/dashboard-details/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const getCooperativeSummary = createAsyncThunk(
  'getCooperativeSummary',
  async ({page}, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/cooperative-summary/?page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const getAllLoansByFilter = createAsyncThunk(
  'getAllLoansByFilter',
  async ({ type }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(`${finagApiUrl}farmer-loan/all-loans/`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }
      else {
        const res = await axios.get(`${finagApiUrl}farmer-loan/all-loans/?loan_status=${type}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)


export const getAllActiveLoans = createAsyncThunk(
  'getAllActiveLoans',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/all-loans/?loan_status=active`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }

    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const getLoanByID = createAsyncThunk(
  'getLoanByID',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/all-loans/?loan_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const getOverdueLoans = createAsyncThunk(
  'getOverdueLoans',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/loan-officer/overdue-loans`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const getGroupLoansByCooperative = createAsyncThunk(
  'getGroupLoansByCooperative',
  async ({ code, type }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(`${finagApiUrl}farmer-loan/group-loans?cooperative_code=${code}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }
      else {
        const res = await axios.get(`${finagApiUrl}farmer-loan/group-loans?cooperative_code=${code}&loan_status=${type}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }

    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)


export const fetchGroups = createAsyncThunk(
  'fetchGroups',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/loan-groups/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const fetchFarmerLoanPorfolio = createAsyncThunk(
  'fetchFarmerLoanPorfolio',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/farmer/loan-profile/?farmer_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const fetchLoanOfficers = createAsyncThunk(
  'fetchLoanOfficers',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/all-loan-officers/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const fetchLoanOfficerById = createAsyncThunk(
  'fetchLoanOfficerById',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/loan-officer/?agent_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const fetchLoanOfficerLoanPortfolio = createAsyncThunk(
  'fetchLoanOfficerLoanPortfolio',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/loan-officer/loan-portfolio?agent_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const fetchLoanOfficerLoans = createAsyncThunk(
  'fetchLoanOfficerLoans',
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/all-loans/?loan_officer=${id}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const fetchLoanOfficerGroups = createAsyncThunk(
  'fetchLoanOfficerGroups',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/loan-groups/loan-officer/?loan_officer=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const fetchLoanOfficerOverdueLoans = createAsyncThunk(
  'fetchLoanOfficerOverdueLoans',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/loan-officer/overdue-loans?agent_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const handleApproveLoan = createAsyncThunk(
  'handleApproveLoan',
  async ({ id, status }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${finagApiUrl}farmer-loan/loan-approval/`,
        {
          loan_id: parseInt(id),
          approval_status: status
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const handleRejectLoan = createAsyncThunk(
  'handleRejectLoan',
  async ({ id, status, message }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${finagApiUrl}farmer-loan/loan-approval/`,
        {
          loan_id: parseInt(id),
          approval_status: status,
          rejection_message: message
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const fetchLoanPackages = createAsyncThunk(
  'fetchLoanPackages',
  async ({ type }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/get-loan-packages/?loan_type=${type}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {

        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const createLoanPorfolio = createAsyncThunk(
  'createLoanPorfolio',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${finagApiUrl}farmer-loan/create-farmer-loan/`, values,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        // cogoToast.success("Farmer's loan was created successfully!")
        return res.data
      }
    } catch (error) {

      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        // cogoToast.error(error?.response?.data?.errors)
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const handleLoanRecovery = createAsyncThunk(
  'handleLoanRecovery',
  async ({ channel, page }, { rejectWithValue, dispatch }) => {
    if (channel === "all") {
      try {
        const res = await axios.get(`${finagApiUrl}farmer-loan/recovery/list?page=${page}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          return rejectWithValue(error.response.data)
        } else {
          return rejectWithValue(error.response.data)
        }
      }
    } else {
      try {
        const res = await axios.get(`${finagApiUrl}farmer-loan/recovery/list?channel=${channel}&page=${page}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          return rejectWithValue(error.response.data)
        } else {
          return rejectWithValue(error.response.data)
        }
      }
    }

  },
)

export const handleALoanRecovery = createAsyncThunk(
  'handleALoanRecovery',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/recovery/${id}/details/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error(error.message)
        return rejectWithValue(error.response.data)
      } else {
        cogoToast.error("An error occured")
        return rejectWithValue(error.response.data)
      }
    }


  },
)

export const handlePendingLoanRecovery = createAsyncThunk(
  'handlePendingLoanRecovery',
  async ({ page }, { rejectWithValue, dispatch }) => {

    try {
      const res = await axios.get(`${finagApiUrl}farmer-loan/recovery/pending-list?page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }


  },
)

export const handlePaymentRecovery = createAsyncThunk(
  'handlePaymentRecovery',
  async ({ values, id }, { rejectWithValue, dispatch }) => {

    try {
      const res = await axios.post(`${finagApiUrl}farmer-loan/recovery/${id}/update/`, values,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        cogoToast.success("Updated successfully")
        return res.data
      }
    } catch (error) {

      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error(error.response.data.errors)
        return rejectWithValue(error.response.data)
      } else {
        cogoToast.error("An error occured")
        return rejectWithValue(error.response.data)
      }
    }


  },
)

export const handleCreateRemark = createAsyncThunk(
  'handleCreateRemark',
  async ({ values, id }, { rejectWithValue, dispatch }) => {

    try {
      const res = await axios.post(`${finagApiUrl}farmer-loan/recovery/${id}/add-remark/`, values,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        cogoToast.success("Remark created successfully")
        return res.data
      }
    } catch (error) {

      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error(error.response.data.errors)
        return rejectWithValue(error.response.data)
      } else {
        cogoToast.error("An error occured")
        return rejectWithValue(error.response.data)
      }
    }


  },
)

