import React, { useState, useMemo, useCallback, useEffect } from "react";
import TableBackground from "../../../components/TableBackground";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../../components/Pagination/Pagination";
import { useParams } from "react-router-dom";
import { fetchFarmerLoanPorfolio } from "../../../redux/Loans/loanActions";
import EmptyState from "../../../components/EmptyState";


const LoanPortfolioTable = () => {
 const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, farmerLoanPorfolio } = useSelector((state) => state.loan);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // ALL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
  };
  



  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
       console.log(val)
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const getLoanBg = (val) => {
    let result
    switch (val) {
      case 'pending':
        result = 'pending-badge'
        break;
      case 'inactive':
        result = 'error-badge'
        break
      case 'active':
        result = 'success-badge'
        break
      default:
        result = 'error-badge'
        break
    }
    return result
  }

  const getLoanText = (val) => {
    let result
    switch (val) {
      case 'pending':
        result = 'Pending'
        break;
      case 'inactive':
        result = 'Inactive'
        break
      case 'active':
        result = 'Active'
        break
      default:
        result = 'Pending'
        break
    }
    return result
  }

  useEffect(()=>{
    dispatch(fetchFarmerLoanPorfolio({
      id
    }))
  },[dispatch, id])



  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4 mt-4 pt-4 px-4">
          <div className="w-full relative">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        <div className="overflow-x-auto relative mt-8">
         <table>
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                <tr>
                <th scope="col" className="px-4 pb-2">
                Date Created
                </th>
                <th scope="col" className="px-4 pb-2">
                    Loan Package
                </th>
                <th scope="col" className="px-4 pb-2">
                Amt. Disbursed
                </th>
                <th scope="col" className="px-4 pb-2">
                Total Loan Expected
                </th>
                <th scope="col" className="px-4 pb-2">
                Amt. Recovered
                </th>
                <th scope="col" className="px-4 pb-2">
                    Total Arrears
                </th>
                <th scope="col" className="px-4 pb-2">
                 Status
                </th>
                </tr>
            </thead>
            {!loading && (
              <tbody>
                {farmerLoanPorfolio.length > 0
                  ? farmerLoanPorfolio.map((item) => (
                    <tr
                    className="relative cursor-pointer hover:bg-[#edf7ee]"
                    >
                    <td className="px-4">{item.date_created}</td>
                    <td className="px-4">{item.loan_package}</td>
                    <td className="px-4">₦{item.loan_amount_disbursed} </td>
                    <td className="px-4">
                    ₦{item.loan_amount_expected}
                    </td>
                    <td className="px-4">
                    ₦{item.loan_amount_repaid} 
                    </td>
                    <td className="px-4">
                    ₦{item.loan_amount_overdue} 
                    </td>
                    <td className="px-4">
                        <div
                        className={getLoanBg(item.loan_status)}
                        >
                        <p>
                            {getLoanText(item.loan_status)}
                        </p>
                        </div>
                    </td>
                   
            </tr>
                    ))
                  : ""}
              </tbody>
            )}
            <tbody>
              
            </tbody>
          </table>
          </div>

          {!loading && farmerLoanPorfolio.length === 0 ? (
              <div>
                <EmptyState text="Oops, You don't seem to have a loan porfolio yet!" />
              </div>
              )
              :
              ""
          }
        
          {loading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
    
      </TableBackground>

      {!loading && farmerLoanPorfolio.length > 0 ? 
      <div className="mt-1">
        <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={10}
            totalItems={10}
        />
        </div>
        :
        ""
      }

      
    </div>
  );
};

export default LoanPortfolioTable;
