import React from "react";
import GoBack from "../../../components/GoBack";
import Header from "../../../components/Header";
import TemporaryFarmersTable from "../../../tables/Farmers/TemporaryFarmersTable";

const TemporaryFarmers = () => {
  return (
    <div>
      <div>
        <GoBack />
      </div>

      <div className="mt-[14px] mb-6">
        <Header text="Temporary Farmers"></Header>
      </div>

      <div>
        <TemporaryFarmersTable />
      </div>
    </div>
  );
};

export default TemporaryFarmers;
