import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from 'cogo-toast'

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
}


export const getUSSDSessions = createAsyncThunk(
  'getUSSDSessions',
  async ({ page, status }, { rejectWithValue, dispatch }) => {
    try {
      if (status === 'all') {
        const res = await axios.get(`${apiUrl}sessions?page=${page}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }
      else {
        const res = await axios.get(`${apiUrl}sessions?page=${page}&status=${status}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }

    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const searchUSSDSessions = createAsyncThunk(
  'searchUSSDSessions',
  async ({ status, val }, { rejectWithValue, dispatch }) => {
    try {
      if (status === 'all') {
        const res = await axios.get(`${apiUrl}sessions?search=${val}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }
      else {
        const res = await axios.get(`${apiUrl}sessions?search=${val}&status=${status}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }

    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const getSessionActivities = createAsyncThunk(
  'getSessionActivities',
  async ({ page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}session_activities?page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }

    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)


