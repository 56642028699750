import React from 'react'
import { CardWithBorder } from '../../../components/Cards';
import GoBack from '../../../components/GoBack';
import Header from '../../../components/Header';
import TemporaryFarmerTable from '../../../tables/CallLog/Member/TemporaryFarmerTable';


const MemberTemporaryFarmersPage = () => {
    return (  
        <>
            <GoBack />
            <div className='mt-4'>
                <Header text="Temporary Farmers" />
            </div>
            <div className='mt-4 max-w-[260px]'>
                <CardWithBorder title="Temporary Farmers Converted" subtitle="250/250" color="border-[#B92043]" isLink={false} />
            </div>
            <TemporaryFarmerTable />
        </>
    );
}
 
export default MemberTemporaryFarmersPage;