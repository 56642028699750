import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import {
  editComplaints,
  getComplaints,
  getSearchComplaints,
} from "../../redux/Complaints/complaintsAction";
import DrawerComponent from "../../components/Drawers/drawers";
import { Link } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";

const ComplaintsTable = () => {
  const [status, setStatus] = useState("all");
  const [isClickTable, setIsClickTable] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [complaintSummary, setComplaintSummary] = useState({});
  const [summaryValue, setSummaryValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [clickStatus, setClickStatus] = useState(false);
  const complaintStatus = ["RESOLVED", "UNRESOLVED"];

  const {
    complaintsData,
    complaintsTotal,
    complaintsLoading,
    editComplaintsLoading,
  } = useSelector((state) => state.complaints);

  // COMPLAINTS PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    if (status === "all") {
      dispatch(getComplaints({ page: page, value: null }));
    } else if (status === "resolved") {
      dispatch(getComplaints({ page: page, value: "RESOLVED" }));
    } else if (status === "unresolved") {
      dispatch(getComplaints({ page: page, value: "UNRESOLVED" }));
    }
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        if (status === "all") {
          dispatch(
            getSearchComplaints({
              page: currentPage,
              value: null,
              searchValue: val,
            })
          );
        } else if (status === "resolved") {
          dispatch(
            getSearchComplaints({
              page: currentPage,
              value: "RESOLVED",
              searchValue: val,
            })
          );
        } else if (status === "unresolved") {
          dispatch(
            getSearchComplaints({
              page: currentPage,
              value: "UNRESOLVED",
              searchValue: val,
            })
          );
        }
      }, 750),
    [dispatch, currentPage, status]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleSummaryChange = (e) => {
    setSummaryValue(e.target.value);
  };

  const handleViewComplaint = (
    name,
    phone_number,
    complaint_status,
    assigned_agent,
    date,
    time,
    summary,
    id,
    complaint_id
  ) => {
    setComplaintSummary({
      name,
      phone_number,
      complaint_status,
      assigned_agent,
      date,
      time,
      summary,
      id,
      complaint_id,
    });
    setSummaryValue(summary);
    setSelectedStatus(complaint_status);
    setIsClickTable(true);
  };

  const handleSave = () => {
    dispatch(
      editComplaints({
        value: summaryValue,
        status: selectedStatus,
        id: complaintSummary.complaint_id,
      })
    );

    setTimeout(() => {
      setIsClickTable(false);
      if (status === "all") {
        dispatch(getComplaints({ page: 1, value: null }));
      } else if (status === "resolved") {
        dispatch(getComplaints({ page: 1, value: "RESOLVED" }));
      } else if (status === "unresolved") {
        dispatch(getComplaints({ page: 1, value: "UNRESOLVED" }));
      }
    }, 2000);
  };

  useEffect(() => {
    if (status === "all") {
      dispatch(getComplaints({ page: 1, value: null }));
    } else if (status === "resolved") {
      dispatch(getComplaints({ page: 1, value: "RESOLVED" }));
    } else if (status === "unresolved") {
      dispatch(getComplaints({ page: 1, value: "UNRESOLVED" }));
    }
  }, [dispatch, status]);

  return (
    <div>
      <TableBackground>
        <p className="text-primary font-semibold text-base leading-5 pt-4 px-4">
          Total Complaints
        </p>

        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS SECTION */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 cursor-pointer px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "all" ? complaintsTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "resolved"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("resolved")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Resolved
                </p>
                <div
                  className={
                    status === "resolved"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "resolved" ? complaintsTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "unresolved"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("unresolved")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Unresolved
                </p>
                <div
                  className={
                    status === "unresolved"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "unresolved" ? complaintsTotal : "0"}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {status === "all" ? (
          <section className="mt-[30px] mb-[24px]">
            {complaintsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="px-4 pb-2">
                      First Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Assigned Agent
                    </th>

                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Complaint Status
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      View Status
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {complaintsData.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className="relative cursor-pointer hover:bg-[#edf7ee]"
                      >
                        <td className="px-4">
                          {data.farmer.first_name || "N/A"}
                        </td>
                        <td className="px-4">
                          {data.farmer.last_name || "N/A"}
                        </td>
                        <td className="px-4">
                          {data.farmer.phone_number || "N/A"}
                        </td>
                        <td className="px-4">
                          {data.user.first_name + " " + data.user.last_name}
                        </td>

                        <td className="px-4">
                          {moment(data.complaint_date).format("ll")}
                        </td>
                        <td className="px-4">
                          {moment(data.complaint_date).format("LT")}
                        </td>
                        <td className="">
                          <div
                            className={
                              data.complaint_status === "RESOLVED"
                                ? "px-4 success-badge"
                                : data.complaint_status === "UNRESOLVED"
                                ? "px-4 error-badge"
                                : null
                            }
                          >
                            <p>
                              {data.complaint_status === "RESOLVED"
                                ? "Resolved"
                                : data.complaint_status === "UNRESOLVED"
                                ? "Unresolved"
                                : null}
                            </p>
                          </div>
                        </td>

                        <td className="px-4">
                          {" "}
                          <div
                            className="px-4 success-badge"
                            onClick={() =>
                              handleViewComplaint(
                                data.farmer.first_name +
                                  " " +
                                  data.farmer.last_name,
                                data.farmer.phone_number,
                                data.complaint_status,
                                data.user.first_name +
                                  " " +
                                  data.user.last_name,
                                moment(data.complaint_date).format("ll"),
                                moment(data.complaint_date).format("LT"),
                                data.complaint,
                                data.farmer.id,
                                data.id
                              )
                            }
                          >
                            <p>View</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {!complaintsLoading && complaintsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={complaintsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "resolved" ? (
          <section className="mt-[30px] mb-[24px]">
            {complaintsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="px-4 pb-2">
                      First Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Assigned Agent
                    </th>

                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Complaint Status
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      View Status
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {complaintsData.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className="relative cursor-pointer hover:bg-[#edf7ee]"
                      >
                        <td className="px-4">
                          {data.farmer.first_name || "N/A"}
                        </td>
                        <td className="px-4">
                          {data.farmer.last_name || "N/A"}
                        </td>
                        <td className="px-4">
                          {data.farmer.phone_number || "N/A"}
                        </td>
                        <td className="px-4">
                          {data.user.first_name + " " + data.user.last_name}
                        </td>

                        <td className="px-4">
                          {moment(data.complaint_date).format("ll")}
                        </td>
                        <td className="px-4">
                          {moment(data.complaint_date).format("LT")}
                        </td>
                        <td className="">
                          <div
                            className={
                              data.complaint_status === "RESOLVED"
                                ? "px-4 success-badge"
                                : data.complaint_status === "UNRESOLVED"
                                ? "px-4 error-badge"
                                : null
                            }
                          >
                            <p>
                              {data.complaint_status === "RESOLVED"
                                ? "Resolved"
                                : data.complaint_status === "UNRESOLVED"
                                ? "Unresolved"
                                : null}
                            </p>
                          </div>
                        </td>

                        <td className="px-4">
                          {" "}
                          <div
                            className="px-4 success-badge"
                            onClick={() =>
                              handleViewComplaint(
                                data.farmer.first_name +
                                  " " +
                                  data.farmer.last_name,
                                data.farmer.phone_number,
                                data.complaint_status,
                                data.user.first_name +
                                  " " +
                                  data.user.last_name,
                                moment(data.complaint_date).format("ll"),
                                moment(data.complaint_date).format("LT"),
                                data.complaint,
                                data.farmer.id,
                                data.id
                              )
                            }
                          >
                            <p>View</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {!complaintsLoading && complaintsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={complaintsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "unresolved" ? (
          <section className="mt-[30px] mb-[24px]">
            {complaintsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="px-4 pb-2">
                      First Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Assigned Agent
                    </th>

                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Complaint Status
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      View Status
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {complaintsData.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className="relative cursor-pointer hover:bg-[#edf7ee]"
                      >
                        <td className="px-4">
                          {data.farmer.first_name || "N/A"}
                        </td>
                        <td className="px-4">
                          {data.farmer.last_name || "N/A"}
                        </td>
                        <td className="px-4">
                          {data.farmer.phone_number || "N/A"}
                        </td>
                        <td className="px-4">
                          {data.user.first_name + " " + data.user.last_name}
                        </td>

                        <td className="px-4">
                          {moment(data.complaint_date).format("ll")}
                        </td>
                        <td className="px-4">
                          {moment(data.complaint_date).format("LT")}
                        </td>
                        <td className="">
                          <div
                            className={
                              data.complaint_status === "RESOLVED"
                                ? "px-4 success-badge"
                                : data.complaint_status === "UNRESOLVED"
                                ? "px-4 error-badge"
                                : null
                            }
                          >
                            <p>
                              {data.complaint_status === "RESOLVED"
                                ? "Resolved"
                                : data.complaint_status === "UNRESOLVED"
                                ? "Unresolved"
                                : null}
                            </p>
                          </div>
                        </td>

                        <td className="px-4">
                          {" "}
                          <div
                            className="px-4 success-badge"
                            onClick={() =>
                              handleViewComplaint(
                                data.farmer.first_name +
                                  " " +
                                  data.farmer.last_name,
                                data.farmer.phone_number,
                                data.complaint_status,
                                data.user.first_name +
                                  " " +
                                  data.user.last_name,
                                moment(data.complaint_date).format("ll"),
                                moment(data.complaint_date).format("LT"),
                                data.complaint,
                                data.farmer.id,
                                data.id
                              )
                            }
                          >
                            <p>View</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {!complaintsLoading && complaintsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={complaintsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : null}
      </TableBackground>

      {/* COMPLAINT DRAWER */}
      <DrawerComponent
        title="Request Info"
        show={isClickTable}
        showDrawer={() => setIsClickTable(false)}
      >
        <div className="mt-8">
          <div className="flex justify-between items-center">
            <div>
              <h5 className="text-xs text-[#5C715E]">Name</h5>
              <p className="font-medium text-[#5C715E] text-sm mt-1">
                {complaintSummary.name || "N/A"}
              </p>
            </div>
            <div>
              <Link
                to={`/farmers-profile/${complaintSummary.id}`}
                className="text-[#2B8C34] text-sm font-medium cursor-pointer no-underline"
              >
                Go to Farmer's profile
              </Link>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Phone Number</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {complaintSummary.phone_number || "N/A"}
          </p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Complaint Status</h5>
          <div className="flex mt-2">
            <div
              className={
                selectedStatus === "RESOLVED"
                  ? "px-4 success-badge flex gap-5 cursor-pointer items-center"
                  : selectedStatus === "UNRESOLVED"
                  ? "px-4 error-badge flex  gap-5 cursor-pointer items-center"
                  : null
              }
              onClick={() => setClickStatus(!clickStatus)}
            >
              <p className="capitalize">
                {selectedStatus === "RESOLVED"
                  ? "Resolved"
                  : selectedStatus === "UNRESOLVED"
                  ? "Unresolved"
                  : null}
              </p>
              <ExpandMore
                className=" cursor-pointer"
                style={{ fontSize: "18px" }}
              />
            </div>
            {clickStatus && (
              <div
                className="absolute rounded-[8px] p-[8px] w-auto mt-[7%] bg-white z-10"
                style={{
                  boxShadow:
                    "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                }}
              >
                {complaintStatus.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                      onClick={() => {
                        setSelectedStatus(data);
                        setClickStatus(false);
                      }}
                    >
                      <p className="font-medium text-sm leading-5 text-[#344335] capitalize">
                        {data}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Assigned Agent</h5>
          <p className="font-medium text-sm mt-1 text-primary">
            {complaintSummary.assigned_agent || "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Call Date</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {complaintSummary.date || "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Call Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {complaintSummary.time || "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <label
            htmlFor="message"
            className="block mb-2 text-xs font-medium text-secondary1"
          >
            Complaint Summary
          </label>
          <textarea
            id="message"
            rows="8"
            value={summaryValue}
            onChange={handleSummaryChange}
            className="appearance-none w-full
                placeholder:text-[#96A397] placeholder:text-sm
                    text-[#5C715E]
                    text-sm resize-none
                    focus:border-[#2B8C34] focus:outline-none  
                    focus:shadow-input rounded-lg border border-[#96A397]
                    bg-white py-3 px-4"
            placeholder="Complaint Summary..."
          ></textarea>
        </div>

        <div className="flex justify-between mt-4 items-center">
          <div>
            <button
              onClick={handleSave}
              disabled={editComplaintsLoading}
              type="button"
              className="text-white bg-primary py-3 px-6
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] font-medium
                         rounded-lg text-sm flex "
            >
              {" "}
              Save
            </button>
          </div>
          <div>
            <p className="text-[#96A397] text-xs font-semibold">
              Edited by <span className="text-[#2B8C34]">Celina Avong</span>
            </p>
            <p className="text-[#96A397] text-xs font-medium">
              02:20 PM | 10 Aug 22
            </p>
          </div>
        </div>
      </DrawerComponent>
    </div>
  );
};

export default ComplaintsTable;
