import React from 'react'
import GoBack from '../../components/GoBack';
import Header from '../../components/Header';
import LoanGroupsTable from '../../tables/Loans/LoanGroupsTable';


const LoanGroupsPage = () => {
    return (  
        <>
          <GoBack />
            <div className='mt-4'>
                <Header text="Groups" />
            </div>
           
           <LoanGroupsTable />
        </>
    );
}
 
export default LoanGroupsPage;