import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl, finagApiUrl } from "../config";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getMessageTemplateCount = createAsyncThunk(
  "getMessageTemplateCount",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}messages/templates`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getMessages = createAsyncThunk(
  "getMessages",
  async ({ page, type }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(`${apiUrl}messages?page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } else {
        const res = await axios.get(
          `${apiUrl}messages?status=${type}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getPendingMessages = createAsyncThunk(
  "getPendingMessages",
  async ({ page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}farmer-message/pending-bulk-sms?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const messageApproval = createAsyncThunk(
  "messageApproval",
  async ({ type, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}farmer-message/approve/`,
        id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        cogoToast.success("Message Approved Successfully!");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        console.log(error.response.data.detail);
        if (error?.response?.data?.detail) {
          cogoToast.error(error?.response?.data?.detail);
        } else {
          cogoToast.error("An error occurred!");
        }
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  "deleteTemplate",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.delete(`${apiUrl}messages/templates/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        cogoToast.success("Template deleted successfully!");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const createMessageTemplate = createAsyncThunk(
  "createMessageTemplate",
  async ({ title, templates }, { rejectWithValue, dispatch, getState }) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}messages/templates`,
        {
          user_id: userId,
          title: title,
          templates: templates,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        cogoToast.success("Template created successfully!");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const updateMessageTemplate = createAsyncThunk(
  "updateMessageTemplate",
  async ({ id, title, templates }, { rejectWithValue, dispatch, getState }) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.patch(
        `${apiUrl}messages/templates/${id}`,
        {
          user_id: userId,
          title: title,
          templates: templates,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Template updated successfully!");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// get farmers query
export const fetchFarmersCountByQuery = createAsyncThunk(
  "fetchFarmersCountByQuery",
  async (
    { state_lga, crops, is_active, is_transacted },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}farmer-query?result=count`,
        {
          state_lga,
          crops,
          is_active,
          is_transacted,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// send bulk messages
export const sendBulkSMS = createAsyncThunk(
  "sendBulkSMS",
  async (
    { message, state_lga, crops, is_active, is_transacted, type },
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (type === "incomplete") {
        const res = await axios.post(
          `${apiUrl}messages/bulk-sms?farmer_type=incomplete`,
          {
            message,
            query: {
              state_lga,
              crops,
              is_active,
              is_transacted,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          cogoToast.success("SMS sent successfully!");
          return res.data;
        }
      } else {
        const res = await axios.post(
          `${apiUrl}messages/bulk-sms`,
          {
            message,
            query: {
              state_lga,
              crops,
              is_active,
              is_transacted,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          cogoToast.success("SMS sent successfully!");
          return res.data;
        }
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getMessageTemplate = createAsyncThunk(
  "getMessageTemplate",
  async ({ page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}messages/templates?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const sendSingleMessage = createAsyncThunk(
  "sendSingleMessage",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}messages/sms`, value, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        cogoToast.success("Message sent successfully");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        // cogoToast.error(error?.response?.data.error);
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
