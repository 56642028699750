import React from 'react'

const TableBackground = ({ children, style, classname }) => {
  return (
    <div style={style} className={`"w-full h-full bg-white ${classname}`}>
      {children}
    </div>
  )
}

export default TableBackground
