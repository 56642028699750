import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

//////////////////////////////////////////// CALL TO ORDER SECTION
export const getCallToOrder = createAsyncThunk(
  "getCallToOrder",
  async ({ page, status, value }, { rejectWithValue, dispatch }) => {
    if (status !== "ALL") {
      try {
        const res = await axios.get(
          `${apiUrl}call_to_orders?page=${page}&status=${status}&search=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}call_to_orders?page=${page}&search=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const getSearchCallToOrder = createAsyncThunk(
  "getSearchCallToOrder",
  async ({ page, status, searchValue }, { rejectWithValue, dispatch }) => {
    if (status) {
      try {
        const res = await axios.get(
          `${apiUrl}call_to_orders?status=${status}&page=${page}&search=${searchValue}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}call_to_orders?page=${page}&search=${searchValue}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const createACallToOrder = createAsyncThunk(
  "createACallToOrder",
  async ({ values }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}call_to_orders`, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (res.status === 200 || res.status === 201) {
        cogoToast.success(res.data.message);
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getCallToOrderInfo = createAsyncThunk(
  "getCallToOrderInfo",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}call_to_orders/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const updateACallToOrder = createAsyncThunk(
  "updateACallToOrder",
  async ({ value, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${apiUrl}call_to_orders/${id}`,
        value,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (res.status === 200 || res.status === 201) {
        cogoToast.success("Order has been updated successfully");
        return setTimeout(() => {
          window.location.reload();
        }, 900);
      }

      if (res.status === 500 || res.status === 503) {
        cogoToast.error("An error occurred!");
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getCallToOrderComment = createAsyncThunk(
  "getCallToOrderComment",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}call_to_orders/${id}/comments`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const createCallToOrderComment = createAsyncThunk(
  "updateCallToOrderComment",
  async ({ id, title, body }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}call_to_orders/${id}/comments`,
        {
          title: title,
          body: body,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Comment created successfully");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAllCallToOrder = createAsyncThunk(
  "getAllCallToOrder",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}call_to_orders/${id}/orders`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const updateAC2OOrder = createAsyncThunk(
  "updateAC2OOrder",
  async ({ id, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}call_to_orders/${id}/orders`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const callToOrderCheckout = createAsyncThunk(
  "callToOrderCheckout",
  async ({ id, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${apiUrl}call_to_orders/${id}/checkout`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const deleteAnOrder = createAsyncThunk(
  "deleteAnOrder",
  async ({ id, order_type, order_id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.delete(
        `${apiUrl}call_to_orders/${id}/orders?type=${order_type}&order_id=${order_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("The item has been removed");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const updateAnOrder = createAsyncThunk(
  "updateAnOrder",
  async (
    { id, order_type, order_id, quantity },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.patch(
        `${apiUrl}call_to_orders/${id}/orders?type=${order_type}&order_id=${order_id}`,
        { quantity: quantity },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const checkoutOrder = createAsyncThunk(
  "checkoutOrder",
  async ({ id, withhold_payment, items }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${apiUrl}call_to_orders/${id}/checkout`,
        {
          items: items,
          withhold_payment: withhold_payment,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
