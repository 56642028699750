import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { useDispatch, useSelector } from "react-redux";
import SearchComponent from "../../components/Search";
import moment from "moment";
import EmptyState from "../../components/EmptyState";
import { getCampaigns } from "../../redux/Campaign/campaignAction";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

const CampaignTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { campaignData, campaignLoading } = useSelector(
    (state) => state.campaign
  );

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        console.log(val, campaignData);
        // const filteredData = campaignData.filter((data) => data.name === val);

        const filteredData = campaignData.filter((dataFilter) => {
          console.log(dataFilter);
          return dataFilter.name.toLowerCase().includes(val.toLowerCase());
        });
        console.log(filteredData);
      }, 750),
    [campaignData]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    dispatch(getCampaigns());
  }, [dispatch]);

  return (
    <div>
      <TableBackground>
        <p className="text-primary font-semibold text-base leading-5 pt-4 px-4">
          Recent Campaigns
        </p>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        <section className="mt-[30px] pb-[6px]">
          {campaignLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {campaignData.length === 0 ? (
                <div>
                  <EmptyState text="There's no data for this filter yet" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        Name of campaign
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Number of target in campaign
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Date of creation
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Campaign status
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Success percentage
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {campaignData?.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                          onClick={() =>
                            navigate(`/campaign-summary/${data.id}`)
                          }
                        >
                          <td className="px-4">{data.name || "N/A"}</td>
                          <td className="px-4">{data.farmers_count || "0"}</td>
                          <td className="px-4">
                            {data.created
                              ? moment(data.created).format("ll")
                              : "N/A"}
                          </td>
                          <td className="px-4">{data.status || "N/A"}</td>
                          <td className="px-4">{data.progress || "0"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default CampaignTable;
