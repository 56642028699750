import React, { useEffect } from "react";
import Header from "../../components/Header";
import { Card } from "../../components/Cards";
import { CardWithStatus } from "../../components/Cards";
import RetailTable from "../../tables/Retail";
import { useDispatch, useSelector } from "react-redux";
import { getRetailStats } from "../../redux/Retail/retailAction";

const Retail = () => {
  const dispatch = useDispatch();
  const { retailStatsData } = useSelector((state) => state.retail);

  useEffect(() => {
    dispatch(getRetailStats());
  }, [dispatch]);

  return (
    <div>
      <Header text="Retail" />
      {/* CARDS SECTION */}
      <section className="grid grid-cols-4 gap-6 mt-6 mb-8">
        <div>
          <Card
            title={"Calls"}
            subtitle={retailStatsData?.lifetime_calls || "0"}
            href="/calls"
          />
        </div>

        <div>
          <CardWithStatus
            title={"Sales"}
            subtitle={retailStatsData?.agents || "0"}
            href="/sales"
            hasStatus={true}
            status="Urgent"
          />
        </div>

        <div>
          <CardWithStatus
            title={"Follow Ups"}
            subtitle={retailStatsData?.total_followups || "0"}
            href="/follow-up"
            hasStatus={true}
            status="Urgent"
          />
        </div>
      </section>
      {/* RETAILS TABLE */}
      <RetailTable />
    </div>
  );
};

export default Retail;
