import React from "react";

const Header = ({ text, children, tabs, style }) => {
  return (
    <div className="bg-white p-5 rounded-[8px]">
      <div className=" flex justify-between items-center gap-[8px]">
        <p
          style={style}
          className="text-primary text-lg font-semibold leading-6"
        >
          {text}
        </p>
        <div>{children}</div>
      </div>

      <div>{tabs}</div>
    </div>
  );
};

export default Header;
