import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { getRetail, getRetailSearch } from "../../redux/Retail/retailAction";
import { useNavigate } from "react-router-dom";

const RetailTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { retailData, retailTotal, retailLoading, retailStatsData } =
    useSelector((state) => state.retail);

  // RETAIL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(getRetail({ page: page }));
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        dispatch(
          getRetailSearch({
            page: currentPage,
            value: val,
          })
        );
      }, 750),
    [dispatch, currentPage]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    dispatch(getRetail({ page: 1 }));
  }, [dispatch]);

  return (
    <div>
      <TableBackground>
        <p className="text-primary font-semibold text-base leading-5 pt-4 px-4">
          Sales Agent ({retailStatsData?.agents || 0})
        </p>

        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {retailLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th scope="col" className="px-4 pb-2">
                    Agent Id
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    First Name
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Last Name
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Calls Made
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Orders Made
                  </th>
                </tr>
              </thead>

              <tbody>
                {retailData?.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      className="relative cursor-pointer hover:bg-[#edf7ee]"
                      onClick={() =>
                        navigate(`sales-agent/${data.sales_agent_id}`)
                      }
                    >
                      <td className="px-4">{data.sales_agent_id || "N/A"}</td>
                      <td className="px-4">{data.first_name || "N/A"}</td>
                      <td className="px-4">{data.last_name || "N/A"}</td>
                      <td className="px-4">
                        <div className="count-badge">
                          <p>{data.calls_made || "0"}</p>
                        </div>
                      </td>

                      <td className="px-4">
                        <div className="count-badge">
                          <p>{data.total_sales || "0"}</p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {!retailLoading && retailData.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={retailTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default RetailTable;
