import React from 'react'
import GoBack from '../../components/GoBack';
import Header from '../../components/Header';
import OverduePaymentTable from '../../tables/Loans/OverduePaymentTable';

const OverduePaymentLoansPage = () => {
    return (  
        <>
              <GoBack />
            <div className='mt-4'>
                <Header text="Overdue Payment" />
            </div>
            <OverduePaymentTable />
        </>
    );
}
 
export default OverduePaymentLoansPage;