import { createSlice } from "@reduxjs/toolkit";
import {
  blockFarmer,
  bulkDebitFarmers,
  debitFarmers,
  EditFarmersDailyLimit,
  farnersGeneratePin,
  getAdvisoryFarmersOrder,
  getAllBankTransactions,
  getAllBankTransactionsSearch,
  getAllFarmers,
  getAllFarmersForLoan,
  getAllFarmersOrder,
  getAllFarmersSearch,
  getAllFarmersUssdSessions,
  getAllFlaggedFarmers,
  getAllFlaggedFarmersSearch,
  getAllTemporaryFarmers,
  getArtimeFarmersOrder,
  getCalledTemporaryFarmers,
  getCalledTemporaryFarmersSearch,
  getCompleteFarmers,
  getCompleteFarmersSearch,
  getCompleteFarmersUssdSessions,
  getCreditTransactions,
  getCreditTransactionsSearch,
  getCropFarmersOrder,
  getDebitTransactions,
  getDebitTransactionsSearch,
  getFarmerRecords,
  getFarmersActivityLog,
  getFarmersCallHistory,
  getFarmersOrders,
  getFarmersProfile,
  getFarmersRefund,
  getFarmersStats,
  getIncompleteFarmers,
  getIncompleteFarmersSearch,
  getIncompleteFarmersUssdSessions,
  getInsuranceFarmersOrder,
  getLivestockFarmersOrder,
  getMechanizationFarmersOrder,
  getMissedTemporaryFarmers,
  getMissedTemporaryFarmersSearch,
  getRestrictedFarmers,
  getRestrictedFarmersSearch,
  getShaddowBannedFarmers,
  getShaddowBannedFarmersSearch,
  getTemporaryFarmersInfo,
  getToCallTemporaryFarmers,
  getToCallTemporaryFarmersSearch,
  tempFarnersGeneratePin,
  updateTemporaryFarmersInfo,
  uploadKYCDocuments,
} from "./farmersActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  // ALL FARMERS STATE
  farmers: [],
  cardLoading: false,
  loading: false,
  stats: [],
  total: 0,

  // ACTIVITY LOG
  activityLogLoading: false,
  activityLogData: [],

  ////////// COMPLETE FARMERS STATE
  completeProfiles: [],
  completeTotal: 0,
  completeProfilesLoading: false,
  completeProfilesTotal: 0,
  ////////// INCOMPLETE FARMERS STATE
  incompleteProfiles: [],
  incompleteProfilesLoading: false,
  incompleteProfilesTotal: 0,
  ////////// TEMPORARY FARMERS STATE
  allTemporaryFarmerData: [],
  allTemporaryFarmerTotal: 0,
  allTemporaryFarmerLoading: false,
  toCallTemporaryFarmerData: [],
  toCallTemporaryFarmerTotal: 0,
  toCallTemporaryFarmerLoading: false,
  calledTemporaryFarmerData: [],
  calledTemporaryFarmerTotal: 0,
  calledTemporaryFarmerLoading: false,
  missedTemporaryFarmerData: [],
  missedTemporaryFarmerTotal: 0,
  missedTemporaryFarmerLoading: false,
  ////////////FLAGGED ACCOUNTS STATE
  allFlaggedAccountsData: [],
  allFlaggedAcountsTotal: 0,
  allFlaggedAcountsLoading: false,

  allShaddowBannedAccountsData: [],
  allShaddowBannedAcountsTotal: 0,
  allShaddowBannedAcountsLoading: false,

  allRestrictedAccountsData: [],
  allRestrictedAcountsTotal: 0,
  allRestrictedsAcountsLoading: false,

  /////////////////////////// BLOCK FARMER
  blockFarmerLoading: false,
  blockFarmerStatus: "",

  ///////////////////////////////// FARMERS PROFILE
  farmersProfileLoading: false,
  farmersProfileData: null,

  ////////// FARMERS  WALLET STATE
  allTransactionsData: [],
  allTransactionsTotal: 0,
  allTransactionsLoading: false,
  debitTransactionsData: [],
  debitTransactionsTotal: 0,
  debitTransactionsLoading: false,
  creditTransactionsData: [],
  creditTransactionsTotal: 0,
  creditTransactionsLoading: false,

  /////////////////////////ORDERs
  allOrdersData: [],
  allOrdersTotal: 0,
  allOrdersLoading: false,

  cropOrdersData: [],
  cropOrdersTotal: 0,
  cropOrdersLoading: false,

  livestockOrdersData: [],
  livestockOrdersTotal: 0,
  livestockOrdersLoading: false,

  mechanizationOrdersData: [],
  mechanizationOrdersTotal: 0,
  mechanizationOrdersLoading: false,

  advisoryOrdersData: [],
  advisoryOrdersTotal: 0,
  advisoryOrdersLoading: false,

  airtimeOrdersData: [],
  airtimeOrdersTotal: 0,
  airtimeOrdersLoading: false,

  insuranceOrdersData: [],
  insuranceOrdersTotal: 0,
  insuranceOrdersLoading: false,

  // USSD SESSION
  allFarmersUssdSessionData: [],
  allFarmersUssdSessionTotal: 0,
  allFarmersUssdSessionLoading: false,

  completeFarmerUssdSessionData: [],
  completeFarmerUssdSessionTotal: 0,
  completeFarmerUssdSessionLoading: false,

  incompleteFarmerUssdSessionData: [],
  incompleteFarmerUssdSessionTotal: 0,
  incompleteFarmerUssdSessionLoading: false,

  // DAILY LIMIT
  isClickDailyLimit: false,
  dailyLimitLoading: false,

  /////// CALL HISTORY
  farmersCallHistoryData: [],
  farmersCallHistoryTotal: 0,
  farmersCallHistoryLoading: false,

  /////// FARMERS REFUND HISTORY
  farmersRefundsData: [],
  farmersRefundsTotal: 0,
  farmersRefundsLoading: false,

  ////////TEMPORRAY FARMERS INFORMATION
  temporaryFarmersInfoData: {},
  temporaryFarmersInfoLoading: false,

  ////////FARMERS ORDERS
  farmersOrdersData: [],
  farmersOrdersLoading: false,
  farmersOrdersTotal: [],

  // KYC DOCUMENTATION
  kycDocumentData: [],
  kycDocumentLoading: false,

  // KYC UPLOAD DOCUMENT
  kycDocumentUploadLoading: false,

  debitFarmerLoading: false,
  bulkDebitloading: false,

  loadingResetPin: false,
};

const farmersPersistConfig = {
  key: "farmers",
  storage,
  blacklist: ["loadingResetPin"],
};

export const farmersSlice = createSlice({
  name: "farmers",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },

    handleOpenDailyLimit: (state) => {
      state.isClickDailyLimit = true;
    },

    handleCloseDailyLimit: (state) => {
      state.isClickDailyLimit = false;
    },
  },
  extraReducers: {
    /////////////////////////////////////// GET ALL FARMERS ----------
    [getAllFarmers.pending]: (state) => {
      state.loading = true;
    },
    [getAllFarmers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farmers = payload.data;
      state.total = payload.total;
    },
    [getAllFarmers.rejected]: (state) => {
      state.loading = false;
    },

    /////////////////////////////////////// GET  FARMERS ACTIVITY LOG ----------
    [getFarmersActivityLog.pending]: (state) => {
      state.loading = true;
    },
    [getFarmersActivityLog.fulfilled]: (state, { payload }) => {
      state.activityLogLoading = false;
      state.activityLogData = payload.data;
    },
    [getFarmersActivityLog.rejected]: (state) => {
      state.activityLogLoading = false;
    },

    [getAllFarmersForLoan.pending]: (state) => {
      state.activityLogLoading = true;
    },
    [getAllFarmersForLoan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farmers = payload.data;
      state.total = payload.total;
    },
    [getAllFarmersForLoan.rejected]: (state) => {
      state.loading = false;
    },

    ///////////////////////////////////////// GET ALL FARMERS SEARCH ----------

    [getAllFarmersSearch.pending]: (state) => {
      state.loading = true;
    },
    [getAllFarmersSearch.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.farmers = payload.data;
      state.total = payload.total;
    },
    [getAllFarmersSearch.rejected]: (state) => {
      state.loading = false;
    },

    ////////////////////////////////////////// GET FARMERS CARDS ----------
    [getFarmersStats.pending]: (state) => {
      state.cardLoading = true;
    },
    [getFarmersStats.fulfilled]: (state, { payload }) => {
      state.cardLoading = false;
      state.stats = payload;
    },
    [getFarmersStats.rejected]: (state) => {
      state.cardLoading = false;
    },

    //////////////////////////////////// GET COMPLETE FARMERS CARDS ----------
    [getCompleteFarmers.pending]: (state) => {
      state.completeProfilesLoading = true;
    },
    [getCompleteFarmers.fulfilled]: (state, { payload }) => {
      state.completeProfilesLoading = false;
      state.completeProfiles = payload.data;
      state.completeProfilesTotal = payload.total;
    },
    [getCompleteFarmers.rejected]: (state) => {
      state.completeProfilesLoading = false;
    },

    ///////////////////////////////////// GET COMPLETE FARMERS SEARCH ----------
    [getCompleteFarmersSearch.pending]: (state) => {
      state.completeProfilesLoading = true;
    },
    [getCompleteFarmersSearch.fulfilled]: (state, { payload }) => {
      state.completeProfilesLoading = false;
      state.completeProfiles = payload.data;
      state.completeProfilesTotal = payload.total;
    },
    [getCompleteFarmersSearch.rejected]: (state) => {
      state.completeProfilesLoading = false;
    },

    ////////////////////////////////////// GET INCOMPLETE FARMERS  ----------
    [getIncompleteFarmers.pending]: (state) => {
      state.incompleteProfilesLoading = true;
    },
    [getIncompleteFarmers.fulfilled]: (state, { payload }) => {
      state.incompleteProfilesLoading = false;
      state.incompleteProfiles = payload.data;
      state.incompleteProfilesTotal = payload.total;
    },
    [getIncompleteFarmers.rejected]: (state) => {
      state.incompleteProfilesLoading = false;
    },

    //////////////////////////////////// GET INCOMPLETE FARMERS Search ----------
    [getIncompleteFarmersSearch.pending]: (state) => {
      state.incompleteProfilesLoading = true;
    },
    [getIncompleteFarmersSearch.fulfilled]: (state, { payload }) => {
      state.incompleteProfilesLoading = false;
      state.incompleteProfiles = payload.data;
      state.incompleteProfilesTotal = payload.total;
    },
    [getIncompleteFarmersSearch.rejected]: (state) => {
      state.incompleteProfilesLoading = false;
    },

    ////////////////////////////////////// GET TO CALL TEMPORARY FARMERS  ----------
    [getAllTemporaryFarmers.pending]: (state) => {
      state.allTemporaryFarmerLoading = true;
    },
    [getAllTemporaryFarmers.fulfilled]: (state, { payload }) => {
      state.allTemporaryFarmerLoading = false;
      state.allTemporaryFarmerData = payload.data;
      state.allTemporaryFarmerTotal = payload.total;
    },
    [getAllTemporaryFarmers.rejected]: (state) => {
      state.allTemporaryFarmerLoading = false;
    },

    ////////////////////////////////////// GET TO CALL TEMPORARY FARMERS  ----------
    [getToCallTemporaryFarmers.pending]: (state) => {
      state.toCallTemporaryFarmerLoading = true;
    },
    [getToCallTemporaryFarmers.fulfilled]: (state, { payload }) => {
      state.toCallTemporaryFarmerLoading = false;
      state.toCallTemporaryFarmerData = payload.data;
      state.toCallTemporaryFarmerTotal = payload.total;
    },
    [getToCallTemporaryFarmers.rejected]: (state) => {
      state.toCallTemporaryFarmerLoading = false;
    },

    /////////////////////////////////////  GET TO CALL TEMPORARY FARMERS SEARCH ----------
    [getToCallTemporaryFarmersSearch.pending]: (state) => {
      state.toCallTemporaryFarmerLoading = true;
    },
    [getToCallTemporaryFarmersSearch.fulfilled]: (state, { payload }) => {
      state.toCallTemporaryFarmerLoading = false;
      state.toCallTemporaryFarmerData = payload.data;
      state.toCallTemporaryFarmerTotal = payload.total;
    },
    [getToCallTemporaryFarmersSearch.rejected]: (state) => {
      state.toCallTemporaryFarmerLoading = false;
    },

    ////////////////////////////// GET CALLED TEMPORARY FARMERS  ----------
    [getCalledTemporaryFarmers.pending]: (state) => {
      state.calledTemporaryFarmerLoading = true;
    },
    [getCalledTemporaryFarmers.fulfilled]: (state, { payload }) => {
      state.calledTemporaryFarmerLoading = false;
      state.calledTemporaryFarmerData = payload.data;
      state.calledTemporaryFarmerTotal = payload.total;
    },
    [getCalledTemporaryFarmers.rejected]: (state) => {
      state.calledTemporaryFarmerLoading = false;
    },

    //////////////////////////////////  GET CALLED TEMPORARY FARMERS SEARCH ----------
    [getCalledTemporaryFarmersSearch.pending]: (state) => {
      state.calledTemporaryFarmerLoading = true;
    },
    [getCalledTemporaryFarmersSearch.fulfilled]: (state, { payload }) => {
      state.calledTemporaryFarmerLoading = false;
      state.calledTemporaryFarmerData = payload.data;
      state.calledTemporaryFarmerTotal = payload.total;
    },
    [getCalledTemporaryFarmersSearch.rejected]: (state) => {
      state.calledTemporaryFarmerLoading = false;
    },

    //////////////////////////////// GET MISSED TEMPORARY FARMERS  ----------
    [getMissedTemporaryFarmers.pending]: (state) => {
      state.missedTemporaryFarmerLoading = true;
    },
    [getMissedTemporaryFarmers.fulfilled]: (state, { payload }) => {
      state.missedTemporaryFarmerLoading = false;
      state.missedTemporaryFarmerData = payload.data;
      state.missedTemporaryFarmerTotal = payload.total;
    },
    [getMissedTemporaryFarmers.rejected]: (state) => {
      state.missedTemporaryFarmerLoading = false;
    },

    ///////////////////////////////////  GET MISSED TEMPORARY FARMERS SEARCH ----------
    [getMissedTemporaryFarmersSearch.pending]: (state) => {
      state.missedTemporaryFarmerLoading = true;
    },
    [getMissedTemporaryFarmersSearch.fulfilled]: (state, { payload }) => {
      state.missedTemporaryFarmerLoading = false;
      state.missedTemporaryFarmerData = payload.data;
      state.missedTemporaryFarmerTotal = payload.total;
    },
    [getMissedTemporaryFarmersSearch.rejected]: (state) => {
      state.missedTemporaryFarmerLoading = false;
    },

    ////////////////////////////////////////////////////////////////FLAGGED ACCOUNTS

    //////////////////////////////// GET ALL FLAGGED FARMERS  ----------
    [getAllFlaggedFarmers.pending]: (state) => {
      state.allFlaggedAcountsLoading = true;
    },
    [getAllFlaggedFarmers.fulfilled]: (state, { payload }) => {
      state.allFlaggedAcountsLoading = false;
      state.allFlaggedAccountsData = payload.data;
      state.allFlaggedAcountsTotal = payload.total;
    },
    [getAllFlaggedFarmers.rejected]: (state) => {
      state.allFlaggedAcountsLoading = false;
    },

    ///////////////////////////////////  GET ALL FLAGGED FARMERS SEARCH ----------
    [getAllFlaggedFarmersSearch.pending]: (state) => {
      state.allFlaggedAcountsLoading = true;
    },
    [getAllFlaggedFarmersSearch.fulfilled]: (state, { payload }) => {
      state.allFlaggedAcountsLoading = false;
      state.allFlaggedAccountsData = payload.data;
      state.allFlaggedAcountsTotal = payload.total;
    },
    [getAllFlaggedFarmersSearch.rejected]: (state) => {
      state.allFlaggedAcountsLoading = false;
    },

    //////////////////////////////// GET ALL SHADDOW BANNED FARMERS  ----------
    [getShaddowBannedFarmers.pending]: (state) => {
      state.allShaddowBannedAcountsLoading = true;
    },
    [getShaddowBannedFarmers.fulfilled]: (state, { payload }) => {
      state.allShaddowBannedAcountsLoading = false;
      state.allShaddowBannedAccountsData = payload.data;
      state.allShaddowBannedAcountsTotal = payload.total;
    },
    [getShaddowBannedFarmers.rejected]: (state) => {
      state.allShaddowBannedAcountsLoading = false;
    },

    ///////////////////////////////////  GET ALL SHADDOW BANNED FARMERS SEARCH ----------
    [getShaddowBannedFarmersSearch.pending]: (state) => {
      state.allShaddowBannedAcountsLoading = true;
    },
    [getShaddowBannedFarmersSearch.fulfilled]: (state, { payload }) => {
      state.allShaddowBannedAcountsLoading = false;
      state.allShaddowBannedAccountsData = payload.data;
      state.allShaddowBannedAcountsTotal = payload.total;
    },
    [getShaddowBannedFarmersSearch.rejected]: (state) => {
      state.allShaddowBannedAcountsLoading = false;
    },

    //////////////////////////////// GET ALL RESTRICTED FARMERS  ----------
    [getRestrictedFarmers.pending]: (state) => {
      state.allRestrictedsAcountsLoading = true;
    },
    [getRestrictedFarmers.fulfilled]: (state, { payload }) => {
      state.allRestrictedsAcountsLoading = false;
      state.allRestrictedAccountsData = payload.data;
      state.allRestrictedAcountsTotal = payload.total;
    },
    [getRestrictedFarmers.rejected]: (state) => {
      state.allRestrictedsAcountsLoading = false;
    },

    ///////////////////////////////////  GET ALL RESTRICTED FARMERS SEARCH ----------
    [getRestrictedFarmersSearch.pending]: (state) => {
      state.allRestrictedsAcountsLoading = true;
    },
    [getRestrictedFarmersSearch.fulfilled]: (state, { payload }) => {
      state.allRestrictedsAcountsLoading = false;
      state.allRestrictedAccountsData = payload.data;
      state.allRestrictedAcountsTotal = payload.total;
    },
    [getRestrictedFarmersSearch.rejected]: (state) => {
      state.allRestrictedsAcountsLoading = false;
    },

    // BLOCK FARMER
    [blockFarmer.pending]: (state) => {
      state.blockFarmerLoading = true;
    },
    [blockFarmer.fulfilled]: (state, { payload }) => {
      state.blockFarmerLoading = false;
      state.blockFarmerStatus = payload.status;
    },
    [blockFarmer.rejected]: (state) => {
      state.blockFarmerLoading = false;
    },

    /////////////////// FARMERS PROFILE
    [getFarmersProfile.pending]: (state) => {
      state.farmersProfileLoading = true;
    },
    [getFarmersProfile.fulfilled]: (state, { payload }) => {
      state.farmersProfileLoading = false;
      state.farmersProfileData = payload.data;
    },
    [getFarmersProfile.rejected]: (state) => {
      state.farmersProfileLoading = false;
    },

    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////// GET FARMER'S TRANSACTIONS  ----------
    [getAllBankTransactions.pending]: (state) => {
      state.allTransactionsLoading = true;
    },
    [getAllBankTransactions.fulfilled]: (state, { payload }) => {
      state.allTransactionsLoading = false;
      state.allTransactionsData = payload.results;
      state.allTransactionsTotal = payload.count;
    },
    [getAllBankTransactions.rejected]: (state) => {
      state.allTransactionsLoading = false;
    },

    /////////////////////////////////////  GET FARMER'S TRANSACTIONS  SEARCH ----------
    [getAllBankTransactionsSearch.pending]: (state) => {
      state.allTransactionsLoading = true;
    },
    [getAllBankTransactionsSearch.fulfilled]: (state, { payload }) => {
      state.allTransactionsLoading = false;
      state.allTransactionsData = payload.results;
      state.allTransactionsTotal = payload.count;
    },
    [getAllBankTransactionsSearch.rejected]: (state) => {
      state.allTransactionsLoading = false;
    },

    //////////////////////////////GET  FARMER'S DEBIT TRANSACTIONS   ----------
    [getDebitTransactions.pending]: (state) => {
      state.debitTransactionsLoading = true;
    },
    [getDebitTransactions.fulfilled]: (state, { payload }) => {
      state.debitTransactionsLoading = false;
      state.debitTransactionsData = payload.results;
      state.debitTransactionsTotal = payload.count;
    },
    [getDebitTransactions.rejected]: (state) => {
      state.debitTransactionsLoading = false;
    },

    //////////////////////////////////  GET  FARMER'S DEBIT TRANSACTIONS  SEARCH ----------
    [getDebitTransactionsSearch.pending]: (state) => {
      state.debitTransactionsLoading = true;
    },
    [getDebitTransactionsSearch.fulfilled]: (state, { payload }) => {
      state.debitTransactionsLoading = false;
      state.debitTransactionsData = payload.results;
      state.debitTransactionsTotal = payload.count;
    },
    [getDebitTransactionsSearch.rejected]: (state) => {
      state.debitTransactionsLoading = false;
    },

    //////////////////////////////// GET  FARMER'S CREDIT TRANSACTIONS  ----------
    [getCreditTransactions.pending]: (state) => {
      state.creditTransactionsLoading = true;
    },
    [getCreditTransactions.fulfilled]: (state, { payload }) => {
      state.creditTransactionsLoading = false;
      state.creditTransactionsData = payload.results;
      state.creditTransactionsTotal = payload.count;
    },
    [getCreditTransactions.rejected]: (state) => {
      state.creditTransactionsLoading = false;
    },

    ///////////////////////////////////   GET  FARMER'S CREDIT TRANSACTIONS  SEARCH ----------
    [getCreditTransactionsSearch.pending]: (state) => {
      state.creditTransactionsLoading = true;
    },
    [getCreditTransactionsSearch.fulfilled]: (state, { payload }) => {
      state.creditTransactionsLoading = false;
      state.creditTransactionsData = payload.results;
      state.creditTransactionsTotal = payload.count;
    },
    [getCreditTransactionsSearch.rejected]: (state) => {
      state.creditTransactionsLoading = false;
    },

    ////////////////////////////////////////ORDERS
    // ALL ORDERS
    [getAllFarmersOrder.pending]: (state) => {
      state.allOrdersLoading = true;
    },
    [getAllFarmersOrder.fulfilled]: (state, { payload }) => {
      state.allOrdersLoading = false;
      state.allOrdersData = payload.data;
      state.allOrdersTotal = payload.total;
    },
    [getAllFarmersOrder.rejected]: (state) => {
      state.allOrdersLoading = false;
    },

    // CROP ORDERS
    [getCropFarmersOrder.pending]: (state) => {
      state.cropOrdersLoading = true;
    },
    [getCropFarmersOrder.fulfilled]: (state, { payload }) => {
      state.cropOrdersLoading = false;
      state.cropOrdersData = payload.data;
      state.cropOrdersTotal = payload.input_total;
    },
    [getCropFarmersOrder.rejected]: (state) => {
      state.cropOrdersLoading = false;
    },

    // LIVESTOCK ORDERS
    [getLivestockFarmersOrder.pending]: (state) => {
      state.livestockOrdersLoading = true;
    },
    [getLivestockFarmersOrder.fulfilled]: (state, { payload }) => {
      state.livestockOrdersLoading = false;
      state.livestockOrdersData = payload.data;
      state.livestockOrdersTotal = payload.livestock_total;
    },
    [getLivestockFarmersOrder.rejected]: (state) => {
      state.livestockOrdersLoading = false;
    },

    // MECHANIZATION ORDERS
    [getMechanizationFarmersOrder.pending]: (state) => {
      state.mechanizationOrdersLoading = true;
    },
    [getMechanizationFarmersOrder.fulfilled]: (state, { payload }) => {
      state.mechanizationOrdersLoading = false;
      state.mechanizationOrdersData = payload.data;
      state.mechanizationOrdersTotal = payload.mechanization_total;
    },
    [getMechanizationFarmersOrder.rejected]: (state) => {
      state.mechanizationOrdersLoading = false;
    },

    // ADVISORY ORDERS
    [getAdvisoryFarmersOrder.pending]: (state) => {
      state.advisoryOrdersLoading = true;
    },
    [getAdvisoryFarmersOrder.fulfilled]: (state, { payload }) => {
      state.advisoryOrdersLoading = false;
      state.advisoryOrdersData = payload.data;
      state.advisoryOrdersTotal = payload.advisory_total;
    },
    [getAdvisoryFarmersOrder.rejected]: (state) => {
      state.advisoryOrdersLoading = false;
    },

    // AIRTIME ORDERS
    [getArtimeFarmersOrder.pending]: (state) => {
      state.airtimeOrdersLoading = true;
    },
    [getArtimeFarmersOrder.fulfilled]: (state, { payload }) => {
      state.airtimeOrdersLoading = false;
      state.airtimeOrdersData = payload.data;
      state.airtimeOrdersTotal = payload.total;
    },
    [getArtimeFarmersOrder.rejected]: (state) => {
      state.airtimeOrdersLoading = false;
    },

    // INSURANCE ORDERS
    [getInsuranceFarmersOrder.pending]: (state) => {
      state.insuranceOrdersLoading = true;
    },
    [getInsuranceFarmersOrder.fulfilled]: (state, { payload }) => {
      state.insuranceOrdersLoading = false;
      state.insuranceOrdersData = payload.data;
      state.insuranceOrdersTotal = payload.insurance_total;
    },
    [getInsuranceFarmersOrder.rejected]: (state) => {
      state.insuranceOrdersLoading = false;
    },

    //////////////USSD SESSIONS
    [getAllFarmersUssdSessions.pending]: (state) => {
      state.allFarmersUssdSessionLoading = true;
    },
    [getAllFarmersUssdSessions.fulfilled]: (state, { payload }) => {
      state.allFarmersUssdSessionLoading = false;
      state.allFarmersUssdSessionData = payload.data;
      state.allFarmersUssdSessionTotal = payload.total;
    },
    [getAllFarmersUssdSessions.rejected]: (state) => {
      state.allFarmersUssdSessionLoading = false;
    },

    [getCompleteFarmersUssdSessions.pending]: (state) => {
      state.completeFarmersUssdSessionLoading = true;
    },
    [getCompleteFarmersUssdSessions.fulfilled]: (state, { payload }) => {
      state.completeFarmersUssdSessionLoading = false;
      state.completeFarmerUssdSessionData = payload.data;
      state.completeFarmerUssdSessionTotal = payload.total;
    },
    [getCompleteFarmersUssdSessions.rejected]: (state) => {
      state.completeFarmersUssdSessionLoading = false;
    },

    [getIncompleteFarmersUssdSessions.pending]: (state) => {
      state.incompleteFarmersUssdSessionLoading = true;
    },
    [getIncompleteFarmersUssdSessions.fulfilled]: (state, { payload }) => {
      state.incompleteFarmersUssdSessionLoading = false;
      state.incompleteFarmerUssdSessionData = payload.data;
      state.incompleteFarmerUssdSessionTotal = payload.total;
    },
    [getIncompleteFarmersUssdSessions.rejected]: (state) => {
      state.incompleteFarmersUssdSessionLoading = false;
    },

    [EditFarmersDailyLimit.pending]: (state) => {
      state.dailyLimitLoading = true;
    },
    [EditFarmersDailyLimit.fulfilled]: (state, { payload }) => {
      state.dailyLimitLoading = false;
      state.isClickDailyLimit = false;
    },
    [EditFarmersDailyLimit.rejected]: (state) => {
      state.dailyLimitLoading = false;
    },

    // CALL HISTORY
    [getFarmersCallHistory.pending]: (state) => {
      state.farmersCallHistoryLoading = true;
    },
    [getFarmersCallHistory.fulfilled]: (state, { payload }) => {
      state.farmersCallHistoryLoading = false;
      state.farmersCallHistoryData = payload.data;
      state.farmersCallHistoryTotal = payload.total;
    },
    [getFarmersCallHistory.rejected]: (state) => {
      state.farmersCallHistoryLoading = false;
    },

    // REFUNDS
    [getFarmersRefund.pending]: (state) => {
      state.farmersRefundsLoading = true;
    },
    [getFarmersRefund.fulfilled]: (state, { payload }) => {
      state.farmersRefundsLoading = false;
      state.farmersRefundsData = payload.data;
      state.farmersRefundsTotal = payload.count;
    },
    [getFarmersRefund.rejected]: (state) => {
      state.farmersRefundsLoading = false;
    },

    // TEMPORARY FARMERS INFORMATION
    [getTemporaryFarmersInfo.pending]: (state) => {
      state.temporaryFarmersInfoLoading = true;
    },
    [getTemporaryFarmersInfo.fulfilled]: (state, { payload }) => {
      state.temporaryFarmersInfoLoading = false;
      state.temporaryFarmersInfoData = payload.data;
    },
    [getTemporaryFarmersInfo.rejected]: (state) => {
      state.temporaryFarmersInfoLoading = false;
    },

    // UPDATE TEMPORARY FARMERS INFORMATION
    [updateTemporaryFarmersInfo.pending]: (state) => {
      state.temporaryFarmersInfoLoading = true;
    },
    [updateTemporaryFarmersInfo.fulfilled]: (state, { payload }) => {
      state.temporaryFarmersInfoLoading = false;
      state.temporaryFarmersInfoData = payload.data;
    },
    [updateTemporaryFarmersInfo.rejected]: (state) => {
      state.temporaryFarmersInfoLoading = false;
    },

    // FARMER'S ORDER
    [getFarmersOrders.pending]: (state) => {
      state.farmersOrdersLoading = true;
    },
    [getFarmersOrders.fulfilled]: (state, { payload }) => {
      state.farmersOrdersLoading = false;
      state.farmersOrdersData = payload.data;
      state.farmersOrdersTotal = payload.total_display_price;
    },
    [getFarmersOrders.rejected]: (state) => {
      state.farmersOrdersLoading = false;
    },
    // KYC DOCUMENTATION
    [getFarmerRecords.pending]: (state) => {
      state.kycDocumentLoading = true;
    },
    [getFarmerRecords.fulfilled]: (state, { payload }) => {
      state.kycDocumentLoading = false;
      state.kycDocumentData = payload.reports;
    },
    [getFarmerRecords.rejected]: (state) => {
      state.kycDocumentLoading = false;
    },

    // UPLOAD KYC DOCUMENT
    [uploadKYCDocuments.pending]: (state) => {
      state.kycDocumentUploadLoading = true;
    },
    [uploadKYCDocuments.fulfilled]: (state, { payload }) => {
      state.kycDocumentUploadLoading = false;
    },
    [uploadKYCDocuments.rejected]: (state) => {
      state.kycDocumentUploadLoading = false;
    },

    //  DEBIT FARMER
    [debitFarmers.pending]: (state) => {
      state.debitFarmerLoading = true;
    },
    [debitFarmers.fulfilled]: (state, { payload }) => {
      state.debitFarmerLoading = false;
    },
    [debitFarmers.rejected]: (state) => {
      state.debitFarmerLoading = false;
    },

    // BULK DEBIT FARMER
    [bulkDebitFarmers.pending]: (state) => {
      state.bulkDebitloading = true;
    },
    [bulkDebitFarmers.fulfilled]: (state, { payload }) => {
      state.bulkDebitloading = false;
    },
    [bulkDebitFarmers.rejected]: (state) => {
      state.bulkDebitloading = false;
    },

    ///////////////////////////////////////// GENERATE PIN----------

    [farnersGeneratePin.pending]: (state) => {
      state.loadingResetPin = true;
    },
    [farnersGeneratePin.fulfilled]: (state, { payload }) => {
      state.loadingResetPin = false;
    },
    [farnersGeneratePin.rejected]: (state) => {
      state.loadingResetPin = false;
    },

    [tempFarnersGeneratePin.pending]: (state) => {
      state.loadingResetPin = true;
    },
    [tempFarnersGeneratePin.fulfilled]: (state, { payload }) => {
      state.loadingResetPin = false;
    },
    [tempFarnersGeneratePin.rejected]: (state) => {
      state.loadingResetPin = false;
    },
  },
});

const farmersReducer = persistReducer(
  farmersPersistConfig,
  farmersSlice.reducer
);

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  handleOpenDailyLimit,
  handleCloseDailyLimit,
} = farmersSlice.actions;

export default farmersReducer;
