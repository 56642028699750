import React, { useEffect } from "react";
import Header from "../../components/Header/index";
import Calendar from "../../assets/icons/calendar.svg";
import { Add } from "@mui/icons-material";
import CampaignTable from "../../tables/Campaign/campaign";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignStats } from "../../redux/Campaign/campaignAction";

const Campaign = () => {
  const dispatch = useDispatch();
  const { campaignStatsData } = useSelector((state) => state.campaign);

  useEffect(() => {
    dispatch(getCampaignStats());
  }, [dispatch]);

  return (
    <div>
      {/* HEDAER SECTION */}
      <section className="mb-5">
        <Header text="Campaign Overview">
          <div className="flex gap-10">
            <div className="flex bg-none border-primary text-sm leading-5 font-medium text-primary gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px]">
              <img src={Calendar} alt="Calendar Icon" />
              <p>All time</p>
            </div>
            <Link to="/add-campaign">
              <div className="flex bg-primary border-primary text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer">
                <Add style={{ fontSize: "20px" }} />
                <p>Add campaign</p>
              </div>
            </Link>
          </div>
        </Header>
      </section>

      {/* CARDS SECTION */}
      <section className="mb-5 flex gap-6">
        {/* 1 */}
        <div
          className={`bg-white rounded-lg py-4 px-4 border-l-[3px] border-l-primary w-[240px] h-[142px]`}
        >
          <h6 className="text-[#96A397] font-semibold text-sm">Campaign Log</h6>

          <p className="text-[#344335] font-semibold text-[20px] mt-5 mb-[26px]">
            {campaignStatsData?.total_campaigns || 0}
          </p>

          <div className="flex justify-end">
            <Link
              to={"/campaign-log"}
              className="text-[#2B8C34] text-[10px] font-semibold cursor-pointer"
            >
              See more
            </Link>
          </div>
        </div>

        {/* 2 */}
        <div
          className={`bg-white rounded-lg pr-4 w-[623px] h-[142px] py-[32px] flex`}
        >
          {/* 1 */}
          <div className="text-center flex justify-center items-center px-5">
            <p className="font-semibold text-sm leading-[18px] text-[#96A397]">
              Campaign Progress Report
            </p>
          </div>
          {/* 2 */}
          <div className="border border-[#CED5CF] w-[1px]"></div>
          {/* 3 */}
          <div className="flex gap-[20px] items-center">
            {/* 1 */}
            <div className="px-[20px]">
              <p className="pb-[12px] font-semibold text-sm leading-[18px] text-[#96A397]">
                Farmers Reached
              </p>
              <p className="pb-[12px] font-semibold text-2xl leading-[30px] text-[#344335]">
                {campaignStatsData?.total_calls || 0}/{" "}
                {campaignStatsData?.total_farmers || 0}
              </p>
            </div>
            {/* 2 */}
            <div className=" px-[20px]">
              <p className="pb-[12px] font-semibold text-sm leading-[18px] text-[#96A397]">
                Farmers Engaged
              </p>
              <p className="pb-[12px] font-semibold text-2xl leading-[30px] text-[#344335]">
                {campaignStatsData?.total_sales || 0}/{" "}
                {campaignStatsData?.total_calls || 0}
              </p>
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className={`bg-white rounded-lg py-4 px-4 w-[255px] h-[142px]`}>
          <h6 className="text-[#96A397] font-semibold text-sm">
            Total Achievement of Our Campaigns
          </h6>
          <div className="mt-2 w-[60px] ">
            <CircularProgressbar
              value={campaignStatsData?.progress || 0}
              text={`${campaignStatsData?.progress || 0}%`}
              className="w-[70px] h-[70px] text-primary text-2xl leading-[30px] font-[700px] campaign-circular-progressbar-div"
              strokeWidth={15}
              styles={buildStyles({
                textColor: "#344335",
                pathColor: "#2b8c34",
                textSize: "24px",
                fontWidth: "700",
              })}
            />
          </div>
        </div>
      </section>

      {/* TABLE SECTION */}
      <CampaignTable />
    </div>
  );
};

export default Campaign;
