import { useDispatch, useSelector } from "react-redux";
import ModalComponent from "../../../../components/Modals/modal";
import {
  handleCloseDailyLimit,
  handleOpenDailyLimit,
} from "../../../../redux/Farmers/Farmers";
import { Add, Close } from "@mui/icons-material";
import { useState } from "react";
import {
  cloudinaryUpload,
  getFarmerRecords,
  uploadKYCDocuments,
} from "../../../../redux/Farmers/farmersActions";
import { ThreeDots } from "react-loader-spinner";

const KYCModal = ({ id }) => {
  const { isClickDailyLimit, kycDocumentUploadLoading } = useSelector(
    (state) => state.farmer
  );
  const [note, setNote] = useState("");
  const [documents, setDocuments] = useState([
    {
      id: 1,
      name: "",
      image: "",
      file: "",
      loading: false,
    },
  ]);
  const dispatch = useDispatch();

  const handleDailyLimit = () => {
    dispatch(handleOpenDailyLimit());
  };

  const CloseDailyLimit = () => {
    dispatch(handleCloseDailyLimit());
  };

  const handleUploadDoc = () => {
    let targetValue = documents[documents.length - 1];
    let ID = targetValue?.id ? targetValue?.id + 1 : 0;
    const documentValue = {
      id: ID,
      name: "",
      image: "",
      file: "",
      loading: false,
    };
    setDocuments((prev) => [...prev, documentValue]);
  };

  const handleRemoveDocument = (index, id) => {
    let filteredValue = documents.filter((document) => document.id !== id);
    setDocuments(filteredValue);
  };

  const handleInputValues = (e, id) => {
    let value = e.target.value;
    let updatedDoc = documents.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          name: value,
        };
      }
      return product;
    });

    setDocuments(updatedDoc);
  };

  const handleSetFile = (event, id) => {
    setDocuments((prevDocuments) => {
      return prevDocuments.map((document) => {
        if (document?.id === id) {
          return {
            ...document,
            loading: true,
          };
        }
        return document;
      });
    });
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      const image = event.target.files[0];

      dispatch(cloudinaryUpload({ imageData: image })).then((res) => {
        if (res?.type === "cloudinaryUpload/fulfilled") {
          setDocuments((prevDocuments) => {
            return prevDocuments.map((document) => {
              if (document?.id === id) {
                return {
                  ...document,
                  image: res?.payload?.url,
                  file: res?.payload?.url,
                  loading: false,
                };
              }
              return document;
            });
          });
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleRemoveImages = (id) => {
    let updatedDoc = documents.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          image: "",
          file: "",
        };
      }
      return product;
    });
    setDocuments(updatedDoc);
  };

  const handleSave = () => {
    const documentsArr = documents.map((document) => ({
      type: document?.name,
      link: document?.file,
    }));
    const values = {
      note,
      reportLinks: documentsArr,
    };

    dispatch(uploadKYCDocuments({ values, id })).then((res) => {
      if (res.type === "uploadKYCDocuments/fulfilled") {
        CloseDailyLimit();
        dispatch(getFarmerRecords({ id }));
      }
    });
  };

  return (
    <div>
      {" "}
      <p className="text-sm font-medium leading-5" onClick={handleDailyLimit}>
        Create Report
      </p>
      {/* GENERATE STATEMENT MODAL */}
      <ModalComponent
        title="Create Report"
        show={isClickDailyLimit}
        showModal={CloseDailyLimit}
      >
        <div className="pt-[12px]">
          <div className="mb-4">
            <p className="text-[#344335] text-sm font-normal leading-[18px] pb-[6px]">
              Report note
            </p>
            <textarea
              onChange={(event) => setNote(event.target.value)}
              className="bg-[#ECEFEC] p-3 w-full text-[#344335] text-sm font-normal leading-[18px] placeholder:text-[#5C715E] rounded-lg h-[84px] resize-none outline-none"
              placeholder="Report Note"
            />
          </div>

          {/* DOCUMENTS */}
          {documents?.map((document, index) => {
            return (
              <div
                key={index}
                className={`border border-[#CED5CF] h-[68px] py-4 px-3 flex items-center justify-between rounded-lg  mb-[10px] ${
                  document?.file ? "gap-2" : "gap-[18px]"
                }`}
              >
                <div
                  className={`flex items-center gap-2 ${
                    document?.file ? "w-[65%]" : "w-[60%]"
                  }`}
                >
                  {/* INPUT */}
                  <div className="bg-[#ECEFEC] rounded-lg w-full ">
                    <input
                      onChange={(event) =>
                        handleInputValues(event, document?.id)
                      }
                      placeholder="Document Name"
                      value={document.name}
                      className="outline-none bg-transparent h-[36px] w-full text-[#1C2022] placeholder:text-[#5C715E] flex items-center px-3 text-sm leading-5  border-none  rounded-lg"
                    />
                  </div>
                  {/* IMAGE */}
                  {document?.loading ? (
                    <div>
                      <ThreeDots
                        visible={true}
                        height="20"
                        width="20"
                        color="#2B8C34"
                        radius="9"
                        ariaLabel="three-dots-loading"
                      />
                    </div>
                  ) : (
                    <>
                      {" "}
                      {document?.file && (
                        <div className="relative w-fit border-[0.71px] border-[#ECEFEC] rounded-[4px]">
                          <div className="w-[36px]">
                            <img
                              src={document?.file}
                              alt="Sample"
                              className="w-[36px] h-[36px] rounded-[4px]"
                            />
                          </div>
                          <div
                            onClick={() => handleRemoveImages(document?.id)}
                            className="absolute top-[1px] right-[1px] rounded-full bg-white border-[0.36px] border-[#CED5CF] flex justify-center items-center w-[11px] h-[11px]"
                          >
                            <Close
                              style={{ fontSize: "12px", color: "#475357" }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div
                  className={`flex items-center  justify-end gap-2 ${
                    document?.file ? "w-[35%]" : "w-[40%]"
                  }  cursor-pointer`}
                >
                  <label htmlFor={document?.id}>
                    <div className="border border-primary text-primary bg-none text-sm font-medium rounded-lg px-3 py-2 cursor-pointer">
                      <p className="text-[13px]">
                        {document?.file
                          ? "Reupload file"
                          : "Click to upload file"}
                      </p>
                    </div>

                    <input
                      id={document?.id}
                      required
                      accept=".jpg,.png,.jpeg,.svg"
                      onChange={(event) => handleSetFile(event, document?.id)}
                      type="file"
                      className=" w-full"
                    />
                  </label>

                  {/* CLOSE ICON */}
                  <div
                    className="flex justify-center items-center bg-[#F7F7F7] p-2 h-[36px] rounded-[4px] border border-[#DDE2DE]"
                    onClick={() => handleRemoveDocument(index, document?.id)}
                  >
                    <Close
                      className=""
                      style={{ color: "black", fontSize: "18px" }}
                    />
                  </div>
                </div>
              </div>
            );
          })}

          <div
            className="flex gap-[6px] cursor-pointer text-[#5C715E] text-sm font-medium mt-2"
            onClick={handleUploadDoc}
          >
            <Add style={{ fontSize: "20px", color: "#5C715E" }} />
            <p>Upload Another Document</p>
          </div>

          {/* <div className="mb-4 relative">
            <div
              {...getRootProps()}
              className="h-[134px]  w-full cursor-pointer gap-2 flex flex-col items-center justify-center rounded-[8px] border-[1px] border-[#A6A08C] bg-[#FFFFFF]"
            >
              <input {...getInputProps()} />
              <UploadFile className="text-[#5C715E]" />
              <p className=" text-primary text-sm font-medium  leading-[20px]">
                Choose file to upload{" "}
                <span className="text-[#5C715E]">or drag and drop</span>
              </p>
              <p className="text-sm font-medium text-[#475357]  leading-[16px] cursor-pointer">
                PNG and JPG
              </p>
            </div>
          </div>
          <div className="mb-6 flex  flex-wrap gap-3">
            {uploadedFiles?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="relative w-fit border border-[#475357] rounded-lg"
                >
                  <div className="">
                    <img
                      src={Sample}
                      className="w-[50px] h-[50px] rounded-lg"
                    />
                  </div>
                  <div className="absolute top-[1px] right-[1px] rounded-full bg-white border-[0.5px] border-[#CBD4D6] flex justify-center items-center w-[16px] h-[16px]">
                    <Close
                      onClick={() => handleDeleteImage(item, index)}
                      style={{ fontSize: "14px", color: "#475357" }}
                    />
                  </div>
                </div>
              );
            })}
          </div> */}

          <div className="flex flex-end justify-end mt-[30px]">
            <button
              onClick={handleSave}
              disabled={
                note.length !== 0 &&
                documents[0]?.name !== "" &&
                documents[0]?.image !== "" &&
                kycDocumentUploadLoading !== true
                  ? false
                  : true
              }
              type="button"
              className="w-[125px] text-white bg-primary py-3 flex justify-center items-center
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] font-medium
                         rounded-lg h-[44px] text-sm"
            >
              Save Report
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default KYCModal;
