import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardWithoutSeeMore } from "../../components/Cards";
import Header from "../../components/Header";
import CooperativeTable from "../../tables/Cooperative";
import { ButtonPrimary } from "../../components/Button/index";
import { getCooperativesStats } from "../../redux/Cooperative/cooperativeAction";

const Cooperative = () => {
  const dispatch = useDispatch();
  const { cooperativeStats } = useSelector((state) => state.cooperatives);

  useEffect(() => {
    dispatch(getCooperativesStats());
  }, [dispatch]);

  const cardsData = [
    {
      title: "Pending Cooperative ",
      subtitle: ` ${cooperativeStats?.no_of_pending_cooperatives || "0"}`,
      href: "/pending-cooperative",
    },
    {
      title: "No of Cooperatives",
      subtitle: ` ${cooperativeStats?.total || "0"}`,
    },
    {
      title: "Flagged Cooperatives",
      subtitle: ` ${cooperativeStats?.no_of_flagged_cooperatives || "0"}`,
      href: "/flagged-cooperative",
    },
  ];

  return (
    <div>
      <Header text="Cooperative">
        <ButtonPrimary href={`/create-cooperative`}>
          Create Cooperative
        </ButtonPrimary>
      </Header>

      {/* CARDS SECTION */}
      <section>
        <div className="grid grid-cols-4 gap-6 mt-6 mb-8">
          {cardsData.map((item, index) => {
            return (
              <>
                {item.href ? (
                  <Card
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                    href={item.href}
                  />
                ) : (
                  <CardWithoutSeeMore
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                  />
                )}
              </>
            );
          })}
        </div>
      </section>

      {/* TABLE SECTION */}
      <section>
        <CooperativeTable />
      </section>
    </div>
  );
};

export default Cooperative;
