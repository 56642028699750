import React from "react";
import { Link } from "react-router-dom";

export const ButtonPrimary = ({ href, onclick, children, style }) => {
  return (
    <div>
      <Link to={href}>
        <button
          className="outline-none bg-primary border border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]  hover:border hover:border-primary  transition ease-in-out delay-100"
          onClick={onclick}
          style={style}
        >
          {children}
        </button>
      </Link>
    </div>
  );
};

export const ButtonSecondary = ({ href, onclick, children }) => {
  return (
    <div>
      <Link to={href}>
        <button
          className="outline-none bg-white border border-primary text-primary font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px] hover:border hover:border-primary transition ease-in-out delay-100"
          onClick={onclick}
        >
          {children}
        </button>
      </Link>
    </div>
  );
};
