import React, { useEffect, useState } from "react";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import { Link, useParams } from "react-router-dom";
import { ContentCopy } from "@mui/icons-material";
import cogoToast from "cogo-toast";
import history_icon from "../../assets/icons/history_icon.svg";
import ModalComponent from "../../components/Modals/modal";
import { ButtonPrimary } from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchFarmerLoanPorfolio, getLoanByID, handleApproveLoan, handleRejectLoan } from "../../redux/Loans/loanActions";
import moment from "moment";
import { clearApproveSuccess } from "../../redux/Loans/loan";

const LoanApplicationReview = () => {
  const [show, setShow] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const {id} = useParams()
  const dispatch = useDispatch()
  const { loanInfo,approveLoader,approveSuccess,farmerLoanPorfolio } = useSelector((state) => state.loan);



  
  const showModal = () => {
    setShow(!show);
  };

  const showRejectModal = () => {
    setShowReject(!showReject);
  };

  const showHistoryModal = () => {
    setHistoryModal(!historyModal);
  };

  const [reason, setReason] = useState("");
  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  const getLoanBg = (val) => {
    let result
    switch (val) {
      case 'pending':
        result = 'pending-badge'
        break;
      case 'inactive':
        result = 'error-badge'
        break
      case 'active':
        result = 'success-badge'
        break;
        case 'completed':
          result = 'success-badge'
          break;
          case 'approved':
            result = 'success-badge'
            break;
        case 'rejected':
          result = 'error-badge'
          break;
      default:
        result = 'error-badge'
        break
    }
    return result
  }

  const getLoanText = (val) => {
    let result
    switch (val) {
      case 'pending':
        result = 'Pending'
        break;
      case 'inactive':
        result = 'Inactive'
        break
      case 'active':
        result = 'Active'
        break;
        case 'completed':
          result = 'Completed'
          break;
          case 'approved':
            result = 'Approved'
            break
          case 'rejected':
            result = 'Rejected'
            break;
      default:
        result = 'Pending'
        break
    }
    return result
  }

  const getFirstName = (fullName) => {
    const names = fullName ? fullName.split(' ') : 'Test User'.split(' ');
    return names.length > 0 ? names[0] : '';
  };

  const getLastName = (fullName) => {
    const names = fullName.split(' ');
    return names.length > 1 ? names[names.length - 1] : '';
  };

  const handleApproval = () =>{
    dispatch(handleApproveLoan({
      id, 
      status : "approved"
    }))
  }

  const handleRejection = () =>{
    dispatch(handleRejectLoan({
      id, 
      status : "rejected",
      message: reason
    }))
  }


  useEffect(()=>{
    dispatch(getLoanByID({
      id
    }))
  },[dispatch, id])

  useEffect(()=>{
    if(loanInfo.length > 0){
      let farmerId =  loanInfo[0].farmer.farmer_id
      dispatch(fetchFarmerLoanPorfolio({
        id: farmerId 
      }))
    }
  },[loanInfo, dispatch])

  useEffect(()=>{
    if(approveSuccess){
      setShowReject(false)
      setShow(false)
      dispatch(getLoanByID({
        id
      }))

      setTimeout(()=>{
        dispatch(clearApproveSuccess())
      },800)
    }
  },[approveSuccess, dispatch, id])

  return (
    <>
      {/* Approve loan */}
      <ModalComponent
        show={show}
        showModal={showModal}
       
        title="Approve Loan Application"
        subtitle="Are you sure you want to approve this loan application?"
      >
        <div className="flex justify-end mt-8 mb-3">
          <button
            type="submit"
            disabled={approveLoader}
            onClick={handleApproval}
            className="bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Approve
          </button>
          <button
            type="submit"
            onClick={() => setShow(false)}
            className="bg-transparent
                    rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
          >
            No
          </button>
        </div>
      </ModalComponent>

      {/* Reject loan */}
      <ModalComponent
        show={showReject}
        showModal={showRejectModal}
        title="Reason for Rejection"
        subtitle="Rejecting the application would mean the farmer won’t 
        get the loan he/she applied for."
      >
        <div className="mt-4">
          <p className="text-[#344335] text-sm">Reason for rejection</p>
          <textarea
            id="reason"
            rows="8"
            values={reason}
            onChange={(e) => setReason(e.target.value)}
            
            className="appearance-none w-full
                        placeholder:text-[#96A397] mt-1 placeholder:text-sm
                        text-secondary1
                        text-sm border border-[#96A397]
                        focus:border-[#ABB6AC]
                        focus:border outline-none
                        rounded-lg resize-none
                        bg-[#FFFFFF] py-3 px-4"
            placeholder="Describe your reason"
          ></textarea>
        </div>

        <div className="mt-5 mb-3">
          <button
            disabled={reason === "" ||  approveLoader}
            type="submit"
            onClick={handleRejection}
            className="bg-[#B92043] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7] w-full
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-opacity-[0.9] hover:bg-[#B92043] transition-all"
          >
            Reject Loan Application
          </button>
        </div>
      </ModalComponent>

      {/* order history modal */}
      <ModalComponent
        show={historyModal}
        showModal={showHistoryModal}
        title="Loan Application History"
        subtitle=""
      >
        <div className="mt-8">
          <div className="mb-4">
            <h6 className="text-[#344335] text-sm font-semibold">
              Celina Avong
            </h6>

            <p className="text-[#7C8D7E] text-xs font-medium mt-1">
              Changed{" "}
              <span className="font-medium text-[#344335]">
                <span className="font-semibold">Loan Status</span> from{" "}
                <span className="text-[#D4A407] font-semibold">Pending</span> to{" "}
                <span className="text-[#2B8C34] font-semibold">Approved</span>
              </span>
            </p>
            <p className="text-[#344335] text-xs font-medium mt-1">
              Mar 27, 2023 02:36 PM
            </p>
          </div>

          <div className="mb-4">
            <h6 className="text-[#344335] text-sm font-semibold">
              Abdullahi Adamu
            </h6>

            <p className="text-[#7C8D7E] text-xs font-medium mt-1">
              Changed{" "}
              <span className="font-medium text-[#344335]">
                <span className="font-semibold">Loan Status</span> from{" "}
                <span className="text-[#2B8C34] font-semibold">Approved</span>{" "}
                to{" "}
                <span className="text-[#004C92] font-semibold">Disbursed</span>
              </span>
            </p>
            <p className="text-[#344335] text-xs font-medium mt-1">
              Mar 27, 2023 02:36 PM
            </p>
          </div>
        </div>
      </ModalComponent>

      <GoBack />
      <div className="mt-4">
        <Header text="Loan Application Review"></Header>
      </div>
      <div className="mt-4 bg-white rounded-lg py-4 px-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-[8px]">
            {/* FARMER"S INITIALS */}
            <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex items-center justify-center">
              <p className="font-medium text-base leading-6 ">
              {loanInfo && loanInfo.length > 0 ? (getFirstName(loanInfo[0]?.farmer.farmer_name).charAt(0).toUpperCase()) : 'T'}{loanInfo && loanInfo.length > 0 ? getLastName(loanInfo[0].farmer.farmer_name).charAt(0).toUpperCase() : 'U'}
              </p>
            </div>
            <div className="">
              <p className="text-[#344335] font-semibold leading-[18px] text-sm">
              {loanInfo && loanInfo.length > 0  ? loanInfo[0].farmer.farmer_name : 'N/A'}
              </p>
              <div
                className="flex gap-[6px] mt-[4px] cursor-pointer"
                onClick={() => handleCopyNumber(loanInfo && loanInfo.length > 0  ? loanInfo[0].farmer.farmer_phone_number : 'N/A')}
              >
                <p className="text-primary font-semibold leading-[18px] text-sm">
                {loanInfo && loanInfo.length > 0  ? loanInfo[0]?.farmer?.farmer_phone_number : 'N/A'}
                </p>
                <ContentCopy
                  className="text-primary"
                  style={{ fontSize: "18px" }}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <ButtonPrimary
                style={{ paddingTop: "12px", paddingBottom: "12px" }}
                href={`/add-guarantor/${loanInfo[0]?.farmer?.farmer_id}`}
              >
                Add Guarantors
              </ButtonPrimary>
            </div>
            <div>
              <Link
                to={`/farmers-profile/${loanInfo[0]?.farmer?.farmer_id}`}
                className="w-full py-3 flex items-center justify-center px-6 bg-[#fff] border border-primary rounded-[8px] text-primary hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
              >
                <p className="font-medium text-sm">Farmer Profile</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center gap-[8px] mt-8">
          <div>
            <h6 className="font-[400] text-sm text-[#7C8D7E]">Date Created</h6>
            <p className="font-medium text-sm text-[#344335]">
              {loanInfo && loanInfo.length > 0  ? moment(loanInfo[0].date_created).format('Do, MMM YYYY'): 'N/A'}
            </p>
          </div>
          <div>
            <h6 className="font-[400] text-sm text-[#7C8D7E]">Disbursed Date</h6>
            <p className="font-medium text-sm text-[#344335]">
            {loanInfo && loanInfo.length > 0  ? (loanInfo[0].date_disbursed ? moment(loanInfo[0].date_disbursed).format('Do, MMM YYYY') : 'Not disbursed'): 'N/A'}
            </p>
          </div>
          <div>
            <h6 className="font-[400] text-sm text-[#7C8D7E]">Cooperative</h6>
            <p className="font-medium text-sm text-[#344335]">
              {loanInfo && loanInfo.length > 0  ? loanInfo[0].cooperative.cooperative_name : 'N/A'}
            </p>
          </div>
          <div>
            <h6 className="font-[400] text-sm text-[#7C8D7E]">State</h6>
            <p className="font-medium text-sm text-[#344335]">Oyo State</p>
          </div>
          <div>
            <h6 className="font-[400] text-sm text-[#7C8D7E]">Loan Officer</h6>
            <p className="font-medium text-sm text-[#344335]"> {loanInfo && loanInfo.length > 0  ? loanInfo[0].loan_officer.loan_profile_name : 'N/A'}</p>
          </div>
          <div>
            <h6 className="font-[400] text-sm text-[#7C8D7E]">Cooperative Code</h6>
            <p className="font-medium text-sm text-[#344335]"> {loanInfo && loanInfo.length > 0  ? loanInfo[0].cooperative.cooperative_code : 'N/A'}</p>
          </div>
        </div>
      </div>

      <div className="mt-5 grid grid-cols-3 gap-4">
        <div>
          <div className="bg-white rounded-lg py-4 px-4 max-h-[300px] overflow-y-scroll">
            <div className="flex justify-between">
              <div>
                <p className="text-[#7C8D7E] font-semibold text-sm">
                  Loan History
                </p>
              </div>
            </div>

              {
                farmerLoanPorfolio && farmerLoanPorfolio.length ?
                farmerLoanPorfolio.map((val)=>(
                  <div key={val.id} className="mt-4 bg-[#ECEFEC] rounded-lg py-4 px-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <h6 className="text-[#5C715E] text-sm">Amount Recovered</h6>
                      <p className="text-[#344335] text-sm font-semibold">
                        ₦{val.loan_amount_repaid}
                      </p>
                    </div>
                    <div>
                      <h6 className="text-[#5C715E] text-sm">Amount Disbursed</h6>
                      <p className="text-[#344335] text-sm font-semibold">
                        ₦{val.loan_amount_disbursed}
                      </p>
                    </div>
                  </div>
    
                  <div className="flex justify-between items-center mt-4">
                    <p className="text-[#5C715E] text-xs font-medium">
                      {val.date_created}
                    </p>
                  </div>
                </div>
                ))
                :
                ""
              }
             
            

          
          </div>
        </div>

        <div className="col-span-2">
          <div className="bg-white rounded-lg py-4 px-4">
            <div className="flex justify-between">
              <div>
                <p className="text-[#7C8D7E] font-semibold text-sm">
                  Total Amount
                </p>
                <h4 className="text-primary font-semibold text-xl">
                  ₦{loanInfo && loanInfo.length > 0  ? loanInfo[0].loan_principal : 'N/A'}
                </h4>
              </div>
              <div>
                <button
                  onClick={showHistoryModal}
                  className="flex items-center gap-2 bg-[#EDF7EE] rounded-lg py-3 px-6 cursor-pointer"
                >
                  <img src={history_icon} alt="history" />
                  <p className="text-primary font-medium text-sm">
                    View History
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg py-4 px-4 mt-4">
            <p className="text-[#7C8D7E] font-semibold text-sm">Loan Details</p>

            <div className="flex justify-between mt-5">
              <div className="flex-1">
                <h6 className="font-[400] text-sm text-[#7C8D7E]">
                  Loan Package
                </h6>
                <p className="font-medium text-sm text-[#344335]">{loanInfo && loanInfo.length > 0  ? loanInfo[0].loan_package.loan_package_name : 'N/A'}</p>
              </div>
              <div className="flex-1">
                <h6 className="font-[400] text-sm text-[#7C8D7E]">
                  Loan Status
                </h6>
                <div className="flex">
                  <div className={getLoanBg(loanInfo && loanInfo.length > 0  ? loanInfo[0].loan_status : 'N/A')}>
                    <p>{getLoanText(loanInfo && loanInfo.length > 0  ? loanInfo[0].loan_status : 'N/A')}</p>
                  </div>
                </div>
              </div>
            </div>

            {loanInfo && loanInfo.length > 0  && loanInfo[0].loan_status === 'pending' &&
              <div className="mt-5 flex gap-4">
                <button

                  className="py-3 disabled:bg-[#ABB6AC] 
                                  flex items-center justify-center px-6 bg-[#2B8C34]
                                  rounded-[8px] text-white  text-sm font-medium transition-all"
                  onClick={showModal}
                  disabled={(loanInfo && loanInfo.length > 0  && loanInfo[0].loan_status !== 'pending')}
                >
                  Approve Loan
                </button>
                <button
                  onClick={showRejectModal}
                  disabled={(loanInfo && loanInfo.length > 0  && loanInfo[0].loan_status !== 'pending') }
                  className="py-3 
                                  flex items-center justify-center px-6 bg-[#FEF0EC] 
                                    rounded-[8px] text-[#B92043] disabled:bg-[#ABB6AC] disabled:text-white
                                  text-sm font-medium  transition-all"
                >
                  Reject Loan
                </button>
              </div>
             }


          </div>

          <div className="bg-white rounded-lg py-4 px-4 mt-4">
            <div className="flex">
                <div className="flex-1">
                  <h6 className="font-[400] text-sm text-[#7C8D7E]">Item Name</h6>
                </div>
                <div className="flex-1">
                  <h6 className="font-[400] text-sm text-[#7C8D7E]">Quantity</h6>
                </div>
              </div>
              {
                loanInfo && loanInfo.length > 0 
                ?
                loanInfo[0].loan_package.loan_items.map((item, index) => (
                  <>
                    <div key={index} className="flex mt-2 mb-2">
                  <div className="flex-1">
                    <p className="font-medium text-sm text-[#344335]">
                     {item.loan_item}
                    </p>
                  </div>
                  <div className="flex-1">
                    <p className="font-medium text-sm text-[#344335]">{item.item_quantity}</p>
                  </div>
                  </div>
                  </>
                ))
                :
                ""
              }
                 
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanApplicationReview;
