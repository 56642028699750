import { Help, Star, StarHalf } from "@mui/icons-material";
import React from "react";

export const PlatinumDescription = ({ handleClick }) => {
  return (
    <div>
      <div
        className="bg-[#4B4E68] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
        <p className="text-white leading-5 text-sm font-medium">Platinum</p>
        <Help
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const GoldDescription = ({ handleClick }) => {
  return (
    <div>
      <div
        className="bg-[#F9B43B] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />

        <p className="text-white leading-5 text-sm font-medium">Gold</p>
        <Help
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const SilverDescription = ({ handleClick }) => {
  return (
    <div>
      <div
        className="bg-[#4B7295] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />

        <p className="text-white leading-5 text-sm font-medium">Silver</p>
        <Help
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const BronzeDescription = ({ handleClick }) => {
  return (
    <div>
      <div
        className="bg-[#E86454] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <Star
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />

        <p className="text-white leading-5 text-sm font-medium">Bronze</p>
        <Help
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const CopperDescription = ({ handleClick }) => {
  return (
    <div>
      <div
        className="bg-[#6A1505] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <StarHalf
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
        <p className="text-white leading-5 text-sm font-medium">Copper</p>
        <Help
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};
