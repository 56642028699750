import React, { useMemo, useState, useCallback, useEffect } from "react";
import SearchComponent from "../../components/Search";
import PaginationComponent from "../../components/Pagination/Pagination";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { getAllActiveLoans } from "../../redux/Loans/loanActions";
import moment from 'moment'
const ActiveLoansTable = () => {

  const [searchValue, setSearchValue] = useState("");
  const { loading,activeLoans,loansCount } = useSelector((state) => state.loan);
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
  };

  const [typeID, setTypeID] = useState(1);
  const loanTypes = [
    {
      id: 1,
      title: "Active",
    },
  ];

  const toggleType = (id) => {
    setTypeID(id);
  };

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        // dispatch(
        //     searchCallRequests({
        //       val: val,
        //       status: 'all',
        //     }),
        //   )
        console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(()=>{
      dispatch(getAllActiveLoans())
  },[dispatch])

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Active Loans
          </p>

          {/* search */}
          <div className="mt-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {loanTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : "bg-[#ECEEEC] rounded-[4px] py-1 px-1 text-[#96A397] font-semibold text-[10px]"
                  }
                >
                  {activeLoans.length}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Agent
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Farmer's Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Loan Amt
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Loan Type 
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Date Created
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Due Date
                </th>
               
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {activeLoans.length > 0
                  ? activeLoans.map((item) => (
                    <tr key={item.id} className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                    <th
                      scope="row"
                      className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                    >
                      {item.loan_officer.loan_profile_name}
                    </th>
    
                    <td className="py-4 px-4 text-[#5C715E]">{item.farmer.farmer_name}</td>
                    <td className="py-4 px-4 text-[#5C715E]">{item.loan_principal}</td>
                    <td className="py-4 px-4">{item.loan_type ? item.loan_type : 'N/A'}</td>
                    <td className="py-4 px-4 text-[#5C715E]">{moment(item.date_created).format('Do, MMM YYYY')}</td>
                    <td className="py-4 px-4">{item.due_date ? moment(item.due_date).format('Do, MMM YYYY') :'N/A'}</td>
                   
                  </tr>
                  ))
                  : ""}
              </tbody>
            )}

           
          </table>

          {loading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={loansCount}
        />
      </div>
    </>
  );
};

export default ActiveLoansTable;
