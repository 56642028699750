import { createSlice } from "@reduxjs/toolkit";
import {
  agentApproval,
  getAgent,
  getAgentLoanPorfolio,
  getAgentOrders,
  getAgentReferrals,
  getAgentTransactions,
  getAgentUssdSessions,
  getAgents,
  getAgentsStats,
  getFlaggedAgents,
  getPendingAgents,
} from "./agentsAction";

const initialState = {
  agentsData: [],
  agentsTotal: 0,
  agentsLoading: false,

  agentsStats: {},
  agentsStatsLoading: false,

  flaggedAgentsData: [],
  flaggedAgentsLoading: false,
  flaggedAgentsTotal: 0,

  agentData: {},
  agentLoading: false,

  agentReferralData: [],
  agentReferralLoading: false,
  agentReferralTotal: 0,

  agentOrderData: [],
  agentOrderLoading: false,
  agentOrderTotal: 0,

  agentTransactionsData: [],
  agentTransactionsLoading: false,
  agentTransactionsTotal: 0,

  agentUssdSessionsData: [],
  agentUssdSessionsLoading: false,
  agentUssdSessionsTotal: 0,

  agentLoanPorfolioData: [],
  agentLoanPorfolioLoading: false,
  agentLoanPorfolioTotal: 0,

  pendingAgentsData: [],
  pendingAgentsTotal: 0,
  pendingAgentsLoading: false,

  agentApprovalLoading: false,
};

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
  extraReducers: {
    ////////////////////////////////////// GET AGENTS  ----------
    [getAgents.pending]: (state) => {
      state.agentsLoading = true;
    },
    [getAgents.fulfilled]: (state, { payload }) => {
      state.agentsLoading = false;
      state.agentsData = payload.data;
      state.agentsTotal = payload.total;
    },
    [getAgents.rejected]: (state) => {
      state.agentsLoading = false;
    },

    ////////////////////////////////////// GET AGENTS STATS  ----------
    [getAgentsStats.pending]: (state) => {
      state.agentsStatsLoading = true;
    },
    [getAgentsStats.fulfilled]: (state, { payload }) => {
      state.agentsStatsLoading = false;
      state.agentsStats = payload;
    },
    [getAgentsStats.rejected]: (state) => {
      state.agentsStatsLoading = false;
    },

    ////////////////////////////////////// GET FLAGGED AGENTS  ----------
    [getFlaggedAgents.pending]: (state) => {
      state.flaggedAgentsLoading = true;
    },
    [getFlaggedAgents.fulfilled]: (state, { payload }) => {
      state.flaggedAgentsLoading = false;
      state.flaggedAgentsData = payload.data;
      state.flaggedAgentsTotal = payload.total;
    },
    [getFlaggedAgents.rejected]: (state) => {
      state.flaggedAgentsLoading = false;
    },

    ////////////////////////////////////// GET  AGENT  ----------
    [getAgent.pending]: (state) => {
      state.agentLoading = true;
    },
    [getAgent.fulfilled]: (state, { payload }) => {
      state.agentLoading = false;
      state.agentData = payload.data;
    },
    [getAgent.rejected]: (state) => {
      state.agentLoading = false;
    },

    ////////////////////////////////////// GET  AGENT REFERRAL  ----------
    [getAgentReferrals.pending]: (state) => {
      state.agentReferralLoading = true;
    },
    [getAgentReferrals.fulfilled]: (state, { payload }) => {
      state.agentReferralLoading = false;
      state.agentReferralData = payload.data;
      state.agentReferralTotal = payload.total;
    },
    [getAgentReferrals.rejected]: (state) => {
      state.agentReferralLoading = false;
    },

    ////////////////////////////////////// GET  AGENT REFERRAL  ----------
    [getAgentOrders.pending]: (state) => {
      state.agentOrderLoading = true;
    },
    [getAgentOrders.fulfilled]: (state, { payload }) => {
      state.agentOrderLoading = false;
      state.agentOrderData = payload.data;
      state.agentOrderTotal = payload.total;
    },
    [getAgentOrders.rejected]: (state) => {
      state.agentOrderLoading = false;
    },

    ////////////////////////////////////// GET  AGENT TRANSACTIONS  ----------
    [getAgentTransactions.pending]: (state) => {
      state.agentTransactionsLoading = true;
    },
    [getAgentTransactions.fulfilled]: (state, { payload }) => {
      state.agentTransactionsLoading = false;
      state.agentTransactionsData = payload.data;
      state.agentTransactionsTotal = payload.total;
    },
    [getAgentTransactions.rejected]: (state) => {
      state.agentTransactionsLoading = false;
    },

    ////////////////////////////////////// GET  AGENT USSD SESSIONS  ----------
    [getAgentUssdSessions.pending]: (state) => {
      state.agentUssdSessionsLoading = true;
    },
    [getAgentUssdSessions.fulfilled]: (state, { payload }) => {
      state.agentUssdSessionsLoading = false;
      state.agentUssdSessionsData = payload.data;
      state.agentUssdSessionsTotal = payload.total;
    },
    [getAgentUssdSessions.rejected]: (state) => {
      state.agentUssdSessionsLoading = false;
    },

    ////////////////////////////////////// GET  AGENT LOAN PORFOLIO ----------
    [getAgentLoanPorfolio.pending]: (state) => {
      state.agentLoanPorfolioLoading = true;
    },
    [getAgentLoanPorfolio.fulfilled]: (state, { payload }) => {
      state.agentLoanPorfolioLoading = false;
      state.agentLoanPorfolioData = payload.loans;
      state.agentLoanPorfolioTotal = payload.total;
    },
    [getAgentLoanPorfolio.rejected]: (state) => {
      state.agentLoanPorfolioLoading = false;
    },

    ////////////////////////////////////// GET  PENDING AGENTS----------
    [getPendingAgents.pending]: (state) => {
      state.pendingAgentsLoading = true;
    },
    [getPendingAgents.fulfilled]: (state, { payload }) => {
      state.pendingAgentsLoading = false;
      state.pendingAgentsData = payload.data;
      state.pendingAgentsTotal = payload.total;
    },
    [getPendingAgents.rejected]: (state) => {
      state.pendingAgentsLoading = false;
    },

    [agentApproval.pending]: (state) => {
      state.agentApprovalLoading = true;
    },
    [agentApproval.fulfilled]: (state, { payload }) => {
      state.agentApprovalLoading = false;
    },
    [agentApproval.rejected]: (state) => {
      state.agentApprovalLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement } = agentsSlice.actions;

export default agentsSlice.reducer;
