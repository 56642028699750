import React, { useState, useEffect } from "react";
import DrawerComponent from "../../components/Drawers/drawers";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import moment from "moment";
import user_green from "../../assets/icons/user_green.svg";
import { FilterList } from "@mui/icons-material";
import { getMessages } from "../../redux/Messages/messageActions";
import { useDispatch, useSelector } from "react-redux";

const MessagesTable = () => {
  const dispatch = useDispatch();
  const { messages, loading, total } = useSelector((state) => state.message);

  const [typeID, setTypeID] = useState(1);
  const messageTypes = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Delivered",
    },
    { id: 3, title: "Not Delivered" },
  ];

  const toggleType = (id) => {
    setTypeID(id);
    switch (id) {
      case 1:
        dispatch(
          getMessages({
            page: 1,
            type: "all",
          })
        );
        break;
      case 2:
        dispatch(
          getMessages({
            page: 1,
            type: "delivered",
          })
        );
        break;
      case 3:
        dispatch(
          getMessages({
            page: 1,
            type: "failed",
          })
        );
        break;
      default:
        break;
    }
  };

  const getMessageBg = (val) => {
    let result;
    switch (val) {
      case "failed":
        result = "error-badge";
        break;
      case "delivered":
        result = "success-badge";
        break;
      default:
        result = "pending-badge";
        break;
    }
    return result;
  };

  const getMessageText = (val) => {
    let result;
    switch (val) {
      case "failed":
        result = "Failed";
        break;
      case "delivered":
        result = "Delivered";
        break;
      default:
        result = "Pending";
        break;
    }
    return result;
  };

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [messageInfo] = useState({})

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    switch (typeID) {
      case 1:
        dispatch(
          getMessages({
            page: page,
            type: "all",
          })
        );
        break;
      case 2:
        dispatch(
          getMessages({
            page: page,
            type: "delivered",
          })
        );
        break;
      case 3:
        dispatch(
          getMessages({
            page: page,
            type: "failed",
          })
        );
        break;
      default:
        break;
    }
  };
  const closeMessage = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [messageInfo, setMessageInfo] = useState({});

  const toggleRequest = (id) => {
    setIsDrawerOpen(!isDrawerOpen);
    // find the request selected
    let message = messages.find((val) => val.id === id);
    setMessageInfo(message);
  };

  useEffect(() => {
    dispatch(
      getMessages({
        page: 1,
        type: "all",
      })
    );
  }, [dispatch]);

  return (
    <>
      {/* message info drawer */}
      <DrawerComponent
        title="Message Info"
        show={isDrawerOpen}
        showDrawer={closeMessage}
      >
        <div className="mt-8">
          <div className="flex justify-between items-center">
            {" "}
            <div>
              <h5 className="text-xs text-[#5C715E]">Recipients</h5>
              <p className="font-medium text-[#5C715E] text-sm mt-1">
                {messageInfo && messageInfo.farmer
                  ? `${messageInfo.farmer.first_name}`
                  : "N/A"}{" "}
                {messageInfo && messageInfo.farmer
                  ? `${messageInfo.farmer.last_name}`
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Phone Number</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {messageInfo && messageInfo.farmer
              ? `${messageInfo.farmer.phone_number}`
              : "N/A"}
          </p>
        </div>
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Status</h5>
          <div className="flex mt-2">
            <div
              className={getMessageBg(
                messageInfo && messageInfo.status
                  ? messageInfo.status
                  : "failed"
              )}
            >
              <p>
                {getMessageText(
                  messageInfo && messageInfo.status
                    ? messageInfo.status
                    : "failed"
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Date</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {messageInfo && messageInfo.created
              ? moment(messageInfo.created).format("Do, MMM YYYY")
              : "N/A"}
          </p>
        </div>
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {messageInfo && messageInfo.created
              ? moment(messageInfo.created).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>{" "}
        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Language</h5>
          <div className="flex mt-2">
            <div className="bg-[#ECEEEC] text-[#344335] text-xs font-medium px-4 py-1 rounded-[4px]">
              <p>English</p>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Message</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1 max-w-md">
            {messageInfo && messageInfo.farmer
              ? `${messageInfo.message}`
              : "N/A"}
          </p>
        </div>
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Sender</h5>
          <div className="flex gap-2 items-center mt-2">
            <div className="bg-[#DBFFDF] w-[24px] h-[24px] rounded-full flex justify-center items-center">
              <img src={user_green} alt="user" />
            </div>
            <div>
              <p className="font-medium text-[#5C715E] text-sm">
                {messageInfo && messageInfo.farmer
                  ? `${messageInfo.user.first_name}`
                  : "N/A"}{" "}
                {messageInfo && messageInfo.farmer
                  ? `${messageInfo.user.last_name}`
                  : "N/A"}{" "}
              </p>
            </div>
          </div>
        </div>
      </DrawerComponent>

      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Messages
          </p>
          {/* search */}
          <div className="flex gap-4 mt-4">
            <div className="w-full">
              <SearchComponent
                placeholder="Search..."
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>
            <div>
              <div className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer">
                <p className="">Filters</p>
                <FilterList style={{ fontSize: "18px" }} />
              </div>
            </div>
          </div>{" "}
          <ul className="flex mt-5 gap-5 items-center">
            {messageTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : ""
                  }
                >
                  {typeID === item.id ? total : ""}
                </span>
              </li>
            ))}
          </ul>{" "}
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Recipient
                </th>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Phone Number
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Message
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Date/Time
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Status
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {messages.length > 0
                  ? messages.map((item) => (
                      <tr
                        onClick={() => toggleRequest(item.id)}
                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      >
                        {" "}
                        <th
                          scope="row"
                          className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                        >
                          {item?.farmer?.first_name || "N/A"}{" "}
                          {item?.farmer?.last_name || "N/A"}
                        </th>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item?.farmer?.phone_number || "N/A"}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {truncateString(item.message, 40)}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.created
                            ? moment(item.created).format(
                                "Do, MMM YYYY hh:mm:ss a"
                              )
                            : ""}
                        </td>
                        <td className="py-4 px-4">
                          <div className="flex">
                            <div className={getMessageBg(item.status)}>
                              <p>{getMessageText(item.status)}</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            )}{" "}
          </table>

          {loading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={total}
        />
      </div>
    </>
  );
};

export default MessagesTable;
