import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import GoBack from "../../components/GoBack";
import TableBackground from "../../components/TableBackground";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  cooperativeApproval,
  getACooperative,
} from "../../redux/Cooperative/cooperativeAction";
import { ButtonPrimary } from "../../components/Button/index";
import ModalComponent from "../../components/Modals/modal";
import Loading from "../../components/Loading/Loading";
import moment from "moment";
import { ContentCopy } from "@mui/icons-material";
import cogoToast from "cogo-toast";

const Cooperative = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState("account_information");

  const [reason, setReason] = useState("");
  const { cooperativeInfoData, cooperativeInfoLoading, approvalStatusLoading } =
    useSelector((state) => state.cooperatives);

  let file = null;
  const showModal = () => {
    setShow(!show);
  };

  const [showReject, setShowReject] = useState(false);

  const showRejectModal = () => {
    setShowReject(!showReject);
  };

  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  const handleStatus = (status) => {
    const approvedvalues = {
      cooperative_status: status,
    };

    const rejectedValues = {
      cooperative_status: status,
      reason: reason,
    };
    dispatch(
      cooperativeApproval({
        id,
        values: status === "approved" ? approvedvalues : rejectedValues,
      })
    );

    setTimeout(() => {
      setShowReject(false);
      setShow(false);
      dispatch(getACooperative({ id: id }));
    }, 800);
  };

  useEffect(() => {
    dispatch(getACooperative({ id: id }));
  }, [dispatch, id]);

  return (
    <div>
      <div className="mb-6">
        <GoBack />
      </div>
      <Header text="Cooperative Profile"></Header>

      {cooperativeInfoLoading ? (
        <div className="flex justify-center h-[300px] items-center">
          <Loading />
        </div>
      ) : (
        <>
          {cooperativeInfoData?.status === "pending" ||
          cooperativeInfoData?.status === "inactive" ||
          cooperativeInfoData?.status === "unapproved" ? (
            <>
              <TableBackground classname="p-[25px] mt-6 rounded-[8px] mb-6">
                {/* -----------Loan Details SECTION --------------------- */}
                <div>
                  <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E] mb-4">
                    Cooperative Details
                  </p>

                  <div>
                    <div className="w-full gap-6 flex">
                      {/* ------   Cooperative Name -------------- */}
                      <div className="w-1/3 relative">
                        <p className="text-sm font-[400]  leading-[18px] text-[#344335] mb-1">
                          Cooperative Name
                        </p>

                        <div>
                          <input
                            disabled
                            value={cooperativeInfoData?.name}
                            type="text"
                            className="outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                          />
                        </div>
                      </div>

                      {/* ------   Cooperative Registration Number-------------- */}
                      <div className="w-1/3 relative">
                        <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                          Cooperative Registration Number
                        </p>

                        <div>
                          <input
                            disabled
                            value={cooperativeInfoData?.code}
                            type="text"
                            className="outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                          />
                        </div>
                      </div>

                      {/* ------   Agent -In -Charge-------------- */}
                      <div className="w-1/3 relative">
                        <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                          Agent -In -Charge
                        </p>

                        <div className="relative">
                          <input
                            disabled
                            value={cooperativeInfoData?.agent?.phone_number}
                            type="text"
                            className="outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                          />
                          <p className="absolute text-xs font-[500] leading-[16px] text-[#5C715E] mt-1">
                            {cooperativeInfoData?.agent?.id}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="w-full gap-6 flex mt-[44px]">
                      {/* ------   Address -------------- */}
                      <div className="w-1/3 relative">
                        <p className="text-sm font-[400]  leading-[18px] text-[#344335] mb-1">
                          Address
                        </p>

                        <div>
                          <textarea
                            disabled
                            value={cooperativeInfoData?.address}
                            type="text"
                            placeholder="Address"
                            className="resize-none h-[134px] outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                          />
                        </div>
                      </div>

                      {/* ------   STATE AND LGA -------------- */}
                      <div className="w-1/3 relative">
                        <div className="">
                          <div className="relative">
                            <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                              State
                            </p>
                            <div className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] ">
                              <p>{cooperativeInfoData?.state?.display_name}</p>
                            </div>
                          </div>
                        </div>

                        <div className="mt-6">
                          {/* LOCAL GOVERNMENT */}
                          <div className="relative">
                            <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                              Local Government
                            </p>
                            <div className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397]">
                              <p>{cooperativeInfoData?.lga?.display_name}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ------   Cooperative Certificate -------------- */}
                      <div className="w-1/3 relative">
                        <p className="text-sm font-[400]  leading-[18px] text-[#344335] mb-1">
                          Cooperative Certificate
                        </p>

                        <label>
                          {file ? (
                            <img
                              src={file}
                              alt="  Cooperative Certificate"
                              className="h-[134px] w-full border-[1px] border-[#A6A08C] bg-[#FFFFFF] rounded-[8px] object-cover"
                            />
                          ) : (
                            <div className="h-[134px]  cursor-pointer gap-2 flex flex-col items-center justify-center rounded-[8px] border-[1px] border-[#A6A08C] bg-[#FFFFFF]">
                              <p className="text-sm font-[400] text-[#344335]  leading-[18px] cursor-pointer">
                                This cooperative has no certificate
                              </p>
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </TableBackground>

              <>
                {cooperativeInfoData?.status === "pending" ||
                cooperativeInfoData?.status === "inactive" ||
                cooperativeInfoData?.status === "unapproved" ? (
                  <div className="flex justify-end gap-6">
                    <ButtonPrimary onclick={() => setShow(true)}>
                      Approve Cooperative
                    </ButtonPrimary>
                    <button
                      type="submit"
                      onClick={() => setShowReject(true)}
                      className="bg-[#FEF0EC] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7] w-fit
                    rounded-lg px-6 py-3 text-[#B92043] text-sm font-medium hover:bg-opacity-[0.9] transition-all"
                    >
                      Reject Cooperative
                    </button>
                  </div>
                ) : null}
              </>

              {/* MODALS */}
              {/* Approve Cooperative */}
              <ModalComponent
                show={show}
                showModal={showModal}
                title="Approve Cooperative Registration"
                subtitle="Are you sure you want to approve this cooperative registration?"
              >
                <div className="flex justify-end mt-8 mb-3">
                  <button
                    disabled={approvalStatusLoading}
                    type="submit"
                    onClick={() => handleStatus("approved")}
                    className="bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => setShow(false)}
                    type="submit"
                    className="bg-transparent
                    rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
                  >
                    Cancel
                  </button>
                </div>
              </ModalComponent>

              {/* Reject Cooperative */}
              <ModalComponent
                show={showReject}
                showModal={showRejectModal}
                title="Reject Cooperative Registration"
                subtitle="Rejecting the cooperative’s registration would mean the “cooperative” won’t be able to refer farmers or access loan."
              >
                <div className="mt-4">
                  <p className="text-[#344335] text-sm">Reason for Rejection</p>
                  <textarea
                    id="reason"
                    rows="8"
                    values={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="appearance-none w-full
                        placeholder:text-[#96A397] mt-1 placeholder:text-sm
                        text-secondary1
                        text-sm border border-[#96A397]
                        focus:border-[#ABB6AC]
                        focus:border outline-none
                        rounded-lg resize-none
                        bg-[#FFFFFF] py-3 px-4"
                    placeholder="State the reason for rejection"
                  ></textarea>
                </div>

                <div className="mt-5 mb-3">
                  <button
                    onClick={() => handleStatus("unapproved")}
                    disabled={reason === "" || approvalStatusLoading}
                    type="submit"
                    className="bg-[#B92043] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7] w-full
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-opacity-[0.9] hover:bg-[#B92043] transition-all"
                  >
                    Reject Cooperative
                  </button>
                </div>
              </ModalComponent>
            </>
          ) : cooperativeInfoData?.status === "approved" ? (
            <>
              {/* TABS */}
              <div className="mt-4">
                <Header
                  text={
                    <div className="flex w-full">
                      <div className="flex items-center gap-[8px]">
                        {/* FARMER"S INITIALS */}

                        <>
                          <div className="bg-[#2B8C34] rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
                            <p className="font-medium text-base leading-6 ">
                              {cooperativeInfoData?.name?.charAt(0)}
                            </p>
                          </div>
                        </>

                        <div className="">
                          <p
                            className={`text-[#344335] font-semibold leading-[18px] text-sm`}
                          >
                            {cooperativeInfoData?.name}
                          </p>
                          <div
                            className={`text-[#344335]  flex gap-[6px] mt-[4px] cursor-pointer`}
                            onClick={() =>
                              handleCopyNumber(cooperativeInfoData?.code)
                            }
                          >
                            <p
                              className={` text-[#344335]  font-semibold leading-[18px] text-sm`}
                            >
                              {cooperativeInfoData?.code}
                            </p>
                            <ContentCopy
                              className={` text-[#344335]`}
                              style={{ fontSize: "18px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  tabs={
                    <div className="flex gap-[16px] mt-[16px]">
                      <p
                        className={
                          tab === "account_information"
                            ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                            : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                        }
                        onClick={() => setTab("account_information")}
                      >
                        Account Information
                      </p>
                      <p
                        className={
                          tab === "referrals"
                            ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                            : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                        }
                        // onClick={() => setTab("referrals")}
                      >
                        Referrals
                      </p>
                      <p
                        className={
                          tab === "agent_information"
                            ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                            : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                        }
                        // onClick={() => setTab("agent_information")}
                      >
                        Agent Information
                      </p>
                    </div>
                  }
                />
              </div>
              <div className="flex  gap-2">
                <div className="w-[70%] h-fit">
                  <TableBackground
                    classname="px-4 pt-4 mt-4 h-fit"
                    style={{ borderRadius: "8px" }}
                  >
                    <div className="flex gap-[20px]">
                      {/* 1 */}
                      <div className="w-1/4">
                        {/* SEX */}
                        <div className="mb-[24px]">
                          <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Agent Name
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {cooperativeInfoData?.agent?.name || "N/A"}
                          </p>
                        </div>

                        {/* LGA */}
                        <div className="mb-[24px]">
                          <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Date Joined
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {moment(cooperativeInfoData?.created).format("lll")}
                          </p>
                        </div>
                      </div>

                      {/* 2 */}
                      <div className="w-1/4">
                        {/* SEX */}
                        <div className="mb-[24px]">
                          <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Agent Number
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {cooperativeInfoData?.agent?.phone_number || "N/A"}
                          </p>
                        </div>

                        {/* LGA */}
                        <div className="mb-[24px]">
                          <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Last Seen
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {moment(cooperativeInfoData?.created).format("lll")}
                          </p>
                        </div>
                      </div>

                      {/* 3 */}
                      <div className="w-1/4">
                        {/* BVN */}
                        <div className="mb-[24px]">
                          <div className="flex items-center gap-4">
                            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                              State
                            </p>
                          </div>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {cooperativeInfoData?.state?.display_name || "N/A"}
                          </p>
                        </div>

                        {/* AGE */}
                        <div className="mb-[24px]">
                          <div className="flex items-center gap-4">
                            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                              Registration Status
                            </p>
                          </div>
                          <div
                            className={
                              cooperativeInfoData?.registration_status ===
                                "active" ||
                              cooperativeInfoData?.registration_status ===
                                "approved"
                                ? "px-4 bg-[#2B8C34] success-status-badge"
                                : cooperativeInfoData?.registration_status ===
                                    "inactive" ||
                                  cooperativeInfoData?.registration_status ===
                                    "rejected"
                                ? " px-4 error-badge"
                                : " px-4 na-badge"
                            }
                          >
                            <p className="capitalize">
                              {cooperativeInfoData?.registration_status ||
                                "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* 4 */}
                      <div className="w-1/4">
                        {/* SEX */}
                        <div className="mb-[24px]">
                          <div className="flex items-center gap-4">
                            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                              LGA
                            </p>
                          </div>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {cooperativeInfoData?.lga?.display_name || "N/A"}
                          </p>
                        </div>

                        <div className="mb-[24px]">
                          <div className="flex items-center gap-4">
                            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                              Status
                            </p>
                          </div>
                          <div
                            className={
                              cooperativeInfoData?.status === "active" ||
                              cooperativeInfoData?.status === "approved"
                                ? "px-4 bg-[#2B8C34] success-status-badge"
                                : cooperativeInfoData?.status === "inactive" ||
                                  cooperativeInfoData?.status === "rejected"
                                ? " px-4 error-badge"
                                : " px-4 na-badge"
                            }
                          >
                            <p className="capitalize">
                              {cooperativeInfoData?.status || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableBackground>
                </div>
                <div className="w-[30%]  h-[159px]  bg-white mt-4 p-4 rounded-lg">
                  {/* ------   Cooperative Certificate -------------- */}
                  <div className=" relative ">
                    <p className="text-sm font-[600]  leading-[18px] text-[#344335] mb-2">
                      Cooperative Certificate
                    </p>

                    <label>
                      {file ? (
                        <img
                          src={file}
                          alt="  Cooperative Certificate"
                          className="h-[100px] w-full border-[1px] border-[#A6A08C] bg-[#FFFFFF] rounded-[8px] object-cover"
                        />
                      ) : (
                        <div className="h-[100px]  cursor-pointer gap-2 flex flex-col items-center justify-center rounded-[8px] border-[1px] border-[#ECEFEC] bg-[#FFFFFF]">
                          <p className="text-sm font-[400] text-[#344335]  leading-[18px] cursor-pointer">
                            This cooperative has no certificate
                          </p>
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : cooperativeInfoData?.status === "rejected" ? (
            <>
              <div className="flex  gap-2">
                <div className="w-[70%] h-fit">
                  <TableBackground
                    classname="px-4 pt-4 mt-4 h-fit"
                    style={{ borderRadius: "8px" }}
                  >
                    <div className="flex gap-[20px]">
                      {/* 1 */}
                      <div className="w-1/5">
                        {/* SEX */}
                        <div className="mb-[24px]">
                          <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Cooperative Name
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {cooperativeInfoData?.name || "N/A"}
                          </p>
                        </div>

                        {/* LGA */}
                        <div className="mb-[24px]">
                          <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Date Joined
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {moment(cooperativeInfoData?.created).format("lll")}
                          </p>
                        </div>
                      </div>
                      {/* 1 */}
                      <div className="w-1/5">
                        {/* SEX */}
                        <div className="mb-[24px]">
                          <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Agent Name
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {cooperativeInfoData?.agent?.name || "N/A"}
                          </p>
                        </div>
                        <div className="mb-[24px]">
                          <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Last Seen
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {moment(cooperativeInfoData?.created).format("lll")}
                          </p>
                        </div>
                      </div>

                      {/* 2 */}
                      <div className="w-1/5">
                        {/* SEX */}
                        <div className="mb-[24px]">
                          <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Agent Number
                          </p>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {cooperativeInfoData?.agent?.phone_number || "N/A"}
                          </p>
                        </div>

                        <div className="mb-[24px]">
                          <div className="flex items-center gap-4">
                            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                              Registration Status
                            </p>
                          </div>
                          <div
                            className={
                              cooperativeInfoData?.registration_status ===
                                "active" ||
                              cooperativeInfoData?.registration_status ===
                                "approved"
                                ? "px-4 bg-[#2B8C34] success-status-badge"
                                : cooperativeInfoData?.registration_status ===
                                    "inactive" ||
                                  cooperativeInfoData?.registration_status ===
                                    "rejected"
                                ? " px-4 error-badge"
                                : " px-4 na-badge"
                            }
                          >
                            <p className="capitalize">
                              {cooperativeInfoData?.registration_status ||
                                "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* 3 */}
                      <div className="w-1/5">
                        {/* BVN */}
                        <div className="mb-[24px]">
                          <div className="flex items-center gap-4">
                            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                              State
                            </p>
                          </div>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {cooperativeInfoData?.state?.display_name || "N/A"}
                          </p>
                        </div>

                        <div className="mb-[24px]">
                          <div className="flex items-center gap-4">
                            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                              Status
                            </p>
                          </div>
                          <div
                            className={
                              cooperativeInfoData?.status === "active" ||
                              cooperativeInfoData?.status === "approved"
                                ? "px-4 bg-[#2B8C34] success-status-badge"
                                : cooperativeInfoData?.status === "inactive" ||
                                  cooperativeInfoData?.status === "rejected"
                                ? " px-4 error-badge"
                                : " px-4 na-badge"
                            }
                          >
                            <p className="capitalize">
                              {cooperativeInfoData?.status || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* 4 */}
                      <div className="w-1/5">
                        {/* SEX */}
                        <div className="mb-[24px]">
                          <div className="flex items-center gap-4">
                            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                              LGA
                            </p>
                          </div>
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {cooperativeInfoData?.lga?.display_name || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </TableBackground>
                </div>
                <div className="w-[30%]  h-[159px]  bg-white mt-4 p-4 rounded-lg">
                  {/* ------   Cooperative Certificate -------------- */}
                  <div className=" relative ">
                    <p className="text-sm font-[600]  leading-[18px] text-[#344335] mb-2">
                      Cooperative Certificate
                    </p>

                    <label>
                      {file ? (
                        <img
                          src={file}
                          alt="  Cooperative Certificate"
                          className="h-[100px] w-full border-[1px] border-[#A6A08C] bg-[#FFFFFF] rounded-[8px] object-cover"
                        />
                      ) : (
                        <div className="h-[100px]  cursor-pointer gap-2 flex flex-col items-center justify-center rounded-[8px] border-[1px] border-[#ECEFEC] bg-[#FFFFFF]">
                          <p className="text-sm font-[400] text-[#344335]  leading-[18px] cursor-pointer">
                            This cooperative has no certificate
                          </p>
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>

              {cooperativeInfoData?.status === "rejected" && (
                <div className="w-[25%]  h-[159px]  bg-white mt-4 p-4 rounded-lg">
                  {/* ------   Cooperative Certificate -------------- */}
                  <div className=" relative ">
                    <p className="text-sm font-[600]  leading-[18px] text-[#7C8D7E] mb-2">
                      Rejection Review
                    </p>

                    <div className="h-[100px] p-3  cursor-pointer gap-2 font-semibold leading-[18px] text-sm rounded-[8px] border-[1px] border-[#ECEFEC] bg-[#ECEFEC]">
                      <p className="font-semibold leading-[18px] text-sm text-[#5C715E]  cursor-pointer">
                        {cooperativeInfoData?.reason || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Cooperative;
