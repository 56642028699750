import React, { useCallback, useReducer, useState } from "react";
import Header from "../../components/Header";
import GoBack from "../../components/GoBack";
import TableBackground from "../../components/TableBackground";
import { ExpandLess, ExpandMore, Upload } from "@mui/icons-material";
import cogoToast from "cogo-toast";
import SearchComponent from "../../components/Search";
import { getAllState, getAllLGA } from "../../redux/Location/locationAction";
import { useDispatch, useSelector } from "react-redux";
import { getAgents } from "../../redux/Agents/agentsAction";
import { createCooperative } from "../../redux/Cooperative/cooperativeAction";
import { useNavigate } from "react-router-dom";

const CreateCooperative = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agentsData, agentsLoading } = useSelector((state) => state.agents);
  const [file, setFile] = useState(null);
  const [lgaSearchValue, setLgaSearchValue] = useState("");
  const [stateSearchValue, setStateSearchValue] = useState("");
  const [stateData, setStateData] = useState([]);
  const [duplicatedStateData, setDuplicatedStateData] = useState([]);
  const [lgaData, setLgaData] = useState([]);
  const [duplicatedLgaData, setDuplicatedLgaData] = useState([]);
  const [cooperativeName, setCooperativeName] = useState("");
  const [cooperativeRegNum, setCooperativeRegNum] = useState("");
  const [agentInCharge, setAgentInCharge] = useState("");
  const [address, setAddress] = useState("");
  const [cooperativeCertificate, setCooperativeCertificate] = useState("");

  const handleStateClick = () => {
    dispatch(getAllState()).then((res) => {
      setStateData(res?.payload?.states);
      setDuplicatedStateData(res?.payload?.states);
    });
    updateClickState({
      isStateClick: !clickState.isStateClick,
    });
  };

  const [selectedValue, updateSelectedValue] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      selectedState: {},
      selectedLGA: {},
    }
  );

  const handleSelectedState = (data) => {
    updateSelectedValue({
      selectedState: { key: data.id, label: data.name },
    });
    updateClickState({ isStateClick: false });

    dispatch(
      getAllLGA({
        id: data.id,
      })
    ).then((res) => {
      setLgaData(res?.payload?.lgas);
      setDuplicatedLgaData(res?.payload?.lgas);
    });
  };

  const handleSearchStateChange = useCallback(
    (e) => {
      let val = e.target.value;
      setStateSearchValue(val);
      let data = duplicatedStateData;

      let filteredData = data.filter((state) =>
        state?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      setStateData(filteredData);
    },
    [duplicatedStateData, setStateSearchValue]
  );

  // HANDLES BOOLEAN CLICK EVENT
  const [clickState, updateClickState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isStateClick: false,
      isLGAClick: false,
    }
  );

  const handleSetFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCooperativeCertificate(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = function (e) {
        setFile(e?.target?.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSearchLgaChange = useCallback(
    (e) => {
      let val = e.target.value;
      setLgaSearchValue(val);
      let data = duplicatedLgaData;

      let filteredData = data.filter((lga) =>
        lga?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      setLgaData(filteredData);
    },
    [duplicatedLgaData, setLgaSearchValue]
  );
  const handleLgaClick = () => {
    if (Object.keys(selectedValue?.selectedState).length !== 0) {
      updateClickState({ isLGAClick: !clickState.isLGAClick });
    } else {
      cogoToast.error("Kindly select a state first");
    }
  };

  const handleAgentInCharge = (event) => {
    let value = event.target.value;
    setAgentInCharge("");
    let result;

    if (value.startsWith("0")) {
      let data = value.replace("0", "");
      result = "234" + data.toString();
    } else if (value.includes("+")) {
      result = value.replace("+", "");
    } else {
      result = value;
    }

    // console.log(result.length)
    if (`${result}`.length === 13) {
      dispatch(getAgents({ page: 1, value: "all", search: result }));
      setAgentInCharge(value);
    }
  };

  const handleCreateCooperative = () => {
    let values = {
      cooperative_certificate: cooperativeCertificate,
      name: cooperativeName,
      code: cooperativeRegNum,
      loan_officer: agentsData[0]?.id,
      state: selectedValue?.selectedState?.key,
      lga: selectedValue?.selectedLGA?.key,
      address: address,
      description: "",

      // name: cooperativeName,
      // code: cooperativeRegNum,
      // agent_id: Number(agentsData[0]?.id),
      // state_id: Number(selectedValue?.selectedState?.key),
      // lga_id: Number(selectedValue?.selectedLGA?.key),
      // description: "",
      // address: address,
      // file: cooperativeCertificate,
    };

    dispatch(createCooperative({ values })).then((res) => {
      if (res?.type === "createCooperative/fulfilled") {
        setCooperativeName("");
        setCooperativeRegNum("");
        setCooperativeCertificate("");
        setAgentInCharge("");
        updateSelectedValue({
          selectedState: {},
          selectedLGA: {},
        });
        cogoToast.success("Cooperative created successfully! ");
        navigate("/cooperative");
      }
    });
  };

  return (
    <div>
      <div className="mb-6">
        <GoBack />
      </div>
      <Header text="Create Cooperative"></Header>

      <TableBackground classname="p-[25px] mt-6 rounded-[8px] mb-6">
        {/* -----------Loan Details SECTION --------------------- */}
        <div>
          <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E] mb-4">
            Cooperative Details
          </p>

          <div>
            <div className="w-full gap-6 flex">
              {/* ------   Cooperative Name -------------- */}
              <div className="w-1/3 relative">
                <p className="text-sm font-[400]  leading-[18px] text-[#344335] mb-1">
                  Cooperative Name
                </p>

                <div>
                  <input
                    onChange={(event) => setCooperativeName(event.target.value)}
                    value={cooperativeName}
                    type="text"
                    placeholder="Igbalode Promoter"
                    className="outline-0 px-4 py-3  disabled:bg-[#F7F7F7] disabled:text-[#ABB6AC] disabled:border-[#96A397]  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                  />
                </div>
              </div>

              {/* ------   Cooperative Registration Number-------------- */}
              <div className="w-1/3 relative">
                <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                  Cooperative Registration Number
                </p>

                <div>
                  <input
                    onChange={(event) =>
                      setCooperativeRegNum(event.target.value)
                    }
                    value={cooperativeRegNum}
                    type="number"
                    placeholder="23481234567"
                    className="outline-0 px-4 py-3  disabled:bg-[#F7F7F7] disabled:text-[#ABB6AC] disabled:border-[#96A397]  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                  />
                </div>
              </div>

              {/* ------   Agent -In -Charge-------------- */}
              <div className="w-1/3 relative">
                <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                  Agent -In -Charge
                </p>

                <div className="relative">
                  <input
                    onChange={(event) => handleAgentInCharge(event)}
                    // value={agentInCharge}
                    type="number"
                    placeholder=""
                    className="outline-0 px-4 py-3  disabled:bg-[#F7F7F7] disabled:text-[#ABB6AC] disabled:border-[#96A397]  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                  />
                  <p className="absolute text-xs font-[500] leading-[16px] text-[#5C715E] mt-1">
                    {agentInCharge !== "" &&
                    agentsData.length === 0 &&
                    !agentsLoading ? (
                      <p>No agent found!</p>
                    ) : (
                      <>
                        {agentsData.length !== 0
                          ? agentsData[0]?.first_name +
                            " " +
                            agentsData[0]?.last_name
                          : ""}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full gap-6 flex mt-[44px]">
              {/* ------   Address -------------- */}
              <div className="w-1/3 relative">
                <p className="text-sm font-[400]  leading-[18px] text-[#344335] mb-1">
                  Address
                </p>

                <div>
                  <textarea
                    onChange={(event) => setAddress(event.target.value)}
                    value={address}
                    type="text"
                    placeholder="Address"
                    className="resize-none h-[134px] outline-0 px-4 py-3  disabled:bg-[#F7F7F7] disabled:text-[#ABB6AC] disabled:border-[#96A397]  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                  />
                </div>
              </div>

              {/* ------   STATE AND LGA -------------- */}
              <div className="w-1/3 relative">
                <div className="">
                  <div className="relative">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      State
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] "
                      onClick={handleStateClick}
                    >
                      <p>
                        {selectedValue.selectedState.label || "Select State"}
                      </p>

                      {clickState.isStateClick ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {clickState.isStateClick && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        <div className="">
                          <SearchComponent
                            placeholder="Search..."
                            searchValue={stateSearchValue}
                            handleChange={handleSearchStateChange}
                          />
                        </div>
                        {stateData.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                              onClick={() => handleSelectedState(data)}
                            >
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-6">
                  {/* LOCAL GOVERNMENT */}
                  <div className="relative">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Local Government
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397]"
                      onClick={handleLgaClick}
                    >
                      <p>{selectedValue.selectedLGA.label || "Select LGA"}</p>

                      {clickState.isLGAClick ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {clickState.isLGAClick && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        <div className="">
                          <SearchComponent
                            placeholder="Search..."
                            searchValue={lgaSearchValue}
                            handleChange={handleSearchLgaChange}
                          />
                        </div>
                        {lgaData.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                              onClick={() => {
                                updateClickState({ isLGAClick: false });
                                updateSelectedValue({
                                  selectedLGA: {
                                    key: data?.id,
                                    label: data?.name,
                                  },
                                });
                              }}
                            >
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* ------   Cooperative Certificate -------------- */}
              <div className="w-1/3 relative">
                <p className="text-sm font-[400]  leading-[18px] text-[#344335] mb-1">
                  Cooperative Certificate
                </p>

                <label htmlFor="file-upload">
                  {file ? (
                    <img
                      src={file}
                      alt="  Cooperative Certificate"
                      className="h-[134px] w-full border-[1px] border-[#A6A08C] bg-[#FFFFFF] rounded-[8px] object-cover"
                    />
                  ) : (
                    <div className="h-[134px]  cursor-pointer gap-2 flex flex-col items-center justify-center rounded-[8px] border-[1px] border-[#A6A08C] bg-[#FFFFFF]">
                      <Upload className="text-primary" />
                      <p className="text-sm text-primary font-[600]  leading-[20px]">
                        Choose file to upload
                      </p>
                      <p className="text-sm font-[400] text-[#344335]  leading-[18px] cursor-pointer">
                        Upload a PDF or JPEG file
                      </p>
                    </div>
                  )}

                  <input
                    id="file-upload"
                    required
                    onChange={(event) => handleSetFile(event)}
                    type="file"
                    className=" w-full"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </TableBackground>
      <div className="flex justify-end gap-6">
        <button
          disabled={
            cooperativeName !== "" &&
            cooperativeRegNum !== "" &&
            cooperativeCertificate !== "" &&
            agentInCharge !== "" &&
            agentsData.length !== 0 &&
            address !== "" &&
            Object.keys(selectedValue?.selectedState)?.length !== 0 &&
            Object.keys(selectedValue?.selectedLGA)?.length !== 0
              ? false
              : true
          }
          className="outline-none bg-primary  disabled:bg-[#ABB6AC] disabled:border-none disabled:cursor-not-allowed  border border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]  hover:border hover:border-primary  transition ease-in-out delay-100"
          onClick={() => handleCreateCooperative()}
        >
          Create Cooperative
        </button>
      </div>
    </div>
  );
};

export default CreateCooperative;

// agentsLoading &&
// selectedValue?.selectedState?.key === "" &&
// selectedValue?.selectedLGA?.key === "" &&
