import React from 'react'
import { CardWithBorder } from '../../../components/Cards';
import GoBack from '../../../components/GoBack';
import Header from '../../../components/Header';
import CallAgentTable from '../../../tables/CallLog/TeamLead/callAgentTable';

const CallAgentPage = () => {
    return ( 
        <>
            <GoBack />
            <div className='mt-4'>
                <Header text="Haruna Hope" />
            </div>
            <div className='grid grid-cols-4 gap-4 mt-4'>
                <CardWithBorder title="Inbound Farmers Call" subtitle="300" color="border-l-primary" href="/call-log-admin" />
                <CardWithBorder title="Temporary Farmers" subtitle="250/500" color="border-l-[#B92043]" href="/call-log-admin" />
                <CardWithBorder title="Completed Profiles" subtitle="250/500" color="border-l-primary" href="/call-log-admin" />
                <CardWithBorder title="Incomplete Profile" subtitle="250/500" color="border-l-[#FFC508]" href="/call-log-admin" />
            </div>
            <CallAgentTable />
          
        </>
     );
}
 
export default CallAgentPage;