import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl, finagApiUrl } from "../config";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

/////////////////////////////////////////////// LOCATION SECTION
export const getAllState = createAsyncThunk(
  "getAllState",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}states`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAllLGA = createAsyncThunk(
  "getAllLGA",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}lgas/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAllLGAByIds = createAsyncThunk(
  "getAllLGAByIds",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}lgas?states=${values}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

////////////////////////////////////////////// CROPS AND LIVESTOCK SECTION
export const getAllCrops = createAsyncThunk(
  "getAllCrops",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}crops`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAllLivestock = createAsyncThunk(
  "getAllLivestock",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}livestock`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

////////////////////////////////////////////// COOPERATIVE SECTION
export const getAllCooperatives = createAsyncThunk(
  "getAllCooperatives",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}cooperatives`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

////////////////////////////////////////////// DEBIT ACTION SECTION
export const getAllDebitActions = createAsyncThunk(
  "getAllDebitActions",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}wallet/transaction-actions`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getWalletDeductions = createAsyncThunk(
  "getWalletDeductions",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    if (value === "all") {
      try {
        const res = await axios.get(
          `${finagApiUrl}wallet/deduction-log?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${finagApiUrl}wallet/deduction-log?status=${value}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);
