import React,{useEffect, useState} from 'react'
import { Form, Formik } from 'formik'
import { addInfoCardValidator } from '../../validationSchema/validator'
import warning from '../../assets/icons/warning.svg'
import clear_icon from '../../assets/icons/clear-green.svg'
import cogoToast from 'cogo-toast'
import { useSelector, useDispatch } from 'react-redux'
import { fetchInfoCard, UpdateInfoCard } from '../../redux/Catalog/catalogActions'
import { useParams } from 'react-router-dom'
import { addKeyword, removeKeyword } from '../../redux/Catalog/catalog'
import GoBack from '../../components/GoBack'


const UpdateInfoCardPage = () => {
    const dispatch = useDispatch()
    const [val, setVal] = useState("")
    const [error, setError] = useState(null)
    const { loader, infoDetails, search_keywords } = useSelector((state) => state.catalog)

    const {category, id} = useParams()

    const handleChangeKeyword = (e) =>{
        setVal(e.target.value)
        setError(null)
    }

    const isValid = (keyword) =>{
        let error = null;
    
        if (isInList(keyword)) {
          error = `${keyword} has already been added.`;
        }
    
        if (error) {
          setError(error)
    
          return false;
        }
         return true;
     }

    const isInList = (val) =>{
        return search_keywords.includes(val);
     }
    
    const handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
          evt.preventDefault();
 
          var value = val.trim();
    
          if (value && isValid(value)) {
            dispatch(addKeyword(value))
              setVal("")
          }
        }
      };


    const handleDelete = item => {
        dispatch(removeKeyword(item))
    };

    const handleSubmit = (values) => {
         // check if at least a keyword has been added
        if(search_keywords.length === 0){
            cogoToast.info('Please add at least a keyword!')
        }
        else{            
          dispatch(UpdateInfoCard({
            name: values.name,
            description: values.description,
            keyword: search_keywords,
            category:category.toUpperCase(),
            id: id
          }))
        }
    }


    useEffect(()=>{
        dispatch(fetchInfoCard({
            id
        }))
    },[dispatch, id])

    return ( 
        <>
           <GoBack />
             <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={addInfoCardValidator}
              enableReinitialize={true}
              initialValues={{
                name: infoDetails ? infoDetails.product_name: "",
                description: infoDetails ? infoDetails.product_information: "",
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
                dirty,
                isValid
              }) => (
                <Form className="" onSubmit={handleSubmit}>
              <div className='bg-white mt-4 rounded-[8px] px-4 py-3 flex justify-between items-center'>
                <div>
                    <h6 className='text-[#2B8C34] font-semibold text-base'>Update Info Card(Crop)</h6>
                </div>
                <div>
                    <button
                     disabled={loader}
                    className="w-full py-4 flex items-center disabled:bg-[#ABB6AC]  justify-center px-6 bg-[#2B8C34] rounded-[8px]
                     text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
                   >
                    <p className="text-white font-medium text-sm ml-2">Save Changes</p>
                </button>
                </div>
            </div> 

            <div className='mt-4 bg-white rounded-[8px] py-4 px-4'>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-[#344335] text-sm font-medium"
                    >
                      Product Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Product Name"
                      className={
                        touched.name && errors.name
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-sm focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.name && errors.name}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className='mt-3'>
                    <label className='block mb-2 text-[#344335] text-sm font-medium'>
                        Keyword(s)
                    </label>
                    <div
                        className='mt-2 rounded-lg border border-[#96A397] flex flex-wrap items-center bg-white py-3 px-4 w-full appearance-none'
                           >
                        <div className="flex flex-wrap gap-2">
                            {
                                search_keywords.length ?
                                search_keywords.map((item, index)=>(
                                <div key={index} className='flex justify-center last:mr-2  items-center bg-[#DBFFDF] gap-2 w-fit rounded-[4px] py-1 px-2'>
                                    <p className='text-[#2B8C34] text-sm font-medium'>{item}</p>
                                    <img onClick={() => handleDelete(index)} width="10" height="10" className='cursor-pointer' src={clear_icon} alt="clear icon" />
                                </div> 
                                ))
                                :
                                ""
                            }
                        </div>    
                        <input
                            className='appearance-none w-full flex-1 placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input bg-transparent'
                            type="text"
                            placeholder="Enter Keyword"
                            value={val}
                            onChange={handleChangeKeyword}
                            onKeyDown={handleKeyDown}
                            />
                    
                        </div>
                        <small className={
                            error ?
                            'text-[#B92043] font-medium text-xs' :
                           'text-[#5c715e] font-medium text-xs' }>
                            {error ? error : "Type your keywords and Press `Enter` or use the comma(,) to separate the keywords" }
                            </small>
                        
                         </div>

                  <div className='mt-4'>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-[#344335] text-sm font-medium"
                    >
                      Description
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      rows="8"
                      placeholder="Enter a description that best describes the keyword(s)"
                      className={
                        touched.description && errors.description
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-sm focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.description && errors.description ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.description && errors.description}
                        </small>
                      </div>
                    ) : null}
                  </div>
            
            </div>

               </Form>
              )}
            </Formik> 
        </>
     );
}
 
export default UpdateInfoCardPage;