import { createSlice } from "@reduxjs/toolkit";
import {
  getBankTransactions,
  getBankTransactionsSearch,
  getATransaction,
} from "./transactionsAction";
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  ////////// FARMERS  WALLET STATE
  bankTransactionsData: [],
  allTransactionsTotal: 0,
  bankTransactionsLoading: false,
  debitTransactionsData: [],
  debitTransactionsTotal: 0,
  debitTransactionsLoading: false,
  creditTransactionsData: [],
  creditTransactionsTotal: 0,
  creditTransactionsLoading: false,

  //   TRANSACTION STATE
  transactionData: null,
  transactionLoading: false,
};

const transactionPersistConfig = {
  key: 'transactions',
  storage,
}

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
  extraReducers: {
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////// GET FARMER'S TRANSACTIONS  ----------
    [getBankTransactions.pending]: (state) => {
      state.bankTransactionsLoading = true;
    },
    [getBankTransactions.fulfilled]: (state, { payload }) => {
      state.bankTransactionsLoading = false;
      state.bankTransactionsData = payload.data;
      state.allTransactionsTotal = payload.total;
    },
    [getBankTransactions.rejected]: (state) => {
      state.bankTransactionsLoading = false;
    },

    /////////////////////////////////////  GET FARMER'S TRANSACTIONS  SEARCH ----------
    [getBankTransactionsSearch.pending]: (state) => {
      state.bankTransactionsLoading = true;
    },
    [getBankTransactionsSearch.fulfilled]: (state, { payload }) => {
      state.bankTransactionsLoading = false;
      state.bankTransactionsData = payload.data;
      state.allTransactionsTotal = payload.total;
    },
    [getBankTransactionsSearch.rejected]: (state) => {
      state.bankTransactionsLoading = false;
    },


    ////// GET TRANSACTION  ----------
    [getATransaction.pending]: (state) => {
      state.transactionLoading = true;
    },
    [getATransaction.fulfilled]: (state, { payload }) => {
      state.transactionLoading = false;
      state.transactionData = payload.data[0];
    },
    [getATransaction.rejected]: (state) => {
      state.transactionLoading = false;
    },

  
  },
});

const transactionsReducer = persistReducer(transactionPersistConfig, transactionsSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement } = transactionsSlice.actions;

export default transactionsReducer
