import { createSlice } from '@reduxjs/toolkit'
import { fetchFarmerLoanPorfolio, fetchGroups, fetchLoanOfficerById, fetchLoanOfficerGroups, fetchLoanOfficerLoanPortfolio, fetchLoanOfficerLoans, fetchLoanOfficerOverdueLoans, fetchLoanOfficers, fetchLoanOverviewInfo, fetchLoanPackages, getAllActiveLoans, getAllLoansByFilter, getCooperativeSummary, getGroupLoansByCooperative, getLoanByID, getOverdueLoans, handleALoanRecovery, handleApproveLoan, handleLoanRecovery, handlePaymentRecovery, handlePendingLoanRecovery, handleRejectLoan } from './loanActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'


const initialState = {

  value: 0,
  loader: false,
  loading: false,
  groups: [],
  overviewInfo: {},
  loanofficers: [],
  loanofficersCount: 0,
  loanofficer: {},
  loanofficerOverdueLoans: [],
  loanofficerLoanPorfolio: [],
  loanofficerGroups: [],
  loanofficerGroupsCount: 0,
  loanofficerLoans: [],
  loanofficerLoansCount: 0,
  farmerLoanPorfolio: [],
  loans: [],
  loansCount: 0,
  activeLoans: [],
  activeLoansCount: 0,
  GroupLoans: [],
  GroupLoanCount: 0,
  overdueLoans: [],
  loanInfo: [],
  loanPackages: [],
  loanPackagesLoading: false,
  farmersDetails: {},
  loanRecoveryData: [],
  loanRecoveryLoading: false,
  loanRecoveryTotal: 0,
  pendingLoanRecoveryData: [],
  pendingLoanRecoveryLoading: false,
  pendingLoanRecoveryTotal: 0,
  loanRecoveryDetails: {},
  loanRecoveryDetailsLoading: false,
  approveLoanLoading: false,
  approveLoader: false,
  approveSuccess: false,
  cooperativeSummary: [],
  cooperativeSummaryCount: 0

}


const loanPersistConfig = {
  key: 'loan',
  storage,
}

export const loanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    handleSaveFarmerDetails: (state, data) => {
      const allData = data.payload

      const values = {
        first_name: allData.firstName,
        last_name: allData.lastName,
        phone_number: allData.phoneNumber,
        status: allData.status,
        cooperative: allData.cooperative,
        loan: allData.loan,
      }

      state.farmersDetails = values
    },
    clearApproveSuccess: (state) =>{
      state.approveSuccess = false
    }
  },
  extraReducers: {
    // fetch group ----------
    [fetchGroups.pending]: (state) => {
      state.loading = true
    },
    [fetchGroups.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.groups = payload.results
    },
    [fetchGroups.rejected]: (state) => {
      state.loading = false
    },

    // get all overldue loans
    [getOverdueLoans.pending]: (state) => {
      state.loading = true
    },
    [getOverdueLoans.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.overdueLoans = payload.results
    },
    [getOverdueLoans.rejected]: (state) => {
      state.loading = false
    },
    // fetch farmer loan porfolio
    [fetchFarmerLoanPorfolio.pending]: (state) => {
      state.loading = true
    },
    [fetchFarmerLoanPorfolio.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.farmerLoanPorfolio = payload.data
    },
    [fetchFarmerLoanPorfolio.rejected]: (state) => {
      state.loading = false
    },
    // fetch overview info
    [fetchLoanOverviewInfo.pending]: (state) => {

    },
    [fetchLoanOverviewInfo.fulfilled]: (state, { payload }) => {
      state.overviewInfo = payload.data
    },
    [fetchLoanOverviewInfo.rejected]: (state) => {

    },
    // getAllLoansByFilter
    [getAllLoansByFilter.pending]: (state) => {
      state.loading = true
    },
    [getAllLoansByFilter.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.loans = payload.results
      state.loansCount = payload.count
    },
    [getAllLoansByFilter.rejected]: (state) => {
      state.loading = false
    },

    // get all active loans
    [getAllActiveLoans.pending]:(state) =>{
      state.loading = true
    },
    [getAllActiveLoans.fulfilled]: (state,{payload}) =>{
      state.loading = false
      state.activeLoans = payload.results
      state.activeLoansCount = payload.count
    },
    [getAllActiveLoans.rejected]: (state) =>{
      state.loading = false
    },
    // getCooperativeSummary
    [getCooperativeSummary.pending]: (state) =>{
      state.loading = true
    },
    [getCooperativeSummary.fulfilled]: (state,{payload}) =>{
      state.loading = false
        state.cooperativeSummary = payload.results
        state.cooperativeSummaryCount = payload.count
    },
    [getCooperativeSummary.rejected]: (state) =>{
      state.loading = false
    },

    // getLoanByID
    [getLoanByID.pending]: (state) => {

    },
    [getLoanByID.fulfilled]: (state, { payload }) => {
      state.loanInfo = payload.results
    },
    [getLoanByID.rejected]: (state) => {

    },
    // getLoanGroupsByCooperative
    [getGroupLoansByCooperative.pending]: (state) => {
      state.loading = true
    },
    [getGroupLoansByCooperative.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.GroupLoans = payload.results
      state.GroupLoanCount = payload.count
    },
    [getGroupLoansByCooperative.rejected]: (state) => {
      state.loading = false
    },
    // fetch loan officers ----------
    [fetchLoanOfficers.pending]: (state) => {
      state.loading = true
    },
    [fetchLoanOfficers.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.loanofficers = payload.results
      state.loanofficersCount = payload.count
    },
    [fetchLoanOfficers.rejected]: (state) => {
      state.loading = false
    },
    // fetch a loan officer
    [fetchLoanOfficerById.pending]: (state) => {

    },
    [fetchLoanOfficerById.fulfilled]: (state, { payload }) => {
      state.loanofficer = payload.data
    },
    [fetchLoanOfficerById.rejected]: (state) => {

    },
    // fetch an overdue loan for a loan officer
    [fetchLoanOfficerOverdueLoans.pending]: (state) => {
      state.loader = true
    },
    [fetchLoanOfficerOverdueLoans.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.loanofficerOverdueLoans = payload.results
    },
    [fetchLoanOfficerOverdueLoans.rejected]: (state) => {
      state.loader = false
    },
    // fetch a loan officer loan porfolio
    [fetchLoanOfficerLoanPortfolio.pending]: (state) => {
      state.loader = true
    },
    [fetchLoanOfficerLoanPortfolio.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.loanofficerLoanPorfolio = payload.results
    },
    [fetchLoanOfficerLoanPortfolio.rejected]: (state) => {
      state.loader = false
    },


    // fetch loans under a loan officer
    [fetchLoanOfficerLoans.pending]: (state) =>{
      state.loader = true
    },
    [fetchLoanOfficerLoans.fulfilled]: (state,{payload}) =>{
      state.loader = false
      state.loanofficerLoans = payload.results
      state.loanofficerLoansCount = payload.count
    },
    [fetchLoanOfficerLoans.rejected]: (state) =>{
      state.loader = false
    },


    // fetch a loan officer loan groups
    [fetchLoanOfficerGroups.pending]: (state) =>{
      state.loader = true
    },
    [fetchLoanOfficerGroups.fulfilled]: (state,{payload}) =>{
      state.loader = false
      state.loanofficerGroups = payload.results
      state.loanofficerGroupsCount = payload.count
    },
    [fetchLoanOfficerGroups.rejected]: (state) =>{
      state.loader = false
    },
    // handleApproveLoan
    [handleApproveLoan.pending]: (state) => {
      state.approveLoader = true
    },
    [handleApproveLoan.fulfilled]: (state) => {
      state.approveLoader = false
      state.approveSuccess = true
    },
    [handleApproveLoan.rejected]: (state) => {
      state.approveLoader = false
    },
    // handleApproveLoan
    [handleRejectLoan.pending]: (state) => {
      state.approveLoader = true
    },
    [handleRejectLoan.fulfilled]: (state) => {
      state.approveLoader = false
      state.approveSuccess = true
    },
    [handleRejectLoan.rejected]: (state) => {
      state.approveLoader = false
    },

    [fetchLoanPackages.pending]: (state) => {
      state.loanPackagesLoading = true
    },
    [fetchLoanPackages.fulfilled]: (state, { payload }) => {

      state.loanPackages = payload.data
      state.loanPackagesLoading = false
    },
    [fetchLoanPackages.rejected]: (state) => {
      state.loanPackagesLoading = true

    },

    [handleLoanRecovery.pending]: (state) => {
      state.loanRecoveryLoading = true
    },
    [handleLoanRecovery.fulfilled]: (state, { payload }) => {
      state.loanRecoveryTotal = payload.count
      state.loanRecoveryData = payload.results
      state.loanRecoveryLoading = false
    },
    [handleLoanRecovery.rejected]: (state) => {
      state.loanRecoveryLoading = false

    },

    [handlePendingLoanRecovery.pending]: (state) => {
      state.pendingLoanRecoveryLoading = true
    },
    [handlePendingLoanRecovery.fulfilled]: (state, { payload }) => {
      state.pendingLoanRecoveryTotal = payload.count
      state.pendingLoanRecoveryData = payload.results
      state.pendingLoanRecoveryLoading = false
    },
    [handlePendingLoanRecovery.rejected]: (state) => {
      state.pendingLoanRecoveryLoading = false

    },

    [handleALoanRecovery.pending]: (state) => {
      state.loanRecoveryDetailsLoading = true
    },
    [handleALoanRecovery.fulfilled]: (state, { payload }) => {
      state.loanRecoveryDetails = payload.data
      state.loanRecoveryDetailsLoading = false
    },
    [handleALoanRecovery.rejected]: (state) => {
      state.loanRecoveryDetailsLoading = false

    },
    [handlePaymentRecovery.pending]: (state) => {
      state.approveLoanLoading = true
    },
    [handlePaymentRecovery.fulfilled]: (state, { payload }) => {
      state.approveLoanLoading = false
    },
    [handlePaymentRecovery.rejected]: (state) => {
      state.approveLoanLoading = false

    },

  },
})

const loanReducer = persistReducer(loanPersistConfig, loanSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement, handleSaveFarmerDetails,clearApproveSuccess } = loanSlice.actions

export default loanReducer
