import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from 'cogo-toast'

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
}


export const getNotifications = createAsyncThunk(
  'getNotifications',
  async ({ type, page, status }, { rejectWithValue, dispatch }) => {
    try {
      if (type === 'all') {
        const res = await axios.get(`${apiUrl}notifications?status=${status}&page=${page}&limit=8`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }
      else {
        const res = await axios.get(`${apiUrl}notifications/${type}?status=${status}&page=${page}&limit=8`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }

    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const markAsReadNotification = createAsyncThunk(
  'markAsReadNotification',
  async ({ status, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(`${apiUrl}notifications/${id}`, {
        status: status,
      },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        cogoToast.success('Notification status changed successfully!')
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)


