import React, { useMemo, useState, useCallback, useEffect } from "react";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../../components/Pagination/Pagination";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { CardWithBorder } from "../../../components/Cards";
import { FilterList } from "@mui/icons-material";
import {useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { fetchLoanOfficerLoanPortfolio } from "../../../redux/Loans/loanActions";

const LoanAgentLoanPorfolioPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const { loader,loanofficerLoanPorfolio } = useSelector((state) => state.loan);
  const {id} = useParams()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
  };

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        // dispatch(
        //     searchCallRequests({
        //       val: val,
        //       status: 'all',
        //     }),
        //   )
        console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );



  useEffect(()=>{
    dispatch(fetchLoanOfficerLoanPortfolio({
      id
    }))
  },[dispatch, id])

  return (
    <>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <CardWithBorder title="Total Loans" subtitle={`30`} isLink={false} />
        <CardWithBorder title="Total Loan Portfolio" subtitle={`₦600,000 `} color="border-[#FFC508]" isLink={false}  />
        <CardWithBorder title="Total Loan Amount" subtitle={`₦500,000 `} isLink={false}  />
        <CardWithBorder
          title="Total Arrears"
          subtitle={`20`}
          color="border-[#B92043]"
          isLink={false} 
        >
        </CardWithBorder>
      </div>

      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* SEARCH AND FILTER SECTION */}
          <div className="flex gap-4">
            <div className="w-full relative">
              <SearchComponent
                placeholder="Search..."
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>

            <div className="flex justify-center items-center gap-2 border border-primary rounded-[8px] min-h-[50px] py-3 px-6 text-primary text-sm font-medium leading-5 cursor-pointer">
              <p className="">Filter</p>
              <FilterList style={{ fontSize: "18px" }} />
            </div>
          </div>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Month Created
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Group
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                Total Members 
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                Total Loans
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                Total Amount Disbursed 
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                Expected Repayment
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                Amount Recovered
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Amount Overdue
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(loanofficerLoanPorfolio).map(([month, result])=>(
                <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  {month}
                </th>

                <td className="py-4 px-4 text-[#5C715E]">{result.group_name}</td>
                <td className="py-4 px-4 text-[#5C715E]">{result.total_members}</td>
                <td className="py-4 px-4">{result.total_loans}</td>
                <td className="py-4 px-4">₦{result.total_amount_disbursed}</td>
                <td className="py-4 px-4">₦{result.expected_repayment}</td>
                <td className="py-4 px-4 text-[#5C715E]">₦{result.amount_recovered}</td>
                <td className="py-4 px-4">{result.amount_overdue}</td>
              </tr>
              ))}

            </tbody>
          </table>

          {loader && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={12}
        />
      </div>
    </>
  );
};

export default LoanAgentLoanPorfolioPage;
