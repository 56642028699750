import { createSlice } from "@reduxjs/toolkit";
import {
  createACallToOrder,
  getAllCallToOrder,
  getCallToOrder,
  getCallToOrderComment,
  getCallToOrderInfo,
  getSearchCallToOrder,
  updateAC2OOrder,
  updateACallToOrder,
} from "./callToOrderAction";
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  callToOrderData: [],
  callToOrderTotal: 0,
  callToOrderLoading: false,
  cartTotal: 0,

  // CREATE CALL TO ORDER DATA
  createCallToOrderLoading: false,
  createCallToOrderMessage: "",

  // CALL TO ORDER INFO
  callToOrderInfoData: [],
  callToOrderInfoLoading: false,

  updateCallToOrderShortCode: "",
  updateCallToOrderLoading: false,

  isShortCodeClick: false,

  // COMMENTS
  comments: [],
  commentsLoading: false,

  OrdersData: [],
  OrdersTotal: 0,
  OrdersLoading: false,
};

const callToOrderPersistConfig = {
  key: 'callToOrder',
  storage,
}


export const callToOrderSlice = createSlice({
  name: "callToOrder",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    handleCloseShortCodeModal: (state) => {
      state.isShortCodeClick = false;
    },
  },
  extraReducers: {
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////// CALL TO ORDER  ----------
    [getCallToOrder.pending]: (state) => {
      state.callToOrderLoading = true;
    },
    [getCallToOrder.fulfilled]: (state, { payload }) => {
      state.callToOrderLoading = false;
      state.callToOrderData = payload.data;
      state.callToOrderTotal = payload.total;
    },
    [getCallToOrder.rejected]: (state) => {
      state.callToOrderLoading = false;
    },

    //////////////////////////////////CALL TO ORDER SEARCH
    [getSearchCallToOrder.pending]: (state) => {
      state.callToOrderLoading = true;
    },
    [getSearchCallToOrder.fulfilled]: (state, { payload }) => {
      state.callToOrderLoading = false;
      state.callToOrderData = payload.data;
      state.callToOrderTotal = payload.total;
    },
    [getSearchCallToOrder.rejected]: (state) => {
      state.callToOrderLoading = false;
    },

    //////////////////////////////////CREATE CALL TO ORDER
    [createACallToOrder.pending]: (state) => {
      state.createCallToOrderLoading = true;
    },
    [createACallToOrder.fulfilled]: (state, { payload }) => {
      state.createCallToOrderLoading = false;
      state.createCallToOrderMessage = payload.cto_id;
    },
    [createACallToOrder.rejected]: (state) => {
      state.createCallToOrderLoading = false;
    },

    //////////////////////////////////CALL TO ORDER INFO
    [getCallToOrderInfo.pending]: (state) => {
      state.callToOrderInfoLoading = true;
    },
    [getCallToOrderInfo.fulfilled]: (state, { payload }) => {
      state.callToOrderInfoLoading = false;
      state.callToOrderInfoData = payload.data;
      state.cartTotal = payload.order_items_count;
    },
    [getCallToOrderInfo.rejected]: (state) => {
      state.callToOrderInfoLoading = false;
    },

    //////////////////////////////////UPADTE CALL TO ORDER
    [updateACallToOrder.pending]: (state) => {
      state.updateCallToOrderLoading = true;
    },
    [updateACallToOrder.fulfilled]: (state, { payload }) => {
      state.updateCallToOrderLoading = false;
      state.updateCallToOrderShortCode = payload;
      // if (payload) {
      //   state.isShortCodeClick = true;
      // } else {
      //   cogoToast.success("Order has been updated successfully");
      // }
    },
    [updateACallToOrder.rejected]: (state) => {
      state.updateCallToOrderLoading = false;
    },

    ////////////////////////////////////// CALL TO ORDER COMMENTS  ----------
    [getCallToOrderComment.pending]: (state) => {
      state.commentsLoading = true;
    },
    [getCallToOrderComment.fulfilled]: (state, { payload }) => {
      state.commentsLoading = false;
      state.comments = payload.data;
    },
    [getCallToOrderComment.rejected]: (state) => {
      state.commentsLoading = false;
    },

    ////////////////////////////////////// CALL TO ORDER   ----------
    [getAllCallToOrder.pending]: (state) => {
      state.OrdersLoading = true;
    },
    [getAllCallToOrder.fulfilled]: (state, { payload }) => {
      state.OrdersLoading = false;
      state.OrdersData = payload.data;
      state.OrdersTotal = payload.total_display_price;
    },
    [getAllCallToOrder.rejected]: (state) => {
      state.OrdersLoading = false;
    },

    ////////////////////////////////////// Update a CALL TO ORDER Order  ----------
    [updateAC2OOrder.pending]: (state) => {
      state.OrdersLoading = true;
    },
    [updateAC2OOrder.fulfilled]: (state, { payload }) => {
      state.OrdersLoading = false;
      state.OrdersData = payload.data;
    },
    [updateAC2OOrder.rejected]: (state) => {
      state.OrdersLoading = false;
    },
  },
});


const callRequestReducer = persistReducer(callToOrderPersistConfig, callToOrderSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement, handleCloseShortCodeModal } =
  callToOrderSlice.actions;

export default callRequestReducer;
