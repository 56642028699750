import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCooperatives,
  getAllCrops,
  getAllDebitActions,
  getAllLGA,
  getAllLGAByIds,
  getAllLivestock,
  getAllState,
  getWalletDeductions,
} from "./locationAction";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  //////////  STATE
  stateData: [],
  stateLoading: false,

  lgaData: [],
  lgaLoading: false,

  lgaDataByID: [],
  lgaDataByIDLoading: false,

  // CROPS
  cropsData: [],
  cropsLoading: false,

  // LIVESTOCK
  livestockData: [],
  livestockLoading: false,

  // LIVESTOCK
  cooperativeData: [],
  cooperativeLoading: false,

  // DEBIT ACTIONS
  debitActionsData: [],
  debitActionsLoading: false,

  walletData: [],
  walletLoading: false,
  walletTotal: 0,
};

const locationPersistConfig = {
  key: "location",
  storage,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    clearLGA: (state) => {
      state.lgaData = [];
    },
    clearState: (state) => {
      state.stateData = [];
    },
  },
  extraReducers: {
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////// GET STATES ----------
    [getAllState.pending]: (state) => {
      state.stateLoading = true;
    },
    [getAllState.fulfilled]: (state, { payload }) => {
      state.stateLoading = false;
      state.stateData = payload.states.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },
    [getAllState.rejected]: (state) => {
      state.stateLoading = false;
    },

    ////////////////////////////////////// GET LGAS ----------
    [getAllLGA.pending]: (state) => {
      state.lgaLoading = true;
    },
    [getAllLGA.fulfilled]: (state, { payload }) => {
      state.lgaLoading = false;
      state.lgaData = payload.lgas;
    },
    [getAllLGA.rejected]: (state) => {
      state.lgaLoading = false;
    },

    ////////////////////////////////////// GET LGAS By IDS----------
    [getAllLGAByIds.pending]: (state) => {
      state.lgaDataByIDLoading = true;
    },
    [getAllLGAByIds.fulfilled]: (state, { payload }) => {
      state.lgaDataByIDLoading = false;
      state.lgaDataByID = payload.lgas;
    },
    [getAllLGAByIds.rejected]: (state) => {
      state.lgaDataByIDLoading = false;
    },

    ////////////////////////////////////// GET CROPS ----------
    [getAllCrops.pending]: (state) => {
      state.cropsLoading = true;
    },
    [getAllCrops.fulfilled]: (state, { payload }) => {
      state.cropsLoading = false;
      state.cropsData = payload.data;
    },
    [getAllCrops.rejected]: (state) => {
      state.cropsLoading = false;
    },

    ////////////////////////////////////// GET LIVESTOCK ----------
    [getAllLivestock.pending]: (state) => {
      state.livestockLoading = true;
    },
    [getAllLivestock.fulfilled]: (state, { payload }) => {
      state.livestockLoading = false;
      state.livestockData = payload.data;
    },
    [getAllLivestock.rejected]: (state) => {
      state.livestockLoading = false;
    },

    ////////////////////////////////////// GET COOPERATIVES ----------
    [getAllCooperatives.pending]: (state) => {
      state.cooperativeLoading = true;
    },
    [getAllCooperatives.fulfilled]: (state, { payload }) => {
      state.cooperativeLoading = false;
      state.cooperativeData = payload.data;
    },
    [getAllCooperatives.rejected]: (state) => {
      state.cooperativeLoading = false;
    },

    ////////////////////////////////////// DEBIT FARMER ----------
    [getAllDebitActions.pending]: (state) => {
      state.debitActionsLoading = true;
    },
    [getAllDebitActions.fulfilled]: (state, { payload }) => {
      state.debitActionsLoading = false;
      state.debitActionsData = payload.data;
    },
    [getAllDebitActions.rejected]: (state) => {
      state.debitActionsLoading = false;
    },

    ////////////////////////////////////// DEBIT FARMER ----------
    [getWalletDeductions.pending]: (state) => {
      state.walletLoading = true;
    },
    [getWalletDeductions.fulfilled]: (state, { payload }) => {
      state.walletLoading = false;
      state.walletData = payload.data;
      state.walletTotal = payload.count;
    },
    [getWalletDeductions.rejected]: (state) => {
      state.walletLoading = false;
    },
  },
});

const locationReducer = persistReducer(
  locationPersistConfig,
  locationSlice.reducer
);

// Action creators are generated for each case reducer function
export const { increment, decrement, clearLGA, clearState } =
  locationSlice.actions;

export default locationReducer;
