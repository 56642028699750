import React from 'react'
import Header from '../../components/Header';
import ActivitiesTable from '../../tables/Sessions/activities';



const SessionActivitiesPage = () => {
    return ( 
        <>
           <Header text="Activities" />
           <ActivitiesTable />
        </>
     );
}
 
export default SessionActivitiesPage;