import React, { useCallback, useState } from "react";
import GoBack from "../../components/GoBack";
import {
  Add,
  CheckCircleOutline,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  getAllLGA,
  getAllState,
  getAllCrops,
} from "../../redux/Location/locationAction";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ClearIcon from "@mui/icons-material/Clear";
import {
  createCampaign,
  getCampaignAgents,
  getFarmersCount,
} from "../../redux/Campaign/campaignAction";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AddCampaign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalFarmer, setTotalFarmers] = useState(0);
  const [campaignToggle, setCampaignToggle] = useState(false);
  const [callFunc, setCallFunc] = useState(false);
  const { stateData, lgaData, cropsData } = useSelector(
    (state) => state.location
  );
  const { campaignAgentsData, createCampaignLoading } = useSelector(
    (state) => state.campaign
  );
  ////////////////////////LOCATION STATES
  const [isClickState, setIsClickState] = useState(false);
  const [selectedLGA] = useState({});
  const [selectedCrops, setSelectedCrops] = useState([]);

  const [pushedData, setPushedData] = useState([]);
  const [pushedDisplayData, setPushedDisplayData] = useState([]);
  const [campaignEstimateLoading, setCampaignEstimateLoading] = useState(false);

  // STATE
  const [pushedState, setPushedState] = useState("");
  const [pushedStateId, setPushedStateId] = useState("");
  // const [stateOptions, setStateOptions] = useState([]);
  const [LGAOptions, setLGAOptions] = useState([]);

  const [camapaignName, setCampaignName] = useState("");
  const [camapaignDescription, setCampaignDescription] = useState("");
  const [groupName, setGroupName] = useState("");

  const handleStateClicked = (stateName, stateId) => {
    setIsClickState(false);
    setPushedState(stateName);
    setPushedStateId(stateId);
    dispatch(
      getAllLGA({
        id: stateId,
      })
    );
  };

  /////////////////////////////////////////  LGA
  const [toggleLGAIcon, setToggleLGAIcon] = useState(false);

  const [selectedLgaServices, setSelectedLgaServices] = useState([]);
  const [selectedLgaName, setSelectedLgaName] = useState([]);
  const [isLgaCheckAll, setIsLgaCheckAll] = useState(false);
  const [lgaLength, setLgaLength] = useState(0);

  const handleLGAClicked = () => {
    if (pushedState !== "") {
      setToggleLGAIcon(!toggleLGAIcon);
    } else {
      setToggleLGAIcon(false);
      cogoToast.error("Kindly select a state first");
    }
  };

  const selectLgaAll = (length) => {
    setLgaLength(length);
    setIsLgaCheckAll(!isLgaCheckAll);
    setSelectedLgaServices(lgaData.map((service) => service.id));
    if (isLgaCheckAll) {
      setSelectedLgaServices([]);
    }
  };
  const selectLgaNameAll = (length) => {
    setLgaLength(length);
    setIsLgaCheckAll(!isLgaCheckAll);
    setSelectedLgaName(LGAOptions.map((service) => service.name));
    if (isLgaCheckAll) {
      setSelectedLgaName([]);
    }
  };

  const handleLgaSelect = (id, length) => {
    setLgaLength(length);
    if (selectedLgaServices.includes(id)) {
      // eslint-disable-next-line
      console.log(pushedDisplayData);
      // eslint-disable-next-line
      setSelectedLgaServices(selectedLgaServices.filter((each) => each != id));
      return;
    }
    setSelectedLgaServices((selServices) => [...selServices, id]);
  };

  // console.log(selectedLgaServices);

  const handleLgaSelectName = (id, length) => {
    setLgaLength(length);
    if (selectedLgaName.includes(id)) {
      // eslint-disable-next-line
      console.log(selectedLgaName.filter((each) => each != id));
      // eslint-disable-next-line
      setSelectedLgaName(selectedLgaName.filter((each) => each != id));
      return;
    }
    setSelectedLgaName((selServices) => [...selServices, id]);
  };

  // ///////////////////////////// Add location
  const handleAddLocation = () => {
    setIsLgaCheckAll(false);
    if (pushedData.some((data) => data.state === pushedState)) {
      let data = pushedData.filter((data) => data.state !== pushedState);
      setPushedData(data);
      setPushedData((prev) => [
        ...prev,
        {
          state: pushedState,
          lgas: selectedLgaName,
          state_id: Number(pushedStateId),
          lga_id: selectedLgaServices,
          length: lgaLength,
        },
      ]);
    } else {
      setPushedData((prev) => [
        ...prev,
        {
          state: pushedState,
          lgas: selectedLgaName,
          state_id: Number(pushedStateId),
          lga_id: selectedLgaServices,
          length: lgaLength,
        },
      ]);
      setPushedDisplayData((prev) => [
        ...prev,
        {
          state: Number(pushedStateId),
          lgas: selectedLgaServices,
        },
      ]);
    }
    setCallFunc(true);
    setPushedState("");
    setPushedStateId("");
    setSelectedLgaServices([]);
    setSelectedLgaName([]);
    setLGAOptions([]);
    setToggleLGAIcon(false);
  };

  const handleRemoveLocation = (id) => {
    setPushedData(pushedData.filter((each) => each.state_id !== id));

    setCallFunc(true);
  };

  ////////////////////////////////////////////// LANGUAGE
  const [toggleLanguageIcon, setToggleLanguageIcon] = useState(false);
  const languageData = [
    { id: "eng", name: "English" },
    { id: "yor", name: "Yoruba" },
    { id: "hau", name: "Hausa" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const handleLanguageClicked = () => {
    setToggleLanguageIcon(!toggleLanguageIcon);
  };

  const handleLanguageSelect = (id, name) => {
    if (selectedLanguage.some((data) => data.id === id)) {
      // eslint-disable-next-line
      setSelectedLanguage(selectedLanguage.filter((each) => each.id != id));

      setCallFunc(true);
      return;
    }
    setSelectedLanguage((selServices) => [
      ...selServices,
      { name: name, id: id },
    ]);

    setCallFunc(true);
  };

  // eslint-disable-next-line
  const getAllAgent = (value) => {
    dispatch(getCampaignAgents({ languages: value }));
  };

  const handleSetNoLang = () => {
    setToggleLanguageIcon(false);
    setSelectedLanguage([]);
    dispatch(getCampaignAgents({ languages: null }));
    setCallFunc(true);
    // getAllAgents();
  };

  const handleCancelLanguage = (data, id) => {
    let ans = selectedLanguage.filter(
      (somedData) => somedData.name !== data && somedData.id !== id
    );

    setSelectedLanguage(ans);
    setCallFunc(true);
    // if (ans.length === 0) {
    //   setAgentData([]);
    // }
  };

  ///////////////////////////////Dates

  const [activeStatusDateFrom, setActiveStatusDateFrom] = useState("");
  const [activeStatusDateTo, setActiveStatusDateTo] = useState("");
  const onChange = (dates) => {
    const [start, end] = dates;
    setActiveStatusDateFrom(start);
    setActiveStatusDateTo(end);

    if (end) {
      setCallFunc(true);
    }
  };

  // TRANSACTION
  const [transactionStatusDateFrom, setTransactionStatusDateFrom] =
    useState("");
  const [transactionStatusDateTo, setTransactionStatusDateTo] = useState("");

  const onChangeTransaction = (dates) => {
    const [start, end] = dates;
    setTransactionStatusDateFrom(start);
    setTransactionStatusDateTo(end);
    if (end) {
      setCallFunc(true);
    }
  };

  const handleStateClick = () => {
    setIsClickState(!isClickState);
    dispatch(getAllState());
  };
  ////////////////////////////////////////////// CROP
  const [toggleCropIcon, setToggleCropIcon] = useState(false);

  const handleCropClicked = () => {
    dispatch(getAllCrops());
    setToggleCropIcon(!toggleCropIcon);
  };

  const handleCropSelect = (id, name) => {
    if (selectedCrops.some((data) => data.id === id)) {
      // eslint-disable-next-line
      setSelectedCrops(selectedCrops.filter((each) => each.id != id));

      setCallFunc(true);
      return;
    }
    setSelectedCrops((selServices) => [...selServices, { name: name, id: id }]);

    setCallFunc(true);
  };

  const handleCancelCrops = (data, id) => {
    let ans = selectedCrops.filter(
      (somedData) => somedData.name !== data && somedData.id !== id
    );

    setSelectedCrops(ans);
    setCallFunc(true);
  };

  ////////////////////////////////////////////// AGENT
  const [toggleAgentIcon, setToggleAgentIcon] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState([]);

  const handleAgentClicked = () => {
    dispatch(getCampaignAgents({ languages: null }));
    setToggleAgentIcon(!toggleAgentIcon);
  };

  const handleAgentSelect = (id, name) => {
    if (selectedAgent.some((data) => data.id === id)) {
      // eslint-disable-next-line
      setSelectedAgent(selectedAgent.filter((each) => each.id != id));
      return;
    }
    setSelectedAgent((selServices) => [...selServices, { name: name, id: id }]);
  };

  const handleCancelAgent = (data, id) => {
    let ans = selectedAgent.filter(
      (somedData) => somedData.name !== data && somedData.id !== id
    );
    setSelectedAgent(ans);
  };

  // eslint-disable-next-line
  let state_and_lga = [];

  const handleGetFarmersCount = useCallback(async () => {
    setCampaignEstimateLoading(true);

    pushedData.map((data) => {
      return state_and_lga.push({ state: data.state_id, lgas: data.lga_id });
    });
    let crops = [];
    selectedCrops.map((data) => {
      return crops.push(data.id);
    });
    let language = [];
    selectedLanguage.map((data) => {
      return language.push(data.id);
    });
    getAllAgent(language.join(","));

    const value = {
      state_lga: state_and_lga,
      crops: crops,
      is_active: {
        from: activeStatusDateFrom
          ? moment(activeStatusDateFrom).format()
          : null,
        to: activeStatusDateTo ? moment(activeStatusDateTo).format() : null,
      },
      is_transacted: {
        from: transactionStatusDateFrom
          ? moment(transactionStatusDateFrom).format()
          : null,
        to: transactionStatusDateTo
          ? moment(transactionStatusDateTo).format()
          : null,
      },
      languages: language,
    };

    const data = await getFarmersCount({ value }); // fetch data from your redux store
    dispatch(data)
      .then((res) => {
        setTotalFarmers(res.payload.data);
        setCampaignEstimateLoading(false);
        setCallFunc(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        }
        setCampaignEstimateLoading(false);
      });
  }, [
    activeStatusDateFrom,
    activeStatusDateTo,
    dispatch,
    getAllAgent,
    pushedData,
    selectedCrops,
    selectedLanguage,
    transactionStatusDateFrom,
    transactionStatusDateTo,
    state_and_lga,
  ]);

  const handleGetAllFarmersCount = useCallback(async () => {
    setCampaignEstimateLoading(true);

    const value = {
      state_lga: [],
      crops: [],
      is_active: {
        from: null,
        to: null,
      },
      is_transacted: {
        from: null,
        to: null,
      },
    };

    const data = await getFarmersCount({ value }); // fetch data from your redux store
    dispatch(data)
      .then((res) => {
        setTotalFarmers(res.payload.data);
        setCampaignEstimateLoading(false);
        setCallFunc(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        }
        setCampaignEstimateLoading(false);
      });
  }, [dispatch]);

  const handleCreateCampaign = () => {
    let state_and_lga = [];
    pushedData.map((data) => {
      return state_and_lga.push({ state: data.state_id, lgas: data.lga_id });
    });

    let crops = [];
    selectedCrops.map((data) => {
      return crops.push(data.id);
    });
    let language = [];
    selectedLanguage.map((data) => {
      return language.push(data.id);
    });
    let agent = [];
    selectedAgent.map((data) => {
      return agent.push(data.id);
    });

    const value = {
      name: camapaignName,
      description: camapaignDescription,
      is_active: campaignToggle,
      total_farmers: totalFarmer,
      groups: [
        {
          name: "group_1",
          query: {
            state_lga: state_and_lga,
            languages: language,
            crops: crops,
            is_active: {
              from: activeStatusDateFrom
                ? moment(activeStatusDateFrom).format()
                : null,
              to: activeStatusDateTo
                ? moment(activeStatusDateTo).format()
                : null,
            },
            is_transacted: {
              from: transactionStatusDateFrom
                ? moment(transactionStatusDateFrom).format()
                : null,
              to: transactionStatusDateTo
                ? moment(transactionStatusDateTo).format()
                : null,
            },
          },
          agents: agent,
        },
      ],
    };

    if (selectedAgent.length > totalFarmer) {
      cogoToast.error("Kindly select lesser agents");
    } else {
      dispatch(createCampaign({ value }));
      setTimeout(() => {
        navigate(`/campaign`);
      }, 800);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (callFunc) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      handleGetFarmersCount();
    }
  }, [callFunc, handleGetFarmersCount]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleGetAllFarmersCount();
  }, [handleGetAllFarmersCount]);

  return (
    <div className="">
      {" "}
      <div className="mb-4">
        <GoBack />
      </div>
      {/* HEDAER SECTION */}
      <section className="mb-5 ">
        <Header text="Create New Campaign ">
          <div className="flex gap-10">
            {campaignToggle ? (
              <div
                className="flex bg-[#b92043]  border-[#b92043]  text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer"
                onClick={() => setCampaignToggle(false)}
              >
                <p>End campaign</p>
              </div>
            ) : (
              <div
                className="flex bg-primary border-primary text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer"
                onClick={() => setCampaignToggle(true)}
              >
                <p>Start campaign</p>
              </div>
            )}
          </div>
        </Header>
      </section>
      <div className="flex gap-5 h-[90vh]">
        <div className="w-[75%] campaignScroll overflow-x-scroll">
          <div>
            {/* 1 */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Campaign info
              </p>

              {/* CAMPAIGN NAME */}
              <div className="mb-4">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Campaign Name
                </p>
                <input
                  type="text"
                  className="outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
                  placeholder="Campaign Name"
                  onChange={(event) => setCampaignName(event.target.value)}
                  value={camapaignName}
                />
              </div>

              {/* CAMPAIGN DESCRIPTION */}
              <div className="mb-4">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Campaign Description
                </p>
                <textarea
                  className="resize-none h-[134px] outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
                  placeholder="Campaign Description"
                  onChange={(event) =>
                    setCampaignDescription(event.target.value)
                  }
                  value={camapaignDescription}
                />
              </div>
            </div>

            {/* 2 */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Target Groups
              </p>

              <div className="flex gap-[28px] cursor-pointer items-center">
                <p className="text-primary  font-semibold border-b-2 text-sm leading-[18px] border-primary pb-1">
                  Group 1
                </p>

                <div className="flex gap-1 text-[#96A397] font-semibold text-sm leading-[18px] items-center">
                  <Add style={{ fontSize: "17px" }} />
                  <p>Add Group</p>
                </div>
              </div>
            </div>

            {/* 3 */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Target Group
              </p>

              {/* GROUP NAME */}
              <div className="mb-4">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Group Name
                </p>
                <input
                  type="text"
                  className="outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
                  placeholder=" Group Name"
                  onChange={(event) => setGroupName(event.target.value)}
                  value={groupName}
                />
              </div>
            </div>

            {/* 4(STATE AND LOCAL GOVERNMENT) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Target Group
              </p>

              <div>
                <div className="flex gap-6 items-center">
                  {/* STATE */}
                  <div className="pb-4 w-1/2">
                    <p className="pb-1 font-[400] text-sm leading-[18px] text-[#344335]">
                      State
                    </p>
                    <div className="relative w-full">
                      <div
                        className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                        onClick={handleStateClick}
                      >
                        <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                          {pushedState || "Select a State"}
                        </p>
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                      {isClickState && (
                        <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                          {stateData?.map((data, index) => {
                            return (
                              <p
                                className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                key={index}
                                onClick={() => {
                                  handleStateClicked(data.name, data.id);
                                }}
                              >
                                {data.name}
                              </p>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* LGA */}
                  <div className="pb-4 w-1/2">
                    <p className="pb-1 font-[400] text-sm leading-[18px] text-[#344335]">
                      LGA
                    </p>
                    <div className="relative w-full">
                      <div
                        className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                        onClick={handleLGAClicked}
                      >
                        <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                          {selectedLGA.name || "Select Lga"}
                        </p>
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                      {toggleLGAIcon && (
                        <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                          <div className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4">
                            <input
                              type="checkbox"
                              name="allSelect"
                              onChange={(event) => {
                                selectLgaAll(LGAOptions.length);
                                selectLgaNameAll(LGAOptions.length);
                              }}
                              className="accent-primary h-[14px] w-[14px] rounded-[2px] mt-1"
                              checked={isLgaCheckAll}
                            />
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              Select All LGAs
                            </p>
                          </div>

                          {lgaData?.map((data, index) => {
                            return (
                              <div
                                key={index}
                                className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                              >
                                <input
                                  type="checkbox"
                                  name={data.name}
                                  className="accent-primary h-[14px] w-[14px] rounded-[2px] mt-1"
                                  // checked={selectedCrops.includes(x.id)}
                                  checked={selectedLgaServices.includes(
                                    data.id
                                  )}
                                  onChange={(event) => {
                                    handleLgaSelect(
                                      data.id,
                                      LGAOptions.length,
                                      event
                                    );
                                    handleLgaSelectName(
                                      data.name,
                                      LGAOptions.length,
                                      event
                                    );
                                  }}
                                />
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                  {data.name}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <button
                  onClick={pushedState !== "" ? handleAddLocation : null}
                  className={`outline-none ${
                    pushedState !== "" ? "bg-primary" : "bg-[#ABB6AC]"
                  }  rounded-[8px] h-[40px] w-[122px] font-medium text-sm leading-5 text-white`}
                >
                  Add Location
                </button>

                <div className="flex gap-2 flex-wrap cursor-pointer mt-3">
                  {pushedData.map((data, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => handleRemoveLocation(data.state_id)}
                      >
                        <div className="bg-[#96A397] rounded-[4px] px-2 w-full h-[26px] flex gap-1  items-center text-white font-medium text-sm leading-[18px] justify-center">
                          <div className="flex gap-2">
                            <p>{`${data.state} -`} </p>
                            {data.lgas.length === data.length ? (
                              <p>All Lgas</p>
                            ) : (
                              <div className="flex gap-1">
                                {data.lgas.map((lgaData, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <p>{lgaData + ","}</p>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          <ClearIcon style={{ fontSize: "12px" }} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* 5(CROPS) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Crop
              </p>
              <div className="flex gap-6 items-center">
                {/* CROP */}

                <div className="pb-4 w-1/2">
                  <div className="relative w-full">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Crops
                    </p>

                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onClick={handleCropClicked}
                    >
                      <p>Crop</p>

                      {toggleCropIcon ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {toggleCropIcon && (
                      <div
                        className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        {cropsData.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                            >
                              <input
                                type="checkbox"
                                name={data.name}
                                className="accent-primary h-[14px] w-[14px] rounded-[2px] mt-1"
                                // checked={selectedCrops.includes(x.id)}
                                checked={selectedCrops.some(
                                  (x) => x.id === data.id
                                )}
                                onChange={(event) => {
                                  handleCropSelect(data.id, data.name, event);
                                }}
                              />
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="flex gap-2 flex-wrap cursor-pointer">
                    {selectedCrops.map((data, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleCancelCrops(data.name, data.id)}
                        >
                          <div className="bg-[#96A397] rounded-[4px] px-2 w-full h-[26px] flex gap-1  items-center text-white font-medium text-sm leading-[18px] justify-center">
                            <p>{data.name}</p>
                            <ClearIcon style={{ fontSize: "12px" }} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* 6(STATUS) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Account Status
              </p>

              <div className="flex gap-6 items-center">
                <div className="pb-4 w-1/2">
                  <div className="relative w-full">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Active status
                    </p>

                    <label>
                      <div className="border items-center mt-1 border-[#abb6ac] flex justify-between h-[44px] gap-2 px-[12px] py-[16px] rounded-[8px] w-full">
                        <DatePicker
                          selected={activeStatusDateFrom}
                          onChange={onChange}
                          startDate={activeStatusDateFrom}
                          endDate={activeStatusDateTo}
                          selectsRange
                          className="w-full h-full outline-none"
                        />
                        <CalendarTodayIcon
                          className="text-[#96a397] w-fit cursor-pointer "
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* 7(TRANSACTION STATUS) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Transaction Status
              </p>

              {/* 7(TRANSACTION STATUS) */}
              <div className="flex gap-6 items-center">
                <div className="pb-4 w-1/2">
                  <div className="relative w-full">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Transaction status
                    </p>

                    <label>
                      <div className="border items-center mt-1 border-[#abb6ac] flex justify-between h-[44px] gap-2 cursor-pointer px-[12px] py-[16px] rounded-[8px] w-full">
                        <DatePicker
                          selected={transactionStatusDateFrom}
                          onChange={onChangeTransaction}
                          startDate={transactionStatusDateFrom}
                          endDate={transactionStatusDateTo}
                          selectsRange
                          className="w-full h-full outline-none"
                        />
                        <CalendarTodayIcon
                          className="text-[#96a397]"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* LANGUAGE */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Language
              </p>
              <div className="flex gap-6 items-center">
                {/* CROP */}

                <div className="pb-4 w-1/2">
                  <div className="relative w-full">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Language
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onClick={handleLanguageClicked}
                    >
                      <p>Language</p>

                      {toggleLanguageIcon ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {toggleLanguageIcon && (
                      <div
                        className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        <p
                          className="px-[16px] py-[6px] hover:bg-[#EDF7EE] cursor-pointer"
                          onClick={handleSetNoLang}
                        >
                          None
                        </p>
                        {languageData.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                              // onClick={() => handleCrops(data)}
                            >
                              <input
                                type="checkbox"
                                name={data.name}
                                className="accent-primary h-[14px] w-[14px] rounded-[2px] mt-1"
                                // checked={selectedCrops.includes(x.id)}
                                checked={selectedLanguage.some(
                                  (x) => x.id === data.id
                                )}
                                onChange={(event) => {
                                  handleLanguageSelect(
                                    data.id,
                                    data.name,
                                    event
                                  );
                                  // handleCropsSelectName(x.name, event);
                                }}
                              />
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="flex gap-2">
                    {selectedLanguage.map((data, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            handleCancelLanguage(data.name, data.id)
                          }
                        >
                          <div className="bg-[#96A397] rounded-[4px] w-[77px] h-[26px] flex gap-1 items-center text-white font-medium text-sm leading-[18px] justify-center">
                            <p>{data.name}</p>
                            <ClearIcon style={{ fontSize: "12px" }} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* AGENTS */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Agents
              </p>
              <div className="flex gap-6 items-center">
                {/* Agents */}

                <div className="pb-4 w-1/2">
                  <div className="relative w-full">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Agents
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onClick={handleAgentClicked}
                    >
                      <p>Agents</p>

                      {toggleLanguageIcon ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {toggleAgentIcon && (
                      <div
                        className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        {campaignAgentsData.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                              // onClick={() => handleCrops(data)}
                            >
                              <input
                                type="checkbox"
                                name={data.name}
                                className="accent-primary h-[14px] w-[14px] rounded-[2px] mt-1"
                                // checked={selectedCrops.includes(x.id)}
                                checked={selectedAgent.some(
                                  (x) => x.id === data.id
                                )}
                                onChange={(event) => {
                                  handleAgentSelect(
                                    data.id,
                                    data.first_name + " " + data.last_name,
                                    event
                                  );
                                }}
                              />
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.first_name + " " + data.last_name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="flex gap-2 flex-wrap cursor-pointer">
                    {selectedAgent.map((data, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleCancelAgent(data.name, data.id)}
                        >
                          <div className="bg-[#96A397] px-2 rounded-[4px] w-full h-[26px] flex gap-1 items-center text-white font-medium text-sm leading-[18px] justify-center">
                            <p>{data.name}</p>
                            <ClearIcon style={{ fontSize: "12px" }} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-end justify-end w-full">
            <div
              className={
                createCampaignLoading === false
                  ? camapaignName !== "" &&
                    camapaignDescription !== "" &&
                    groupName !== "" &&
                    selectedAgent.length !== 0
                    ? "flex w-[fit-content] bg-primary border-primary text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer"
                    : "flex w-[fit-content] bg-[#abb6ac] border-[#abb6ac] text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer"
                  : "flex w-[fit-content] bg-[#abb6ac] border-[#abb6ac] text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer"
              }
              onClick={
                createCampaignLoading === false
                  ? camapaignName !== "" &&
                    camapaignDescription !== "" &&
                    groupName !== "" &&
                    selectedAgent.length !== 0
                    ? handleCreateCampaign
                    : null
                  : null
              }
            >
              <p>{createCampaignLoading ? "Loading..." : "Create Campaign"}</p>
            </div>
          </div>
        </div>

        {/* FLEX 2 */}
        <div className="w-[25%]">
          <div className="bg-white rounded-[8px] p-6">
            <p className="font-semibold text-sm text-[#7C8D7E] leading-[18px] pb-1">
              Current Setting
            </p>
            <p className="font-[400] text-xs text-[#5C715E] leading-[18px] pb-4">
              To see your campaign estimates, enter the following settings:
            </p>

            <div>
              {/* LOCATION */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Location
                </p>
                <CheckCircleOutline
                  className={
                    pushedData.length !== 0 ? "text-primary " : "text-[#ABB6AC]"
                  }
                  style={{ fontSize: "16px" }}
                />
              </div>

              {/* CROPS */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Crops
                </p>
                <CheckCircleOutline
                  className={
                    selectedCrops.length !== 0
                      ? "text-primary "
                      : "text-[#ABB6AC]"
                  }
                  style={{ fontSize: "16px" }}
                />
              </div>

              {/* LANGUAGE */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Language
                </p>
                <CheckCircleOutline
                  className={
                    selectedLanguage.length !== 0
                      ? "text-primary "
                      : "text-[#ABB6AC]"
                  }
                  style={{ fontSize: "16px" }}
                />
              </div>

              {/* AGENT */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Agent
                </p>
                <CheckCircleOutline
                  className={
                    selectedAgent.length !== 0
                      ? "text-primary "
                      : "text-[#ABB6AC]"
                  }
                  style={{ fontSize: "16px" }}
                />
              </div>
            </div>
          </div>

          {/* 2 */}
          <div className="bg-white rounded-[8px] p-6 mt-[6px]">
            <p className="font-semibold text-sm text-[#7C8D7E] leading-[18px] pb-1">
              Campaign Estimates
            </p>
            <p className="font-[400] text-xs text-[#5C715E] leading-[18px] pb-4">
              Based on your current campaign setting you would be reaching an
              estimate of:
            </p>

            <div>
              {campaignEstimateLoading ? (
                <p>Loading...</p>
              ) : (
                <p className="font-[700] text-[18px] text-[#344335] leading-[28px]">
                  {totalFarmer}
                </p>
              )}

              <p className="font-medium text-xs text-[#344335] leading-4">
                Farmers
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCampaign;
