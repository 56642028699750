import React, { useCallback, useEffect, useReducer, useState } from "react";
import { ContentCopy, ExpandLess, ExpandMore } from "@mui/icons-material";
import GoBack from "../../../components/GoBack";
import Header from "../../../components/Header";
import TableBackground from "../../../components/TableBackground";
import { ButtonPrimary, ButtonSecondary } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  editFarmerProfile,
  getFarmersProfile,
} from "../../../redux/Farmers/farmersActions";
import cogoToast from "cogo-toast";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  getAllCooperatives,
  getAllCrops,
  getAllLGA,
  getAllLivestock,
  getAllState,
} from "../../../redux/Location/locationAction";
import SearchComponent from "../../../components/Search";
import { handleSaveFarmerDetails } from "../../../redux/Loans/loan";

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { farmersProfileData } = useSelector((state) => state.farmer);
  const { is_team_lead } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [selectedCrops, setSelectedCrops] = useState([]);
  const [selectedLivestock, setSelectedLivestock] = useState([]);
  const [stateSearchValue, setStateSearchValue] = useState("");
  const [cooperativeSearchValue, setCooperativeSearchValue] = useState("");
  const [lgaSearchValue, setLgaSearchValue] = useState("");
  const [cropsSearchValue, setCropsSearchValue] = useState("");
  const [livestockSearchValue, setLivestockSearchValue] = useState("");
  const [stateData, setStateData] = useState([]);
  const [duplicatedStateData, setDuplicatedStateData] = useState([]);
  const [lgaData, setLgaData] = useState([]);
  const [duplicatedLgaData, setDuplicatedLgaData] = useState([]);
  const [cropsData, setCropsData] = useState([]);
  const [duplicatedCropsData, setDuplicatedCropsData] = useState([]);
  const [livestockData, setLivestockData] = useState([]);
  const [duplicatedLivestockData, setDuplicatedLivestockData] = useState([]);
  const [cooperativeData, setCooperativeData] = useState([]);
  const [duplicatedCooperativeData, setDuplicatedCooperativeData] = useState(
    []
  );

  const handleStateClick = () => {
    dispatch(getAllState()).then((res) => {
      setStateData(res?.payload?.states);
      setDuplicatedStateData(res?.payload?.states);
    });
    updateClickState({
      isStateClick: !clickState.isStateClick,
    });
  };

  const handleCooperativeClick = () => {
    dispatch(getAllCooperatives()).then((res) => {
      setCooperativeData(res?.payload?.data);
      setDuplicatedCooperativeData(res?.payload?.data);
    });
    updateClickState({
      isCooperativeClick: !clickState.isCooperativeClick,
    });
  };

  const handleSelectedState = (data) => {
    updateSelectedValue({ selectedState: { key: data.id, label: data.name } });
    updateClickState({ isStateClick: false });

    dispatch(
      getAllLGA({
        id: data.id,
      })
    ).then((res) => {
      setLgaData(res?.payload?.lgas);
      setDuplicatedLgaData(res?.payload?.lgas);
    });
  };

  const handleSelectedCooperative = (data) => {
    updateSelectedValue({
      selectedCooperative: { key: data.id, label: data.name },
    });
    updateClickState({ isCooperativeClick: false });
  };

  const handleSearchStateChange = useCallback(
    (e) => {
      let val = e.target.value;
      setStateSearchValue(val);
      let data = duplicatedStateData;

      let filteredData = data.filter((state) =>
        state?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      setStateData(filteredData);
    },
    [duplicatedStateData, setStateSearchValue]
  );

  const handleSearchCooperativeChange = useCallback(
    (e) => {
      let val = e.target.value;
      setCooperativeSearchValue(val);
      let data = duplicatedCooperativeData;

      let filteredData = data.filter((state) =>
        state?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      setCooperativeData(filteredData);
    },
    [duplicatedCooperativeData, setCooperativeSearchValue]
  );

  const handleSearchLgaChange = useCallback(
    (e) => {
      let val = e.target.value;
      setLgaSearchValue(val);
      let data = duplicatedLgaData;

      let filteredData = data.filter((lga) =>
        lga?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      setLgaData(filteredData);
    },
    [duplicatedLgaData, setLgaSearchValue]
  );
  const handleLgaClick = () => {
    if (Object.keys(selectedValue?.selectedState).length !== 0) {
      updateClickState({ isLGAClick: !clickState.isLGAClick });
    } else {
      cogoToast.error("Kindly select a state first");
    }
  };

  const handleCropClick = () => {
    dispatch(getAllCrops()).then((res) => {
      setCropsData(res?.payload?.data);
      setDuplicatedCropsData(res?.payload?.data);
    });
    updateClickState({
      isCropsClick: !clickState.isCropsClick,
    });
  };

  const handleLivestockClick = () => {
    dispatch(getAllLivestock()).then((res) => {
      setLivestockData(res?.payload?.data);
      setDuplicatedLivestockData(res?.payload?.data);
    });
    updateClickState({
      isLivestockClick: !clickState.isLivestockClick,
    });
  };
  const handleSearchCropsChange = useCallback(
    (e) => {
      let val = e.target.value;
      setCropsSearchValue(val);
      let data = duplicatedCropsData;

      let filteredData = data.filter((crops) =>
        crops?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      setCropsData(filteredData);
    },
    [duplicatedCropsData, setCropsSearchValue]
  );

  const handleSearchLivestockChange = useCallback(
    (e) => {
      let val = e.target.value;
      setLivestockSearchValue(val);
      let data = duplicatedLivestockData;

      let filteredData = data.filter((livestock) =>
        livestock?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      setLivestockData(filteredData);
    },
    [duplicatedLivestockData, setLivestockSearchValue]
  );

  const handleCrops = (data) => {
    if (selectedCrops.some((item) => Number(data.id) === Number(item.id))) {
      // eslint-disable-next-line
      // eslint-disable-next-line
      setSelectedCrops(
        // eslint-disable-next-line
        selectedCrops.filter((each) => Number(each.id) != Number(data.id))
      );
      return;
    }
    setSelectedCrops((selServices) => [...selServices, data]);
  };

  const handleLivestock = (data) => {
    if (selectedLivestock.some((item) => Number(data.id) === Number(item.id))) {
      // eslint-disable-next-line
      // eslint-disable-next-line
      setSelectedLivestock(
        // eslint-disable-next-line
        selectedLivestock.filter((each) => Number(each.id) != Number(data.id))
      );
      return;
    }
    setSelectedLivestock((selServices) => [...selServices, data]);
  };

  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  const language = [
    { key: "eng", label: "English" },
    { key: "yor", label: "Yoruba" },
    { key: "hau", label: "Hausa" },
  ];

  const gender = [
    { key: "m", label: "Male" },
    { key: "f", label: "Female" },
  ];

  // HANDLES BOOLEAN CLICK EVENT
  const [clickState, updateClickState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isLanguageClick: false,
      isGenderClick: false,
      isStateClick: false,
      isLGAClick: false,
      isCropsClick: false,
      isLivestockClick: false,
      isCooperativeClick: false,
    }
  );

  // HANDLES SELECTED CLICK EVENT
  const [selectedValue, updateSelectedValue] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      selectedLanguage: {},
      selectedGender: {},
      selectedState: {},
      selectedLGA: {},
      // selectedCrops: {},
      selectedCooperative: {},
    }
  );

  // HANDLES STATE
  const [stateValue, updateStateValue] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      age: "",
      dateJoined: "",
      cooperative: "",
      landSize: "",
    }
  );

  // HANDLES GENDER CLICK
  const handleClickGender = (key, label) => {
    updateSelectedValue({ selectedGender: { key: key, label: label } });
    updateClickState({ isGenderClick: false });
  };

  // HANDLES LANGUAGE CCLICK
  const handleClickLanguage = (key, label) => {
    updateSelectedValue({ selectedLanguage: { key: key, label: label } });
    updateClickState({ isLanguageClick: false });
  };

  const fetchData = useCallback(async () => {
    const data = await getFarmersProfile({ id: id }); // fetch data from your redux store
    dispatch(data)
      .then((result) => {
        let data = result?.payload?.data;

        if (
          data?.gender?.toLowerCase() === "male" ||
          data?.gender?.toLowerCase() === "m"
        ) {
          updateSelectedValue({ selectedGender: { key: "m", label: "Male" } });
        } else {
          updateSelectedValue({
            selectedGender: { key: "f", label: "Female" },
          });
        }
        updateSelectedValue({
          selectedCooperative: { key: "", label: data?.cooperative },
        });
        updateStateValue({ firstName: data?.first_name });
        updateStateValue({ lastName: data?.last_name });
        updateStateValue({ cooperative: data?.cooperative });
        updateStateValue({ landSize: data?.land_size || "N/A" });
        updateStateValue({
          dateOfBirth: moment(data?.date_of_birth).format("YYYY-MM-DD"),
        });
        updateStateValue({
          dateJoined: moment(data?.created).format("YYYY-MM-DD"),
        });
        updateStateValue({
          age: moment().diff(data?.date_of_birth, "years", false),
        });
        setSelectedCrops(data?.crops);
        setSelectedLivestock(data?.livestock);
        updateSelectedValue({
          selectedState: { key: data.state_id, label: data.state },
        });
        updateSelectedValue({
          selectedLGA: { key: data.lga_id, label: data.lga },
        });

        if (data.ussd_lang === "eng") {
          updateSelectedValue({
            selectedLanguage: { key: "eng", label: "English" },
          });
        } else if (data.ussd_lang === "yor") {
          updateSelectedValue({
            selectedLanguage: { key: "yor", label: "Yoruba" },
          });
        } else if (data.ussd_lang === "hau") {
          updateSelectedValue({
            selectedLanguage: { key: "hau", label: "Hausa" },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        }
      });
  }, [dispatch, id]);

  const handleEditFarmersProfile = () => {
    const values = {
      crop: selectedCrops.map((data) => data.id),
      date_of_birth: moment(stateValue?.dateOfBirth).format(),
      first_name: stateValue?.firstName,
      gender: selectedValue.selectedGender.key,
      last_name: stateValue?.lastName,
      lga_id: selectedValue.selectedLGA.key,
      livestock: selectedLivestock.map((data) => data.id),
      // phone_number: "+2348121094248",
      state_id: selectedValue.selectedState.key,
      ussd_lang: selectedValue?.selectedLanguage?.key || "",
      cooperative_id:
        Number(selectedValue?.selectedCooperative.key) === 0
          ? null
          : Number(selectedValue?.selectedCooperative.key),
    };

    dispatch(editFarmerProfile({ id, values })).then((res) => {
      if (res?.type === "editFarmerProfile/fulfilled") {
        navigate(-1);
      }
    });

    setTimeout(() => {
      fetchData();
    }, 800);
  };

  const handleSaveDetails = () => {
    dispatch(
      handleSaveFarmerDetails({
        firstName: farmersProfileData?.first_name,
        lastName: farmersProfileData?.last_name,
        phoneNumber: farmersProfileData?.phone_number,
        status: farmersProfileData?.status,
        cooperative: farmersProfileData?.cooperative,
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {" "}
      <div onClick={handleSaveDetails}>
        <GoBack />
      </div>
      <div className="mt-[14px] mb-[24px]">
        <Header text="Edit Farmer Profile" />
      </div>
      {/* EDIT SECTION */}
      <section>
        {/* PERSONAL DETAILS SECTION */}
        <TableBackground style={{ borderRadius: "8px" }} classname="px-4">
          <p className=" pb-6 text-sm leading-[18px] font-semibold text-[#7C8D7E] pt-4">
            Personal Details
          </p>
          <div className="flex gap-[20px]">
            <div className="w-1/3">
              {/* PHONE NUMBER */}
              <div>
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Phone Number
                </p>
                <div
                  className="flex items-center justify-between outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                  onClick={() =>
                    handleCopyNumber(farmersProfileData?.phone_number)
                  }
                >
                  <p>{farmersProfileData?.phone_number} </p>

                  <ContentCopy
                    className="text-[#ABB6AC] cursor-pointer"
                    style={{ fontSize: "18px" }}
                  />
                </div>
              </div>

              <div className="flex gap-[20px] ">
                {/* Gender */}
                <div className="relative w-1/2">
                  <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Sex
                  </p>
                  <div
                    className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                    onClick={() =>
                      updateClickState({
                        isGenderClick: !clickState.isGenderClick,
                      })
                    }
                  >
                    <p>
                      {selectedValue.selectedGender.label === "Female"
                        ? "Female"
                        : selectedValue.selectedGender.label === "Male"
                        ? "Male"
                        : null}
                    </p>

                    {clickState.isGenderClick ? (
                      <ExpandLess
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    ) : (
                      <ExpandMore
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    )}
                  </div>

                  {clickState.isGenderClick && (
                    <div
                      className="absolute rounded-[8px] p-[8px] w-full bg-white z-10"
                      style={{
                        boxShadow:
                          "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                      }}
                    >
                      {gender.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                            onClick={() =>
                              handleClickGender(data.key, data.label)
                            }
                          >
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              {data.label}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                {/* DATE OF BIRTH */}
                <div className="w-1/2">
                  <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Date of Birth
                  </p>
                  <input
                    type="date"
                    className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                    onChange={(event) =>
                      updateStateValue({ dateOfBirth: event.target.value })
                    }
                    value={stateValue?.dateOfBirth}
                  />
                </div>
              </div>

              <p className=" pb-6 text-sm leading-[18px] font-semibold text-[#7C8D7E]">
                Account Information
              </p>

              {/* BVN*/}
              <div>
                <p className="font-[400] text-sm leading-[18px] text-[#ABB6AC]">
                  BVN
                </p>
                <div className="flex items-center justify-between outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]">
                  <p>{farmersProfileData?.bvn || "N/A"} </p>
                </div>
              </div>

              <p className=" pb-6 text-sm leading-[18px] font-semibold text-[#7C8D7E]">
                Form Details
              </p>

              {/* CROPS */}
              <div className="relative">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Crops
                </p>
                <div
                  className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                  onClick={handleCropClick}
                >
                  <p>
                    {selectedCrops.map((data) => data.name).join(",") ||
                      "Select Crops"}
                  </p>

                  {clickState.isCropsClick ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>

                {clickState.isCropsClick && (
                  <div
                    className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    <div className="">
                      <SearchComponent
                        placeholder="Search..."
                        searchValue={cropsSearchValue}
                        handleChange={handleSearchCropsChange}
                      />
                    </div>
                    {cropsData.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                        >
                          <input
                            type="checkbox"
                            checked={selectedCrops.some(
                              (item) => Number(item.id) === Number(data.id)
                            )}
                            onChange={(event) => {
                              handleCrops(data, event);
                            }}
                            className="accent-primary h-[14px] w-[14px] rounded-[2px] mt-1"
                          />
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <p className=" pb-6 text-sm leading-[18px] font-semibold text-[#7C8D7E]">
                Location
              </p>

              {/* LOCATION */}
              <div className="relative">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  State
                </p>
                <div
                  className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                  onClick={handleStateClick}
                >
                  <p>{selectedValue.selectedState.label || "Select State"}</p>

                  {clickState.isStateClick ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>

                {clickState.isStateClick && (
                  <div
                    className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    <div className="">
                      <SearchComponent
                        placeholder="Search..."
                        searchValue={stateSearchValue}
                        handleChange={handleSearchStateChange}
                      />
                    </div>
                    {stateData.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                          onClick={() => handleSelectedState(data)}
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <p className=" pb-6 text-sm leading-[18px] font-semibold text-[#7C8D7E]">
                Language
              </p>

              {/* LANGUAGE */}
              <div className="relative">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Language
                </p>
                <div
                  className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                  onClick={() =>
                    updateClickState({
                      isLanguageClick: !clickState.isLanguageClick,
                    })
                  }
                >
                  <p>
                    {selectedValue.selectedLanguage.label || "Select Langauge"}
                  </p>

                  {clickState.isLanguageClick ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>

                {clickState.isLanguageClick && (
                  <div
                    className="absolute rounded-[8px] p-[8px] w-full bg-white z-10"
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    {language.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                          onClick={() =>
                            handleClickLanguage(data.key, data.label)
                          }
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.label}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="w-1/3">
              {/* FIRSTNAME */}
              <div>
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  First Name
                </p>
                <input
                  type="text"
                  className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                  placeholder="First Name"
                  onChange={(event) =>
                    updateStateValue({ firstName: event.target.value })
                  }
                  value={stateValue?.firstName}
                />
              </div>

              <div className="flex gap-[20px] ">
                {/* AGE */}
                <div className="w-1/2">
                  <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Age
                  </p>
                  <input
                    type="number"
                    className="outline-0 h-[44px] rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px] bg-[#F7F7F7]"
                    placeholder="Age"
                    disabled
                    value={stateValue?.age}
                  />
                </div>

                {/* Date Joined */}
                <div className="w-1/2">
                  <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Date Joined
                  </p>
                  <input
                    type="date"
                    className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                    onChange={(event) =>
                      updateStateValue({ dateJoined: event.target.value })
                    }
                    value={stateValue?.dateJoined}
                  />
                </div>
              </div>

              {/* Account Number*/}
              <div className="pt-[12%] lg:pt-[18%] xl:pt-[11%] 2xl:pt-[6%]">
                <p className="font-[400] text-sm leading-[18px] text-[#ABB6AC]">
                  Account Number
                </p>
                <div className="flex items-center justify-between outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]">
                  <p>{farmersProfileData?.account_number || " N/A"} </p>
                </div>
              </div>

              <p className=" pb-6 text-sm leading-[18px] font-semibold text-[#7C8D7E] invisible">
                Form Details
              </p>

              {/* LAND SIZE AND LIVESTOCK */}
              <div className="flex gap-[20px] ">
                {/* LAND SIZE */}
                <div className="w-1/2">
                  <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Land Size
                  </p>
                  <div className="flex items-center justify-between outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]">
                    <p>{stateValue.landSize}</p>
                  </div>
                </div>

                {/* Livestock */}
                <div className="relative w-1/2">
                  <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Livestock
                  </p>
                  <div
                    className="flex items-center cursor-pointer justify-between outline-0 w-[348px] h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                    onClick={handleLivestockClick}
                  >
                    <p>
                      {selectedLivestock.map((data) => data.name).join(",") ||
                        "Select Livestock"}
                    </p>

                    {clickState.isLivestockClick ? (
                      <ExpandLess
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    ) : (
                      <ExpandMore
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    )}
                  </div>

                  {clickState.isLivestockClick && (
                    <div
                      className="absolute rounded-[8px] p-[8px] w-[348px] bg-white z-20 max-h-[250px] overflow-y-scroll "
                      style={{
                        boxShadow:
                          "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                      }}
                    >
                      <div className="">
                        <SearchComponent
                          placeholder="Search..."
                          searchValue={livestockSearchValue}
                          handleChange={handleSearchLivestockChange}
                        />
                      </div>
                      {livestockData.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="w-full px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                          >
                            <input
                              type="checkbox"
                              className="accent-primary h-[14px] w-[14px] rounded-[2px] mt-1"
                              checked={selectedLivestock.some(
                                (item) => Number(item.id) === Number(data.id)
                              )}
                              onChange={(event) => {
                                handleLivestock(data, event);
                              }}
                            />
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              {data.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              <p className=" pb-6 text-sm leading-[18px] font-semibold text-[#7C8D7E] invisible">
                Form Details
              </p>
              {/* LOCAL GOVERNMENT */}
              <div className="relative">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Local Government
                </p>
                <div
                  className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                  onClick={handleLgaClick}
                >
                  <p>{selectedValue.selectedLGA.label || "Select LGA"}</p>

                  {clickState.isLGAClick ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>

                {clickState.isLGAClick && (
                  <div
                    className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    <div className="">
                      <SearchComponent
                        placeholder="Search..."
                        searchValue={lgaSearchValue}
                        handleChange={handleSearchLgaChange}
                      />
                    </div>
                    {lgaData.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                          onClick={() => {
                            updateClickState({ isLGAClick: false });
                            updateSelectedValue({
                              selectedLGA: {
                                key: data?.id,
                                label: data?.name,
                              },
                            });
                          }}
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/3">
              {/* LAST NAME */}
              <div>
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Last Name
                </p>
                <input
                  type="text"
                  className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                  placeholder="Last Name"
                  onChange={(event) =>
                    updateStateValue({ lastName: event.target.value })
                  }
                  value={stateValue?.lastName}
                />
              </div>

              {/* {Cooperative } */}
              <div className="relative">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Cooperative
                </p>
                <div
                  className={`flex items-center cursor-pointer justify-between outline-0 h-[44px] ${
                    is_team_lead ? "bg-white" : "bg-[#F7F7F7]"
                  }  rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]`}
                  onClick={is_team_lead ? handleCooperativeClick : null}
                >
                  <p>
                    {selectedValue.selectedCooperative.label ||
                      "Select Cooperative"}
                  </p>

                  {clickState.isCooperativeClick ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>

                {clickState.isCooperativeClick && (
                  <div
                    className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    <div className="">
                      <SearchComponent
                        placeholder="Search..."
                        searchValue={cooperativeSearchValue}
                        handleChange={handleSearchCooperativeChange}
                      />
                    </div>
                    {cooperativeData.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                          onClick={() => handleSelectedCooperative(data)}
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </TableBackground>
      </section>
      {/* BUTTONS */}
      <div className="flex items-center justify-end gap-[24px] mt-6">
        <ButtonPrimary>Cancel</ButtonPrimary>
        <ButtonSecondary onclick={handleEditFarmersProfile}>
          Save
        </ButtonSecondary>
      </div>
    </div>
  );
};

export default EditProfile;
