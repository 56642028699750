import React from "react";
import Header from "../../components/Header";
import BankTransactionsTable from "../../tables/Transactions/BankTransaction";

const Transactions = () => {
  return (
    <div>
      {" "}
      <div className="mt-[14px] mb-6">
        <Header text="Wallet Transactions" />
      </div>
      <div>
        <BankTransactionsTable />
      </div>
    </div>
  );
};

export default Transactions;
