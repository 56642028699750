import React, { useState } from "react";
import GoBack from "../../../components/GoBack";
import Header from "../../../components/Header";
import ModalComponent from "../../../components/Modals/modal";
import MemberInboundTable from "../../../tables/CallLog/Member/inboundTable";
import { Form, Formik } from "formik";
import { addInboundCallsValidator } from "../../../validationSchema/validator";
import warning from "../../../assets/icons/warning.svg";

const MemberInboundCallsPage = () => {
  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(!show);
  };

  const handleSubmit = (values) => {
    // console.log(values);
  };

  return (
    <>
      <ModalComponent
        show={show}
        showModal={showModal}
        title="Add Inbound Caller"
        subtitle=""
      >
        <Formik
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, setSubmitting)
          }
          validationSchema={addInboundCallsValidator}
          initialValues={{
            farmer_name: "",
            phone_number: "",
            summary: "",
          }}
        >
          {({
            handleChange,
            isSubmitting,
            handleSubmit,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form className="mt-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="farmer_name"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Farmer Name
                </label>
                <input
                  type="text"
                  name="farmer_name"
                  placeholder="Enter farmer name"
                  className={
                    touched.farmer_name && errors.farmer_name
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  }
                  value={values.farmer_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.farmer_name && errors.farmer_name ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.farmer_name && errors.farmer_name}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="mt-4">
                <label
                  htmlFor="phone_number"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phone_number"
                  placeholder="Enter phone number"
                  className={
                    touched.phone_number && errors.phone_number
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                  }
                  value={values.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.phone_number && errors.phone_number ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.phone_number && errors.phone_number}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="mt-4 flex gap-6">
                <div className="flex items-center">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="default-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0"
                  />
                  <label
                    for="default-radio-1"
                    className="ml-2 text-sm font-medium text-[#5C715E]"
                  >
                    Call summary
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    checked
                    id="default-radio-2"
                    type="radio"
                    value=""
                    name="default-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                  />
                  <label
                    for="default-radio-2"
                    className="ml-2 text-sm font-medium text-[#344335]"
                  >
                    Complaints
                  </label>
                </div>
              </div>

              <div className="mt-4">
                <textarea
                  type="text"
                  rows="6"
                  name="summary"
                  placeholder="Leave a summary/complaints"
                  className={
                    touched.summary && errors.summary
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4 resize-none"
                      : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4 resize-none"
                  }
                  value={values.summary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.summary && errors.summary ? (
                  <div className="flex">
                    <img src={warning} className="" alt="warning" />
                    <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      {touched.summary && errors.summary}
                    </small>
                  </div>
                ) : null}
              </div>

              <div className="flex justify-end mt-5 mb-3">
                <button
                  type="submit"
                  disabled={false}
                  className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>

      <GoBack />
      <div className="mt-4">
        <Header text="Inbound Farmer Calls">
          <div>
            <button
              onClick={showModal}
              className="w-full py-3 flex items-center justify-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
            >
              <p className="text-white font-medium text-sm">
                Add Inbound Caller
              </p>
            </button>
          </div>
        </Header>
      </div>

      <MemberInboundTable />
    </>
  );
};

export default MemberInboundCallsPage;
