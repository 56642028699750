import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { getCalls, getRetailSearch } from "../../redux/Retail/retailAction";
import moment from "moment";
import EmptyState from "../../components/EmptyState";

const CallsTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const status = [
    { id: "ALL", title: "All" },
    { id: "CALLED", title: "Called" },
    { id: "NOT_CALLED", title: "Not Called" },
    { id: "MISSED", title: "Missed" },
    { id: "INCOMPLETE", title: "Incomplete" },
  ];
  const [clickedTab, setClickedTab] = useState("ALL");

  const { callsData, callsTotal, callsLoading } = useSelector(
    (state) => state.retail
  );

  // CALLS PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(getCalls({ status: clickedTab, page: page }));
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        dispatch(
          getRetailSearch({
            page: currentPage,
            value: val,
          })
        );
      }, 750),
    [dispatch, currentPage]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleTogglePage = (item) => {
    setClickedTab(item);

    dispatch(getCalls({ status: item, page: 1 }));
  };

  useEffect(() => {
    dispatch(getCalls({ status: clickedTab, page: 1 }));
  }, [dispatch, clickedTab]);

  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS */}
        <ul className="flex mt-5 gap-5 items-center ml-4">
          {status.map((item, index) => (
            <li
              key={index}
              onClick={() => handleTogglePage(item.id)}
              className={
                clickedTab === item.id
                  ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                  : "flex gap-2 items-center pb-1 cursor-pointer"
              }
            >
              <p
                className={
                  clickedTab === item.id
                    ? "text-primary text-sm font-semibold"
                    : "text-[#96A397] text-sm font-semibold"
                }
              >
                {item.title}
              </p>
              <span
                className={
                  clickedTab === item.id
                    ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-1 text-primary font-semibold text-[10px]"
                    : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-1 text-[#96A397] font-semibold text-[10px]"
                }
              >
                {item.id === clickedTab ? callsTotal : "0"}
              </span>
            </li>
          ))}
        </ul>

        <section className="mt-[30px] mb-[24px]">
          {callsLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {callsData.length === 0 ? (
                <div>
                  <EmptyState text="There's no data for this filter yet" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        Farmer Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Phone Number
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Date
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Time
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Campaign Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Status
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Agent
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {callsData?.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                        >
                          <td className="px-4">
                            {data.first_name || "N/A"} {data.last_name || "N/A"}
                          </td>
                          <td className="px-4">{data.phone_number || "N/A"}</td>
                          <td className="px-4">
                            {data.call_date
                              ? moment(data.call_date).format("ll")
                              : "N/A"}
                          </td>
                          <td className="px-4">
                            {data.call_date
                              ? moment(data.call_date).format("LT")
                              : "N/A"}
                          </td>
                          <td className="px-4">
                            {data.campaign_name || "N/A"}
                          </td>
                          <td className="px-4">
                            <div
                              className={
                                data.call_status === "CALLED"
                                  ? "success-badge"
                                  : data.call_status === "MISSED"
                                  ? "pending-badge"
                                  : data.call_status === "NOT_CALLED"
                                  ? "error-badge"
                                  : data.call_status === "INCOMPLETE"
                                  ? "dispatched-badge"
                                  : data.call_status === "FOLLOW_UP"
                                  ? "error-badge"
                                  : "N/A"
                              }
                            >
                              <p>
                                {data.call_status === "CALLED"
                                  ? "Called"
                                  : data.call_status === "MISSED"
                                  ? "Missed"
                                  : data.call_status === "NOT_CALLED"
                                  ? "Not Called"
                                  : data.call_status === "INCOMPLETE"
                                  ? "Incomplete"
                                  : data.call_status === "FOLLOW_UP"
                                  ? "Follow up"
                                  : "N/A"}
                              </p>
                            </div>
                          </td>
                          <td className="px-4">
                            {data.sales_agent_first_name || "N/A"}{" "}
                            {data.sales_agent_last_name}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}

          {!callsLoading && callsData.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={callsTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default CallsTable;
