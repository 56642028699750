import { createSlice } from '@reduxjs/toolkit'
import { getCallRequests, getCallRequestStatThisMonth, searchCallRequests, updateCallRequest } from './requestActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  value: 0,
  loader: false,
  loading: false,
  requests: [],
  total: 0,
  total_requests_this_month : 0
}


const callRequestPersistConfig = {
  key: 'callrequest',
  storage,
}


export const callRequestSlice = createSlice({
  name: 'callrequest',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
  },
  extraReducers: {
      // fetch call requests ----------
    [getCallRequests.pending]: (state) =>{
      state.loader = true
    },
    [getCallRequests.fulfilled]: (state, {payload}) =>{
      state.loader = false
      state.requests = payload.call_request
      state.total = payload.total
    },
    [getCallRequests.rejected]: (state) =>{
        state.loader = false
    },

    // search call requests
    [searchCallRequests.pending]: (state) =>{
      state.loader = true
    },
    [searchCallRequests.fulfilled]: (state, {payload}) =>{
      state.loader = false
      state.requests = payload.call_request
      state.total = payload.total
    },
    [searchCallRequests.rejected]: (state) =>{
       state.loader = false
    },
    // get call request stat this month
    [getCallRequestStatThisMonth.pending]: (state) =>{

    },
    [getCallRequestStatThisMonth.fulfilled] : (state, {payload}) =>{
        state.total_requests_this_month = payload.total_requests_this_month
    },
    [getCallRequestStatThisMonth.rejected]: (state) =>{

    },

    // update call request
    [updateCallRequest.pending]: (state) =>{
        state.loading = true
    },
    [updateCallRequest.fulfilled]: (state) =>{
        state.loading = false
    },
    [updateCallRequest.rejected]: (state) =>{
      state.loading = false
    }
  },
})

const callRequestReducer = persistReducer(callRequestPersistConfig, callRequestSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement } = callRequestSlice.actions

export default callRequestReducer
