import React, { useEffect } from 'react'
import Header from '../../../components/Header';
import { CardWithStatus } from '../../../components/Cards';
import LoanRecoveryTable from '../../../tables/LoanRecovery';
import { handlePendingLoanRecovery } from '../../../redux/Loans/loanActions';
import { useDispatch, useSelector } from 'react-redux';


const LoanRecoveryPage = () => {
    const dispatch = useDispatch()
    const { pendingLoanRecoveryTotal } = useSelector((state) => state.loan);

    useEffect(() => {
        dispatch(handlePendingLoanRecovery({ page: "1" }))
    }, [dispatch])

    // console.log(pendingLoanRecoveryTotal)

    return (
        <>
            <Header text="Recovery" />
            <div className="grid grid-cols-4 gap-4 mt-4">
                <CardWithStatus
                    title="Pending Recovery"
                    subtitle={pendingLoanRecoveryTotal || 0}
                    color="border-l-[#B92043]"
                    href="/pending-recovery"
                    hasStatus
                    status="Urgent"
                />
            </div>
            <div>
                <LoanRecoveryTable />
            </div>
        </>
    );
}

export default LoanRecoveryPage;