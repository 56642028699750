import { createSlice } from "@reduxjs/toolkit";
import {
  userLogin,
  forgotPassword,
  resetPassword,
  changePassword,
  updateUserDetails,
  refreshToken,
} from "./authActions";
import {Mixpanel} from '../../components/Helpers/MixPanel'
import jwt_decode from "jwt-decode";
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  value: 0,
  loading: false,
  token: "",
  finag_token: "",
  refreshToken: "",
  isAuthenticated: false,
  userInfo: null,
  is_team_lead: false,
  isReset: false,
};

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['loading']
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    Logout: (state) => {
      state.isAuthenticated = false;
      localStorage.setItem("token", "");
      localStorage.setItem("refreshToken", "");
      localStorage.clear();
      sessionStorage.clear();
    },
    clearReset: (state) => {
      state.isReset = false;
    },
  },
  extraReducers: {
    //refresh token
    [refreshToken.pending]: (state) => {
      state.loading = true
    },
    [refreshToken.fulfilled]: (state, {payload}) => {
      state.loading = false;
      state.token = payload.access;
      state.isAuthenticated = true;
  
      localStorage.setItem("token", payload.access);
      let decodedUserInfo = jwt_decode(payload.access);
      state.userInfo = decodedUserInfo;
      state.is_team_lead = decodedUserInfo.is_team_lead;
      localStorage.setItem("userId", decodedUserInfo.user_id);
      Mixpanel.identify(decodedUserInfo.user_id)
      Mixpanel.track('Successful Login')
      Mixpanel.people.set({
        $name: decodedUserInfo.first_name + ' ' + decodedUserInfo.last_name,
        $email: decodedUserInfo.email
      })
    },
    [refreshToken.rejected]: (state) =>{
      state.loading = false
      state.isAuthenticated = false
    },
    // login user -------------------- ---------------------------------------------------------------
    [userLogin.pending]: (state) => {
      state.loading = true;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.token = payload.token;
      state.refreshToken = payload.refresh;
      state.isAuthenticated = true;
      let decodedUserInfo = payload.user;
      localStorage.setItem("token", payload.token);
      localStorage.setItem("refreshToken", payload.refresh);
      state.userInfo = decodedUserInfo;
      state.is_team_lead = decodedUserInfo.is_team_lead;
      localStorage.setItem("userId", decodedUserInfo.user_id);
      Mixpanel.identify(decodedUserInfo.user_id)
      Mixpanel.track('Successful Login')
      Mixpanel.people.set({
        $name: decodedUserInfo.first_name + ' ' + decodedUserInfo.last_name,
        $email: decodedUserInfo.email
      })
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = false;
    },

    // forgot password -------------------- ---------------------------------------------------------------
    [forgotPassword.pending]: (state) => {
      state.loading = true;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.loading = false;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // reset password -------------------- ---------------------------------------------------------------
    [resetPassword.pending]: (state) => {
      state.loading = true;
    },
    [resetPassword.fulfilled]: (state) => {
      state.loading = false;
      state.isReset = true;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isReset = false;
    },
    // change password ----- --------------------
    [changePassword.pending]: (state) => {
      state.loading = true;
    },
    [changePassword.fulfilled]: (state) => {
      state.loading = false;

      state.isAuthenticated = false;
      localStorage.setItem("token", "");
    },
    [changePassword.rejected]: (state) => {
      state.loading = false;
    },

    // Update user deatils
    [updateUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo.first_name = payload.firstname;
      state.userInfo.last_name = payload.lastname;
    },
    [updateUserDetails.rejected]: (state) => {
      state.loading = false;
    },
  },
});



const authReducer = persistReducer(authPersistConfig, authSlice.reducer);
// Action creators are generated for each case reducer function
export const { increment, decrement, Logout, clearReset } = authSlice.actions;

export default authReducer;
