import React,{useState} from 'react'
import { Form, Formik } from 'formik'
import { changePasswordValidator } from '../../validationSchema/validator'
import eye_off from '../../assets/icons/eye-off.svg'
import eye from '../../assets/icons/eye.svg'
import warning from '../../assets/icons/warning.svg'
import { useSelector, useDispatch } from 'react-redux'
import { changePassword, updateUserDetails } from '../../redux/Auth/authActions'
import { useNavigate } from 'react-router-dom'

const ProfilePage = () => {

    const [oldpasswordShown, setOldPasswordShown] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false)
    const [confirmpasswordShown, setConfirmPasswordShown] = useState(false)
    const navigate = useNavigate()
    const { userInfo, loading } = useSelector((state) => state.auth)
    const dispatch = useDispatch();

    const [firstname, setFirstname] = useState(userInfo ? userInfo.first_name : 'Test')
    const [lastname, setLastname] = useState(userInfo ? userInfo.last_name : 'User')
    const [email, setEmail] = useState(userInfo ? userInfo.email : '')
    const [role, setRole] = useState(userInfo ? userInfo.is_team_lead : false)

    const [typeId, setTypeID] = useState(1);
    const type = [
      {
        id: 1,
        title: "Account Information",
      },
      {
        id: 2,
        title: "Password",
      },
    ];

    const toggleType = (id) => {
        setTypeID(id);
      };

      const toggleOldPasswordVisiblity = () => {
        setOldPasswordShown(oldpasswordShown ? false : true)
      }

      const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
      }

      const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmpasswordShown ? false : true)
      }
    
      const handleSubmit = (values) => {
        dispatch(changePassword({
            old_password: values.oldpassword,
            new_password: values.password
        }))

        setTimeout(()=>{
            navigate('/')
        },5000)
      }

      const handleUpdateInfo = () =>{
        dispatch(updateUserDetails({
            first_name: firstname,
            last_name: lastname,
        }))
      }
    

    return ( 
        <>
        <div className="bg-white rounded-lg py-5 px-4">
                <div className='flex justify-between items-center'>
                    <div>
                    <h6 className="text-primary font-semibold text-lg">Profile</h6>
                    </div>
                   {typeId === 1 &&
                     <div>
                        <button
                        onClick={handleUpdateInfo}
                        disabled={loading}
                        className="bg-primary 
                                disabled:bg-[#ABB6AC] text-white
                            rounded-lg w-full py-3 px-4 text-sm font-medium hover:bg-[#24752B] hover:text-white transition-all"
                            >
                            Save Changes
                            </button>
                      </div>
                      }
                </div>
            

            <ul className="flex gap-4 items-center mt-5">
                {type.map((val) => (
                <li
                    key={val.id}
                    onClick={() => toggleType(val.id)}
                    className="cursor-pointer"
                >
                    <p
                    className={
                        typeId === val.id
                        ? "text-primary text-sm font-semibold border-b-2 border-primary"
                        : "text-[#96A397] text-sm font-semibold"
                    }
                    >
                    {val.title}
                    </p>
                </li>
                ))}
            </ul>
        </div>

        {/* Tab One */}
        <div className='grid grid-cols-3 gap-4 mt-5'>
            <div className='bg-white rounded-lg py-5 px-4 h-fit'>
                <div className='flex justify-center'>
                   <div className='flex justify-center items-center w-[48px] h-[48px] rounded-full bg-primary'>
                        <p className='text-white font-medium text-sm'>
                            {userInfo ? userInfo.first_name.charAt(0).toUpperCase() : "T"}
                        {userInfo ? userInfo.last_name.charAt(0).toUpperCase() : "U"}
                        </p>
                    </div>
                </div>
                <div className='mt-3 text-center'>
                    <h6 className='text-[#344335] font-semibold text-base'>{firstname} {lastname}</h6>
                    <p className='text-[#344335] font-medium text-sm'>{userInfo ? userInfo.email : ''}</p>
                    <p className='text-[#344335] font-medium text-xs'>{role  ? 'Team Lead' : 'Customer Support'}</p>
                </div>
                    

            </div>
            <div className='col-span-2 bg-white rounded-lg pt-5 pb-8 px-5'>

              {
                typeId === 1 &&
                <>
                <p className='text-[#7C8D7E] text-sm font-semibold'>Account Information</p>                
                <div className='max-w-xl mt-4'>
           
                     <div className='flex gap-4'>
                        <div className='flex-1'>
                            <label
                            htmlFor="firstname"
                            className="block mb-2 text-[#344335] text-sm font-normal"
                            >
                            First Name
                            </label>
                            <input
                            type="text"
                            name="firstname"
                            placeholder="First name"
                            onChange={(e) => setFirstname(e.target.value)}
                            value={firstname}
                            className='appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            />
                        </div>
                        <div className='flex-1'>
                            <label
                            htmlFor="lastname"
                            className="block mb-2 text-[#344335] text-sm font-normal"
                            >
                            Last Name
                            </label>
                            <input
                            type="text"
                            name="lastname"
                            placeholder="Last name"
                            onChange={(e) => setLastname(e.target.value)}
                            value={lastname}
                            className='appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            />
                        </div>
                    </div>
        
                    <div className='flex gap-4 mt-4'>
                        <div className='flex-1'>
                            <label
                            htmlFor="email"
                            className="block mb-2 text-[#344335] text-sm font-normal"
                            >
                            Email Address
                            </label>
                            <input
                            type="email"
                            name="email"
                            disabled
                            placeholder="Email address"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className='appearance-none w-full disabled:bg-[#F7F7F7] placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            />
                        </div>
                        <div className='flex-1'>
                            <label
                            htmlFor="role"
                            className="block mb-2 text-[#344335] text-sm font-normal"
                            >
                            Role
                            </label>
                            <input
                            type="text"
                            name="role"
                            placeholder="Role"
                            disabled
                            onChange={(e) => setRole(e.target.value)}
                            value={role  ? 'Team Lead' : 'Customer Support'}
                            className='appearance-none  w-full placeholder:text-[#96A397] disabled:bg-[#F7F7F7] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            />
                        </div>
                    </div>
                
                </div>
                </>
                }
               
            
            {/* tab two control */}
            {
                typeId === 2 &&
             <>
              <p className='text-[#7C8D7E] text-sm font-semibold'>Change Password</p>

                <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={changePasswordValidator}
              initialValues={{
                oldpassword: '',
                password: '',
                password_confirmation: ''
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="mt-4 max-w-xl" onSubmit={handleSubmit}>
            
                  <div className="">
                    <label
                      htmlFor="oldpassword"
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                      Old Password
                    </label>
                    <div className="relative">
                      <input
                        type={oldpasswordShown ? 'text' : 'password'}
                        name="oldpassword"
                        placeholder="Enter your Old Password"
                        className={
                          touched.oldpassword && errors.oldpassword
                            ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                            : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                        }
                        value={values.oldpassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={toggleOldPasswordVisiblity}
                        src={oldpasswordShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.oldpassword && errors.oldpassword ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.oldpassword && errors.oldpassword}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                      New Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordShown ? 'text' : 'password'}
                        name="password"
                        placeholder="Enter your New Password"
                        className={
                          touched.password && errors.password
                            ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                            : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                        }
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={togglePasswordVisiblity}
                        src={passwordShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.password && errors.password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.password && errors.password}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="password_confirmation"
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        type={confirmpasswordShown ? 'text' : 'password'}
                        name="password_confirmation"
                        placeholder="Confirm your New Password"
                        className={
                          touched.password_confirmation && errors.password_confirmation
                            ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                            : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                        }
                        value={values.password_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={toggleConfirmPasswordVisiblity}
                        src={confirmpasswordShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.password_confirmation && errors.password_confirmation ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.password_confirmation && errors.password_confirmation}
                        </small>
                      </div>
                    ) : null}
                  </div>
               

                  <div className="mt-6">
                    <button
                      type="submit"
                      disabled={loading}
                      className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-sm text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                     Change Password
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
               </>
                }

               
            </div>
        </div>

 


        </>
     );
}
 
export default ProfilePage;