import React from 'react'
import GoBack from '../../components/GoBack';
import Header from '../../components/Header';
import LoanApplicationTable from '../../tables/Loans/LoanApplicationTable';

const LoanApplicationPage = () => {

    return ( 
        <>
            <GoBack />
            <div className='mt-4'>
                <Header text="Loan Application" />
            </div>
            <LoanApplicationTable />
        </>
     );
}
 
export default LoanApplicationPage;