import { Block } from "@mui/icons-material";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import ModalComponent from "../../../../components/Modals/modal";
import {
  blockFarmer,
  getAllFarmers,
  getAllFlaggedFarmers,
} from "../../../../redux/Farmers/farmersActions";
import { useLocation } from "react-router-dom";

const BlockFarmer = (id) => {
  const dispatch = useDispatch();
  const [isClickSuspendFarmer, setIsClickSuspendFarmer] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);
  const inputRef = useRef("");
  const location = useLocation();
  // const { blockFarmerStatus } = useSelector((state) => state.farmer)

  const handleClickSuspendFarmer = (e) => {
    e.stopPropagation();
    setIsClickSuspendFarmer(!isClickSuspendFarmer);
  };

  const handleBlock = () => {
    dispatch(
      blockFarmer({
        inputValue: inputRef.current.value,
        id: id.id,
        status: "Blocked",
      })
    );

    setTimeout(() => {
      setIsClickSuspendFarmer(false);
      if (location.pathname === "/farmers") {
        dispatch(
          getAllFarmers({
            id: id.id,
            page: 1,
            value: sessionStorage.getItem("farmer_search_value"),
            states: "",
            lgas: "",
            rank: "",
          })
        );
      } else if (location.pathname === "/flagged-farmers") {
        dispatch(getAllFlaggedFarmers({ page: 1 }));
      }
    }, 800);
  };

  return (
    <div>
      <div>
        {" "}
        <p
          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
          onClick={(event) => handleClickSuspendFarmer(event)}
        >
          Block Farmer
        </p>
      </div>
      {/* SUSPEND FARMER MODAL */}
      <ModalComponent
        title="Block Farmer"
        show={isClickSuspendFarmer}
        showModal={() => setIsClickSuspendFarmer(false)}
        subtitle="When a farmer is blocked he/she won’t be able to access crop2cash USSD"
      >
        <div className="pt-[16px]">
          <div className="mb-[32px]">
            <p className="text-sm font-[400] leading-[18px] text-[#344335] ">
              Reason for blocking
            </p>
            <textarea
              ref={inputRef}
              placeholder="Reason for blocking"
              className="outline-0 border border-[#96A397] h-[134px] rounded-[8px] w-full py-[12px] px-[16px] mt-[4px] resize-none"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={false}
              onClick={handleBlock}
              className="bg-[#B92043] 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-[102px] h-[44px] text-sm text-white font-medium hover:bg-[#B92043] transition-all mb-[16px]"
            >
              Block
            </button>
          </div>
        </div>
      </ModalComponent>

      {/* SUSPEND FARMER ALERT MODAL */}
      <ModalComponent
        title=""
        show={isSuspended}
        showModal={() => setIsSuspended(false)}
      >
        <div className="flex flex-col items-center">
          <Block
            className="text-[#B92043] mb-[10px]"
            style={{ fontSize: "20px" }}
          />
          <p className="mb-[4px] font-semibold text-base leading-5 text-[#344335]">
            Farmer has been blocked
          </p>
          <p className="text-center font-medium text-xs leading-4 text-[#344335] mb-[44px]">
            Jesam Abubakar won’t be able to make access Crop2cash USSD untill
            his/her is <br /> Unblocked
          </p>

          <p
            className="font-medium text-sm  leading-5 text-[#5C715E] mb-[22px]"
            onClick={() => setIsSuspended(false)}
          >
            Close
          </p>
        </div>
      </ModalComponent>
    </div>
  );
};

export default BlockFarmer;
