import React, { useState, useCallback } from "react";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import TableBackground from "../../components/TableBackground";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// import cogoToast from "cogo-toast";
import SearchComponent from "../../components/Search";
import { useDispatch } from "react-redux";
import { getAllLGA, getAllState } from "../../redux/Location/locationAction";
import { ButtonPrimary, ButtonSecondary } from "../../components/Button";

const AddGuarantor = () => {
  const dispatch = useDispatch();

  const gender = [
    { key: "o", label: "Male" },
    { key: "f", label: "Female" },
  ];

  const [isSexClicked, setIsSexClicked] = useState(false);
  const [selectedSex, setSelectedSex] = useState({});
  const [stateData, setStateData] = useState([]);
  const [duplicatedStateData, setDuplicatedStateData] = useState([]);
  const [lgaData, setLgaData] = useState([]);
  const [duplicatedLgaData, setDuplicatedLgaData] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [selectedLga, setSelectedLga] = useState({});
  const [isClickedState, setIsClickedState] = useState(false);
  const [isClickedLga, setIsClickedLga] = useState(false);
  const [stateSearchValue, setStateSearchValue] = useState("");
  const [lgaSearchValue, setLgaSearchValue] = useState("");

  const handleSelectSex = (label, key) => {
    setSelectedSex({ key, label });
    setIsSexClicked(false);
  };

  const handleStateClick = () => {
    dispatch(getAllState()).then((res) => {
      setStateData(res?.payload?.states);
      setDuplicatedStateData(res?.payload?.states);
    });
    setIsClickedState(!isClickedState);
  };

  const handleSearchStateChange = useCallback(
    (e) => {
      let val = e.target.value;
      setStateSearchValue(val);
      let data = duplicatedStateData;

      let filteredData = data.filter((state) =>
        state?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      setStateData(filteredData);
    },
    [duplicatedStateData, setStateSearchValue]
  );

  const handleSearchLgaChange = useCallback(
    (e) => {
      let val = e.target.value;
      setLgaSearchValue(val);
      let data = duplicatedLgaData;

      let filteredData = data.filter((lga) =>
        lga?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      setLgaData(filteredData);
    },
    [duplicatedLgaData, setLgaSearchValue]
  );

  const handleSelectedState = (data) => {
    setSelectedState({
      key: data.id,
      label: data.name,
    });
    setIsClickedState(false);

    dispatch(
      getAllLGA({
        id: data.id,
      })
    ).then((res) => {
      setLgaData(res?.payload?.lgas);
      setDuplicatedLgaData(res?.payload?.lgas);
    });
  };

  // const handleLgaClick = () => {
  //   if (Object.keys(selectedState).length !== 0) {
  //     setIsClickedLga(!isClickedLga);
  //   } else {
  //     cogoToast.error("Kindly select a state first");
  //   }
  // };

  return (
    <div>
      <GoBack />

      <div>
        <div className="mt-4 mb-6">
          <Header text="Guarantor 1"></Header>
        </div>
        <TableBackground classname="p-[25px] rounded-[8px] mb-6">
          {/* -----------PERSONAL DETAILS SECTION --------------------- */}
          <div>
            <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E] mb-4">
              Personal Detail
            </p>

            <div>
              {/* --------PHONE NUMBER AND SEX -------------------- */}
              <div className="flex gap-6 w-[70%] mb-4">
                {/* ------    PHONE NUMBER -------------- */}
                <div className="w-1/2">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Phone Number
                  </p>
                  <input
                    type="number"
                    className="outline-0 px-4 py-3 text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                    placeholder="+2348142663830"
                  />
                </div>
                {/* ------   SEX -------------- */}
                <div className="w-1/2 relative">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Sex
                  </p>
                  <div
                    className="flex items-center cursor-pointer justify-between outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#96A397]"
                    onClick={() => setIsSexClicked(!isSexClicked)}
                  >
                    <p>{selectedSex.key === "f" ? "Female" : "Male"}</p>

                    {isSexClicked ? (
                      <ExpandLess
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    ) : (
                      <ExpandMore
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    )}
                  </div>

                  {isSexClicked && (
                    <div
                      className="absolute rounded-[8px] p-[8px] w-full  bg-white z-10 mt-2"
                      style={{
                        boxShadow:
                          "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                      }}
                    >
                      {gender.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                            onClick={() =>
                              handleSelectSex(data.label, data.key)
                            }
                          >
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              {data.label}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              {/* --------FIRST NAME AND LAST NAME -------------------- */}
              <div className="flex gap-6 w-[70%] mb-6">
                {/* ------   FIRST NAME  -------------- */}
                <div className="w-1/2">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Firstname
                  </p>
                  <input
                    type="text"
                    className="outline-0 px-4 py-3  text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                    placeholder="+2348142663830"
                  />
                </div>
                {/* ------   LAST NAME -------------- */}
                <div className="w-1/2">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Lastname
                  </p>
                  <input
                    type="text"
                    className="outline-0 px-4 py-3 text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                    placeholder="+2348142663830"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------- LOCATION SECTION--------------------- */}
          <div>
            <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E] mb-4">
              Location
            </p>

            <div>
              {/* --------LOCATION -------------------- */}
              <div className="flex gap-6 w-full mb-4">
                {/* ------   ADDRESS -------------- */}
                <div className="w-[53%]">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Address
                  </p>
                  <textarea
                    type="text"
                    className="outline-0 resize-none h-[134px] px-4 py-3 text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                    placeholder="Jesam"
                  />
                </div>
                {/* ------   SECTION TWO -------------- */}
                <div className="w-full relative">
                  {/* --------   NEAREST LANDMARK AND LGA-------------------- */}
                  <div className="flex gap-6  mb-4">
                    {/* ------    NEAREST LANDMARK -------------- */}
                    <div className="w-1/2">
                      <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                        Nearest Landmark
                      </p>
                      <input
                        type="text"
                        className="outline-0 px-4 py-3 text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                        placeholder="Landmark"
                      />
                    </div>
                    {/* ------   LGA -------------- */}
                    <div className="w-1/2 relative">
                      <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                        Local Government
                      </p>
                      <div
                        className="flex items-center cursor-pointer justify-between outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#96A397]"
                        onClick={() => setIsClickedLga(!isClickedLga)}
                      >
                        <p>{selectedLga.label || "Select Lga"}</p>

                        {isClickedLga ? (
                          <ExpandLess
                            className="text-[#ABB6AC] cursor-pointer"
                            style={{ fontSize: "18px" }}
                          />
                        ) : (
                          <ExpandMore
                            className="text-[#ABB6AC] cursor-pointer"
                            style={{ fontSize: "18px" }}
                          />
                        )}
                      </div>

                      {isClickedLga && (
                        <div
                          className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                          style={{
                            boxShadow:
                              "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                          }}
                        >
                          <div className="">
                            <SearchComponent
                              placeholder="Search..."
                              searchValue={lgaSearchValue}
                              handleChange={handleSearchLgaChange}
                            />
                          </div>
                          {lgaData.map((data, index) => {
                            return (
                              <div
                                key={index}
                                className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                                onClick={() => {
                                  setIsClickedLga(false);
                                  setSelectedLga({
                                    key: data?.id,
                                    label: data?.name,
                                  });
                                }}
                              >
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                  {data.name}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* -------------STATE ---------------- */}

                  <div className="relative ">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      State
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#96A397]"
                      onClick={handleStateClick}
                    >
                      <p>{selectedState.label || "Select State"}</p>

                      {isClickedState ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {isClickedState && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll mb-[200px]"
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        <div className="">
                          <SearchComponent
                            placeholder="Search..."
                            searchValue={stateSearchValue}
                            handleChange={handleSearchStateChange}
                          />
                        </div>
                        {stateData.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                              onClick={() => {
                                handleSelectedState(data);
                              }}
                            >
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TableBackground>
      </div>

      <div>
        <div className="mt-4 mb-6">
          <Header text="Guarantor 2"></Header>
        </div>
        <TableBackground classname="p-[25px] rounded-[8px] mb-6">
          {/* -----------PERSONAL DETAILS SECTION --------------------- */}
          <div>
            <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E] mb-4">
              Personal Detail
            </p>

            <div>
              {/* --------PHONE NUMBER AND SEX -------------------- */}
              <div className="flex gap-6 w-[70%] mb-4">
                {/* ------    PHONE NUMBER -------------- */}
                <div className="w-1/2">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Phone Number
                  </p>
                  <input
                    type="number"
                    className="outline-0 px-4 py-3 text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                    placeholder="+2348142663830"
                  />
                </div>
                {/* ------   SEX -------------- */}
                <div className="w-1/2 relative">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Sex
                  </p>
                  <div
                    className="flex items-center cursor-pointer justify-between outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#96A397]"
                    onClick={() => setIsSexClicked(!isSexClicked)}
                  >
                    <p>{selectedSex.key === "f" ? "Female" : "Male"}</p>

                    {isSexClicked ? (
                      <ExpandLess
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    ) : (
                      <ExpandMore
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    )}
                  </div>

                  {isSexClicked && (
                    <div
                      className="absolute rounded-[8px] p-[8px] w-full  bg-white z-10 mt-2"
                      style={{
                        boxShadow:
                          "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                      }}
                    >
                      {gender.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                            onClick={() =>
                              handleSelectSex(data.label, data.key)
                            }
                          >
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              {data.label}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              {/* --------FIRST NAME AND LAST NAME -------------------- */}
              <div className="flex gap-6 w-[70%] mb-6">
                {/* ------   FIRST NAME  -------------- */}
                <div className="w-1/2">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Firstname
                  </p>
                  <input
                    type="text"
                    className="outline-0 px-4 py-3  text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                    placeholder="+2348142663830"
                  />
                </div>
                {/* ------   LAST NAME -------------- */}
                <div className="w-1/2">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Lastname
                  </p>
                  <input
                    type="text"
                    className="outline-0 px-4 py-3 text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                    placeholder="+2348142663830"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---------------- LOCATION SECTION--------------------- */}
          <div>
            <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E] mb-4">
              Location
            </p>

            <div>
              {/* --------LOCATION -------------------- */}
              <div className="flex gap-6 w-full mb-4">
                {/* ------   ADDRESS -------------- */}
                <div className="w-[53%]">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Address
                  </p>
                  <textarea
                    type="text"
                    className="outline-0 resize-none h-[134px] px-4 py-3 text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                    placeholder="Jesam"
                  />
                </div>
                {/* ------   SECTION TWO -------------- */}
                <div className="w-full relative">
                  {/* --------   NEAREST LANDMARK AND LGA-------------------- */}
                  <div className="flex gap-6  mb-4">
                    {/* ------    NEAREST LANDMARK -------------- */}
                    <div className="w-1/2">
                      <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                        Nearest Landmark
                      </p>
                      <input
                        type="text"
                        className="outline-0 px-4 py-3 text-sm font-medium leading-5 text-[#96A397] rounded-[8px]  border-[1px] border-[solid] border-[#96A397] w-full"
                        placeholder="Landmark"
                      />
                    </div>
                    {/* ------   LGA -------------- */}
                    <div className="w-1/2 relative">
                      <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                        Local Government
                      </p>
                      <div
                        className="flex items-center cursor-pointer justify-between outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#96A397]"
                        onClick={() => setIsClickedLga(!isClickedLga)}
                      >
                        <p>{selectedLga.label || "Select Lga"}</p>

                        {isClickedLga ? (
                          <ExpandLess
                            className="text-[#ABB6AC] cursor-pointer"
                            style={{ fontSize: "18px" }}
                          />
                        ) : (
                          <ExpandMore
                            className="text-[#ABB6AC] cursor-pointer"
                            style={{ fontSize: "18px" }}
                          />
                        )}
                      </div>

                      {isClickedLga && (
                        <div
                          className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                          style={{
                            boxShadow:
                              "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                          }}
                        >
                          <div className="">
                            <SearchComponent
                              placeholder="Search..."
                              searchValue={lgaSearchValue}
                              handleChange={handleSearchLgaChange}
                            />
                          </div>
                          {lgaData.map((data, index) => {
                            return (
                              <div
                                key={index}
                                className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                                onClick={() => {
                                  setIsClickedLga(false);
                                  setSelectedLga({
                                    key: data?.id,
                                    label: data?.name,
                                  });
                                }}
                              >
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                  {data.name}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* -------------STATE ---------------- */}

                  <div className="relative ">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      State
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#96A397]"
                      onClick={handleStateClick}
                    >
                      <p>{selectedState.label || "Select State"}</p>

                      {isClickedState ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {isClickedState && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll mb-[200px]"
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        <div className="">
                          <SearchComponent
                            placeholder="Search..."
                            searchValue={stateSearchValue}
                            handleChange={handleSearchStateChange}
                          />
                        </div>
                        {stateData.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                              onClick={() => {
                                handleSelectedState(data);
                              }}
                            >
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TableBackground>
      </div>

      <div className="flex justify-end items-end gap-[24px]">
        <ButtonSecondary>Cancel</ButtonSecondary>
        <ButtonPrimary>Save</ButtonPrimary>
      </div>
    </div>
  );
};

export default AddGuarantor;
