import { createSlice } from '@reduxjs/toolkit'
import { getBarChartsData, getOverviewInsights, getOverviewStats, getRecentOrders, getRecentTransactions } from './overviewActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
    value: 0,
    stats: {},
    insights: {},
    loading: false,
    loader: false,
    recentTransactions: [],
    recentTransactionCount: 0,
    recentOrders: [],
    recentOrdersCount: 0,
    completeChart: [],
    incompleteChart: [],
    temporaryChart: []
}


const overviewPersistConfig = {
  key: 'overview',
  storage,
}

export const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
  },
  extraReducers: {
    ////////////////////////////////////// GET Overview Stats ----------
    [getOverviewStats.pending]: (state) => {
    },
    [getOverviewStats.fulfilled]: (state, { payload }) => {
      state.stats = payload.data
    },
    [getOverviewStats.rejected]: (state) => {
    },

    // fetch insights
    [getOverviewInsights.pending]: (state) =>{

    },
    [getOverviewInsights.fulfilled]: (state, {payload}) =>{
      state.insights = payload.data
    },
    [getOverviewInsights.rejected]: (state) =>{

    },

    // get recent transactions
    [getRecentTransactions.pending]: (state) =>{
        state.loading = true
    },
    [getRecentTransactions.fulfilled]: (state, {payload}) =>{
      state.loading = false
      state.recentTransactions = payload.data
      state.recentTransactionCount = payload.total
    },
    [getRecentTransactions.rejected]: (state) =>{
        state.loading = false
    },

    // get recent orders
    [getRecentOrders.pending]: (state) =>{
        state.loader = true
    },
    [getRecentOrders.fulfilled]: (state, {payload}) =>{
      state.loader = false
      state.recentOrders = payload.data
      state.recentOrdersCount = payload.total
    },
    [getRecentOrders.rejected]: (state) =>{
        state.loader = false
    },

    // get overview bar chart
    [getBarChartsData.pending]: (state) =>{

    },
    [getBarChartsData.fulfilled]: (state, {payload}) =>{
        state.completeChart = payload.data.complete
        state.incompleteChart = payload.data.incomplete
        state.temporaryChart = payload.data.temporary
    },
    [getBarChartsData.rejected]: (state) =>{

    },
  },
})


const overviewReducer = persistReducer(overviewPersistConfig, overviewSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement } = overviewSlice.actions

export default overviewReducer
