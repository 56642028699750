import React, { useCallback, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { ButtonPrimary } from "../../components/Button";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import TableBackground from "../../components/TableBackground";
import { Clear, ExpandMore } from "@mui/icons-material";
import { getAllState } from "../../redux/Location/locationAction";
import { useDispatch } from "react-redux";
import SearchComponent from "../../components/Search";

const Input = () => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const [tab, setTab] = useState("product_details");
  const vendor = [
    { id: 1, name: "Saro" },
    { id: 2, name: "Afrimash" },
  ];
  const [allData, updateAllData] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      stateData: [],
      lgaDataByID: [],
      duplicatedStateData: [],
      duplicatedLgaData: [],
      rankData: ["Platinum", "Gold", "Silver", "Bronze", "Copper"],
      duplicatedRankData: ["Platinum", "Gold", "Silver", "Bronze", "Copper"],
    }
  );
  const [selectedvendor, setSelectedVendor] = useState("");
  const [isToggleVendor, setToggleVendor] = useState(false);
  const [stateSearchValue, setStateSearchValue] = useState("");
  const [selectedState, setSelectedState] = useState([]);
  const [selectedStateName, setSelectedStateName] = useState([]);
  const [isClickState, setClickState] = useState(false);
  const handleVendor = (data) => {
    setSelectedVendor(data);
    setToggleVendor(false);
  };

  const handleOpenStateFilter = () => {
    dispatch(getAllState()).then((res) => {
      if (res.type === "getAllState/fulfilled") {
        updateAllData({
          duplicatedStateData: res?.payload?.states,
          stateData: res?.payload?.states,
        });
      }
    });
  };

  const handleSearchChange = useCallback(
    (e) => {
      let val = e.target.value;
      setStateSearchValue(val);
      let data = allData?.duplicatedStateData;

      let filteredData = data.filter((state) =>
        state?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      updateAllData({ stateData: filteredData });
    },
    [allData, setStateSearchValue]
  );

  const handleSelectedState = (data) => {
    if (selectedState.includes(data.id)) {
      let filteredData = selectedState.filter(
        (selectedData) => selectedData !== data?.id
      );
      let filteredDataName = selectedStateName.filter(
        (selectedData) => selectedData.id !== data?.id
      );
      setSelectedState(filteredData);
      setSelectedStateName(filteredDataName);
    } else {
      setSelectedStateName((prev) => [...prev, data]);
      setSelectedState((prev) => [...prev, data?.id]);
    }
  };

  console.log(selectedStateName);

  return (
    <div>
      {" "}
      <div>
        <GoBack />
      </div>
      {/* HEADER AND TAB SECTION */}
      <div className="mt-[14px] mb-6">
        <Header
          text={`Add Product (${type})`}
          tabs={
            <div className="flex gap-[16px] mt-[16px]">
              <p
                className={
                  tab === "product_details"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("product_details")}
              >
                Product Details
              </p>
              <p
                className={
                  tab === "pricing_and_location"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("pricing_and_location")}
              >
                Pricing and Location
              </p>
            </div>
          }
        >
          <div className="relative">
            <div className="flex gap-[34px] items-center">
              <div className="flex items-center gap-[16px]">
                <ButtonPrimary>Save Changes</ButtonPrimary>
                <p className="text-[#5C715E] font-medium text-sm leading-5 cursor-pointer">
                  Cancel
                </p>
              </div>
            </div>
          </div>
        </Header>
      </div>
      {tab === "product_details" ? (
        <div className="flex gap-4 h-full w-full">
          <div className="w-[45%]">
            <TableBackground classname="rounded-lg p-4 h-fit">
              <p className="text-[#7C8D7E] font-semibold text-sm leading-[18px] mb-3">
                Product Details
              </p>
              <div className="flex flex-col gap-4">
                <div>
                  <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                    Product Name
                  </p>
                  <input
                    type="text"
                    placeholder="Product Name"
                    className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                  />
                </div>

                {type === "Seed Dressing" || type === "Fertilizers" ? null : (
                  <div>
                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                      Product Class
                    </p>
                    <input
                      type="text"
                      placeholder="Product Class"
                      className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                    />
                  </div>
                )}
                {type === "Fertilizers" ? null : (
                  <div>
                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                      Product Description
                    </p>
                    <textarea
                      type="text"
                      placeholder="Product Description"
                      className="outline-none resize-none border border-[#96A397] w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                    />
                  </div>
                )}

                {type !== "Fertilizers" ? null : (
                  <div>
                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                      Composition
                    </p>
                    <input
                      type="text"
                      placeholder="Composition"
                      className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                    />
                  </div>
                )}

                {type !== "Fertilizers" ? null : (
                  <div>
                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                      Mode of Action
                    </p>
                    <textarea
                      type="text"
                      placeholder="Mode of Action"
                      className="outline-none resize-none border border-[#96A397] w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                    />
                  </div>
                )}

                {type === "Seed Dressing" ||
                type === "Fertilizers" ||
                type === "Bird Repellant" ||
                type === "Nematicide" ||
                type === "Rodenticide" ||
                type === "Storage Product" ? null : (
                  <div>
                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                      Application Time
                    </p>
                    <textarea
                      type="text"
                      placeholder="Application Time"
                      className="outline-none resize-none border border-[#96A397] w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                    />
                  </div>
                )}
                {type === "Fertilizers" ? null : (
                  <div>
                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                      Target Crops
                    </p>
                    <textarea
                      type="text"
                      placeholder="Select Taget Crops"
                      className="outline-none resize-none border border-[#96A397] w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                    />
                  </div>
                )}
              </div>
              {type === "Fertilizers" ? null : (
                <p className="text-[#5C715E] font-medium text-xs leading-[16px] mb-3">
                  Use a comma(,) to separate different crops
                </p>
              )}
            </TableBackground>
          </div>
          <div className="w-[55%]">
            {" "}
            <TableBackground classname="rounded-lg p-4 h-fit">
              <p className="text-[#7C8D7E] font-semibold text-sm leading-[18px] mb-3">
                Chemical Composittion
              </p>
              <div className="flex flex-col gap-4">
                <div>
                  <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                    Dose Rate
                  </p>
                  <input
                    type="text"
                    placeholder="Dose Rate"
                    className="outline-none border border-[#96A397] w-[60%] h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                  />
                </div>

                {type !== "Fertilizers" ? null : (
                  <div>
                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                      Rate of Use
                    </p>
                    <input
                      type="text"
                      placeholder=" Rate of Use"
                      className="outline-none border border-[#96A397] w-[60%] h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                    />
                  </div>
                )}

                {type === "Fertilizers" ? null : (
                  <div>
                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                      Active Ingredients
                    </p>
                    <textarea
                      type="text"
                      placeholder="Active Ingredients"
                      className="outline-none resize-none border border-[#96A397] w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                    />
                  </div>
                )}

                {type === "Fertilizers" ? null : (
                  <div>
                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                      Mixing And Application
                    </p>
                    <textarea
                      type="text"
                      placeholder="Mixing And Application"
                      className="outline-none resize-none border border-[#96A397] w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                    />
                  </div>
                )}
              </div>
            </TableBackground>
          </div>
        </div>
      ) : tab === "pricing_and_location" ? (
        <div>
          <TableBackground classname="rounded-lg p-4 h-fit mb-2">
            <p className="text-[#7C8D7E] font-semibold text-sm leading-[18px] mb-3">
              Vendor and Sizing
            </p>

            <div className="mt-3 flex gap-4">
              <div className="relative">
                <div className="">
                  <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                    Vendor
                  </p>
                  <div
                    onClick={() => setToggleVendor(!isToggleVendor)}
                    className="w-[348px] cursor-pointer h-[44px] border border-[#96A397] rounded-lg flex items-center px-3 justify-between"
                  >
                    <p className="text-[#96A397] font-medium text-sm leading-[20px]">
                      {selectedvendor.name || "Select Vendor"}
                    </p>
                    <ExpandMore
                      style={{ color: "#96A397", fontSize: "20px" }}
                    />
                  </div>
                </div>
                {isToggleVendor && (
                  <div className="absolute rounded-lg right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                    {vendor?.map((data, index) => {
                      return (
                        <p
                          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                          key={index}
                          onClick={() => handleVendor(data)}
                        >
                          {data.name}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="">
                <div>
                  <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                    Size
                  </p>
                  <input
                    type="text"
                    className="outline-none w-[348px] border border-[#96A397] h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                  />
                  <p className="text-[#5C715E] font-medium text-xs leading-[16px] mb-3">
                    Use a comma(,) to separate different crops
                  </p>
                </div>
              </div>
            </div>
          </TableBackground>

          <TableBackground classname="rounded-lg p-4 h-fit">
            <p className="text-[#7C8D7E] font-semibold text-sm leading-[18px] mb-3">
              Location
            </p>

            <div className="mt-3 flex gap-8 w-full ">
              <div className="relative w-[32%]">
                <div className="">
                  <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                    States
                  </p>
                  <div
                    onClick={() => {
                      handleOpenStateFilter();
                      setClickState(!isClickState);
                    }}
                    className="w-full cursor-pointer h-[44px] border border-[#a7c1a9] rounded-lg flex items-center px-3 justify-between"
                  >
                    <p className="text-[#96A397] font-medium text-sm leading-[20px]">
                      {`Select State(s)`}
                    </p>
                    <ExpandMore
                      style={{ color: "#96A397", fontSize: "20px" }}
                    />
                  </div>
                </div>

                {isClickState && (
                  <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[200px] overflow-y-scroll shadow-md">
                    <div className="">
                      <SearchComponent
                        placeholder="Search..."
                        searchValue={stateSearchValue}
                        handleChange={handleSearchChange}
                      />
                    </div>
                    <div className="mt-2 px-4 py-4 text-sm font-semibold leading-[18px] ">
                      <p>{selectedState?.length} Selected</p>
                    </div>

                    <>
                      {allData?.stateData?.map((data, index) => {
                        const isChecked = selectedState.includes(data.id);

                        return (
                          <div
                            className="flex gap-4 items-center px-4 py-3"
                            key={index}
                            onClick={() => {
                              handleSelectedState(data);
                            }}
                          >
                            <input
                              type="checkbox"
                              className="accent-primary h-[14px] w-[14px] rounded-[2px]"
                              checked={isChecked}
                            />
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              {data.name}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  </div>
                )}

                <div className="flex flex-wrap gap-3 mt-3">
                  {selectedStateName?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handleSelectedState(data);
                        }}
                        className="bg-[#96A397] flex items-center gap-[6px]  cursor-pointer h-[26px] w-fit px-2 text-white rounded-md"
                      >
                        <p className="text-sm"> {data.display_name}</p>
                        <Clear style={{ fontSize: "16px" }} />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-[68%]">
                {selectedStateName.length !== 0 && (
                  <div>
                    <div className="">
                      <p className="text-[#7C8D7E] font-semibold text-sm leading-[18px] mb-3">
                        Pricing
                      </p>

                      <div className="flex gap-4 flex-col">
                        {Array.from(
                          { length: selectedStateName.length },
                          (_, index) => {
                            const value = selectedStateName[index];

                            return (
                              <div className="border-b border-b-[#DDE2DE] pb-3 w-fit">
                                <p className="text-[#1D5D23] font-medium text-sm leading-[18px] pb-1">
                                  {value?.display_name}
                                </p>
                                <div className="flex gap-3 items-center">
                                  <div>
                                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                                      Cost Price
                                    </p>
                                    <input
                                      type="text"
                                      placeholder="₦"
                                      className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                                    />
                                  </div>

                                  <div>
                                    <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                                      Display Price
                                    </p>
                                    <input
                                      type="text"
                                      placeholder="₦"
                                      className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </TableBackground>
        </div>
      ) : null}
    </div>
  );
};

export default Input;
