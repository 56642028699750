import { createSlice } from "@reduxjs/toolkit";
import {
  createCampaign,
  getAvailableFarmersCount,
  getCampaignAgents,
  getCampaignLog,
  getCampaignStats,
  getCampaignSummary,
  getCampaignSummaryStats,
  getCampaigns,
} from "./campaignAction";
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'


const initialState = {
  // GET ALL CAMPAIGNS
  campaignData: [],
  campaignTotal: 0,
  campaignLoading: false,

  // GET CAMPAIGNS STATS
  campaignStatsData: [],
  campaignStatsLoading: false,

  // GET CAMPAIGNS LOg
  campaignLogData: [],
  campaignLogLoading: false,

  // GET CAMPAIGNS AGENTS
  campaignAgentsData: [],
  campaignAgentsLoading: false,

  // CREATE CAMPAIGNS
  createCampaignLoading: false,

  // GET CAMPAIGNS SUMMARY
  campaignSummaryData: {},
  campaignSummaryLoading: false,

  // GET CAMPAIGNS SUMMARY STATS
  campaignSummaryStatsData: {},
  campaignSummaryStatsLoading: false,

  // GET AVAILABLE FARMERS COUNT
  availableFarmersCountData: {},
  availableFarmersCountLoading: false,
};

const campaignPersistConfig = {
  key: 'campaign',
  storage,
}

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
  extraReducers: {
    [getCampaigns.pending]: (state) => {
      state.campaignLoading = true;
    },
    [getCampaigns.fulfilled]: (state, { payload }) => {
      state.campaignLoading = false;
      state.campaignData = payload.data;
      state.campaignTotal = payload.total;
    },
    [getCampaigns.rejected]: (state) => {
      state.campaignLoading = false;
    },

    [getCampaignStats.pending]: (state) => {
      state.campaignStatsLoading = true;
    },
    [getCampaignStats.fulfilled]: (state, { payload }) => {
      state.campaignStatsLoading = false;
      state.campaignStatsData = payload.data;
    },
    [getCampaignStats.rejected]: (state) => {
      state.campaignStatsLoading = false;
    },

    // CAMPAIGN LOG
    [getCampaignLog.pending]: (state) => {
      state.campaignLogLoading = true;
    },
    [getCampaignLog.fulfilled]: (state, { payload }) => {
      state.campaignLogLoading = false;
      state.campaignLogData = payload.data;
    },
    [getCampaignLog.rejected]: (state) => {
      state.campaignLogLoading = false;
    },

    // CAMPAIGN AGENTS
    [getCampaignAgents.pending]: (state) => {
      state.campaignAgentsLoading = true;
    },
    [getCampaignAgents.fulfilled]: (state, { payload }) => {
      state.campaignAgentsLoading = false;
      state.campaignAgentsData = payload.data;
    },
    [getCampaignAgents.rejected]: (state) => {
      state.campaignAgentsLoading = false;
    },

    // CREATE CAMPAIGN
    [createCampaign.pending]: (state) => {
      state.createCampaignLoading = true;
    },
    [createCampaign.fulfilled]: (state, { payload }) => {
      state.createCampaignLoading = false;
    },
    [createCampaign.rejected]: (state) => {
      state.createCampaignLoading = false;
    },

    //  CAMPAIGN SUMMARY
    [getCampaignSummary.pending]: (state) => {
      state.campaignSummaryLoading = true;
    },
    [getCampaignSummary.fulfilled]: (state, { payload }) => {
      state.campaignSummaryLoading = false;
      state.campaignSummaryData = payload.data;
    },
    [getCampaignSummary.rejected]: (state) => {
      state.campaignSummaryLoading = false;
    },

    //  CAMPAIGN SUMMARY STATS
    [getCampaignSummaryStats.pending]: (state) => {
      state.campaignSummaryStatsLoading = true;
    },
    [getCampaignSummaryStats.fulfilled]: (state, { payload }) => {
      state.campaignSummaryStatsLoading = false;
      state.campaignSummaryStatsData = payload.data;
    },
    [getCampaignSummaryStats.rejected]: (state) => {
      state.campaignSummaryStatsLoading = false;
    },

    [getAvailableFarmersCount.pending]: (state) => {
      state.availableFarmersCountLoading = true;
    },
    [getAvailableFarmersCount.fulfilled]: (state, { payload }) => {
      state.availableFarmersCountLoading = false;
      state.availableFarmersCountData = payload.data;
    },
    [getAvailableFarmersCount.rejected]: (state) => {
      state.availableFarmersCountLoading = false;
    },
  },
});

const campaignReducer = persistReducer(campaignPersistConfig, campaignSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement } = campaignSlice.actions;

export default campaignReducer;
