import React, { useState, useEffect, useCallback, useMemo } from "react";
import SearchComponent from "../../components/Search";
import moment from "moment";
import DrawerComponent from "../../components/Drawers/drawers";
import {
  getUSSDSessions,
  searchUSSDSessions,
} from "../../redux/Sessions/sessionActions";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import { debounce } from "lodash";

const SessionsTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const { sessions, loader, total } = useSelector((state) => state.session);

  const [currentPage, setCurrentPage] = useState(1);

  const [typeID, setTypeID] = useState(1);
  const sessionTypes = [
    {
      id: 1,
      title: "All Sessions",
    },
    {
      id: 2,
      title: "Complete Sessions",
    },
    {
      id: 3,
      title: "Incomplete Sessions",
    },
  ];

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        switch (typeID) {
          case 1:
            dispatch(
              searchUSSDSessions({
                val: val,
                status: "all",
              })
            );
            break;
          case 2:
            dispatch(
              searchUSSDSessions({
                val,
                status: "Complete",
              })
            );
            break;
          case 3:
            dispatch(
              searchUSSDSessions({
                val,
                status: "Incomplete",
              })
            );
            break;
          default:
            break;
        }
      }, 750),
    [dispatch, typeID]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const toggleType = (id) => {
    setTypeID(id);
    switch (id) {
      case 1:
        dispatch(
          getUSSDSessions({
            page: 1,
            status: "all",
          })
        );
        break;
      case 2:
        dispatch(
          getUSSDSessions({
            page: 1,
            status: "Complete",
          })
        );
        break;
      case 3:
        dispatch(
          getUSSDSessions({
            page: 1,
            status: "Incomplete",
          })
        );
        break;
      default:
        break;
    }
  };

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    switch (typeID) {
      case 1:
        dispatch(
          getUSSDSessions({
            page: page,
            status: "all",
          })
        );
        break;
      case 2:
        dispatch(
          getUSSDSessions({
            page: page,
            status: "Complete",
          })
        );
        break;
      case 3:
        dispatch(
          getUSSDSessions({
            page: page,
            status: "Incomplete",
          })
        );
        break;
      default:
        break;
    }
  };

  const getCallBg = (val) => {
    let result;
    switch (val) {
      case "Incomplete":
        result = "error-badge";
        break;
      case "Complete":
        result = "success-badge";
        break;
      default:
        result = "error-badge";
        break;
    }
    return result;
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const closeOrder = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [sessionInfo, setSessionInfo] = useState({});

  const toggleSession = (id) => {
    setIsDrawerOpen(!isDrawerOpen);
    // find the request selected
    let session = sessions.find((val) => val.id === id);
    setSessionInfo(session);
  };

  useEffect(() => {
    dispatch(
      getUSSDSessions({
        page: 1,
        status: "all",
      })
    );
  }, [dispatch]);

  return (
    <>
      {/* session info drawer */}
      <DrawerComponent
        title="USSD Info"
        show={isDrawerOpen}
        showDrawer={closeOrder}
      >
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Name</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {sessionInfo && sessionInfo.farmer
              ? `${sessionInfo.farmer.first_name} ${sessionInfo.farmer.last_name}`
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Phone Number</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {sessionInfo && sessionInfo.farmer
              ? `${sessionInfo.farmer.phone_number}`
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Start Date</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {sessionInfo && sessionInfo.created
              ? moment(sessionInfo.created).format("Do, MMM YYYY")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Start Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {sessionInfo && sessionInfo.created
              ? moment(sessionInfo.created).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">End Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {sessionInfo && sessionInfo.updated
              ? moment(sessionInfo.updated).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">End Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {sessionInfo && sessionInfo.updated
              ? moment(sessionInfo.updated).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Session Status</h5>
          <div className="flex mt-2">
            <div
              className={getCallBg(
                sessionInfo && sessionInfo.session_status
                  ? sessionInfo.session_status
                  : "Incomplete"
              )}
            >
              <p>
                {sessionInfo && sessionInfo.session_status
                  ? sessionInfo.session_status
                  : "Incomplete"}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Activity </h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {sessionInfo && sessionInfo.text_activity
              ? sessionInfo.text_activity
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Previous Text</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {sessionInfo && sessionInfo.prev_text
              ? sessionInfo.prev_text
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Session ID</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {sessionInfo && sessionInfo.session_id
              ? sessionInfo.session_id
              : "N/A"}
          </p>
        </div>
      </DrawerComponent>

      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Total USSD Duration
          </p>
          <p className="text-[#7C8D7E] text-sm font-medium mt-1">60 Mins</p>

          {/* search */}
          <div className="mt-5">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {sessionTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : ""
                  }
                >
                  {typeID === item.id ? total : ""}
                </span>
              </li>
            ))}
          </ul>
        </div>
        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Name
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Phone Number
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Activity
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Session Duration
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Previous Text
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Date/Time
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Session Status
                </th>
              </tr>
            </thead>
            {!loader && (
              <tbody>
                {sessions.length > 0
                  ? sessions.map((item) => (
                      <tr
                        key={item.id}
                        onClick={() => toggleSession(item.id)}
                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      >
                        <th
                          scope="row"
                          className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                        >
                          {item.farmer.first_name} {item.farmer.last_name}
                        </th>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.farmer.phone_number}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.text_activity ? item.text_activity : "N/A"}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.duration ? item.duration : "N/A"}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.prev_text ? item.prev_text : "N/A"}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.created
                            ? moment(item.created).format(
                                "Do, MMM YYYY hh:mm:ss a"
                              )
                            : ""}
                        </td>
                        <td className="py-4 px-4">
                          <div className="flex">
                            <div className={getCallBg(item.session_status)}>
                              <p>{item.session_status}</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            )}
          </table>

          {loader && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>

      {!loader && sessions.length > 0 ? (
        <div className="mt-1">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={10}
            totalItems={total}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SessionsTable;
