import React, { useEffect, useState } from "react";
import TableBackground from "../../components/TableBackground";
// import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
// import SearchComponent from "../../components/Search";
import EmptyState from "../../components/EmptyState";
import { getWalletDeductions } from "../../redux/Location/locationAction";
import moment from "moment";
import { FormatNumber } from "../../components/FormatNumber";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";

const DebitFarmerTable = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("all");
  // const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { walletData, walletLoading, walletTotal } = useSelector(
    (state) => state.location
  );

  // COMPLAINTS PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(getWalletDeductions({ page: page, value: status }));
  };

  // const handleSearch = useMemo(
  //   () =>
  //     debounce((val) => {
  //       //  api to dispatch and make the actions for search
  //       dispatch(getWalletDeductions({ page: 1, value: status }));
  //     }, 750),
  //   [dispatch, status]
  // );

  // const handleChange = useCallback(
  //   (e) => {
  //     let val = e.target.value;
  //     setSearchValue(val);
  //     handleSearch(val);
  //   },
  //   [handleSearch]
  // );

  const handleStatusChange = (tabStatus) => {
    setStatus(tabStatus);
    dispatch(getWalletDeductions({ page: 1, value: tabStatus }));
  };
  const handlePhoneNumber = (e, number) => {
    e.stopPropagation();
    navigator.clipboard.writeText(number);
    cogoToast.success("Phone Number copied successfully!");
  };

  useEffect(() => {
    dispatch(getWalletDeductions({ page: 1, value: status }));
  }, [dispatch, status]);

  return (
    <div>
      <TableBackground>
        <p className="text-primary font-semibold text-base leading-5 pt-4 px-4">
          Debit Transactions
        </p>

        {/* SEARCH AND FILTER SECTION */}
        {/* <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section> */}

        {/* TABS SECTION */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 cursor-pointer px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "all" ? walletData?.length : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "successful"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("successful")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Successful
                </p>
                <div
                  className={
                    status === "successful"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "successful" ? walletData?.length : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "failed"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("failed")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Failed
                </p>
                <div
                  className={
                    status === "failed"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "failed" ? walletData?.length : "0"}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {walletLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {walletData.length === 0 ? (
                <EmptyState text="There's no data for this filter yet" />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Phone Number
                      </th>

                      <th scope="col" className="px-4 pb-2">
                        {" "}
                        Amount To Debit
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Amount Debitted
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Outstanding Debt
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Initiated By
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Date/Time
                      </th>

                      <th scope="col" className="px-4 pb-2">
                        Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {walletData.map((data, index) => {
                      return (
                        <tr
                          onClick={() =>
                            navigate(`/farmers-profile/${data.farmer_id}`)
                          }
                          key={index}
                          className="relative  hover:bg-[#edf7ee]"
                        >
                          <td className="px-4">{data?.farmer_name || "N/A"}</td>
                          <td
                            className="px-4 cursor-pointer"
                            onClick={(e) =>
                              handlePhoneNumber(e, data?.farmer_phone_number)
                            }
                          >
                            {data?.farmer_phone_number || "N/A"}
                          </td>
                          <td className="px-4">
                            ₦{FormatNumber(data?.to_debit) || "N/A"}
                          </td>
                          <td className="px-4">
                            ₦{FormatNumber(data?.debited_amount) || "N/A"}
                          </td>
                          <td className="px-4">
                            ₦{FormatNumber(data?.outstanding_debit) || "N/A"}
                          </td>
                          <td className="px-4">
                            {data?.initiated_by?.first_name +
                              " " +
                              data?.initiated_by?.last_name || "N/A"}
                          </td>
                          <td className="px-4">
                            {moment(data?.created).format("ll")}
                            {moment(data?.created).format("LT")}
                          </td>
                          {/* APPROVAL STATUS */}

                          <td className="">
                            <div
                              className={
                                data?.status === "successful"
                                  ? "success-badge"
                                  : data?.status === "failed"
                                  ? "error-badge"
                                  : data?.status === "pending"
                                  ? "pending-badge"
                                  : data?.status === "partial"
                                  ? "pending-badge"
                                  : "na-badge"
                              }
                            >
                              <p className="capitalize">{data?.status}</p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}

          {!walletLoading && walletData.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={walletTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default DebitFarmerTable;
