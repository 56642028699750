import React from "react";
import Header from "../../components/Header";
import CallToOrderTable from "../../tables/CallToOrder";

const CallToOrder = () => {
  return (
    <div>
      <Header text="Call To Order" />

      {/* TABLE SECTION */}
      <section className="mt-[24px]">
        <CallToOrderTable />
      </section>
    </div>
  );
};

export default CallToOrder;
