import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { loginValidator } from "../../validationSchema/validator";
import eye_off from "../../assets/icons/eye-off.svg";
import eye from "../../assets/icons/eye.svg";
import warning from "../../assets/icons/warning.svg";
import { Link, useNavigate } from "react-router-dom";
import OnboardingSidebar from "../../components/OnboardingSidebar/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/Auth/authActions";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleSubmit = (values) => {
    dispatch(userLogin(values));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/overview");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
        <OnboardingSidebar />
        <div className="w-full md:w-[60%] px-4 md:px-0 flex justify-center flex-col min-h-screen">
          <div className="min-w-full md:min-w-[32rem] mx-auto">
            <h6 className="text-primary font-semibold text-lg">Welcome Back</h6>
            <p className="text-[#5C715E] text-sm font-medium max-w-lg">
              Enter your Credentials below to login{" "}
            </p>

            <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={loginValidator}
              initialValues={{
                username: "",
                password: "",
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="mt-8" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="username"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      name="username"
                      placeholder="Enter your username"
                      className={
                        touched.username && errors.username
                          ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                          : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                      }
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.username && errors.username ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.username && errors.username}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-secondary text-sm font-normal"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className={
                          touched.password && errors.password
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <img
                        onClick={togglePasswordVisiblity}
                        src={passwordShown ? eye_off : eye}
                        alt="eye"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      />
                    </div>
                    {touched.password && errors.password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.password && errors.password}
                        </small>
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-2">
                    <Link
                      to="/forgotpassword"
                      className="text-secondary font-medium text-sm"
                    >
                      Forgot your password?
                    </Link>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      disabled={loading}
                      className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Log In
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
