import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaginationComponent from "../../../components/Pagination/Pagination";
import TableBackground from "../../../components/TableBackground";
import EmptyState from "../../../components/EmptyState";
import { getAgentTransactions } from "../../../redux/Agents/agentsAction";
import { debounce } from "lodash";
import SearchComponent from "../../../components/Search";
import moment from "moment";

const BankTransactionsTable = () => {
  const [status, setStatus] = useState("all");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const { id } = useParams();

  const {
    agentTransactionsData,
    agentTransactionsTotal,
    agentTransactionsLoading,
  } = useSelector((state) => state.agents);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getAgentTransactions({
        id: id,
        page: currentPage,
        search: page,
        status: status,
      })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        dispatch(
          getAgentTransactions({
            id: id,
            page: currentPage,
            search: val,
            status: status,
          })
        );
      }, 750),
    [dispatch, status, currentPage, id]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleStatusChange = (tabStatus) => {
    setStatus(tabStatus);
    dispatch(
      getAgentTransactions({
        id: id,
        page: currentPage,
        search: searchValue,
        status: tabStatus,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAgentTransactions({
        id: id,
        page: currentPage,
        search: searchValue,
        status: status,
      })
    );
  }, [dispatch, status, id, currentPage, searchValue]);

  // const navigate = useNavigate()
  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS SECTION */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 cursor-pointer px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "all" ? agentTransactionsTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "credit"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("credit")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Credit
                </p>
                <div
                  className={
                    status === "credit"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "credit" ? agentTransactionsTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "debit"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("debit")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Debit
                </p>
                <div
                  className={
                    status === "debit"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "debit" ? agentTransactionsTotal : "0"}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-[5px]">
          {agentTransactionsLoading ? (
            <div className="animate-pulse w-full mt-2 mb-4 pb-4 px-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {agentTransactionsData.length === 0 ? (
                <div>
                  <EmptyState text="There's no data for this filter yet" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        Transaction ID
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Activity
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Previous Balance
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Cost
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        New Balance
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Date
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Time
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Transaction Type
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        {" "}
                        Transaction Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentTransactionsData.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                          // onClick={() => setIsClickTable(!isClickTable)}
                        >
                          <td className="px-4">{data.id}</td>
                          <td className="capitalize px-4">
                            {data.activity
                              ?.replace("_", " ")
                              ?.replace("_", " ")}
                          </td>
                          <td className="px-4">
                            N{data.prev_balance || "N/A"}
                          </td>
                          <td className="px-4">N{data.amount || "N/A"}</td>
                          <td className="px-4">N{data.new_balance || "N/A"}</td>
                          <td className="px-4">
                            {moment(data.created).format("ll")}
                          </td>
                          <td className="px-4">
                            {moment(data.created).format("LT")}
                          </td>
                          <td className="px-4">
                            <div
                              className={
                                data.transaction_type === "debit"
                                  ? "error-badge"
                                  : data.transaction_type === "credit"
                                  ? "success-badge"
                                  : null
                              }
                            >
                              <p>
                                {data.transaction_type === "debit"
                                  ? "Debit"
                                  : data.transaction_type === "credit"
                                  ? "Credit"
                                  : null}
                              </p>
                            </div>
                          </td>
                          <td className="px-4">
                            <div
                              className={
                                data.transaction_status
                                  ? "success-badge"
                                  : "error-badge"
                              }
                            >
                              <p>
                                {data.transaction_status
                                  ? "Successful"
                                  : "Failed"}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}

          {!agentTransactionsLoading && agentTransactionsData.length > 0 ? (
            <div className="mt-1 pl-[-16px]" style={{ paddingLeft: "-16px" }}>
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={agentTransactionsTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default BankTransactionsTable;
