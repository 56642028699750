import React, { useState } from "react";
import GoBack from "../../../components/GoBack";
import Header from "../../../components/Header";
import { ButtonSecondary } from "../../../components/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import TableBackground from "../../../components/TableBackground";
import { CalendarToday, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { createLoanPorfolio, fetchLoanPackages } from "../../../redux/Loans/loanActions";
import cogoToast from "cogo-toast";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const LoanDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const [createLoanLoading, setCreateLoanLoading] = useState(false)
  const dispatch = useDispatch();
  const gender = [
    { key: "afc", label: "AFC" },
    { key: "input_finance", label: "FFL" },
  ];
  const tenure = [
    { key: 1, label: "1 month" },
    { key: 2, label: "2 months" },
    { key: 3, label: "3 month" },
    { key: 4, label: "4 months" },
    { key: 5, label: "5 month" },
    { key: 6, label: "6 months" },
    { key: 7, label: "7 month" },
    { key: 8, label: "8 months" },
    { key: 9, label: "9 month" },
    { key: 10, label: "10 months" },
    { key: 11, label: "11 month" },
    { key: 12, label: "12 months" },
  ];

  const { farmersDetails } = useSelector((state) => state.loan);
  const [isLoanTypeClicked, setIsLoanTypeClicked] = useState(false);
  const [selectedLoanType, setSelectedLoanType] = useState({});
  const [isLoanTenureClicked, setIsLoanTenureClicked] = useState(false);
  const [selectedLoanTenure, setSelectedLoanTenure] = useState({});
  const [isLoanPackageClicked, setIsLoanPackageClicked] = useState(false);
  const [selectedLoanPackage, setSelectedLoanPackage] = useState("");
  const [selectedLoanPackageInfo, setSelectedLoanPackageInfo] = useState({})
  const [equityContribution, setEquityContribution] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanPackages, setLoanPackages] = useState([])
  const [loanPackagesLoading, setLoanPackagesLoading] = useState(false)
  const [dateCreated, setDateCreated] = useState(new Date())



  const handleSelectLoanType = (label, key) => {
    setSelectedLoanType({ key, label });
    setIsLoanTypeClicked(false);
    setIsLoanTypeClicked(false)
    setSelectedLoanPackage("")
    setInterestRate("")
    setSelectedLoanTenure({})
  };

  const handleSelectLoanTenure = (label, key) => {
    setSelectedLoanTenure({ key, label });
    setIsLoanTenureClicked(false);
  };

  const handleSelectLoanPackage = (label, data) => {
    setSelectedLoanPackageInfo(data)
    setSelectedLoanPackage(label);
    setSelectedLoanTenure({ key: data?.tenure, label: `${data?.tenure} months` })
    setInterestRate(data?.interest_rate)
    setIsLoanPackageClicked(false);
  };





  const handleOpenLoanPackage = () => {
    setLoanPackagesLoading(true)
    setLoanPackages([])
    dispatch(fetchLoanPackages({ type: selectedLoanType.key })).then(res => {

      setLoanPackages(res.payload.data)

      setLoanPackagesLoading(false)
    }).catch(error => {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      cogoToast.error("Something went wrong")
      setLoanPackagesLoading(false)
    })
    setIsLoanPackageClicked(!isLoanPackageClicked);
  };


  const handleCreateLoan = () => {
    setCreateLoanLoading(true)
    const values = {
      farmer_id: Number(id),
      loan_type: selectedLoanType.key,
      loan_package: selectedLoanPackage,
      equity_contribution: Number(equityContribution) === 0 ? null : Number(equityContribution),
      loan_tenure: selectedLoanTenure.key,
      interest_rate: Number(interestRate),
      date_disbursed: moment(dateCreated).format("YYYY/DD/MM")
    };
    dispatch(createLoanPorfolio(values)).then((res) => {
      if (res?.error?.message === "Rejected" && typeof (res?.payload) === "string") {
        cogoToast.error("Something went wrong")
      } else {
        if (res?.payload?.errors) {
          setCreateLoanLoading(false)
          cogoToast.error(res?.payload?.errors)
        } else {
          setCreateLoanLoading(false)
          cogoToast.success("Farmer's loan was created successfully!")
          setSelectedLoanType({})
          setSelectedLoanPackage("")
          setEquityContribution("")
          setSelectedLoanTenure({})
          setInterestRate("")
          navigate(`/create-loan-porfolio`)
        }
      }
    }).catch(error => {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      cogoToast.error("Something went wrong")
    })
  };

  return (
    <div>
      <GoBack />
      <div className="mt-4 mb-6">
        {/* {console.log(farmersDetails)} */}
        <Header text={`Loan Details  | ${farmersDetails?.first_name + " " + farmersDetails?.last_name} (${farmersDetails?.phone_number})`} tabs={

          farmersDetails?.status === "Complete" && farmersDetails?.cooperative !== null ? null : <div className="mt-4">
            <p className="text-sm text-[#B92043] font-medium leading-5">
              {
                `The farmer's ${farmersDetails?.status === "Incomplete" ? "profile" : ""
                }${farmersDetails?.status === "Incomplete" && farmersDetails?.cooperative === null ? "," : ""}
                    ${farmersDetails?.cooperative === null ? "cooperative" : ""
                } and guarantor's information needs to be updated before you can proceed with creating a loan portfolio`
              }
            </p>
          </div>
        }>
          <div className="flex gap-4">
            {farmersDetails?.status === "Complete" && farmersDetails?.cooperative !== null ? null : <div>
              <ButtonSecondary href={`/edit-profile/${id}`}>
                Update Profile
              </ButtonSecondary>
            </div>}

            <div>
              <Link to={`/add-guarantor/${id}`}>
                <button className="outline-none bg-[#EDF7EE] border border-[#EDF7EE] text-primary font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]   transition ease-in-out delay-100">
                  Add Guarantors
                </button>
              </Link>
            </div>
          </div>
        </Header>
      </div>

      {farmersDetails?.status === "Complete" && farmersDetails?.cooperative !== null ?
        <TableBackground classname="p-[25px] rounded-[8px] mb-6">
          {/* -----------Loan Details SECTION --------------------- */}
          <div>
            <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E] mb-4">
              Loan Details
            </p>

            <div>
              {/* --------LOAN DETAILS -------------------- */}
              <div className="flex gap-6 w-full mb-4">
                {/* ------    Loan Type and Tenure -------------- */}
                <div className="w-1/3 relative">
                  <div className="relative w-full">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Loan Type
                    </p>

                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                      onClick={() => {
                        setIsLoanTypeClicked(!isLoanTypeClicked)
                        setIsLoanPackageClicked(false);
                      }}
                    >
                      <p>{selectedLoanType.label || "Select a loan type"}</p>

                      {isLoanTypeClicked ? (
                        <ExpandLess
                          className="text-[#344335] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#344335] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {isLoanTypeClicked && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full  bg-white z-10 mt-2 max-h-[200px] overflow-auto call2orderOverflow"
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        {gender.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                              onClick={() =>
                                handleSelectLoanType(data.label, data.key)
                              }
                            >
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.label}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  {/* ------    Loan Tenure -------------- */}
                  <div className="relative mt-6">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Loan Tenure
                    </p>

                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                      onClick={() => setIsLoanTenureClicked(!isLoanTenureClicked)}
                    >
                      <p>{selectedLoanTenure.label || "Select a tenure"}</p>

                      {isLoanTenureClicked && !selectedLoanPackageInfo?.tenure ? (
                        <ExpandLess
                          className="text-[#344335] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#344335] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {(isLoanTenureClicked && !selectedLoanPackageInfo?.tenure) && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full  bg-white z-10 mt-2 max-h-[200px] overflow-auto call2orderOverflow"
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        {tenure.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                              onClick={() =>
                                handleSelectLoanTenure(data.label, data.key)
                              }
                            >
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.label}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                {/* ------   Loan Package and Interest rate -------------- */}
                <div className="w-1/3 relative">
                  <div className="w-full relative">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Loan Package
                    </p>

                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 px-4 py-3  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                      onClick={() => handleOpenLoanPackage()}
                    >
                      <p>
                        {selectedLoanPackage || `Select a loan package`}

                      </p>

                      {isLoanPackageClicked ? (
                        <ExpandLess
                          className="text-[#344335] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#344335] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>



                    {isLoanPackageClicked && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full  bg-white z-10 mt-2 max-h-[200px] overflow-auto call2orderOverflow"
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        {loanPackagesLoading ? <div className="my-8 mx-4">
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            Loading....
                          </p>
                        </div> : <>
                          {loanPackages?.map((data, index) => {

                            return (
                              <div
                                key={index}
                                className="px-[16px] py-[12px] rounded-[8px] border-[#EDF7EE] hover:border-primary hover:border-[1px] bg-[#FFF] hover:bg-[#EDF7EE] cursor-pointer"
                                onClick={() =>
                                  handleSelectLoanPackage(data.package_name, data)
                                }
                              >
                                <div className="text-[#5C715E] hover:text-primary">
                                  <p className="font-medium text-sm leading-5 ">
                                    {data.package_name}
                                  </p>
                                  <p className=" text-sm font-medium leading-4">
                                    {data?.package_description}
                                  </p>
                                </div>
                              </div>
                            );
                          })}</>}

                      </div>
                    )}
                  </div>

                  {/* ------  Interest Rate -------------- */}
                  <div className="w-full relative mt-6">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Interest Rate (%)
                    </p>

                    <div>
                      <input
                        disabled={selectedLoanPackageInfo?.interest_rate ? true : false}
                        onChange={(event) => setInterestRate(event.target.value)}
                        value={interestRate}
                        type="number"
                        min="0"
                        placeholder="3% per month"
                        className="outline-0 px-4 py-3  placeholder:text-[#344335] bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                      />
                    </div>
                  </div>
                </div>

                {/* ------   Equity Contribution -------------- */}
                <div className="w-1/3 relative">
                  <div className="w-full relative">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Equity Contribution
                    </p>

                    <div>
                      <input
                        disabled={selectedLoanType.label === "AFC" ? true : false}
                        onChange={(event) => setEquityContribution(event.target.value)}
                        value={equityContribution}
                        // value={selectedLoanType.label === "FFL" ? equityContribution : ""}
                        type="number"
                        placeholder={selectedLoanType.label === "AFC" ? "No Equity Required" : "₦15,000"}
                        className="outline-0 px-4 py-3 placeholder:text-[#344335] disabled:bg-[#F7F7F7] disabled:text-[#ABB6AC] disabled:border-[#96A397]  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                      />
                    </div>
                  </div>

                  {/* ------  Date Created -------------- */}
                  <div className="w-full relative mt-6">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Creation Date
                    </p>

                    <div className="border relative items-center mt-1 border-[#abb6ac] flex center h-[44px] font-mdium text-sm leading-5 text-[#344335] gap-2 cursor-pointer px-[12px] py-[16px] rounded-[8px] w-full">

                      <div className="relative">
                        <CalendarToday className="absolute top-0 z-10 flex items-center justify-center" style={{ fontSize: "18px" }} />
                        <DatePicker maxDate={moment().toDate()} selected={dateCreated} onChange={(date) => setDateCreated(date)} className="w-[24vw] h-full pl-[25px] outline-none" />
                      </div>

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </TableBackground>
        :
        <TableBackground classname="p-[25px] rounded-[8px] mb-6">
          {/* -----------Loan Details SECTION --------------------- */}
          <div>
            <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E] mb-4">
              Loan Details
            </p>

            <div>
              {/* --------LOAN DETAILS -------------------- */}
              <div className="flex gap-6 w-full mb-4">
                {/* ------    Loan Type and Tenure -------------- */}
                <div className="w-1/3 relative">
                  <div className="relative w-full">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Loan Type
                    </p>

                    <div
                      className="flex bg-[#F7F7F7] text-[#ABB6AC] border-[#96A397] items-center cursor-pointer justify-between outline-0 px-4 py-3  rounded-[8px] border  w-full font-mdium text-sm leading-5 "

                    >
                      <p>{selectedLoanType.label || "Select a loan type"}</p>

                      {isLoanTypeClicked ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>


                  </div>

                  {/* ------    Loan Tenure -------------- */}
                  <div className="relative mt-6">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Loan Tenure
                    </p>

                    <div
                      className="flex bg-[#F7F7F7] text-[#ABB6AC] border-[#96A397] items-center cursor-pointer justify-between outline-0 px-4 py-3  rounded-[8px] border  w-full font-mdium text-sm leading-5 "
                    >
                      <p>{selectedLoanTenure.label || "Select a tenure"}</p>

                      {isLoanTenureClicked ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>


                  </div>
                </div>

                {/* ------   Loan Package and Interest rate -------------- */}
                <div className="w-1/3 relative">
                  <div className="w-full relative">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Loan Package
                    </p>

                    <div
                      className="flex bg-[#F7F7F7] text-[#ABB6AC] border-[#96A397] items-center cursor-pointer justify-between outline-0 px-4 py-3  rounded-[8px] border  w-full font-mdium text-sm leading-5 "
                    >
                      <p>
                        {selectedLoanPackage || "Select a loan package"}
                      </p>

                      {isLoanPackageClicked ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>
                    <p></p>


                  </div>

                  {/* ------  Interest Rate -------------- */}
                  <div className="w-full relative mt-6">
                    <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                      Interest Rate (% per month)
                    </p>

                    <div>
                      <input
                        disabled={true}
                        onChange={(event) => setInterestRate(event.target.value)}
                        value={interestRate}
                        type="number"
                        min="0"
                        placeholder="3% per month"
                        className="outline-0 px-4 py-3 disabled:bg-[#F7F7F7] disabled:text-[#ABB6AC] disabled:border-[#96A397]   bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#344335]"
                      />
                    </div>
                  </div>
                </div>

                {/* ------   Equity Contribution -------------- */}
                <div className="w-1/3 relative">
                  <p className="text-sm font-[400] leading-[18px] text-[#344335] mb-1">
                    Equity Contribution
                  </p>

                  <div>
                    <input
                      disabled={true}
                      onChange={(event) => setEquityContribution(event.target.value)}
                      value={equityContribution}
                      // value={selectedLoanType.label === "FFL" ? equityContribution : ""}
                      type="number"
                      placeholder={selectedLoanType.label === "AFC" ? "No Equity Required" : "₦15,000"}
                      className="outline-0 px-4 py-3  disabled:bg-[#F7F7F7] disabled:text-[#ABB6AC] disabled:border-[#96A397]  bg-white rounded-[8px] border border-[#96A397]  w-full font-mdium text-sm leading-5 text-[#96A397]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TableBackground>
      }


      <div className="flex justify-end gap-6">
        <ButtonSecondary>Cancel</ButtonSecondary>

        {createLoanLoading ?
          <button
            type="submit"
            disabled={true}
            className="bg-[#ABB6AC] 
                           disabled:bg-[#ABB6AC] 
                           rounded-lg px-8 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Create Loan Portfolio
          </button> : <button
            type="submit"
            onClick={handleCreateLoan}
            disabled={selectedLoanType.label === "FFL" ? Object.values(selectedLoanType).length !== 0 && Object.values(selectedLoanTenure).length !== 0 && interestRate.length !== 0 && selectedLoanPackage.length !== 0 && equityContribution.length !== 0 && createLoanLoading === false ? false : true : Object.values(selectedLoanType).length !== 0 && Object.values(selectedLoanTenure).length !== 0 && interestRate.length !== 0 && selectedLoanPackage.length !== 0 && createLoanLoading === false ? false : true}
            className="bg-primary 
                           disabled:bg-[#ABB6AC] 
                           rounded-lg px-8 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Create Loan Portfolio
          </button>}


      </div>
    </div>
  );
};

export default LoanDetails;
