import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import {
  getCallToOrder,
  updateACallToOrder,
} from "../../redux/CallToOrder/callToOrderAction";
import CreateOrderModal from "./CreateOrderModal";
import { useNavigate } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";

const CallToOrderTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const call_status = [
    { key: "ALL", label: "All" },
    { key: "CALLED", label: "Called" },
    { key: "MISSED", label: "Missed" },
    { key: "TO_CALL", label: "Not Called" },
  ];
  const [status, setStatus] = useState("ALL");
  const [isClickStatus, setIsClickStatus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const c2oPagination = sessionStorage.getItem("c2o_page");
  const { callToOrderData, callToOrderTotal, callToOrderLoading } = useSelector(
    (state) => state.callToOrder
  );
  const call2orderPagination = sessionStorage.getItem("call2order_page");
  const call2orderTabValue =
    sessionStorage.getItem("call2orderTabValue") || "ALL";

  const handleNavigateCallToOrder = (id) => {
    navigate(`/call-to-order/${id}`);
  };

  const handleChangeCallStatus = (id, e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setIsClickStatus(!isClickStatus);
  };

  console.log(status);

  // HANDLES CALL STATUS CLICK
  const handleClickStatus = (key, label, id, e) => {
    e.stopPropagation();
    let value = { call_status: key };
    dispatch(updateACallToOrder({ value, id: id }));
    setIsClickStatus(false);
    setTimeout(() => {
      dispatch(
        getCallToOrder({ page: c2oPagination || 1, status: call2orderTabValue })
      );
    }, 800);
  };

  // CALL TO ORDER PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    sessionStorage.setItem("call2order_page", page);
    dispatch(
      getCallToOrder({
        page: page,
        status: call2orderTabValue,
        value: sessionStorage.getItem("call2order_search_value") || "",
      })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        setSearchValue(val);
        sessionStorage.setItem("call2order_search_value", val);
        dispatch(
          getCallToOrder({
            page: call2orderPagination ? call2orderPagination : 1,
            status: call2orderTabValue,
            value: val,
          })
        );
      }, 750),
    [dispatch, call2orderPagination, call2orderTabValue]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleChangeStatus = (data) => {
    setStatus(data);
    sessionStorage.setItem("call2orderTabValue", data);
  };

  useEffect(() => {
    const storedvalue = sessionStorage.getItem("call2order_search_value");
    const page = call2orderPagination ? call2orderPagination : 1;
    setSearchValue(storedvalue);
    dispatch(
      getCallToOrder({
        page: page || 1,
        status: call2orderTabValue,
        value: storedvalue || "",
      })
    );
  }, [dispatch, call2orderPagination, call2orderTabValue]);

  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4 w-full pt-4  px-4">
          <div className="w-full relative">
            <SearchComponent
              style={{ height: "42px" }}
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <div>
            <CreateOrderModal />
          </div>
        </section>

        {/* TABS SECTION */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 cursor-pointer px-4">
            {call_status?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    call2orderTabValue === item.key
                      ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                      : "flex text-[#96A397] pb-[4px]"
                  }
                  onClick={() => handleChangeStatus(item?.key)}
                >
                  <div className="flex">
                    <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                      {item?.label}
                    </p>
                    <div
                      className={
                        call2orderTabValue === item?.key
                          ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                          : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      }
                    >
                      <p>
                        {call2orderTabValue === item?.key
                          ? callToOrderTotal
                          : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {callToOrderLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th scope="col" className="px-4 pb-2">
                    Name
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Phone Number
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Date Created
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Order Channel
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Call Status
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    CTO Status
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Payment
                  </th>
                </tr>
              </thead>

              <tbody>
                {callToOrderData?.map((data, index) => {
                  let ctoStatus = "";

                  if (data.cto_status) {
                    ctoStatus = data?.cto_status?.at(-1);
                  } else {
                    ctoStatus = "PENDING";
                  }

                  return (
                    <tr
                      key={index}
                      className="relative cursor-pointer hover:bg-[#edf7ee]"
                      onClick={() => handleNavigateCallToOrder(data?.id)}
                    >
                      <td className="px-4">
                        {data.farmer.first_name + " " + data.farmer.last_name}
                      </td>
                      <td className="px-4"> {data.farmer.phone_number}</td>
                      <td className="px-4">
                        {moment(data.created).format("lll")}
                      </td>
                      <td className="px-4">{data?.order_channel || "N/A"}</td>
                      <td className="px-4">
                        {" "}
                        <div className="relative">
                          <div
                            className={
                              data.call_status === "CALLED"
                                ? "success-badge flex gap-[9px] items-center"
                                : data.call_status === "MISSED"
                                ? "pending-badge flex gap-[9px] items-center"
                                : data.call_status === "TO_CALL"
                                ? "error-badge flex gap-[9px] items-center"
                                : "na-badge flex gap-[9px] items-center"
                            }
                            onClick={(event) =>
                              handleChangeCallStatus(data?.id, event, index)
                            }
                          >
                            <p>
                              {data.call_status === "CALLED"
                                ? "Called"
                                : data.call_status === "MISSED"
                                ? "Missed"
                                : data.call_status === "TO_CALL"
                                ? "Not Called"
                                : "N/A"}
                            </p>
                            {data.call_status === "CALLED" ? (
                              <ExpandMore
                                className="text-primary cursor-pointer"
                                style={{ fontSize: "12px" }}
                              />
                            ) : data.call_status === "MISSED" ? (
                              <ExpandMore
                                className="text-[#d5a407] cursor-pointer"
                                style={{ fontSize: "12px" }}
                              />
                            ) : data.call_status === "TO_CALL" ? (
                              <ExpandMore
                                className="text-[#b92043] cursor-pointer"
                                style={{ fontSize: "12px" }}
                              />
                            ) : (
                              <ExpandMore
                                className="text-[#96A397] cursor-pointer"
                                style={{ fontSize: "12px" }}
                              />
                            )}
                          </div>
                          {isClickStatus && selectedIndex === index ? (
                            <div
                              className="absolute rounded-[8px] p-[8px] w-full bg-white z-10"
                              style={{
                                boxShadow:
                                  "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                              }}
                            >
                              {call_status.map((callData, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                                    onClick={(event) =>
                                      handleClickStatus(
                                        callData.key,
                                        callData.label,
                                        data?.id,
                                        event
                                      )
                                    }
                                  >
                                    <p className="font-medium text-sm leading-5 text-[#344335]">
                                      {callData.label}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </td>
                      <td className="px-4">
                        {" "}
                        <div className="flex">
                          <div
                            className={
                              ctoStatus === "PENDING"
                                ? "pending-badge capitalize"
                                : ctoStatus === "PROCESSING"
                                ? "pending-badge capitalize"
                                : null
                            }
                          >
                            <p className="capitalize">
                              {ctoStatus[0] +
                                ctoStatus?.replace(/^./, "").toLowerCase()}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-4">
                        {" "}
                        <div className="flex">
                          <div
                            className={
                              data.payment_status
                                ? "success-badge"
                                : "error-badge"
                            }
                          >
                            <p>{data.payment_status ? "Paid" : "Unpaid"}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {!callToOrderLoading && callToOrderData?.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={call2orderPagination ? Number(call2orderPagination) : 1}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={callToOrderTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default CallToOrderTable;
