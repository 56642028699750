import React, { useEffect } from "react";
import Header from "../../components/Header";
import plus_white from "../../assets/icons/plus_white.svg";
import { Card } from "../../components/Cards";
import MessagesTable from "../../tables/Messages";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessageTemplateCount,
  getPendingMessages,
} from "../../redux/Messages/messageActions";

const MessagesPage = () => {
  const dispatch = useDispatch();
  const { count, pendingTotal } = useSelector((state) => state.message);

  useEffect(() => {
    dispatch(getMessageTemplateCount());
    dispatch(
      getPendingMessages({
        page: 1,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Header text="Messages">
        <div>
          <Link
            to="/messages/compose-message"
            className="w-full py-3 flex items-center justify-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
          >
            <img src={plus_white} alt="plus icon" />
            <p className="text-white font-medium text-sm ml-2">
              Compose Message
            </p>
          </Link>
        </div>
      </Header>
      <div className="mt-4 grid grid-cols-4 gap-4">
        <Card
          title="Templates"
          subtitle={count ? count : 0}
          description="Create and edit message templates"
          href="/messages/templates"
        />
        <Card
          title="Pending Messages"
          subtitle={pendingTotal ? pendingTotal : 0}
          description="Approve or reject all pending messages"
          href="/messages/pending"
        />
      </div>

      <MessagesTable />
    </>
  );
};

export default MessagesPage;
