import {
  Close,
  FilterList,
  KeyboardArrowRight,
  MoreVert,
} from "@mui/icons-material";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import TableBackground from "../../components/TableBackground";
import { useOutsideAlerter } from "../../helpers/OutsideClick";
import BlockFarmer from "../../pages/Farmers/FarmersModals/BlockFarmer/index";
import SuspendFarmer from "../../pages/Farmers/FarmersModals/SuspendFarmer/index";
import { useDispatch, useSelector } from "react-redux";
import { getAllFarmers } from "../../redux/Farmers/farmersActions";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import UnblockFarmer from "../../pages/Farmers/FarmersModals/UnblockFarmer";
import UnsuspendFarmer from "../../pages/Farmers/FarmersModals/UnsuspendFarmer";
import EmptyState from "../../components/EmptyState";
import {
  getAllLGAByIds,
  getAllState,
} from "../../redux/Location/locationAction";

const FarmersTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isClickMore, setIsClickMore] = useState(false);
  const [clickIndex, setIsClickIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const [lgaSearchValue, setLgaSearchValue] = useState("");
  const [rankSearchValue, setRankSearchValue] = useState("");
  const [stateSearchValue, setStateSearchValue] = useState("");
  const [selectedState, setSelectedState] = useState([]);
  const [selectedLga, setSelectedLga] = useState([]);
  const [selectedRank, setSelectedRank] = useState("");
  const [bvnNoAcctNo, setBvnNoAcctNo] = useState(false);
  const [acctNoNoBvn, setAcctNoNoBvn] = useState(false);
  const [clickState, updateClickState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      clickedFilter: "",
    }
  );

  const [allData, updateAllData] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      stateData: [],
      lgaDataByID: [],
      duplicatedStateData: [],
      duplicatedLgaData: [],
      rankData: ["Platinum", "Gold", "Silver", "Bronze", "Copper"],
      duplicatedRankData: ["Platinum", "Gold", "Silver", "Bronze", "Copper"],
    }
  );
  const wrapperFilterRef = useRef(null);
  useOutsideAlerter(wrapperFilterRef, setIsFilter);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsClickMore);
  const { farmers, total, loading } = useSelector((state) => state.farmer);
  const farmersPagination = sessionStorage.getItem("farmer_page");
  const bvnNoAcctNoStorage = sessionStorage.getItem("bvnNoAcct");
  const acctNoNoBvnStorage = sessionStorage.getItem("acctNoBvn");

  const onPageChange = (val) => {
    const { page } = val;
    sessionStorage.setItem("farmer_page", page);

    dispatch(
      getAllFarmers({
        page: page,
        value: sessionStorage.getItem("farmer_search_value") || "",
        states: sessionStorage.getItem("filteredState"),
        lgas: sessionStorage.getItem("filteredLga"),
        rank: sessionStorage.getItem("filteredRank"),
        bvn: bvnNoAcctNoStorage || false,
        acct_no: acctNoNoBvnStorage || false,
      })
    );
  };

  const handleMoreVertClick = (index, e) => {
    e.stopPropagation();
    setIsClickMore(!isClickMore);
    setIsClickIndex(index);
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        sessionStorage.setItem("filteredState", "");
        sessionStorage.setItem("filteredLga", "");
        sessionStorage.setItem("filteredRank", "");
        setSearchValue(val);
        sessionStorage.setItem("farmer_search_value", val); // Store the search value in session storage
        sessionStorage.setItem("farmer_page", 1);
        dispatch(
          getAllFarmers({
            page: farmersPagination,
            value: val,
            states: "",
            lgas: "",
            rank: "",
            bvn: false,
            acct_no: false,
          })
        );
      }, 750),
    [dispatch, farmersPagination]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleNavigate = (e, id) => {
    navigate(`/farmers-profile/${id}`);
  };

  const handleOpenStateFilter = () => {
    updateClickState({ clickedFilter: "state" });
    dispatch(getAllState())
      .then((res) => {
        updateAllData({
          duplicatedStateData: res?.payload?.states,
          stateData: res?.payload?.states,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        }
      });
  };

  const handleSearchChange = useCallback(
    (e) => {
      let val = e.target.value;
      setStateSearchValue(val);
      let data = allData?.duplicatedStateData;

      let filteredData = data.filter((state) =>
        state?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      updateAllData({ stateData: filteredData });
    },
    [allData, setStateSearchValue]
  );

  const handleSelectedState = (data) => {
    if (selectedState.includes(data.id)) {
      let filteredData = selectedState.filter(
        (selectedData) => selectedData !== data?.id
      );
      setSelectedState(filteredData);
    } else {
      setSelectedState((prev) => [...prev, data?.id]);
    }
  };

  const handleSelectedLga = (data) => {
    if (selectedLga.includes(data.id)) {
      let filteredData = selectedLga.filter(
        (selectedData) => selectedData !== data?.id
      );
      setSelectedLga(filteredData);
    } else {
      setSelectedLga((prev) => [...prev, data?.id]);
    }
  };

  const handleSelectedRank = (data) => {
    setSelectedRank(data);
  };

  const handleLoadLgaData = () => {
    let lgaData = selectedState?.join(",");

    dispatch(getAllLGAByIds(lgaData))
      .then((res) => {
        console.log(res);
        updateAllData({
          duplicatedLgaData: res?.payload?.lgas,
          lgaDataByID: res?.payload?.lgas,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        }
      });
  };
  const handleSearchLgaChange = useCallback(
    (e) => {
      let val = e.target.value;
      setLgaSearchValue(val);
      let data = allData?.duplicatedLgaData;

      let filteredData = data.filter((lga) =>
        lga?.name?.toLowerCase().includes(val?.toLowerCase())
      );
      updateAllData({ lgaDataByID: filteredData });
    },
    [allData, setLgaSearchValue]
  );

  const handleSearchRankChange = useCallback(
    (e) => {
      let val = e.target.value;
      setRankSearchValue(val);
      let data = allData?.duplicatedRankData;

      let filteredData = data.filter((rank) =>
        rank?.toLowerCase().includes(val?.toLowerCase())
      );
      updateAllData({ rankData: filteredData });
    },
    [allData, setRankSearchValue]
  );

  const handleFilter = useCallback(() => {
    const page = 1;
    const state = selectedState.join(",");
    const lgas = selectedLga.join(",");

    dispatch(
      getAllFarmers({
        page: page,
        value: "", // Use the stored search value
        states: state,
        lgas: lgas,
        rank: selectedRank,
        bvn: bvnNoAcctNoStorage,
        acct_no: acctNoNoBvnStorage,
      })
    );

    sessionStorage.setItem("filteredState", state);
    sessionStorage.setItem("filteredLga", lgas);
    sessionStorage.setItem("filteredRank", selectedRank);
  }, [
    dispatch,
    selectedLga,
    selectedRank,
    selectedState,
    // bvnNoAcctNo,
    // acctNoNoBvn,
    bvnNoAcctNoStorage,
    acctNoNoBvnStorage,
  ]);

  useEffect(() => {
    const storedSearchValue = sessionStorage.getItem("farmer_search_value");
    const page = farmersPagination ? farmersPagination : 1;
    sessionStorage.setItem("filteredState", "");
    sessionStorage.setItem("filteredLga", "");
    sessionStorage.setItem("filteredRank", "");

    setSearchValue(storedSearchValue);

    if (
      selectedState.length > 0 ||
      selectedRank.length > 0 ||
      selectedLga.length > 0 ||
      bvnNoAcctNo ||
      acctNoNoBvn
    ) {
      if (bvnNoAcctNo && clickState?.clickedFilter === "acct_no") {
        setBvnNoAcctNo(false);
      } else if (acctNoNoBvn && clickState?.clickedFilter === "bvn") {
        setAcctNoNoBvn(false);
      } else {
      }
      sessionStorage.setItem("bvnNoAcct", bvnNoAcctNo);
      sessionStorage.setItem("acctNoBvn", acctNoNoBvn);

      handleFilter();
    } else {
      dispatch(
        getAllFarmers({
          page: page,
          value: storedSearchValue === null ? "" : storedSearchValue || "",
          states: "",
          lgas: "",
          rank: "",
          bvn: bvnNoAcctNoStorage || false,
          acct_no: acctNoNoBvnStorage || false,
        })
      );
    }
  }, [
    dispatch,
    farmersPagination,
    selectedState,
    selectedRank,
    selectedLga,
    handleFilter,
    bvnNoAcctNo,
    acctNoNoBvn,
    clickState,
    bvnNoAcctNoStorage,
    acctNoNoBvnStorage,
  ]);

  return (
    <div>
      <TableBackground>
        <div className="flex items-center justify-between gap-4 pt-4 pr-5">
          {/*  */}
          <p className="text-primary font-semibold text-base leading-5  px-4">
            Farmer Profiles ({Number(total)?.toLocaleString()})
          </p>

          <div className="flex gap-3 items-center">
            {bvnNoAcctNoStorage === "true" ? (
              <div
                onClick={() => {
                  setBvnNoAcctNo(false);
                  sessionStorage.setItem("bvnNoAcct", bvnNoAcctNo);
                  window.location.reload();
                }}
                className="rounded-lg border cursor-pointer flex items-center gap-1 border-primary text-primary font-normal text-xs px-2 py-1 "
              >
                <p>Bvn, No Account Number</p>
                <Close style={{ fontSize: "13px" }} />
              </div>
            ) : null}

            {acctNoNoBvnStorage === "true" ? (
              <div
                onClick={() => {
                  setAcctNoNoBvn(false);
                  sessionStorage.setItem("acctNoBvn", acctNoNoBvn);
                  window.location.reload();
                }}
                className="rounded-lg border cursor-pointer flex items-center gap-1 border-primary text-primary font-normal text-xs px-2 py-1 "
              >
                <p>Account Number, No BVN</p>
                <Close style={{ fontSize: "13px" }} />
              </div>
            ) : null}
          </div>
        </div>

        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4 mt-4 px-4">
          <div className="w-full relative">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
          <div className="relative">
            <div
              className="flex justify-center items-center gap-2 border border-primary rounded-[8px] min-h-[50px] py-3 px-6 text-primary text-sm font-medium leading-5 cursor-pointer"
              onClick={() => setIsFilter(!isFilter)}
            >
              <p className="">Filter</p>
              <FilterList style={{ fontSize: "18px" }} />
            </div>

            <div>
              {isFilter && (
                <div ref={wrapperFilterRef}>
                  <div className="">
                    <div className="bg-white rounded-[8px] p-2 shadow-md w-[211px] absolute right-[-15%] cursor-pointer z-20 mt-2">
                      <div
                        className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
                        onClick={() => {
                          handleOpenStateFilter();
                        }}
                      >
                        <p>State</p>
                        <KeyboardArrowRight style={{ fontSize: "20px" }} />
                      </div>
                      <div
                        className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
                        onClick={() => {
                          updateClickState({ clickedFilter: "lga" });
                          handleLoadLgaData();
                        }}
                      >
                        <p>Local Government</p>
                        <KeyboardArrowRight style={{ fontSize: "20px" }} />
                      </div>
                      <div
                        className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
                        onClick={() => {
                          updateClickState({ clickedFilter: "rank" });
                        }}
                      >
                        <p>Rank</p>
                        <KeyboardArrowRight style={{ fontSize: "20px" }} />
                      </div>

                      <div
                        className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
                        onClick={() => {
                          setBvnNoAcctNo(true);
                          updateClickState({ clickedFilter: "bvn" });
                          setIsFilter(false);
                        }}
                      >
                        <p>BVN, No Account Number</p>
                      </div>
                      <div
                        className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
                        onClick={() => {
                          setAcctNoNoBvn(true);
                          updateClickState({ clickedFilter: "acct_no" });
                          setIsFilter(false);
                        }}
                      >
                        <p>Account Number, No BVN</p>
                      </div>
                    </div>
                  </div>

                  <>
                    {clickState?.clickedFilter === "state" ? (
                      <div
                        className="bg-white rounded-[8px] p-2 shadow-md w-[236px] absolute left-[-290%] top-[200%] cursor-pointer z-20 mt-2"
                        ref={wrapperFilterRef}
                      >
                        <div className="text-sm font-medium leading-5 text-[#344335]  mb-2 max-h-[300px] overflow-y-auto call2orderOverflow">
                          <div className="">
                            <SearchComponent
                              placeholder="Search..."
                              searchValue={stateSearchValue}
                              handleChange={handleSearchChange}
                            />
                          </div>
                          <div className="mt-2 px-4 py-4 text-sm font-semibold leading-[18px] ">
                            <p>{selectedState?.length} Selected</p>
                          </div>

                          <>
                            {allData?.stateData?.map((data, index) => {
                              const isChecked = selectedState.includes(data.id);

                              return (
                                <div
                                  className="flex gap-4 items-center px-4 py-3"
                                  key={index}
                                  onClick={() => {
                                    handleSelectedState(data);
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="accent-primary h-[14px] w-[14px] rounded-[2px]"
                                    checked={isChecked}
                                  />
                                  <p className="font-medium text-sm leading-5 text-[#344335]">
                                    {data.display_name}
                                  </p>
                                </div>
                              );
                            })}
                          </>
                        </div>
                      </div>
                    ) : clickState?.clickedFilter === "lga" ? (
                      <div
                        className="bg-white rounded-[8px] p-2 shadow-md w-[236px] absolute left-[-290%] top-[260%] cursor-pointer z-20 mt-2"
                        ref={wrapperFilterRef}
                      >
                        <div className="text-sm font-medium leading-5 text-[#344335]  mb-2 max-h-[300px] overflow-y-auto call2orderOverflow">
                          <div className="">
                            <SearchComponent
                              placeholder="Search..."
                              searchValue={lgaSearchValue}
                              handleChange={handleSearchLgaChange}
                            />
                          </div>
                          <div className="mt-2 px-4 py-4 text-sm font-semibold leading-[18px] ">
                            <p>{selectedLga?.length} Selected</p>
                          </div>

                          <>
                            {allData?.lgaDataByID?.map((data, index) => {
                              const isChecked = selectedLga.includes(data.id);
                              return (
                                <div
                                  className="flex gap-4 items-center px-4 py-3"
                                  key={index}
                                  onClick={() => {
                                    handleSelectedLga(data);
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="accent-primary h-[14px] w-[14px] rounded-[2px]"
                                    checked={isChecked}
                                  />
                                  <p className="font-medium text-sm leading-5 text-[#344335]">
                                    {data.display_name}
                                  </p>
                                </div>
                              );
                            })}
                          </>
                        </div>
                      </div>
                    ) : clickState?.clickedFilter === "rank" ? (
                      <div
                        className="bg-white rounded-[8px] p-2 shadow-md w-[236px] absolute left-[-290%] top-[260%] cursor-pointer z-20 mt-2"
                        ref={wrapperFilterRef}
                      >
                        <div className="text-sm font-medium leading-5 text-[#344335]  mb-2 max-h-[300px] overflow-y-auto call2orderOverflow">
                          <div className="">
                            <SearchComponent
                              placeholder="Search..."
                              searchValue={rankSearchValue}
                              handleChange={handleSearchRankChange}
                            />
                          </div>
                          <div className="mt-2 px-4 py-4 text-sm font-semibold leading-[18px] ">
                            <p>{selectedRank ? 1 : 0} Selected</p>
                          </div>

                          <>
                            {allData?.rankData?.map((data, index) => {
                              return (
                                <div
                                  className="flex gap-4 items-center px-4 py-3"
                                  key={index}
                                >
                                  <input
                                    name={data}
                                    type="radio"
                                    className="accent-primary h-[14px] w-[14px] rounded-[2px]"
                                    value={data}
                                    onClick={(event) =>
                                      handleSelectedRank(event.target.value)
                                    }
                                    checked={selectedRank === data}
                                  />
                                  <p className="font-medium text-sm leading-5 text-[#344335]">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </>
                        </div>
                      </div>
                    ) : null}
                  </>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* TBALE SECTION */}
        <section className="mt-[16px] overflow-x-auto">
          {loading ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {farmers?.length === 0 ? (
                <EmptyState text="There's no data for this filter yet" />
              ) : (
                <table className="p-[16px]">
                  <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                    <tr>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        First Name
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Last Name
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Phone Number
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        State
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Local Government
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        {" "}
                        Registration Status
                      </th>
                      <th scope="col">Rank</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {farmers?.map((data, index) => {
                      return (
                        <tr
                          onClick={() =>
                            navigate(`/farmers-profile/${data.id}`)
                          }
                          className="relative bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                          key={index}
                        >
                          <td className="py-4 px-4 text-[#5C715E]">
                            {data.first_name || "N/A"}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {data.last_name || "N/A"}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {data.phone_number || "N/A"}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {data.state || "N/A"}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {data.lga || "N/A"}
                          </td>

                          <td className="py-4 px-4 text-[#5C715E]">
                            <div
                              className={
                                data.status === "Complete"
                                  ? "success-badge"
                                  : data.status === "Incomplete"
                                  ? "error-badge"
                                  : null
                              }
                            >
                              <p>{data.status}</p>
                            </div>
                          </td>
                          <td className="py-4 pr-4 text-[#5C715E]">
                            <div
                              className={
                                data.rank === "Copper"
                                  ? "copper-badge"
                                  : data.rank === "Platinum"
                                  ? "platinum-badge"
                                  : data.rank === "Silver"
                                  ? "silver-badge"
                                  : data.rank === "Bronze"
                                  ? "bronze-badge"
                                  : data.rank === "Gold"
                                  ? "gold-badge"
                                  : null
                              }
                            >
                              <p>
                                {data.rank === "Copper"
                                  ? "Copper"
                                  : data?.rank || "N/A"}
                              </p>
                            </div>
                          </td>
                          <td className="py-4 pr-4 text-[#5C715E]">
                            {" "}
                            <div
                              className={
                                data?.active_status === "Active"
                                  ? "success-badge"
                                  : data?.active_status === "Blocked"
                                  ? "na-badge"
                                  : data?.active_status === "Suspended"
                                  ? "error-badge"
                                  : data?.active_status === "Complete"
                                  ? "success-badge"
                                  : data?.active_status === "Inactive"
                                  ? "pending-badge"
                                  : null
                              }
                            >
                              <p>{data.active_status}</p>
                            </div>
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            <div className="relative">
                              <MoreVert
                                style={{ fontSize: "18px", cursor: "pointer" }}
                                onClick={(event) =>
                                  handleMoreVertClick(index, event)
                                }
                              />
                            </div>
                          </td>
                          <div className="">
                            {isClickMore && clickIndex === index && (
                              <div
                                className="absolute right-0 w-[187px] shadow-sm bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20"
                                ref={wrapperRef}
                              >
                                <p
                                  className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                  onClick={(event) =>
                                    handleNavigate(event, data?.id)
                                  }
                                >
                                  View Profile
                                </p>
                                <div>
                                  {data.active_status === "Blocked" ? (
                                    <p className="text-[#96A397] cursor-default  py-[12px] px-[16px] text-sm leading-5 font-medium">
                                      Suspend Farmer
                                    </p>
                                  ) : (
                                    <>
                                      {data.active_status === "Suspended" ? (
                                        <UnsuspendFarmer id={data.id} />
                                      ) : (
                                        <SuspendFarmer id={data.id} />
                                      )}
                                    </>
                                  )}
                                </div>
                                <div>
                                  {data.active_status === "Suspended" ? (
                                    <p className="text-[#96A397] cursor-default py-[12px] px-[16px] text-sm leading-5 font-medium">
                                      Block Farmer
                                    </p>
                                  ) : (
                                    <>
                                      {data.active_status === "Blocked" ? (
                                        <UnblockFarmer id={data.id} />
                                      ) : (
                                        <BlockFarmer id={data.id} />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </section>
        {!loading && farmers?.length > 0 ? (
          <div className="">
            <PaginationComponent
              page={farmersPagination ? Number(farmersPagination) : 1}
              onPageChange={(page) => onPageChange({ page })}
              pageSize={10}
              totalItems={total}
            />
          </div>
        ) : (
          ""
        )}
      </TableBackground>
    </div>
  );
};

export default FarmersTable;
