import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import SearchComponent from "../../components/Search";
import moment from "moment";
import EmptyState from "../../components/EmptyState";
import { getCampaignLog } from "../../redux/Campaign/campaignAction";
import { useNavigate } from "react-router-dom";

const CampaignLogTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = [
    { id: "ALL", title: "All" },
    { id: "active", title: "Active" },
    { id: "inactive", title: "Inactive" },
    { id: "ended", title: "Ended" },
    { id: "archived", title: "Archived" },
  ];
  const [clickedTab, setClickedTab] = useState("ALL");

  const { campaignLogData, campaignLogLoading } = useSelector(
    (state) => state.campaign
  );

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        // dispatch(
        //   getRetailSearch({
        //     page: currentPage,
        //     value: val,
        //   })
        // );
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleTogglePage = (item) => {
    setClickedTab(item);

    dispatch(getCampaignLog({ type: item }));
  };

  useEffect(() => {
    dispatch(getCampaignLog({ type: "ALL" }));
  }, [dispatch]);

  return (
    <div>
      <TableBackground>
        <p className="text-primary font-semibold text-base leading-5 pt-4 px-4">
          Campaign History
        </p>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS */}
        <ul className="flex mt-5 gap-5 items-center ml-4">
          {status.map((item, index) => (
            <li
              key={index}
              onClick={() => handleTogglePage(item.id)}
              className={
                clickedTab === item.id
                  ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                  : "flex gap-2 items-center pb-1 cursor-pointer"
              }
            >
              <p
                className={
                  clickedTab === item.id
                    ? "text-primary text-sm font-semibold"
                    : "text-[#96A397] text-sm font-semibold"
                }
              >
                {item.title}
              </p>
              {/* <span
                className={
                  clickedTab === item.id
                    ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-1 text-primary font-semibold text-[10px]"
                    : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-1 text-[#96A397] font-semibold text-[10px]"
                }
              >
                {item.id === clickedTab ? callsTotal : "0"}
              </span> */}
            </li>
          ))}
        </ul>

        <section className="mt-[30px] pb-[6px]">
          {campaignLogLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {campaignLogData.length === 0 ? (
                <div>
                  <EmptyState text="There's no data for this filter yet" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        Name of campaign
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Number of target in campaign
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Date of creation
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Campaign status
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Success percentage
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {campaignLogData?.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                          onClick={() =>
                            navigate(`/campaign-summary/${data.id}`)
                          }
                        >
                          <td className="px-4">{data.name || "N/A"}</td>
                          <td className="px-4">{data.farmers_count || "0"}</td>
                          <td className="px-4">
                            {data.created
                              ? moment(data.created).format("ll")
                              : "N/A"}
                          </td>
                          <td className="px-4">{data.status || "N/A"}</td>
                          <td className="px-4">{data.progress || "0"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default CampaignLogTable;
