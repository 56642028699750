import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from 'cogo-toast'

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
}



export const fetchTypeCategories = createAsyncThunk(
  'fetchTypeCategories',
  async ({ val }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}${val}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const atlasSearch = createAsyncThunk(
  'atlasSearch',
  async ({ val }, { rejectWithValue, dispatch }) => {
    try {

      const res = await axios.get(
        `${apiUrl}sales/atlas-search?product=${val}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response.data)
      }
    }
  }
)

export const fetchInfoCard = createAsyncThunk(
  'fetchInfoCard',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}info-card/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const CreateInfoCard = createAsyncThunk(
  'CreateInfoCard',
  async ({ name, description, keyword, category }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}info-card`, {
        product_name: name,
        product_information: description,
        search_keywords: keyword,
        product_type: category
      },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 201) {
        cogoToast.success("Info Card successfully added!")
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const UpdateInfoCard = createAsyncThunk(
  'UpdateInfoCard',
  async ({ name, description, keyword, category, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(`${apiUrl}info-card/${id}`, {
        product_name: name,
        product_information: description,
        search_keywords: keyword,
        product_type: category
      },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        cogoToast.success("Info Card successfully updated!")
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

