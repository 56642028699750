import React, { useMemo, useState, useCallback, useEffect } from "react";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../../components/Pagination/Pagination";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { CardWithBorder } from "../../../components/Cards";
import { fetchLoanOfficerGroups } from "../../../redux/Loans/loanActions";
import EmptyState from "../../../components/EmptyState";
import {useParams} from 'react-router-dom'


const LoanAgentGroupsPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const { loader, loanofficerGroups,loanofficerGroupsCount } = useSelector((state) => state.loan);
  const dispatch = useDispatch()
  const {id} = useParams()
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
  };

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        // dispatch(
        //     searchCallRequests({
        //       val: val,
        //       status: 'all',
        //     }),
        //   )
        console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(()=>{
    dispatch(fetchLoanOfficerGroups({
      id
    }))
  },[dispatch, id])

  return (
    <>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <CardWithBorder
          title="Total Arrears"
          subtitle={`₦0`}
          isLink={false}
        />
        <CardWithBorder
          title="Cnt Disb In The Month"
          subtitle={`20 `}
          color="border-l-[#FFC508]"
          isLink={false}
        />
        <CardWithBorder
          title="Amt Disb In The Month"
          subtitle={`₦4 `}
          isLink={false}
        />
        <CardWithBorder
          title="Overdue Loan"
          subtitle={`₦40`}
          color="border-l-[#B92043]"
          isLink={false}
        />
      </div>

      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* tables */}  
        <div className="overflow-x-auto relative">
          {loader ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {loanofficerGroups?.length === 0 ? (
                <EmptyState text="You don't have any group(s) available yet" />
              ) : (
                <table className="w-full text-left">
                  <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                      <tr>
                      <th scope="col" className="px-4 py-4 font-semibold ">
                        Loan Officer
                      </th>

                      <th scope="col" className="px-4 py-4 font-semibold">
                        Group Name
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        State
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        LGA
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Members
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                {loanofficerGroups.length > 0
                  ? loanofficerGroups.map((item) => (
                      <tr
                        key={item.id}
                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      >
                        <th
                          scope="row"
                          className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                        >
                          {item.loan_officer.officer_name === ""
                            ? "N/A"
                            : item.loan_officer.officer_name}
                        </th>

                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.name}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.state.name}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.lga.name}
                        </td>
                        <td className="py-4 px-4">{item.members}</td>
                      </tr>
                    ))
                  : ""}
              </tbody>
                </table>
              )}
            </>

          )}
        </div>

      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={loanofficerGroupsCount}
        />
      </div>
    </>
  );
};

export default LoanAgentGroupsPage;
