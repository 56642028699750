import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl, finagApiUrl } from "../config";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

////////////////////////////////////// GET ALL FARMERS SECTION
export const getAllFarmers = createAsyncThunk(
  "getAllFarmers",
  async (
    { page, value, states, lgas, rank, bvn, acct_no },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmers?search=${value}&page=${page}&states=${states}&lgas=${lgas}&rank=${rank}&has_bvn_no_account_number=${bvn}&has_account_number_no_bvn=${acct_no}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAllFarmersForLoan = createAsyncThunk(
  "getAllFarmersForLoan",
  async (
    { page, value, states, lgas, rank, cooperative },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmers?search=${value}&page=${page}&states=${states}&lgas=${lgas}&rank=${rank}&has_cooperative=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAllFarmersSearch = createAsyncThunk(
  "getAllFarmersSearch",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}farmers?search=${value}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////////// COMPLETE STATS SECTION
export const getFarmersStats = createAsyncThunk(
  "getFarmersStats",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}/farmers/statistics`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////// COMPLETE FARMERS SECTION

export const getCompleteFarmers = createAsyncThunk(
  "getCompleteFarmersStats",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}complete_farmers?search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getCompleteFarmersSearch = createAsyncThunk(
  "getCompleteFarmersSearch",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}complete_farmers?search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

////////////////////////////////////////////// INCOMPLETE FARMERS SECTION

export const getIncompleteFarmers = createAsyncThunk(
  "getIncompleteFarmers",
  async ({ page, value, status }, { rejectWithValue, dispatch }) => {
    if (status === "all") {
      try {
        const res = await axios.get(
          `${apiUrl}incomplete_farmers?search=${value}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}incomplete_farmers?search=${value}&call_status=${status}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const getIncompleteFarmersSearch = createAsyncThunk(
  "getIncompleteFarmersSearch",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}incomplete_farmers?search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

////////////////////////////////// TEMPORARY FARMERS SECTION

export const getAllTemporaryFarmers = createAsyncThunk(
  "getAllTemporaryFarmers",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}temporary_farmers?search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getToCallTemporaryFarmers = createAsyncThunk(
  "getToCallTemporaryFarmers",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}temporary_farmers?status=TO_CALL&search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getToCallTemporaryFarmersSearch = createAsyncThunk(
  "getToCallTemporaryFarmersSearch",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}temporary_farmers?status=TO_CALL&search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getCalledTemporaryFarmers = createAsyncThunk(
  "getCalledTemporaryFarmers",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}temporary_farmers?status=CALLED&search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getCalledTemporaryFarmersSearch = createAsyncThunk(
  "getCalledTemporaryFarmersSearch",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}temporary_farmers?status=CALLED&search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getMissedTemporaryFarmers = createAsyncThunk(
  "getMissedTemporaryFarmers",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}temporary_farmers?status=MISSED&search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getMissedTemporaryFarmersSearch = createAsyncThunk(
  "getMissedTemporaryFarmersSearch",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}temporary_farmers?status=MISSED&search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////////// FLAGGED FARMERS SECTION
export const getAllFlaggedFarmers = createAsyncThunk(
  "getAllFlaggedFarmers",
  async ({ page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}/flagged_accounts?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAllFlaggedFarmersSearch = createAsyncThunk(
  "getAllFlaggedFarmersSearch",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}flagged_accounts?search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getShaddowBannedFarmers = createAsyncThunk(
  "getShaddowBannedFarmers",
  async ({ page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}flagged_accounts?type=shadow_banned&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getShaddowBannedFarmersSearch = createAsyncThunk(
  "getShaddowBannedFarmersSearch",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}flagged_accounts?type=shadow_banned&search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getRestrictedFarmers = createAsyncThunk(
  "getRestrictedFarmers",
  async ({ page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}flagged_accounts?type=restricted&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getRestrictedFarmersSearch = createAsyncThunk(
  "getRestrictedFarmersSearch",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}flagged_accounts?type=restricted&search=${value}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

///////////////////////////////// BLOCK FARMER
export const blockFarmer = createAsyncThunk(
  "blockFarmer",
  async ({ inputValue, id, status }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}farmers/${id}`,
        {
          active_status: status,
          // reason: inputValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(`Status updated successfully!`);
        return res;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const resetBVN = createAsyncThunk(
  "resetBVN",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}farmers/${id}/reset-bvn`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        cogoToast.success(`BVN reset was made successfully!`);
        return res;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////FARMERS PROFILE
export const getFarmersProfile = createAsyncThunk(
  "getFarmersProfile",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}farmers/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////FARMERS ACTIVITY LOG
export const getFarmersActivityLog = createAsyncThunk(
  "getFarmersActivityLog",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}/farmers/${id}/activity-logs`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
/////////////////////////FARMERS PROFILE
export const generateAcctNo = createAsyncThunk(
  "generateAcctNo",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}wallet/${id}/create/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////////// FARMERS TRANSACTION SECTION
export const getAllBankTransactions = createAsyncThunk(
  "getAllBankTransactions",
  async ({ page, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}wallet-transactions?farmer_id=${id}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAllBankTransactionsSearch = createAsyncThunk(
  "getAllBankTransactionsSearch",
  async ({ id, page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}wallet-transactions?farmer_id=${id}&page=${page}&search=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getDebitTransactions = createAsyncThunk(
  "getDebitTransactions",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}wallet-transactions?farmer_id=${id}&page=${page}&type=debit`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getDebitTransactionsSearch = createAsyncThunk(
  "getDebitTransactionsSearch",
  async ({ id, page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}wallet-transactions?farmer_id=${id}&page=${page}&search=${value}&type=debit`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getCreditTransactions = createAsyncThunk(
  "getCreditTransactions",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}wallet-transactions?farmer_id=${id}&page=${page}&type=credit`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getCreditTransactionsSearch = createAsyncThunk(
  "getCreditTransactionsSearch",
  async ({ page, value, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}wallet-transactions?farmer_id=${id}&page=${page}&search=${value}&type=credit`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
//////////////////////////////ORDERS
export const getAllFarmersOrder = createAsyncThunk(
  "getAllFarmersOrder",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmers/${id}/orders?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
export const getCropFarmersOrder = createAsyncThunk(
  "getCropFarmersOrder",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmers/${id}/orders?type=input&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
export const getLivestockFarmersOrder = createAsyncThunk(
  "getLivestockFarmersOrder",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmers/${id}/orders?type=livestock&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
export const getMechanizationFarmersOrder = createAsyncThunk(
  "getMechanizationFarmersOrder",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmers/${id}/orders?type=mechanization&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAdvisoryFarmersOrder = createAsyncThunk(
  "getAdvisoryFarmersOrder",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmers/${id}/orders?type=advisory&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getArtimeFarmersOrder = createAsyncThunk(
  "getArtimeFarmersOrder",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmers/${id}/orders?type=airtime&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getInsuranceFarmersOrder = createAsyncThunk(
  "getInsuranceFarmersOrder",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmers/${id}/orders?type=insurance&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////USSD SESSIONS
export const getAllFarmersUssdSessions = createAsyncThunk(
  "getAllFarmersUssdSessions",
  async ({ number, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmer/session?phone_number=${number}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getCompleteFarmersUssdSessions = createAsyncThunk(
  "getCompleteFarmersUssdSessions",
  async ({ number, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmer/session/?phone_number=${number}&page=${page}&status=complete`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getIncompleteFarmersUssdSessions = createAsyncThunk(
  "getIncompleteFarmersUssdSessions",
  async ({ number, page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}farmer/session/?phone_number=${number}&page=${page}&status=incomplete`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

///////////////////////////////Daily Limit
export const EditFarmersDailyLimit = createAsyncThunk(
  "EditFarmersDailyLimit",
  async ({ value, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}farmers/${id}`,
        {
          wallet_daily_limit: value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return cogoToast.success(
          "Farmer's wallet limit was updated successfully"
        );
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

///////////////////////////////Generate Pin
export const tempFarnersGeneratePin = createAsyncThunk(
  "tempFarnersGeneratePin",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}temp-farmer/reset_pin/`,
        { phone_number: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        return cogoToast.success("Farmer's pin was generated successfully");
      }
    } catch (error) {
      console.log(error.response.data);
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      }
      if (error.response.status === 503) {
        cogoToast.error("Something went wrong!");
        return rejectWithValue(error.response.data);
      } else if (error.response && error.response.data) {
        if (error.response.data.errors) {
          cogoToast.error(error?.response?.data?.errors);
        } else {
          cogoToast.error(error?.response?.data?.detail);
        }

        return rejectWithValue(error.response.data);
      } else {
        cogoToast.error("Something went wrong");
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const farnersGeneratePin = createAsyncThunk(
  "farnersGeneratePin",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}farmer/reset_pin/`,
        { phone_number: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        return cogoToast.success("Farmer's pin was generated successfully");
      }
    } catch (error) {
      console.log(error.response.data);
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      }
      if (error.response.status === 503) {
        cogoToast.error("Something went wrong!");
        return rejectWithValue(error.response.data);
      } else if (error.response && error.response.data) {
        if (error.response.data.errors) {
          cogoToast.error(error?.response?.data?.errors);
        } else {
          cogoToast.error(error?.response?.data?.detail);
        }

        return rejectWithValue(error.response.data);
      } else {
        cogoToast.error("Something went wrong");
        return rejectWithValue(error.response.data);
      }
    }
  }
);

///////////////////////////////Generate Statements
export const farnersGenerateStatement = createAsyncThunk(
  "farnersGenerateStatement",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}farmer/statement/${id}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return cogoToast.success(res?.data?.message);
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

////////////////////CALL HISTORY
export const getFarmersCallHistory = createAsyncThunk(
  "getFarmersCallHistory",
  async ({ type, id, page }, { rejectWithValue, dispatch }) => {
    if (type === "all") {
      try {
        const res = await axios.get(
          `${apiUrl}farmer/call_requests/${id}?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}farmer/call_requests/${id}?status=${type.toUpperCase()}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

////////////////////REFUNDS
export const getFarmersRefund = createAsyncThunk(
  "getFarmersRefund",
  async ({ type, id, page }, { rejectWithValue, dispatch }) => {
    if (type === "all") {
      try {
        const res = await axios.get(
          `${apiUrl}farmers/${id}/refunds?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}farmers/${id}/refunds?status=${type}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

////////////////////TEMPORARY FARMER'S INFORMATION
export const getTemporaryFarmersInfo = createAsyncThunk(
  "getTemporrayfarmersInfo",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}temporary_farmers/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const updateTemporaryFarmersInfo = createAsyncThunk(
  "updateTemporaryFarmersInfo",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}temporary_farmers/${id}`,
        {
          customer_service_conversation: values.customer_service_conversation,
          customer_service_status: values.customer_service_status,
          day: values.day,
          first_name: values.first_name,
          gender: values.gender,
          id: values.id,
          last_name: values.last_name,
          month: values.month,
          year: values.year,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Updated successfully");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////GET FARMER"S CALL TO ORDER
export const getFarmersOrders = createAsyncThunk(
  "getFarmersOrders",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}farmers/${id}/cart`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

///////////////////////////////// EDIT FARMER'S PROFILE
export const editFarmerProfile = createAsyncThunk(
  "editFarmerProfile",
  async ({ values, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(`${apiUrl}farmers/${id}`, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        cogoToast.success("Farmer's details have been updated successfully!");

        return res;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

///////////////////////////////// EDIT INCOMPLETE FARMER'S CALL STATUS
export const editIncompleteFarmerCallStatus = createAsyncThunk(
  "editIncompleteFarmerCallStatus",
  async ({ values, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${apiUrl}call_log?farmer_id=${id}`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Farmer's details have been updated successfully!");
        return res;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////FARMERS RECORDS
export const getFarmerRecords = createAsyncThunk(
  "getFarmerRecords",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}farmers/${id}/reports`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const cloudinaryUpload = createAsyncThunk(
  "cloudinaryUpload",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}farmers-upload`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const uploadKYCDocuments = createAsyncThunk(
  "uploadKYCDocuments",
  async ({ values, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}farmers/${id}/reports`, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        cogoToast.success("KYC Document uploaded successfully");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

////////////////////////////////////// GET ALL FARMERS SECTION
export const debitFarmers = createAsyncThunk(
  "debitFarmers",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${finagApiUrl}wallet/debit/`, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        if (res.data.message === "Farmer has been successfully debited!") {
          cogoToast.success(res.data.message);
        } else if (res.data.message === "Farmer debit unsuccessful!") {
          cogoToast.error(res.data.message);
        }

        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        if (error.response.data.errors) {
          cogoToast.error(error.response.data.errors);
        } else {
          cogoToast.error("An error occurred!");
        }

        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const bulkDebitFarmers = createAsyncThunk(
  "bulkDebitFarmers",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}wallet/debit-wallets/`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Farmers were debited successfully");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        if (error.response.data.errors) {
          cogoToast.error(error.response.data.errors);
        } else {
          cogoToast.error("An error occurred!");
        }

        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

////////////////////////////////////// GET ALL FARMERS SECTION
export const getDebitedFarmers = createAsyncThunk(
  "getDebitedFarmers",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}wallet/debit/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        cogoToast.success("Farmer was debited successfully!");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        if (error.response.data.errors) {
          cogoToast.error(error.response.data.errors);
        } else {
          cogoToast.error("An error occurred!");
        }

        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
