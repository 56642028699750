import React, { useState, useEffect, useCallback } from "react";
import ModalComponent from "../../components/Modals/modal";
import { ExpandMore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getAllLGA, getAllState } from "../../redux/Location/locationAction";
import {
  getCallToOrderInfo,
  updateACallToOrder,
} from "../../redux/CallToOrder/callToOrderAction";
import cogoToast from "cogo-toast";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditCallToOrder = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [callDate, setCallDate] = useState(new Date());
  const [orderType, setOrderType] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [callToOrderInfoData, setCallToOrderInfoData] = useState({});

  // STATUS
  const status = [
    { key: "CALLED", label: "Called" },
    { key: "MISSED", label: "Missed" },
    { key: "TO_CALL", label: "Not Called" },
  ];
  const [isClickStatus, setIsClickStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});

  ////////////////////////LOCATION STATES
  const [isClickState, setIsClickState] = useState(false);
  const [isClickLGA, setIsClickLGA] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [selectedLGA, setSelectedLGA] = useState({});

  //   REDUX STATES
  const { stateData, lgaData } = useSelector((state) => state.location);

  const handleStateClick = () => {
    setIsClickState(!isClickState);
    dispatch(getAllState());
  };

  const handleSelectedState = (data) => {
    setSelectedState({
      key: data.id,
      name: data.display_name,
    });
    setIsClickState(false);

    dispatch(
      getAllLGA({
        id: data.id,
      })
    );
  };

  const handleLgaClick = () => {
    if (Object.keys(selectedState).length !== 0) {
      setIsClickLGA(!isClickLGA);
    } else {
      cogoToast.error("Kindly select a state first");
    }
  };

  const handleDeliveryAddressChange = (event) => {
    setDeliveryAddress(event.target.value);
  };

  const handleSave = () => {
    const value = {
      call_date: callDate ? callDate : callToOrderInfoData?.call_date,
      call_status: selectedStatus.key
        ? selectedStatus.key
        : callToOrderInfoData?.call_status,
      is_group_order: orderType
        ? orderType === "Personal"
          ? false
          : true
        : callToOrderInfoData?.is_group_order,
      delivery_address: deliveryAddress,
    };
    dispatch(updateACallToOrder({ value, id: id }));

    setTimeout(() => {
      setIsOpenModal(false);
    }, 800);
  };

  const handleCallToOrderInfo = useCallback(async () => {
    const data = await getCallToOrderInfo({ id: id });
    dispatch(data)
      .then((res) => {
        setCallToOrderInfoData(res.payload.data);
        setDeliveryAddress(res.payload.data.delivery_address);
      })
      .catch((error) => {

        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        }
      });
  }, [dispatch, id]);

  useEffect(() => {
    handleCallToOrderInfo();
  }, [handleCallToOrderInfo]);

  return (
    <div>
      <button
        className="outline-none border border-[#2B8C34] rounded-[8px] w-[91px] h-[40px] text-sm font-medium text-primary leading-5"
        onClick={() => setIsOpenModal(true)}
      >
        Edit Info
      </button>

      {/* CREATE ORDER MODAL */}

      <ModalComponent
        title="Edit Call to Order Info"
        show={isOpenModal}
        showModal={() => setIsOpenModal(false)}
      >
        <div className="mt-[20px] w-full">
          {/* CALL STATUS */}
          <div className="pb-5 w-full">
            <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
              Call Status
            </p>
            <div className="relative w-1/2">
              <div
                className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                onClick={() => setIsClickStatus(!isClickStatus)}
              >
                <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                  {(selectedStatus?.key
                    ? selectedStatus.label
                    : callToOrderInfoData?.call_status === "CALLED"
                      ? "Called"
                      : callToOrderInfoData?.call_status === "MISSED"
                        ? "Missed"
                        : callToOrderInfoData?.call_status === "TO_CALL"
                          ? "Not Called"
                          : null) || "Select a status"}
                </p>
                <ExpandMore
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              </div>
              {isClickStatus && (
                <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                  {status?.map((data, index) => {
                    return (
                      <p
                        className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                        key={index}
                        onClick={() => {
                          setSelectedStatus({
                            key: data.key,
                            label: data.label,
                          });
                          setIsClickStatus(false);
                        }}
                      >
                        {data.label}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {/* CALL DATE AND TIME */}
          <div className="flex gap-[24px]">
            <div className="pb-5 w-full">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                Call Date and Time
              </p>
              <DatePicker
                selected={callDate}
                onChange={(date) => setCallDate(date)}
                showTimeSelect
                closable
                // timeFormat="p"
                timeIntervals={1}
                dateFormat="Pp"
                className="w-full outline-none py-[12px] px-[16px] border border-[#96A397] rounded-[8px] text-medium text-sm leading-5 text-[#96A397] focus:border-[#2B8C34] focus:outline-none  
                focus:shadow-input"
              />
            </div>
          </div>

          {/* ORDER TYPE */}
          <div className="flex gap-[24px]">
            <div className="pb-[23px] w-full">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                Order Type
              </p>
              <div className="flex gap-[28px]">
                <div className="flex items-center">
                  <input
                    onChange={() => setOrderType("Personal")}
                    type="radio"
                    id="Order Type"
                    name="Order Type"
                    status="30"
                    className="w-[13px] h-[13px]"
                  />
                  <label
                    for="Order Type"
                    className="pl-[8px]  pt-[2px]  font-[500] text-sm leading-[18px] text-[#5C715E]"
                  >
                    Personal
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    onChange={(event) => setOrderType("Group")}
                    type="radio"
                    id="Order Type"
                    name="Order Type"
                    status="30"
                    className="w-[13px] h-[13px]"
                  />
                  <label
                    for="Order Type"
                    className="pl-[8px] pt-[2px]  font-[500] text-sm leading-[18px] text-[#5C715E]"
                  >
                    Group
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* STATE AND LGA */}
          <div className="flex gap-[24px]" style={{ display: "none" }}>
            <div className="pb-4 w-1/2">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                State
              </p>
              <div className="relative w-full">
                <div
                  className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                  onClick={handleStateClick}
                >
                  <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                    {selectedState.name || "Select a State"}
                  </p>
                  <ExpandMore
                    className="text-[#ABB6AC] cursor-pointer"
                    style={{ fontSize: "18px" }}
                  />
                </div>
                {isClickState && (
                  <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                    {stateData?.map((data, index) => {
                      return (
                        <p
                          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                          key={index}
                          onClick={() => handleSelectedState(data)}
                        >
                          {data.display_name}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="pb-4 w-1/2">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                LGA
              </p>
              <div className="relative w-full">
                <div
                  className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                  onClick={handleLgaClick}
                >
                  <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                    {selectedLGA.name || "Select Lga"}
                  </p>
                  <ExpandMore
                    className="text-[#ABB6AC] cursor-pointer"
                    style={{ fontSize: "18px" }}
                  />
                </div>
                {isClickLGA && (
                  <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                    {lgaData?.map((data, index) => {
                      return (
                        <p
                          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                          key={index}
                          onClick={() => {
                            setSelectedLGA({
                              key: data.id,
                              name: data.display_name,
                            });
                            setIsClickLGA(false);
                          }}
                        >
                          {data.display_name}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* DELIVERY STATUS */}
          <div className="flex gap-[24px]">
            <div className="pb-5 w-full">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                Delivery Address
              </p>

              <textarea
                id="address"
                rows="6"
                className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#5C715E] text-sm resize-none focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                placeholder="Delivery Address"
                onChange={handleDeliveryAddressChange}
                value={deliveryAddress || ""}
              ></textarea>
            </div>
          </div>

          {/* SAVE BUTTON */}
          <div>
            <button
              onClick={handleSave}
              disabled={false}
              type="button"
              className="w-full text-white bg-primary py-3 flex justify-center items-center
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] font-medium
                         rounded-lg text-"
            >
              Save
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default EditCallToOrder;
