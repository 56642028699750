import React from 'react'
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js'
import {useSelector} from 'react-redux'

Chart.register(
    ...registerables,
);



const BarChart = () => {

  const { completeChart, incompleteChart, temporaryChart } = useSelector((state) => state.overview)

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Farmers',
      },
      legend: {
        font: 14
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  
const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct', 'Nov', 'Dec'];
  
const data = {
    labels,
    datasets: [
      {
        label: 'Incomplete Farmers',
        data: incompleteChart.map((val)=>(val)),
        backgroundColor: '#2B8C34',
        borderRadius: 8,
        color: '#7C8D7E'
      },
      {
        label: 'Completed Farmers',
        data: completeChart.map((val)=>(val)),
        backgroundColor: '#1D5D23',
        borderRadius: 8,
        color: '#7C8D7E'
      },
      {
        label: 'Temporary Farmers',
        data: temporaryChart.map((val)=>(val)),
        backgroundColor: '#FFC508',
        borderRadius: 8,
        color: '#7C8D7E'
      },
    ],
};

    return ( 
        <>
         <Bar options={options} data={data} />
        </>
     );
}
 
export default BarChart;
  