import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../../components/TableBackground";
import badge_failed from "../../../assets/icons/Badge_failed.svg";
import badge_success from "../../../assets/icons/Badge_success.svg";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTemporaryFarmers,
  getCalledTemporaryFarmers,
  getCalledTemporaryFarmersSearch,
  getMissedTemporaryFarmers,
  getMissedTemporaryFarmersSearch,
  getToCallTemporaryFarmers,
  getToCallTemporaryFarmersSearch,
} from "../../../redux/Farmers/farmersActions";
import moment from "moment";
import PaginationComponent from "../../../components/Pagination/Pagination";
import SearchComponent from "../../../components/Search";
import EmptyState from "../../../components/EmptyState";
import { ContentCopy } from "@mui/icons-material";
import cogoToast from "cogo-toast";

const TemporaryFarmersTable = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const temporaryfarmersPagination = sessionStorage.getItem(
    "temporary_farmer_page"
  );

  const allTemporaryfarmersPagination = sessionStorage.getItem(
    "all_temporary_farmer_page"
  );
  const missedtemporaryfarmersPagination = sessionStorage.getItem(
    "missed_temporary_farmer_page"
  );
  const calledtemporaryfarmersPagination = sessionStorage.getItem(
    "called_temporary_farmer_page"
  );

  const {
    toCallTemporaryFarmerData,
    toCallTemporaryFarmerTotal,
    toCallTemporaryFarmerLoading,
    calledTemporaryFarmerData,
    calledTemporaryFarmerTotal,
    calledTemporaryFarmerLoading,
    missedTemporaryFarmerData,
    missedTemporaryFarmerTotal,
    missedTemporaryFarmerLoading,
    allTemporaryFarmerTotal,
    allTemporaryFarmerLoading,
    allTemporaryFarmerData,
  } = useSelector((state) => state.farmer);

  // TO CALL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    sessionStorage.setItem("temporary_farmer_page", page);

    dispatch(
      getToCallTemporaryFarmers({
        page: page,
        value: sessionStorage.getItem("temporary_farmer_search_value") || "",
      })
    );
  };

  const handleCopyNumber = (number, event) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(number)
      .then(() => {
        cogoToast.success(`${number} copied successfully`);
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        cogoToast.error("Failed to copy number");
      });
  };

  // CALLED PAGE CHANGE
  const onCalledPageChange = (val) => {
    const { page } = val;
    sessionStorage.setItem("called_temporary_farmer_page", page);

    dispatch(
      getCalledTemporaryFarmers({
        page: page,
        value:
          sessionStorage.getItem("called_temporary_farmer_search_value") || "",
      })
    );
  };

  // ALL

  const onAllPageChange = (val) => {
    const { page } = val;
    sessionStorage.setItem("all_temporary_farmer_page", page);

    dispatch(
      getAllTemporaryFarmers({
        page: page,
        value:
          sessionStorage.getItem("all_temporary_farmer_search_value") || "",
      })
    );
  };

  //MISSED PAGE CHANGE
  const onMissedPageChange = (val) => {
    const { page } = val;
    sessionStorage.setItem("missed_temporary_farmer_page", page);

    dispatch(
      getMissedTemporaryFarmers({
        page: page,
        value:
          sessionStorage.getItem("missed_temporary_farmer_search_value") || "",
      })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        sessionStorage.setItem("temporary_farmer_search_value", val);
        //  api to dispatch and make the actions for search
        dispatch(
          getAllTemporaryFarmers({
            value: val,
            page: allTemporaryfarmersPagination
              ? allTemporaryfarmersPagination
              : 1,
          })
        );

        dispatch(
          getToCallTemporaryFarmersSearch({
            value: val,
            page: temporaryfarmersPagination ? temporaryfarmersPagination : 1,
          })
        );

        dispatch(
          getCalledTemporaryFarmersSearch({
            value: val,
            page: calledtemporaryfarmersPagination
              ? calledtemporaryfarmersPagination
              : 1,
          })
        );

        dispatch(
          getMissedTemporaryFarmersSearch({
            value: val,
            page: missedtemporaryfarmersPagination
              ? missedtemporaryfarmersPagination
              : 1,
          })
        );
      }, 750),
    [
      dispatch,
      missedtemporaryfarmersPagination,
      calledtemporaryfarmersPagination,
      temporaryfarmersPagination,
      allTemporaryfarmersPagination,
    ]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    const storedSearchValue = sessionStorage.getItem(
      "all_temporary_farmer_search_value"
    );
    const page = allTemporaryfarmersPagination
      ? allTemporaryfarmersPagination
      : 1;

    setSearchValue(storedSearchValue);
    if (status === "to_call") {
      dispatch(
        getToCallTemporaryFarmers({
          page: page,
          value: storedSearchValue || "",
        })
      );
    } else if (status === "all") {
      dispatch(
        getAllTemporaryFarmers({
          page: page,
          value: storedSearchValue || "",
        })
      );
    } else if (status === "called") {
      dispatch(
        getCalledTemporaryFarmers({
          page: page,
          value: storedSearchValue || "",
        })
      );
    } else if (status === "missed") {
      dispatch(
        getMissedTemporaryFarmers({
          page: page,
          value: storedSearchValue || "",
        })
      );
    }
  }, [dispatch, status, allTemporaryfarmersPagination]);

  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4 mt-4 pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS SCETIONS */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 cursor-pointer px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{allTemporaryFarmerTotal || 0}</p>
                </div>
              </div>
            </div>
            <div
              className={
                status === "to_call"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("to_call")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  To Call
                </p>
                <div
                  className={
                    status === "to_call"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{toCallTemporaryFarmerTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "called"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("called")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Called
                </p>
                <div
                  className={
                    status === "called"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{calledTemporaryFarmerTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "missed"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("missed")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Missed
                </p>
                <div
                  className={
                    status === "missed"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{missedTemporaryFarmerTotal}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {status === "all" ? (
          <section className="mt-[30px] mb-[24px]">
            {allTemporaryFarmerLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {allTemporaryFarmerData.length === 0 ? (
                  <EmptyState text="There's no data for this filter yet" />
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          First Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Last Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date of Birth
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Status
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          First Pin
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Second Pin
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          {" "}
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {allTemporaryFarmerData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            onClick={() =>
                              navigate(`/temporary-farmers-profile/${data.id}`)
                            }
                          >
                            <td className="px-4">{data.first_name || "N/A"}</td>
                            <td className="px-4">{data.last_name || "N/A"}</td>
                            <td className="px-4">
                              <div
                                className="flex gap-[6px]"
                                onClick={(event) =>
                                  handleCopyNumber(data?.phone_number, event)
                                }
                              >
                                <p>{data.phone_number || "N/A"}</p>
                                <ContentCopy
                                  className=" cursor-pointer"
                                  style={{ fontSize: "18px" }}
                                />
                              </div>
                            </td>
                            <td className="px-4">
                              {data.date_of_birth ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className=" px-4">
                              <div className="relative">
                                <div
                                  className={
                                    data.customer_service_status === "CALLED"
                                      ? "success-badge flex gap-3 items-center"
                                      : data.customer_service_status ===
                                        "MISSED"
                                      ? "pending-badge flex gap-3 items-center"
                                      : data.customer_service_status ===
                                        "TO_CALL"
                                      ? "error-badge flex gap-3 items-center"
                                      : "na-badge flex gap-3 items-center"
                                  }
                                >
                                  <p>
                                    {data.customer_service_status === "CALLED"
                                      ? "Called"
                                      : data.customer_service_status ===
                                        "MISSED"
                                      ? "Missed"
                                      : data.customer_service_status ===
                                        "TO_CALL"
                                      ? "To Call"
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-4">
                              {data.first_pin_entry ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {data.second_pin_entry ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}

            {!allTemporaryFarmerLoading && allTemporaryFarmerData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={
                    allTemporaryfarmersPagination
                      ? Number(allTemporaryfarmersPagination)
                      : 1
                  }
                  onPageChange={(page) => onAllPageChange({ page })}
                  pageSize={10}
                  totalItems={allTemporaryFarmerTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "to_call" ? (
          <section className="mt-[30px] mb-[24px]">
            {toCallTemporaryFarmerLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {toCallTemporaryFarmerData.length === 0 ? (
                  <EmptyState text="There's no data for this filter yet" />
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          First Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Last Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date of Birth
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          First Pin
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Second Pin
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          {" "}
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {toCallTemporaryFarmerData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            onClick={() =>
                              navigate(`/temporary-farmers-profile/${data.id}`)
                            }
                          >
                            <td className="px-4">{data.first_name || "N/A"}</td>
                            <td className="px-4">{data.last_name || "N/A"}</td>
                            <td className="px-4">
                              <div
                                className="flex gap-[6px]"
                                onClick={(event) =>
                                  handleCopyNumber(data?.phone_number, event)
                                }
                              >
                                <p>{data.phone_number || "N/A"}</p>
                                <ContentCopy
                                  className=" cursor-pointer"
                                  style={{ fontSize: "18px" }}
                                />
                              </div>
                            </td>
                            <td className="px-4">
                              {data.created ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {data.first_pin_entry ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {data.second_pin_entry ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}

            {!toCallTemporaryFarmerLoading &&
            toCallTemporaryFarmerData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={
                    temporaryfarmersPagination
                      ? Number(temporaryfarmersPagination)
                      : 1
                  }
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={toCallTemporaryFarmerTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "called" ? (
          <section className="mt-[30px] mb-[24px]">
            {calledTemporaryFarmerLoading ? (
              <div className="animate-pulse w-full mt-4 px-4  pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {calledTemporaryFarmerData.length === 0 ? (
                  <EmptyState text="There's no data for this filter yet" />
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          First Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Last Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date of Birth
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          First Pin
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Second Pin
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          {" "}
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {calledTemporaryFarmerData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            onClick={() =>
                              navigate(`/temporary-farmers-profile/${data.id}`)
                            }
                          >
                            <td className="px-4">{data.first_name || "N/A"}</td>
                            <td className="px-4">{data.last_name || "N/A"}</td>
                            <td className="px-4">
                              <div
                                className="flex gap-[6px]"
                                onClick={(event) =>
                                  handleCopyNumber(data?.phone_number, event)
                                }
                              >
                                <p>{data.phone_number || "N/A"}</p>
                                <ContentCopy
                                  className=" cursor-pointer"
                                  style={{ fontSize: "18px" }}
                                />
                              </div>
                            </td>
                            <td className="px-4">
                              {data.created ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {data.first_pin_entry ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {data.second_pin_entry ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}

            {!calledTemporaryFarmerLoading &&
            calledTemporaryFarmerData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={
                    calledtemporaryfarmersPagination
                      ? Number(calledtemporaryfarmersPagination)
                      : 1
                  }
                  onPageChange={(page) => onCalledPageChange({ page })}
                  pageSize={10}
                  totalItems={calledTemporaryFarmerTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "missed" ? (
          <section className="mt-[30px] mb-[24px]">
            {missedTemporaryFarmerLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {missedTemporaryFarmerData.length === 0 ? (
                  <EmptyState text="There's no data for this filter yet" />
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          First Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Last Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date of Birth
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          First Pin
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Second Pin
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          {" "}
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {missedTemporaryFarmerData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            onClick={() =>
                              navigate(`/temporary-farmers-profile/${data.id}`)
                            }
                          >
                            <td className="px-4">{data.first_name || "N/A"}</td>
                            <td className="px-4">{data.last_name || "N/A"}</td>
                            <td className="px-4">
                              <div
                                className="flex gap-[6px]"
                                onClick={(event) =>
                                  handleCopyNumber(data?.phone_number, event)
                                }
                              >
                                <p>{data.phone_number || "N/A"}</p>
                                <ContentCopy
                                  className=" cursor-pointer"
                                  style={{ fontSize: "18px" }}
                                />
                              </div>
                            </td>
                            <td className="px-4">
                              {data.created ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {data.first_pin_entry ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {data.second_pin_entry ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}

            {!missedTemporaryFarmerLoading &&
            missedTemporaryFarmerData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={
                    missedtemporaryfarmersPagination
                      ? Number(missedtemporaryfarmersPagination)
                      : 1
                  }
                  onPageChange={(page) => onMissedPageChange({ page })}
                  pageSize={10}
                  totalItems={missedTemporaryFarmerTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : null}
      </TableBackground>
    </div>
  );
};

export default TemporaryFarmersTable;
