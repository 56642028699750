import React,{useState, useEffect, useRef} from 'react'
import plus_white from '../../assets/icons/plus_white.svg'
import SearchComponent from '../../components/Search';
import user_green from '../../assets/icons/user_green.svg'
import more_icon from '../../assets/icons/more_icon.svg'
import check_circle from '../../assets/icons/checkbox_circle.svg'
import ModalComponent from '../../components/Modals/modal';
import {  ExpandLess, ExpandMore } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux';
import { createMessageTemplate, deleteTemplate, getMessageTemplateCount, updateMessageTemplate } from '../../redux/Messages/messageActions';
import moment from 'moment';
import { clearCreateTemplateSuccess, clearDeleteTemplateSuccess, clearUpdateTemplateSuccess } from '../../redux/Messages/messages';
import { useOutsideAlerter } from "../../helpers/OutsideClick";


const MessageTemplatesPage = () => {

    const dispatch = useDispatch()
    const { templates, loader, loading, deleteTemplateSuccess, createTemplateSuccess, updateTemplateSuccess } = useSelector((state) => state.message)

    const [show, setShow] = useState(false)
    const [sendModal, setSendModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [createModal, setCreateModal] = useState(false)
    const [templateDetails, setTemplateDetails] = useState({})

    const [templateTitle, setTemplateTitle] = useState('')
    const [english, setEnglish] = useState('')
    const [yoruba, setYoruba] = useState('')
    const [igbo, setIgbo] = useState('')
    const [hausa, setHausa] = useState('')

    const [editTemplateTitle, setEditTemplateTitle] = useState('')
    const [editEnglish, setEditEnglish] = useState('')
    const [editYoruba, setEditYoruba] = useState('')
    const [editIgbo, setEditIgbo] = useState('')
    const [editHausa, setEditHausa] = useState('')

    const toggleDeleteModal = (id) =>{
        let template = templates.find((val) => val.id === id)
        setTemplateDetails(template)
        setShow(!show)
    }

    const showDeleteModal = () =>{
      setShow(!show)
    }

    const toggleEditModal = (id) =>{
        setEditYoruba('')
        setEditEnglish('')
        setEditHausa('')
        setEditIgbo('')
        let template = templates.find((val) => val.id === id)
        setEditTemplateTitle(template.title)
        template.templates.map((template) => {
            if (template.language === 'English') {
              return setEditEnglish(template.body);
            } else if (template.language === 'Yoruba') {
               return setEditYoruba(template.body);
            } else if (template.language === 'Hausa') {
              return setEditHausa(template.body);
            } else {
              return setEditIgbo(template.body);
            }
          });
        showEditModal(!editModal)
    }

    const showSendModal = () =>{
        setSendModal(!sendModal)
    }

    const showEditModal = () =>{
        setEditModal(!editModal)
    }
    const showCreateModal = () =>{
        setCreateModal(!createModal)
    }

    const [searchValue, setSearchValue] = useState('')
    const [isLanguageDrop, setIsLanguageDrop] = useState('')
    const [selectedLanguage, setSelectedLanguage] = useState(null)
    const [templateID, setTemplateID] = useState(null)
    const [isClickMore, setIsClickMore] = useState(false)

    const clickMore = (id) =>{
        setTemplateID(id)
        setIsClickMore(!isClickMore)   
    }

    const languages = [
        { key: 'eng', label: 'English' },
        { key: 'yor', label: 'Yoruba' },
        { key: 'hau', label: 'Hausa' },
    ]

      const [languageID, setLanguageID] = useState(1)
      const languageTypes = [
        {
          id: 1,
          title: 'English',
        },
        {
          id: 2,
          title: 'Yoruba',
        },
        {
          id: 3,
          title: 'Hausa',
        },
        {
            id: 4,
            title: 'Igbo',
          },
      ]
    
      const toggleLanguageType = (id) => {
        setLanguageID(id)
      }

    

      const handleCreateTemplate = () =>{
        const template = [];
        if (english !== '') {
          template.push({ language: 'English', body: english });
        }
        if (yoruba !== '') {
          template.push({ language: 'Yoruba', body: yoruba });
        }
        if (hausa !== '') {
          template.push({ language: 'Hausa', body: hausa });
        }
        if (igbo !== '') {
          template.push({ language: 'Igbo', body: igbo });
        }
        dispatch(createMessageTemplate({
            title: templateTitle,
            templates: template
        }))
      }

      const handleUpdateTemplate = () =>{
        const template = [];
        if (editEnglish !== '') {
          template.push({ language: 'English', body: editEnglish });
        }
        if (editYoruba !== '') {
          template.push({ language: 'Yoruba', body: editYoruba });
        }
        if (editHausa !== '') {
          template.push({ language: 'Hausa', body: editHausa });
        }
        if (editIgbo !== '') {
          template.push({ language: 'Igbo', body: editIgbo });
        }
        dispatch(updateMessageTemplate({
            id: templateID,
            title: editTemplateTitle,
            templates: template
        }))
      }
       
      const wrapperRef = useRef(null);
      useOutsideAlerter(wrapperRef, setIsClickMore);

    const handleChange = (e) => {
          let val = e.target.value
          setSearchValue(val)
    }

    const handleLangDropdown = () =>{
        setIsLanguageDrop(!isLanguageDrop)
    }

    const handleClickLanguage = (key, label) =>{
        setIsLanguageDrop(!isLanguageDrop)
        setSelectedLanguage(label)
    }

    const handleDeleteTemplate = () =>{
        dispatch(deleteTemplate({
            id: templateDetails.id
        }))
    }

    useEffect(()=>{
        if(deleteTemplateSuccess){
             setShow(false)
             dispatch(clearDeleteTemplateSuccess())
             dispatch(getMessageTemplateCount())
        }
    },[deleteTemplateSuccess, dispatch])

    useEffect(()=>{
        if(updateTemplateSuccess){
            setEditModal(false)
             dispatch(clearUpdateTemplateSuccess())
             dispatch(getMessageTemplateCount())
        }
    },[updateTemplateSuccess, dispatch])

    useEffect(()=>{
        if(createTemplateSuccess){
            setCreateModal(false)
            dispatch(getMessageTemplateCount())
            dispatch(clearCreateTemplateSuccess())
        }
    },[createTemplateSuccess, dispatch])

    useEffect(() => {
        dispatch(getMessageTemplateCount())
      }, [dispatch])

    return ( 
        <>
        {/* Create Message Modal */}
        <ModalComponent show={createModal} showModal={showCreateModal} 
        title="Create Message Template"
        subtitle=""
        >
            <div className='mt-5'>
               <div>
                    <label
                      htmlFor="title"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Template Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={templateTitle}
                      onChange={(e) =>setTemplateTitle(e.target.value)}
                      placeholder="Template Title"
                      className='appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    />
              </div>
              <div>
                <div className='mt-4'>
                    <h6 className='text-[#344335] text-sm font-semibold'>Compose Template</h6>
                </div>
                <ul className="flex mt-2 gap-3 items-center">
                    {languageTypes.map((item) => (
                    <li
                        key={item.id}
                        onClick={() => toggleLanguageType(item.id)}
                        className={
                        languageID === item.id
                            ? 'flex gap-1 items-center border-b-2 border-primary cursor-pointer'
                            : 'flex gap-1 items-center cursor-pointer'
                        }
                    >
                      {languageID === item.id &&  <img src={check_circle} alt="check" /> }
                        <p
                        className={
                            languageID === item.id
                            ? 'text-primary text-xs font-semibold'
                            : 'text-[#96A397] text-xs font-semibold'
                        }
                        >
                        {item.title}
                        </p>
                    </li>
                    ))}
                </ul>
              </div>
                <div className='mt-3'>
                    {/* English */}
                    {languageID === 1 && <textarea
                            id="message"    
                            rows="6"
                            value={english}
                            onChange={(e) =>setEnglish(e.target.value)}
                            className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                    text-[#344335]
                                    text-sm resize-none
                                    focus:border-[#2B8C34] focus:outline-none  
                                    focus:shadow-input rounded-lg border border-[#96A397]
                                    bg-white py-3 px-4"
                            placeholder="Write your message template in English"
                        ></textarea>
                    }

                    {/* Yoruba */}
                    {languageID === 2 && <textarea
                            id="message"    
                            rows="6"
                            value={yoruba}
                            onChange={(e) =>setYoruba(e.target.value)}
                            className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                    text-[#344335]
                                    text-sm resize-none
                                    focus:border-[#2B8C34] focus:outline-none  
                                    focus:shadow-input rounded-lg border border-[#96A397]
                                    bg-white py-3 px-4"
                            placeholder="Write your message template in Yoruba"
                        ></textarea>
                    }

                    {/* Hausa */}
                    {languageID === 3 && <textarea
                            id="message"    
                            rows="6"
                            value={hausa}
                            onChange={(e) =>setHausa(e.target.value)}
                            className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                    text-[#344335]
                                    text-sm resize-none
                                    focus:border-[#2B8C34] focus:outline-none  
                                    focus:shadow-input rounded-lg border border-[#96A397]
                                    bg-white py-3 px-4"
                            placeholder="Write your message template in Hausa"
                        ></textarea>
                    }

                    {/* Igbo */}
                    {languageID === 4 && <textarea
                            id="message"    
                            rows="6"
                            value={igbo}
                            onChange={(e) =>setIgbo(e.target.value)}
                            className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                    text-[#344335]
                                    text-sm resize-none
                                    focus:border-[#2B8C34] focus:outline-none  
                                    focus:shadow-input rounded-lg border border-[#96A397]
                                    bg-white py-3 px-4"
                            placeholder="Write your message template in Igbo"
                        ></textarea>
                    }
                </div>
            </div>
            <div className='flex justify-end mt-6 mb-3'>
                <button
                    type="submit"
                    onClick={handleCreateTemplate}
                    disabled={
                        templateTitle === "" ||
                        (english === "") ||
                        loading
                    }
                    className="bg-[#2B8C34] 
                        disabled:bg-[#ABB6AC] hover:opacity-[0.9]
                        rounded-lg px-6 py-3 text-white text-sm font-medium transition-all"
                >
                    Save
                </button>  
            </div>
        </ModalComponent>

          {/* Edit Message Modal */}
          <ModalComponent show={editModal} showModal={showEditModal} 
        title="Edit Message Template"
        subtitle=""
        >
            <div className='mt-5'>
               <div>
                    <label
                      htmlFor="title"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Template Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      placeholder="Enter a template title"
                      value={editTemplateTitle}
                      onChange={(e) =>setEditTemplateTitle(e.target.value)}
                      className='appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    />
              </div>
              <div>
              <ul className="flex mt-5 gap-3 items-center">
                    {languageTypes.map((item) => (
                    <li
                        key={item.id}
                        onClick={() => toggleLanguageType(item.id)}
                        className={
                        languageID === item.id
                            ? 'flex gap-1 items-center border-b-2 border-primary cursor-pointer'
                            : 'flex gap-1 items-center cursor-pointer'
                        }
                    >
                      {languageID === item.id &&  <img src={check_circle} alt="check" /> }
                        <p
                        className={
                            languageID === item.id
                            ? 'text-primary text-xs font-semibold'
                            : 'text-[#96A397] text-xs font-semibold'
                        }
                        >
                        {item.title}
                        </p>
                    </li>
                    ))}
                </ul>
              </div>
                <div className='mt-3'>
                    {/* English */}
                    {languageID === 1 && <textarea
                            id="message"    
                            rows="6"
                            value={editEnglish}
                            onChange={(e) =>setEditEnglish(e.target.value)}
                            className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                    text-[#344335]
                                    text-sm resize-none
                                    focus:border-[#2B8C34] focus:outline-none  
                                    focus:shadow-input rounded-lg border border-[#96A397]
                                    bg-white py-3 px-4"
                            placeholder="Write your template here..."
                        ></textarea>
                    }

                     {/* Yoruba */}
                     {languageID === 2 && <textarea
                            id="message"    
                            rows="6"
                            value={editYoruba}
                            onChange={(e) =>setEditYoruba(e.target.value)}
                            className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                    text-[#344335]
                                    text-sm resize-none
                                    focus:border-[#2B8C34] focus:outline-none  
                                    focus:shadow-input rounded-lg border border-[#96A397]
                                    bg-white py-3 px-4"
                            placeholder="Write your message template in Yoruba"
                        ></textarea>
                    }

                    {/* Hausa */}
                    {languageID === 3 && <textarea
                            id="message"    
                            rows="6"
                            value={editHausa}
                            onChange={(e) =>setEditHausa(e.target.value)}
                            className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                    text-[#344335]
                                    text-sm resize-none
                                    focus:border-[#2B8C34] focus:outline-none  
                                    focus:shadow-input rounded-lg border border-[#96A397]
                                    bg-white py-3 px-4"
                            placeholder="Write your message template in Hausa"
                        ></textarea>
                    }

                    {/* Igbo */}
                    {languageID === 4 && <textarea
                            id="message"    
                            rows="6"
                            value={editIgbo}
                            onChange={(e) =>setEditIgbo(e.target.value)}
                            className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                    text-[#344335]
                                    text-sm resize-none
                                    focus:border-[#2B8C34] focus:outline-none  
                                    focus:shadow-input rounded-lg border border-[#96A397]
                                    bg-white py-3 px-4"
                            placeholder="Write your message template in Igbo"
                        ></textarea>
                    }

                </div>
            </div>
            <div className='flex justify-end mt-6 mb-3'>
                <button
                    type="submit"
                    onClick={handleUpdateTemplate}
                    disabled={
                        editTemplateTitle === "" ||
                        (editEnglish === "") ||
                        loading
                    }
                    className="bg-[#2B8C34] 
                        disabled:bg-[#ABB6AC] hover:opacity-[0.9]
                        rounded-lg px-6 py-3 text-white text-sm font-medium transition-all"
                >
                    Save
                </button>  
            </div>
        </ModalComponent>

         {/* Send Message Modal */}
         <ModalComponent show={sendModal} showModal={showSendModal} 
        title="Send Message Template"
        subtitle=""
        >
            <div className='mt-5'>
                <div className='relative'>
                    <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                         Select language
                    </label>
                    <div
                        className="flex items-center cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397]"
                        onClick={handleLangDropdown}
                        >
                        <p>
                            {selectedLanguage ? selectedLanguage : 'Select Language'}
                        </p>
                        {isLanguageDrop ? (
                            <ExpandLess
                            className="text-[#ABB6AC] cursor-pointer"
                            style={{ fontSize: '18px' }}
                            />
                        ) : (
                            <ExpandMore
                            className="text-[#ABB6AC] cursor-pointer"
                            style={{ fontSize: '18px' }}
                            />
                        )}
                    
                  </div>
                 {isLanguageDrop && 
                  <div
                    className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10"
                    style={{
                      boxShadow:
                        '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08',
                    }}
                  >
                     {languages.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                          onClick={() =>
                            handleClickLanguage(data.key, data.label)
                          }
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.label}
                          </p>
                        </div>
                      )
                    })} 
                  </div>
                    }
                </div>

                <div className='mt-3'>
                <textarea
                        id="message"    
                        rows="6"
                        value="Hello Adamu Abubakar,
                        This is (name of cs operator)a from Crop2cash. Thank you for registering with us. We noticed that your profile is incomplete. Please dial *347*46# to complete your registration."
                        className="appearance-none w-full
                            placeholder:text-[#96A397] placeholder:text-sm
                                text-[#344335]
                                text-sm resize-none
                                focus:border-[#2B8C34] focus:outline-none  
                                focus:shadow-input rounded-lg border border-[#96A397]
                                bg-white py-3 px-4"
                        placeholder="Write your template here..."
                    ></textarea>
                </div>
            </div>
            <div className='flex justify-end mt-6 mb-3'>
                <button
                    type="submit"
                    disabled={false}
                    className="bg-[#2B8C34] 
                        disabled:bg-[#ABB6AC] hover:opacity-[0.9]
                        rounded-lg px-6 py-3 text-white text-sm font-medium transition-all"
                >
                    Save
                </button>  
            </div>
        </ModalComponent>

        {/* Delete Modal */}
        <ModalComponent show={show} showModal={showDeleteModal} 
        title="Delete message template"
        subtitle=""
        >
            <div className='mt-5'>
                <h6 className='text-[#5C715E] text-sm font-medium'>
                Are you sure you sure you want to permanently delete the template <span className='text-[#344335] font-semibold text-sm'>
                   "{templateDetails && templateDetails.title ? templateDetails.title: ""}"
                </span>
                </h6>
            </div>
            <div className='flex justify-end mt-10 mb-3'>
                <div className='flex gap-4'>
                <button
                onClick={() => setShow(false)}
                    type="submit"
                    disabled={false}
                    className="bg-transparent 
                        disabled:bg-[#ABB6AC] 
                        rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    disabled={loading}
                    onClick={handleDeleteTemplate}
                    className="bg-[#B92043] 
                        disabled:bg-[#B92043] disabled:opacity-[0.5] hover:opacity-[0.9]
                        rounded-lg px-6 py-3 text-white text-sm font-medium transition-all"
                >
                    Delete
                </button>
                </div>
               
            </div>
        </ModalComponent>

         <div className="bg-white p-5 rounded-[8px]">
            <div className=" flex justify-between items-center gap-[8px]">
                <p className="text-primary text-lg font-semibold leading-6">Message Template</p>
                <div>
                <button
                onClick={() => showCreateModal()}
                    className="w-full py-3 flex items-center justify-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
                   >
                       <img src={plus_white} alt="plus icon" />
                    <p className="text-white font-medium text-sm ml-2">
                    Create Template
                    </p>
                </button>
                </div>
            </div>
            <div className='mt-3'>
                <SearchComponent
                placeholder="Search..."
                searchValue={searchValue}
                handleChange={handleChange}
                 />
            </div>
        </div>
        <div className='mt-5 grid grid-cols-3 gap-4'>
            {loader &&
            ['2', '1', '4', '5', '3', '8'].map((item) => (
                <div className="mb-2 bg-[#FFFFFF] rounded-lg py-5 px-4">
                <div className="animate-pulse w-full">
                    <div className="h-4 bg-gray-200 rounded-md  w-full mb-2"></div>
                    <div className="h-4 bg-gray-200 rounded-md  w-full mb-2"></div>
                    <div className="h-8 bg-gray-200 rounded-md  w-full mb-2"></div>
                    <div className="h-4 bg-gray-200 rounded-md mb-2 mt-5 w-full"></div>
                    <div className="h-4 bg-gray-200 rounded-md  w-full"></div>
                </div>
            </div>
            ))}

              {
                !loader && templates.length ? templates.map(template => (
                    <div key={template.id} className='bg-white rounded-lg py-5 px-4 h-fit'>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='text-[#344335] text-sm font-semibold'>{template.title}</p>
                        </div>
                        <div className='relative'>
                            <img src={more_icon} alt="more icon" className='cursor-pointer' onClick={() =>clickMore(template.id)} />

                           {
                           templateID === template.id && isClickMore &&
                           <ul ref={wrapperRef} className='absolute right-1 top-3 bg-white rounded-lg shadow-[#00000040] border min-w-[162px] py-2 px-2'>
                                {/* <li 
                                    onClick={() => showSendModal()}
                                className='py-2 px-4 text-sm text-[#344335] font-medium hover:bg-[#EDF7EE] transition-all cursor-pointer'>
                                    Send
                                </li> */}
                                  <li
                                    onClick={() => toggleEditModal(template.id)}
                                    className='py-2 px-4 text-sm text-[#344335] font-medium hover:bg-[#EDF7EE] transition-all cursor-pointer'>
                                    Edit
                                </li>
                                <li 
                                onClick={() => toggleDeleteModal(template.id)}
                                className='py-2 px-4 text-sm text-[#344335] font-medium hover:bg-[#EDF7EE] transition-all cursor-pointer'>
                                    Delete
                                </li>
                              
                            </ul>
                            }
                        </div>
                    </div>
    
                    <div className='mt-2'>
                        <p className='text-xs font-medium text-[#5C715E]'>
                            {
                                template.templates.length && 
                                template.templates[0].body
                            }
                        </p>
                    </div>
    
                    <div className='mt-4 flex gap-2'>
                        {
                            template.templates.length ?
                            template.templates.map((val, index)=>(
                                <div key={index}>
                                <p className='text-[#2B8C34] text-xs font-medium rounded-[4px] py-1 px-1 border border-primary'>{val.language}</p>
                            </div>
                            ))
                            :
                            ""
                        }
                        
                    </div>
    
                    <div className='mt-6 flex justify-between items-center'>
                        <div className='flex gap-2 items-center'>
                            <div className='bg-[#DBFFDF] w-[24px] h-[24px] rounded-full flex justify-center items-center'>
                                <img src={user_green} alt="user" />
                            </div>
                            <div>
                                <p className="font-medium text-[#5C715E] text-sm">
                                {template.user.first_name}  {template.user.last_name}
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className='text-[#96A397] font-medium text-xs'> {moment(template.created).format('Do, MMM YYYY hh:mm:ss a')}</p>
                        </div>
                    </div>
    
    
                </div>  
                ))
                :
                ""
            }

        </div>
        
        </>
     );
}
 
export default MessageTemplatesPage;