import React, { useMemo, useState, useCallback, useEffect } from "react";
import SearchComponent from "../../components/Search";
import PaginationComponent from "../../components/Pagination/Pagination";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCooperativeSummary } from "../../redux/Loans/loanActions";

const LoanApplicationTable = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  const { loading,cooperativeSummary,cooperativeSummaryCount } = useSelector((state) => state.loan);


  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
   
    dispatch(getCooperativeSummary({
      page: page
    }))
  };


  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        // dispatch(
        //     searchCallRequests({
        //       val: val,
        //       status: 'all',
        //     }),
        //   )
        console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() =>{
      dispatch(getCooperativeSummary({
        page: 1
      }))
  },[dispatch])

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Loan Application
          </p>

          {/* search */}
          <div className="mt-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

        
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
               Cooperative Name
                </th>

               
                <th scope="col" className="px-4 py-4 font-semibold">
                  Loan Amount 
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Applicants
                </th>
             
                <th scope="col" className="px-4 py-4 font-semibold">
                 State
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                 LGA
                </th>
              </tr>
            </thead>
            <tbody>  
            </tbody>
            {!loading && (
              <tbody>
                {cooperativeSummary.length > 0
                  ? cooperativeSummary.map((item) => (
                    <tr
                    onClick={() => navigate(`/agrofastcash/loan-application/${item.cooperative_code}`)}
                    className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                  >
                    <th
                      scope="row"
                      className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                    >
                    {item.cooperative_name}
                    </th>
    
                    <td className="py-4 px-4 text-[#5C715E]">₦{item.total_loan_amount}</td>
                    <td className="py-4 px-4 text-[#5C715E]">{item.total_applications}</td>
                    <td className="py-4 px-4">{item.state}</td>
                    <td className="py-4 px-4">{item.lga}</td>
                  </tr>
                    ))
                  : ""}
              </tbody>
            )}
          </table>

          {loading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={cooperativeSummaryCount}
        />
      </div>
    </>
  );
};

export default LoanApplicationTable;
