import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../components/Header";
import { getComplaints } from "../../redux/Complaints/complaintsAction";
import FlaggedAgentsTable from "../../tables/Agents/FlaggedAgentsTable";

const FlaggedAgents = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getComplaints({ page: 1, value: null }));
  }, [dispatch]);

  return (
    <div>
      <Header text="Flagged Accounts"></Header>

      {/* TABLE SECTION */}
      <section className="mt-8">
        <FlaggedAgentsTable />
      </section>
    </div>
  );
};

export default FlaggedAgents;
