import { createSlice } from "@reduxjs/toolkit";
import {
  getAnOrder,
  getOrders,
  getOrdersStats,
  getOverdueOrders,
  getRefunds,
  getVendorPayment,
  getVendorPaymentInfo,
  getVendorsStats,
  refundsApproval,
  refundsInfo,
  searchOrders,
  vendorDetails,
  vendorOrders,
} from "./ordersAction";
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  // GET ALL ORDERS
  ordersData: [],
  ordersTotal: 0,
  ordersLoading: false,

  // GET AN ORDER
  orderData: {},
  orderLoading: false,

  // GET OVERDUE ORDERs
  overdueOrderData: [],
  overdueOrderLoading: false,
  overdueOrderTotal: 0,

  // GET ORDERS STATS
  ordersStatsData: {},

  // GET VENDOR PAYMENt
  vendorPaymentData: [],
  vendorPaymentLoading: false,
  vendorPaymentTotal: 0,

  // GET VENDOR PAYMENt INFO
  vendorPaymentInfoData: {},
  vendorPaymentInfoLoading: false,

  // GET REFUNDS
  refundsData: [],
  refundsLoading: false,
  refundsTotal: 0,

  // GET REFUNDS INFO
  refundsInfoData: {},
  refundsInfoLoading: false,

  // GET REFUNDS INFO

  refundsApprovalLoading: false,

  // GET VENDOR STATS
  vendorStatsData: [],
  vendorStatsLoading: false,
  vendorStatsTotal: 0,

  vendorDetailsData: {},
  vendorDetailsLoading: false,

  vendorOrdersData: [],
  vendorOrdersLoading: false,
  vendorOrdersTotal: 0,
};

const ordersPersistConfig = {
  key: 'orders',
  storage,
}

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
  extraReducers: {
    [getOrders.pending]: (state) => {
      state.ordersLoading = true;
    },
    [getOrders.fulfilled]: (state, { payload }) => {
      state.ordersLoading = false;
      state.ordersData = payload.data;
      state.ordersTotal = payload.total;
    },
    [getOrders.rejected]: (state) => {
      state.ordersLoading = false;
    },

    //GET AN ORDER
    [getAnOrder.pending]: (state) => {
      state.orderLoading = true;
    },
    [getAnOrder.fulfilled]: (state, { payload }) => {
      state.orderLoading = false;
      state.orderData = payload.data;
    },
    [getAnOrder.rejected]: (state) => {
      state.orderLoading = false;
    },

    // SEARCH FOR ORDERS
    [searchOrders.pending]: (state) => {
      state.ordersLoading = true;
    },
    [searchOrders.fulfilled]: (state, { payload }) => {
      state.ordersLoading = false;
      state.ordersData = payload.data;
      state.ordersTotal = payload.total;
    },
    [searchOrders.rejected]: (state) => {
      state.ordersLoading = false;
    },

    // GET OVERDUE ORDERS
    [getOverdueOrders.pending]: (state) => {
      state.overdueOrderLoading = true;
    },
    [getOverdueOrders.fulfilled]: (state, { payload }) => {
      state.overdueOrderLoading = false;
      state.overdueOrderData = payload.data;
      state.overdueOrderTotal = payload.count;
    },
    [getOverdueOrders.rejected]: (state) => {
      state.overdueOrderLoading = false;
    },

    // GET ORDERS STATS
    [getOrdersStats.pending]: (state) => {
      state.orderLoading = true;
    },
    [getOrdersStats.fulfilled]: (state, { payload }) => {
      state.orderLoading = false;
      state.ordersStatsData = payload;
    },
    [getOrdersStats.rejected]: (state) => {
      state.orderLoading = false;
    },

    // GET VENDOR PAYMENT
    [getVendorPayment.pending]: (state) => {
      state.vendorPaymentLoading = true;
    },
    [getVendorPayment.fulfilled]: (state, { payload }) => {
      state.vendorPaymentLoading = false;
      state.vendorPaymentData = payload.data;
      state.vendorPaymentTotal = payload.count;
    },
    [getVendorPayment.rejected]: (state) => {
      state.vendorPaymentLoading = false;
    },

    // GET VENDOR PAYMENT INFO
    [getVendorPaymentInfo.pending]: (state) => {
      state.vendorPaymentInfoLoading = true;
    },
    [getVendorPaymentInfo.fulfilled]: (state, { payload }) => {
      state.vendorPaymentInfoLoading = false;
      state.vendorPaymentInfoData = payload.data;
    },
    [getVendorPaymentInfo.rejected]: (state) => {
      state.vendorPaymentInfoLoading = false;
    },

    // GET REFUNDS
    [getRefunds.pending]: (state) => {
      state.refundsLoading = true;
    },
    [getRefunds.fulfilled]: (state, { payload }) => {
      state.refundsLoading = false;
      state.refundsData = payload.data;
      state.refundsTotal = payload.count;
    },
    [getRefunds.rejected]: (state) => {
      state.refundsLoading = false;
    },

    // GET REFUNDS INFO
    [refundsInfo.pending]: (state) => {
      state.refundsInfoLoading = true;
    },
    [refundsInfo.fulfilled]: (state, { payload }) => {
      state.refundsInfoLoading = false;
      state.refundsInfoData = payload;
    },
    [refundsInfo.rejected]: (state) => {
      state.refundsInfoLoading = false;
    },

    // REFUNDS APPROVAL
    [refundsApproval.pending]: (state) => {
      state.refundsApprovalLoading = true;
    },
    [refundsApproval.fulfilled]: (state, { payload }) => {
      state.refundsApprovalLoading = false;
    },
    [refundsApproval.rejected]: (state) => {
      state.refundsApprovalLoading = false;
    },

    // VENDOR STATS
    [getVendorsStats.pending]: (state) => {
      state.vendorStatsLoading = true;
    },
    [getVendorsStats.fulfilled]: (state, { payload }) => {
      state.vendorStatsLoading = false;
      state.vendorStatsData = payload.results;
      state.vendorStatsTotal = payload.count;
    },
    [getVendorsStats.rejected]: (state) => {
      state.vendorStatsLoading = false;
    },

    [vendorDetails.pending]: (state) => {
      state.vendorDetailsLoading = true;
    },
    [vendorDetails.fulfilled]: (state, { payload }) => {
      state.vendorDetailsLoading = false;
      state.vendorDetailsData = payload.data;
    },
    [vendorDetails.rejected]: (state) => {
      state.vendorDetailsLoading = false;
    },

    [vendorOrders.pending]: (state) => {
      state.vendorOrdersLoading = true;
    },
    [vendorOrders.fulfilled]: (state, { payload }) => {
      state.vendorOrdersLoading = false;
      state.vendorOrdersData = payload.data;
      state.vendorOrdersTotal = payload.total;
    },
    [vendorOrders.rejected]: (state) => {
      state.vendorOrdersLoading = false;
    },
  },
});


const ordersReducer = persistReducer(ordersPersistConfig, ordersSlice.reducer);


// Action creators are generated for each case reducer function
export const { increment, decrement } = ordersSlice.actions;

export default ordersReducer;
