import { createSlice } from '@reduxjs/toolkit'
import { CreateInfoCard, fetchInfoCard, UpdateInfoCard, atlasSearch, fetchTypeCategories } from './catalogActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  value: 0,
  loading: false,
  loader: false,
  isCreateInfoSuccess: false,
  infoDetails: {},
  search_keywords: [],
  searchResult: [],
  groupByResult: {},
  filteredByResult: {},
  informationCard: [],
  categories: []
}

const catalogPersistConfig = {
  key: 'catalog',
  storage,
}


export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    resetCreateInfo: (state) =>{
        state.isCreateInfoSuccess = false
    },
    addKeyword: (state, {payload}) =>{
        state.search_keywords =[...state.search_keywords, payload]
    },
    removeKeyword: (state,{payload}) =>{
        let result = [...state.search_keywords];
        // removing the element using splice
        result.splice(payload, 1);
        state.search_keywords = result
    },
    addInfoTypeCategories: (state,{payload}) =>{
      console.log(payload)
      state.categories = payload
    },
    clearSearchProducts: (state) =>{
      state.searchResult = []
      state.groupByResult = {}
      state.filteredByResult = {}
      state.informationCard = []
    } 
  },
  extraReducers: {
      // fetch type categories ----------
    [fetchTypeCategories.pending]: (state) =>{
      state.loader = true
    },
    [fetchTypeCategories.fulfilled]: (state, {payload}) =>{
      state.loader = false
      state.categories = payload.data
    },
    [fetchTypeCategories.rejected]: (state) =>{
        state.loader = false
    },
      // ATLAS SEARCH -------------------- ---------------------------------------------------------------
      [atlasSearch.pending]: (state) =>{
        state.loading = true
        state.searchResult=[]
        state.groupByResult={}
        state.filteredByResult={}
        state.informationCard = []
      },
      [atlasSearch.fulfilled]: (state, {payload}) =>{
        state.loading = false
        let input = payload.data.products.InputProducts ? payload.data.products.InputProducts : []
        let livestock = payload.data.products.LivestockInputs ? payload.data.products.LivestockInputs : []
        let advisory = payload.data.products.AdvisoryProducts ? payload.data.products.AdvisoryProducts : []
        let insurance = payload.data.products.InsuranceProducts ? payload.data.products.InsuranceProducts : []
  
        state.searchResult = [  ...input, ...livestock,
                               ...advisory, ...insurance]
  
        state.groupByResult = payload.data.products
        state.filteredByResult = payload.data.products
        state.informationCard = payload.data.information_card
  
      },
      [atlasSearch.rejected]: (state) =>{
        state.loading = false
      },

    // fetch info card details ----------------
    [fetchInfoCard.pending]: (state) =>{

    },
    [fetchInfoCard.fulfilled]: (state, {payload}) =>{
      state.infoDetails = payload.data
      state.search_keywords = payload.data.search_keywords
    },
    [fetchInfoCard.rejected]: (state) =>{

    },

    // create info card -------------------- ---------------------------------------------------------------
    [CreateInfoCard.pending]: (state) => {
      state.loader = true
    },
    [CreateInfoCard.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.isCreateInfoSuccess = true
    },
    [CreateInfoCard.rejected]: (state, { payload }) => {
      state.loader = false
    },
      // update info card -------------------- ---------------------------------------------------------------
    [UpdateInfoCard.pending]: (state) => {
        state.loader = true
    },
    [UpdateInfoCard.fulfilled]: (state, { payload }) => {
        state.loader = false
    },
    [UpdateInfoCard.rejected]: (state, { payload }) => {
        state.loader = false
    },


  },
})



const catalogReducer = persistReducer(catalogPersistConfig, catalogSlice.reducer);


// Action creators are generated for each case reducer function
export const { increment, decrement, resetCreateInfo,addKeyword, removeKeyword, addInfoTypeCategories,clearSearchProducts } = catalogSlice.actions

export default catalogReducer
