import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "./Auth/auth";
import catalogReducer from "./Catalog/catalog";
import callRequestReducer from "./CallRequest/request";
import farmersReducer from "./Farmers/Farmers";
import transactionsReducer from "./Transactions/Transactions";
import ussdSessionReducer from "./Sessions/sessions";
import complaintsReducer from "./Complaints/Complaints";
import callToOrderReducer from "./CallToOrder/CallToOrder";
import locationReducer from "./Location/Location";
import ordersReducer from "./Orders/Orders";
import notificationReducer from "./Notifications/notifications";
import overviewReducer from "./Overview/overview";
import retailReducer from "./Retail/Retail";
import campaignReducer from "./Campaign/Campaign";
import messageReducer from "./Messages/messages";
import loanReducer from "./Loans/loan";
import cooperativesReducer from "./Cooperative/Cooperative";
import agentsReducer from "./Agents/Agents";
import pointsReducer from "./Points/points";

const appReducer = combineReducers({
  auth: authReducer,
  catalog: catalogReducer,
  request: callRequestReducer,
  farmer: farmersReducer,
  transactions: transactionsReducer,
  session: ussdSessionReducer,
  complaints: complaintsReducer,
  callToOrder: callToOrderReducer,
  location: locationReducer,
  orders: ordersReducer,
  notification: notificationReducer,
  overview: overviewReducer,
  retail: retailReducer,
  campaign: campaignReducer,
  message: messageReducer,
  loan: loanReducer,
  cooperatives: cooperativesReducer,
  agents: agentsReducer,
  points: pointsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/Logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
