import React from 'react'
import GoBack from '../../../components/GoBack';
import Header from '../../../components/Header';
import InCompleteProfileTable from '../../../tables/CallLog/Member/IncompleteProfileTable';

const MemberIncompleteFarmersPage = () => {
    return ( 
        <>
          <GoBack />
            <div className='mt-4'>
            <Header text="Incomplete Profiles" /> 
         </div>
         <InCompleteProfileTable />
        </>
     );
}
 
export default MemberIncompleteFarmersPage;