import { ExpandMore } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalComponent from "../../../../components/Modals/modal";
import { ThreeDots } from "react-loader-spinner";
import {
  handleCloseDailyLimit,
  handleOpenDailyLimit,
} from "../../../../redux/Farmers/Farmers";
import { EditFarmersDailyLimit } from "../../../../redux/Farmers/farmersActions";
// import { FarmerContext } from '../../FarmersProfile'

const DailyLimit = (id) => {
  // const [isClickDailyLimit, setIsClickDailyLimit] = useState(false)
  const [isClickIcon, setIsClickIcon] = useState(false);
  const [selectedLimit, setSelectedLimit] = useState("");
  const { is_team_lead } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { farmersProfileData, isClickDailyLimit, dailyLimitLoading } =
    useSelector((state) => state.farmer);

  const handleDailyLimit = () => {
    dispatch(handleOpenDailyLimit());
  };

  const CloseDailyLimit = () => {
    dispatch(handleCloseDailyLimit());
  };

  const data = [
    {
      key: "50000",
      name: "N50,000",
    },
    {
      key: "100000",
      name: "N100,000",
    },
    {
      key: "200000",
      name: "N200,000",
    },
    {
      key: "500000",
      name: "N500,000",
    },
  ];
  const handleSaveLimit = () => {
    dispatch(
      EditFarmersDailyLimit({
        value: selectedLimit.key,
        id: id.id,
      })
    );
  };

  return (
    <div>
      {" "}
      {is_team_lead ? (
        <p
          className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]"
          onClick={handleDailyLimit}
        >
          Daily Limit
        </p>
      ) : (
        <p className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#96A397] cursor-default">
          Daily Limit
        </p>
      )}
      {/* GENERATE STATEMENT MODAL */}
      <ModalComponent
        title="Daily Limit"
        show={isClickDailyLimit}
        showModal={CloseDailyLimit}
      >
        <div className="pt-[32px]">
          <div>
            <p className="text-sm font-[400] leading-4 text-[#344335] pb-[4px]">
              Daily Limit
            </p>

            <div className="relative">
              <div
                className="flex justify-between items-center h-[44px] w-full px-[16px] border border-[#96A397] rounded-[8px] cursor-pointer mb-[4px]"
                onClick={() => setIsClickIcon(!isClickIcon)}
              >
                <p>
                  {selectedLimit.name ||
                    farmersProfileData?.wallet_daily_limit ||
                    "Select a limit"}
                </p>
                <ExpandMore
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "20px" }}
                />
              </div>
              {isClickIcon && (
                <div
                  className="absolute z-10 p-2 bg-white rounded-lg w-full"
                  style={{
                    boxShadow:
                      "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  {data.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setSelectedLimit({ key: item.key, name: item.name });
                          setIsClickIcon(false);
                        }}
                        className="px-4 py-3 text-sm font-medium leading-5 text-[#344335] hover:bg-[#EDF7EE]b rounded-lg hover:bg-[#EDF7EE] hover:border hover:border-primary hover:text-primary"
                      >
                        <p>{item.name}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <p className="text-xs font-medium leading-4 text-[#344335] w-full max-w-[85%] mb-[32px]">
              The Daily limits puts a cap on the amount of debit transactions a
              customer is allowed to make daily
            </p>

            <div className="flex justify-center mb-[12px]">
              <button
                className="outline-none  border  font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px] bg-primary hborder border-primary text-white transition ease-in-out delay-100"
                onClick={handleSaveLimit}
              >
                {dailyLimitLoading ? (
                  <ThreeDots
                    height={30}
                    width={30}
                    color="#FFF"
                    visible={true}
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "                Save Limit"
                )}
              </button>
              {/* <ButtonPrimary onclick={handleSaveLimit}>
                {dailyLimitLoading ? (
                  <ThreeDots
                    height={30}
                    width={30}
                    color="#FFF"
                    visible={true}
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  '                Save Limit'
                )}
              </ButtonPrimary> */}
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default DailyLimit;
