import React, { useState, useEffect, useCallback } from "react";
import ModalComponent from "../../components/Modals/modal";
import { ExpandMore } from "@mui/icons-material";
import { getAllFarmersSearch } from "../../redux/Farmers/farmersActions";
import { useDispatch, useSelector } from "react-redux";
import { getAllLGA, getAllState } from "../../redux/Location/locationAction";
import { createACallToOrder } from "../../redux/CallToOrder/callToOrderAction";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateOrderModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [callDate, setCallDate] = useState(new Date());
  const [orderType, setOrderType] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [farmers, setFarmers] = useState([]);

  ////////////////////////LOCATION STATES
  const [isClickState, setIsClickState] = useState(false);
  const [isClickLGA, setIsClickLGA] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [selectedLGA, setSelectedLGA] = useState({});

  //   REDUX STATES
  // const { farmers } = useSelector((state) => state.farmer);
  const { stateData, lgaData } = useSelector((state) => state.location);
  const { createCallToOrderMessage, createCallToOrderLoading } = useSelector(
    (state) => state.callToOrder
  );

  const handleStateClick = () => {
    setIsClickState(!isClickState);
    dispatch(getAllState());
  };

  const handlePhoneNumber = (value) => {
    setFarmers([]);
    let result;

    if (value.startsWith("0")) {
      let data = value.replace("0", "");
      result = "234" + data.toString();
    } else if (value.includes("+")) {
      result = value.replace("+", "");
    } else {
      result = value;
    }

    // console.log(result.length)
    if (`${result}`.length === 13) {
      fetchData(result);
    }
  };

  const fetchData = useCallback(
    async (result) => {
      const data = await getAllFarmersSearch({ value: result }); // fetch data from your redux store
      dispatch(data)
        .then((result) => {
          let data = result?.payload?.data;
          if (data.length !== 0) {
            setFarmers(data);
          } else {
            cogoToast.error("Farmer doesn't exist!");
          }
        })
        .catch((error) => {

          if (error.response.status === 401) {
            dispatch({ type: 'auth/Logout' })
          }
        });
    },
    [dispatch]
  );

  const handleSelectedState = (data) => {
    setSelectedState({
      key: data.id,
      name: data.display_name,
    });
    setIsClickState(false);

    dispatch(
      getAllLGA({
        id: data.id,
      })
    );
  };

  const handleLgaClick = () => {
    if (Object.keys(selectedState).length !== 0) {
      setIsClickLGA(!isClickLGA);
    } else {
      cogoToast.error("Kindly select a state first");
    }
  };

  const handleSave = () => {
    const values = {
      advisory_order: [],
      call_date: callDate,
      call_status: "TO_CALL",
      call_summary: "",
      checkout: false,
      comments: [],
      farmer_id: farmers[0]?.id,
      input_order: [],
      insurance_order: [],
      is_group_order: orderType === "Personal" ? false : true,
      livestock_order: [],
      mechanization_order: [],
      total_display_price: 0,
      delivery_address: deliveryAddress,
    };

    dispatch(
      createACallToOrder({
        values: values,
      })
    );

    // setIsOpenModal(false)
  };

  useEffect(() => {
    if (createCallToOrderMessage) {
      navigate(`/call-to-order/${createCallToOrderMessage}`);
    }
  }, [createCallToOrderMessage, navigate]);

  return (
    <div>
      <button
        className="outline-none bg-primary border border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px] hover:bg-[#24752B] transition-all ease-in-out delay-100 w-[131px] h-[42px]"
        onClick={() => setIsOpenModal(true)}
      >
        Create Order
      </button>

      {/* CREATE ORDER MODAL */}

      <ModalComponent
        title="Create Call To Order"
        show={isOpenModal}
        showModal={() => setIsOpenModal(false)}
      >
        <div className="mt-[20px] w-full">
          {/* PHONE NUMBER */}
          <div className="pb-4">
            <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
              Phone Number
            </p>
            <input
              type="number"
              className="w-full outline-none py-[12px] px-[16px] border border-[#96A397] rounded-[8px] text-medium text-sm leading-5 text-[#96A397] focus:border-[#2B8C34] focus:outline-none  
              focus:shadow-input"
              placeholder="Phone Number"
              onChange={(event) => handlePhoneNumber(event.target.value)}
              pattern="[0-9]"
            />
            {farmers?.map((data, index) => {
              return (
                <p
                  key={index}
                  className="pt-1 font-medium text-xs leading-[16px] text-[#5C715E]"
                >
                  {data?.first_name + " " + data?.last_name}
                </p>
              );
            })}
            {/* {farmers?.map((data, index) => {
              return (
                <p
                  key={index}
                  className="pt-1 font-medium text-xs leading-[16px] text-[#5C715E]"
                >
                  {data?.first_name + " " + data?.last_name}
                </p>
              );
            })} */}
          </div>

          {/* CALL DATE AND TIME */}
          <div className="flex gap-[24px]">
            <div className="pb-5 w-full">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                Call Date and Time
              </p>
              {/* <input
                type="datetime-local"
                onChange={(event) => setCallDate(event.target.value)}
                className="w-full outline-none py-[12px] px-[16px] border border-[#96A397] rounded-[8px] text-medium text-sm leading-5 text-[#96A397] focus:border-[#2B8C34] focus:outline-none  
                focus:shadow-input"
                placeholder="Phone Number"
              /> */}
              <DatePicker
                selected={callDate}
                onChange={(date) => setCallDate(date)}
                showTimeSelect
                closable
                // timeFormat="p"
                timeIntervals={1}
                dateFormat="Pp"
                className="w-full outline-none py-[12px] px-[16px] border border-[#96A397] rounded-[8px] text-medium text-sm leading-5 text-[#96A397] focus:border-[#2B8C34] focus:outline-none  
                focus:shadow-input"
              />
            </div>
          </div>

          {/* ORDER TYPE */}
          <div className="flex gap-[24px]">
            <div className="pb-[23px] w-full">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                Order Type
              </p>
              <div className="flex gap-[28px]">
                <div className="flex items-center">
                  <input
                    onChange={() => setOrderType("Personal")}
                    type="radio"
                    id="Order Type"
                    name="Order Type"
                    status="30"
                    className="w-[13px] h-[13px]"
                  />
                  <label
                    for="Order Type"
                    className="pl-[8px]  pt-[2px]  font-[500] text-sm leading-[18px] text-[#5C715E]"
                  >
                    Personal
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    onChange={(event) => setOrderType("Group")}
                    type="radio"
                    id="Order Type"
                    name="Order Type"
                    status="30"
                    className="w-[13px] h-[13px]"
                  />
                  <label
                    for="Order Type"
                    className="pl-[8px] pt-[2px]  font-[500] text-sm leading-[18px] text-[#5C715E]"
                  >
                    Group
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* STATE AND LGA */}
          <div className="flex gap-[24px]" style={{ display: "none" }}>
            <div className="pb-4 w-1/2">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                State
              </p>
              <div className="relative w-full">
                <div
                  className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                  onClick={handleStateClick}
                >
                  <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                    {selectedState.name || "Select a State"}
                  </p>
                  <ExpandMore
                    className="text-[#ABB6AC] cursor-pointer"
                    style={{ fontSize: "18px" }}
                  />
                </div>
                {isClickState && (
                  <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                    {stateData?.map((data, index) => {
                      return (
                        <p
                          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                          key={index}
                          onClick={() => handleSelectedState(data)}
                        >
                          {data.display_name}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="pb-4 w-1/2">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                LGA
              </p>
              <div className="relative w-full">
                <div
                  className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                  onClick={handleLgaClick}
                >
                  <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                    {selectedLGA.name || "Select Lga"}
                  </p>
                  <ExpandMore
                    className="text-[#ABB6AC] cursor-pointer"
                    style={{ fontSize: "18px" }}
                  />
                </div>
                {isClickLGA && (
                  <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                    {lgaData?.map((data, index) => {
                      return (
                        <p
                          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                          key={index}
                          onClick={() => {
                            setSelectedLGA({
                              key: data.id,
                              name: data.display_name,
                            });
                            setIsClickLGA(false);
                          }}
                        >
                          {data.display_name}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* DELIVERY STATUS */}
          <div className="flex gap-[24px]">
            <div className="pb-5 w-full">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                Delivery Address
              </p>
              <textarea
                id="address"
                rows="6"
                // status={summarystatus}
                // onChange={handleSummaryChange}
                className="appearance-none w-full
                placeholder:text-[#96A397] placeholder:text-sm
                    text-[#5C715E]
                    text-sm resize-none
                    focus:border-[#2B8C34] focus:outline-none  
                    focus:shadow-input rounded-lg border border-[#96A397]
                    bg-white py-3 px-4"
                placeholder="Delivery Address"
                onChange={(event) => setDeliveryAddress(event.target.value)}
              ></textarea>
            </div>
          </div>

          {/* SAVE BUTTON */}
          <div>
            <button
              onClick={handleSave}
              disabled={
                farmers.length !== 0 &&
                  callDate !== "" &&
                  orderType !== "" &&
                  deliveryAddress !== "" &&
                  createCallToOrderLoading !== true
                  ? // Object.keys(selectedState).length !== 0 &&
                  // Object.keys(selectedLGA).length !== 0
                  false
                  : true
              }
              type="button"
              className="w-full text-white bg-primary py-3 flex justify-center items-center
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] font-medium
                         rounded-lg text-"
            >
              Save
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default CreateOrderModal;
