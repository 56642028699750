import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import Header from '../../../components/Header';
import plus_white from '../../../assets/icons/plus_white.svg'
import { CardWithBorder } from '../../../components/Cards';
import chevron_down from '../../../assets/icons/chevron_down.svg'
// import chevron_up from '../../assets/icons/chevron_up.svg'
import check_box from '../../../assets/icons/check-box.svg'
import CallLogsTable from '../../../tables/CallLog/TeamLead/callLogsTable';

const CallLogsPage = () => {


    const [isCallOpen, setIsCallOpen] = useState(false)
    const [callTitle, setCallTitle] = useState('All')
    const [callId, setCallID] = useState(1)

    const [callStatus] = useState([
        {
          id: 1,
          text: 'All',
          tag: 'all',
        },
        {
          id: 2,
          text: 'Active',
          tag: 'Active',
        },
        {
          id: 5,
          text: 'Inactive',
          tag: 'inactive',
        },
        {
          id: 6,
          text: 'Paused',
          tag: 'Paused',
        },
      ])


  const handleCallStatus = (val) => {
    const { id, text } = val
    setCallTitle(text)
    setCallID(id)
    setIsCallOpen(!isCallOpen)
   }
    
    const handleCallOpen = () => {
    setIsCallOpen(!isCallOpen)
    }

    return ( 
        <>
            <Header text="Call Logs Created">
              <div>
                  <Link to="/call-log-admin/createlog"
                      className="w-full py-3 flex items-center justify-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
                    >
                        <img src={plus_white} alt="plus icon" />
                      <p className="text-white font-medium text-sm ml-2">
                      Create New Log
                      </p>
                  </Link>
                  </div>
              </Header>
              <div className='grid grid-cols-4 gap-4 mt-4'>
                <CardWithBorder title="Active Call Logs" subtitle="5" color="border-l-primary" isLink={false}  />
                <CardWithBorder title="Paused Call Logs" subtitle="5" color="border-l-[#B92043]"  isLink={false} />
                <CardWithBorder title="Inactive Call Logs" subtitle="5" color="border-l-primary"  isLink={false} />
                <CardWithBorder title="Archived Call Logs" subtitle="5" color="border-l-[#1D1B16]"  isLink={false} />
            </div>
            <div className="mt-5 bg-white rounded-lg px-6 py-6">
              <h4 className="text-primary font-semibold text-lg">Call Logs Status</h4>

              <div className="mt-5 flex gap-6">
                <div className="flex items-center gap-3">
                  <div>
                    <p className="text-[#344335] text-sm font-medium">
                      Status:
                    </p>
                  </div>
                  <div
                    className="flex gap-2 items-center cursor-pointer relative"
                    onClick={handleCallOpen}
                  >
                    <p className="text-[#96A397] text-sm font-medium">
                      {callTitle}
                    </p>
                    <img src={chevron_down} className="w-3 h-3" alt="arrow" />
                    <ul
                      className={
                        isCallOpen
                          ? 'absolute bg-white rounded-md shadow-dropdown top-6 w-44 py-3 px-2 z-30'
                          : 'hidden'
                      }
                    >
                      {callStatus.map((val) => (
                        <>
                          <li
                            key={val.id}
                            onClick={() => handleCallStatus(val)}
                            className="px-6 py-2 rounded-md hover:bg-[#EDF7EE]"
                          >
                            <div className="flex gap-3">
                              {callId === val.id && (
                                <img src={check_box} alt="checkbox" />
                              )}
                              <p className="text-[#344335] text-sm font-normal">
                                {val.text}
                              </p>
                            </div>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div> 
                          
              </div>

            </div>
            <CallLogsTable />
        </>
     );
}
 
export default CallLogsPage;