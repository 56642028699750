import React from "react";
import GoBack from "../../../components/GoBack";
import CreateLoanPortfolioTable from "../../../tables/Loans/CreateLoanPortfolio";
import Header from "../../../components/Header";

const CreateLoanPorfolio = () => {
  return (
    <div>
      {" "}
      <GoBack />
      <div className="mt-4 mb-6">
        <Header text="Create Loan" />
      </div>
      <div>
        <CreateLoanPortfolioTable />
      </div>
    </div>
  );
};

export default CreateLoanPorfolio;
