import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { getCooperatives } from "../../redux/Cooperative/cooperativeAction";
import EmptyState from "../../components/EmptyState";

const FlaggedCooperativeTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { cooperativeData, cooperativeTotal, cooperativeLoading } = useSelector(
    (state) => state.cooperatives
  );

  // COMPLAINTS PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(
      getCooperatives({ page: page, value: "flagged", search: searchValue })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        dispatch(getCooperatives({ page: 1, value: "flagged", search: val }));
      }, 750),
    [dispatch]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    dispatch(
      getCooperatives({ page: 1, value: "flagged", search: searchValue })
    );
  }, [dispatch, searchValue]);

  // console.log(cooperativeData, cooperativeTotal, cooperativeLoading);

  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {cooperativeLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {cooperativeData.length === 0 ? (
                <EmptyState text="There's no data for this filter yet" />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Members
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Agent
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Phone Number
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        {" "}
                        State
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        LGA
                      </th>

                      <th scope="col" className="px-4 pb-2">
                        Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {cooperativeData.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                          // onClick={() =>
                          //   handleViewComplaint(
                          //     data.farmer.first_name + " " + data.farmer.last_name,
                          //     data.farmer.phone_number,
                          //     data.complaint_status,
                          //     data.user.first_name + " " + data.user.last_name,
                          //     moment(data.complaint_date).format("ll"),
                          //     moment(data.complaint_date).format("LT"),
                          //     data.complaint,
                          //     data.farmer.id,
                          //     data.id
                          //   )
                          // }
                        >
                          <td className="px-4">{data?.name || "N/A"}</td>
                          <td className="px-4">
                            {data?.member_count || "N/A"}
                          </td>
                          <td className="px-4">{data?.agent || "N/A"}</td>
                          <td className="px-4">
                            {data?.phone_number || "N/A"}
                          </td>

                          <td className="px-4">{data?.state || "N/A"}</td>
                          <td className="px-4">{data?.lga || "N/A"}</td>
                          {/* APPROVAL STATUS */}
                          <td className="">
                            <div
                              className={
                                data.status === "approved"
                                  ? "px-4 success-badge"
                                  : "ml-4 px-4 error-badge"
                              }
                            >
                              <p>
                                {data.status === "approved"
                                  ? data?.status
                                  : data?.status}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}

          {!cooperativeLoading && cooperativeData.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={cooperativeTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default FlaggedCooperativeTable;
