import React, { useEffect } from "react";
import Header from "../../components/Header";
import { Card, CardWithStatus } from "../../components/Cards";
import AgentPortfolioTable from "../../tables/Loans";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoanOverviewInfo } from "../../redux/Loans/loanActions";
import { ButtonPrimary } from "../../components/Button/index";

const AgroFastCashPage = () => {
  const dispatch = useDispatch();
  const { overviewInfo } = useSelector((state) => state.loan);

  useEffect(() => {
    dispatch(fetchLoanOverviewInfo());
  }, [dispatch]);

  return (
    <>
      <Header text="Loan Management System">
        <ButtonPrimary href="/create-loan-porfolio">Create Loan</ButtonPrimary>
      </Header>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <Card
          title="Loan Application"
          subtitle={overviewInfo ? overviewInfo.total_loan_applications : 0}
          color="border-l-[#B92043]"
          href="/agrofastcash/loan-application"
          hasStatus
       
        />
        <Card
          title="Active Loans"
          subtitle={overviewInfo ? overviewInfo.active_loans : 0}
          href="/agrofastcash/active-loans"
        />
        <CardWithStatus
          title="Overdue Payment"
          subtitle={overviewInfo ? overviewInfo.overdue_loans : 0}
          href="/agrofastcash/overdue-payment"
          hasStatus
          status="Urgent"
        />
        <Card
          title="Groups"
          subtitle={overviewInfo ? overviewInfo.loan_group_count : 0}
          href="/agrofastcash/groups"
        />
      </div>
      <AgentPortfolioTable />
    </>
  );
};

export default AgroFastCashPage;
