import React, { useEffect, useState } from "react";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { useDispatch, useSelector } from "react-redux";
import { vendorOrders } from "../../redux/Orders/ordersAction";
import moment from "moment";
import EmptyState from "../../components/EmptyState";

const VendorInfoTable = ({ productType, id }) => {
  const [typeID, setTypeID] = useState(1);
  const [typeStatus, setTypeStatus] = useState("");
  const dispatch = useDispatch();
  const { vendorOrdersData, vendorOrdersLoading, vendorOrdersTotal } =
    useSelector((state) => state.orders);
  const orderTypes = [
    {
      id: 1,
      title: "All Orders",
      status: "",
    },
    {
      id: 2,
      title: "Delivered Orders",
      status: "DELIVERED",
    },
    {
      id: 3,
      title: "Orders in Progress",
      status: "PENDING",
    },
    {
      id: 4,
      title: "Undelivered Orders ",
      status: "NOT-DELIVERED",
    },
  ];

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const toggleType = (typeId, status) => {
    setTypeID(typeId);
    setTypeStatus(status);
    dispatch(
      vendorOrders({
        id: id,
        orderType: productType,
        page: 1,
        status: status,
      })
    );
  };

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      vendorOrders({
        id: id,
        orderType: productType,
        page: page,
        status: typeStatus,
      })
    );
  };

  console.log(vendorOrdersData, vendorOrdersLoading, vendorOrdersTotal);

  useEffect(() => {
    dispatch(
      vendorOrders({
        id: id,
        orderType: productType,
        page: 1,
        status: typeStatus,
      })
    );
  }, [dispatch, id, productType, typeStatus]);
  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="w-full">
            <SearchComponent
              placeholder="Search"
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {orderTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id, item.status)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : "bg-[#ECEEEC] rounded-[4px] py-1 px-1 text-[#96A397] font-semibold text-[10px]"
                  }
                >
                  {item.id === typeID ? vendorOrdersTotal : "0"}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          {vendorOrdersLoading ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {vendorOrdersData.length === 0 ? (
                <div>
                  <EmptyState text="No result matching the filter" />
                </div>
              ) : (
                <table className="w-full text-left">
                  <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                    <tr>
                      <th scope="col" className="px-4 py-4 font-semibold ">
                        Order ID
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold ">
                        Amount
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Phone Number
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Date/Time
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Category
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Order Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendorOrdersData?.map((data, index) => {
                      return (
                        <tr
                          className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                          key={index}
                        >
                          <th
                            scope="row"
                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                          >
                            {data.id}
                          </th>
                          <td className="py-4 px-4 text-[#5C715E]">
                            N{data.total_display_price}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {data.farmer.first_name} {data.farmer.last_name}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {data.farmer.phone_number}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {moment(data.created).format("lll")}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {productType}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            <div
                              className={`flex items-center capitalize ${
                                data.delivery_status === "pending" ||
                                data.delivery_status === "PENDING"
                                  ? "pending-badge"
                                  : data.delivery_status === "PROCESSING" ||
                                    data.delivery_status === "processing"
                                  ? "pending-badge"
                                  : data.delivery_status === "DISPATCHED" ||
                                    data.delivery_status === "dispatched"
                                  ? "success-badge "
                                  : data.delivery_status === "DELIVERED" ||
                                    data.delivery_status === "delivered"
                                  ? "success-badge "
                                  : data.delivery_status === "CANCELLED" ||
                                    data.delivery_status === "cancelled"
                                  ? "error-badge"
                                  : " error-badge"
                              } `}
                            >
                              <p className="capitalize">
                                {data.delivery_status?.toLowerCase() ||
                                  "Pending"}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>
      {vendorOrdersData.length !== 0 && (
        <div className="mt-1">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={10}
            totalItems={vendorOrdersTotal}
          />
        </div>
      )}
    </>
  );
};

export default VendorInfoTable;
