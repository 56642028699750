import React, { useState } from "react";
import Header from "../../components/Header";
import GoBack from "../../components/GoBack";
import AccountInformation from "./SalesAgenttabs/AccountInformation";
import SalesAgentCallsTable from "../../tables/Retail/Sales Agent/calls";
import { useParams } from "react-router-dom";
import SalesAgentComplaintsTable from "../../tables/Retail/Sales Agent/complaints";
import SalesAgentSalesTable from "../../tables/Retail/Sales Agent/sales";

const SalesAgent = () => {
  const { id } = useParams();
  const [tab, setTab] = useState("account_information");
  return (
    <div>
      {/* GO BACK */}
      <div>
        <GoBack />
      </div>

      {/* HEADER AND TAB SECTION */}
      <div className="mt-[14px] mb-6">
        <Header
          text="Sales Agent Profile"
          tabs={
            <div className="flex gap-[16px] mt-[16px]">
              <p
                className={
                  tab === "account_information"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("account_information")}
              >
                Account Information
              </p>
              <p
                className={
                  tab === "call_history"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("call_history")}
              >
                Call History
              </p>
              <p
                className={
                  tab === "complaints"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("complaints")}
              >
                Complaints
              </p>
              <p
                className={
                  tab === "sales"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("sales")}
              >
                Sales
              </p>
            </div>
          }
        ></Header>
      </div>

      {/*  */}
      <div>
        {tab === "account_information" ? (
          <div>
            <AccountInformation />
          </div>
        ) : tab === "call_history" ? (
          <div>
            <SalesAgentCallsTable id={id} />
          </div>
        ) : tab === "complaints" ? (
          <div>
            <SalesAgentComplaintsTable id={id} />
          </div>
        ) : tab === "sales" ? (
          <div>
            <SalesAgentSalesTable id={id} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SalesAgent;
