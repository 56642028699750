import React, { useCallback, useEffect, useState, useMemo } from "react";
import DrawerComponent from "../../components/Drawers/drawers";
import SearchComponent from "../../components/Search";
import PaginationComponent from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  getCallRequests,
  searchCallRequests,
  updateCallRequest,
} from "../../redux/CallRequest/requestActions";
import moment from "moment";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";

const CallRequestTable = () => {
  const dispatch = useDispatch();
  const { requests, loader, total, loading } = useSelector(
    (state) => state.request
  );

  const [typeID, setTypeID] = useState(1);
  const callTypes = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Called",
    },
    {
      id: 3,
      title: "Missed",
    },
    {
      id: 4,
      title: "To Call",
    },
  ];

  const call_status = [
    { key: "CALLED", label: "Called" },
    { key: "MISSED", label: "Missed" },
    { key: "TO_CALL", label: "To Call" },
  ];

  const [isClickStatus, setIsClickStatus] = useState(false);
  const [isDrawerStatusOpen, setIsDrawerStatusOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClickStatus = (key, id, e) => {
    e.stopPropagation();
    let summary = requests.find((val) => val.id === id).summary;

    dispatch(
      updateCallRequest({
        status: key,
        summary: summary ? summary : "",
        id: id,
      })
    );
    setIsClickStatus(false);
    setTimeout(() => {
      switch (typeID) {
        case 1:
          dispatch(
            getCallRequests({
              page: 1,
              status: "all",
            })
          );
          break;
        case 2:
          dispatch(
            getCallRequests({
              page: 1,
              status: "CALLED",
            })
          );
          break;
        case 3:
          dispatch(
            getCallRequests({
              page: 1,
              status: "MISSED",
            })
          );
          break;
        case 4:
          dispatch(
            getCallRequests({
              page: 1,
              status: "TO_CALL",
            })
          );
          break;
        default:
          break;
      }
    }, 2000);
  };

  const handleChangeTag = (key, e) => {
    e.stopPropagation();
    setRequestCallStatus(key);
    setIsDrawerStatusOpen(false);
  };

  const handleChangeCallStatus = (e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setIsClickStatus(!isClickStatus);
  };

  const handleChangeDrawerCallStatus = (e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setIsDrawerStatusOpen(!isDrawerStatusOpen);
  };

  const toggleType = (id) => {
    setTypeID(id);
    switch (id) {
      case 1:
        dispatch(
          getCallRequests({
            page: 1,
            status: "all",
          })
        );
        break;
      case 2:
        dispatch(
          getCallRequests({
            page: 1,
            status: "CALLED",
          })
        );
        break;
      case 3:
        dispatch(
          getCallRequests({
            page: 1,
            status: "MISSED",
          })
        );
        break;
      case 4:
        dispatch(
          getCallRequests({
            page: 1,
            status: "TO_CALL",
          })
        );
        break;
      default:
        break;
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const [summaryValue, setSummaryValue] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [requestInfo, setRequestInfo] = useState({});
  const [requestCallStatus, setRequestCallStatus] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    switch (typeID) {
      case 1:
        dispatch(
          getCallRequests({
            page: page,
            status: "all",
          })
        );
        break;
      case 2:
        dispatch(
          getCallRequests({
            page: page,
            status: "CALLED",
          })
        );
        break;
      case 3:
        dispatch(
          getCallRequests({
            page: page,
            status: "MISSED",
          })
        );
        break;
      case 4:
        dispatch(
          getCallRequests({
            page: page,
            status: "TO_CALL",
          })
        );
        break;
      default:
        break;
    }
  };

  const handleSummaryChange = (e) => {
    setSummaryValue(e.target.value);
  };

  const closeOrder = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        switch (typeID) {
          case 1:
            dispatch(
              searchCallRequests({
                val: val,
                status: "all",
              })
            );
            break;
          case 2:
            dispatch(
              searchCallRequests({
                val,
                status: "CALLED",
              })
            );
            break;
          case 3:
            dispatch(
              searchCallRequests({
                val,
                status: "MISSED",
              })
            );
            break;
          case 4:
            dispatch(
              searchCallRequests({
                val,
                status: "TO_CALL",
              })
            );
            break;
          default:
            break;
        }
      }, 750),
    [dispatch, typeID]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const toggleRequest = (id) => {
    setIsDrawerOpen(!isDrawerOpen);
    // find the request selected
    let request = requests.find((val) => val.id === id);
    setRequestInfo(request);
    setSummaryValue(request.summary);
    setRequestCallStatus(request.status);
  };

  const handleSave = () => {
    dispatch(
      updateCallRequest({
        status: requestCallStatus,
        summary: summaryValue,
        id: requestInfo.id,
      })
    );

    setTimeout(() => {
      setIsDrawerOpen(!isDrawerOpen);
      switch (typeID) {
        case 1:
          dispatch(
            getCallRequests({
              page: 1,
              status: "all",
            })
          );
          break;
        case 2:
          dispatch(
            getCallRequests({
              page: 1,
              status: "CALLED",
            })
          );
          break;
        case 3:
          dispatch(
            getCallRequests({
              page: 1,
              status: "MISSED",
            })
          );
          break;
        case 4:
          dispatch(
            getCallRequests({
              page: 1,
              status: "TO_CALL",
            })
          );
          break;
        default:
          break;
      }
    }, 2000);
  };

  useEffect(() => {
    dispatch(
      getCallRequests({
        page: 1,
        status: "all",
      })
    );
  }, [dispatch]);

  return (
    <>
      {/* request info drawer */}
      <DrawerComponent
        title="Request Info"
        show={isDrawerOpen}
        showDrawer={closeOrder}
      >
        <div className="mt-8">
          <div className="flex justify-between items-center">
            <div>
              <h5 className="text-xs text-[#5C715E]">Name</h5>
              <p className="font-medium text-[#5C715E] text-sm mt-1">
                {requestInfo && requestInfo.farmer
                  ? `${requestInfo.farmer.first_name} ${requestInfo.farmer.last_name}`
                  : "N/A"}
              </p>
            </div>
            <div>
              <Link
                to={
                  requestInfo && requestInfo.farmer
                    ? `/farmers-profile/${requestInfo.farmer.id}`
                    : "/farmers-profile/1"
                }
                className="text-[#2B8C34] text-sm font-medium cursor-pointer no-underline"
              >
                Go to Farmer's profile
              </Link>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Phone Number</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.farmer
              ? `${requestInfo.farmer.phone_number}`
              : "N/A"}
          </p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Call Status</h5>
          <div className="relative mt-2">
            <div
              className={
                requestCallStatus === "CALLED"
                  ? "success-badge flex gap-3 items-center cursor-pointer"
                  : requestCallStatus === "MISSED"
                  ? "pending-badge flex gap-3 items-center cursor-pointer"
                  : requestCallStatus === "TO_CALL"
                  ? "error-badge flex gap-3 items-center cursor-pointer"
                  : "na-badge flex gap-3 items-center"
              }
              onClick={(event) => handleChangeDrawerCallStatus(event)}
            >
              <p>
                {requestCallStatus === "CALLED"
                  ? "Called"
                  : requestCallStatus === "MISSED"
                  ? "Missed"
                  : requestCallStatus === "TO_CALL"
                  ? "To Call"
                  : "N/A"}
              </p>
              {requestCallStatus === "CALLED" ? (
                <ExpandMore
                  className="text-primary cursor-pointer"
                  style={{ fontSize: "12px" }}
                />
              ) : requestCallStatus === "MISSED" ? (
                <ExpandMore
                  className="text-[#d5a407] cursor-pointer"
                  style={{ fontSize: "12px" }}
                />
              ) : requestCallStatus === "TO_CALL" ? (
                <ExpandMore
                  className="text-[#b92043] cursor-pointer"
                  style={{ fontSize: "12px" }}
                />
              ) : (
                <ExpandMore
                  className="text-[#96A397] cursor-pointer"
                  style={{ fontSize: "12px" }}
                />
              )}
            </div>
            {isDrawerStatusOpen ? (
              <div
                className="absolute rounded-[8px] p-[8px]  bg-white z-10"
                style={{
                  boxShadow:
                    "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                }}
              >
                {call_status.map((callData, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        requestCallStatus === callData.key
                          ? `px-[16px] py-[12px] pointer-events-none`
                          : `px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer`
                      }
                      onClick={(event) => handleChangeTag(callData.key, event)}
                    >
                      {requestCallStatus === callData.key ? (
                        <p className="font-medium text-sm leading-5 text-[#96A397] pointer-events-none">
                          {callData.label}
                        </p>
                      ) : (
                        <p className="font-medium text-sm leading-5 text-[#344335]">
                          {callData.label}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Request Date</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.created
              ? moment(requestInfo.created).format("Do, MMM YYYY")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Request Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.created
              ? moment(requestInfo.created).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Call Date</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.updated
              ? moment(requestInfo.updated).format("Do, MMM YYYY")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Call Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.updated
              ? moment(requestInfo.updated).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8 flex gap-6">
          <div className="flex items-center">
            <input
              checked
              id="default-radio-1"
              type="radio"
              value=""
              name="default-radio"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0"
            />
            <label
              for="default-radio-1"
              className="ml-2 text-sm font-medium text-[#5C715E]"
            >
              Call summary
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="default-radio-2"
              type="radio"
              value=""
              name="default-radio"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
            />
            <label
              for="default-radio-2"
              className="ml-2 text-sm font-medium text-[#344335]"
            >
              Complaints
            </label>
          </div>
        </div>

        <div className="mt-8">
          <label
            htmlFor="message"
            className="block mb-2 text-xs font-medium text-secondary1"
          >
            Summary
          </label>
          <textarea
            id="message"
            rows="8"
            value={summaryValue}
            onChange={handleSummaryChange}
            className="appearance-none w-full
                placeholder:text-[#96A397] placeholder:text-sm
                    text-[#5C715E]
                    text-sm resize-none
                    focus:border-[#2B8C34] focus:outline-none  
                    focus:shadow-input rounded-lg border border-[#96A397]
                    bg-white py-3 px-4"
            placeholder="Leave a summary..."
          ></textarea>
        </div>

        <div className="flex justify-between mt-4 items-center">
          <div>
            <button
              onClick={handleSave}
              disabled={loading}
              type="button"
              className="text-white bg-primary py-3 px-6
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] font-medium
                         rounded-lg text-sm flex "
            >
              {" "}
              Save
            </button>
          </div>
          <div>
            {/* <p className="text-[#96A397] text-xs font-semibold">
              Edited by <span className="text-[#2B8C34]">Celina Avong</span>
            </p>
            <p className="text-[#96A397] text-xs font-medium">
              02:20 PM | 10 Aug 22
            </p> */}
          </div>
        </div>
      </DrawerComponent>

      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Call Request
          </p>

          {/* search */}
          <div className="mt-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {callTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : ""
                  }
                >
                  {typeID === item.id ? total : ""}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Name
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Phone Number
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Date Created
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Date Updated
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Call Status
                </th>
              </tr>
            </thead>
            {!loader && (
              <tbody>
                {requests.length > 0
                  ? requests.map((item, index) => (
                      <tr
                        key={item.id}
                        onClick={() => toggleRequest(item.id)}
                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      >
                        <th
                          scope="row"
                          className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                        >
                          {item.farmer.first_name} {item.farmer.last_name}
                        </th>

                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.farmer.phone_number}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.created
                            ? moment(item.created).format(
                                "Do, MMM YYYY hh:mm:ss a"
                              )
                            : ""}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.created
                            ? moment(item.updated).format(
                                "Do, MMM YYYY hh:mm:ss a"
                              )
                            : ""}
                        </td>

                        <td className="py-4 px-4">
                          <div className="relative">
                            <div
                              className={
                                item.status === "CALLED"
                                  ? "success-badge flex gap-3 items-center"
                                  : item.status === "MISSED"
                                  ? "pending-badge flex gap-3 items-center"
                                  : item.status === "TO_CALL"
                                  ? "error-badge flex gap-3 items-center"
                                  : "na-badge flex gap-3 items-center"
                              }
                              onClick={(event) =>
                                handleChangeCallStatus(event, index)
                              }
                            >
                              <p>
                                {item.status === "CALLED"
                                  ? "Called"
                                  : item.status === "MISSED"
                                  ? "Missed"
                                  : item.status === "TO_CALL"
                                  ? "To Call"
                                  : "N/A"}
                              </p>
                              {item.status === "CALLED" ? (
                                <ExpandMore
                                  className="text-primary cursor-pointer"
                                  style={{ fontSize: "12px" }}
                                />
                              ) : item.status === "MISSED" ? (
                                <ExpandMore
                                  className="text-[#d5a407] cursor-pointer"
                                  style={{ fontSize: "12px" }}
                                />
                              ) : item.status === "TO_CALL" ? (
                                <ExpandMore
                                  className="text-[#b92043] cursor-pointer"
                                  style={{ fontSize: "12px" }}
                                />
                              ) : (
                                <ExpandMore
                                  className="text-[#96A397] cursor-pointer"
                                  style={{ fontSize: "12px" }}
                                />
                              )}
                            </div>
                            {isClickStatus && selectedIndex === index ? (
                              <div
                                className="absolute rounded-[8px] p-[8px] w-full bg-white z-10"
                                style={{
                                  boxShadow:
                                    "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                                }}
                              >
                                {call_status.map((callData, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        item.status === callData.key
                                          ? `px-[16px] py-[12px] pointer-events-none`
                                          : `px-[16px] py-[12px] hover:bg-[#EDF7EE]`
                                      }
                                      onClick={(event) =>
                                        handleClickStatus(
                                          callData.key,
                                          item?.id,
                                          event
                                        )
                                      }
                                    >
                                      {item.status === callData.key ? (
                                        <p className="font-medium text-sm leading-5 text-[#96A397] pointer-events-none">
                                          {callData.label}
                                        </p>
                                      ) : (
                                        <p className="font-medium text-sm leading-5 text-[#344335] cursor-pointer">
                                          {callData.label}
                                        </p>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            )}
          </table>

          {loader && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>
      {!loader && requests.length > 0 ? (
        <div className="mt-1">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={10}
            totalItems={total}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CallRequestTable;
