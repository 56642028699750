import React from "react";
import { Link } from "react-router-dom";



export const Card = ({ title, subtitle, href, description, bgColor }) => {
  return (
    <>
      <div 
       className={`rounded-lg py-4 px-4 ${
        bgColor ? bgColor : "bg-white"
      }`}
      >
        <h6 
          className={`font-semibold text-sm ${
            bgColor ? 'text-white' : "text-[#96A397]"
          }`}
        >{title}</h6>

        <p 
          className={`font-semibold text-[20px] mt-5 ${
            bgColor ? 'text-white' : "text-[#344335]"
          }`}
          >
          {subtitle}
        </p>

        <p className="mt-1 text-[#96A397] text-xs leading-4 font-medium">
          {description}
        </p>

        <div className="flex justify-end mt-3">
          <Link
            to={href ? href : "/"}
            className="text-[#2B8C34] text-[10px] font-semibold cursor-pointer"
          >
            See more
          </Link>
        </div>
      </div>
    </>
  );
};
export const CardWithoutSeeMore = ({ title, subtitle, href, description }) => {
  return (
    <>
      <div className="bg-white rounded-lg py-4 px-4">
        <h6 className="text-[#96A397] font-semibold text-sm">{title}</h6>

        <p className="text-[#344335] font-semibold text-[20px] mt-5">
          {subtitle}
        </p>

        <p className="mt-1 text-[#96A397] text-xs leading-4 font-medium">
          {description}
        </p>
      </div>
    </>
  );
};

export const CardWithNoLink = ({ title, subtitle, bgColor }) => {
  return (
    <>
      <div className={`rounded-lg py-4 px-4 ${
        bgColor ? bgColor : "bg-white"
       }`}>
        <h6
        className={`font-semibold text-sm ${
          bgColor ? 'text-white' : "text-[#96A397]"
        }`}
         >{title}</h6>

        <p className={`font-semibold text-[20px] mt-6 ${
            bgColor ? 'text-white' : "text-[#344335]"
          }`}>
          {subtitle}
        </p>
      </div>
    </>
  );
};

export const CardWithStatus = ({
  title,
  subtitle,
  href,
  hasStatus,
  status,
}) => {
  return (
    <>
      <div className="bg-white rounded-lg py-4 px-4">
        <div className="flex items-center justify-between">
          <div>
            <h6 className="text-[#96A397] font-semibold text-sm">{title}</h6>
          </div>
          {hasStatus && (
            <div className="bg-[#FDDED4] rounded-[4px] text-[#B92043] text-xs font-medium py-1 px-2">
              <p>{status}</p>
            </div>
          )}
        </div>

        <p className="text-[#344335] font-semibold text-[20px] mt-4">
          {subtitle}
        </p>

        <div className="flex justify-end mt-3">
          <Link
            to={href ? href : "/"}
            className="text-[#2B8C34] text-[10px] font-semibold cursor-pointer"
          >
            See more
          </Link>
        </div>
      </div>
    </>
  );
};

export const CardWithBorder = ({
  title,
  subtitle,
  href,
  description,
  color,
  isLink = true,
}) => {
  return (
    <>
      <div
        className={`bg-white rounded-lg py-4 px-4 border-l-[3px] ${
          color ? color : "border-l-primary"
        }`}
      >
        <h6 className="text-[#96A397] font-semibold text-sm">{title}</h6>

        <p className="text-[#344335] font-semibold text-[20px] mt-5">
          {subtitle}
        </p>

        <p className="mt-1 text-[#96A397] text-xs leading-4 font-medium">
          {description}
        </p>

        {isLink && (
          <div className="flex justify-end mt-3">
            <Link
              to={href ? href : "/"}
              className="text-[#2B8C34] text-[10px] font-semibold cursor-pointer"
            >
              See more
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export const CardWithBorderStatus = ({
  title,
  subtitle,
  href,
  description,
  color,
  isLink = true,
  hasStatus,
  status,
}) => {
  return (
    <>
      <div
        className={`bg-white rounded-lg py-4 px-4 border-l-[3px] ${
          color ? color : "border-l-primary"
        }`}
      >
        <div className="flex items-center justify-between">
          <div>
            <h6 className="text-[#96A397] font-semibold text-sm">{title}</h6>
          </div>
          {hasStatus && (
            <div className="bg-[#FDDED4] rounded-[4px] text-[#B92043] text-xs font-medium py-1 px-2">
              <p>{status}</p>
            </div>
          )}
        </div>

        <p className="text-[#344335] font-semibold text-[20px] mt-5">
          {subtitle}
        </p>

        <p className="mt-1 text-[#96A397] text-xs leading-4 font-medium">
          {description}
        </p>

        {isLink && (
          <div className="flex justify-end mt-3">
            <Link
              to={href ? href : "/"}
              className="text-[#2B8C34] text-[10px] font-semibold cursor-pointer"
            >
              See more
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export const CardWithFilter = ({
  title,
  subtitle,
  children
}) => {
  return (
    <>
      <div className="bg-white rounded-lg py-4 px-4">
        <div className="flex items-center justify-between">
          <div>
            <h6 className="text-[#96A397] font-semibold text-sm">{title}</h6>
          </div>

            {children}
            
        </div>

        <p className="text-[#344335] font-semibold text-[20px] mt-4">
          {subtitle}
        </p>

      </div>
    </>
  );
};
