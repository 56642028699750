import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaginationComponent from "../../../components/Pagination/Pagination";
import TableBackground from "../../../components/TableBackground";
import EmptyState from "../../../components/EmptyState";

import { debounce } from "lodash";
import SearchComponent from "../../../components/Search";
import moment from "moment";
import { getAgentLoanPorfolio } from "../../../redux/Agents/agentsAction";

const LoanPorfolioTable = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const { id } = useParams();

  const {
    agentLoanPorfolioData,
    agentLoanPorfolioTotal,
    agentLoanPorfolioLoading,
  } = useSelector((state) => state.agents);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getAgentLoanPorfolio({
        id: id,
        page: currentPage,
        search: page,
        status: "all",
      })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        dispatch(
          getAgentLoanPorfolio({
            id: id,
            page: currentPage,
            search: val,
            status: "all",
          })
        );
      }, 750),
    [dispatch, currentPage, id]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const getLoanBg = (val) => {
    let result;
    switch (val) {
      case "pending":
        result = "pending-badge";
        break;
      case "inactive":
        result = "error-badge";
        break;
      case "active":
        result = "success-badge";
        break;
      case "completed":
        result = "success-badge";
        break;
      default:
        result = "error-badge";
        break;
    }
    return result;
  };

  const getLoanText = (val) => {
    let result;
    switch (val) {
      case "pending":
        result = "Pending";
        break;
      case "inactive":
        result = "Inactive";
        break;
      case "active":
        result = "Active";
        break;
      case "completed":
        result = "Completed";
        break;
      default:
        result = "Pending";
        break;
    }
    return result;
  };

  useEffect(() => {
    dispatch(
      getAgentLoanPorfolio({
        id: id,
        page: currentPage,
        search: searchValue,
        status: "all",
      })
    );
  }, [dispatch, id, currentPage, searchValue]);

  // const navigate = useNavigate()
  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        <section className="pt-[5px]">
          {agentLoanPorfolioLoading ? (
            <div className="animate-pulse w-full mt-2 mb-4 pb-4 px-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {agentLoanPorfolioData?.length === 0 ? (
                <div>
                  <EmptyState text="There's no data for this filter yet" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        Date Created
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Loan Package
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Amt. Disbursed
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Total Loan Expected
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Amt. Recovered
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Total Arrears
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentLoanPorfolioData.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                        >
                          <td className="px-4">
                            {moment(item.created).format("lll")}
                          </td>
                          <td className="px-4">{item.loan_package}</td>
                          <td className="px-4">
                            ₦{item.amount_disbursed || 0}{" "}
                          </td>
                          <td className="px-4">₦{item.total_loan || 0}</td>
                          <td className="px-4">
                            ₦{item.amount_recovered || 0}
                          </td>
                          <td className="px-4">₦{item.arrears || 0}</td>
                          <td className="px-4">
                            <div className={getLoanBg(item.status)}>
                              <p>{getLoanText(item.status)}</p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}

          {!agentLoanPorfolioLoading && agentLoanPorfolioData.length > 0 ? (
            <div className="mt-1 pl-[-16px]" style={{ paddingLeft: "-16px" }}>
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={agentLoanPorfolioTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default LoanPorfolioTable;
