import React from 'react'
import GoBack from '../../../components/GoBack'
import Header from '../../../components/Header'
import CompleteProfilesTable from '../../../tables/Farmers/CompleteProfilesTable'

const CompleteProfile = () => {
  return (
    <div>
      <div>
        <GoBack />
      </div>

      <div className="mt-[14px] mb-6">
        <Header text="Completed Profiles" />
      </div>

      <div>
        <CompleteProfilesTable />
      </div>
    </div>
  )
}

export default CompleteProfile
