import React, { useState, useMemo, useCallback, useEffect } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { useNavigate } from "react-router-dom";
import { getPointsLeaderBoard } from "../../redux/Points/pointsActions";

const PointsTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { leaderboardData, leaderboardLoading } = useSelector(
    (state) => state.points
  );

  // RETAIL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(getPointsLeaderBoard({ page: page }));
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        dispatch(
          getPointsLeaderBoard({
            page: currentPage,
            value: val,
          })
        );
      }, 750),
    [dispatch, currentPage]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    dispatch(getPointsLeaderBoard({ page: currentPage, value: searchValue }));
  }, [dispatch, searchValue, currentPage]);

  return (
    <div>
      <TableBackground classname={"rounded-lg"}>
        <p className="text-primary font-semibold text-base leading-5 pt-4 px-4">
          Points Leaderboard
        </p>

        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {leaderboardLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th scope="col" className="px-4 pb-2">
                    Name
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Total Points Rewarded
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Current Point Balance
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Total Points Used
                  </th>
                </tr>
              </thead>

              <tbody>
                {leaderboardData?.leaderboard?.data?.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      className="relative cursor-pointer hover:bg-[#edf7ee]"
                      onClick={() => navigate(`/points/${data.farmer.id}`)}
                    >
                      <td className="px-4">
                        {data.farmer.firstname || "N/A"}{" "}
                        {data.farmer.lastname || "N/A"}
                      </td>
                      <td className="px-4">{data.points_awarded || "0"}</td>
                      <td className="px-4">{data.points_remaining || "0"}</td>
                      <td className="px-4">{data.points_used || "0"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {!leaderboardLoading &&
          leaderboardData?.leaderboard?.data?.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={leaderboardData?.leaderboard?.totalRecords}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default PointsTable;
