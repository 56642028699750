import moment from "moment";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaginationComponent from "../../../components/Pagination/Pagination";
import TableBackground from "../../../components/TableBackground";
import { debounce } from "lodash";
import EmptyState from "../../../components/EmptyState";
import { getAgentOrders } from "../../../redux/Agents/agentsAction";
import SearchComponent from "../../../components/Search";

const AgentOrdersTable = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const { agentOrderLoading, agentOrderData, agentOrderTotal } = useSelector(
    (state) => state.agents
  );

  // ALL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getAgentOrders({
        id: id,
        page: page,
        search: searchValue,
        status: status,
      })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        dispatch(
          getAgentOrders({
            id: id,
            page: currentPage,
            search: val,
            status: status,
          })
        );
      }, 750),
    [dispatch, status, currentPage, id]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleStatusChange = (tabStatus) => {
    setStatus(tabStatus);
    dispatch(
      getAgentOrders({
        id: id,
        page: currentPage,
        search: searchValue,
        status: status,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAgentOrders({ page: 1, id: id, search: searchValue, status: status })
    );
  }, [dispatch, id, status, searchValue]);

  return (
    <div>
      <TableBackground classname="">
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4 px-4  pt-2">
          <div className="w-full relative px-0">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS SECTION */}
        <section>
          <div className="flex gap-4 mt-1 mb-5 cursor-pointer pt-4 px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "all" ? agentOrderTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "mechanization"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("mechanization")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Mechanization
                </p>
                <div
                  className={
                    status === "mechanization"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "mechanization" ? agentOrderTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "crop"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("crop")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Crop Input
                </p>
                <div
                  className={
                    status === "crop"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "crop" ? agentOrderTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "livestock"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("livestock")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Livestock
                </p>
                <div
                  className={
                    status === "livestock"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "livestock" ? agentOrderTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "insurance"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("insurance")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Insurance
                </p>
                <div
                  className={
                    status === "insurance"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "insurance" ? agentOrderTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "advisory"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleStatusChange("advisory")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Advisory
                </p>
                <div
                  className={
                    status === "advisory"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "advisory" ? agentOrderTotal : "0"}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {agentOrderLoading ? (
            <div className="animate-pulse w-full mt-4  mb-4 pb-4 px-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {agentOrderData?.length === 0 ? (
                <div>
                  <EmptyState text="There's no data for this filter yet" />
                </div>
              ) : (
                <div className="px-4">
                  <table className="">
                    <thead>
                      <tr>
                        <th scope="col" className="pr-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Product Name
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Category
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          USSD Short Code
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Payment Status
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {agentOrderData.map((data, index) => {
                        const order_status = data?.order_status;
                        let orderStatus = order_status?.at(-1);

                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee] "
                          >
                            <td className="">{data.id}</td>
                            <td className="">
                              {data?.product?.product_name || "N/A"}
                            </td>
                            <td className="">N {data.total_display_price}</td>
                            <td className="">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="">
                              {moment(data.created).format("LT")}
                            </td>
                            <td className="capitalize">
                              {data.product_type?.replace("_", " ")}
                            </td>
                            <td className="">{"N/A"}</td>

                            <td className="">
                              <div
                                className={
                                  data.payment_status
                                    ? "flex items-center success-badge"
                                    : "flex items-center error-badge"
                                }
                              >
                                <p className="capitalize">
                                  {data.payment_status ? "Paid" : "Unpaid"}
                                </p>
                              </div>
                            </td>
                            <td className="">
                              <div
                                className={`flex items-center capitalize ${
                                  orderStatus === "PENDING"
                                    ? "pending-badge"
                                    : orderStatus === "PROCESSING"
                                    ? "pending-badge"
                                    : orderStatus === "DISPATCHED"
                                    ? "success-badge "
                                    : orderStatus === "DELIVERED"
                                    ? "success-badge "
                                    : orderStatus === "CANCELLED"
                                    ? "error-badge "
                                    : "pending-badge"
                                } `}
                              >
                                <p className="capitalize">
                                  {orderStatus?.toLowerCase() || "Pending"}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
          {!agentOrderLoading && agentOrderData?.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={agentOrderTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default AgentOrdersTable;
