import React from "react";
import Header from "../../components/Header";
import GoBack from "../../components/GoBack/index";
import FollowUpTable from "../../tables/Retail/follow up";

const FollowUp = () => {
  return (
    <div>
      <div className="mb-5">
        <GoBack />
      </div>
      <div className="mb-4">
        <Header text="Follow Up" />
      </div>

      <FollowUpTable />
    </div>
  );
};

export default FollowUp;
