import React, { useEffect } from "react";
import Header from "../../components/Header/index";
import Calendar from "../../assets/icons/calendar.svg";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignStats } from "../../redux/Campaign/campaignAction";
import CampaignLogTable from "../../tables/Campaign/CampaignLog";
import GoBack from "../../components/GoBack";

const CampaignLog = () => {
  const dispatch = useDispatch();
  const { campaignStatsData } = useSelector((state) => state.campaign);

  useEffect(() => {
    dispatch(getCampaignStats());
  }, [dispatch]);

  return (
    <div>
      <div className="mb-4">
        <GoBack />
      </div>
      {/* HEDAER SECTION */}
      <section className="mb-5">
        <Header text="Campaign Log">
          <div className="flex gap-10">
            <div className="flex bg-none border-primary text-sm leading-5 font-medium text-primary gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px]">
              <img src={Calendar} alt="Calendar Icon" />
              <p>All time</p>
            </div>
            <Link to="/add-campaign">
              <div className="flex bg-primary border-primary text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer">
                <Add style={{ fontSize: "20px" }} />
                <p>Add campaign</p>
              </div>
            </Link>
          </div>
        </Header>
      </section>

      {/* CARDS SECTION */}
      <div className="grid grid-cols-4 gap-[25px] mb-5">
        {/* 1 */}
        <div
          className={`bg-white rounded-lg py-4 px-4 border-l-[3px] border-l-primary`}
        >
          <h6 className="text-[#96A397] font-semibold text-sm">
            Active Campaign
          </h6>

          <p className="text-[#344335] font-semibold text-[20px] mt-5">
            {campaignStatsData?.active_campaigns}
          </p>
        </div>

        {/* 2*/}
        <div
          className={`bg-white rounded-lg py-4 px-4 border-l-[3px] border-l-[#B8B3A3]`}
        >
          <h6 className="text-[#96A397] font-semibold text-sm">
            Inactive Campaign
          </h6>

          <p className="text-[#344335] font-semibold text-[20px] mt-5">
            {" "}
            {campaignStatsData?.inactive_campaigns}
          </p>
        </div>

        {/* 3 */}
        <div
          className={`bg-white rounded-lg py-4 px-4 border-l-[3px] border-l-[#B92043]`}
        >
          <h6 className="text-[#96A397] font-semibold text-sm">
            Ended Campaign
          </h6>

          <p className="text-[#344335] font-semibold text-[20px] mt-5">
            {" "}
            {campaignStatsData?.ended_campaigns}
          </p>
        </div>

        {/* 4 */}
        <div
          className={`bg-white rounded-lg py-4 px-4 border-l-[3px] border-l-[#FFC508]`}
        >
          <h6 className="text-[#96A397] font-semibold text-sm">
            Archived Campaign
          </h6>

          <p className="text-[#344335] font-semibold text-[20px] mt-5">
            {" "}
            {campaignStatsData?.archived_campaigns}
          </p>
        </div>
      </div>
      {/* TABLE SECTION */}
      <CampaignLogTable />
    </div>
  );
};

export default CampaignLog;
