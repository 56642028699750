import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

/////////////////////////////////////////////// GET ALL RETAIL STATS
export const getRetailStats = createAsyncThunk(
  "getRetailStats",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}sales-agents/stats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////////// GET ALL RETAIL
export const getRetail = createAsyncThunk(
  "getRetail",
  async ({ page }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}sales-agents/assignments?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////////// GET ALL RETAIL
export const getRetailSearch = createAsyncThunk(
  "getRetailSearch",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}sales-agents/assignments?page=${page}&search=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////////// GET ALL RETAIL
export const getCalls = createAsyncThunk(
  "getCalls",
  async ({ status, page }, { rejectWithValue, dispatch }) => {
    if (status === "ALL") {
      try {
        const res = await axios.get(
          `${apiUrl}/sales-agents-calls?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}/sales-agents-calls?call_type=${status}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

/////////////////////////////////////////////// GET ALL RETAIL
export const getCallsSearch = createAsyncThunk(
  "getCallsSearch",
  async ({ status, page }, { rejectWithValue, dispatch }) => {
    if (status === "ALL") {
      try {
        const res = await axios.get(
          `${apiUrl}/sales-agents-calls?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}/sales-agents-calls?call_type=${status}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const getSalesAgentStats = createAsyncThunk(
  "getSalesAgentStats",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}sales-agents/${id}/stats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getSalesAgentCampaign = createAsyncThunk(
  "getSalesAgentCampaign",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}sales-agents/${id}/campaigns`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////////// GET ALL RETAIL
export const getSalesAgentCalls = createAsyncThunk(
  "getSalesAgentCalls",
  async ({ id, status, page }, { rejectWithValue, dispatch }) => {
    if (status === "ALL") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents/${id}/calls?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents/${id}/calls?call_type=${status}&page=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const getSalesAgentComplaints = createAsyncThunk(
  "getSalesAgentComplaints",
  async ({ id, value }, { rejectWithValue, dispatch }) => {
    if (value === "ALL") {
      try {
        const res = await axios.get(`${apiUrl}sales-agents/${id}/complaints`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents/${id}/complaints?complaint_type=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

//////////////////////////////////////////// ALL RETAIL ORDERS SECTION
export const getOrders = createAsyncThunk(
  "getOrders",
  async ({ page, orderType }, { rejectWithValue, dispatch }) => {
    if (orderType === "All") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents-orders?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Input") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents-orders?order_type=crop_input&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Livestock") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents-orders?order_type=livestock&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Insurance") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents-orders?order_type=insurance&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Advisory") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents-orders?order_type=advisory&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Mechanization") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents-orders?order_type=mechanization&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

//////////////////////////////////////////// ALL SALES AGENT ORDERS SECTION
export const getSalesAgentOrders = createAsyncThunk(
  "getSalesAgentOrders",
  async ({ id, page, orderType }, { rejectWithValue, dispatch }) => {
    if (orderType === "All") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents/${id}/orders?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Input") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents/${id}/orders?order_type=input&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Livestock") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents/${id}/orders?order_type=livestock&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Insurance") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents/${id}/orders?order_type=insurance&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Advisory") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents/${id}/orders?order_type=advisory&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Mechanization") {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents/${id}/orders?order_type=mechanization&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

