import React from 'react'
import search_icon from '../../assets/icons/search.svg'

const SearchComponent = ({ searchValue, handleChange, placeholder, style }) => {
  return (
    <>
      <div className="relative">
        <input
          type="text"
          onChange={handleChange}
          value={searchValue}
          className="appearance-none w-full placeholder:text-[#96A397] 
                placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] 
                focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-10"
          placeholder={placeholder}
          style={style}
        />
        <img
          src={search_icon}
          alt="search"
          className="absolute top-1/2 transform -translate-y-1/2 left-4 cursor-pointer"
        />
      </div>
    </>
  )
}

export default SearchComponent
