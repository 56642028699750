import React, { useState, useEffect } from "react";
import { CardWithNoLink } from "../../../components/Cards";
import TableBackground from "../../../components/TableBackground";
import Header from "../../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getSalesAgentCampaign,
  getSalesAgentStats,
} from "../../../redux/Retail/retailAction";
import { useParams } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AccountInformation = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tab, setTab] = useState("all");
  const {
    salesAgentStatsData,
    salesAgentStatsLoading,
    salesAgentCampaignData,
  } = useSelector((state) => state.retail);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "none",
      },
      title: {
        display: false,
        text: "Sales Agent",
      },
    },
  };

  const labels = [
    "Jan",
    "feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Sales",
        data: salesAgentStatsData?.sales_by_month?.map((data) => data),
        borderColor: "#2B8C34",
        backgroundColor: "#2B8C34",
        barPercentage: 1,
        categoryPercentage: 0.6,
      },
      {
        label: "Calls",
        data: salesAgentStatsData?.calls_by_month?.map((data) => data),
        borderColor: "#FFC508",
        backgroundColor: "#FFC508",
        barPercentage: 1,
        categoryPercentage: 0.6,
      },
    ],
  };

  useEffect(() => {
    dispatch(getSalesAgentStats({ id: id }));
    dispatch(getSalesAgentCampaign({ id: id }));
  }, [dispatch, id]);

  return (
    <>
      {salesAgentStatsLoading ? (
        <div className="flex justify-center items-center h-[500px] w-full">
          {" "}
          <p className="font-medium text-sm leading-5">Loading...</p>
        </div>
      ) : (
        <div>
          <div className="flex gap-6 mb-[24px]">
            <div className="w-[35%]">
              <div className="bg-white p-4 rounded-[8px] mb-[27px] h-[402px]">
                <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-4">
                  Account Information
                </p>
                <div className="flex items-center gap-[8px] mb-[24px]">
                  {/* FARMER"S INITIALS */}
                  <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex items-center justify-center">
                    <p className="font-medium text-base leading-6 ">
                      {salesAgentStatsData?.first_name?.charAt(0)}
                      {salesAgentStatsData?.last_name?.charAt(0)}
                    </p>
                  </div>

                  <div className="">
                    <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                      {salesAgentStatsData?.first_name}{" "}
                      {salesAgentStatsData?.last_name}
                    </p>
                    <div className="flex gap-[6px] mt-[4px]">
                      <p className="text-primary font-medium leading-[18px] text-sm">
                        {salesAgentStatsData?.email}
                      </p>
                    </div>
                  </div>
                </div>

                {/* SEX */}
                <div className="mb-[24px]">
                  <p className="text-sm leading-5 font-normal text-[#7C8D7E] pb-[2px]">
                    Sex
                  </p>
                  <p className="text-sm  font-medium leading-5 text-[#344335] pb-[2px]">
                    N/A
                  </p>
                </div>

                {/* AGENT ID */}
                <div className="mb-[24px]">
                  <p className="text-sm leading-5 font-normal text-[#7C8D7E] pb-[2px]">
                    Agent Id
                  </p>
                  <p className="text-sm  font-medium leading-5 text-[#344335] pb-[2px]">
                    {id}
                  </p>
                </div>

                {/* LANGUAGE */}
                <div className="mb-[24px]">
                  <p className="text-sm leading-5 font-normal text-[#7C8D7E] pb-[2px]">
                    Language
                  </p>
                  <p className="text-sm  font-medium leading-5 text-[#344335] pb-[2px]">
                    {salesAgentStatsData?.languages_string}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[65%]">
              {/* CARDS SECTION */}
              <div className="grid grid-cols-3 gap-6 mb-6">
                <div className="bg-[#2B8C34] rounded-lg py-4 px-4">
                  <h6 className="text-[#FFF] font-semibold text-sm">
                    Overall Sales
                  </h6>

                  <p className="text-[#FFF] font-semibold text-[20px] mt-6">
                    {salesAgentStatsData?.lifetime_sales}
                  </p>
                </div>
                <CardWithNoLink
                  title="Calls"
                  subtitle={salesAgentStatsData?.lifetime_calls}
                />
                <CardWithNoLink title="Follow Up" subtitle="0" />
              </div>

              {/* GRAPH SECTION */}
              <div className="">
                <TableBackground classname="h-[272px] rounded-[8px] px-4 pb-4">
                  <div className="flex flex-end justify-end w-full gap-3 ">
                    <div className="flex gap-2 items-center my-4">
                      <div className="w-2 h-2 rounded-full bg-primary "></div>
                      <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E]">
                        Sales
                      </p>
                    </div>

                    <div className="flex gap-2 items-center my-4">
                      <div className="w-2 h-2 rounded-full bg-[#FFC508] "></div>
                      <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E] mr-4">
                        Calls
                      </p>
                    </div>
                  </div>
                  <div className="h-[272px] w-full">
                    <Line
                      options={options}
                      data={data}
                      height="272px"
                      width="200px"
                    />
                  </div>
                </TableBackground>
              </div>
            </div>
          </div>

          <div className="">
            {/* TABS SECTION */}
            <TableBackground classname="rounded-[8px]">
              {/* HEADER AND TAB SECTION */}
              <div className="mb-6">
                <Header
                  text="Campaigns"
                  tabs={
                    <div className="flex gap-[16px] mt-[16px]">
                      <p
                        className={
                          tab === "all"
                            ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                            : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                        }
                        onClick={() => setTab("all")}
                      >
                        All
                      </p>
                      <p
                        className={
                          tab === "active"
                            ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                            : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                        }
                        onClick={() => setTab("active")}
                      >
                        Active
                      </p>
                      <p
                        className={
                          tab === "inactive"
                            ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                            : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                        }
                        onClick={() => setTab("inactive")}
                      >
                        Ended
                      </p>
                    </div>
                  }
                ></Header>
              </div>
            </TableBackground>

            {/* LIST OF CAMPAIGNS SECTION */}
            <div>
              {tab === "all" ? (
                <div>
                  {salesAgentCampaignData.length === 0 ? (
                    <EmptyState text="There's no campaign for this sales agent yet" />
                  ) : (
                    salesAgentCampaignData?.map((data, index) => {
                      return (
                        <>
                          <div key={index} className="">
                            <TableBackground classname="rounded-[8px] px-4 pt-4 mb-6">
                              <p className="text-base font-semibold leading-5 text-[#344335] mb-2">
                                {data.campaign_name}
                              </p>
                              <p className="text-sm font-medium leading-5 text-[#344335] mb-4">
                                {data.description}
                              </p>
                              <div className="flex gap-5">
                                <div className="mb-6">
                                  <p className="text-sm font-semibold leading-[18px] text-[#96A397] mb-1">
                                    Calls Made
                                  </p>
                                  <p className="text-base font-semibold leading-5 text-[#344335]">
                                    {data.calls_made}
                                  </p>
                                </div>

                                <div className="mb-6">
                                  <p className="text-sm font-semibold leading-[18px] text-[#96A397] mb-1">
                                    Sales Made
                                  </p>
                                  <p className="text-base font-semibold leading-5 text-[#344335]">
                                    {data.total_sales}
                                  </p>
                                </div>

                                <div className="mb-6">
                                  <p className="text-sm font-semibold leading-[18px] text-[#96A397] mb-1">
                                    Farmer's assigned
                                  </p>
                                  <p className="text-base font-semibold leading-5 text-[#344335]">
                                    {data.total_farmers}
                                  </p>
                                </div>
                              </div>
                            </TableBackground>
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
              ) : tab === "active" ? (
                <div>
                  {salesAgentCampaignData.length === 0 ? (
                    <EmptyState text="There's no active campaign" />
                  ) : (
                    salesAgentCampaignData?.map((data, index) => {
                      return (
                        <>
                          {data.status === "Active" ? (
                            <div key={index}>
                              <TableBackground classname="rounded-[8px] px-4 pt-4 mb-6">
                                <p className="text-base font-semibold leading-5 text-[#344335] mb-2">
                                  {data.campaign_name}
                                </p>
                                <p className="text-sm font-medium leading-5 text-[#344335] mb-4">
                                  {data.description}
                                </p>
                                <div className="flex gap-5">
                                  <div className="mb-6">
                                    <p className="text-sm font-semibold leading-[18px] text-[#96A397] mb-1">
                                      Calls Made
                                    </p>
                                    <p className="text-base font-semibold leading-5 text-[#344335]">
                                      {data.calls_made}
                                    </p>
                                  </div>

                                  <div className="mb-6">
                                    <p className="text-sm font-semibold leading-[18px] text-[#96A397] mb-1">
                                      Sales Made
                                    </p>
                                    <p className="text-base font-semibold leading-5 text-[#344335]">
                                      {data.total_sales}
                                    </p>
                                  </div>

                                  <div className="mb-6">
                                    <p className="text-sm font-semibold leading-[18px] text-[#96A397] mb-1">
                                      Farmer's assigned
                                    </p>
                                    <p className="text-base font-semibold leading-5 text-[#344335]">
                                      {data.total_farmers}
                                    </p>
                                  </div>
                                </div>
                              </TableBackground>
                            </div>
                          ) : null}
                        </>
                      );
                    })
                  )}
                </div>
              ) : tab === "inactive" ? (
                <div>
                  {salesAgentCampaignData.length === 0 ? (
                    <EmptyState text="There's no inactive campaign" />
                  ) : (
                    salesAgentCampaignData?.map((data, index) => {
                      return (
                        <>
                          {data.status === "Inactive" ? (
                            <div key={index}>
                              <TableBackground classname="rounded-[8px] px-4 pt-4 mb-6">
                                <p className="text-base font-semibold leading-5 text-[#344335] mb-2">
                                  {data.campaign_name}
                                </p>
                                <p className="text-sm font-medium leading-5 text-[#344335] mb-4">
                                  {data.description}
                                </p>
                                <div className="flex gap-5">
                                  <div className="mb-6">
                                    <p className="text-sm font-semibold leading-[18px] text-[#96A397] mb-1">
                                      Calls Made
                                    </p>
                                    <p className="text-base font-semibold leading-5 text-[#344335]">
                                      {data.calls_made}
                                    </p>
                                  </div>

                                  <div className="mb-6">
                                    <p className="text-sm font-semibold leading-[18px] text-[#96A397] mb-1">
                                      Sales Made
                                    </p>
                                    <p className="text-base font-semibold leading-5 text-[#344335]">
                                      {data.total_sales}
                                    </p>
                                  </div>

                                  <div className="mb-6">
                                    <p className="text-sm font-semibold leading-[18px] text-[#96A397] mb-1">
                                      Farmer's assigned
                                    </p>
                                    <p className="text-base font-semibold leading-5 text-[#344335]">
                                      {data.total_farmers}
                                    </p>
                                  </div>
                                </div>
                              </TableBackground>
                            </div>
                          ) : null}
                        </>
                      );
                    })
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountInformation;
