import React,{useState} from 'react'
import GoBack from '../../../components/GoBack';
import Header from '../../../components/Header';
import checked_outline from '../../../assets/icons/checked_outline_grey.svg'
import arrow_down from '../../../assets/icons/arrow_down.svg'
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const CreateLogPage = () => {

    const states = [
        { key: 'eng', label: 'Abia' },
        { key: 'yor', label: 'Oyo' },
        { key: 'hau', label: 'Lagos' },
        { key: 'hau', label: 'Osun' },
        { key: 'hau', label: 'Ondo' },
        { key: 'hau', label: 'Zamfara' },
        { key: 'hau', label: 'Bauchi' },
        ]
    
        const [isStateDrop, setIsStateDrop] = useState(false)
        const [selectedState, setSelectedState] = useState(null)
    
         const handleStateDropdown = () =>{
            setIsStateDrop(!isStateDrop)
        }
    
    const handleClickState = (key, label) =>{
            setIsStateDrop(!isStateDrop)
            setSelectedState(label)
     }

    return ( 
        <>
            <GoBack />
            <div className='mt-4'>
                <Header text="Create New Call Log">
                <div>
                    <button
                        className="w-full py-3 flex items-center justify-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
                        >
                        <p className="text-white font-medium text-sm ml-2">
                        Start Call Log
                        </p>
                    </button>
                    </div>
                </Header>
            </div>
            <div className='mt-4 grid grid-cols-3 gap-4'>
                <div className='col-span-2'>
                     <div className='bg-white py-4 px-4 mt-4 rounded-lg'>
                        <div className='flex justify-between items-center'>
                            <div>
                                <p className='text-[#7C8D7E] text-sm font-semibold'>Farmer Category</p>
                            </div>
                            <div className='cursor-pointer'>
                                <img src={arrow_down} alt="keyboard arrow down" />
                            </div>
                        </div>
                     </div>
                     <div className='bg-white py-4 px-4 mt-4 rounded-lg'>
                        <div className='flex justify-between items-center'>
                            <div>
                                <p className='text-[#7C8D7E] text-sm font-semibold'>Location</p>
                            </div>
                            <div className='cursor-pointer'>
                                <img src={arrow_down} alt="keyboard arrow down" />
                            </div>
                        </div>

                        <div className='mt-4 flex gap-4 max-w-2xl'>
                            <div className='relative flex-1'>
                            <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                                States
                            </label>
                            <div
                                className="flex items-center  cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397]"
                                onClick={handleStateDropdown}
                                >
                                <p>
                                    {selectedState ? selectedState : 'Select State(s)'}
                                </p>
                                        {isStateDrop ? (
                                    <ExpandLess
                                    className="text-[#ABB6AC] cursor-pointer"
                                    style={{ fontSize: '18px' }}
                                    />
                                ) : (
                                    <ExpandMore
                                    className="text-[#ABB6AC] cursor-pointer"
                                    style={{ fontSize: '18px' }}
                                    />
                                )}
                            
                            </div>
                            {isStateDrop && 
                            <div
                            className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10 max-h-[200px] overflow-y-scroll"
                                style={{
                                boxShadow:
                                    '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08',
                                }}
                                >
                                    {states.map((data, index) => {
                                    return (
                                        <div
                                        key={index}
                                        className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                                        onClick={() =>
                                            handleClickState(data.key, data.label)
                                }
                                >
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                    {data.label}
                                </p>
                                </div>
                            )
                            })} 
                            </div>
                                }
                            </div>

                            <div className='relative flex-1'>
                            <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                                Local Government
                            </label>
                            <div
                                className="flex items-center cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397]"
                                onClick={handleStateDropdown}
                                >
                                <p>
                                    {selectedState ? selectedState : 'Select Local Government'}
                                </p>
                                        {isStateDrop ? (
                                    <ExpandLess
                                    className="text-[#ABB6AC] cursor-pointer"
                                    style={{ fontSize: '18px' }}
                                    />
                                ) : (
                                    <ExpandMore
                                    className="text-[#ABB6AC] cursor-pointer"
                                    style={{ fontSize: '18px' }}
                                    />
                                )}
                            
                            </div>
                            {isStateDrop && 
                            <div
                            className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10  max-h-[200px] overflow-y-scroll"
                                style={{
                                boxShadow:
                                    '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08',
                                }}
                                >
                                    {states.map((data, index) => {
                                    return (
                                        <div
                                        key={index}
                                        className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                                        onClick={() =>
                                            handleClickState(data.key, data.label)
                                }
                                >
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                    {data.label}
                                </p>
                                </div>
                            )
                            })} 
                            </div>
                                }
                            </div>

                            </div>
                            <button
                               disabled
                                className="mt-4 mb-4 py-3 disabled:bg-[#ABB6AC] flex items-center justify-center px-6 bg-primary hover:bg-[#24752B] hover:bg-opacity-[0.9] rounded-[8px] text-white text-sm font-medium transition-all"
                            >
                                <p className="text-white font-medium text-sm ml-2">
                                    Add Location
                                </p>
                            </button>

                     </div>
                     <div className='bg-white py-4 px-4 mt-4 rounded-lg'>
                        <div className='flex justify-between items-center'>
                            <div>
                                <p className='text-[#7C8D7E] text-sm font-semibold'>Input Type</p>
                            </div>
                            <div className='cursor-pointer'>
                                <img src={arrow_down} alt="keyboard arrow down" />
                            </div>
                        </div>

                        <div className='mt-4 flex gap-4 max-w-2xl'>
                            <div className='relative flex-1'>
                            <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                                Crop(s)
                            </label>
                            <div
                                className="flex items-center  cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397]"
                                onClick={handleStateDropdown}
                                >
                                <p>
                                    {selectedState ? selectedState : 'Select Crop(s)'}
                                </p>
                                        {isStateDrop ? (
                                    <ExpandLess
                                    className="text-[#ABB6AC] cursor-pointer"
                                    style={{ fontSize: '18px' }}
                                    />
                                ) : (
                                    <ExpandMore
                                    className="text-[#ABB6AC] cursor-pointer"
                                    style={{ fontSize: '18px' }}
                                    />
                                )}
                            
                            </div>
                            {isStateDrop && 
                            <div
                            className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10 max-h-[200px] overflow-y-scroll"
                                style={{
                                boxShadow:
                                    '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08',
                                }}
                                >
                                    {states.map((data, index) => {
                                    return (
                                        <div
                                        key={index}
                                        className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                                        onClick={() =>
                                            handleClickState(data.key, data.label)
                                }
                                >
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                    {data.label}
                                </p>
                                </div>
                            )
                            })} 
                            </div>
                                }
                            </div>

                            <div className='relative flex-1'>
                            <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                                Livestock(s)
                            </label>
                            <div
                                className="flex items-center cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397]"
                                onClick={handleStateDropdown}
                                >
                                <p>
                                    {selectedState ? selectedState : 'Select Livestock(s)'}
                                </p>
                                        {isStateDrop ? (
                                    <ExpandLess
                                    className="text-[#ABB6AC] cursor-pointer"
                                    style={{ fontSize: '18px' }}
                                    />
                                ) : (
                                    <ExpandMore
                                    className="text-[#ABB6AC] cursor-pointer"
                                    style={{ fontSize: '18px' }}
                                    />
                                )}
                            
                            </div>
                            {isStateDrop && 
                            <div
                            className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10  max-h-[200px] overflow-y-scroll"
                                style={{
                                boxShadow:
                                    '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08',
                                }}
                                >
                                    {states.map((data, index) => {
                                    return (
                                        <div
                                        key={index}
                                        className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                                        onClick={() =>
                                            handleClickState(data.key, data.label)
                                }
                                >
                                <p className="font-medium text-sm leading-5 text-[#344335]">
                                    {data.label}
                                </p>
                                </div>
                            )
                            })} 
                            </div>
                                }
                            </div>

                            </div>
                            <button
                               disabled
                                className="mt-4 mb-4 py-3 disabled:bg-[#ABB6AC] flex items-center justify-center px-6 bg-primary hover:bg-[#24752B] hover:bg-opacity-[0.9] rounded-[8px] text-white text-sm font-medium transition-all"
                            >
                                <p className="text-white font-medium text-sm ml-2">
                                    Add Input Type
                                </p>
                            </button>

                     </div>
                     <div className='flex justify-end mt-5'>
                        <div className='flex gap-2'>
                            <button
                            
                                type="submit"
                                disabled={false}
                                className="rounded-lg px-8 py-3 text-[#5C715E] text-sm font-medium transition-all"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={false}
                                className="bg-primary 
                                    disabled:bg-[#ABB6AC] 
                                    rounded-lg px-8 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                            >
                                Create Log
                            </button>
                        </div>           
                     </div>
                </div>
                <div>

                 <div className='bg-white rounded-lg py-4 px-4'>
                         <h6 className='text-[#7C8D7E] text-sm font-semibold'>Current Setting</h6> 
                       <p className='text-[#5C715E] text-xs mt-1'>
                       To see your broadcast estimates, enter the following settings:
                       </p>
                       <div className='mt-4 flex gap-2 items-center'>
                            <p className='text-[#ABB6AC] font-medium text-sm'>Farmer Category</p>
                            <img src={checked_outline}
                             alt="checked_outline" />
                       </div>
                       <div className='mt-2 flex gap-2 items-center'>
                            <p className='text-[#ABB6AC] font-medium text-sm'>Location</p>
                            <img src={checked_outline}
                             alt="checked_outline" />
                       </div>
                       <div className='mt-2 flex gap-2 items-center'>
                            <p className='text-[#ABB6AC] font-medium text-sm'>Crop(s)</p>
                            <img src={checked_outline}
                             alt="checked_outline" />
                       </div>
                       <div className='mt-2 flex gap-2 items-center'>
                            <p className='text-[#ABB6AC] font-medium text-sm'>Language</p>
                            <img src={checked_outline}
                             alt="checked_outline" />
                       </div>
                       <div className='mt-2 flex gap-2 items-center'>
                            <p className='text-[#ABB6AC] font-medium text-sm'>Input Type</p>
                            <img src={checked_outline}
                             alt="checked_outline" />
                       </div>
                       <div className='mt-2 flex gap-2 items-center'>
                            <p className='text-[#ABB6AC] font-medium text-sm'>Agent</p>
                            <img src={checked_outline}
                             alt="checked_outline" />
                       </div>
                    </div>

                    <div className='bg-white rounded-lg py-4 px-4 mt-3'>
                       <h6 className='text-[#7C8D7E] text-sm font-semibold'>Call Log Estimates</h6> 
                       <p className='text-[#5C715E] text-xs mt-1'>
                       Based on your current setting you would be reaching an estimate of:
                       </p>
                       <h6 className='text-[#344335] font-bold mt-2 text-lg'>12,000</h6>
                       <p className='text-[#344335] text-xs font-medium'>Farmers</p>
                    </div>

                </div>

            </div>
        </>
     );
}
 
export default CreateLogPage;