import { createSlice } from '@reduxjs/toolkit'
import { getNotifications, markAsReadNotification  } from './notificationsActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  value: 0,
  loading: false,
  clearLoader : false,
  isCleared: false,
  notifications: [],
  count: 0,
  total: 0
}

const notificationPersistConfig = {
  key: 'notifications',
  storage,
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    resetIsCleared: (state) => {
      state.isCleared = false
    }
  },
  extraReducers: {
      // get notifications ----------
    [getNotifications.pending]: (state) =>{
      state.loading = true
    },
    [getNotifications.fulfilled]: (state, {payload}) =>{
      state.loading = false
      state.notifications = payload.data
      state.count = payload.count
      state.total = payload.total ? payload.total : 0
    },
    [getNotifications.rejected]: (state) =>{
        state.loading = false
    },
    // mark as read notifications
    [markAsReadNotification.pending]: (state) =>{
      state.clearLoader = true
    },
    [markAsReadNotification.fulfilled] : (state, {payload}) =>{
      state.clearLoader = false
      state.isCleared = true
    },
    [markAsReadNotification.rejected]: (state)  =>{
      state.clearLoader = false
    } 
  },
})

const notificationReducer = persistReducer(notificationPersistConfig, notificationsSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement, resetIsCleared } = notificationsSlice.actions

export default notificationReducer
