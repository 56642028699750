import React, { useState, useEffect, useCallback } from "react";
import GoBack from "../../components/GoBack";
import email_white from "../../assets/icons/email_white.svg";
import add_green from "../../assets/icons/add_green.svg";
import arrow_down from "../../assets/icons/arrow_down.svg";
import ModalComponent from "../../components/Modals/modal";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCrops,
  getAllLGA,
  getAllState,
} from "../../redux/Location/locationAction";
import ClearIcon from "@mui/icons-material/Clear";
import {
  fetchFarmersCountByQuery,
  sendBulkSMS,
} from "../../redux/Messages/messageActions";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { clearIsMessageSuccess } from "../../redux/Messages/messages";
import { clearLGA } from "../../redux/Location/Location";

const ComposeMessagePage = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const { stateData, lgaData, cropsData } = useSelector(
    (state) => state.location
  );
  const { loading, estimateCount, smsLoader, isMessageSent } = useSelector(
    (state) => state.message
  );

  const showModal = () => {
    setShow(!show);
  };

  const [isStateDrop, setIsStateDrop] = useState(false);
  const [isLGADrop, setIsLGADrop] = useState(false);
  const [isCropDrop, setIsCropDrop] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateID, setSelectedStateID] = useState(null);
  const [selectedLGA, setSelectedLGA] = useState(null);
  const [selectedLGAID, setSelectedLGAID] = useState(null);
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [smsMessage, setSmsMessage] = useState("");

  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedCrops, setSelectedCrops] = useState([]);

  // TRANSACTION
  const [transactionStatusDateFrom, setTransactionStatusDateFrom] =
    useState(null);
  const [transactionStatusDateTo, setTransactionStatusDateTo] = useState(null);

  ///////////////////////////////Dates
  const [activeStatusDateFrom, setActiveStatusDateFrom] = useState(null);
  const [activeStatusDateTo, setActiveStatusDateTo] = useState(null);

  const [iscropShow, setIsCropShow] = useState(false);
  const [istransactShow, setIsTransactShow] = useState(false);
  const [isUSSDShow, setIsUSSDShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Farmers");

  const handleType = (event) => {
    console.log(event.target.value);
    setSelectedOption(event.target.value);
  };

  const onChangeTransaction = (dates) => {
    const [start, end] = dates;
    setTransactionStatusDateFrom(start);
    setTransactionStatusDateTo(end);
  };

  const onChangeActiveStatus = (dates) => {
    const [start, end] = dates;
    setActiveStatusDateFrom(start);
    setActiveStatusDateTo(end);
  };

  const handleStateDropdown = () => {
    setIsStateDrop(!isStateDrop);
  };

  const handleClickState = (stateName, stateId) => {
    setIsStateDrop(!isStateDrop);
    setSelectedState(stateName);
    setSelectedStateID(stateId);
    setSelectedLGA(null);
    dispatch(
      getAllLGA({
        id: stateId,
      })
    );
  };

  const handleClickLGA = (lgaName, id) => {
    setIsLGADrop(!isLGADrop);
    setSelectedLGA(lgaName);
    setSelectedLGAID(id);
  };

  const handleLGADropdown = () => {
    setIsLGADrop(!isLGADrop);
  };

  const handleCropSelect = (cropName, id) => {
    setIsCropDrop(!isCropDrop);
    setSelectedCrop(cropName);
    if (selectedCrops.some((data) => data.id === id)) {
      return;
    }
    setSelectedCrops((selServices) => [
      ...selServices,
      { name: cropName, id: id },
    ]);

    setTimeout(() => {
      setSelectedCrop(null);
    }, 1000);
  };

  const handleCropDropdown = () => {
    setIsCropDrop(!isCropDrop);
  };

  const handleAddLocation = () => {
    if (selectedLocation.some((data) => data.state === selectedState)) {
      let data = selectedLocation.filter(
        (data) => data.state !== selectedState
      );
      setSelectedLocation(data);
      setSelectedLocation((prev) => [
        ...prev,
        {
          state: selectedState,
          lgas: selectedLGA,
          state_id: Number(selectedStateID),
          lga_id: [Number(selectedLGAID)],
        },
      ]);
    } else {
      setSelectedLocation((prev) => [
        ...prev,
        {
          state: selectedState,
          lgas: selectedLGA,
          state_id: Number(selectedStateID),
          lga_id: [Number(selectedLGAID)],
        },
      ]);
    }

    setSelectedState(null);
    setSelectedLGA(null);
    // clear local government
    dispatch(clearLGA());
  };

  const handleCancelLocation = (state_id) => {
    let ans = selectedLocation.filter(
      (somedData) => somedData.state_id !== state_id
    );
    setSelectedLocation(ans);
  };

  const handleCancelCrop = (id) => {
    let res = selectedCrops.filter((somedData) => somedData.id !== id);
    setSelectedCrops(res);
  };

  const handleGetFarmersCount = useCallback(async () => {
    let state_and_lga = [];
    selectedLocation.map((data) => {
      return state_and_lga.push({ state: data.state_id, lgas: data.lga_id });
    });

    let crops = [];
    selectedCrops.map((data) => {
      return crops.push(data.id);
    });

    const value = {
      state_lga: state_and_lga,
      crops: crops,
      is_active: {
        from: activeStatusDateFrom
          ? moment(activeStatusDateFrom).format()
          : null,
        to: activeStatusDateTo ? moment(activeStatusDateTo).format() : null,
      },
      is_transacted: {
        from: transactionStatusDateFrom
          ? moment(transactionStatusDateFrom).format()
          : null,
        to: transactionStatusDateTo
          ? moment(transactionStatusDateTo).format()
          : null,
      },
    };

    dispatch(
      fetchFarmersCountByQuery({
        state_lga: value.state_lga,
        crops: value.crops,
        is_active: value.is_active,
        is_transacted: value.is_transacted,
      })
    );
  }, [
    activeStatusDateFrom,
    activeStatusDateTo,
    transactionStatusDateFrom,
    transactionStatusDateTo,
    selectedCrops,
    selectedLocation,
    dispatch,
  ]);

  const handleSendMessage = () => {
    let state_and_lga = [];
    selectedLocation.map((data) => {
      return state_and_lga.push({ state: data.state_id, lgas: data.lga_id });
    });

    let crops = [];
    selectedCrops.map((data) => {
      return crops.push(data.id);
    });

    let val = {
      state_lga: state_and_lga,
      crops: crops,
      is_active: {
        from: activeStatusDateFrom
          ? moment(activeStatusDateFrom).format()
          : null,
        to: activeStatusDateTo ? moment(activeStatusDateTo).format() : null,
      },
      is_transacted: {
        from: transactionStatusDateFrom
          ? moment(transactionStatusDateFrom).format()
          : null,
        to: transactionStatusDateTo
          ? moment(transactionStatusDateTo).format()
          : null,
      },
    };

    if (selectedOption === "Farmers") {
      dispatch(
        sendBulkSMS({
          message: smsMessage,
          state_lga: val.state_lga,
          crops: val.crops,
          is_active: val.is_active,
          is_transacted: val.is_transacted,
        })
      );
    } else {
      dispatch(
        sendBulkSMS({
          message: smsMessage,
          state_lga: val.state_lga,
          crops: val.crops,
          is_active: val.is_active,
          is_transacted: val.is_transacted,
          type: "incomplete",
        })
      );
    }
  };

  useEffect(() => {
    if (isMessageSent) {
      navigate("/messages");
      setTimeout(() => {
        dispatch(clearIsMessageSuccess());
      }, 800);
    }
  }, [isMessageSent, dispatch, navigate]);

  const [filterId, setFilterId] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterType] = useState([
    {
      id: 1,
      tag: "crop",
      text: "Crop",
    },
    {
      id: 2,
      tag: "ussdActivity",
      text: "USSD Activity",
    },
    {
      id: 3,
      tag: "transactStatus",
      text: " Transaction Status",
    },
  ]);

  const toggleFilterType = (val) => {
    const { id, text } = val;
    setFilterId(id);
    setSelectedFilter(text);
  };

  const handleApplyFilter = () => {
    setShow(false);
    switch (filterId) {
      // crop
      case 1:
        setIsCropShow(true);
        setIsUSSDShow(false);
        setIsTransactShow(false);
        break;
      // ussd
      case 2:
        setIsUSSDShow(true);
        setIsCropShow(false);
        setIsTransactShow(false);
        break;
      // transaction
      case 3:
        setIsTransactShow(true);
        setIsUSSDShow(false);
        setIsCropShow(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleGetFarmersCount();
  }, [handleGetFarmersCount]);

  useEffect(() => {
    dispatch(getAllState());
    dispatch(getAllCrops());
  }, [dispatch]);

  return (
    <>
      <ModalComponent
        show={show}
        showModal={showModal}
        title="Filters"
        subtitle="You can select more filters here"
      >
        <div className="mt-5 flex gap-4 flex-wrap">
          {filterType.map((val) => (
            <>
              <div
                key={val.id}
                onClick={() => toggleFilterType(val)}
                className={
                  filterId === val.id
                    ? "rounded-[4px] bg-[#EDF7EE] border border-primary py-2 px-4 cursor-pointer"
                    : "rounded-[4px] border border-[#96A397] py-2 px-4 cursor-pointer"
                }
              >
                <p
                  className={
                    filterId === val.id
                      ? "text-primary text-sm font-medium"
                      : "text-[#96A397] text-sm font-medium"
                  }
                >
                  {val.text}
                </p>
              </div>
            </>
          ))}
        </div>

        <div className="flex justify-end mt-5 mb-3">
          <button
            type="submit"
            onClick={handleApplyFilter}
            disabled={selectedFilter === null}
            className="bg-primary 
                        disabled:bg-[#ABB6AC] 
                        rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Apply Filter
          </button>
        </div>
      </ModalComponent>

      <GoBack />
      <div className="bg-white mt-4 rounded-lg py-5 px-4">
        <div className="flex justify-between items-center">
          <div>
            <h6 className="text-primary font-semibold text-lg">
              Compose Message
            </h6>
          </div>
          <div>
            <button
              disabled={smsLoader || smsMessage === ""}
              onClick={handleSendMessage}
              className="w-full py-3 disabled:bg-[#ABB6AC]  flex items-center justify-center px-6 bg-[#2B8C34] rounded-[8px] text-white hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
            >
              <img src={email_white} alt="email icon" />
              <p className="text-white font-medium text-sm ml-2">Send</p>
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 mt-4">
        <div className="col-span-2 ">
          <div className="bg-white py-4 px-4 rounded-lg flex justify-between items-center">
            <p className="text-[#344335] font-semibold text-base">Recipients</p>
            <button
              onClick={() => showModal()}
              className=" py-3 disabled:bg-[#ABB6AC]  flex items-center justify-center px-6 bg-[#EDF7EE] rounded-[8px] text-white text-sm font-medium transition-all"
            >
              <img src={add_green} alt="email icon" />
              <p className="text-[#2B8C34] font-medium text-sm ml-2">
                Add Filters
              </p>
            </button>
          </div>

          <div className="bg-white py-4 px-4 mt-4 rounded-lg">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[#7C8D7E] text-sm font-semibold">
                  User Type
                </p>
              </div>
              <div className="cursor-pointer">
                <img src={arrow_down} alt="keyboard arrow down" />
              </div>
            </div>

            <div className="mt-6 flex gap-6">
              <div className="flex items-center">
                <input
                  id="default-radio-2"
                  type="radio"
                  value="Farmers"
                  name="default-radio"
                  checked={selectedOption === "Farmers"}
                  onChange={handleType}
                  className="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                />
                <label
                  for="default-radio-2"
                  className="ml-2 text-sm font-medium text-[#5C715E]"
                >
                  Farmers
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="default-radio-3"
                  type="radio"
                  value="Incomplete"
                  name="default-radio"
                  checked={selectedOption === "Incomplete"}
                  onChange={handleType}
                  className="w-4 h-4 text-blue-600  cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
                />
                <label
                  for="default-radio-3"
                  className="ml-2 text-sm font-medium text-[#5C715E]"
                >
                  Incomplete Farmers
                </label>
              </div>
            </div>
          </div>

          <div className="bg-white py-4 px-4 mt-4 rounded-lg">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[#7C8D7E] text-sm font-semibold">Location</p>
              </div>
              <div className="cursor-pointer">
                <img src={arrow_down} alt="keyboard arrow down" />
              </div>
            </div>

            <div className="mt-4 flex gap-4 max-w-2xl">
              <div className="relative flex-1">
                <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                  States
                </label>
                <div
                  className="flex items-center  cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#344335]"
                  onClick={handleStateDropdown}
                >
                  <p>{selectedState ? selectedState : "Select State(s)"}</p>
                  {isStateDrop ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>
                {isStateDrop && (
                  <div
                    className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10 max-h-[200px] overflow-y-scroll"
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    {stateData?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                          onClick={() => handleClickState(data.name, data.id)}
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="relative flex-1">
                <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Local Government
                </label>
                <div
                  className="flex items-center cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#344335]"
                  onClick={handleLGADropdown}
                >
                  <p>{selectedLGA ? selectedLGA : "Select Local Government"}</p>
                  {isLGADrop ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>
                {isLGADrop && (
                  <div
                    className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10  max-h-[200px] overflow-y-scroll"
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    {lgaData.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                          onClick={() => handleClickLGA(data.name, data.id)}
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <button
              onClick={handleAddLocation}
              disabled={selectedState === null || selectedLGA === null}
              className="mt-4 mb-4 py-3 disabled:bg-[#ABB6AC] flex items-center justify-center px-6 bg-primary hover:bg-[#24752B] hover:bg-opacity-[0.9] rounded-[8px] text-white text-sm font-medium transition-all"
            >
              <p className="text-white font-medium text-sm ml-2">
                Add Location
              </p>
            </button>

            <div className="flex gap-2">
              {selectedLocation.map((data, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleCancelLocation(data.state_id)}
                  >
                    <div className="bg-[#96A397] text-[#fff] text-xs font-medium px-2 py-2 rounded-[4px] flex gap-1 items-center  justify-center">
                      <p>
                        {data.state} - {data.lgas}
                      </p>
                      <ClearIcon
                        style={{ fontSize: "12px", cursor: "pointer" }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {iscropShow && (
            <div className="bg-white py-4 px-4 mt-4 rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[#7C8D7E] text-sm font-semibold">Crop</p>
                </div>
                <div className="cursor-pointer">
                  <img src={arrow_down} alt="keyboard arrow down" />
                </div>
              </div>

              <div className="mt-4 flex gap-4 max-w-2xl">
                <div className="relative flex-1">
                  <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Crops
                  </label>
                  <div
                    className="flex items-center  cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#344335]"
                    onClick={handleCropDropdown}
                  >
                    <p>{selectedCrop ? selectedCrop : "Select crop(s)"}</p>
                    {isCropDrop ? (
                      <ExpandLess
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    ) : (
                      <ExpandMore
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    )}
                  </div>
                  {isCropDrop && (
                    <div
                      className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10 max-h-[200px] overflow-y-scroll"
                      style={{
                        boxShadow:
                          "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                      }}
                    >
                      {cropsData?.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                            onClick={() => handleCropSelect(data.name, data.id)}
                          >
                            <p className="font-medium text-sm leading-5 text-[#344335]">
                              {data.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="flex-1"></div>
              </div>

              <div className="flex gap-2 mt-3">
                {selectedCrops.map((data, index) => {
                  return (
                    <div key={index} onClick={() => handleCancelCrop(data.id)}>
                      <div className="bg-[#96A397] text-[#fff] text-xs font-medium px-2 py-2 rounded-[4px] flex gap-1 items-center  justify-center">
                        <p>{data.name}</p>
                        <ClearIcon
                          style={{ fontSize: "12px", cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {istransactShow && (
            <div className="bg-white py-4 px-4 mt-4 rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[#7C8D7E] text-sm font-semibold">
                    {" "}
                    Transaction status
                  </p>
                </div>
                <div className="cursor-pointer">
                  <img src={arrow_down} alt="keyboard arrow down" />
                </div>
              </div>

              <div className="mt-4 flex gap-4 max-w-2xl">
                <div className="relative flex-1">
                  <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Transaction status
                  </label>

                  <div className="border items-center mt-1 border-[#abb6ac] flex center h-[46px] gap-2 cursor-pointer px-[12px] py-[16px] rounded-[8px] w-full">
                    <DatePicker
                      selected={transactionStatusDateFrom}
                      onChange={onChangeTransaction}
                      startDate={transactionStatusDateFrom}
                      endDate={transactionStatusDateTo}
                      selectsRange
                      className="w-full h-full outline-none text-[#344335]"
                    />
                    <CalendarTodayIcon
                      className="text-[#96a397]"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                </div>
                <div className="flex-1"></div>
              </div>
            </div>
          )}

          {isUSSDShow && (
            <div className="bg-white py-4 px-4 mt-4 rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[#7C8D7E] text-sm font-semibold">
                    {" "}
                    USSD Activity
                  </p>
                </div>
                <div className="cursor-pointer">
                  <img src={arrow_down} alt="keyboard arrow down" />
                </div>
              </div>

              <div className="mt-4 flex gap-4 max-w-2xl">
                <div className="relative flex-1">
                  <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Active status
                  </label>

                  <div className="border items-center mt-1 border-[#abb6ac] flex center h-[46px] gap-2 cursor-pointer px-[12px] py-[16px] rounded-[8px] w-full">
                    <DatePicker
                      selected={activeStatusDateFrom}
                      onChange={onChangeActiveStatus}
                      startDate={activeStatusDateFrom}
                      endDate={activeStatusDateTo}
                      selectsRange
                      dateFormat="yyyy-MM-dd"
                      className="w-full h-full outline-none text-[#344335]"
                    />
                    <CalendarTodayIcon
                      className="text-[#96a397]"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                </div>
                <div className="flex-1"></div>
              </div>
            </div>
          )}

          <div className="bg-white py-4 px-4 mt-4 rounded-lg flex justify-between items-center">
            <p className="text-[#344335] font-semibold text-base">
              Broadcast Message
            </p>
            <button className=" py-3 disabled:bg-[#ABB6AC]  flex items-center justify-center px-6 bg-[#EDF7EE] rounded-[8px] text-white text-sm font-medium transition-all">
              <img src={add_green} alt="email icon" />
              <p className="text-[#2B8C34] font-medium text-sm ml-2">
                Select Template
              </p>
            </button>
          </div>

          <textarea
            type="text"
            name="description"
            rows="8"
            value={smsMessage}
            onChange={(e) => setSmsMessage(e.target.value)}
            placeholder="Compose your message"
            className="mt-4 appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
          />
        </div>
        <div>
          <div className="bg-white rounded-lg py-4 px-4">
            <h6 className="text-[#7C8D7E] text-sm font-semibold">
              Current Setting
            </h6>
            <p className="text-[#5C715E] text-xs mt-1">
              To see your log estimates, enter the following settings:
            </p>
            <div className="mt-5 flex gap-2 items-center">
              <p className="text-[#344335] font-medium text-sm">User Type</p>
              <CheckCircleOutline
                className="text-primary"
                style={{ fontSize: "16px" }}
              />
            </div>
            <div className="mt-2 flex gap-2 items-center">
              <p
                className={
                  selectedLocation.length !== 0
                    ? "text-[#344335] font-medium text-sm "
                    : "text-[#ABB6AC] font-medium text-sm"
                }
              >
                Location
              </p>
              <CheckCircleOutline
                className={
                  selectedLocation.length !== 0
                    ? "text-primary "
                    : "text-[#ABB6AC]"
                }
                style={{ fontSize: "16px" }}
              />
            </div>
          </div>

          <div className="bg-white rounded-lg py-4 px-4 mt-3">
            <h6 className="text-[#7C8D7E] text-sm font-semibold">
              Call Log Estimates
            </h6>
            <p className="text-[#5C715E] text-xs mt-1">
              Based on your current log setting you would be reaching an
              estimate of:
            </p>
            <h6 className="text-[#344335] font-bold mt-2 text-lg">
              {loading ? (
                <div className={`mt-2`}>
                  <ThreeDots
                    height={40}
                    width={30}
                    color="#4fa94d"
                    visible={true}
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              ) : (
                <p className="font-[700] text-[18px] text-[#344335] leading-[28px]">
                  {estimateCount}
                </p>
              )}
            </h6>
            <p className="text-[#344335] text-xs font-medium">Recipients</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComposeMessagePage;
