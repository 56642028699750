import React from 'react'
import { Form, Formik } from 'formik'
import { forgotPasswordValidator } from '../../validationSchema/validator'
import warning from '../../assets/icons/warning.svg'
import { Link } from 'react-router-dom'
import OnboardingSidebar from '../../components/OnboardingSidebar/sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword } from '../../redux/Auth/authActions'

const ForgotPassword = () => {
  const dispatch = useDispatch()

  const { loading } = useSelector((state) => state.auth)

  const handleSubmit = (values) => {
    dispatch(forgotPassword(values))
  }

  return (
    <>
      <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
        <OnboardingSidebar />
        <div className="w-full md:w-[60%] px-4 md:px-4 flex justify-center flex-col">
          <div className="min-w-full md:min-w-[32rem] mx-auto">
            <h6 className="text-primary font-semibold text-lg">
              Forgotten Your Password?
            </h6>
            <p className="text-[#5C715E] text-sm font-medium max-w-lg">
              Don’t feel bad, it happens to the best of us. Enter your email
              address and we'll send you a link to reset your password.
            </p>

            <Formik
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
              validationSchema={forgotPasswordValidator}
              initialValues={{
                email: '',
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="mt-8" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className={
                        touched.email && errors.email
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.email && errors.email}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-8">
                    <button
                      type="submit"
                      disabled={loading}
                      className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Send Reset Link
                    </button>
                  </div>

                  <div className="text-center mt-3">
                    <p className="text-[#344335] text-sm font-semibold">
                      Remember password?{' '}
                      <Link
                        to="/"
                        className="text-primary font-semibold text-sm"
                      >
                        Back to Login
                      </Link>{' '}
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
