import React from 'react'
import email_icon from '../../assets/icons/email.svg'
import OnboardingSidebar from '../../components/OnboardingSidebar/sidebar'
import { useDispatch } from 'react-redux'
import { clearReset } from '../../redux/Auth/auth'
import { useNavigate } from 'react-router-dom'

const ResetSuccessPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogin = () =>{
        dispatch(clearReset())
        navigate('/')
    }

    return ( 
        <>
          <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
        <OnboardingSidebar />
        <div className="w-full md:w-[60%] px-4 md:px-0 flex justify-center flex-col min-h-screen">
          <div className="max-w-lg mx-auto">

            <div className='flex justify-center'>
                <div className='bg-[#DBFFDF] w-[60px] h-[60px] flex justify-center items-center rounded-full'>
                    <img src={email_icon} alt="email" />
                </div>
            </div>
          
            <h6 className="text-primary font-semibold text-lg text-center mt-4">
                Your Password Has Been Reset
            </h6>
            <p className="text-[#5C715E] text-sm font-medium max-w-lg text-center mt-2">
            Your password has been successufully reset, you can access your account by clicking the button
            </p>
            <div className='mt-6'>
             <button onClick={handleLogin}
                      type="submit"
                      disabled={false}
                      className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Log In
                    </button>
            </div>
          </div>
        </div>
      </div>
        </>
     );
}
 
export default ResetSuccessPage;