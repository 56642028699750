import React from "react";
import Header from "../../components/Header";
import TableBackground from "../../components/TableBackground";
import GoBack from "../../components/GoBack";
import {
  Bronze,
  Copper,
  Gold,
  Plantinum,
  Silver,
} from "../../components/Ranks/Rank";
import { ContentCopy } from "@mui/icons-material";
import cogoToast from "cogo-toast";
import gradient from "../../assets/images/gradient.png";
import PointTable from "../../tables/Points/point";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const Point = () => {
  // const dispatch = useDispatch();
  const { pointLeaderboard } = useSelector((state) => state.points);
  const { id } = useParams();

  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  // useEffect(() => {
  //   dispatch(getPointStats({ page: 1 }));
  // }, [dispatch]);

  // console.log(pointLeaderboard);

  return (
    <div>
      <div className="mb-5">
        <GoBack />
      </div>
      <Header text="Point Leaderboard"></Header>

      {/* CARDS SECTION */}
      <section className="mt-7">
        <TableBackground classname={"px-4 pt-4 pb-6 rounded-lg"}>
          <div className="flex justify-between">
            <div>
              <div className="flex items-center gap-[8px] mb-[24px]">
                {/* FARMER"S INITIALS */}

                {pointLeaderboard?.farmerDetails?.rank === "Plantinum" ? (
                  <Plantinum>
                    {" "}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.first_name?.charAt(0)}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.last_name?.charAt(0)}
                  </Plantinum>
                ) : pointLeaderboard?.farmerDetails?.rank === "Gold" ? (
                  <Gold>
                    {" "}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.first_name.charAt(0)}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.last_name.charAt(0)}
                  </Gold>
                ) : pointLeaderboard?.farmerDetails?.rank === "Silver" ? (
                  <Silver>
                    {" "}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.first_name.charAt(0)}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.last_name.charAt(0)}
                  </Silver>
                ) : pointLeaderboard?.farmerDetails?.rank === "Bronze" ? (
                  <Bronze>
                    {" "}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.first_name.charAt(0)}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.last_name.charAt(0)}
                  </Bronze>
                ) : pointLeaderboard?.farmerDetails?.rank === "Copper" ? (
                  <Copper>
                    {" "}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.first_name.charAt(0)}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.last_name.charAt(0)}
                  </Copper>
                ) : null}

                <div className="">
                  <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.first_name}{" "}
                    {pointLeaderboard?.farmerDetails &&
                      pointLeaderboard?.farmerDetails?.last_name}
                  </p>
                  <div
                    className="flex gap-[6px] mt-[6px] cursor-pointer"
                    onClick={() =>
                      handleCopyNumber(
                        pointLeaderboard?.farmerDetails &&
                          pointLeaderboard?.farmerDetails?.phone_number
                      )
                    }
                  >
                    <p
                      className={`  ${
                        pointLeaderboard?.farmerDetails?.rank === "Plantinum"
                          ? "text-[#4B4E68]"
                          : pointLeaderboard?.farmerDetails?.rank === "Gold"
                          ? "text-[#F9B43B]"
                          : pointLeaderboard?.farmerDetails?.rank === "Silver"
                          ? "text-[#4B7295]"
                          : pointLeaderboard?.farmerDetails?.rank === "Bronze"
                          ? "text-[#E86454]"
                          : pointLeaderboard?.farmerDetails?.rank === "Copper"
                          ? "text-[#6A1505]"
                          : null
                      }font-semibold leading-[18px] text-sm`}
                    >
                      {pointLeaderboard?.farmerDetails &&
                        pointLeaderboard?.farmerDetails?.phone_number}
                    </p>
                    <ContentCopy
                      className={`  ${
                        pointLeaderboard?.farmerDetails?.rank === "Plantinum"
                          ? "text-[#4B4E68]"
                          : pointLeaderboard?.farmerDetails?.rank === "Gold"
                          ? "text-[#F9B43B]"
                          : pointLeaderboard?.farmerDetails?.rank === "Silver"
                          ? "text-[#4B7295]"
                          : pointLeaderboard?.farmerDetails?.rank === "Bronze"
                          ? "text-[#E86454]"
                          : pointLeaderboard?.farmerDetails?.rank === "Copper"
                          ? "text-[#6A1505]"
                          : null
                      }`}
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Link
                to={`/farmers-profile/${
                  pointLeaderboard?.farmerDetails &&
                  pointLeaderboard?.farmerDetails?.id
                }`}
              >
                <p className="font-medium text-sm leading-[20px] text-[#2B8C34] cursor-pointer">
                  Go to farmer’s profile
                </p>
              </Link>
            </div>
          </div>

          {/* CARDS */}
          <div className="mt-0 flex gap-5 w-full">
            <div className="relative">
              <div className="absolute">
                <img src={gradient} alt="gradient" className="" />
              </div>
              <div className="bg-[white] border border-[#A7EAAD] rounded-lg py-3 px-4 w-[272px] h-[80px]">
                <h6 className="text-[#96A397] font-semibold text-sm">
                  Current Points Balance
                </h6>

                <p className="text-[#344335] font-semibold text-[20px] mt-1">
                  {pointLeaderboard?.pointStats?.pointStats[0]?.point_balance?.toLocaleString() ||
                    0}
                </p>
              </div>
            </div>

            <div className="bg-[white] border border-[#ECEFEC] rounded-lg py-3 px-4 w-[272px] h-[80px]">
              <h6 className="text-[#96A397] font-semibold text-sm">
                Naira Equivalent
              </h6>

              <p className="text-[#344335] font-semibold text-[20px] mt-1">
                ₦
                {pointLeaderboard?.pointStats?.pointStats[0]?.naira_equivalent?.toLocaleString() ||
                  0}
              </p>
            </div>
          </div>
        </TableBackground>
      </section>

      {/* GRAPH/PROGRESS BAR */}
      <section className="mt-7">
        <TableBackground classname={"px-4 pt-4 pb-6 rounded-lg"}>
          <div className="flex gap-14">
            <div>
              <p className="font-semibold text-sm leading-[18px] text-[#5C715E] mb-3">
                Total Awarded Points
              </p>
              <p className="font-semibold text-[30px] leading-[36px] text-[#344335]  mb-7">
                {pointLeaderboard?.pointStats?.pointStats[0]?.total_awarded_points?.toLocaleString() ||
                  0}
              </p>
            </div>

            <div>
              <p className="font-semibold text-sm leading-[18px] text-[#5C715E] mb-3">
                Total Point Usage
              </p>
              <p className="font-semibold text-[30px] leading-[36px] text-[#344335]  mb-7">
                {pointLeaderboard?.pointStats?.pointStats[0]?.total_point_usage?.toLocaleString() ||
                  0}
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            {pointLeaderboard?.pointStats?.actions?.map((data, index) => {
              return (
                <div key={index} className="flex w-full">
                  <div className="w-[20%]">
                    <p className="font-semibold text-sm leading-[18px] text-[#5C715E]">
                      {data?.action}
                    </p>
                  </div>
                  <div
                    style={{
                      width: `${
                        (data?.total_points / data?.max_attainable) * 100
                      }%`,
                    }}
                  >
                    <div
                      className={`w-full border-r-[4px]  h-6 flex items-center ${
                        data?.action === "Credit"
                          ? "text-[#D4A407] border-r-[#D4A407]  bg-[#FFF3CE]"
                          : data?.action === "Airtime"
                          ? "text-primary border-r-primary bg-[#DBFFDF]"
                          : data?.action === "Referral Reward(Referree)"
                          ? "text-[#004C92] border-r-[#004C92] bg-[#E7FBFE]"
                          : data?.action === "Input Purchase"
                          ? "text-[#2661FB] border-r-[#2661FB] bg-[#EDF2FE]"
                          : null
                      }`}
                    >
                      <p className="font-semibold text-sm leading-[18px]  pl-[9px]">
                        {data?.total_points || "0"}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </TableBackground>
      </section>

      {/* TABLE SECTION */}
      <section className="mt-7">
        <PointTable id={id} />
      </section>
    </div>
  );
};

export default Point;
