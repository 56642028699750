import { createSlice } from "@reduxjs/toolkit";
import {
  cooperativeApproval,
  getACooperative,
  getCooperatives,
  getCooperativesStats,
} from "./cooperativeAction";

const initialState = {
  cooperativeData: [],
  cooperativeTotal: 0,
  cooperativeLoading: false,

  cooperativeStats: {},
  cooperativeStatsLoading: false,

  cooperativeInfoData: {},
  cooperativeInfoLoading: false,

  approvalStatusLoading: false,
};

export const cooperativeSlice = createSlice({
  name: "cooperatives",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
  extraReducers: {
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////// GET COOPERATIVES  ----------
    [getCooperatives.pending]: (state) => {
      state.cooperativeLoading = true;
    },
    [getCooperatives.fulfilled]: (state, { payload }) => {
      state.cooperativeLoading = false;
      state.cooperativeData = payload.data;
      state.cooperativeTotal = payload.total;
    },
    [getCooperatives.rejected]: (state) => {
      state.cooperativeLoading = false;
    },

    ////////////////////////////////////// GET COOPERATIVES STATS  ----------
    [getCooperativesStats.pending]: (state) => {
      state.cooperativeStatsLoading = true;
    },
    [getCooperativesStats.fulfilled]: (state, { payload }) => {
      state.cooperativeStatsLoading = false;
      state.cooperativeStats = payload;
    },
    [getCooperativesStats.rejected]: (state) => {
      state.cooperativeStatsLoading = false;
    },

    ////////////////////////////////////// GET COOPERATIVE  ----------
    [getACooperative.pending]: (state) => {
      state.cooperativeInfoLoading = true;
    },
    [getACooperative.fulfilled]: (state, { payload }) => {
      state.cooperativeInfoLoading = false;
      state.cooperativeInfoData = payload.data;
    },
    [getACooperative.rejected]: (state) => {
      state.cooperativeInfoLoading = false;
    },

    ////////////////////////////////////// APPROVE COOPERATIVE  ----------
    [cooperativeApproval.pending]: (state) => {
      state.approvalStatusLoading = true;
    },
    [cooperativeApproval.fulfilled]: (state, { payload }) => {
      state.approvalStatusLoading = false;
    },
    [cooperativeApproval.rejected]: (state) => {
      state.approvalStatusLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement } = cooperativeSlice.actions;

export default cooperativeSlice.reducer;
