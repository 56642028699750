import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ModalComponent from "../../../../components/Modals/modal";
import {
  getFarmersProfile,
  resetBVN,
} from "../../../../redux/Farmers/farmersActions";
import cogoToast from "cogo-toast";

const ResetBVN = (id) => {
  const dispatch = useDispatch();
  const [isClickSuspendFarmer, setIsClickSuspendFarmer] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickSuspendFarmer = (e) => {
    e.stopPropagation();
    setIsClickSuspendFarmer(!isClickSuspendFarmer);
  };

  const handleReset = () => {
    setLoading(true);
    dispatch(
      resetBVN({
        id: id.id,
      })
    ).then((res) => {
      if (res.type === "resetBVN/fulfilled") {
        dispatch(getFarmersProfile({ id: id.id }));
        setLoading(false);
        setIsClickSuspendFarmer(false);
      } else if (res.type === "resetBVN/rejected") {
        setIsClickSuspendFarmer(false);
        if (res.payload.message) {
          cogoToast.error(res.payload.message);
        } else {
          cogoToast.error("Something went wrong");
        }
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <div>
        {" "}
        <p
          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
          onClick={(event) => handleClickSuspendFarmer(event)}
        >
          Reset BVN
        </p>
      </div>
      {/* SUSPEND FARMER MODAL */}
      <ModalComponent
        title=" Reset BVN"
        show={isClickSuspendFarmer}
        showModal={() => setIsClickSuspendFarmer(false)}
        subtitle="Are you sure you want to reset the farmer's BVN"
      >
        <div className="pt-[16px]">
          <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
            <p
              className="text-sm text-medium leading-5 text-[#5C715E]"
              onClick={() => setIsClickSuspendFarmer(false)}
            >
              No
            </p>
            <button
              disabled={loading}
              className="outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white disabled:bg-[#ABB6AC] disabled:cursor-not-allowed"
              onClick={handleReset}
            >
              Yes
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ResetBVN;
