import React from "react";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import SalesTable from "../../tables/Retail/Sales";

const Sales = () => {
  return (
    <div>
      {" "}
      {/* GO BACK */}
      <div className="mb-5">
        <GoBack />
      </div>
      {/* HEADER */}
      <Header text="Sales" />
      {/* TABLE */}
      <div className="my-5">
        <SalesTable />
      </div>
    </div>
  );
};

export default Sales;
