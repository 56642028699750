import mixpanel from 'mixpanel-browser'


mixpanel.init('74c78e36f9ef82e88c98bcf051b44052', {debug: true, ignore_dnt: true});

let env_check = process.env.NODE_ENV === 'production';
// let env_check = process.env.NODE_ENV === 'development';

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;