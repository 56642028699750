import React, { useEffect, useState } from "react";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import copy_icon from "../../assets/icons/copy_black.svg";
import ModalComponent from "../../components/Modals/modal";
import { Form, Formik } from "formik";
import { refundApplicationValidator } from "../../validationSchema/validator";
import warning from "../../assets/icons/warning.svg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnOrder,
  requestRefund,
  updateAnOrder,
} from "../../redux/Orders/ordersAction";
import cogoToast from "cogo-toast";
import moment from "moment";
import {
  Bronze,
  Copper,
  Gold,
  Plantinum,
  Silver,
} from "../../components/Ranks/Rank";

import { ContentCopy } from "@mui/icons-material";

const OrderInfoPage = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const { orderLoading, orderData } = useSelector((state) => state.orders);
  const [show, setShow] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [orderType, setOrderType] = useState("");

  const showModal = (orderData) => {
    setOrderType(orderData);
    setShow(!show);
  };

  const showRejectModal = (orderData) => {
    setOrderType(orderData);
    setRejectModal(!rejectModal);
  };

  const showDeliveryModal = () => {
    setDeliveryModal(!deliveryModal);
  };

  const showHistoryModal = () => {
    setHistoryModal(!historyModal);
  };

  const showRefundModal = () => {
    setRefundModal(!refundModal);
  };

  const handleSubmit = (values) => {
    const typeValueMap = {
      Input: {
        input_order_id: orderData?.id,
      },
      Livestock: {},
      Advisory: {
        advisory_order_id: orderData?.id,
      },
      Mechanization: {
        mechanization_order_id: orderData?.id,
      },
      Insurance: {
        insurance_order_id: orderData?.id,
      },
    };

    const typeValue = typeValueMap[type] || {};

    const value = {
      ...typeValue,
      refund_amount: values.cost,
      refund_reason: values.description,
    };

    dispatch(requestRefund({ value }));

    setTimeout(() => {
      dispatch(
        getAnOrder({
          orderType: type,
          id: id,
        })
      );
    }, 800);
  };

  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  const handleChangeOrderType = () => {
    let userId = localStorage.getItem("userId");
    let orderStatus;
    if (orderType === "pending") {
      orderStatus = ["PENDING", "PROCESSING"];
    } else if (orderType === "processing") {
      orderStatus = ["PENDING", "PROCESSING", "DISPATCHED"];
    } else if (orderType === "dispatched") {
      orderStatus = ["PENDING", "PROCESSING", "DISPATCHED", "DELIVERED"];
    }
    
    dispatch(updateAnOrder(
      {
         orderStatus, 
         type, 
         id,
         userId,
         farmerName: orderData.farmer.first_name + ' ' + orderData.farmer.last_name,
         farmerPhonenumber: orderData.farmer.phone_number,
         productName:  type === "Input" ? orderData.input_product.name || "N/A" : type === "Advisory"
         ? orderData.advisory_product.name || "N/A"
         : type === "Mechanization"
         ? orderData.mechanization_product.name || "N/A"
         : type === "Insurance"
         ? orderData.insurance_product.name || "N/A"
         : type === "Livestock"
         ? orderData.input_product.name || "N/A"
         : "N/A",
         price: orderData.total_display_price || "N/A"
      }));

    setTimeout(() => {
      setShow(false);
      dispatch(
        getAnOrder({
          orderType: type,
          id: id,
        })
      );
    }, 800);
  };

  const handleRejectChangeStatus = () => {
    let userId = localStorage.getItem("userId");
    let orderStatus;
    if (orderType === "pending") {
      orderStatus = ["PENDING", "CANCELLED"];
    } else if (orderType === "processing") {
      orderStatus = ["PENDING", "PROCESSING", "CANCELLED"];
    } else if (orderType === "dispatched") {
      orderStatus = ["PENDING", "PROCESSING", "DISPATCHED", "CANCELLED"];
    }
    dispatch(updateAnOrder(
      {
         orderStatus, 
         type, 
         id,
         userId,
         farmerName: orderData.farmer.first_name + ' ' + orderData.farmer.last_name,
         farmerPhonenumber: orderData.farmer.phone_number,
         productName:  type === "Input" ? orderData.input_product.name || "N/A" : type === "Advisory"
         ? orderData.advisory_product.name || "N/A"
         : type === "Mechanization"
         ? orderData.mechanization_product.name || "N/A"
         : type === "Insurance"
         ? orderData.insurance_product.name || "N/A"
         : type === "Livestock"
         ? orderData.input_product.name || "N/A"
         : "N/A",
         price: orderData.total_display_price || "N/A"
      }));

    setTimeout(() => {
      setRejectModal(false);
      dispatch(
        getAnOrder({
          orderType: type,
          id: id,
        })
      );
    }, 800);
  };

  useEffect(() => {
    dispatch(
      getAnOrder({
        orderType: type,
        id: id,
      })
    );
  }, [dispatch, id, type]);

  return (
    <div>
      {orderLoading ? (
        <div className="flex justify-center items-center h-[500px] w-full">
          {" "}
          <p className="font-medium text-sm leading-5">Loading...</p>
        </div>
      ) : (
        <div>
          <GoBack />
          <div className="mt-4">
            <Header text="Order Info" />
          </div>
          <div className="mt-4 grid grid-cols-3 gap-4">
            <div className="rounded-lg py-4 px-4 bg-white">
              <p className="text-[#7C8D7E] font-semibold text-sm">
                Farmer Info
              </p>

              {/* FARMER INFO */}
              <div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-[8px] mt-[19px]">
                    {/* FARMER"S INITIALS */}

                    {orderData?.rank === "Plantinum" ? (
                      <Plantinum>
                        {" "}
                        {orderData?.farmer?.first_name?.charAt(0)}
                        {orderData?.farmer?.last_name?.charAt(0)}
                      </Plantinum>
                    ) : orderData?.rank === "Gold" ? (
                      <Gold>
                        {" "}
                        {orderData?.farmer?.first_name?.charAt(0)}
                        {orderData?.farmer?.last_name?.charAt(0)}
                      </Gold>
                    ) : orderData?.rank === "Silver" ? (
                      <Silver>
                        {" "}
                        {orderData?.farmer?.first_name?.charAt(0)}
                        {orderData?.farmer?.last_name?.charAt(0)}
                      </Silver>
                    ) : orderData?.rank === "Bronze" ? (
                      <Bronze>
                        {" "}
                        {orderData?.farmer?.first_name?.charAt(0)}
                        {orderData?.farmer?.last_name?.charAt(0)}
                      </Bronze>
                    ) : orderData?.rank === "Copper" ? (
                      <Copper>
                        {" "}
                        {orderData?.farmer?.first_name?.charAt(0)}
                        {orderData?.farmer?.last_name?.charAt(0)}
                      </Copper>
                    ) : (
                      <Copper>
                        {" "}
                        {orderData?.farmer?.first_name?.charAt(0)}
                        {orderData?.farmer?.last_name?.charAt(0)}
                      </Copper>
                    )}

                    <div className="">
                      <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                        {orderData?.farmer?.first_name}{" "}
                        {orderData?.farmer?.last_name}
                      </p>
                      <div
                        className="flex gap-[6px] mt-[4px] cursor-pointer"
                        onClick={() =>
                          handleCopyNumber(orderData?.farmer?.phone_number)
                        }
                      >
                        <p
                          className={`  ${
                            orderData?.rank === "Plantinum"
                              ? "text-[#4B4E68]"
                              : orderData?.rank === "Gold"
                              ? "text-[#F9B43B]"
                              : orderData?.rank === "Silver"
                              ? "text-[#4B7295]"
                              : orderData?.rank === "Bronze"
                              ? "text-[#E86454]"
                              : orderData?.rank === "Copper"
                              ? "text-[#6A1505]"
                              : null
                          }font-semibold leading-[18px] text-sm`}
                        >
                          {orderData?.farmer?.phone_number}
                        </p>
                        <ContentCopy
                          className={`  ${
                            orderData?.rank === "Plantinum"
                              ? "text-[#4B4E68]"
                              : orderData?.rank === "Gold"
                              ? "text-[#F9B43B]"
                              : orderData?.rank === "Silver"
                              ? "text-[#4B7295]"
                              : orderData?.rank === "Bronze"
                              ? "text-[#E86454]"
                              : orderData?.rank === "Copper"
                              ? "text-[#6A1505]"
                              : null
                          }`}
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-[#7C8D7E] text-sm">Order ID</p>
                <div className="flex gap-1 items-center">
                  <h6 className="text-[#344335] font-medium text-sm">
                    {orderData?.id || "N/A"}
                  </h6>
                  <img
                    src={copy_icon}
                    alt="copy icon"
                    onClick={() => {
                      navigator.clipboard.writeText(orderData?.id);
                      cogoToast.success(
                        `Order Id(${orderData?.id}) copied successfully`
                      );
                    }}
                  />
                </div>
              </div>

              <div className="mt-3">
                <p className="text-[#7C8D7E] text-sm">USSD Short Code</p>
                <div className="flex gap-1 items-center">
                  <h6 className="text-[#344335] font-medium text-sm">
                    {orderData?.short_code || "N/A"}
                  </h6>
                  <img
                    src={copy_icon}
                    alt="copy icon"
                    onClick={
                      orderData?.short_code
                        ? () => {
                            navigator.clipboard.writeText(
                              orderData?.short_code
                            );
                            cogoToast.success(
                              `Order Id(${orderData?.short_code}) copied successfully`
                            );
                          }
                        : null
                    }
                  />
                </div>
              </div>

              <div className="mt-3">
                <p className="text-[#7C8D7E] text-sm">Payment Status</p>
                <div className="flex mt-1">
                  <div
                    className={`${
                      orderData?.payment_status
                        ? "complete-badge"
                        : "error-badge"
                    }`}
                  >
                    <p>{orderData?.payment_status ? "Paid" : "Unpaid"}</p>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <p className="text-[#7C8D7E] text-sm">Delivery Address</p>
                <div className="">
                  <p className="text-[#344335] font-medium text-sm">
                    {orderData?.delivery_location || "N/A"}
                  </p>
                  {/* <p className="text-[#344335] font-medium text-sm">
                    {orderData?.state || "N/A"} | {orderData?.lga || "N/A"}
                  </p> */}
                </div>
              </div>

              {/* <div className="mt-1">
                <button className="text-[#2B8C34] text-sm font-medium">
                  Edit Location
                </button>
              </div> */}
            </div>
            <div className="col-span-2">
              <div className="bg-white rounded-lg py-4 px-4 flex justify-between items-center">
                <div>
                  <p className="text-[#7C8D7E] text-sm font-semibold">
                    Total Amount
                  </p>
                  <h6 className="text-[#2B8C34] font-semibold text-lg">
                    ₦{orderData?.total_display_price?.toLocaleString() || "N/A"}
                  </h6>
                </div>

                {/* {orderData?.order_status?.at(-1) !== "DELIVERED" && (
                  <div>
                    <button
                      onClick={() => showRefundModal()}
                      className="py-3 disabled:bg-[#ABB6AC]  flex items-center justify-center px-5 bg-[#EDF7EE] rounded-[8px] text-white text-sm font-medium transition-all"
                    >
                      <p className="text-[#2B8C34] font-medium text-sm ml-2">
                        Request Refund
                      </p>
                    </button>
                  </div>
                )} */}
                <div>
                  {orderData?.payment_status && (
                    <button
                      onClick={() => showRefundModal()}
                      className="py-3 disabled:bg-[#ABB6AC]  flex items-center justify-center px-5 bg-[#EDF7EE] rounded-[8px] text-white text-sm font-medium transition-all"
                    >
                      <p className="text-[#2B8C34] font-medium text-sm ml-2">
                        Request Refund
                      </p>
                    </button>
                  )}
                </div>
              </div>
              <div className="bg-white rounded-lg py-4 px-4 mt-3">
                <p className="text-[#7C8D7E] font-semibold text-sm">
                  Item Details{" "}
                </p>

                <div className="mt-4 flex justify-between">
                  <div>
                    <h6 className="text-[#7C8D7E] text-sm">Item Name</h6>
                    <p className="text-[#344335] font-medium text-sm mt-1">
                      {type === "Input"
                        ? orderData?.input_product?.name || "N/A"
                        : type === "Advisory"
                        ? orderData?.advisory_product?.name || "N/A"
                        : type === "Mechanization"
                        ? orderData?.mechanization_product?.name || "N/A"
                        : type === "Insurance"
                        ? orderData?.insurance_product?.name || "N/A"
                        : type === "Livestock"
                        ? orderData?.input_product?.name || "N/A"
                        : null}
                    </p>
                  </div>
                  <div>
                    <h6 className="text-[#7C8D7E] text-sm">Vendor</h6>
                    <p className="text-[#344335] font-medium text-sm mt-1">
                      {type === "Input"
                        ? orderData?.input_supplier?.name || "N/A"
                        : type === "Advisory"
                        ? orderData?.advisory_supplier?.name || "N/A"
                        : type === "Mechanization"
                        ? orderData?.mechanization_supplier?.name || "N/A"
                        : type === "Insurance"
                        ? orderData?.insurance_supplier?.name || "N/A"
                        : type === "Livestock"
                        ? orderData?.input_supplier?.name || "N/A"
                        : "N/A"}
                    </p>
                  </div>
                  <div>
                    <h6 className="text-[#7C8D7E] text-sm">Quantity</h6>
                    <p className="text-[#344335] font-medium text-sm mt-1">
                      {orderData?.quantity || "N/A"}
                    </p>
                  </div>
                  <div>
                    <h6 className="text-[#7C8D7E] text-sm">Price</h6>
                    <p className="text-[#344335] font-medium text-sm mt-1">
                      ₦{orderData?.total_display_price || "N/A"}
                    </p>
                  </div>
                  <div>
                    <h6 className="text-[#7C8D7E] text-sm">Delivery Status</h6>
                    <div className="flex mt-1">
                      <div
                        className={`capitalize ${
                          orderData?.order_status?.at(-1)?.toLowerCase() ===
                          "processing"
                            ? "pending-badge"
                            : orderData?.order_status?.at(-1)?.toLowerCase() ===
                              "pending"
                            ? "error-badge"
                            : orderData?.order_status?.at(-1)?.toLowerCase() ===
                              "dispatched"
                            ? "dispatched-badge"
                            : orderData?.order_status?.at(-1)?.toLowerCase() ===
                              "delivered"
                            ? "success-badge"
                            : orderData?.order_status?.at(-1)?.toLowerCase() ===
                              "cancelled"
                            ? "error-badge"
                            : "na-badge"
                        }`}
                      >
                        <p>{orderData?.order_status?.at(-1)?.toLowerCase()}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <button
                    onClick={() => showHistoryModal()}
                    className="text-[#2B8C34] text-sm font-medium"
                  >
                    See Order History
                  </button>
                </div>

                {orderData?.order_status?.at(-1)?.toLowerCase() ===
                  "delivered" ||
                orderData?.order_status?.at(-1)?.toLowerCase() ===
                  "cancelled" ? null : (
                  <div className="flex mt-6 gap-4">
                    <button
                      onClick={() =>
                        showModal(
                          orderData?.order_status?.at(-1)?.toLowerCase()
                        )
                      }
                      className="py-3 disabled:bg-[#ABB6AC] text-white text-sm  px-5 bg-[#2B8C34] hover:bg-opacity-[0.9] rounded-[8px] font-medium transition-all"
                    >
                      {orderData?.order_status?.at(-1)?.toLowerCase() ===
                      "processing"
                        ? "Dispatch Order"
                        : orderData?.order_status?.at(-1)?.toLowerCase() ===
                          "pending"
                        ? "Accept"
                        : orderData?.order_status?.at(-1)?.toLowerCase() ===
                          "dispatched"
                        ? "Confirm Delivery"
                        : "na-badge"}
                    </button>
                    <button
                      onClick={() =>
                        showRejectModal(
                          orderData?.order_status?.at(-1)?.toLowerCase()
                        )
                      }
                      className="py-3 disabled:bg-[#FEF0EC] disabled:bg-opacity-[0.9] text-[#B92043] text-sm  px-5 bg-[#FEF0EC] rounded-[8px] font-medium transition-all"
                    >
                      Reject
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* ALL MODALS SECTION */}
          <section>
            {/* accept, dispatch order modal */}
            <ModalComponent
              show={show}
              showModal={showModal}
              title={
                orderType === "processing"
                  ? "Dispatch Order"
                  : orderType === "pending"
                  ? "  Process Order"
                  : orderType === "dispatched"
                  ? " Confirm Order Delivery"
                  : "N/A"
              }
              subtitle=""
            >
              <div className="mt-4">
                <h6 className="text-[#5C715E] font-medium text-sm">
                  {orderType === "processing"
                    ? " Are you sure you want to accept and dispatch this order?"
                    : orderType === "pending"
                    ? "  Are you sure you want to accept and process this order?"
                    : orderType === "dispatched"
                    ? "   Are you sure the order has been delivered?"
                    : "N/A"}
                </h6>
              </div>

              <div className="flex justify-end mt-8 mb-3">
                <div className="flex gap-2">
                  <button
                    type="submit"
                    onClick={handleChangeOrderType}
                    className="bg-primary 
                disabled={false}
                           disabled:bg-[#ABB6AC] 
                           rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setShow(false)}
                    type="submit"
                    disabled={false}
                    className="rounded-lg px-6 py-3 text-[#2B8C34] text-sm font-medium transition-all"
                  >
                    No
                  </button>
                </div>
              </div>
            </ModalComponent>

            {/* confirm delivery modal */}
            <ModalComponent
              show={deliveryModal}
              showModal={showDeliveryModal}
              title="Confirm Order Delivery"
              subtitle=""
            >
              <div className="mt-4">
                <h6 className="text-[#5C715E] font-medium text-sm">
                  Are you sure the order has been delivered?
                </h6>
              </div>

              <div className="flex justify-end mt-8 mb-3">
                <div className="flex gap-2">
                  <button
                    type="submit"
                    disabled={false}
                    className="bg-primary 
                           disabled:bg-[#ABB6AC] 
                           rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setDeliveryModal(false)}
                    type="submit"
                    disabled={false}
                    className="rounded-lg px-6 py-3 text-[#2B8C34] text-sm font-medium transition-all"
                  >
                    No
                  </button>
                </div>
              </div>
            </ModalComponent>

            {/* reject order modal */}
            <ModalComponent
              show={rejectModal}
              showModal={showRejectModal}
              title="Reject Order"
              subtitle=""
            >
              <div className="mt-4">
                <h6 className="text-[#5C715E] font-medium text-sm">
                  Are you sure you want to reject this order?
                </h6>
              </div>

              <div className="flex justify-end mt-8 mb-3">
                <div className="flex gap-2">
                  <button
                    onClick={handleRejectChangeStatus}
                    type="submit"
                    disabled={false}
                    className="bg-[#B92043] 
                           disabled:bg-[#B92043] hover:bg-opacity-[0.9]
                           rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#B92043] transition-all"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setRejectModal(false)}
                    type="submit"
                    disabled={false}
                    className="rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </ModalComponent>

            {/* order history modal */}
            <ModalComponent
              show={historyModal}
              showModal={showHistoryModal}
              title="Order History"
              subtitle=""
            >
              <div>
                {orderData?.order_history?.length === 0 ? (
                  <div className="h-[160px] w-full flex justify-center items-center">
                    <p className="text-sm leading-5 font-semibold text-[#344335]">
                      No status update yet
                    </p>
                  </div>
                ) : (
                  <div className="mt-6 mb-6">
                    {orderData?.order_history?.map((data, index) => {
                      return (
                        <div>
                          <div className="mb-4" key={index}>
                            <h6 className="text-[#344335] text-sm font-semibold capitalize">
                              {data?.update_by || "N/A"}
                            </h6>
                            {index === 0 ? (
                              <p className="text-[#7C8D7E] text-xs font-medium mt-1">
                                Changed{" "}
                                <span className="font-semibold text-[#344335]">
                                  Order Status from{" "}
                                  <span
                                    className={`capitalize ${
                                      orderData?.order_status?.at(0) ===
                                      "PENDING"
                                        ? "text-[#b92043]"
                                        : orderData?.order_status?.at(0) ===
                                          "PROCESSING"
                                        ? "text-[#d5a407]"
                                        : orderData?.order_status?.at(0) ===
                                          "DISPATCHED"
                                        ? "text-[#0066AF]"
                                        : orderData?.order_status?.at(0) ===
                                          "DELIVERED"
                                        ? "text-[#2b8c34]"
                                        : orderData?.order_status?.at(0) ===
                                          "CANCELLED"
                                        ? "text-[#b92043]"
                                        : null
                                    } `}
                                  >
                                    {orderData?.order_status
                                      ?.at(0)
                                      ?.toLowerCase()}{" "}
                                  </span>
                                  to{" "}
                                  <span
                                    className={` capitalize ${
                                      orderData?.order_status?.at(1) ===
                                      "PENDING"
                                        ? "text-[#b92043]"
                                        : orderData?.order_status?.at(1) ===
                                          "PROCESSING"
                                        ? "text-[#d5a407]"
                                        : orderData?.order_status?.at(1) ===
                                          "DISPATCHED"
                                        ? "text-[#0066AF]"
                                        : orderData?.order_status?.at(1) ===
                                          "DELIVERED"
                                        ? "text-[#2b8c34]"
                                        : orderData?.order_status?.at(1) ===
                                          "CANCELLED"
                                        ? "text-[#b92043]"
                                        : null
                                    } `}
                                  >
                                    {orderData?.order_status
                                      ?.at(1)
                                      ?.toLowerCase()}
                                  </span>
                                </span>{" "}
                              </p>
                            ) : index === 1 ? (
                              <p className="text-[#7C8D7E] text-xs font-medium mt-1">
                                Changed{" "}
                                <span className="font-semibold text-[#344335]">
                                  Order Status from{" "}
                                  <span
                                    className={` capitalize ${
                                      orderData?.order_status?.at(1) ===
                                      "PENDING"
                                        ? "text-[#b92043]"
                                        : orderData?.order_status?.at(1) ===
                                          "PROCESSING"
                                        ? "text-[#d5a407]"
                                        : orderData?.order_status?.at(1) ===
                                          "DISPATCHED"
                                        ? "text-[#0066AF]"
                                        : orderData?.order_status?.at(1) ===
                                          "DELIVERED"
                                        ? "text-[#2b8c34]"
                                        : orderData?.order_status?.at(1) ===
                                          "CANCELLED"
                                        ? "text-[#b92043]"
                                        : null
                                    } `}
                                  >
                                    {orderData?.order_status
                                      ?.at(1)
                                      ?.toLowerCase()}
                                  </span>{" "}
                                  to{" "}
                                  <span
                                    className={` capitalize ${
                                      orderData?.order_status?.at(2) ===
                                      "PENDING"
                                        ? "text-[#b92043]"
                                        : orderData?.order_status?.at(2) ===
                                          "PROCESSING"
                                        ? "text-[#d5a407]"
                                        : orderData?.order_status?.at(2) ===
                                          "DISPATCHED"
                                        ? "text-[#0066AF]"
                                        : orderData?.order_status?.at(2) ===
                                          "DELIVERED"
                                        ? "text-[#2b8c34]"
                                        : orderData?.order_status?.at(2) ===
                                          "CANCELLED"
                                        ? "text-[#b92043]"
                                        : null
                                    } `}
                                  >
                                    {orderData?.order_status
                                      ?.at(2)
                                      ?.toLowerCase()}
                                  </span>
                                </span>{" "}
                              </p>
                            ) : index === 2 ? (
                              <p className="text-[#7C8D7E] text-xs font-medium mt-1">
                                Changed{" "}
                                <span className="font-semibold text-[#344335]">
                                  Order Status from{" "}
                                  <span
                                    className={`capitalize ${
                                      orderData?.order_status?.at(2) ===
                                      "PENDING"
                                        ? "text-[#b92043]"
                                        : orderData?.order_status?.at(2) ===
                                          "PROCESSING"
                                        ? "text-[#d5a407]"
                                        : orderData?.order_status?.at(2) ===
                                          "DISPATCHED"
                                        ? "text-[#0066AF]"
                                        : orderData?.order_status?.at(2) ===
                                          "DELIVERED"
                                        ? "text-[#2b8c34]"
                                        : orderData?.order_status?.at(2) ===
                                          "CANCELLED"
                                        ? "text-[#b92043]"
                                        : null
                                    } `}
                                  >
                                    {orderData?.order_status
                                      ?.at(2)
                                      ?.toLowerCase()}
                                  </span>{" "}
                                  to{" "}
                                  <span
                                    className={`capitalize ${
                                      orderData?.order_status?.at(3) ===
                                      "PENDING"
                                        ? "text-[#b92043]"
                                        : orderData?.order_status?.at(3) ===
                                          "PROCESSING"
                                        ? "text-[#d5a407]"
                                        : orderData?.order_status?.at(3) ===
                                          "DISPATCHED"
                                        ? "text-[#0066AF]"
                                        : orderData?.order_status?.at(3) ===
                                          "DELIVERED"
                                        ? "text-[#2b8c34]"
                                        : orderData?.order_status?.at(3) ===
                                          "CANCELLED"
                                        ? "text-[#b92043]"
                                        : null
                                    } `}
                                  >
                                    {orderData?.order_status
                                      ?.at(3)
                                      ?.toLowerCase()}
                                  </span>
                                </span>{" "}
                              </p>
                            ) : null}

                            <p className="text-[#344335] text-xs font-medium mt-1">
                              {moment(data?.update_date).format("LLL")}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </ModalComponent>

            {/* request refund modal */}
            <ModalComponent
              show={refundModal}
              showModal={showRefundModal}
              title="Refund Application"
              subtitle=""
            >
              <div className="mt-6 mb-6">
                <Formik
                  onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                  }
                  validationSchema={refundApplicationValidator}
                  initialValues={{
                    orderID: `${orderData?.id}`,
                    cost: `${orderData?.total_display_price}`,
                    refundAmount: "",
                    description: "",
                  }}
                >
                  {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form className="mt-8" onSubmit={handleSubmit}>
                      <div>
                        <label
                          htmlFor="orderID"
                          className="block mb-2 text-[#344335] text-sm font-normal"
                        >
                          Order ID
                        </label>
                        <input
                          type="text"
                          disabled
                          name="orderID"
                          placeholder="Order ID"
                          className={
                            touched.orderID && errors.orderID
                              ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                              : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                          }
                          value={values.orderID}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.orderID && errors.orderID ? (
                          <div className="flex">
                            <img src={warning} className="" alt="warning" />
                            <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                              {touched.orderID && errors.orderID}
                            </small>
                          </div>
                        ) : null}
                      </div>

                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                          <label
                            htmlFor="cost"
                            className="block mb-2 text-[#344335] text-sm font-normal"
                          >
                            Cost
                          </label>
                          <input
                            type="number"
                            disabled
                            name="cost"
                            placeholder="Enter cost"
                            className={
                              touched.cost && errors.cost
                                ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                                : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                            }
                            value={values.cost}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.cost && errors.cost ? (
                            <div className="flex">
                              <img src={warning} className="" alt="warning" />
                              <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                                {touched.cost && errors.cost}
                              </small>
                            </div>
                          ) : null}
                        </div>

                        <div>
                          <label
                            htmlFor="refundAmount"
                            className="block mb-2 text-[#344335] text-sm font-normal"
                          >
                            Refund Amount
                          </label>
                          <input
                            type="text"
                            name="refundAmount"
                            placeholder="Enter refund amount"
                            className={
                              touched.refundAmount && errors.refundAmount
                                ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                                : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                            }
                            value={values.refundAmount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.refundAmount && errors.refundAmount ? (
                            <div className="flex">
                              <img src={warning} className="" alt="warning" />
                              <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                                {touched.refundAmount && errors.refundAmount}
                              </small>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-4">
                        <label
                          htmlFor="description"
                          className="block mb-2 text-[#344335] text-sm font-normal"
                        >
                          Request Description
                        </label>
                        <textarea
                          id="description"
                          rows="6"
                          className={
                            touched.description && errors.description
                              ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                              : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                          }
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter a description"
                        ></textarea>
                        {touched.description && errors.description ? (
                          <div className="flex">
                            <img src={warning} className="" alt="warning" />
                            <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                              {touched.description && errors.description}
                            </small>
                          </div>
                        ) : null}
                      </div>

                      <div className="mt-6">
                        <button
                          type="submit"
                          disabled={false}
                          className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                        >
                          Request Refund
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </ModalComponent>
          </section>
        </div>
      )}
    </div>
  );
};

export default OrderInfoPage;
