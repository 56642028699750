import { ContentCopy } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import TableBackground from "../../../components/TableBackground";
import DrawerComponent from "../../../components/Drawers/drawers";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFarmersUssdSessions,
  getCompleteFarmersUssdSessions,
  getIncompleteFarmersUssdSessions,
} from "../../../redux/Farmers/farmersActions";
import moment from "moment";
import PaginationComponent from "../../../components/Pagination/Pagination";
import EmptyState from "../../../components/EmptyState";

const UssdSessionTable = () => {
  const phone_number = sessionStorage.getItem("phone_number");
  const [status, setStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [completeCurrentPage, setCompleteCurrentPage] = useState(1);
  const [incompleteCurrentPage, setIncompleteCurrentPage] = useState(1);
  const [isClickTable, setIsClickTable] = useState(false);
  const dispatch = useDispatch();
  const {
    allFarmersUssdSessionData,
    allFarmersUssdSessionTotal,
    allFarmersUssdSessionLoading,

    completeFarmerUssdSessionData,
    completeFarmerUssdSessionTotal,
    completeFarmerUssdSessionLoading,

    incompleteFarmerUssdSessionData,
    incompleteFarmerUssdSessionTotal,
    incompleteFarmerUssdSessionLoading,
  } = useSelector((state) => state.farmer);

  // ALL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getAllFarmersUssdSessions({
        page: page,
        number: phone_number.replace("+", ""),
      })
    );
  };

  // DEBIT PAGE CHANGE
  const onCompletePageChange = (val) => {
    const { page } = val;
    setCompleteCurrentPage(page);

    dispatch(
      getCompleteFarmersUssdSessions({
        page: page,
        number: phone_number.replace("+", ""),
      })
    );
  };

  //CREDIT PAGE CHANGE
  const onIncompletePageChange = (val) => {
    const { page } = val;
    setIncompleteCurrentPage(page);

    dispatch(
      getIncompleteFarmersUssdSessions({
        page: page,
        number: phone_number.replace("+", ""),
      })
    );
  };

  useEffect(() => {
    if (status === "all") {
      dispatch(
        getAllFarmersUssdSessions({
          page: 1,
          number: phone_number.replace("+", ""),
        })
      );
    } else if (status === "complete") {
      dispatch(
        getCompleteFarmersUssdSessions({
          page: 1,
          number: phone_number.replace("+", ""),
        })
      );
    } else if (status === "incomplete") {
      dispatch(
        getIncompleteFarmersUssdSessions({
          page: 1,
          number: phone_number.replace("+", ""),
        })
      );
    }
  }, [dispatch, phone_number, status]);

  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        {/* <section className="flex gap-4 mt-4 pt-4 px-4">
          <div className="w-full relative">
            <div className=" absolute flex items-center h-[44px] px-[18px]">
              <Search style={{ fontSize: "18px", color: "#96A397" }} />
            </div>
            <input
              type="search"
              className="w-full border border-[#96A397] rounded-[8px] h-[44px] text-primary text-sm font-medium leading-5 cursor-pointer pl-[41px] px-[18px] outline-none focus:border-primary placeholder:text-[#96A397] serach"
              placeholder="Search"
            />
          </div>
        </section> */}

        {/* TABS SCETIONS */}
        <section className="mt-4">
          <div className="flex gap-4 pt-4 mt-4 mb-5 cursor-pointer px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All Sessions
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{allFarmersUssdSessionTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "complete"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("complete")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Complete Sessions
                </p>
                <div
                  className={
                    status === "complete"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{completeFarmerUssdSessionTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "incomplete"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("incomplete")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Incomplete Sessions
                </p>
                <div
                  className={
                    status === "incomplete"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{incompleteFarmerUssdSessionTotal}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {status === "all" ? (
          <section className="mt-[30px] mb-[24px]">
            {allFarmersUssdSessionLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {allFarmersUssdSessionData.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Activity
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Session Duration
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Previous Text{" "}
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Session Status
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Session Id
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allFarmersUssdSessionData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">
                              {data.phone_number || "N/A"}
                            </td>
                            <td className="px-4">
                              {data.text_activity || "N/A"}
                            </td>
                            <td className="px-4">N/A</td>
                            <td className="px-4">{data.prev_text || "N/A"}</td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                            <td className="px-4">
                              <div
                                className={
                                  data.session_status === "Incomplete"
                                    ? "error-badge"
                                    : "success-badge"
                                }
                              >
                                <p>{data.session_status || "N/A"}</p>
                              </div>
                            </td>
                            <td className="px-4">
                              {" "}
                              <div className="break-words w-[150px]">
                                <p>{data.session_id || "N/A"}</p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!allFarmersUssdSessionLoading &&
            allFarmersUssdSessionData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={allFarmersUssdSessionTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "complete" ? (
          <section className="mt-[30px] mb-[24px]">
            {completeFarmerUssdSessionLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {completeFarmerUssdSessionData.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Activity
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Session Duration
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Previous Text{" "}
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Session Status
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Session Id
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {completeFarmerUssdSessionData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">
                              {data.phone_number || "N/A"}
                            </td>
                            <td className="px-4">
                              {data.text_activity || "N/A"}
                            </td>
                            <td className="px-4">N/A</td>
                            <td className="px-4">{data.prev_text || "N/A"}</td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                            <td className="px-4">
                              <div
                                className={
                                  data.session_status === "Incomplete"
                                    ? "error-badge"
                                    : "success-badge"
                                }
                              >
                                <p>{data.session_status || "N/A"}</p>
                              </div>
                            </td>
                            <td className="px-4">
                              {" "}
                              <div className="break-words w-[150px]">
                                <p>{data.session_id || "N/A"}</p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!completeFarmerUssdSessionLoading &&
            completeFarmerUssdSessionData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={completeCurrentPage}
                  onPageChange={onCompletePageChange}
                  pageSize={10}
                  totalItems={completeFarmerUssdSessionTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "incomplete" ? (
          <section className="mt-[30px] mb-[24px]">
            {incompleteFarmerUssdSessionLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {incompleteFarmerUssdSessionData.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Activity
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Session Duration
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Previous Text{" "}
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Session Status
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Session Id
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {incompleteFarmerUssdSessionData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">
                              {data.phone_number || "N/A"}
                            </td>
                            <td className="px-4">
                              {data.text_activity || "N/A"}
                            </td>
                            <td className="px-4">N/A</td>
                            <td className="px-4">{data.prev_text || "N/A"}</td>
                            <td className="px-4">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="px-4">
                              {moment(data.created).format("LT")}
                            </td>
                            <td className="px-4">
                              <div
                                className={
                                  data.session_status === "Incomplete"
                                    ? "error-badge"
                                    : "success-badge"
                                }
                              >
                                <p>{data.session_status || "N/A"}</p>
                              </div>
                            </td>
                            <td className="px-4">
                              {" "}
                              <div className="break-words w-[150px]">
                                <p>{data.session_id || "N/A"}</p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!incompleteFarmerUssdSessionLoading &&
            incompleteFarmerUssdSessionData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={incompleteCurrentPage}
                  onPageChange={onIncompletePageChange}
                  pageSize={10}
                  totalItems={incompleteFarmerUssdSessionTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : null}
      </TableBackground>

      {/* BANK TRANSACTION INFORMATION SECTION */}
      <section>
        <DrawerComponent
          show={isClickTable}
          showDrawer={() => setIsClickTable(false)}
          title="USSD Info"
        >
          <div className="mt-[40px]">
            {/* Name */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Name
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  Celina Avong
                </p>
              </div>
            </div>

            {/* Phone Number */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Phone Number
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  +2348168791258
                </p>
                <ContentCopy
                  className="text-[#5C715E] cursor-pointer"
                  style={{ fontSize: "14px" }}
                />
              </div>
            </div>

            {/* Start Date */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Start Date
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  10 Aug 22
                </p>
              </div>
            </div>

            {/* Start Date*/}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Start Time
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  2:20 PM
                </p>
              </div>
            </div>

            {/* End Date */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                End Date
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  10 Aug 22
                </p>
              </div>
            </div>

            {/* End Date*/}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                End Time
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  2:20 PM
                </p>
              </div>
            </div>

            {/* Session Status*/}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Session Status
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] error-badge">
                  Incomplete
                </p>
              </div>
            </div>

            {/* TRANSACTION ACTIVITY */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Activity
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  Transfer
                </p>
              </div>
            </div>

            {/*Previous Text*/}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Previous Text
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  3*2*3*3*2*5
                </p>
              </div>
            </div>

            {/* TRANSACTION Narration */}
            <div className="mb-[25px]">
              <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                Session ID
              </p>
              <div className="flex gap-[6px]">
                <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                  ATUid_d3290b63d60524fbc26673f27076c0c8
                </p>
              </div>
            </div>
          </div>
        </DrawerComponent>
      </section>
    </div>
  );
};

export default UssdSessionTable;
