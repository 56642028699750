import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Card, CardWithStatus } from '../../components/Cards/index'
import arrowDown from '../../assets/icons/keyboard_arrow_down.svg'
import BarChart from '../../components/Charts/BarChart'
import { useDispatch, useSelector } from 'react-redux'
import { getBarChartsData, getOverviewInsights, getOverviewStats, getRecentOrders, getRecentTransactions } from '../../redux/Overview/overviewActions'
import { useNavigate } from 'react-router-dom'
import single_icon from '../../assets/icons/person.svg';
import grouped_icon from '../../assets/icons/people_alt.svg'
import { handlePendingLoanRecovery } from '../../redux/Loans/loanActions'
import EmptyState from '../../components/EmptyState'

const LoanOverviewPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { stats, insights, } = useSelector((state) => state.overview)
    const { pendingLoanRecoveryTotal, pendingLoanRecoveryData, pendingLoanRecoveryLoading } = useSelector((state) => state.loan);
    const [typeID, setTypeID] = useState(1)
    const orderTypes = [
      {
        id: 1,
        title: 'All',
      },
      {
        id: 2,
        title: 'Single'
      },
      {
        id: 3,
        title: 'Grouped'
      },
    ]
    const toggleType = (id, title) => {
      setTypeID(id)
      switch (id) {
        case 1:
          dispatch(getRecentOrders({
            type: 'all',
          }))
          break
        case 2:
          dispatch(getRecentOrders({
            type: 'input_orders',
          }))
          break;
        case 3:
          dispatch(getRecentOrders({
            type: 'mechanization_orders',
          }))
          break;
        case 4:
          dispatch(getRecentOrders({
            type: 'advisory_orders',
          }))
          break;
        case 5:
          dispatch(getRecentOrders({
            type: 'insurance_orders',
          }))
          break;
        default:
          break
      }
    }
  
   
  
    //  const numberWithCommas = (x) => { 
    //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    // }
  
    const insightDays = [
      {
        id: 1,
        name: 'Last 7 days',
        tag: '7',
      },
      {
        id: 2,
        name: 'Last 28 days',
        tag: '28',
      },
      {
        id: 3,
        name: 'Last 90 days',
        tag: '90',
      },
      {
        id: 4,
        name: 'Last 365 days',
        tag: '365',
      },
      {
        id: 5,
        name: 'Lifetime',
        tag: 'lifetime',
      }
    ]
  
    const [isSelected, setIsselected] = useState(1)
    const [selectedName, setSelectedName] = useState(null)
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').format('MMMM Do, YYYY'))
    const [toDate, setToDate] = useState(moment().format('MMMM Do, YYYY'))
    const [isOpen, setIsOpen] = useState(false)
    const handleDropdown = () => {
      setIsOpen(!isOpen)
    }
  
    const handleClickInsight = (id, name) => {
      // setIsOpen(!isOpen)
      setIsselected(id)
      setSelectedName(name)
      // check for filter selected
      switch (id) {
        case 1:
          dispatch(getOverviewInsights({
            from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
          }))
          setFromDate(moment().subtract(7, 'days').format('MMMM Do, YYYY'))
          setToDate(moment().format('MMMM Do, YYYY'))
          break
        case 2:
          dispatch(getOverviewInsights({
            from: moment().subtract(28, 'days').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
          }))
          setFromDate(moment().subtract(28, 'days').format('MMMM Do, YYYY'))
          setToDate(moment().format('MMMM Do, YYYY'))
          break;
        case 3:
          dispatch(getOverviewInsights({
            from: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
          }))
          setFromDate(moment().subtract(90, 'days').format('MMMM Do, YYYY'))
          setToDate(moment().format('MMMM Do, YYYY'))
          break;
        case 4:
          dispatch(getOverviewInsights({
            from: moment().subtract(365, 'days').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
          }))
          setFromDate(moment().subtract(365, 'days').format('MMMM Do, YYYY'))
          setToDate(moment().format('MMMM Do, YYYY'))
          break;
        case 5:
          dispatch(getOverviewInsights({
            from: moment().subtract(3, 'years').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
          }))
          setFromDate(moment().subtract(3, 'years').format('MMMM Do, YYYY'))
          setToDate(moment().format('MMMM Do, YYYY'))
          break;
        default:
          break
      }
  
      setTimeout(() => {
        setIsOpen(!isOpen)
      }, 500)
    }
  
    useEffect(() => {
      dispatch(getOverviewStats())
      dispatch(getOverviewInsights({
        from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }))
      dispatch(getRecentTransactions({
        type: 'all',
        limit: 10
      }))
      dispatch(getRecentOrders({
        type: 'all',
      }))
      dispatch(handlePendingLoanRecovery({ page: "1" }))
      dispatch(getBarChartsData())
    }, [dispatch])


 

    return ( 
        <>
         <div className="bg-white flex justify-between rounded-lg py-5 px-4">
            <div>
            <h6 className="text-primary font-semibold text-lg">Loan Overview</h6>
            <p className="text-[#5C715E] text-sm font-semibold mt-5">
                {moment().format('MMMM Do, YYYY')}
            </p>
            </div>
         </div>

         <div className="mt-6 grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <Card title="Active Loans" subtitle=
                {
                  stats ?
                    Number(stats.total_farmers)?.toLocaleString()
                    : 0} href="/agrofastcash/active-loans" />
            </div>
            <div>
              <CardWithStatus hasStatus={true} status="Urgent" title="Overdue Loans" subtitle={
                stats ?
                  Number(stats.total_unchecked_call_to_orders)?.toLocaleString()
                  : 0} href="/agrofastcash/overdue-payment" />
            </div>
            <div>
              <CardWithStatus hasStatus={true} status="Urgent" title="Mature Loans" subtitle={
                stats ?
                  Number(stats.total_overdue_orders)?.toLocaleString()
                  : 0} href="/orders/overdue-orders" />
            </div>
          </div>

          <div className="mt-4 bg-white rounded-lg py-6 px-6">
            <BarChart />
          </div>
        </div>
        <div className="bg-[#4E9F56] rounded-lg py-4 px-4 h-fit">
          <div className='flex justify-between items-center'>
            <div>
              <h5 className="text-white font-semibold text-xl">Insights</h5>
            </div>
            <div className='relative'>
              <small className='text-xs text-white  font-medium'>{fromDate} – {toDate}</small>
              <div className='flex gap-2 items-center cursor-pointer justify-end' onClick={handleDropdown}>
                <p className='text-white text-sm font-semibold'>{selectedName ? selectedName : 'Last 7 days'}</p>
                <img src={arrowDown} alt="arrow down" />
              </div>
              {isOpen &&
                <div
                  className="absolute  right-0 top-12 rounded-[6px] py-2 px-2 w-full bg-white z-10 min-w-[160px]"
                  style={{
                    boxShadow:
                      '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  {
                    insightDays.map((val) => (
                      <div
                        key={val.id}
                        onClick={() => handleClickInsight(val.id, val.name)}
                        className={
                          val.id === isSelected ?
                            "px-4 py-3 bg-[#EDF7EE] border border-primary rounded-md hover:bg-[#EDF7EE] cursor-pointer"
                            :
                            "px-4 py-3 rounded-md hover:bg-[#EDF7EE] cursor-pointer"
                        }
                      >
                        <p className={
                          val.id === isSelected ?
                            "font-medium text-sm leading-5 text-[#24752B]"
                            :
                            "font-medium text-sm leading-5 text-[#344335]"
                        }
                        >
                          {val.name}
                        </p>
                      </div>
                    ))
                  }
                </div>
              }
            </div>
          </div>
          <div className="mt-6">
            <p className="text-white text-sm font-semibold">Loans Disbursed</p>
            <h6 className="text-white font-semibold text-lg">
               ₦ 3,000,000
            </h6>
            <h6 className="text-white font-semibold text-lg">
              4 Farmer(s)
            </h6>
          
          </div>
          <div className="mt-4">
            <p className="text-white text-sm font-semibold">Active Loans</p>
            <h6 className="text-white font-semibold text-lg"> ₦ 3,000,000</h6>
            <h6 className="text-white font-semibold text-lg">{insights ? insights.total_call_requests : 0} Farmer(s)</h6>
          </div>
          <div className="mt-4">
            <p className="text-white text-sm font-semibold">Loans Recovered</p>
            <h6 className="text-white font-semibold text-lg"> ₦ 3,000,000</h6>
            <h6 className="text-white font-semibold text-lg">{insights ? insights.total_call_requests : 0} Farmer(s)</h6>
          </div>
          <div className="mt-4">
            <p className="text-white text-sm font-semibold">Overdue Loans </p>
            <h6 className="text-white font-semibold text-lg"> ₦ 3,000,000</h6>
            <h6 className="text-white font-semibold text-lg">12 Farmer(s)</h6>
          </div>
        </div>
      </div>

      <div className="mt-8 bg-white rounded-lg py-5 px-4">
        <h5 className="text-[#344335] font-semibold text-base">Pending Loan Recovery Log</h5>
      </div>

    <div className="mt-3 bg-white rounded-lg py-4">
        <ul className='flex gap-5 items-center px-4'>
          {
            orderTypes.map((item,) => (
              <li key={item.id}
                onClick={() => toggleType(item.id, item.title)}
                className=
                {
                  typeID === item.id
                    ?
                    "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    :
                    "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p className={
                  typeID === item.id
                    ?
                    "text-primary text-sm font-semibold"
                    :
                    "text-[#96A397] text-sm font-semibold"
                }
                >{item.title}</p>
                 <span
                  className={
                    typeID === item.id
                      ? 'bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]'
                      : 'bg-[#ECEEEC] rounded-[4px] py-1 px-1 text-[#96A397] font-semibold text-[10px]'
                  }
                >
                  {typeID === item.id ? pendingLoanRecoveryTotal : 0 }
                </span>
              </li>
            ))
          }

        </ul>
        <div className="overflow-x-auto relative">
          {pendingLoanRecoveryLoading ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {pendingLoanRecoveryData?.length === 0 ? (
                <EmptyState text="There's no pending recovery available " />
              ) : (
                <table className="w-full text-left">
                  <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                    <tr>
                      <th scope="col" className="px-4 py-4 font-semibold ">
                        Farmer
                      </th>

                      <th scope="col" className="px-4 py-4 font-semibold">
                        Amount
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Date/Time
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Channel
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Type
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingLoanRecoveryData?.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() =>
                            navigate(
                              `/loan-recovery-log/${data?.id}`
                            )
                          }
                          className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                          <th
                            scope="row"
                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                          >
                            {data?.farmer || "N/A"}
                          </th>

                          <td className="py-4 px-4 text-[#5C715E]">₦{data?.amount_repaid || "N/A"}</td>
                          <td className="py-4 px-4 text-[#5C715E]">{moment(data.created).format("lll") || "N/A"}</td>
                          <td className="py-4 px-4 capitalize">{data?.repayment_channel?.replace("_", " ") || "N/A"}</td>
                          <td className="py-4 px-4">
                            <div className='flex gap-1 items-center'>
                              <img alt="grouped icon" src={data?.repayment_type === "single" ? single_icon : grouped_icon} />
                              < p className='text-[#5C715E] text-sm font-medium'>{data?.repayment_type === "single" ? "Single" : "Grouped"}</p>
                            </div>

                          </td>
                          <td className="py-4 px-4">
                            <div className="flex">
                              <div className={`${data?.approval_status === "approved" ? "success-badge" : data?.approval_status === "rejected" ? "error-badge" : data?.approval_status === "pending" ? "pending-badge" : "na-badge"}`}>
                                <p className='capitalize'>{`${data?.approval_status}`}</p>
                              </div>
                            </div>
                          </td>

                        </tr>

                      )
                    }
                    )}


                  </tbody>
                </table>
              )}
            </>

          )}



        </div>
      </div>
      

     

        </>
     );
}
 
export default LoanOverviewPage;