import { Block } from "@mui/icons-material";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import ModalComponent from "../../../../components/Modals/modal";
import {
  blockFarmer,
  getAllFarmers,
  getAllFlaggedFarmers,
} from "../../../../redux/Farmers/farmersActions";
import { useLocation, useNavigate } from "react-router-dom";

const UnblockFarmer = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isClickSuspendFarmer, setIsClickSuspendFarmer] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);
  const inputRef = useRef("");
  const location = useLocation();
  // const { blockFarmerStatus } = useSelector((state) => state.farmer)

  const handleClickSuspendFarmer = (e) => {
    e.stopPropagation();
    setIsClickSuspendFarmer(!isClickSuspendFarmer);
  };

  const handleBlock = () => {
    dispatch(
      blockFarmer({
        inputValue: inputRef.current.value,
        id: id.id,
        status: "Active",
      })
    ).then((res) => {
      if (res?.type === "blockFarmer/fulfilled") {
        if (location.pathname !== "/flagged-farmers") {
          navigate("/farmers");
        }
      }
    });

    setTimeout(() => {
      setIsClickSuspendFarmer(false);
      if (
        location.pathname === "/farmers" ||
        location.pathname.includes("farmers-profile")
      ) {
        dispatch(
          getAllFarmers({
            id: id.id,
            page: 1,
            value:
              sessionStorage.getItem("farmer_search_value") === null
                ? ""
                : sessionStorage.getItem("farmer_search_value"),
            states: "",
            lgas: "",
            rank: "",
          })
        );
      } else if (location.pathname === "/flagged-farmers") {
        dispatch(getAllFlaggedFarmers({ page: 1 }));
      }
    }, 800);
  };

  return (
    <div>
      <div>
        {" "}
        <p
          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
          onClick={(event) => handleClickSuspendFarmer(event)}
        >
          Unblock Farmer
        </p>
      </div>
      {/* SUSPEND FARMER MODAL */}
      <ModalComponent
        title="Unblock Farmer"
        show={isClickSuspendFarmer}
        showModal={() => setIsClickSuspendFarmer(false)}
        subtitle="When a farmer is unblocked he/she will be able to access crop2cash USSD"
      >
        <div className="pt-[16px]">
          <div className="mb-[32px]">
            <p className="text-sm font-[400] leading-[18px] text-[#344335] ">
              Reason for unblocking
            </p>
            <textarea
              ref={inputRef}
              placeholder="Reason for unblocking"
              className="outline-0 border border-[#96A397] h-[134px] rounded-[8px] w-full py-[12px] px-[16px] mt-[4px] resize-none"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={false}
              onClick={handleBlock}
              className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-[102px] h-[44px] text-sm text-white font-medium hover:bg-primary transition-all mb-[16px]"
            >
              Unblock
            </button>
          </div>
        </div>
      </ModalComponent>

      {/* SUSPEND FARMER ALERT MODAL */}
      <ModalComponent
        title=""
        show={isSuspended}
        showModal={() => setIsSuspended(false)}
      >
        <div className="flex flex-col items-center">
          <Block
            className="text-[#B92043] mb-[10px]"
            style={{ fontSize: "20px" }}
          />
          <p className="mb-[4px] font-semibold text-base leading-5 text-[#344335]">
            Farmer has been unblocked
          </p>
          <p className="text-center font-medium text-xs leading-4 text-[#344335] mb-[44px]">
            Jesam Abubakar will be able to make access Crop2cash USSD
          </p>

          <p
            className="font-medium text-sm  leading-5 text-[#5C715E] mb-[22px]"
            onClick={() => setIsSuspended(false)}
          >
            Close
          </p>
        </div>
      </ModalComponent>
    </div>
  );
};

export default UnblockFarmer;
