import React, { useEffect, useState } from "react";
import GoBack from "../../../components/GoBack";
import { ButtonSecondary } from "../../../components/Button";
import Header from "../../../components/Header";
// import { useParams } from 'react-router-dom';
import { MoreVert, ContentCopy } from "@mui/icons-material";
import cogoToast from "cogo-toast";
import TableBackground from "../../../components/TableBackground";
import LoanAgentOverdueLoanPage from "./overdueLoan";
import LoanAgentLoanPorfolioPage from "./loanPorfolio";
import LoanAgentGroupsPage from "./groups";
import LoanAgentLoanApplicationPage from "./loanApplication";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoanOfficerById } from "../../../redux/Loans/loanActions";

const LoanAgentProfilePage = () => {
  // const { id } = useParams();
  const [isClickMore, setIsClickMore] = useState(false);
  const [tab, setTab] = useState("account_information");
  const { id } = useParams();
  const dispatch = useDispatch();

  const { loanofficer } = useSelector((state) => state.loan);

  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  const formatGender = (val) => {
    let res;
    switch (val) {
      case "m":
        res = "Male";
        break;
      case "f":
        res = "Female";
        break;
      case "o":
        res = "Other";
        break;
      default:
        res = "Other";
        break;
    }
    return res;
  };

  const splitFullName = (val) => {
    const fullName = val;
    const parts = fullName.split(" ");
    const firstName = parts[0] ? parts[0]: '';
    const lastName = parts[1] ? parts[1]: "";
    return {
      firstName,
      lastName,
    };
  };

  useEffect(() => {
    dispatch(
      fetchLoanOfficerById({
        id,
      })
    );
  }, [dispatch, id]);

  return (
    <>
      <GoBack />

      {/* HEADER AND TAB SECTION */}
      <div className="mt-[14px] mb-6">
        <Header
          text={`Loan Agent Profile (${loanofficer && loanofficer.officer_details
            ? loanofficer.officer_details.name
            : "N/A"})`}
          tabs={
            <div className="flex gap-[16px] mt-[16px]">
              <p
                className={
                  tab === "account_information"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("account_information")}
              >
                Account Information
              </p>
              <p
                className={
                  tab === "loan_porfolio"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("loan_porfolio")}
              >
                Loan Porfolio
              </p>
              <p
                className={
                  tab === "overdue_loan"
                    ? "font-semibold text-sm leading-[18px] text-[#B92043] underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("overdue_loan")}
              >
                Overdue Loan
              </p>
              <p
                className={
                  tab === "loan_application"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("loan_application")}
              >
                Loan Application
              </p>
              <p
                className={
                  tab === "groups"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("groups")}
              >
                Groups
              </p>
            </div>
          }
        >
          <div className="relative">
            <div className="flex gap-[34px] items-center">
              <div className="flex">
                <ButtonSecondary>Send Message</ButtonSecondary>
              </div>
              <div className="relative">
                <MoreVert
                  onClick={() => setIsClickMore(!isClickMore)}
                  className="text-primary cursor-pointer"
                  style={{ fontSize: "20px" }}
                />
                {isClickMore && (
                  <div
                    className="absolute right-0 top-12 z-10 min-w-[213px] rounded-[8px] bg-white p-[8px] hover:rounded-[6px]"
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    {/* EDIT PROFILE */}
                    <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                      <p className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]">
                        Edit Profile
                      </p>
                    </div>
                    {/* Suspend Agent */}
                    <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                      <p className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]">
                        Suspend Agent
                      </p>
                    </div>
                    {/* Block Agent */}
                    <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                      <p className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#96A397]">
                        Block Agent
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Header>
      </div>

      <div className="">
        {tab === "account_information" ? (
          <>
            <div className="flex gap-[16px]">
              <div className="w-[75%] h-auto">
                <TableBackground
                  classname="px-4 pt-4"
                  style={{ borderRadius: "8px" }}
                >
                  <div className="flex items-center gap-[8px] mb-[24px]">
                    {/* FARMER"S INITIALS */}
                    <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex items-center justify-center">
                      <p className="font-medium text-base leading-6 ">
                        {loanofficer && loanofficer.officer_details
                          ? splitFullName(loanofficer.officer_details.name)
                              .firstName.charAt(0)
                              .toUpperCase()
                          : "T"}
                        {loanofficer && loanofficer.officer_details
                          ? splitFullName(loanofficer.officer_details.name)
                              .lastName.charAt(0)
                              .toUpperCase()
                          : "U"}
                      </p>
                    </div>

                    <div className="">
                      <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                        {loanofficer && loanofficer.officer_details
                          ? loanofficer.officer_details.name
                          : "N/A"}
                      </p>
                      <div
                        className="flex gap-[6px] mt-[4px] cursor-pointer"
                        onClick={() =>
                          handleCopyNumber(
                            `${
                              loanofficer && loanofficer.officer_details
                                ? loanofficer.officer_details.agent_phone_number
                                : "N/A"
                            }`
                          )
                        }
                      >
                        <p className="text-primary font-semibold leading-[18px] text-sm">
                          {loanofficer && loanofficer.officer_details
                            ? loanofficer.officer_details.agent_phone_number
                            : "N/A"}
                        </p>
                        <ContentCopy
                          className="text-primary"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-[20px]">
                    <div className="w-1/5">
                      {/* Agent ID */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Agent ID
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          {loanofficer && loanofficer.officer_details
                            ? loanofficer.officer_details.id
                            : "1"}
                        </p>
                      </div>

                      {/* LGA */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          LGA
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          {loanofficer && loanofficer.officer_details
                            ? loanofficer.officer_details.lga
                            : "N/A"}
                        </p>
                      </div>

                      {/* Cooperative */}
                      <div className="">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Cooperative
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          Ifesowapo
                        </p>
                      </div>
                    </div>
                    <div className="w-1/5">
                      {/* Sex */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Sex
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          {loanofficer && loanofficer.officer_details
                            ? formatGender(loanofficer.officer_details.gender)
                            : "N/A"}
                        </p>
                      </div>

                      {/* State */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          State
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          {loanofficer && loanofficer.officer_details
                            ? loanofficer.officer_details.state
                            : "N/A"}{" "}
                          State
                        </p>
                      </div>

                      {/* crop/livestock */}
                      <div className="">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Language
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          Hausa
                        </p>
                      </div>
                    </div>
                    <div className="w-1/5">
                      {/* SEX */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          BVN
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          894769587469
                        </p>
                      </div>

                      {/* Age */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Age
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          24
                        </p>
                      </div>

                      {/* crop/livestock */}
                      <div className="">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Crop/Livestock
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          Maize,Cassava
                        </p>
                      </div>
                    </div>
                    <div className="w-1/5">
                      {/* Bank */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Account Number
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          3227468369
                        </p>
                      </div>

                      {/* Date with Birth */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Date Of Birth
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          {loanofficer && loanofficer.officer_details
                            ? loanofficer.officer_details.date_of_birth
                            : "N/A"}
                        </p>
                      </div>

                      {/* Registation Status */}
                      <div className="">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Status
                        </p>
                        <div
                          className={
                            loanofficer
                              ? loanofficer.is_active
                                ? "complete-badge"
                                : "error-badge"
                              : "N/A"
                          }
                        >
                          <p className="font-semibold text-sm leading-5">
                            {loanofficer
                              ? loanofficer.is_active
                                ? "Active"
                                : "Inactive"
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/5">
                      {/* State */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Bank
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          Wema Bank
                        </p>
                      </div>
                      {/* address */}
                      <div className="mb-[24px]">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                          Address
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                          {loanofficer && loanofficer.officer_details
                            ? loanofficer.officer_details.address
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </TableBackground>
              </div>
              <div className="w-[25%]">
                <div className="h-[84px] w-full p-[16px] text-white bg-primary rounded-[8px] mb-[16px]">
                  <p className="pb-[4px] text-sm font-semibold leading-[18px]">
                    Total Loan Porfolio
                  </p>
                  <p className="pb-[4px] text-2xl font-semibold leading-[30px]">
                    ₦600,000
                  </p>
                </div>

                <div className="h-[84px] w-full p-[16px] text-white bg-[#D4A407] rounded-[8px] mb-[16px]">
                  <p className="pb-[4px] text-sm font-semibold leading-[18px]">
                    Total Loan Recovered
                  </p>
                  <p className="pb-[4px] text-2xl font-semibold leading-[30px]">
                    ₦
                    {loanofficer && loanofficer.amount_recovered
                      ? loanofficer.amount_recovered
                      : 0}
                  </p>
                </div>

                <div className="h-[84px] w-full p-[16px] text-white bg-[#B92043] rounded-[8px] ">
                  <p className="pb-[4px] text-sm font-semibold leading-[18px]">
                    Overdue Loan
                  </p>
                  <p className="pb-[4px] text-2xl font-semibold leading-[30px]">
                    {loanofficer && loanofficer.overdue_loans
                      ? loanofficer.overdue_loans
                      : 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-[#FFFFFF] mt-5 py-4 px-4 rounded-lg">
              <p className="text-[#344335] text-sm font-semibold">
                Guarantor 1 Information
              </p>
            </div>
            <div className="mt-3 bg-[white] rounded-lg py-4 px-4">
              <div>
                <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                  Abubakar Jesam
                </p>
                <div
                  className="flex gap-[6px] mt-[4px] cursor-pointer"
                  onClick={() => handleCopyNumber("+234897590893")}
                >
                  <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                    +234897590893
                  </p>
                  <ContentCopy
                    className="text-[#344335]"
                    style={{ fontSize: "18px" }}
                  />
                </div>
              </div>
              <div className="mt-6">
                <div className="flex justify-between">
                  <div className="flex-1">
                    <p className="text-[#7C8D7E] text-sm ">Agent ID</p>
                    <h6 className="text-[#344335] font-medium text-sm">
                      AG001
                    </h6>
                  </div>
                  <div className="flex-1">
                    <p className="text-[#7C8D7E] text-sm ">Sex</p>
                    <h6 className="text-[#344335] font-medium text-sm">Male</h6>
                  </div>
                  <div className="flex-1">
                    <p className="text-[#7C8D7E] text-sm ">BVN</p>
                    <h6 className="text-[#344335] font-medium text-sm">
                      894769587469
                    </h6>
                  </div>
                  <div className="flex-1">
                    <p className="text-[#7C8D7E] text-sm ">NIN</p>
                    <h6 className="text-[#344335] font-medium text-sm">
                      3227468369
                    </h6>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <div className="flex justify-between">
                  <div className="flex-1">
                    <p className="text-[#7C8D7E] text-sm ">Address</p>
                    <h6 className="text-[#344335] font-medium text-sm">
                      Almond Court
                    </h6>
                  </div>
                  <div className="flex-1">
                    <p className="text-[#7C8D7E] text-sm ">Nearest Landmark</p>
                    <h6 className="text-[#344335] font-medium text-sm">
                      Ikolaba
                    </h6>
                  </div>
                  <div className="flex-1">
                    <p className="text-[#7C8D7E] text-sm ">LGA</p>
                    <h6 className="text-[#344335] font-medium text-sm">
                      Ibadan North
                    </h6>
                  </div>
                  <div className="flex-1">
                    <p className="text-[#7C8D7E] text-sm ">State</p>
                    <h6 className="text-[#344335] font-medium text-sm">Oyo</h6>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : tab === "overdue_loan" ? (
          <div>
            <LoanAgentOverdueLoanPage />
          </div>
        ) : tab === "loan_porfolio" ? (
          <div>
            <LoanAgentLoanPorfolioPage />
          </div>
        ) : tab === "loan_application" ? (
          <div>
            <LoanAgentLoanApplicationPage />
          </div>
        ) : tab === "groups" ? (
          <div>
            <LoanAgentGroupsPage />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LoanAgentProfilePage;
