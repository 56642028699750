import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {
  getPointAction,
  getPointStats,
  getPointsActions,
  getPointsActionsSettings,
  getPointsLeaderBoard,
  getPointsSettings,
  handlePointsSettings,
} from "./pointsActions";

const initialState = {
  loader: false,
  pointsSettings: {},
  pointsActionSettings: {},
  pointsSettingsLoading: false,
  pointsActions: {},
  leaderboardLoading: false,
  leaderboardData: {},
  leaderboardTotal: 0,
  pointLeaderboard: [],
  pointLeaderboardTotal: 0,
  pointLeaderboardLoading: false,
  pointStat: {},
  pointStatLoading: false,
};

const pointsPersistConfig = {
  key: "points",
  storage,
};

export const pointsSlice = createSlice({
  name: "points",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
  extraReducers: {
    // fetch points setting ----------
    [getPointsSettings.pending]: (state) => {
      state.pointsSettingsLoading = true;
    },
    [getPointsSettings.fulfilled]: (state, { payload }) => {
      state.pointsSettingsLoading = false;
      state.pointsSettings = payload.data;
    },
    [getPointsSettings.rejected]: (state) => {
      state.pointsSettingsLoading = false;
    },

    // fetch points actions ----------
    [getPointsActions.pending]: (state) => {
      state.pointsSettingsLoading = true;
    },
    [getPointsActions.fulfilled]: (state, { payload }) => {
      state.pointsSettingsLoading = false;
      state.pointsActions = payload.data;
    },
    [getPointsActions.rejected]: (state) => {
      state.pointsSettingsLoading = false;
    },

    // fetch points actions settings ----------
    [getPointsActionsSettings.pending]: (state) => {
      state.pointsSettingsLoading = true;
    },
    [getPointsActionsSettings.fulfilled]: (state, { payload }) => {
      state.pointsSettingsLoading = false;
      state.pointsActionSettings = payload.data;
    },
    [getPointsActionsSettings.rejected]: (state) => {
      state.pointsSettingsLoading = false;
    },
    // set points ----------
    [handlePointsSettings.pending]: (state) => {
      state.loader = true;
    },
    [handlePointsSettings.fulfilled]: (state, { payload }) => {
      state.loader = false;
    },
    [handlePointsSettings.rejected]: (state) => {
      state.loader = false;
    },

    // set leaderboard ----------
    [getPointsLeaderBoard.pending]: (state) => {
      state.leaderboardLoading = true;
    },
    [getPointsLeaderBoard.fulfilled]: (state, { payload }) => {
      state.leaderboardLoading = false;
      state.leaderboardData = payload.data;
      state.leaderboardTotal = payload.total;
    },
    [getPointsLeaderBoard.rejected]: (state) => {
      state.leaderboardLoading = false;
    },

    // set point leaderboard ----------
    [getPointAction.pending]: (state) => {
      state.pointLeaderboardLoading = true;
    },
    [getPointAction.fulfilled]: (state, { payload }) => {
      state.pointLeaderboardLoading = false;
      state.pointLeaderboard = payload.data;
      state.pointLeaderboardTotal = payload.total;
    },
    [getPointAction.rejected]: (state) => {
      state.pointLeaderboardLoading = false;
    },

    // set point stats ----------
    [getPointStats.pending]: (state) => {
      state.pointStatLoading = true;
    },
    [getPointStats.fulfilled]: (state, { payload }) => {
      state.pointStatLoading = false;
      state.pointStat = payload.data;
    },
    [getPointStats.rejected]: (state) => {
      state.pointStatLoading = false;
    },
  },
});

const pointsReducer = persistReducer(pointsPersistConfig, pointsSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement } = pointsSlice.actions;

export default pointsReducer;
