import React from 'react'
import logo from '../../assets/icons/logo_white.svg'

const OnboardingSidebar = () => {
    return ( 
        <>
           <div className='w-[40%] bg-primary min-h-screen relative hidden md:block'>
                <div className='absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                    <img src={logo} alt="logo" />
                </div>
            </div>  
        </>
     );
}
 
export default OnboardingSidebar;