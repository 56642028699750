import { Star, StarHalf } from "@mui/icons-material";
import React from "react";

export const Plantinum = ({ children }) => {
  return (
    <div className="bg-[#4B4E68] rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
      <div className="flex gap-[1.5px]">
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const Gold = ({ children }) => {
  return (
    <div className="bg-[#F9B43B] rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
      <div className="flex gap-[1.5px]">
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const Silver = ({ children }) => {
  return (
    <div className="bg-[#4B7295] rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
      <div className="flex gap-[1.5px]">
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const Bronze = ({ children }) => {
  return (
    <div className="bg-[#E86454] rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
      <div className="flex gap-[1.5px]">
        <Star
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const Copper = ({ children }) => {
  return (
    <div className="bg-[#6A1505] rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
      <div className="flex gap-[1.5px]">
        <StarHalf
          style={{
            fontSize: "8px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};
