import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { getCalls, getRetailSearch } from "../../redux/Retail/retailAction";
import moment from "moment";
import EmptyState from "../../components/EmptyState";

const FollowUpTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { callsData, callsTotal, callsLoading } = useSelector(
    (state) => state.retail
  );

  // CALLS PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(getCalls({ status: "FOLLOW_UP", page: page }));
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        dispatch(
          getRetailSearch({
            page: currentPage,
            value: val,
          })
        );
      }, 750),
    [dispatch, currentPage]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    dispatch(getCalls({ status: "FOLLOW_UP", page: 1 }));
  }, [dispatch]);

  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {callsLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {callsData.length === 0 ? (
                <div>
                  <EmptyState text="There's no data for this filter yet" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        Farmer Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Phone Number
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Due Date
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Due Time
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Campaign Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Note
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {callsData?.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                        >
                          <td className="px-4">
                            {data.first_name || "N/A"} {data.last_name || "N/A"}
                          </td>
                          <td className="px-4">{data.phone_number || "N/A"}</td>
                          <td className="px-4">
                            {data.call_date
                              ? moment(data.follow_up_date).format("ll")
                              : "N/A"}
                          </td>
                          <td className="px-4">
                            {data.call_date
                              ? moment(data.follow_up_date).format("LT")
                              : "N/A"}
                          </td>
                          <td className="px-4">
                            {data.campaign_name || "N/A"}
                          </td>
                          <td className="px-4">
                            <div
                              className={
                                data.note_id ? "success-badge" : "na-badge"
                              }
                            >
                              <p>{data.note_id ? "View Note" : "No note"}</p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}

          {!callsLoading && callsData.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={callsTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default FollowUpTable;
