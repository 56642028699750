import React from "react";
import icon from "../../assets/icons/404.svg";
import { ButtonPrimary } from "../../components/Button/index";

const NotFound = () => {
  return (
    <div className="h-screen flex flex-col w-full justify-center items-center">
      <img src={icon} alt="404 icon" className="mb-6" />
      <p className="text-center font-semibold text-3xl leading-9 text-[#423F34] w-full max-w-[576px] mb-10">
        The page you are looking for can’t be found
      </p>
      <ButtonPrimary href="/overview">
        Go To Overview
      </ButtonPrimary>
    </div>
  );
};

export default NotFound;
