import moment from "moment";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../../components/Pagination/Pagination";
import TableBackground from "../../../components/TableBackground";
import {
  getCompleteFarmers,
  getCompleteFarmersSearch,
} from "../../../redux/Farmers/farmersActions";
import { debounce } from "lodash";
import SearchComponent from "../../../components/Search";
import { useNavigate } from "react-router-dom";

const CompleteProfilesTable = () => {
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const completeFarmersPagination = sessionStorage.getItem(
    "complete_farmer_page"
  );

  const { completeProfiles, completeProfilesLoading, completeProfilesTotal } =
    useSelector((state) => state.farmer);

  const onPageChange = (val) => {
    const { page } = val;
    sessionStorage.setItem("complete_farmer_page", page);
    // setCurrentPage(page);

    dispatch(
      getCompleteFarmers({
        page: page,
        value: sessionStorage.getItem("complete_farmer_page"),
      })
    );
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        setSearchValue(val);
        sessionStorage.setItem("complete_farmer_search_value", val); // Store the search value in session storage
        //  api to dispatch and make the actions for search
        dispatch(
          getCompleteFarmersSearch({
            value: val,
            page: completeFarmersPagination ? completeFarmersPagination : 1,
          })
        );
      }, 750),
    [dispatch, completeFarmersPagination]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    const storedSearchValue = sessionStorage.getItem(
      "complete_farmer_search_value"
    );
    const page = completeFarmersPagination ? completeFarmersPagination : 1;
    setSearchValue(storedSearchValue);

    dispatch(
      getCompleteFarmers({ page: page, value: storedSearchValue || "" })
    );
  }, [dispatch, completeFarmersPagination]);

  return (
    <div>
      <TableBackground>
        {/*  */}
        <p className="text-primary font-semibold text-base leading-5 px-4 pt-4">
          Completed Profiles ({completeProfilesTotal})
        </p>

        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4 mt-4 px-4">
          <div className="w-full relative">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TBALE SECTION */}
        <section className="mt-[30px] mb-[24px]">
          {completeProfilesLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th scope="col" className="px-4 pb-2">
                    First Name
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Last Name
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Phone Number
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    State
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Local Government
                  </th>

                  <th scope="col" className="px-4 pb-2">
                    {" "}
                    Date
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Time
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Crop
                  </th>
                  <th scope="col" className="px-4 pb-2">
                    Rank
                  </th>
                </tr>
              </thead>
              <tbody>
                {completeProfiles.map((data, index) => {
                  return (
                    <tr
                      className="relative hover:bg-[#edf7ee]"
                      key={index}
                      onClick={() => navigate(`/farmers-profile/${data?.id}`)}
                    >
                      <td className="px-4">{data.first_name}</td>
                      <td className="px-4">{data.last_name}</td>
                      <td className="px-4">{data.phone_number}</td>
                      <td className="px-4">{data.state}</td>
                      <td className="px-4">{data.lga}</td>

                      <td className="px-4">
                        {moment(data.created).format("ll")}
                      </td>
                      <td className="px-4">
                        {moment(data.created).format("LT")}
                      </td>
                      <td className="px-4">{data.crop}</td>
                      <td className="py-4 pr-4 text-[#5C715E]">
                        <div
                          className={
                            data.rank === "Copper"
                              ? "copper-badge"
                              : data.rank === "Platinum"
                                ? "platinum-badge"
                                : data.rank === "Silver"
                                  ? "silver-badge"
                                  : data.rank === "Bronze"
                                    ? "bronze-badge"
                                    : data.rank === "Gold"
                                      ? "gold-badge"
                                      : null
                          }
                        >
                          <p>
                            {data.rank === "Copper"
                              ? "Copper"
                              : data?.rank || "N/A"}
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {!completeProfilesLoading && completeProfiles.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={
                  completeFarmersPagination
                    ? Number(completeFarmersPagination)
                    : 1
                }
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={completeProfilesTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default CompleteProfilesTable;
