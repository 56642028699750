import React, { useState, useEffect } from "react";
import ModalComponent from "../../../components/Modals/modal";
import { getFarmersProfile } from "../../../redux/Farmers/farmersActions";
import { useDispatch, useSelector } from "react-redux";
import { createACallToOrder } from "../../../redux/CallToOrder/callToOrderAction";
import { useNavigate } from "react-router-dom";

const CreateFarmerOrderModal = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [callDate, setCallDate] = useState("");
  const [orderType, setOrderType] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");

  const { farmersProfileData } = useSelector((state) => state.farmer);

  //   REDUX STATES
  // const { farmers } = useSelector((state) => state.farmer);
  const { createCallToOrderMessage, createCallToOrderLoading } = useSelector(
    (state) => state.callToOrder
  );

  const handleSave = () => {
    const values = {
      call_date: callDate,
      farmer_id: id,
      is_group_order: orderType === "Personal" ? false : true,
    };

    dispatch(
      createACallToOrder({
        values: values,
      })
    );

    // setIsOpenModal(false)
  };

  useEffect(() => {
    dispatch(getFarmersProfile({ id: id }));
    if (createCallToOrderMessage) {
      navigate(`/call-to-order/${createCallToOrderMessage}`);
    }
  }, [createCallToOrderMessage, navigate, dispatch, id]);

  return (
    <div>
      <div
        onClick={() => setIsOpenModal(true)}
        className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]"
      >
        <p className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]">
          Create Order
        </p>
      </div>

      {/* CREATE ORDER MODAL */}

      <ModalComponent
        title="Create Call To Order"
        show={isOpenModal}
        showModal={() => setIsOpenModal(false)}
      >
        <div className="mt-[20px] w-full">
          {/* PHONE NUMBER */}
          <div className="pb-4">
            <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
              Phone Number
            </p>
            <input
              value={farmersProfileData?.phone_number}
              disabled
              className="w-full outline-none py-[12px] px-[16px] border border-[#96A397] rounded-[8px] text-medium text-sm leading-5 text-[#96A397] focus:border-[#2B8C34] focus:outline-none  
              focus:shadow-input"
            />
            <p className="pt-1 font-medium text-xs leading-[16px] text-[#5C715E]">
              {farmersProfileData?.first_name} {""}{" "}
              {farmersProfileData?.last_name}
            </p>
          </div>

          {/* CALL DATE AND TIME */}
          <div className="flex gap-[24px]">
            <div className="pb-5 w-full">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                Call Date and Time
              </p>
              <input
                type="datetime-local"
                onChange={(event) => setCallDate(event.target.value)}
                className="w-full outline-none py-[12px] px-[16px] border border-[#96A397] rounded-[8px] text-medium text-sm leading-5 text-[#96A397] focus:border-[#2B8C34] focus:outline-none  
                focus:shadow-input"
                placeholder="Phone Number"
              />
            </div>
          </div>

          {/* ORDER TYPE */}
          <div className="flex gap-[24px]">
            <div className="pb-[23px] w-full">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                Order Type
              </p>
              <div className="flex gap-[28px]">
                <div className="flex items-center">
                  <input
                    onChange={() => setOrderType("Personal")}
                    type="radio"
                    id="Order Type"
                    name="Order Type"
                    status="30"
                    className="w-[13px] h-[13px]"
                  />
                  <label
                    for="Order Type"
                    className="pl-[8px]  pt-[2px]  font-[500] text-sm leading-[18px] text-[#5C715E]"
                  >
                    Personal
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    onChange={(event) => setOrderType("Group")}
                    type="radio"
                    id="Order Type"
                    name="Order Type"
                    status="30"
                    className="w-[13px] h-[13px]"
                  />
                  <label
                    for="Order Type"
                    className="pl-[8px] pt-[2px]  font-[500] text-sm leading-[18px] text-[#5C715E]"
                  >
                    Group
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* STATE AND LGA */}

          {/* DELIVERY STATUS */}
          <div className="flex gap-[24px]">
            <div className="pb-5 w-full">
              <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                Delivery Address
              </p>
              <textarea
                id="address"
                rows="6"
                // status={summarystatus}
                // onChange={handleSummaryChange}
                className="appearance-none w-full
                placeholder:text-[#96A397] placeholder:text-sm
                    text-[#5C715E]
                    text-sm resize-none
                    focus:border-[#2B8C34] focus:outline-none  
                    focus:shadow-input rounded-lg border border-[#96A397]
                    bg-white py-3 px-4"
                placeholder="Delivery Address"
                onChange={(event) => setDeliveryAddress(event.target.value)}
              ></textarea>
            </div>
          </div>

          {/* SAVE BUTTON */}
          <div>
            <button
              onClick={handleSave}
              disabled={
                Object.keys(farmersProfileData).length !== 0 &&
                callDate !== "" &&
                orderType !== "" &&
                deliveryAddress !== "" &&
                createCallToOrderLoading !== true
                  ? false
                  : true
              }
              type="button"
              className="w-full text-white bg-primary py-3 flex justify-center items-center
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] font-medium
                         rounded-lg text-"
            >
              Save
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default CreateFarmerOrderModal;
