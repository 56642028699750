import React from "react";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import ActiveLoansTable from "../../tables/Loans/ActiveLoansTable";
import { CardWithFilter, Card } from "../../components/Cards";

const ActiveLoansPage = () => {
  return (
    <>
      <GoBack />
      <div className="mt-4">
        <Header text="Active Loans" />
      </div>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <CardWithFilter
          title="Total Loanee"
          subtitle={`0`}
          color="border-l-[#B92043]"
        ></CardWithFilter>
        <Card
          title="Total Loan Amount"
          subtitle={`₦0`}
          href="/agrofastcash/active-loans"
        />
        <CardWithFilter
          title="Total Loan Portfolio"
          subtitle={`4`}
        ></CardWithFilter>
        <CardWithFilter
          title="Total Arrears"
          subtitle={`10`}
          href="/agrofastcash/groups"
        ></CardWithFilter>
      </div>
      <ActiveLoansTable />
    </>
  );
};

export default ActiveLoansPage;
