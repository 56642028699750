import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getFarmersOrders } from "../../../redux/Farmers/farmersActions";
import { getAllLGA, getAllState } from "../../../redux/Location/locationAction";
import cogoToast from "cogo-toast";
import TableBackground from "../../../components/TableBackground";
import { ErrorOutline, ExpandMore, ShoppingCart } from "@mui/icons-material";
import {
  checkoutOrder,
  // updateAnOrder,
} from "../../../redux/CallToOrder/callToOrderAction";
import ModalComponent from "../../../components/Modals/modal";
import emptyCart from "../../../assets/icons/Group 992.svg";

const Cart = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedOrder, setSelectedOrder] = useState([]);
  const { stateData, lgaData } = useSelector((state) => state.location);
  const { farmersOrdersData, farmersOrdersLoading, farmersOrdersTotal } =
    useSelector((state) => state.farmer);
  const { updateCallToOrderShortCode, isShortCodeClick } = useSelector(
    (state) => state.callToOrder
  );

  // const [openRemoveItemModal, setOpenRemoveItemModal] = useState(false);
  ////////////////////////LOCATION STATES
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [isClickState, setIsClickState] = useState(false);
  const [isClickLGA, setIsClickLGA] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [selectedLGA, setSelectedLGA] = useState({});

  // STATES
  const [clickShortCode, setClickShortCode] = useState(false);
  const [isAddToCart] = useState(false);
  const [setTotalPrice] = useState(0);
  const [clickedTab, setClickedTab] = useState("Payment Method");
  const [clickedPayment, setClickedPayment] = useState("");
  const [isClickCheckout, setIsClickCheckout] = useState(false);
  const tab = ["Payment Method", "Confirm Order"];
  const [cart, setCart] = useState([]);
  // const [setClickedIndex] = useState("");
  // const [setClickedProduct] = useState("");
  // const [setType] = useState("");
  // const [setOrderID] = useState(0);

  const handleStateClick = () => {
    setIsClickState(!isClickState);
    dispatch(getAllState());
  };

  const handleSelectedState = (data) => {
    setSelectedState({
      key: data.id,
      name: data.display_name,
    });
    setIsClickState(false);

    dispatch(
      getAllLGA({
        id: data.id,
      })
    );
  };

  const handleLgaClick = () => {
    if (Object.keys(selectedState).length !== 0) {
      setIsClickLGA(!isClickLGA);
    } else {
      cogoToast.error("Kindly select a state first");
    }
  };

  // const handleOpenRemoveItemFromCart = (dataIndex, data, type, orderID) => {
  //   setClickedIndex(dataIndex);
  //   setClickedProduct(data);
  //   // setOpenRemoveItemModal(true);
  //   setType(type);
  //   setOrderID(orderID);
  // };

  // const handleAddItemFromCart = (quan, type, orderID) => {
  //   let updatedQuantity = quan + 1;

  //   dispatch(
  //     updateAnOrder({
  //       id: id,
  //       order_type: type,
  //       order_id: orderID,
  //       quantity: updatedQuantity,
  //     })
  //   );

  //   setTimeout(() => {
  //     dispatch(
  //       getFarmersOrders({
  //         id: id,
  //       })
  //     );
  //   }, 800);
  // };

  // const handleRemoveItemFromCart = (quan, type, orderID) => {
  //   if (quan === 1) {
  //     cogoToast.error("Can not go below one");
  //   } else {
  //     let updatedQuantity = quan - 1;

  //     dispatch(
  //       updateAnOrder({
  //         id: id,
  //         order_type: type,
  //         order_id: orderID,
  //         quantity: updatedQuantity,
  //       })
  //     );

  //     setTimeout(() => {
  //       dispatch(
  //         getFarmersOrders({
  //           id: id,
  //         })
  //       );
  //     }, 800);
  //   }
  //   // eslint-disable-next-line
  //   // const newState = cart.map((obj, index) => {
  //   //   // eslint-disable-next-line
  //   //   // 👇️ if id equals 2, update country property
  //   //   if (index === dataIndex) {
  //   //     setTotalPrice(totalPrice * obj.quantity);
  //   //     return {
  //   //       ...obj,
  //   //       quantity: obj.quantity - 1,
  //   //       price: obj.price - obj.price,
  //   //     };
  //   //   }

  //   //   // 👇️ otherwise return the object as is
  //   //   return obj;
  //   // });
  //   // setCart(newState);
  // };

  // const handleDeleteItemFronCart = () => {
  //   // setOpenRemoveItemModal(false);

  //   dispatch(deleteAnOrder({ id: id, order_type: type, order_id: orderID }));

  // setTimeout(() => {
  //   dispatch(
  //     getFarmersOrders({
  //       id: id,
  //     })
  //   );
  // }, 800);
  //   // let data = [...cart];
  //   // const newState = data.filter((obj, index) => index !== clickedIndex);

  //   // setCart(newState);
  //   // cogoToast.success("The Item has been removed");
  // };

  const handleCheckout = () => {
    let items = selectedOrder.map((order) => order.product_name).join(",");
    dispatch(
      checkoutOrder({
        id: id,
        withhold_payment: clickedPayment === "Instant Payment" ? false : true,
        items: items,
      })
    );

    setTimeout(() => {
      setIsClickCheckout(false);
      dispatch(getFarmersOrders({ id: id }));
    }, 800);
  };

  const handleCloseShortCode = () => {
    setClickShortCode(true);
    dispatch(getFarmersOrders({ id: id }));
    setCart([]);
    setTotalPrice(0);
  };

  const handleCheckbox = (data) => {
    if (selectedOrder.length !== 0) {
      if (selectedOrder.some((orders) => orders.id === data.id)) {
        // console.log("I have this id");
        setSelectedOrder(selectedOrder.filter((items) => items.id !== data.id));
      } else {
        // console.log("I don't have this id");
        setSelectedOrder((prev) => [...prev, data]);
      }
    } else {
      setSelectedOrder((prev) => [...prev, data]);
    }
  };

  // const handleCheckoutModal = () => {
  //   setIsClickCheckout(true);
  //   setTotalAmount(
  //     selectedOrder.reduce((accumulator, { total_display_price }) => {
  //       return accumulator + Number(total_display_price);
  //     }, 0)
  //   );
  // };

  useEffect(() => {
    dispatch(getFarmersOrders({ id: id }));
  }, [dispatch, id]);

  return (
    <div>
      {farmersOrdersLoading ? (
        <div className="flex justify-center items-center h-[500px] w-full">
          {" "}
          <p className="font-medium text-sm leading-5">Loading...</p>
        </div>
      ) : (
        <>
          {farmersOrdersData.length !== 0 ? (
            <div className="gap-4 flex">
              <div className="w-[32%] h-full bg-white rounded-[8px] p-4">
                <>
                  <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] mb-6">
                    Delivery Address<span className="text-[#B92043]">*</span>
                  </p>
                  {/* STATE AND LGA */}
                  {/* <div className="flex gap-[24px]">
              
                    <div className="pb-4 w-1/2">
                      <div className="relative w-full">
                        <div
                          className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                          onClick={handleStateClick}
                        >
                          <p className="font-medium text-sm leading-[20px] text-[#344335]">
                            {selectedState.name || "Select a State"}
                          </p>
                          <ExpandMore
                            className="text-[#ABB6AC] cursor-pointer"
                            style={{ fontSize: "18px" }}
                          />
                        </div>
                        {isClickState && (
                          <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                            {stateData?.map((data, index) => {
                              return (
                                <p
                                  className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                  key={index}
                                  onClick={() => handleSelectedState(data)}
                                >
                                  {data.display_name}
                                </p>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                 
                    <div className="pb-4 w-1/2">
                      <div className="relative w-full">
                        <div
                          className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                          onClick={handleLgaClick}
                        >
                          <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                            {selectedLGA.name || "Select Lga"}
                          </p>
                          <ExpandMore
                            className="text-[#ABB6AC] cursor-pointer"
                            style={{ fontSize: "18px" }}
                          />
                        </div>
                        {isClickLGA && (
                          <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                            {lgaData?.map((data, index) => {
                              return (
                                <p
                                  className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                  key={index}
                                  onClick={() => {
                                    setSelectedLGA({
                                      key: data.id,
                                      name: data.display_name,
                                    });
                                    setIsClickLGA(false);
                                  }}
                                >
                                  {data.display_name}
                                </p>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}

                  {/* DELIVERY STATUS */}
                  <div className="flex gap-[24px]">
                    <div className="w-full">
                      <textarea
                        id="address"
                        rows="6"
                        // status={summarystatus}
                        // onChange={handleSummaryChange}
                        className="appearance-none w-full bg-[#F7F7F7]
                placeholder:text-[#96A397] placeholder:text-sm
                    text-[#5C715E]
                    text-sm resize-none
                     focus:outline-none  
                    focus:shadow-input rounded-lg 
                     py-3 px-4"
                        placeholder="Delivery Address"
                        onChange={(event) =>
                          setDeliveryAddress(event.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                </>
              </div>
              <div className="w-[68%]">
                <div className="flex  items-center justify-between gap-[20px] mb-[15px] bg-white rounded-[8px] p-4">
                  <div className="">
                    <p className="text-sm leading-[18px] font-semibold text-[#7C8D7E]">
                      Total Amount
                    </p>
                    <p className="text-2xl leading-[30px] font-semibold text-[#2B8C34] pt-[2px]">
                      ₦
                      {farmersOrdersTotal &&
                        parseFloat(farmersOrdersTotal).toLocaleString("en-US")}
                      {/* {callToOrderInfoData.checkout
                ? callToOrderInfoData?.total_display_price
                : totalPrice?.toLocaleString()} */}
                    </p>
                  </div>

                  {/* <>
                    {farmersOrdersLoading ? (
                      <p>Loading...</p>
                    ) : (
                      <div className="flex items-center gap-[24px]">
                        <button
                          type="submit"
                          disabled={
                            selectedOrder.length !== 0 &&
                            deliveryAddress !== "" &&
                            Object.keys(selectedState).length !== 0 &&
                            Object.keys(selectedLGA).length !== 0
                              ? false
                              : true
                          }
                          // disabled={farmersOrdersData.length === 0 ? true : false}
                          onClick={handleCheckoutModal}
                          className="bg-primary
                            disabled:bg-[#ABB6AC] 
                            disabled:text-white
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                        >
                          Checkout
                        </button>
                      </div>
                    )}
                  </> */}
                </div>

                <>
                  <div>
                    {/* 2 */}
                    <TableBackground classname="relative p-[16px] rounded-[8px] max-h-[450px] overflow-y-auto">
                      <p className="text-sm font-semibold text-[#7C8D7E] leading-[18px]">
                        Items Details
                      </p>

                      {farmersOrdersData?.map((data, index) => {
                        return (
                          <>
                            <div className="pt-[16px] pb-[16px] flex gap-[12px]">
                              <div>
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 accent-primary"
                                  onClick={() => handleCheckbox(data)}
                                />

                                <br />
                              </div>

                              <div
                                className="flex justify-between w-full "
                                key={index}
                              >
                                <div className="">
                                  <p className="font-semibold text-sm leading-[18px] text-[#344335] pb-[8px]">
                                    {data?.name || "N/A"}
                                  </p>
                                  <p className="font-[400] text-sm leadin-[18px] text-[#344335] pb-[8px]">
                                    {data?.vendor_name || "Crop2cash"}
                                  </p>

                                  {/* <div className="flex gap-[8px] items-center">
                                    <div className="flex items-center px-[16px] gap-[28px] justify-between w-[118px] h-[36px] bg-[#ECEFEC] rounded-[8px]">
                                      <Remove
                                        onClick={() =>
                                          handleRemoveItemFromCart(
                                            data?.quantity,
                                            data?.type,
                                            data?.id
                                          )
                                        }
                                        style={{
                                          fontSize: "16px",
                                          cursor: "pointer",
                                          color: "#344335",
                                        }}
                                      />
                                      <p className="text-[#344335] leading-5 text-semibold text-sm">
                                        {data?.quantity}
                                      </p>
                                      <Add
                                        onClick={() =>
                                          handleAddItemFromCart(
                                            data?.quantity,
                                            data?.type,
                                            data?.order_id
                                          )
                                        }
                                        style={{
                                          fontSize: "16px",
                                          cursor: "pointer",
                                          color: "#344335",
                                        }}
                                      />
                                    </div>
                                    <div className="border border-l-[#96A397] h-[36px]" />
                                    <p
                                      className="text-[#9B7000] text-sm font-medium leading-5 text-center cursor-pointer"
                                      onClick={() =>
                                        handleOpenRemoveItemFromCart(
                                          index,
                                          data?.product_name,
                                          data?.type,
                                          data?.id
                                        )
                                      }
                                    >
                                      Delete Item
                                    </p>
                                  </div> */}
                                </div>
                                <div className="">
                                  <p className="font-medium text-sm leading-5 text-[#344335]">
                                    ₦{data?.total_display_price} | {""}
                                    {data?.product_size && data.measurement
                                      ? data?.product_size +
                                        " " +
                                        data.measurement
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                      <div>
                        <hr className="bg-[DDE2DE]" />
                      </div>
                    </TableBackground>
                  </div>
                </>
              </div>

              {/* MODAL */}
              <>
                {/* CHECKOUT MODAL ONE */}
                <ModalComponent
                  title="Checkout"
                  show={isClickCheckout}
                  showModal={() => setIsClickCheckout(false)}
                >
                  <div className="mt-[12px]">
                    <div className="flex gap-[12px] mb-5">
                      {tab.map((data, index) => {
                        return (
                          <p
                            className={`font-semibold text-xs leading-4 ${
                              data === clickedTab
                                ? "text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-default"
                                : "text-[#96A397] cursor-pointer "
                            } `}
                            key={index}
                          >
                            {data}
                          </p>
                        );
                      })}
                    </div>
                    {clickedTab === "Delivery Address" ? (
                      <>
                        {/* STATE AND LGA */}
                        <div className="flex gap-[24px]">
                          <div className="pb-4 w-1/2">
                            <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                              State
                            </p>
                            <div className="relative w-full">
                              <div
                                className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                                onClick={handleStateClick}
                              >
                                <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                                  {selectedState.name || "Select a State"}
                                </p>
                                <ExpandMore
                                  className="text-[#ABB6AC] cursor-pointer"
                                  style={{ fontSize: "18px" }}
                                />
                              </div>
                              {isClickState && (
                                <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                                  {stateData?.map((data, index) => {
                                    return (
                                      <p
                                        className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                        key={index}
                                        onClick={() =>
                                          handleSelectedState(data)
                                        }
                                      >
                                        {data.display_name}
                                      </p>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="pb-4 w-1/2">
                            <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                              LGA
                            </p>
                            <div className="relative w-full">
                              <div
                                className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                                onClick={handleLgaClick}
                              >
                                <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                                  {selectedLGA.name || "Select Lga"}
                                </p>
                                <ExpandMore
                                  className="text-[#ABB6AC] cursor-pointer"
                                  style={{ fontSize: "18px" }}
                                />
                              </div>
                              {isClickLGA && (
                                <div className="absolute right-0 w-full bg-white padding-[8px] mt-[4%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                                  {lgaData?.map((data, index) => {
                                    return (
                                      <p
                                        className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                        key={index}
                                        onClick={() => {
                                          setSelectedLGA({
                                            key: data.id,
                                            name: data.display_name,
                                          });
                                          setIsClickLGA(false);
                                        }}
                                      >
                                        {data.display_name}
                                      </p>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* DELIVERY STATUS */}
                        <div className="flex gap-[24px] mb-[52px]">
                          <div className="pb-5 w-full">
                            <p className="pb-1 font-[500] text-sm leading-[18px] text-[#344335]">
                              Delivery Address
                            </p>
                            <textarea
                              id="address"
                              rows="6"
                              // status={summarystatus}
                              // onChange={handleSummaryChange}
                              className="appearance-none w-full
                placeholder:text-[#96A397] placeholder:text-sm
                    text-[#5C715E]
                    text-sm resize-none
                    focus:border-[#2B8C34] focus:outline-none  
                    focus:shadow-input rounded-lg border border-[#96A397]
                    bg-white py-3 px-4"
                              placeholder="Delivery Address"
                              onChange={(event) =>
                                setDeliveryAddress(event.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>

                        <button
                          type="submit"
                          disabled={
                            selectedState?.name !== "" &&
                            selectedLGA?.name !== "" &&
                            deliveryAddress !== ""
                              ? false
                              : true
                          }
                          onClick={
                            selectedState?.name !== "" &&
                            selectedLGA?.name !== "" &&
                            deliveryAddress !== ""
                              ? () => setClickedTab("Payment Method")
                              : null
                          }
                          className="bg-primary w-full
                            disabled:bg-[#ABB6AC] 
                            disabled:text-white
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                        >
                          Next
                        </button>
                      </>
                    ) : clickedTab === "Payment Method" ? (
                      <div>
                        <div
                          className={`rounded-[8px] py-[12px] px-[16px] cursor-pointer mb-[16px] ${
                            clickedPayment === "Instant Payment"
                              ? "bg-[#EDF7EE] border border-[#2B8C34] rounded-[8px] "
                              : "bg-[#F7F7F7] border rounded-[8px]"
                          }`}
                          onClick={() => setClickedPayment("Instant Payment")}
                        >
                          <p
                            className={`text-[#344335] text-sm font-semibold pb-[4px] leading-5 ${
                              clickedPayment === "Instant Payment"
                                ? "  text-primary"
                                : " text-[#344335]"
                            }`}
                          >
                            Instant Payment
                          </p>
                          <p
                            className={`text-[#5C715E] text-sm font-medium leading-5 ${
                              clickedPayment === "Instant Payment"
                                ? "  text-primary"
                                : " text-[#344335]"
                            }`}
                          >
                            Payment is accepted when order is placed
                          </p>
                        </div>

                        <div
                          className={`rounded-[8px] py-[12px] px-[16px] cursor-pointer mb-[16px] ${
                            clickedPayment === "Payment On Delivery"
                              ? "bg-[#EDF7EE] border border-[#2B8C34] rounded-[8px] "
                              : "bg-[#F7F7F7] border rounded-[8px]"
                          }`}
                          onClick={() =>
                            setClickedPayment("Payment On Devlivery")
                          }
                        >
                          <p
                            className={`text-[#344335] text-sm font-semibold pb-[4px] leading-5 ${
                              clickedPayment === "Payment On Devlivery"
                                ? "  text-primary"
                                : " text-[#344335]"
                            }`}
                          >
                            Payment on the delivery
                          </p>
                          <p
                            className={`text-[#5C715E] text-sm font-medium leading-5 ${
                              clickedPayment === "Payment On Devlivery"
                                ? "  text-primary"
                                : " text-[#344335]"
                            }`}
                          >
                            Payment is held then accepted when the order is
                            received
                          </p>
                        </div>

                        <button
                          type="submit"
                          disabled={clickedPayment !== "" ? false : true}
                          onClick={
                            clickedPayment !== ""
                              ? () => setClickedTab("Confirm Order")
                              : null
                          }
                          className="bg-primary w-full mt-[100px]
                            disabled:bg-[#ABB6AC] 
                            disabled:text-white
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                        >
                          Next
                        </button>
                      </div>
                    ) : clickedTab === "Confirm Order" ? (
                      <div>
                        <div
                          className={`rounded-[8px] py-[12px] px-[16px] cursor-pointer bg-[#EDF7EE] mb-[8px]`}
                        >
                          <p
                            className={`text-sm font-semibold leading-5 text-primary`}
                          >
                            {clickedPayment}
                          </p>
                        </div>

                        <div className="bg-[#F7F7F7] rounded-[8px] p-2 mb-[20px]">
                          <p className="font-medium text-xs leading-4 text-[#96A397] pb-[2px]">
                            Delivery Address{" "}
                          </p>
                          <p className="font-semibold text-sm leading-5 text-[#344335] pb-[2px]">
                            {deliveryAddress}
                          </p>
                          <p className="font-medium text-xs leading-4 text-[#344335] pb-[2px]">
                            {selectedState?.name} | {selectedLGA?.name}
                          </p>
                        </div>

                        <div className="">
                          {cart?.map((data, index) => {
                            return (
                              <div
                                className="flex justify-between mb-[12px]"
                                key={index}
                              >
                                <div className="flex gap-2">
                                  <div className="bg-primary rounded-[4px] items-center justify-center flex w-[20px] h-[22px] font-medium text-sm leading-[18px] text-white">
                                    <p>{index + 1}</p>
                                  </div>
                                  <div className="">
                                    <p className="font-semibold text-sm leading-5 text-[#344335] pb-1">
                                      {data?.product_name}
                                      <span className="font-medium">
                                        {" "}
                                        | {data?.product_size}
                                        {data.measurement}
                                      </span>
                                    </p>
                                    <p className="font-medium text-sm leading-5 text-[#344335] pb-1">
                                      {data?.vendor_name}
                                    </p>
                                  </div>
                                </div>
                                <div className="">
                                  <p className="font-medium text-sm leading-5 text-[#344335]">
                                    ₦{data?.price}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                          <div className="flex justify-end pt-1 border-t-[2px] border-t-[#ECEEEC] mb-[38px]">
                            <p className="font-medium text-sm leading-5 text-[#7C8D7E]">
                              Subtotal:
                              <span className="font-semibold text-primary text-base">
                                {" "}
                                {/* ₦{totalAmount} */}
                              </span>
                            </p>
                          </div>

                          <button
                            type="submit"
                            disabled={false}
                            onClick={handleCheckout}
                            className="bg-primary w-full
                            disabled:bg-[#ABB6AC] 
                            disabled:text-white
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </ModalComponent>

                {/* SHORT CODE MODAL */}
                <ModalComponent
                  show={clickShortCode ? false : isShortCodeClick}
                  showModal={handleCloseShortCode}
                >
                  <div className="flex flex-col justify-center items-center">
                    <ShoppingCart
                      style={{ fontSize: "24px", color: "#2B8C34" }}
                    />
                    <p className="font-semibold text-base leading-6 text-[#344335] pt-[19px]">
                      {isAddToCart
                        ? "Order has been added to cart"
                        : "  Order has been sent"}
                    </p>

                    {isAddToCart ? null : (
                      <>
                        {" "}
                        <p className="font-medium text-xs leading-4 text-[#5C715E] pt-[8px]">
                          A Short Code has been sent to hold payment
                        </p>
                        <p className="font-semibold text-xl leading-5 text-primary pt-[8px]">
                          *346*46*{updateCallToOrderShortCode}#
                        </p>
                      </>
                    )}

                    <div className="flex gap-1 text-[#0066AF] bg-[#E7FBFE] font-medium text-xs leading-4 items-center p-2 mt-5">
                      <ErrorOutline style={{ fontSize: "16px" }} />
                      <p>
                        You can track the payment status on the order history
                      </p>
                    </div>

                    <p
                      className="font-medium text-sm leading-5 text-[#5C715E] pt-[20px] mb-[44px] cursor-pointer"
                      onClick={handleCloseShortCode}
                    >
                      Close
                    </p>
                  </div>
                </ModalComponent>
              </>
            </div>
          ) : (
            <div className="mt-[67px] flex flex-col justify-center items-center w-full">
              <img src={emptyCart} alt="Empty cart" className="" />
              <p className="mt-2 font-semibold text-xs leading-4 text-[#344335]">
                Looks like we have an empty cart
              </p>
              <p className="mt-2 font-medium text-xs leading-4 text-[#7C8D7E] text-center">
                Add items to cart by creating <br /> orders
              </p>
              <p
                className="mt-[10px] font-medium text-sm cursor-pointer leading-4 text-primary text-center"
                onClick={() => navigate(`/call-to-order`)}
              >
                Create Order
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Cart;
