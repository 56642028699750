import React, { useEffect, useState } from "react";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import TableBackground from "../../components/TableBackground";
import { ContentCopy } from "@mui/icons-material";
import {
  Bronze,
  Copper,
  Gold,
  Plantinum,
  Silver,
} from "../../components/Ranks/Rank";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ModalComponent from "../../components/Modals/modal";
import { agentApproval, getAgent } from "../../redux/Agents/agentsAction";
import { useNavigate, useParams } from "react-router-dom";
import cogoToast from "cogo-toast";
import { ButtonPrimary } from "../../components/Button";

const ApprovalInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const { agentData, agentApprovalLoading } = useSelector(
    (state) => state.agents
  );
  const [reason, setReason] = useState("");
  const { is_team_lead } = useSelector((state) => state.auth);
  const [isShowAge, setIsShowAge] = useState(false);
  const [isShowBvn, setIsShowBvn] = useState(false);

  const showModal = () => {
    setShow(!show);
  };

  const showRejectModal = () => {
    setShowReject(!showReject);
  };

  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  const handleStatus = (status) => {
    const approvedvalues = {
      action: status,
    };

    const rejectedValues = {
      action: status,
      reason: reason,
    };
    dispatch(
      agentApproval({
        id,
        values: status === "approve" ? approvedvalues : rejectedValues,
      })
    ).then((res) => {
      if (res.type === "agentApproval/fulfilled") {
        setShowReject(false);
        setShow(false);
        dispatch(getAgent({ id: id }));
        navigate("/pending-agent-approval");
      }
    });
  };

  useEffect(() => {
    dispatch(getAgent({ id: id }));
  }, [dispatch, id]);
  return (
    <div>
      <div>
        <GoBack />

        <div className="mt-6">
          <Header text="Agent Approval" />
        </div>

        <div className="mt-6">
          <TableBackground
            classname="px-4 pt-4"
            style={{ borderRadius: "8px" }}
          >
            <p className="text-sm font-bold leading-[18px] text-[#7C8D7E] mb-[19px]">
              Agent Details
            </p>
            <div className="flex w-full">
              <div className="flex items-center gap-[8px]">
                {sessionStorage.setItem(
                  "phone_number",
                  agentData?.phone_number
                )}
                {/* FARMER"S INITIALS */}

                <>
                  {agentData?.rank === "Plantinum" ? (
                    <Plantinum>
                      {" "}
                      {agentData?.first_name?.charAt(0)}
                      {agentData?.last_name?.charAt(0)}
                    </Plantinum>
                  ) : agentData?.rank === "Gold" ? (
                    <Gold>
                      {" "}
                      {agentData?.first_name?.charAt(0)}
                      {agentData?.last_name?.charAt(0)}
                    </Gold>
                  ) : agentData?.rank === "Silver" ? (
                    <Silver>
                      {" "}
                      {agentData?.first_name?.charAt(0)}
                      {agentData?.last_name?.charAt(0)}
                    </Silver>
                  ) : agentData?.rank === "Bronze" ? (
                    <Bronze>
                      {" "}
                      {agentData?.first_name?.charAt(0)}
                      {agentData?.last_name?.charAt(0)}
                    </Bronze>
                  ) : (
                    <Copper>
                      {" "}
                      {agentData?.first_name?.charAt(0)}
                      {agentData?.last_name?.charAt(0)}
                    </Copper>
                  )}
                </>

                <div className="">
                  <p
                    className={` ${
                      agentData?.rank === "Plantinum"
                        ? "text-[#4B4E68]"
                        : agentData?.rank === "Gold"
                        ? "text-[#F9B43B]"
                        : agentData?.rank === "Silver"
                        ? "text-[#4B7295]"
                        : agentData?.rank === "Bronze"
                        ? "text-[#E86454]"
                        : "text-[#6A1505]"
                    } font-semibold leading-[18px] text-sm`}
                  >
                    {agentData?.first_name} {agentData?.last_name}
                  </p>
                  <div
                    className={`  ${
                      agentData?.rank === "Plantinum"
                        ? "text-[#4B4E68]"
                        : agentData?.rank === "Gold"
                        ? "text-[#F9B43B]"
                        : agentData?.rank === "Silver"
                        ? "text-[#4B7295]"
                        : agentData?.rank === "Bronze"
                        ? "text-[#E86454]"
                        : "text-[#6A1505]"
                    } flex gap-[6px] mt-[4px] cursor-pointer`}
                    onClick={() => handleCopyNumber(agentData?.phone_number)}
                  >
                    <p
                      className={`  ${
                        agentData?.rank === "Plantinum"
                          ? "text-[#4B4E68]"
                          : agentData?.rank === "Gold"
                          ? "text-[#F9B43B]"
                          : agentData?.rank === "Silver"
                          ? "text-[#4B7295]"
                          : agentData?.rank === "Bronze"
                          ? "text-[#E86454]"
                          : "text-[#6A1505]"
                      }font-semibold leading-[18px] text-sm`}
                    >
                      {agentData?.phone_number}
                    </p>
                    <ContentCopy
                      className={`  ${
                        agentData?.rank === "Plantinum"
                          ? "text-[#4B4E68]"
                          : agentData?.rank === "Gold"
                          ? "text-[#F9B43B]"
                          : agentData?.rank === "Silver"
                          ? "text-[#4B7295]"
                          : agentData?.rank === "Bronze"
                          ? "text-[#E86454]"
                          : "text-[#6A1505]"
                      }`}
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-6 gap-[20px]">
              {/* 1 */}
              <div className="w-1/4">
                {/* SEX */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Sex
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.gender === "m" ||
                    agentData?.gender?.toLowerCase() === "male"
                      ? "Male"
                      : agentData?.gender === "f" ||
                        agentData?.gender?.toLowerCase() === "female"
                      ? "Female"
                      : "N/A"}
                  </p>
                </div>

                {/* LGA */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    State
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.state || "N/A"}
                  </p>
                </div>
              </div>

              {/* 2 */}
              <div className="w-1/4">
                {/* BVN */}
                <div className="mb-[24px]">
                  <div className="flex items-center gap-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      BVN
                    </p>
                    {is_team_lead && (
                      <>
                        {isShowBvn ? (
                          <Visibility
                            onClick={
                              is_team_lead
                                ? () => setIsShowBvn(!isShowBvn)
                                : null
                            }
                            style={{ fontSize: "18px" }}
                            className="text-[#7C8D7E]"
                          />
                        ) : (
                          <VisibilityOff
                            onClick={
                              is_team_lead
                                ? () => setIsShowBvn(!isShowBvn)
                                : null
                            }
                            style={{ fontSize: "18px" }}
                            className="text-[#7C8D7E]"
                          />
                        )}
                      </>
                    )}
                  </div>
                  {isShowBvn ? (
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {agentData?.bvn || "N/A"}
                    </p>
                  ) : (
                    <p className="font-medium text-sm leading-5 text-[#344335]">
                      {agentData?.bvn ? (
                        <>
                          {" "}
                          {agentData?.bvn?.charAt(0)}**********
                          {agentData?.bvn?.charAt(10)}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </p>
                  )}
                </div>

                {/* LGA */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    LGA
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.lga || "N/A"}
                  </p>
                </div>
              </div>

              {/* 3 */}
              <div className="w-1/4">
                {/* BVN */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Account number
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.account_number || "N/A"}
                  </p>
                </div>

                {/* AGE */}
                <div className="mb-[24px]">
                  <div className="flex items-center gap-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Age
                    </p>
                    {is_team_lead && (
                      <>
                        {isShowAge ? (
                          <Visibility
                            onClick={
                              is_team_lead
                                ? () => setIsShowAge(!isShowAge)
                                : null
                            }
                            style={{ fontSize: "18px" }}
                            className="text-[#7C8D7E]"
                          />
                        ) : (
                          <VisibilityOff
                            onClick={
                              is_team_lead
                                ? () => setIsShowAge(!isShowAge)
                                : null
                            }
                            style={{ fontSize: "18px" }}
                            className="text-[#7C8D7E]"
                          />
                        )}
                      </>
                    )}
                  </div>
                  {isShowAge ? (
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {agentData?.age || "N/A"}
                    </p>
                  ) : (
                    <p className="font-medium text-sm leading-5 text-[#344335]">
                      **
                    </p>
                  )}
                </div>
              </div>

              {/* 4 */}
              <div className="w-1/4">
                {/* Bank */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Bank
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.bank || "N/A"}
                  </p>
                </div>

                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Date Of Birth
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.date_of_birth
                      ? moment(agentData?.date_of_birth).format("DD/MM")
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex gap-[20px]">
              {/* 1 */}
              <div className="w-1/4">
                {/* SEX */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Livestock
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.livestock || "N/A"}
                  </p>
                </div>

                {/* LGA */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Last Seen
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {moment(agentData?.updated).format("lll")}
                  </p>
                </div>
              </div>

              {/* 2 */}
              <div className="w-1/4">
                {/* Land Size */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Land Size
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {"N/A"}
                  </p>
                </div>

                {/* Registration Status */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Registration Status
                  </p>
                  <div
                    className={
                      agentData?.registration_status === "Complete"
                        ? "px-4 success-badge"
                        : agentData?.registration_status === "Incomplete"
                        ? " px-4 error-badge"
                        : " px-4 na-badge"
                    }
                  >
                    <p className="capitalize">
                      {agentData?.registration_status || "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              {/* 3 */}
              <div className="w-1/4">
                {/* Cooperative */}
                <div className="mb-[24px]">
                  <div className="flex items-center gap-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Cooperative
                    </p>
                  </div>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {agentData?.cooperative_name || "N/A"}
                  </p>
                </div>

                {/*  Status */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Status
                  </p>
                  <div
                    className={
                      agentData?.status === "Active"
                        ? "px-4 success-badge"
                        : agentData?.status === "Inactive"
                        ? " px-4 error-badge"
                        : " px-4 na-badge"
                    }
                  >
                    <p className="capitalize">{agentData?.status || "N/A"}</p>
                  </div>
                </div>
              </div>

              {/* 4 */}
              <div className="w-1/4">
                {/* SEX */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Date Joined
                  </p>
                  <p className="font-semibold text-sm leading-5 text-[#344335]">
                    {moment(agentData?.created).format("lll")}
                  </p>
                </div>

                {/* Loan Status */}
                <div className="mb-[24px]">
                  <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                    Loan Status
                  </p>
                  <div className="font-semibold text-sm leading-5 text-[#344335]">
                    <div
                      className={
                        agentData?.reg_status === "Complete"
                          ? "success-badge"
                          : agentData?.reg_status === "Incomplete"
                          ? "error-badge"
                          : agentData?.reg_status === "Temporary"
                          ? "pending-badge"
                          : "na-badge"
                      }
                    >
                      <p> {"N/A"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-end justify-end ">
              <div className="flex justify-end gap-6 mb-5 mt-4">
                <ButtonPrimary onclick={() => setShow(true)}>
                  Approve Agent
                </ButtonPrimary>
                <button
                  type="submit"
                  onClick={() => setShowReject(true)}
                  className="bg-[#FEF0EC] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7] w-fit
                    rounded-lg px-6 py-3 text-[#B92043] text-sm font-medium hover:bg-opacity-[0.9] transition-all"
                >
                  Reject Agent
                </button>
              </div>
            </div>
          </TableBackground>
        </div>
      </div>

      {/* MODALS */}
      {/* Approve Agent */}
      <ModalComponent
        show={show}
        showModal={showModal}
        title="Approve Agent Registration"
        subtitle="Are you sure you want to approve this agent registration?"
      >
        <div className="flex justify-end mt-8 mb-3">
          <button
            disabled={agentApprovalLoading}
            type="submit"
            onClick={() => handleStatus("approve")}
            className="bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Approve
          </button>
          <button
            onClick={() => setShow(false)}
            type="submit"
            className="bg-transparent
                    rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
          >
            Cancel
          </button>
        </div>
      </ModalComponent>

      {/* Reject Agent */}
      <ModalComponent
        show={showReject}
        showModal={showRejectModal}
        title="Reject Agent Registration"
        subtitle="Rejecting the agent's registration would mean the agent won’t be able to refer farmers"
      >
        <div className="mt-4">
          <p className="text-[#344335] text-sm">Reason for Rejection</p>
          <textarea
            id="reason"
            rows="8"
            values={reason}
            onChange={(e) => setReason(e.target.value)}
            className="appearance-none w-full
                        placeholder:text-[#96A397] mt-1 placeholder:text-sm
                        text-secondary1
                        text-sm border border-[#96A397]
                        focus:border-[#ABB6AC]
                        focus:border outline-none
                        rounded-lg resize-none
                        bg-[#FFFFFF] py-3 px-4"
            placeholder="State the reason for rejection"
          ></textarea>
        </div>

        <div className="mt-5 mb-3">
          <button
            onClick={() => handleStatus("reject")}
            disabled={reason === "" || agentApprovalLoading}
            type="submit"
            className="bg-[#B92043] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7] w-full
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-opacity-[0.9] hover:bg-[#B92043] transition-all"
          >
            Reject Cooperative
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ApprovalInfo;
