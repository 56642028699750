import React, { useCallback, useEffect, useState } from "react";
import call_icon from "../../assets/icons/call_icon.svg";
import cart_icon from "../../assets/icons/shopping_cart.svg";
import refund_icon from "../../assets/icons/refund_icon.svg";
import complaint_icon from "../../assets/icons/complaint_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  markAsReadNotification,
} from "../../redux/Notifications/notificationsActions";
import moment from "moment";
import PaginationComponent from "../../components/Pagination/Pagination";
import ModalComponent from "../../components/Modals/modal";
import { resetIsCleared } from "../../redux/Notifications/notifications";
import { useNavigate } from "react-router-dom";

const NotificationsPage = () => {
  const dispatch = useDispatch();
  const { notifications, count, loading, clearLoader, isCleared, total } = useSelector(
    (state) => state.notification
  );
  const navigate = useNavigate()
  const [typeId, setTypeID] = useState(1);
  const type = [
    {
      id: 1,
      title: "New",
    },
    {
      id: 2,
      title: "Cleared",
    },
  ];

  const [categoryID, setCategoryID] = useState(1);
  const [categoryDisplay, setCategoryDisplay] = useState("All");
  const categories = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Call Log",
    },
    {
      id: 3,
      title: "Complaints",
    },
    {
      id: 4,
      title: "Orders",
    },
    {
      id: 5,
      title: "Refunds",
    },
  ];

  const toggleType = (id) => {
    setTypeID(id);
    switch (categoryDisplay) {
      case "All":
        dispatch(
          getNotifications({
            page: 1,
            status: id === 1 ? "active" : "cleared",
            type: "all",
          })
        );
        break;
      case "Call Log":
        dispatch(
          getNotifications({
            page: 1,
            status: id === 1 ? "active" : "cleared",
            type: "call_logs",
          })
        );
        break;
      case "Vendors":
        dispatch(
          getNotifications({
            page: 1,
            status: id === 1 ? "active" : "cleared",
            type: "vendors",
          })
        );
        break;
      case "Complaints":
        dispatch(
          getNotifications({
            page: 1,
            status: id === 1 ? "active" : "cleared",
            type: "complaints",
          })
        );
        break;
      case "Orders":
        dispatch(
          getNotifications({
            page: 1,
            status: id === 1 ? "active" : "cleared",
            type: "orders",
          })
        );
        break;
      case "Refunds":
        dispatch(
          getNotifications({
            page: 1,
            status: id === 1 ? "active" : "cleared",
            type: "refunds",
          })
        );
        break;
      default:
        break;
    }
  };

  const handleSelectedCategory = (val) => {
    const { id, title } = val;
    setCategoryID(id);
    setCategoryDisplay(title);
    switch (title) {
      case "All":
        dispatch(
          getNotifications({
            page: 1,
            status: typeId === 1 ? "active" : "cleared",
            type: "all",
          })
        );
        break;
      case "Call Log":
        dispatch(
          getNotifications({
            page: 1,
            status: typeId === 1 ? "active" : "cleared",
            type: "call_logs",
          })
        );
        break;
      case "Vendors":
        dispatch(
          getNotifications({
            page: 1,
            status: typeId === 1 ? "active" : "cleared",
            type: "vendors",
          })
        );
        break;
      case "Complaints":
        dispatch(
          getNotifications({
            page: 1,
            status: typeId === 1 ? "active" : "cleared",
            type: "complaints",
          })
        );
        break;
      case "Orders":
        dispatch(
          getNotifications({
            page: 1,
            status: typeId === 1 ? "active" : "cleared",
            type: "orders",
          })
        );
        break;
      case "Refunds":
        dispatch(
          getNotifications({
            page: 1,
            status: typeId === 1 ? "active" : "cleared",
            type: "refunds",
          })
        );
        break;
      default:
        break;
    }
  };

  // format Title
  const formatTitle = (val) => {
    let res;
    switch (val) {
      case "call_logs":
        res = `Call Logs`;
        break;
      case "vendors":
        res = `Vendors`;
        break;
      case "refunds":
        res = `Refunds`;
        break;
      case "orders":
        res = `Orders`;
        break;
      case "complaints":
        res = `Complaints`;
        break;
      default:
        break;
    }
    return res;
  };

  const formatIcon = (val) => {
    let res;
    switch (val) {
      case "call_logs":
        res = call_icon;
        break;
      case "vendors":
        res = cart_icon;
        break;
      case "refunds":
        res = refund_icon;
        break;
      case "orders":
        res = cart_icon;
        break;
      case "complaints":
        res = complaint_icon;
        break;
      default:
        break;
    }
    return res;
  };

  const formatLeftIcon = (val) => {
    let res;
    switch (val) {
      case "Call Log":
        res = call_icon;
        break;
      case "Vendors":
        res = cart_icon;
        break;
      case "Refunds":
        res = refund_icon;
        break;
      case "Orders":
        res = cart_icon;
        break;
      case "Complaints":
        res = complaint_icon;
        break;
      default:
        break;
    }
    return res;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(!show);
  };

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    switch (categoryDisplay) {
      case "All":
        dispatch(
          getNotifications({
            page: page,
            status: typeId === 1 ? "active" : "cleared",
            type: "all",
          })
        );
        break;
      case "Call Log":
        dispatch(
          getNotifications({
            page: page,
            status: typeId === 1 ? "active" : "cleared",
            type: "call_logs",
          })
        );
        break;
      case "Vendors":
        dispatch(
          getNotifications({
            page: page,
            status: typeId === 1 ? "active" : "cleared",
            type: "vendors",
          })
        );
        break;
      case "Complaints":
        dispatch(
          getNotifications({
            page: page,
            status: typeId === 1 ? "active" : "cleared",
            type: "complaints",
          })
        );
        break;
      case "Orders":
        dispatch(
          getNotifications({
            page: page,
            status: typeId === 1 ? "active" : "cleared",
            type: "orders",
          })
        );
        break;
      case "Refunds":
        dispatch(
          getNotifications({
            page: page,
            status: typeId === 1 ? "active" : "cleared",
            type: "refunds",
          })
        );
        break;
      default:
        break;
    }
  };

  const fetchNotificationsByCatType = useCallback(
    (type, category) => {
      // fetch notifications by category and active/cleared status
      switch (category) {
        case "All":
          dispatch(
            getNotifications({
              page: 1,
              status: type === 1 ? "active" : "cleared",
              type: "all",
            })
          );
          break;
        case "Vendors":
          dispatch(
            getNotifications({
              page: 1,
              status: type === 1 ? "active" : "cleared",
              type: "vendors",
            })
          );
          break;
        case "Complaints":
          dispatch(
            getNotifications({
              page: 1,
              status: type === 1 ? "active" : "cleared",
              type: "complaints",
            })
          );
          break;
        case "Call Log":
          dispatch(
            getNotifications({
              page: 1,
              status: type === 1 ? "active" : "cleared",
              type: "call_logs",
            })
          );
          break;
        case "Orders":
          dispatch(
            getNotifications({
              page: 1,
              status: type === 1 ? "active" : "cleared",
              type: "orders",
            })
          );
          break;
        case "Refunds":
          dispatch(
            getNotifications({
              page: 1,
              status: type === 1 ? "active" : "cleared",
              type: "refunds",
            })
          );
          break;
        default:
          break;
      }
    },
    [dispatch]
  );

  const [clearId, setClearId] = useState(null);
  const ShowMarkModal = (id) => {
    setShow(!show);
    setClearId(id);
  };


  const viewNotification = (details) =>{
    const {object_id,object_type} = details
    console.log(object_id)
    console.log(object_type)
    switch(object_type){
      case 'cto':
        navigate(`/call-to-order/${object_id}`)
        break;
      default:
        break;
    }
  }

  const clearNotification = () => {
    dispatch(
      markAsReadNotification({
        id: clearId,
        status: typeId === 1 ? "cleared" : "active",
      })
    );
  };

  useEffect(() => {
    if (isCleared) {
      setShow(false);
      fetchNotificationsByCatType(typeId, categoryDisplay);
      dispatch(resetIsCleared());
    }
  }, [
    isCleared,
    dispatch,
    typeId,
    categoryDisplay,
    fetchNotificationsByCatType,
  ]);

  useEffect(() => {
    dispatch(
      getNotifications({
        page: 1,
        status: "active",
        type: "all",
      })
    );
  }, [dispatch]);

  return (
    <>
      {/* mark as read modal */}
      <ModalComponent
        show={show}
        showModal={showModal}
        title="Mark As Read Notification"
        subtitle=""
      >
        <div className="mt-4">
          <h6 className="text-[#5C715E] font-medium text-sm">
            Are you sure you want to mark as read this notification?
          </h6>
        </div>

        <div className="flex justify-end mt-8 mb-3">
          <div className="flex gap-2">
            <button
              onClick={() => clearNotification()}
              type="submit"
              disabled={clearLoader}
              className="bg-primary 
                           disabled:bg-[#ABB6AC] 
                           rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
            >
              Yes
            </button>
            <button
              onClick={() => setShow(false)}
              type="submit"
              disabled={false}
              className="rounded-lg px-6 py-3 text-[#2B8C34] text-sm font-medium transition-all"
            >
              No
            </button>
          </div>
        </div>
      </ModalComponent>

      <div className="bg-white flex justify-between rounded-lg py-5 px-4">
        <div>
          <h6 className="text-primary font-semibold text-lg">Notifications</h6>
          <ul className="flex gap-3 items-center mt-5">
            {type.map((val) => (
              <li
                key={val.id}
                onClick={() => toggleType(val.id)}
                className="cursor-pointer"
              >
                <p
                  className={
                    typeId === val.id
                      ? "text-primary text-sm font-semibold border-b-2 border-primary"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {val.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="mt-3 bg-white flex justify-between rounded-lg py-4 px-4">
        <ul className="flex gap-4 items-center">
          {categories.map((item) => (
            <li
              key={item.id}
              onClick={() => handleSelectedCategory(item)}
              className={
                categoryID === item.id
                  ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                  : "flex gap-2 items-center pb-1 cursor-pointer"
              }
            >
              <p
                className={
                  categoryID === item.id
                    ? "text-primary text-sm font-semibold"
                    : "text-[#96A397] text-sm font-semibold"
                }
              >
                {item.title}
              </p>
              <span
                className={
                  categoryID === item.id
                    ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                    : ""
                }
              >
                {" "}
                {categoryID === item.id ? (categoryID === 1 ? total :count) : ""}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {loading ? (
        ["2", "1", "4", "5", "3"].map((item) => (
          <div className="mt-5 mb-4 bg-[#FFFFFF] rounded-lg py-4 px-4">
            <div className="animate-pulse w-full">
              <div className="h-6 bg-gray-200 rounded-md  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-md  w-full mb-2"></div>
              <div className="h-6 bg-gray-200 rounded-md  w-full"></div>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="mt-5">
            {categoryDisplay !== "All" && notifications.length > 0 && (
              <div className={`mb-4`}>
                {/* category display */}
                <p className="text-[#423F34] text-sm font-semibold">
                  {categoryDisplay}
                </p>
                {notifications.map((val) => (
                  <div
                    key={val.id}
                    className={`${
                      typeId === 1 ? "bg-white" : "bg-[#ECEEEC]"
                    } rounded-lg px-4 py-5 flex justify-between mt-1 items-center`}
                  >
                    <div className="flex gap-3">
                      <div className="bg-[#DBFFDF] w-[36px] h-[36px] rounded-full flex justify-center items-center">
                        <img
                          src={formatLeftIcon(categoryDisplay)}
                          alt="call icon"
                        />
                      </div>
                      <div>
                        <h6 className="text-[#344335] font-semibold text-sm">
                          {val.details.title}
                        </h6>
                        <p className="text-[#96A397] font-medium text-sm mt-1">
                          {val.details.message}
                        </p>
                        <p className="text-[#423F34] font-medium text-sm mt-1">
                          {moment(val.created).format("LLL")}
                        </p>
                      </div>
                    </div>
                    {typeId === 1 && (
                      
                      <div className="flex gap-3">
                      <button
                      onClick={() => viewNotification(val.details)}
                        className="bg-transparent 
                                disabled:bg-[#ABB6AC] border border-primary text-primary
                                rounded-lg py-3 px-6 text-sm font-medium hover:bg-[#24752B] hover:text-white transition-all"
                      >
                        View
                      </button>
                      <button
                        onClick={() => ShowMarkModal(val.id)}
                        className="bg-transparent 
                                disabled:bg-[#ABB6AC] border border-primary text-primary
                                rounded-lg py-3 px-6 text-sm font-medium hover:bg-[#24752B] hover:text-white transition-all"
                      >
                        Mark as read
                      </button>
                    </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="mt-5">
            {categoryDisplay === "All" &&
              notifications &&
              Object.keys(notifications).map((cat, index) => (
                <>
                  {notifications[cat].length > 0 && (
                    <div key={index} className={`mt-5 mb-4`}>
                      {/* category display */}
                      <p className="text-[#423F34] text-sm font-semibold">
                        {formatTitle(cat)}
                      </p>
                      {notifications[cat].map((val) => (
                        <div
                          key={val.id}
                          className={`${
                            typeId === 1 ? "bg-white" : "bg-[#ECEEEC]"
                          } rounded-lg px-4 py-5 flex justify-between mt-1 mb-1 items-center`}
                        >
                          <div className="flex gap-3">
                            <div className="bg-[#DBFFDF] w-[36px] h-[36px] rounded-full flex justify-center items-center">
                              <img src={formatIcon(cat)} alt="cart icon" />
                            </div>
                            <div>
                              <h6 className="text-[#344335] font-semibold text-sm">
                                {" "}
                                {val.details.title}
                              </h6>
                              <p className="text-[#96A397] font-medium text-sm mt-1">
                                {val.details.message}
                              </p>
                              <p className="text-[#423F34] font-medium text-sm mt-1">
                                {" "}
                                {moment(val.created).format("LLL")}
                              </p>
                            </div>
                          </div>
                          {typeId === 1 && (
                            
                            <div className="flex gap-3">
                              <button
                              onClick={() => viewNotification(val.details)}
                                className="bg-transparent 
                                        disabled:bg-[#ABB6AC] border border-primary text-primary
                                        rounded-lg py-3 px-6 text-sm font-medium hover:bg-[#24752B] hover:text-white transition-all"
                              >
                                View
                              </button>
                              <button
                                onClick={() => ShowMarkModal(val.id)}
                                className="bg-transparent 
                                        disabled:bg-[#ABB6AC] border border-primary text-primary
                                        rounded-lg py-3 px-6 text-sm font-medium hover:bg-[#24752B] hover:text-white transition-all"
                              >
                                Mark as read
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ))}
          </div>
        </>
      )}

      {categoryDisplay !== "All" && notifications.length === 0 && (
        <div className="max-w-sm mx-auto flex flex-col justify-center items-center min-h-[300px]">
          <div className="text-center">
            <h6 className="text-[#344335] text-base font-semibold">
              A journey of a thousand orders begins with a call.
            </h6>
            <p className="text-[#5C715E] text-xs mt-1 font-medium">
              Somebody at Crop2Cash
            </p>
          </div>
        </div>
      )}

      {categoryDisplay !== "All" && notifications.length > 0 && (
        <div className="mt-1">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={8}
            totalItems={count}
          />
        </div>
      )}
    </>
  );
};

export default NotificationsPage;
