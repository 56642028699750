import React, { useCallback, useEffect, useState } from "react";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import TableBackground from "../../components/TableBackground";
import { Edit, ExpandLess, ExpandMore, Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getPointsActions,
  getPointsActionsSettings,
  getPointsSettings,
  handlePointsSettings,
} from "../../redux/Points/pointsActions";
import moment from "moment";
import Loading from "../../components/Loading/Loading";
import cogoToast from "cogo-toast";

const PointsSetting = () => {
  const dispatch = useDispatch();
  const [openActionsSection, setOpenActionsSection] = useState(true);
  const [openEligibilitySection, setOpenEligibilitySection] = useState(true);
  const [disablePoints, setDisablePoints] = useState(true);
  const [disableActivity, setDisableActivity] = useState(false);
  const [disableTransaction, setDisableTransaction] = useState(false);
  const [conversionRate, setConversionRate] = useState("");
  const [hoverConversationRate, setHoverConversationRate] = useState(false);
  const [edit, setEdit] = useState(false);
  const { loader, pointsSettingsLoading, pointsSettings } = useSelector(
    (state) => state.points
  );
  const [pointsActionSettings, setPointsActionSettings] = useState([]);
  const [activity, setActivity] = useState({
    start_date: "",
    end_date: "",
  });

  const [transaction, setTransaction] = useState({
    min_amount: "",
    max_amount: "",
  });

  const handleDisablePoints = () => {
    setDisablePoints(!disablePoints);
  };

  const handleGetPointsSettings = useCallback(() => {
    dispatch(getPointsSettings()).then((res) => {
      if (res.type === "getPointsSettings/fulfilled") {
        const data = res?.payload?.data;
        setConversionRate(data?.conversion_rate);
        setActivity({
          start_date: moment(data?.start_date).format("YYYY-MM-DD"),
          end_date: moment(data?.end_date).format("YYYY-MM-DD"),
        });
        setTransaction({
          min_amount: data?.min_amount,
          max_amount: data?.max_amount,
        });
        setDisableActivity(data?.has_date_range);
        setDisableTransaction(data?.has_amount_limit);
        setDisablePoints(data?.is_active);
      }
    });
  }, [dispatch]);

  const handleGetPointsActions = useCallback(() => {
    dispatch(getPointsActions());
    dispatch(getPointsActionsSettings()).then((res) => {
      if (res?.type === "getPointsActionsSettings/fulfilled") {
        const DATA = res?.payload?.data;
        setPointsActionSettings(DATA);
      }
    });
  }, [dispatch]);

  const handleSaveChanges = () => {
    let actionType = pointsActionSettings.map((item) => {
      return {
        pointType: item.point_type.id,
        minimumInput: item.minimuminput,
        pointValue: item.point_value,
        maxAttainable: item.max_attainable,
      };
    });

    const values = {
      is_active: disablePoints,
      start_date: moment(activity.start_date).format("YYYY-MM-DD"),
      end_date: moment(activity.end_date).format("YYYY-MM-DD"),
      has_date_range: disableActivity,
      max_amount: transaction.max_amount,
      min_amount: transaction.min_amount,
      has_amount_limit: disableTransaction,
      conversion_rate: conversionRate,
      actionTypeSettings: actionType,
    };
    dispatch(handlePointsSettings(values)).then((res) => {
      if (res.type === "pointsSettings/fulfilled") {
        handleGetPointsSettings();
        handleGetPointsActions();
      }
    });
  };

  const handleSaveConversionRate = () => {
    setEdit(false);
  };

  const handleEvery = (e, actionName) => {
    let value = e.target.value;
    let updatedData = pointsActionSettings?.map((item) => {
      if (item?.point_type?.name === actionName) {
        return {
          ...item,
          minimuminput: Number(value),
        };
      }
      return item;
    });
    setPointsActionSettings(updatedData);
  };

  const handleGive = (e, actionName) => {
    let value = e.target.value;
    const FILTEREDDATA = pointsActionSettings.filter(
      (data) => data.point_type.name === actionName
    );
    if (Number(value) > Number(FILTEREDDATA[0]?.max_attainable)) {
      cogoToast.error(
        "You can not input a value greater than the maximum point"
      );
    } else {
      let updatedData = pointsActionSettings?.map((item) => {
        if (item?.point_type?.name === actionName) {
          return {
            ...item,
            point_value: Number(value),
          };
        }
        return item;
      });
      setPointsActionSettings(updatedData);
    }
  };

  const handleMaxPoint = (e, actionName) => {
    let value = e.target.value;
    let updatedData = pointsActionSettings?.map((item) => {
      if (item?.point_type?.name === actionName) {
        return {
          ...item,
          max_attainable: Number(value),
        };
      }
      return item;
    });
    setPointsActionSettings(updatedData);
  };

  useEffect(() => {
    handleGetPointsSettings();
    handleGetPointsActions();
  }, [handleGetPointsSettings, handleGetPointsActions]);

  const handleKeyUp = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
  };

  return (
    <div className="">
      <div>
        <GoBack />
      </div>

      <div className="mt-6">
        <Header text={"Point Settings"}>
          <div className="flex gap-5 items-center">
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={disablePoints}
                  value={disablePoints}
                  onChange={handleDisablePoints}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <button
              type="submit"
              onClick={handleSaveChanges}
              disabled={loader}
              className="bg-primary
                    disabled:bg-[#ABB6AC] disabled:bg-opacity-[0.7] disabled:cursor-not-allowed
                    rounded-lg px-5 py-[10px] text-white text-sm font-medium hover:bg-opacity-[0.9] transition-all"
            >
              Save Changes
            </button>
          </div>
        </Header>
      </div>

      {pointsSettingsLoading ? (
        <div className="my-[10%] flex justify-center text-center items-center">
          <div>
            <Loading />
          </div>
        </div>
      ) : (
        <>
          <div className="mt-4 flex gap-2 mb-4">
            <div className="w-[70%]">
              <TableBackground
                classname={`rounded-lg px-[22px] py-[25px] border border-[#ECEFEC] `}
              >
                <p className="text-sm  font-medium leading-[18px] text-[#5C715E] mb-3">
                  Point Balance
                </p>
                <p className="text-[58px]  font-bold leading-[105.6px] text-[#344335]">
                  {pointsSettings?.balance || 0}
                </p>
              </TableBackground>
            </div>
            <div className="w-[30%]">
              <div
                className={
                  "rounded-lg p-4 mb-3 h-fit bg-white border border-[#ECEFEC]"
                }
              >
                <p className="text-sm  font-medium leading-[18px] text-[#96A397] mb-3">
                  Points Allocated
                </p>
                <p className="text-[24px]  font-semibold leading-[30px] text-[#344335]">
                  {pointsSettings?.totalPointsAllocated || 0}
                </p>
              </div>

              <div
                onMouseEnter={() => setHoverConversationRate(true)}
                onMouseLeave={() => setHoverConversationRate(false)}
                className={
                  "rounded-lg p-4  h-fit bg-white border border-[#ECEFEC]"
                }
              >
                <div className="flex justify-between items-center mb-3">
                  <p className="text-sm  font-medium leading-[18px] text-[#96A397] ">
                    Conversion Rate (1 Point = NGN)
                  </p>

                  {edit ? (
                    <div>
                      <div
                        onClick={handleSaveConversionRate}
                        className={`flex items-center justify-center gap-1 border border-primary h-[26px] rounded-lg px-2 cursor-pointer transition-all  duration-100 ease-in-out`}
                      >
                        <p className="font-semibold text-sm leading-[18px] text-primary">
                          Save
                        </p>
                        <Save style={{ color: "#2B8C34", fontSize: "14px" }} />
                      </div>
                    </div>
                  ) : (
                    <>
                      {disablePoints && (
                        <div
                          className={`flex items-center justify-center gap-1 border border-[#7C8D7E] h-[26px] rounded-lg px-2 cursor-pointer transition-all  duration-100 ease-in-out ${
                            hoverConversationRate ? "block" : "invisible"
                          }`}
                          onClick={() => setEdit(true)}
                        >
                          <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E]">
                            Edit
                          </p>
                          <Edit
                            style={{ color: "#7C8D7E", fontSize: "14px" }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                {edit ? (
                  <input
                    type="number"
                    onChange={(e) => setConversionRate(e.target.value)}
                    value={conversionRate}
                    className="w-full border-none bg-transparent outline-none text-[24px]  font-semibold leading-[30px] text-[#344335]"
                  />
                ) : (
                  <p className="text-[24px]  font-semibold leading-[30px] text-[#344335]">
                    {conversionRate || 0}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* !Actions And Reward Logic */}
          <div className="">
            <div
              className="mb-4 cursor-pointer"
              onClick={() => setOpenActionsSection(!openActionsSection)}
            >
              <Header
                style={{
                  color: "#5C715E",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                text="Actions And Reward Logic"
              >
                {openActionsSection ? (
                  <ExpandLess
                    style={{
                      color: "#5C715E",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <ExpandMore
                    style={{
                      color: "#5C715E",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Header>
            </div>

            {openActionsSection && (
              <div>
                <div className="grid grid-cols-2 gap-3 mb-3">
                  {pointsActionSettings?.map((action, index) => {
                    return (
                      <div className="" key={index}>
                        <TableBackground
                          classname={`rounded-lg px-[22px] py-[25px] border border-[#ECEFEC] ${
                            !disablePoints ? "bg-disabled" : "bg-white"
                          }`}
                        >
                          <p className="text-sm  font-medium leading-[18px] text-[#96A397] mb-6">
                            {action?.point_type?.name}
                          </p>
                          <div className="mb-4">
                            <p className="text-sm  font-medium leading-[18px] text-[#5C715E] mb-[6px]">
                              Maximum Point
                            </p>

                            <input
                              type="number"
                              onChange={(e) =>
                                handleMaxPoint(e, action?.point_type?.name)
                              }
                              min="0"
                              onKeyUp={handleKeyUp}
                              defaultValue={action?.max_attainable}
                              // value={action?.max_attainable}
                              disabled={!disablePoints}
                              className="bg-[#ECEFEC] focus:bg-white focus:border focus:border-primary border-[none] w-full px-4 py-3 appearance-none outline-none rounded-lg text-[#344335] placeholder:text-[#96A397] text-sm font-medium"
                              placeholder="00"
                            />
                          </div>

                          <div className="mb-4">
                            <p className="text-sm  font-medium leading-[18px] text-[#5C715E] mb-[6px]">
                              For every
                            </p>
                            <input
                              type="number"
                              onChange={(e) =>
                                handleEvery(e, action?.point_type?.name)
                              }
                              min="0"
                              onKeyUp={handleKeyUp}
                              defaultValue={action?.minimuminput}
                              disabled={!disablePoints}
                              className="bg-[#ECEFEC] border-[none] focus:bg-white focus:border focus:border-primary w-full px-4 py-3 appearance-none outline-none rounded-lg text-[#344335] placeholder:text-[#96A397] text-sm font-medium"
                              placeholder="00"
                            />
                          </div>

                          <div className="mb-4">
                            <p className="text-sm  font-medium leading-[18px] text-[#5C715E] mb-[6px]">
                              Give
                            </p>
                            <input
                              type="number"
                              onChange={(e) =>
                                handleGive(e, action?.point_type?.name)
                              }
                              min="0"
                              onKeyUp={handleKeyUp}
                              disabled={!disablePoints}
                              defaultValue={action?.point_value}
                              className="bg-[#ECEFEC] border-[none] focus:bg-white focus:border focus:border-primary px-4 py-3 appearance-none outline-none rounded-lg w-full  text-[#344335] placeholder:text-[#96A397]  text-sm font-medium"
                              placeholder="00"
                            />
                          </div>
                        </TableBackground>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          {/* !Eligibility Criteria */}
          <div className="mt-6">
            <div
              className="mb-4 cursor-pointer"
              onClick={() => setOpenEligibilitySection(!openEligibilitySection)}
            >
              <Header
                style={{
                  color: "#5C715E",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                text="Eligibility Criteria"
              >
                {openEligibilitySection ? (
                  <ExpandLess
                    style={{
                      color: "#5C715E",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <ExpandMore
                    style={{
                      color: "#5C715E",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Header>
            </div>

            {openEligibilitySection && (
              <div>
                {/* 1 */}
                <div className="flex gap-3 mb-3">
                  {/* Activity */}
                  <div className="w-1/2">
                    <TableBackground
                      classname={`rounded-lg px-[22px] py-[25px] border border-[#ECEFEC] ${
                        disableActivity || !disablePoints
                          ? "bg-disabled"
                          : "bg-white"
                      }`}
                    >
                      <div className="flex justify-between">
                        <p className="text-sm  font-medium leading-[18px] text-[#96A397] mb-6">
                          Activity
                        </p>

                        <div>
                          <label className="switch_two">
                            <input
                              type="checkbox"
                              disabled={!disablePoints}
                              checked={!disableActivity}
                              value={moment(activity.start_date).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={() =>
                                setDisableActivity(!disableActivity)
                              }
                            />
                            <span className="slider_two round_two"></span>
                          </label>
                        </div>
                      </div>

                      <div className="mb-4">
                        <p className="text-sm  font-medium leading-[18px] text-[#5C715E] mb-[6px]">
                          Start Date
                        </p>
                        <input
                          onChange={(e) =>
                            setActivity((prev) => ({
                              ...prev,
                              start_date: e.target.value,
                            }))
                          }
                          value={activity.start_date}
                          type="date"
                          disabled={!disablePoints || disableActivity}
                          className="bg-[#ECEFEC] border-[none] focus:bg-white focus:border focus:border-primary px-4 py-3 appearance-none outline-none rounded-lg w-full text-[#344335] placeholder:text-[#96A397] text-sm font-medium"
                          placeholder="00"
                        />
                      </div>

                      <div className="mb-4">
                        <p className="text-sm  font-medium leading-[18px] text-[#5C715E] mb-[6px]">
                          End Date
                        </p>
                        <input
                          type="date"
                          value={activity.end_date}
                          disabled={!disablePoints || disableActivity}
                          onChange={(e) =>
                            setActivity((prev) => ({
                              ...prev,
                              end_date: e.target.value,
                            }))
                          }
                          className="bg-[#ECEFEC] border-[none] focus:bg-white focus:border focus:border-primary px-4 py-3 appearance-none outline-none rounded-lg w-full text-[#344335] placeholder:text-[#96A397]  text-sm font-medium"
                          placeholder="00"
                        />
                      </div>
                    </TableBackground>
                  </div>
                  {/* Transaction Amount */}
                  <div className="w-1/2">
                    <TableBackground
                      classname={`rounded-lg px-[22px] py-[25px] border border-[#ECEFEC] ${
                        disableTransaction || !disablePoints
                          ? "bg-disabled"
                          : "bg-white"
                      }`}
                    >
                      <div className="flex justify-between">
                        <p className="text-sm  font-medium leading-[18px] text-[#96A397] mb-6">
                          Transaction Amount
                        </p>

                        <div>
                          <label className="switch_two">
                            <input
                              type="checkbox"
                              value={disableTransaction}
                              checked={!disableTransaction}
                              disabled={!disablePoints}
                              onChange={() =>
                                setDisableTransaction(!disableTransaction)
                              }
                            />
                            <span className="slider_two round_two"></span>
                          </label>
                        </div>
                      </div>

                      <div className="mb-4">
                        <p className="text-sm  font-medium leading-[18px] text-[#5C715E] mb-[6px]">
                          Minimum Transaction Amount
                        </p>
                        <input
                          type="number"
                          value={transaction.min_amount}
                          className="bg-[#ECEFEC] border-[none] focus:bg-white focus:border focus:border-primary px-4 py-3 appearance-none outline-none rounded-lg w-full text-[#344335] placeholder:text-[#96A397] text-sm font-medium"
                          placeholder="00"
                          disabled={!disablePoints || disableTransaction}
                          onChange={(e) => {
                            if (transaction.max_amount < e.target.value) {
                              cogoToast.error(
                                "Min amount can not be greater than the max amount"
                              );
                              setTransaction((prev) => ({
                                ...prev,
                                min_amount: Number(0),
                              }));
                            } else {
                              setTransaction((prev) => ({
                                ...prev,
                                min_amount: Number(e.target.value),
                              }));
                            }
                          }}
                        />
                      </div>

                      <div className="mb-4">
                        <p className="text-sm  font-medium leading-[18px] text-[#5C715E] mb-[6px]">
                          Maximum Transaction Amount
                        </p>
                        <input
                          type="number"
                          value={transaction.max_amount}
                          disabled={!disablePoints || disableTransaction}
                          onChange={(e) => {
                            if (transaction.min_amount > e.target.value) {
                              cogoToast.error(
                                "Max amount can not be less than the min amount"
                              );
                              setTransaction((prev) => ({
                                ...prev,
                                min_amount: Number(0),
                              }));
                            } else {
                              setTransaction((prev) => ({
                                ...prev,
                                min_amount: Number(e.target.value),
                              }));
                            }
                          }}
                          className="bg-[#ECEFEC] border-[none] focus:bg-white focus:border focus:border-primary px-4 py-3 appearance-none outline-none rounded-lg w-full text-[#344335] placeholder:text-[#96A397]  text-sm font-medium"
                          placeholder="00"
                        />
                      </div>
                    </TableBackground>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PointsSetting;
