import React from 'react'
import GoBack from '../../../components/GoBack';
import Header from '../../../components/Header';
import CompleteProfileTable from '../../../tables/CallLog/Member/CompleteProfileTable';


const MemberCompleteFarmersPage = () => {
    return ( 
        <>  
            <GoBack />
            <div className='mt-4'>
            <Header text="Completed Profiles" /> 
            </div>
            <CompleteProfileTable />
        </>
     );
}
 
export default MemberCompleteFarmersPage;