import React from "react";
import Header from "../../components/Header";
import PendingCooperativeTable from "../../tables/Cooperative/PendingCooperativeTable";
import { ButtonPrimary } from "../../components/Button/index";

const PendingCooperative = () => {
  return (
    <div>
      <Header text="Pending Cooperative">
        <ButtonPrimary href={`/create-cooperative`}>
          Create Cooperative
        </ButtonPrimary>
      </Header>

      {/* TABLE SECTION */}
      <section className="mt-8">
        <PendingCooperativeTable />
      </section>
    </div>
  );
};

export default PendingCooperative;
