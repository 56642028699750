// import { Search } from "@mui/icons-material";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaginationComponent from "../../../components/Pagination/Pagination";
import TableBackground from "../../../components/TableBackground";
import { getFarmersRefund } from "../../../redux/Farmers/farmersActions";
import EmptyState from "../../../components/EmptyState";
// import { useNavigate } from 'react-router-dom'

const RefundsTable = () => {
  const [status, setStatus] = useState("all");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();

  const { farmersRefundsData, farmersRefundsTotal, farmersRefundsLoading } =
    useSelector((state) => state.farmer);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getFarmersRefund({
        id: id,
        page: page,
        type: status,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getFarmersRefund({
        id: id,
        page: 1,
        type: status,
      })
    );
  }, [dispatch, status, id]);

  // const navigate = useNavigate()
  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        {/* <section className="flex gap-4 mt-4 px-4 pt-4">
          <div className="w-full relative">
            <div className=" absolute flex items-center h-[44px] px-[18px]">
              <Search style={{ fontSize: "18px", color: "#96A397" }} />
            </div>
            <input
              type="search"
              className="w-full border border-[#96A397] rounded-[8px] h-[44px] text-primary text-sm font-medium leading-5 cursor-pointer pl-[41px] px-[18px] outline-none focus:border-primary placeholder:text-[#96A397] serach"
              placeholder="Search"
            />
          </div>
        </section> */}

        {/* TABS SCETIONS */}
        <section>
          <div className="flex gap-4 pt-4 mt-4 mb-5 cursor-pointer px-4 ">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "all" ? farmersRefundsTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "approved"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("approved")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Approved
                </p>
                <div
                  className={
                    status === "approved"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "approved" ? farmersRefundsTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "pending"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("pending")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Pending
                </p>
                <div
                  className={
                    status === "pending"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "pending" ? farmersRefundsTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "denied"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("denied")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Rejected
                </p>
                <div
                  className={
                    status === "denied"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "denied" ? farmersRefundsTotal : "0"}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {status === "all" ? (
          <section className="mt-[30px]">
            {farmersRefundsLoading ? (
              <div className="animate-pulse w-full mt-4 mb-4 pb-4 px-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {farmersRefundsData.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date Created
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date Updated
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Order Type
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Refund Amount
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Refund Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {farmersRefundsData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">{data.id}</td>
                            <td className="px-4">{data.name}</td>
                            <td className="px-4">{data.phone_number}</td>
                            <td className="px-4">
                              {moment(data.created).format("lll")}
                            </td>
                            <td className="px-4">
                              {moment(data.updated).format("lll")}
                            </td>
                            <td className="px-4">{data.order_type}</td>
                            <td className="px-4">N{data.refund_amount}</td>
                            <td className="px-4">
                              <div
                                className={`flex items-center ${
                                  data.approval_status === "APPROVED"
                                    ? "success-badge"
                                    : data.approval_status === "DENIED"
                                    ? "error-badge"
                                    : data.approval_status === "PENDING"
                                    ? "pending-badge"
                                    : null
                                }`}
                              >
                                <p>
                                  {data.approval_status === "APPROVED"
                                    ? "Approved"
                                    : data.approval_status === "DENIED"
                                    ? "Rejected"
                                    : data.approval_status === "PENDING"
                                    ? "Pending"
                                    : null}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}

            {!farmersRefundsLoading && farmersRefundsData.length > 0 ? (
              <div className="mt-1 pl-[-16px]" style={{ paddingLeft: "-16px" }}>
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={farmersRefundsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "approved" ? (
          <section className="mt-[30px]">
            {farmersRefundsLoading ? (
              <div className="animate-pulse w-full mt-4 mb-4 pb-4 px-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {farmersRefundsData.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Order Type
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Refund Amount
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Refund Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {farmersRefundsData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">{data.id}</td>
                            <td className="px-4">{data.name}</td>
                            <td className="px-4">{data.phone_number}</td>
                            <td className="px-4">
                              {moment(data.created).format("lll")}
                            </td>
                            <td className="px-4">
                              {moment(data.updated).format("lll")}
                            </td>
                            <td className="px-4">{data.order_type}</td>
                            <td className="px-4">N{data.refund_amount}</td>
                            <td className="px-4">
                              <div
                                className={`flex items-center ${
                                  data.approval_status === "APPROVED"
                                    ? "success-badge"
                                    : data.approval_status === "DENIED"
                                    ? "error-badge"
                                    : data.approval_status === "PENDING"
                                    ? "pending-badge"
                                    : null
                                }`}
                              >
                                <p>
                                  {data.approval_status === "APPROVED"
                                    ? "Approved"
                                    : data.approval_status === "DENIED"
                                    ? "Rejected"
                                    : data.approval_status === "PENDING"
                                    ? "Pending"
                                    : null}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}

            {!farmersRefundsLoading && farmersRefundsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={farmersRefundsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "pending" ? (
          <section className="mt-[30px]">
            {farmersRefundsLoading ? (
              <div className="animate-pulse w-full mt-4 mb-4 pb-4 px-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {farmersRefundsData.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Order Type
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Refund Amount
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Refund Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {farmersRefundsData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">{data.id}</td>
                            <td className="px-4">{data.name}</td>
                            <td className="px-4">{data.phone_number}</td>
                            <td className="px-4">
                              {moment(data.created).format("lll")}
                            </td>
                            <td className="px-4">
                              {moment(data.updated).format("lll")}
                            </td>
                            <td className="px-4">{data.order_type}</td>
                            <td className="px-4">N{data.refund_amount}</td>
                            <td className="px-4">
                              <div
                                className={`flex items-center ${
                                  data.approval_status === "APPROVED"
                                    ? "success-badge"
                                    : data.approval_status === "DENIED"
                                    ? "error-badge"
                                    : data.approval_status === "PENDING"
                                    ? "pending-badge"
                                    : null
                                }`}
                              >
                                <p>
                                  {data.approval_status === "APPROVED"
                                    ? "Approved"
                                    : data.approval_status === "DENIED"
                                    ? "Rejected"
                                    : data.approval_status === "PENDING"
                                    ? "Pending"
                                    : null}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}

            {!farmersRefundsLoading && farmersRefundsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={farmersRefundsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "denied" ? (
          <section className="mt-[30px]">
            {farmersRefundsLoading ? (
              <div className="animate-pulse w-full mt-4 mb-4 pb-4 px-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {farmersRefundsData.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="px-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Name
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Phone Number
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Order Type
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Refund Amount
                        </th>
                        <th scope="col" className="px-4 pb-2">
                          Refund Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {farmersRefundsData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                            // onClick={() => setIsClickTable(!isClickTable)}
                          >
                            <td className="px-4">{data.id}</td>
                            <td className="px-4">{data.name}</td>
                            <td className="px-4">{data.phone_number}</td>
                            <td className="px-4">
                              {moment(data.created).format("lll")}
                            </td>
                            <td className="px-4">
                              {moment(data.updated).format("lll")}
                            </td>
                            <td className="px-4">{data.order_type}</td>
                            <td className="px-4">N{data.refund_amount}</td>
                            <td className="px-4">
                              <div
                                className={`flex items-center ${
                                  data.approval_status === "APPROVED"
                                    ? "success-badge"
                                    : data.approval_status === "DENIED"
                                    ? "error-badge"
                                    : data.approval_status === "PENDING"
                                    ? "pending-badge"
                                    : null
                                }`}
                              >
                                <p>
                                  {data.approval_status === "APPROVED"
                                    ? "Approved"
                                    : data.approval_status === "DENIED"
                                    ? "Rejected"
                                    : data.approval_status === "PENDING"
                                    ? "Pending"
                                    : null}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}

            {!farmersRefundsLoading && farmersRefundsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={farmersRefundsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : null}
      </TableBackground>
    </div>
  );
};

export default RefundsTable;
