import React, { useCallback, useState } from "react";
import GoBack from "../../components/GoBack";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import {
  getCampaignAgents,
  getCampaignSummary,
  getCampaignSummaryStats,
  getAvailableFarmersCount,
  updateCampaignAgents,
} from "../../redux/Campaign/campaignAction";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EditCampaign = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [totalFarmer, setTotalFarmers] = useState(0);
  const [callFunc, setCallFunc] = useState(false);

  const {
    campaignSummaryStatsData,
    campaignSummaryData,
    campaignAgentsData,
    createCampaignLoading,
  } = useSelector((state) => state.campaign);

  const [campaignEstimateLoading, setCampaignEstimateLoading] = useState(false);

  ////////////////////////////////////////////// LANGUAGE
  const [toggleLanguageIcon, setToggleLanguageIcon] = useState(false);
  const languageData = [
    { id: "eng", name: "English" },
    { id: "yor", name: "Yoruba" },
    { id: "hau", name: "Hausa" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const handleLanguageClicked = () => {
    setToggleLanguageIcon(!toggleLanguageIcon);
  };

  const handleLanguageSelect = (id, name) => {
    if (selectedLanguage.some((data) => data.id === id)) {
      // eslint-disable-next-line
      setSelectedLanguage(selectedLanguage.filter((each) => each.id != id));

      setCallFunc(true);
      return;
    }
    setSelectedLanguage((selServices) => [
      ...selServices,
      { name: name, id: id },
    ]);

    setCallFunc(true);
  };

  // eslint-disable-next-line
  const getAllAgent = (value) => {
    dispatch(getCampaignAgents({ languages: value }));
  };

  const handleSetNoLang = () => {
    setToggleLanguageIcon(false);
    setSelectedLanguage([]);
    dispatch(getCampaignAgents({ languages: null }));
    setCallFunc(true);
    // getAllAgents();
  };

  const handleCancelLanguage = (data, id) => {
    let ans = selectedLanguage.filter(
      (somedData) => somedData.name !== data && somedData.id !== id
    );

    setSelectedLanguage(ans);
    setCallFunc(true);
    // if (ans.length === 0) {
    //   setAgentData([]);
    // }
  };

  ////////////////////////////////////////////// AGENT
  const [toggleAgentIcon, setToggleAgentIcon] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState([]);

  const handleAgentClicked = () => {
    dispatch(getCampaignAgents({ languages: null }));
    setToggleAgentIcon(!toggleAgentIcon);
  };

  const handleAgentSelect = (id, name) => {
    if (selectedAgent.some((data) => data.id === id)) {
      // eslint-disable-next-line
      setSelectedAgent(selectedAgent.filter((each) => each.id != id));
      return;
    }
    setSelectedAgent((selServices) => [...selServices, { name: name, id: id }]);
  };

  const handleCancelAgent = (data, id) => {
    let ans = selectedAgent.filter(
      (somedData) => somedData.name !== data && somedData.id !== id
    );
    setSelectedAgent(ans);
  };

  // eslint-disable-next-line
  let state_and_lga = [];

  const handleGetFarmersCount = useCallback(async () => {
    setCampaignEstimateLoading(true);

    let language = [];
    selectedLanguage.map((data) => {
      return language.push(data.id);
    });
    getAllAgent(language.join(","));

    const data = await getAvailableFarmersCount({ id: id }); // fetch data from your redux store
    dispatch(data)
      .then((res) => {
        setTotalFarmers(res.payload.data);
        setCampaignEstimateLoading(false);
        setCallFunc(false);
      })
      .catch((error) => {

        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        }
        setCampaignEstimateLoading(false);
      });
  }, [dispatch, getAllAgent, selectedLanguage, id]);

  const handleGetAllFarmersCount = useCallback(async () => {
    setCampaignEstimateLoading(true);

    const data = await getAvailableFarmersCount({ id: id }); // fetch data from your redux store
    dispatch(data)
      .then((res) => {
        setTotalFarmers(res.payload.data);
        setCampaignEstimateLoading(false);
        setCallFunc(false);
      })
      .catch((error) => {

        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        }
        setCampaignEstimateLoading(false);
      });
  }, [dispatch, id]);

  const handleEditCampaign = () => {
    let agent = [];
    selectedAgent.map((data) => {
      return agent.push(data.id);
    });
    dispatch(updateCampaignAgents({ id: id, value: agent }));

    setTimeout(() => {
      navigate(`/campaign-summary/${id}`);
    }, 800);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (callFunc) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      handleGetFarmersCount();
    }
  }, [callFunc, handleGetFarmersCount]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleGetAllFarmersCount();
    dispatch(getCampaignSummary({ id: id }));
    dispatch(getCampaignSummaryStats({ id: id }));
  }, [handleGetAllFarmersCount, dispatch, id]);

  return (
    <div className="">
      {" "}
      <div className="mb-4">
        <GoBack />
      </div>
      {/* HEDAER SECTION */}
      <section className="mb-5 ">
        <Header text="Edit Campaign "></Header>
      </section>
      <div className="flex gap-5 h-[90vh]">
        <div className="w-[75%] campaignScroll overflow-x-scroll">
          <div>
            {/* 1 */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Campaign info
              </p>

              {/* CAMPAIGN NAME */}
              <div className="mb-4">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Campaign Name
                </p>
                <input
                  type="text"
                  className="outline-none disabled:bg-[#F7F7F7] border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
                  placeholder="Campaign Name"
                  disabled
                  value={campaignSummaryData?.campaign_name}
                />
              </div>

              {/* CAMPAIGN DESCRIPTION */}
              <div className="mb-4">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Campaign Description
                </p>
                <textarea
                  className="resize-none h-[134px] disabled:bg-[#F7F7F7] outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
                  placeholder="Campaign Description"
                  disabled
                  value={campaignSummaryData?.description}
                />
              </div>
            </div>

            {/* 4(STATE AND LOCAL GOVERNMENT) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Target Group
              </p>

              <div>
                <div className="flex gap-6 items-center">
                  {/* STATE */}
                  <div className="pb-4 w-1/2">
                    <p className="pb-1 font-[400] text-sm leading-[18px] text-[#344335]">
                      State
                    </p>
                    <div className="relative w-full">
                      <div
                        className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                      // onClick={handleStateClick}
                      >
                        <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                          {"Select a State"}
                        </p>
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* LGA */}
                  <div className="pb-4 w-1/2">
                    <p className="pb-1 font-[400] text-sm leading-[18px] text-[#344335]">
                      LGA
                    </p>
                    <div className="relative w-full">
                      <div
                        className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                      // onClick={handleLGAClicked}
                      >
                        <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                          {"Select Lga"}
                        </p>
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className={`outline-none  rounded-[8px] h-[40px] w-[122px] font-medium text-sm leading-5 text-white bg-[#ABB6AC]"`}
                >
                  Add Location
                </button>

                <div className="flex gap-2 flex-wrap cursor-pointer mt-3"></div>
              </div>
            </div>

            {/* 6(STATUS) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Account Status
              </p>

              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                Active Status
              </p>

              <div className="flex gap-4">
                <div className="mt-4">
                  <p className="py-1 leading-[18px] font-medium text-sm text-[#abb6ac]">
                    From
                  </p>
                  <div className="w-[162px] flex justify-between h-[44px] bg-[#f7f7f7] border-[#abb6ac] border border-solid rounded-[8px] px-[12px] items-center font-medium text-sm leading-5 text-[#abb6ac]">
                    {/* FROM */}
                    <p>
                      {!campaignSummaryData?.active_status?.from
                        ? "N/A"
                        : moment(
                          campaignSummaryData?.active_status?.from
                        ).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <p className="py-1 leading-[18px] font-medium text-sm text-[#abb6ac]">
                    To
                  </p>
                  <div className="w-[162px] flex justify-between h-[44px] bg-[#f7f7f7] border-[#abb6ac] border border-solid rounded-[8px] px-[12px] items-center font-medium text-sm leading-5 text-[#abb6ac]">
                    {/* FROM */}
                    <p>
                      {" "}
                      {!campaignSummaryData?.active_status?.to
                        ? "N/A"
                        : moment(campaignSummaryData?.active_status?.to).format(
                          "DD/MM/YYYY"
                        )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* 7(TRANSACTION STATUS) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Transaction Status
              </p>

              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                Transaction Status
              </p>

              <div className="flex gap-4">
                <div className="mt-4">
                  <p className="py-1 leading-[18px] font-medium text-sm text-[#abb6ac]">
                    From
                  </p>
                  <div className="w-[162px] flex justify-between h-[44px] bg-[#f7f7f7] border-[#abb6ac] border border-solid rounded-[8px] px-[12px] items-center font-medium text-sm leading-5 text-[#abb6ac]">
                    {/* FROM */}
                    <p>
                      {!campaignSummaryData?.transaction_status?.from
                        ? "N/A"
                        : moment(
                          campaignSummaryData?.transaction_status?.from
                        ).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <p className="py-1 leading-[18px] font-medium text-sm text-[#abb6ac]">
                    To
                  </p>
                  <div className="w-[162px] flex justify-between h-[44px] bg-[#f7f7f7] border-[#abb6ac] border border-solid rounded-[8px] px-[12px] items-center font-medium text-sm leading-5 text-[#abb6ac]">
                    {/* FROM */}
                    <p>
                      {" "}
                      {!campaignSummaryData?.transaction_status?.to
                        ? "N/A"
                        : moment(
                          campaignSummaryData?.transaction_status?.to
                        ).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* LANGUAGE */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Language
              </p>
              <div className="flex gap-6 items-center">
                {/* CROP */}

                <div className="pb-4 w-1/2">
                  <div className="relative w-full">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Language
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onClick={handleLanguageClicked}
                    >
                      <p>Language</p>

                      {toggleLanguageIcon ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {toggleLanguageIcon && (
                      <div
                        className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        <p
                          className="px-[16px] py-[6px] hover:bg-[#EDF7EE] cursor-pointer"
                          onClick={handleSetNoLang}
                        >
                          None
                        </p>
                        {languageData.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                            // onClick={() => handleCrops(data)}
                            >
                              <input
                                type="checkbox"
                                name={data.name}
                                className="accent-primary h-[14px] w-[14px] rounded-[2px] mt-1"
                                // checked={selectedCrops.includes(x.id)}
                                checked={selectedLanguage.some(
                                  (x) => x.id === data.id
                                )}
                                onChange={(event) => {
                                  handleLanguageSelect(
                                    data.id,
                                    data.name,
                                    event
                                  );
                                  // handleCropsSelectName(x.name, event);
                                }}
                              />
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="flex gap-2">
                    {selectedLanguage.map((data, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            handleCancelLanguage(data.name, data.id)
                          }
                        >
                          <div className="bg-[#96A397] rounded-[4px] w-[77px] h-[26px] flex gap-1 items-center text-white font-medium text-sm leading-[18px] justify-center">
                            <p>{data.name}</p>
                            <ClearIcon style={{ fontSize: "12px" }} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* AGENTS */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Agents
              </p>
              <div className="flex gap-6 items-center">
                {/* Agents */}

                <div className="pb-4 w-1/2">
                  <div className="relative w-full">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Agents
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onClick={handleAgentClicked}
                    >
                      <p>Agents</p>

                      {toggleLanguageIcon ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {toggleAgentIcon && (
                      <div
                        className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        {campaignAgentsData.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                            // onClick={() => handleCrops(data)}
                            >
                              <input
                                type="checkbox"
                                name={data.name}
                                className="accent-primary h-[14px] w-[14px] rounded-[2px] mt-1"
                                // checked={selectedCrops.includes(x.id)}
                                checked={selectedAgent.some(
                                  (x) => x.id === data.id
                                )}
                                onChange={(event) => {
                                  handleAgentSelect(
                                    data.id,
                                    data.first_name + " " + data.last_name,
                                    event
                                  );
                                }}
                              />
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.first_name + " " + data.last_name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="flex gap-2 flex-wrap cursor-pointer">
                    {selectedAgent.map((data, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleCancelAgent(data.name, data.id)}
                        >
                          <div className="bg-[#96A397] px-2 rounded-[4px] w-full h-[26px] flex gap-1 items-center text-white font-medium text-sm leading-[18px] justify-center">
                            <p>{data.name}</p>
                            <ClearIcon style={{ fontSize: "12px" }} />
                          </div>
                        </div>
                      );
                    })}
                    {campaignSummaryData?.agents?.map((data, index) => {
                      return (
                        <div key={index}>
                          <div className="bg-[#96A397] px-2 rounded-[4px] w-full h-[26px] flex gap-1 items-center text-white font-medium text-sm leading-[18px] justify-center">
                            <p>{data}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-end justify-end w-full">
            <div
              className={
                createCampaignLoading === false
                  ? selectedAgent.length !== 0
                    ? "flex w-[fit-content] bg-primary border-primary text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer"
                    : "flex w-[fit-content] bg-[#abb6ac] border-[#abb6ac] text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer"
                  : "flex w-[fit-content] bg-[#abb6ac] border-[#abb6ac] text-sm leading-5 font-medium text-white gap-2 items-center justify-center border rounded-[8px] px-[20px] py-[10px] cursor-pointer"
              }
              onClick={
                createCampaignLoading === false
                  ? selectedAgent.length !== 0
                    ? handleEditCampaign
                    : null
                  : null
              }
            >
              <p>{createCampaignLoading ? "Loading..." : "Edit Campaign"}</p>
            </div>
          </div>
        </div>

        {/* FLEX 2 */}
        <div className="w-[25%]">
          <div className="bg-white rounded-[8px] p-6">
            <p className="font-semibold text-sm text-[#7C8D7E] leading-[18px] pb-1">
              Campaign Summary
            </p>
            <p className="font-[400] text-xs text-[#5C715E] leading-[18px] pb-4">
              To see the summary of a campaign.
            </p>

            <div>
              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Total Farmers:{" "}
                  <span className="text-primary">
                    {campaignSummaryStatsData?.total_farmers}
                  </span>
                </p>
              </div>

              {/* 2 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Calls Made:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.calls_made}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Follow Ups Created:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.follow_ups_created}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Sales Made:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.sales_made}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Running Time:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.running_time?.days}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Campaign Status:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.status}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Date Created:{" "}
                  <span className="text-primary">
                    {" "}
                    {moment(campaignSummaryStatsData?.created).format("lll")}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* 2 */}
          <div className="bg-white rounded-[8px] p-6 mt-[6px]">
            <p className="font-semibold text-sm text-[#7C8D7E] leading-[18px] pb-1">
              Campaign Estimates
            </p>
            <p className="font-[400] text-xs text-[#5C715E] leading-[18px] pb-4">
              Based on your current campaign setting you would be reaching an
              estimate of:
            </p>

            <div>
              {campaignEstimateLoading ? (
                <p>Loading...</p>
              ) : (
                <p className="font-[700] text-[18px] text-[#344335] leading-[28px]">
                  {totalFarmer}
                </p>
              )}

              <p className="font-medium text-xs text-[#344335] leading-4">
                Farmers
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCampaign;
