import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaginationComponent from "../../../components/Pagination/Pagination";
import TableBackground from "../../../components/TableBackground";
import {
  getAdvisoryFarmersOrder,
  getAllFarmersOrder,
  getArtimeFarmersOrder,
  getCropFarmersOrder,
  getInsuranceFarmersOrder,
  getLivestockFarmersOrder,
  getMechanizationFarmersOrder,
} from "../../../redux/Farmers/farmersActions";
import EmptyState from "../../../components/EmptyState";

const OrdersTable = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState("all");
  const {
    allOrdersData,
    allOrdersTotal,
    allOrdersLoading,

    cropOrdersData,
    cropOrdersTotal,
    cropOrdersLoading,

    livestockOrdersData,
    livestockOrdersTotal,
    livestockOrdersLoading,

    mechanizationOrdersData,
    mechanizationOrdersTotal,
    mechanizationOrdersLoading,

    advisoryOrdersData,
    advisoryOrdersTotal,
    advisoryOrdersLoading,

    airtimeOrdersData,
    airtimeOrdersTotal,
    airtimeOrdersLoading,

    insuranceOrdersData,
    insuranceOrdersTotal,
    insuranceOrdersLoading,
  } = useSelector((state) => state.farmer);
  const [currentPage, setCurrentPage] = useState(1);
  const [cropCurrentPage, setCropCurrentPage] = useState(1);
  const [livestockCurrentPage, setLivestockCurrentPage] = useState(1);
  const [mechanizationCurrentPage, setMechanizationCurrentPage] = useState(1);
  const [advisoryCurrentPage, setAdvisoryCurrentPage] = useState(1);
  const [airtimeCurrentPage, setAirtimeCurrentPage] = useState(1);
  const [insuranceCurrentPage, setInsuranceCurrentPage] = useState(1);

  // ALL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getAllFarmersOrder({
        page: page,
        id: id,
      })
    );
  };

  const onCropPageChange = (val) => {
    const { page } = val;
    setCropCurrentPage(page);

    dispatch(
      getCropFarmersOrder({
        page: page,
        id: id,
      })
    );
  };

  const onLivestockPageChange = (val) => {
    const { page } = val;
    setLivestockCurrentPage(page);

    dispatch(
      getLivestockFarmersOrder({
        page: page,
        id: id,
      })
    );
  };

  const onMechanizationPageChange = (val) => {
    const { page } = val;
    setMechanizationCurrentPage(page);

    dispatch(
      getMechanizationFarmersOrder({
        page: page,
        id: id,
      })
    );
  };

  const onAdvisoryPageChange = (val) => {
    const { page } = val;
    setAdvisoryCurrentPage(page);

    dispatch(
      getAdvisoryFarmersOrder({
        page: page,
        id: id,
      })
    );
  };

  const onAirtimePageChange = (val) => {
    const { page } = val;
    setAirtimeCurrentPage(page);

    dispatch(
      getArtimeFarmersOrder({
        page: page,
        id: id,
      })
    );
  };

  const onInsurancePageChange = (val) => {
    const { page } = val;
    setInsuranceCurrentPage(page);

    dispatch(
      getInsuranceFarmersOrder({
        page: page,
        id: id,
      })
    );
  };

  useEffect(() => {
    if (status === "all") {
      dispatch(getAllFarmersOrder({ page: 1, id: id }));
    } else if (status === "crop") {
      dispatch(getCropFarmersOrder({ page: 1, id: id }));
    } else if (status === "livestock") {
      dispatch(getLivestockFarmersOrder({ page: 1, id: id }));
    } else if (status === "mechanization") {
      dispatch(getMechanizationFarmersOrder({ page: 1, id: id }));
    } else if (status === "advisory") {
      dispatch(getAdvisoryFarmersOrder({ page: 1, id: id }));
    } else if (status === "airtime") {
      dispatch(getArtimeFarmersOrder({ page: 1, id: id }));
    } else if (status === "insurance") {
      dispatch(getInsuranceFarmersOrder({ page: 1, id: id }));
    }
  }, [dispatch, id, status]);

  console.log(livestockOrdersTotal);

  return (
    <div>
      <TableBackground classname="p-4">
        {/* SEARCH AND FILTER SECTION */}
        {/* <section className="flex gap-4 mt-4">
          <div className="w-full relative">
            <div className=" absolute flex items-center h-[44px] px-[18px]">
              <Search style={{ fontSize: "18px", color: "#96A397" }} />
            </div>
            <input
              type="search"
              className="w-full border border-[#96A397] rounded-[8px] h-[44px] text-primary text-sm font-medium leading-5 cursor-pointer pl-[41px] px-[18px] outline-none focus:border-primary placeholder:text-[#96A397] serach"
              placeholder="Search"
            />
          </div>
        </section> */}

        {/* TABS SCETIONS */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 pr-4 cursor-pointer">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{allOrdersTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "crop"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("crop")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Crop
                </p>
                <div
                  className={
                    status === "crop"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{cropOrdersTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "livestock"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("livestock")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Livestock
                </p>
                <div
                  className={
                    status === "livestock"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{livestockOrdersTotal || 0}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "mechanization"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("mechanization")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Mechanization
                </p>
                <div
                  className={
                    status === "mechanization"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{mechanizationOrdersTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "advisory"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("advisory")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Advisory
                </p>
                <div
                  className={
                    status === "advisory"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{advisoryOrdersTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "airtime"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("airtime")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Airtime
                </p>
                <div
                  className={
                    status === "airtime"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{airtimeOrdersTotal}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "insurance"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("insurance")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Insurance
                </p>
                <div
                  className={
                    status === "insurance"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{insuranceOrdersTotal}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {status === "all" ? (
          <section className="mt-[30px] mb-[24px]">
            {allOrdersLoading ? (
              <div className="animate-pulse w-full mt-4  mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {allOrdersData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="pr-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Product Name
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Category
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          USSD Short Code
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Payment Status
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allOrdersData.map((data, index) => {
                        const order_status = data?.order_status;
                        let orderStatus = order_status?.at(-1);

                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                          >
                            <td className="">{data.id}</td>
                            <td className="">
                              {data?.product?.product_name || "N/A"}
                            </td>
                            <td className="">N {data.total_display_price}</td>
                            <td className="">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="">
                              {moment(data.created).format("LT")}
                            </td>
                            <td className="">
                              {data.product_type?.replace("_", " ")}
                            </td>
                            <td className="">{"N/A"}</td>

                            <td className="">
                              <div
                                className={
                                  data.payment_status
                                    ? "flex items-center success-badge"
                                    : "flex items-center error-badge"
                                }
                              >
                                <p className="capitalize">
                                  {data.payment_status ? "Paid" : "Unpaid"}
                                </p>
                              </div>
                            </td>
                            <td className="">
                              <div
                                className={`flex items-center capitalize ${
                                  orderStatus === "PENDING"
                                    ? "pending-badge"
                                    : orderStatus === "PROCESSING"
                                    ? "pending-badge"
                                    : orderStatus === "DISPATCHED"
                                    ? "success-badge "
                                    : orderStatus === "DELIVERED"
                                    ? "success-badge "
                                    : orderStatus === "CANCELLED"
                                    ? "error-badge "
                                    : "pending-badge"
                                } `}
                              >
                                <p className="capitalize">
                                  {orderStatus?.toLowerCase() || "Pending"}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!allOrdersLoading && allOrdersData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={allOrdersTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "crop" ? (
          <section className="mt-[30px] mb-[24px]">
            {cropOrdersLoading ? (
              <div className="animate-pulse w-full mt-4  mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {allOrdersData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="pr-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Product Name
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Category
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          USSD Short Code
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Payment Status
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cropOrdersData.map((data, index) => {
                        const order_status = data.order_status;
                        let orderStatus =
                          order_status[order_status?.length - 1];

                        return (
                          <tr
                            key={index}
                            className="relative cursor-pointer hover:bg-[#edf7ee]"
                          >
                            <td className="">{data.id}</td>
                            <td className="">
                              {data?.product?.product_name || "N/A"}
                            </td>
                            <td className="">N {data.total_display_price}</td>
                            <td className="">
                              {moment(data.created).format("ll")}
                            </td>
                            <td className="">
                              {moment(data.created).format("LT")}
                            </td>
                            <td className="">
                              {data.product_type?.replace("_", " ")}
                            </td>
                            <td className="">{"N/A"}</td>

                            <td className="">
                              <div
                                className={
                                  data.payment_status
                                    ? "flex items-center success-badge"
                                    : "flex items-center error-badge"
                                }
                              >
                                <p className="capitalize">
                                  {data.payment_status ? "Paid" : "Unpaid"}
                                </p>
                              </div>
                            </td>
                            <td className="">
                              <div
                                className={`flex items-center capitalize ${
                                  orderStatus === "PENDING"
                                    ? "pending-badge "
                                    : orderStatus === "PROCESSING"
                                    ? "pending-badge"
                                    : orderStatus === "DISPATCHED"
                                    ? "success-badge "
                                    : orderStatus === "DELIVERED"
                                    ? "success-badge "
                                    : orderStatus === "CANCELLED"
                                    ? "error-badge "
                                    : null
                                } `}
                              >
                                <p className="capitalize">
                                  {orderStatus?.toLowerCase()}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!cropOrdersLoading && cropOrdersData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={cropCurrentPage}
                  onPageChange={onCropPageChange}
                  pageSize={10}
                  totalItems={cropOrdersTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "livestock" ? (
          <section className="mt-[30px] mb-[24px]">
            {livestockOrdersLoading ? (
              <div className="animate-pulse w-full mt-4  mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {livestockOrdersData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="pr-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Product Name
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Category
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          USSD Short Code
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Payment Status
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {livestockOrdersData.map((data, index) => {
                        const order_status = data.order_status;
                        let orderStatus =
                          order_status[order_status?.length - 1];

                        return (
                          <tr key={index} className="relative cursor-pointer">
                            <td>{data.id}</td>
                            <td className="">
                              {data?.product?.product_name || "N/A"}
                            </td>
                            <td>N{data.total_display_price}</td>
                            <td>{moment(data.created).format("ll")}</td>
                            <td>{moment(data.created).format("LT")}</td>
                            <td>{"Insurance"}</td>
                            <td>{"N/A"}</td>

                            <td>
                              <div
                                className={
                                  data.payment_status
                                    ? "flex items-center success-badge"
                                    : "flex items-center error-badge"
                                }
                              >
                                <p className="capitalize">
                                  {data.payment_status ? "Paid" : "Unpaid"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`flex items-center capitalize ${
                                  orderStatus === "PENDING"
                                    ? "pending-badge "
                                    : orderStatus === "PROCESSING"
                                    ? "pending-badge"
                                    : orderStatus === "DISPATCHED"
                                    ? "success-badge "
                                    : orderStatus === "DELIVERED"
                                    ? "success-badge "
                                    : orderStatus === "CANCELLED"
                                    ? "error-badge "
                                    : null
                                } `}
                              >
                                <p className="capitalize">
                                  {orderStatus?.toLowerCase()}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!livestockOrdersLoading && livestockOrdersData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={livestockCurrentPage}
                  onPageChange={onLivestockPageChange}
                  pageSize={10}
                  totalItems={livestockOrdersTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "mechanization" ? (
          <section className="mt-[30px] mb-[24px]">
            {mechanizationOrdersLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {mechanizationOrdersData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="pr-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Product Name
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Category
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          USSD Short Code
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Payment Status
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mechanizationOrdersData.map((data, index) => {
                        const order_status = data?.order_status;
                        let orderStatus =
                          order_status[order_status?.length - 1];

                        return (
                          <tr key={index} className="relative cursor-pointer">
                            <td>{data.id}</td>
                            <td className="">
                              {data?.product?.product_name || "N/A"}
                            </td>
                            <td>N{data.total_display_price}</td>
                            <td>{moment(data.created).format("ll")}</td>
                            <td>{moment(data.created).format("LT")}</td>
                            <td>{"Mechanization"}</td>
                            <td>{"N/A"}</td>

                            <td>
                              <div
                                className={
                                  data.payment_status
                                    ? "flex items-center success-badge"
                                    : "flex items-center error-badge"
                                }
                              >
                                <p className="capitalize">
                                  {data.payment_status ? "Paid" : "Unpaid"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`flex items-center capitalize ${
                                  orderStatus === "PENDING"
                                    ? "pending-badge "
                                    : orderStatus === "PROCESSING"
                                    ? "pending-badge"
                                    : orderStatus === "DISPATCHED"
                                    ? "success-badge "
                                    : orderStatus === "DELIVERED"
                                    ? "success-badge "
                                    : orderStatus === "CANCELLED"
                                    ? "error-badge "
                                    : null
                                } `}
                              >
                                <p className="capitalize">
                                  {orderStatus?.toLowerCase()}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!mechanizationOrdersLoading &&
            mechanizationOrdersData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={mechanizationCurrentPage}
                  onPageChange={onMechanizationPageChange}
                  pageSize={10}
                  totalItems={mechanizationOrdersTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "advisory" ? (
          <section className="mt-[30px] mb-[24px]">
            {advisoryOrdersLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {advisoryOrdersData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="pr-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Product Name
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Category
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          USSD Short Code
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Payment Status
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {advisoryOrdersData?.map((data, index) => {
                        const order_status = data?.order_status;
                        let orderStatus = data?.order_status
                          ? order_status[order_status?.length - 1]
                          : "PENDING";

                        return (
                          <tr key={index} className="relative cursor-pointer">
                            <td>{data.id}</td>
                            <td className="">
                              {data?.product?.product_name || "N/A"}
                            </td>
                            <td>N{data.total_display_price}</td>
                            <td>{moment(data.created).format("ll")}</td>
                            <td>{moment(data.created).format("LT")}</td>
                            <td>{"Advisory"}</td>
                            <td>{"N/A"}</td>

                            <td>
                              <div
                                className={
                                  data.payment_status
                                    ? "flex items-center success-badge"
                                    : "flex items-center error-badge"
                                }
                              >
                                <p className="capitalize">
                                  {data.payment_status ? "Paid" : "Unpaid"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`flex items-center capitalize ${
                                  orderStatus === "PENDING"
                                    ? "pending-badge "
                                    : orderStatus === "PROCESSING"
                                    ? "pending-badge"
                                    : orderStatus === "DISPATCHED"
                                    ? "success-badge "
                                    : orderStatus === "DELIVERED"
                                    ? "success-badge "
                                    : orderStatus === "CANCELLED"
                                    ? "error-badge "
                                    : null
                                } `}
                              >
                                <p className="capitalize">
                                  {orderStatus?.toLowerCase()}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!advisoryOrdersLoading && advisoryOrdersData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={advisoryCurrentPage}
                  onPageChange={onAdvisoryPageChange}
                  pageSize={10}
                  totalItems={advisoryOrdersTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "airtime" ? (
          <section className="mt-[30px] mb-[24px]">
            {airtimeOrdersLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {airtimeOrdersData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="pr-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Product Name
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Category
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          USSD Short Code
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Payment Status
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {airtimeOrdersData.map((data, index) => {
                        const order_status = data.order_status;
                        let orderStatus =
                          order_status[order_status?.length - 1];

                        return (
                          <tr key={index} className="relative cursor-pointer">
                            <td>{data.id}</td>
                            <td className="">
                              {data?.product?.product_name || "N/A"}
                            </td>
                            <td>N {data.total_display_price}</td>
                            <td>{moment(data.created).format("ll")}</td>
                            <td>{moment(data.created).format("LT")}</td>
                            <td>{"Airtime"}</td>
                            <td>{"N/A"}</td>

                            <td>
                              <div
                                className={
                                  data.payment_status
                                    ? "flex items-center success-badge"
                                    : "flex items-center error-badge"
                                }
                              >
                                <p className="capitalize">
                                  {data.payment_status ? "Paid" : "Unpaid"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`flex items-center capitalize ${
                                  orderStatus === "PENDING"
                                    ? "pending-badge "
                                    : orderStatus === "PROCESSING"
                                    ? "pending-badge"
                                    : orderStatus === "DISPATCHED"
                                    ? "success-badge "
                                    : orderStatus === "DELIVERED"
                                    ? "success-badge "
                                    : orderStatus === "CANCELLED"
                                    ? "error-badge "
                                    : null
                                } `}
                              >
                                <p className="capitalize">
                                  {orderStatus?.toLowerCase()}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!airtimeOrdersLoading && airtimeOrdersData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={airtimeCurrentPage}
                  onPageChange={onAirtimePageChange}
                  pageSize={10}
                  totalItems={airtimeOrdersTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "insurance" ? (
          <section className="mt-[30px] mb-[24px]">
            {insuranceOrdersLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 mb-4 pb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {insuranceOrdersData?.length === 0 ? (
                  <div>
                    <EmptyState text="There's no data for this filter yet" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th scope="col" className="pr-4 pb-2">
                          Order ID
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Product Name
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Cost
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Date
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Time
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Category
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          USSD Short Code
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Payment Status
                        </th>
                        <th scope="col" className="pr-4 pb-2">
                          Order Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {insuranceOrdersData.map((data, index) => {
                        const order_status = data.order_status;
                        let orderStatus =
                          order_status[order_status?.length - 1];

                        return (
                          <tr key={index} className="relative cursor-pointer">
                            <td>{data.id}</td>
                            <td className="">
                              {data?.product?.product_name || "N/A"}
                            </td>
                            <td>N {data.total_display_price}</td>
                            <td>{moment(data.created).format("ll")}</td>
                            <td>{moment(data.created).format("LT")}</td>
                            <td>{"Insurance"}</td>
                            <td>{"N/A"}</td>

                            <td>
                              <div
                                className={
                                  data.payment_status
                                    ? "flex items-center success-badge"
                                    : "flex items-center error-badge"
                                }
                              >
                                <p className="capitalize">
                                  {data.payment_status ? "Paid" : "Unpaid"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`flex items-center capitalize ${
                                  orderStatus === "PENDING"
                                    ? "pending-badge "
                                    : orderStatus === "PROCESSING"
                                    ? "pending-badge"
                                    : orderStatus === "DISPATCHED"
                                    ? "success-badge "
                                    : orderStatus === "DELIVERED"
                                    ? "success-badge "
                                    : orderStatus === "CANCELLED"
                                    ? "error-badge "
                                    : null
                                } `}
                              >
                                <p className="capitalize">
                                  {orderStatus?.toLowerCase()}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {!insuranceOrdersLoading && insuranceOrdersData?.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={insuranceCurrentPage}
                  onPageChange={onInsurancePageChange}
                  pageSize={10}
                  totalItems={insuranceOrdersTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : null}
      </TableBackground>
    </div>
  );
};

export default OrdersTable;
