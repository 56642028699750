import React, { useState } from "react";
import "./pagination.css";
import {
  ExpandMore,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

const PaginationComponent = ({ onPageChange, totalItems, page }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const onNext = () => {
    onPageChange(page + 1);
  };

  const onPrevious = () => {
    onPageChange(page - 1);
  };

  const handlePageClick = (number) => {
    setIsOptionsOpen(false);
    onPageChange(number);
  };

  return (
    <div className="flex justify-between w-full p-4 font-medium bg-white text-sm leading-4 text-[#5C715E]">
      <div className="flex gap-[37px]">
        <p>
          Items per page: <span className="pl-1">10</span>
        </p>
        <p>
          Showing {totalItems <= 9 ? totalItems : 10} of {totalItems} items
        </p>
      </div>
      <div className="relative">
        <div className="flex gap-3">
          <div className="relative">
            <div className="relative">
              {page}
              <span className="pl-[13px]">
                <ExpandMore
                  style={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() => setIsOptionsOpen(!isOptionsOpen)}
                />
              </span>
            </div>
          </div>

          <p>Of {Math.ceil(totalItems / 10)} pages</p>

          <p>
            {" "}
            <span className="">
              <KeyboardArrowLeft
                onClick={page <= 1 ? null : onPrevious}
                className={`${
                  page <= 1
                    ? "cursor-not-allowed text-[#5C715E]"
                    : "cursor-pointer text-[#3A503A]"
                } `}
                style={{ fontSize: "16px" }}
              />
            </span>
            <span className="pl-[13px]">
              <KeyboardArrowRight
                className={`${
                  Math.ceil(totalItems / 10) === page
                    ? "cursor-not-allowed text-[#5C715E]"
                    : "cursor-pointer text-[#3A503A]"
                } `}
                style={{ fontSize: "16px" }}
                onClick={Math.ceil(totalItems / 10) === page ? null : onNext}
              />
            </span>
          </p>
        </div>

        <React.Fragment className="h-[fit-content] w-full">
          {isOptionsOpen && (
            <div className="absolute z-[100] bg-white shadow-md bottom-[150%] rounded-[4px] w-[fit-content] max-h-[200px] overflow-scroll">
              <>
                {Array.from(
                  { length: Math.ceil(totalItems / 10) },
                  (_, index) => (
                    <div
                      key={index}
                      className="hover:bg-[#EDF7EE] hover:text-[#2B8C34] p-2 text-center cursor-pointer"
                      onClick={() => handlePageClick(index + 1)}
                    >
                      <p className="p-1">{index + 1}</p>
                    </div>
                  )
                )}
              </>
            </div>
          )}
        </React.Fragment>
      </div>
    </div>
  );
};

export default PaginationComponent;
