import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../components/Cards";
import Header from "../../components/Header";
import { getFarmersStats } from "../../redux/Farmers/farmersActions";
import FarmersTable from "../../tables/Farmers";

const Farmers = () => {
  const dispatch = useDispatch();
  const { stats, cardLoading } = useSelector((state) => state.farmer);

  useEffect(() => {
    dispatch(getFarmersStats());
  }, [dispatch]);

  return (
    <div>
      <Header text="Farmer" />

      {/* CARDS SECTION */}
      <section>
        <div className="grid grid-cols-4 gap-6 mt-6 mb-8">
          <>
            <Card
              title="Complete Profiles"
              subtitle={
                cardLoading
                  ? 0
                  : Number(stats?.no_of_complete_farmers)?.toLocaleString() || 0
              }
              description="Total number of farmers with complete registration."
              href="/complete-profiles"
            />

            <Card
              title="Temporary Farmers"
              subtitle={
                cardLoading
                  ? 0
                  : Number(stats?.no_of_temporary_farmers)?.toLocaleString() || 0
              }
              description="Total number of farmers with temporary registration"
              href="/temporary-farmers"
            />

            <Card
              title="Incomplete Profiles"
              subtitle={
                cardLoading
                  ? 0
                  : Number(stats?.no_of_incomplete_farmers)?.toLocaleString() ||
                  0
              }
              description="Total number of farmers with incomplete registration"
              href="/incomplete-profiles"
            />

            <Card
              title="Flagged Farmers"
              subtitle={
                cardLoading
                  ? 0
                  : Number(stats?.no_of_flagged_farmers)?.toLocaleString() || 0
              }
              description="Total number of farmers with flagged accounts."
              href="/flagged-farmers"
            />
          </>
        </div>
      </section>

      {/* TABLE SECTION */}
      <section>
        <FarmersTable />
      </section>
    </div>
  );
};

export default Farmers;
