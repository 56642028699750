import React from 'react'
import Header from '../../../components/Header';
import PendingRecoveryTable from '../../../tables/LoanRecovery/PendingRecoveryTable';

const PendingRecoveryPage = () => {
    return ( 
        <>
            <Header text="Pending Recovery" />
            <PendingRecoveryTable />
        </>
     );
}
 
export default PendingRecoveryPage;