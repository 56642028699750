import React from 'react'
import GoBack from '../../components/GoBack'
import RetailComplaintsTable from '../../tables/Complaints/RetailComplaint'

const RetailComplaints = () => {
  return (
    <div>
      <div>
        <GoBack />
      </div>

      <section className="mt-[14px]">
        <RetailComplaintsTable />
      </section>
    </div>
  )
}

export default RetailComplaints
