import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../../components/TableBackground";
import badge_failed from "../../../assets/icons/Badge_failed.svg";
import badge_success from "../../../assets/icons/Badge_success.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {

  editIncompleteFarmerCallStatus,
  getIncompleteFarmers,
  getIncompleteFarmersSearch,
} from "../../../redux/Farmers/farmersActions";
import moment from "moment";
import { debounce } from "lodash";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../../components/Pagination/Pagination";
import { ContentCopy, ExpandMore } from "@mui/icons-material";
import cogoToast from "cogo-toast";
import EmptyState from "../../../components/EmptyState";

const IncompleteProfilesTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const call_status = [
    { key: "CALLED", label: "Called" },
    { key: "MISSED", label: "Missed" },
    { key: "NOT_CALLED", label: "Not Called" },
  ];
  const [status, setStatus] = useState("all");
  const [isClickStatus, setIsClickStatus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const incompleteTabValue =
    sessionStorage.getItem("incompleteTabValue") || "all";

  const [searchValue, setSearchValue] = useState("");
  const {
    incompleteProfiles,
    incompleteProfilesLoading,
    incompleteProfilesTotal,
  } = useSelector((state) => state.farmer);
  const incompletefarmersPagination = sessionStorage.getItem(
    "incomplete_farmer_page"
  );

  const onPageChange = (val) => {
    const { page } = val;
    sessionStorage.setItem("incomplete_farmer_page", page);
    dispatch(
      getIncompleteFarmers({
        page: page,
        value: sessionStorage.getItem("incomplete_farmer_search_value") || "",
        status: status,
      })
    );
  };

  const handleCopyNumber = (number, event) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(number)
      .then(() => {
        cogoToast.success(`${number} copied successfully`);
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        cogoToast.error("Failed to copy number");
      });
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        sessionStorage.setItem("incomplete_farmer_search_value", val);
        //  api to dispatch and make the actions for search
        dispatch(
          getIncompleteFarmersSearch({
            value: val,
            page: incompletefarmersPagination ? incompletefarmersPagination : 1,
            status: status,
          })
        );
      }, 750),
    [dispatch, incompletefarmersPagination, status]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleChangeStatus = (data) => {
    console.log(data);
    const storedSearchValue = sessionStorage.getItem(
      "incomplete_farmer_search_value"
    );
    const page = incompletefarmersPagination ? incompletefarmersPagination : 1;
    setSearchValue(storedSearchValue);
    setStatus(data);
    sessionStorage.setItem("incompleteTabValue", data);
    dispatch(
      getIncompleteFarmers({
        page: page,
        value: storedSearchValue || "",
        status: data,
      })
    );
  };

  const handleChangeCallStatus = (id, e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setIsClickStatus(!isClickStatus);
  };



  const handleGetData = useCallback(() => {
    const storedSearchValue = sessionStorage.getItem(
      "incomplete_farmer_search_value"
    );
    setSearchValue(storedSearchValue);
    const page = incompletefarmersPagination ? incompletefarmersPagination : 1;
    dispatch(
      getIncompleteFarmers({
        page: page,
        value: storedSearchValue || "",
        status: incompleteTabValue,
      })
    );
  }, [dispatch, incompletefarmersPagination, incompleteTabValue])


  // HANDLES CALL STATUS CLICK
  const handleClickStatus = (key, label, id, data, e) => {
    e.stopPropagation();
    console.log(data, id, key, label, localStorage.getItem("userId"))
    let values = {
      call_status: key,
      user_id: Number(localStorage.getItem("userId")),
      call_category: "INCOMPLETE_FARMER",
      phone_number: data.phone_number
    };
    dispatch(editIncompleteFarmerCallStatus({ id, values }));
    setIsClickStatus(false);
    setTimeout(() => {
      handleGetData()
    }, 800);
  };

  useEffect(() => {
    handleGetData()
  }, [handleGetData]);

  return (
    <div>
      <TableBackground>
        {/*  */}
        <p className="text-primary font-semibold text-base leading-5 px-4 pt-4">
          Incomplete Profiles ({incompleteProfilesTotal})
        </p>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4 mt-4 px-4">
          <div className="w-full relative">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS SECTION */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 cursor-pointer px-4">
            <div
              className={
                incompleteTabValue === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleChangeStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    incompleteTabValue === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>
                    {incompleteTabValue === "all"
                      ? incompleteProfilesTotal
                      : "0"}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={
                incompleteTabValue === "called"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleChangeStatus("called")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Called
                </p>
                <div
                  className={
                    incompleteTabValue === "called"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>
                    {incompleteTabValue === "called"
                      ? incompleteProfilesTotal
                      : "0"}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={
                incompleteTabValue === "missed"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleChangeStatus("missed")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Missed
                </p>
                <div
                  className={
                    incompleteTabValue === "missed"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>
                    {incompleteTabValue === "missed"
                      ? incompleteProfilesTotal
                      : "0"}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={
                incompleteTabValue === "not_called"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => handleChangeStatus("not_called")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Not called
                </p>
                <div
                  className={
                    incompleteTabValue === "not_called"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>
                    {incompleteTabValue === "not_called"
                      ? incompleteProfilesTotal
                      : "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {incompleteProfilesLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {incompleteProfiles.length === 0 ? (
                <div>
                  <EmptyState text="There's no data for this filter" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        First Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Last Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Phone Number
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        State
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Local Government
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Call Status
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Crop/Livestock
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        BVN
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        {" "}
                        Date
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {incompleteProfiles.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                          onClick={() => navigate(`/farmers-profile/${data?.id}`)}
                        >
                          <td className="px-4">{data.first_name}</td>
                          <td className="px-4">{data.last_name}</td>
                          <td className="px-4">
                            <div
                              className="flex gap-[6px]"
                              onClick={(event) =>
                                handleCopyNumber(data?.phone_number, event)
                              }
                            >
                              <p>{data.phone_number || "N/A"}</p>
                              <ContentCopy
                                className=" cursor-pointer"
                                style={{ fontSize: "18px" }}
                              />
                            </div>
                          </td>
                          <td className="px-4">
                            <div className="w-[70px]">
                              {data.state ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </div>
                          </td>
                          <td className="px-4">
                            {data.lga ? (
                              <img src={badge_success} alt="badge" />
                            ) : (
                              <img src={badge_failed} alt="badge" />
                            )}
                          </td>
                          <td className="px-4">
                            {" "}
                            <div className="relative">
                              <div
                                className={
                                  data.call_status === "CALLED"
                                    ? "success-badge flex gap-[9px] items-center "
                                    : data.call_status === "MISSED"
                                      ? "pending-badge flex gap-[9px] items-center "
                                      : "notcalled-badge flex gap-[9px] items-center "

                                }
                                onClick={(event) =>
                                  handleChangeCallStatus(data?.id, event, index)
                                }
                              >
                                <p>
                                  {data.call_status === "CALLED"
                                    ? "Called"
                                    : data.call_status === "MISSED"
                                      ? "Missed"
                                      : "Not Called"
                                  }
                                </p>
                                {data.call_status === "CALLED" ? (
                                  <ExpandMore
                                    className="text-primary cursor-pointer"
                                    style={{ fontSize: "12px" }}
                                  />
                                ) : data.call_status === "MISSED" ? (
                                  <ExpandMore
                                    className="text-[#d5a407] cursor-pointer"
                                    style={{ fontSize: "12px" }}
                                  />
                                ) : (
                                  <ExpandMore
                                    className="text-[#b92043] cursor-pointer"
                                    style={{ fontSize: "12px" }}
                                  />
                                )}
                              </div>
                              {isClickStatus && selectedIndex === index ? (
                                <div
                                  className="absolute rounded-[8px] w-[120px] p-[8px] bg-white z-10"
                                  style={{
                                    boxShadow:
                                      "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                                  }}
                                >
                                  {call_status?.map((callData, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                                        onClick={(event) =>
                                          handleClickStatus(
                                            callData.key,
                                            callData.label,
                                            data?.id,
                                            data,
                                            event
                                          )
                                        }
                                      >
                                        <p className="font-medium text-sm leading-5 text-[#344335]">
                                          {callData.label}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </td>
                          <td className="px-4">{data.crop}</td>
                          <td className="px-4">
                            <div className="w-[70px]">
                              {data.bvn ? (
                                <img src={badge_success} alt="badge" />
                              ) : (
                                <img src={badge_failed} alt="badge" />
                              )}
                            </div>
                          </td>
                          <td className="px-4">
                            {moment(data.created).format("ll")}
                          </td>
                          <td className="px-4">
                            {moment(data.created).format("LT")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>)}
            </>
          )}

          {!incompleteProfilesLoading && incompleteProfiles.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={
                  incompletefarmersPagination
                    ? Number(incompletefarmersPagination)
                    : 1
                }
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={incompleteProfilesTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default IncompleteProfilesTable;
