import * as Yup from "yup";

export const loginValidator = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

export const forgotPasswordValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const resetPasswordValidator = Yup.object({
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const addInfoCardValidator = Yup.object({
  name: Yup.string().required("Product name is required"),
  description: Yup.string().required("Description is required"),
});

export const addInboundCallsValidator = Yup.object({
  farmer_name: Yup.string().required("Farmer name is required"),
  phone_number: Yup.string()
    .min(11, "Phone number cannot be less than 11 digits")
    .max(11, "Exceeded characters for phone number")
    .required("Phone Number is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  summary: Yup.string(),
});

export const refundApplicationValidator = Yup.object({
  orderID: Yup.string().required("Order ID is required"),
  cost: Yup.string().required("Enter a cost"),
  // .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount")
  // .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid cost"),
  refundAmount: Yup.string()
    .required("Enter a refund amount")
    .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  description: Yup.string(),
});

export const changePasswordValidator = Yup.object({
  oldpassword: Yup.string().required("Password is required"),
  password: Yup.string().required("Enter a new password"),
  password_confirmation: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
