import React from "react";
import GoBack from "../../../components/GoBack";
import Header from "../../../components/Header";
import PendingMessagesTable from "../../../tables/Messages/pendingMessages";

const PendingMessages = () => {
  return (
    <div>
      <div>
        <GoBack />
      </div>

      <div className="mt-[14px] mb-6">
        <Header text="Pending Messages" />
      </div>

      <div>
        <PendingMessagesTable />
      </div>
    </div>
  );
};

export default PendingMessages;
