import React from 'react'
import GoBack from '../../../components/GoBack'
import Header from '../../../components/Header'
import FlaggedFarmersTable from '../../../tables/Farmers/FlaggedFarmersTable'

const FlaggedFarmers = () => {
  return (
    <div>
      <div>
        <GoBack />
      </div>

      <div className="mt-[14px] mb-6">
        <Header text="Flagged Farmers" />
      </div>

      <div>
        <FlaggedFarmersTable />
      </div>
    </div>
  )
}

export default FlaggedFarmers
