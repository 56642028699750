import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { getFlaggedAgents } from "../../redux/Agents/agentsAction";
import EmptyState from "../../components/EmptyState";
import { useNavigate } from "react-router-dom";

const FlaggedAgentsTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { flaggedAgentsData, flaggedAgentsTotal, flaggedAgentsLoading } =
    useSelector((state) => state.agents);

  // COMPLAINTS PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(getFlaggedAgents({ page: page, search: searchValue }));
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        dispatch(getFlaggedAgents({ page: 1, search: val }));
      }, 750),
    [dispatch]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    dispatch(getFlaggedAgents({ page: 1, search: searchValue }));
  }, [dispatch, searchValue]);

  return (
    <div>
      <TableBackground>
        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        <section className="mt-[30px] mb-[24px]">
          {flaggedAgentsLoading ? (
            <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <>
              {flaggedAgentsData.length === 0 ? (
                <EmptyState text="There's no data for this filter yet" />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="px-4 pb-2">
                        First Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Last Name
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Phone Number
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Date Flagged
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        Cooperatives
                      </th>

                      <th scope="col" className="px-4 pb-2">
                        {" "}
                        State
                      </th>
                      <th scope="col" className="px-4 pb-2">
                        LGA
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {flaggedAgentsData.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="relative cursor-pointer hover:bg-[#edf7ee]"
                          onClick={() => navigate(`/cooperative/${data?.id}`)}
                        >
                          <td className="px-4">{data?.first_name || "N/A"}</td>
                          <td className="px-4">{data?.last_name || "N/A"}</td>
                          <td className="px-4">
                            {data?.phone_number || "N/A"}
                          </td>
                          <td className="px-4">
                            {data?.referral_count || "N/A"}
                          </td>
                          <td className="px-4">
                            {data?.cooperative_count || "N/A"}
                          </td>
                          <td className="px-4">{data?.state || "N/A"}</td>

                          <td className="px-4">{data?.lga || "N/A"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}

          {!flaggedAgentsLoading && flaggedAgentsData.length > 0 ? (
            <div className="mt-1">
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange({ page })}
                pageSize={10}
                totalItems={flaggedAgentsTotal}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </TableBackground>
    </div>
  );
};

export default FlaggedAgentsTable;
