import React, { useEffect, useState, useMemo, useCallback } from "react";
import TableBackground from "../../components/TableBackground";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import {
  getComplaints,
  getSearchComplaints,
} from "../../redux/Complaints/complaintsAction";
import DrawerComponent from "../../components/Drawers/drawers";
import { Link } from "react-router-dom";

const IVRTable = () => {
  const [status, setStatus] = useState("all");
  const [isClickTable, setIsClickTable] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [complaintSummary] = useState({});
  const [selectedStatus] = useState("");

  const {
    complaintsData,
    complaintsTotal,
    complaintsLoading,
    // editComplaintsLoading,
  } = useSelector((state) => state.complaints);

  // COMPLAINTS PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    if (status === "all") {
      dispatch(getComplaints({ page: page, value: null }));
    } else if (status === "resolved") {
      dispatch(getComplaints({ page: page, value: "RESOLVED" }));
    } else if (status === "unresolved") {
      dispatch(getComplaints({ page: page, value: "UNRESOLVED" }));
    }
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        if (status === "all") {
          dispatch(
            getSearchComplaints({
              page: currentPage,
              value: null,
              searchValue: val,
            })
          );
        } else if (status === "resolved") {
          dispatch(
            getSearchComplaints({
              page: currentPage,
              value: "RESOLVED",
              searchValue: val,
            })
          );
        } else if (status === "unresolved") {
          dispatch(
            getSearchComplaints({
              page: currentPage,
              value: "UNRESOLVED",
              searchValue: val,
            })
          );
        }
      }, 750),
    [dispatch, currentPage, status]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    if (status === "all") {
      dispatch(getComplaints({ page: 1, value: null }));
    } else if (status === "resolved") {
      dispatch(getComplaints({ page: 1, value: "RESOLVED" }));
    } else if (status === "unresolved") {
      dispatch(getComplaints({ page: 1, value: "UNRESOLVED" }));
    }
  }, [dispatch, status]);

  return (
    <div>
      <TableBackground>
        <p className="text-primary font-semibold text-base leading-5 pt-4 px-4">
          IVR History
        </p>

        {/* SEARCH AND FILTER SECTION */}
        <section className="flex gap-4  pt-4">
          <div className="w-full relative px-4">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </section>

        {/* TABS SECTION */}
        <section>
          <div className="flex gap-4 mt-4 mb-5 cursor-pointer px-4">
            <div
              className={
                status === "all"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("all")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  All
                </p>
                <div
                  className={
                    status === "all"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "all" ? complaintsTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "resolved"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("resolved")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Complete
                </p>
                <div
                  className={
                    status === "resolved"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "resolved" ? complaintsTotal : "0"}</p>
                </div>
              </div>
            </div>

            <div
              className={
                status === "unresolved"
                  ? "flex text-primary border-b-primary border-b-[2px] pb-[4px]"
                  : "flex text-[#96A397] pb-[4px]"
              }
              onClick={() => setStatus("unresolved")}
            >
              <div className="flex">
                <p className=" leading-[18px] text-sm font-semibold pr-[4px]">
                  Complete
                </p>
                <div
                  className={
                    status === "unresolved"
                      ? "bg-[#DBFFDF] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                      : "bg-[#ECEEEC] rounded-[4px] py-[2px] px-[4px]  leading-[14px] text-[10px] font-semibold "
                  }
                >
                  <p>{status === "unresolved" ? complaintsTotal : "0"}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {status === "all" ? (
          <section className="mt-[30px] mb-[24px]">
            {complaintsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="px-4 pb-2">
                      First Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Activity
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Text
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Previous Text
                    </th>

                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Session ID
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {complaintsData.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => setIsClickTable(true)}
                        className="relative cursor-pointer hover:bg-[#edf7ee]"
                      >
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">
                          {moment(data.complaint_date).format("ll")}
                        </td>
                        <td className="px-4">
                          {moment(data.complaint_date).format("LT")}
                        </td>
                        <td className="px-4">
                          <div className={``}>
                            <p>12345</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {!complaintsLoading && complaintsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={complaintsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "resolved" ? (
          <section className="mt-[30px] mb-[24px]">
            {complaintsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="px-4 pb-2">
                      First Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Activity
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Text
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Previous Text
                    </th>

                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Session ID
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {complaintsData.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => setIsClickTable(true)}
                        className="relative cursor-pointer hover:bg-[#edf7ee]"
                      >
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">
                          {moment(data.complaint_date).format("ll")}
                        </td>
                        <td className="px-4">
                          {moment(data.complaint_date).format("LT")}
                        </td>
                        <td className="px-4">
                          <div className={``}>
                            <p>12345</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {!complaintsLoading && complaintsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={complaintsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : status === "unresolved" ? (
          <section className="mt-[30px] mb-[24px]">
            {complaintsLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="px-4 pb-2">
                      First Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Name
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Activity
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Last Text
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Previous Text
                    </th>

                    <th scope="col" className="px-4 pb-2">
                      {" "}
                      Date
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Time
                    </th>
                    <th scope="col" className="px-4 pb-2">
                      Session ID
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {complaintsData.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => setIsClickTable(true)}
                        className="relative cursor-pointer hover:bg-[#edf7ee]"
                      >
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">Test</td>
                        <td className="px-4">
                          {moment(data.complaint_date).format("ll")}
                        </td>
                        <td className="px-4">
                          {moment(data.complaint_date).format("LT")}
                        </td>
                        <td className="px-4">
                          <div className={``}>
                            <p>12345</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {!complaintsLoading && complaintsData.length > 0 ? (
              <div className="mt-1">
                <PaginationComponent
                  page={currentPage}
                  onPageChange={(page) => onPageChange({ page })}
                  pageSize={10}
                  totalItems={complaintsTotal}
                />
              </div>
            ) : (
              ""
            )}
          </section>
        ) : null}
      </TableBackground>

      {/* COMPLAINT DRAWER */}
      <DrawerComponent
        title="IVR Info"
        show={isClickTable}
        showDrawer={() => setIsClickTable(false)}
      >
        <div className="mt-8">
          <div className="flex justify-between items-center">
            <div>
              <h5 className="text-xs text-[#5C715E]">Name</h5>
              <p className="font-medium text-[#5C715E] text-sm mt-1">Test</p>
            </div>
            <div>
              <Link
                to={`/farmers-profile/${complaintSummary.id}`}
                className="text-[#2B8C34] text-sm font-medium cursor-pointer no-underline"
              >
                Go to Farmer's profile
              </Link>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Phone Number</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">Test</p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Activity</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">Test</p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Transaction Session</h5>
          <div className="flex mt-2">
            <div
              className={
                selectedStatus === "RESOLVED"
                  ? "px-4 success-badge flex gap-5 cursor-pointer items-center"
                  : selectedStatus === "UNRESOLVED"
                  ? "px-4 error-badge flex  gap-5 cursor-pointer items-center"
                  : null
              }
            >
              <p className="capitalize">
                {selectedStatus === "RESOLVED"
                  ? "Resolved"
                  : selectedStatus === "UNRESOLVED"
                  ? "Unresolved"
                  : null}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Last Text</h5>
          <p className="font-medium text-sm mt-1 text-primary">Test</p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Previous Text</h5>
          <p className="font-medium text-sm mt-1 text-primary">Test</p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Date/Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {complaintSummary.date || "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Session ID</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">1234</p>
        </div>
      </DrawerComponent>
    </div>
  );
};

export default IVRTable;
