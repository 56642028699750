import React from 'react'
import { CardWithBorder } from '../../../components/Cards';
import Header from '../../../components/Header';
import MemberLogTable from '../../../tables/CallLog/Member';

const MemberLogPage = () => {
    return ( 
        <>
         <Header text="Call Log" />
         <div className='grid grid-cols-4 gap-4 mt-4'>
            <CardWithBorder title="Inbound Farmers Call" subtitle="300" color="border-l-primary" href="/call-log/inboundcalls" />
            <CardWithBorder title="Temporary Farmers" subtitle="250" color="border-l-[#B92043]" href="/call-log/temporaryprofile" />
            <CardWithBorder title="Completed Profiles" subtitle="300" color="border-l-primary" href="/call-log/completeprofile" />
            <CardWithBorder title="Incomplete Profile" subtitle="300" color="border-l-[#FFC508]" href="/call-log/incompleteprofile" />
         </div>
         <MemberLogTable />
        </>
     );
}
 
export default MemberLogPage;