import React, { useEffect } from 'react'
import { CardWithBorder } from '../../components/Cards';
import Header from '../../components/Header';
import SessionsTable from '../../tables/Sessions';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionActivities } from '../../redux/Sessions/sessionActions';

const USSDSessionsPage = () => {
    const dispatch = useDispatch()
    const {count} = useSelector((state) => state.session)

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
      }

    useEffect(()=>{
        dispatch(getSessionActivities({
            page: 1
        }))
    },[dispatch])
    return (
        <>
            <Header text="USSD Sessions" />
            <div className='mt-4 max-w-[260px]'>
                <CardWithBorder title="Check Balance" subtitle={count ? numberWithCommas(count) : 0 } description="Most Used Activity" href="/ussd-sessions/activities" />
            </div>
            <SessionsTable />
        </>
      );
}
 
export default USSDSessionsPage;