import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardWithoutSeeMore } from "../../components/Cards";
import Header from "../../components/Header";
import { getComplaints } from "../../redux/Complaints/complaintsAction";
import ComplaintsTable from "../../tables/Complaints";

const Complaints = () => {
  const dispatch = useDispatch();
  const { complaintsTotal, totalRetailData } = useSelector(
    (state) => state.complaints
  );

  useEffect(() => {
    dispatch(getComplaints({ page: 1, value: null }));
  }, [dispatch]);

  const cardsData = [
    {
      title: "Total",
      subtitle: `${complaintsTotal || 0}`,
      description: "Total Number of Complaints",
    },
    {
      title: "Retail",
      subtitle: `${totalRetailData || 0}`,
      description: "Total Number of Retail Complaints",
      href: "/retail-complaints",
    },
  ];

  return (
    <div>
      <Header text="Complaints" />

      {/* CARDS SECTION */}
      <section>
        <div className="grid grid-cols-4 gap-6 mt-6 mb-8">
          {cardsData.map((item, index) => {
            return (
              <>
                {item.href ? (
                  <Card
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                    href={item.href}
                  />
                ) : (
                  <CardWithoutSeeMore
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                  />
                )}
              </>
            );
          })}
        </div>
      </section>

      {/* TABLE SECTION */}
      <section>
        <ComplaintsTable />
      </section>
    </div>
  );
};

export default Complaints;
