import React, { useState, useEffect } from "react";
import SearchComponent from "../../components/Search";
import { useSelector, useDispatch } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import { getSessionActivities } from "../../redux/Sessions/sessionActions";
import moment from "moment";

const ActivitiesTable = () => {
  const dispatch = useDispatch();
  const { loading, activities, count } = useSelector((state) => state.session);

  const [currentPage, setCurrentPage] = useState(1);

  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(
      getSessionActivities({
        page: page,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getSessionActivities({
        page: 1,
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Ranking
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Activity
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Entries
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Last Entry Date
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Last Entry Time
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {activities.length > 0
                  ? activities.map((item, index) => (
                      <tr
                        key={index}
                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      >
                        <th
                          scope="row"
                          className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                        >
                          {item.rank ? item.rank : "N/A"}
                        </th>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.activity}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                          {item.entries}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                        {item.last_entry ? moment(item.last_entry).format('Do, MMM YYYY') : "N/A"}
                        </td>
                        <td className="py-4 px-4 text-[#5C715E]">
                        {item.last_entry ? moment(item.last_entry).format('hh:mm:ss a') : "N/A"}
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            )}
          </table>
          {loading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>

      {!loading && activities.length > 0 ? (
        <div className="mt-1">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={26}
            totalItems={count}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ActivitiesTable;
