import { createSlice } from '@reduxjs/toolkit'
import { getSessionActivities, getUSSDSessions, searchUSSDSessions } from './sessionActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  value: 0,
  loader: false,
  loading: false,
  sessions: [],
  total: 0,
  count : 0,
  activities: []
}


const sessionPersistConfig = {
  key: 'sessions',
  storage,
}


export const sesssionsSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
  },
  extraReducers: {
      // fetch ussd sessions  ----------
    [getUSSDSessions.pending]: (state) =>{
      state.loader = true
    },
    [getUSSDSessions.fulfilled]: (state, {payload}) =>{
      state.loader = false
      state.sessions = payload.data
      state.total = payload.total
    },
    [getUSSDSessions.rejected]: (state) =>{
        state.loader = false
    },

    // search ussd sessions 
    [getSessionActivities.pending]: (state) =>{
        state.loading = true
    },
    [getSessionActivities.fulfilled]: (state,{payload}) =>{
        state.loading = false
        state.count = payload.count
        state.activities = payload.data
    },
    [getSessionActivities.rejected]: (state) =>{
        state.loading = false
    },

    // search ussd sessions
    [searchUSSDSessions.pending]: (state) =>{
        state.loader = true
    },
    [searchUSSDSessions.fulfilled]: (state,{payload}) =>{
        state.loader = false
        state.sessions = payload.data
        state.total = payload.total
    },
    [searchUSSDSessions.rejected]: (state) =>{
        state.loader = false
    }
   
  },
})


const ussdSessionReducer = persistReducer(sessionPersistConfig, sesssionsSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement } = sesssionsSlice.actions

export default ussdSessionReducer
