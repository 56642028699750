import React, { useEffect } from 'react'
import GoBack from '../../components/GoBack';
import { ContentCopy } from "@mui/icons-material";
import cogoToast from 'cogo-toast';
import GroupLoanApplicationTable from '../../tables/Loans/GroupLoanApplicationTable';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupLoansByCooperative } from '../../redux/Loans/loanActions';
import moment from 'moment'

const GroupLoanApplicationPage = () => {
        const dispatch = useDispatch()
        const { GroupLoans } = useSelector((state) => state.loan);
        const {group} = useParams()
    const handleCopyNumber = (number) => {
        navigator.clipboard.writeText(number);
        cogoToast.success(`${number} copied successfully`);
    };


    const splitFullName = (val) => {
        const fullName = val;
        const parts = fullName.split(" ");
        const firstName = parts[0] ? parts[0]: '';
        const lastName = parts[1] ? parts[1]: "";
        return {
          firstName,
          lastName,
        };
    };

    useEffect(()=>{
        dispatch(getGroupLoansByCooperative({
            type: 'all',
            code: group,
            
        }))
    },[dispatch, group])


    return ( 
        <>
             <GoBack />
           

            <div className='bg-white mt-4 rounded-[8px] p-5'>
                <div>
                    <h6 className='text-[#2B8C34] font-semibold text-lg'>{GroupLoans && GroupLoans.length ? GroupLoans[0].cooperative.cooperative_name: ""} Loan Application</h6>
                </div>
                <div className='mt-3'>
                    <p className='text-[#344335] font-semibold text-base leading-5'>Agent Profile</p>
                </div>
            </div>

            <div className='mt-4 bg-white rounded-lg py-4 px-4'>

            <div className='flex justify-between'>  
                <div className="flex items-center gap-[8px] mb-[24px]">
                    <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex items-center justify-center">
                        <p className="font-medium text-base leading-6 ">
                        {GroupLoans && GroupLoans.length ? splitFullName(GroupLoans[0].loan_officer.loan_profile_name).firstName.charAt(0)
                              .toUpperCase(): ""} 
                        {GroupLoans && GroupLoans.length ? splitFullName(GroupLoans[0].loan_officer.loan_profile_name).lastName.charAt(0)
                              .toUpperCase(): ""} 
                        </p>
                    </div>
                    <div className="">
                        <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                        {GroupLoans && GroupLoans.length ? GroupLoans[0].loan_officer.loan_profile_name: ""} 
                        </p>
                        <div
                            className="flex gap-[6px] mt-[4px] cursor-pointer"
                            onClick={() =>
                            handleCopyNumber(`${GroupLoans && GroupLoans.length ? GroupLoans[0].loan_officer.loan_officer_phone_number: ""}`)
                            }
                        >
                            <p className="text-primary font-semibold leading-[18px] text-sm">
                            {GroupLoans && GroupLoans.length ? GroupLoans[0].loan_officer.loan_officer_phone_number: ""} 
                            </p>
                            <ContentCopy
                            className="text-primary"
                            style={{ fontSize: "18px" }}
                            />
                        </div>
                    </div>

                </div>
                <div>
                    <Link
                    to={`/agrofastcash/loan-agent/${GroupLoans && GroupLoans.length ? GroupLoans[0].loan_officer.loan_officer_id: ""}`}
                    className="w-full py-3 flex items-center justify-center px-6 bg-[#fff] border border-primary rounded-[8px] text-primary hover:text-white text-sm font-medium  hover:bg-[#24752B] transition-all"
                    >
                    <p className="font-medium text-sm">View Agent Profile</p>
                    </Link>
              </div>
             </div>

                <div className="flex justify-between gap-[20px] mt-4">
                      {/* Agent ID */}
                    <div className="">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            Date Created
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                        {GroupLoans && GroupLoans.length ? moment(GroupLoans[0].cooperative.created_date).format('Do, MMM YYYY'): ""} 
                        </p>
                    </div>
                    

                    <div className="">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                        Cooperative
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                        {GroupLoans && GroupLoans.length ? GroupLoans[0].cooperative.cooperative_name: ""} 
                        </p>
                    </div>
                    <div className="">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            State
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                        {GroupLoans && GroupLoans.length ? GroupLoans[0].cooperative.state: ""} 
                        </p>
                    </div>
                    <div className="">
                        <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                            LGA
                        </p>
                        <p className="font-semibold text-sm leading-5 text-[#344335]">
                        {GroupLoans && GroupLoans.length ? GroupLoans[0].cooperative.lga: ""} 
                        </p>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <GroupLoanApplicationTable />
            </div>
        </>
     );
}
 
export default GroupLoanApplicationPage;