import React, { useEffect, useRef, useState } from "react";
import GoBack from "../../../components/GoBack";
import Header from "../../../components/Header";
import { ContentCopy } from "@mui/icons-material";
import cogoToast from "cogo-toast";
import single_icon from "../../../assets/icons/person.svg";
import grouped_icon from "../../../assets/icons/people_alt.svg";
import add_icon from "../../../assets/icons/add.svg";
import receipt_icon from "../../../assets/icons/image.svg";
// import receipt_img from "../../../assets/images/receipt.svg";
import assignment_add_icon from "../../../assets/icons/assignment_add.svg";
import history_toggle_off from "../../../assets/icons/history_toggle_off.svg";
import ModalComponent from "../../../components/Modals/modal";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  handleALoanRecovery,
  handleCreateRemark,
  handlePaymentRecovery,
} from "../../../redux/Loans/loanActions";
import moment from "moment";
import { useOutsideAlerter } from "../../../helpers/OutsideClick";

const LoanRecoveryLogPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [remark, setRemark] = useState("");

  const { loanRecoveryDetails, approveLoanLoading } = useSelector(
    (state) => state.loan
  );
  const [isRemarkClicked, setIsRemarkClicked] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsRemarkClicked);

  const [loading, setLoading] = useState(false);
  const [isSeeMore, setIsSeeMore] = useState(false);

  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow(!show);
  };

  const [showReject, setShowReject] = useState(false);

  const showRejectModal = () => {
    setShowReject(!showReject);
  };

  const [reason, setReason] = useState("");

  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  const handleApproveLoan = () => {
    const values = {
      approval_status: "approved",
    };
    dispatch(handlePaymentRecovery({ id, values }));

    setTimeout(() => {
      setShow(false);
      dispatch(handleALoanRecovery({ id: id }));
    }, 800);
  };

  const handleRejectLoan = () => {
    const values = {
      approval_status: "rejected",
      note: reason,
    };
    dispatch(handlePaymentRecovery({ id, values }));

    setTimeout(() => {
      setShowReject(false);
      dispatch(handleALoanRecovery({ id: id }));
    }, 800);
  };

  const handleAddRemark = () => {
    const values = {
      note: remark,
    };
    dispatch(handleCreateRemark({ id, values }));
    setTimeout(() => {
      setIsRemarkClicked(false);
      dispatch(handleALoanRecovery({ id: id }));
    }, 800);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(handleALoanRecovery({ id: id })).then(() => {
      setLoading(false);
    });
  }, [dispatch, id]);

  // const group = "single"
  // loanRecoveryDetails?.transaction_details?.repayment_type

  return (
    <>
      {loanRecoveryDetails?.transaction_details?.repayment_type ===
      "grouped" ? (
        <>
          {loading ? (
            <div className="h-screen flex items-center justify-center">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              <GoBack />
              <div className="mt-4">
                <Header text="Loan Recovery" />
              </div>

              <div className="flex gap-[16px] mt-[32px]">
                <div className="w-[30%]">
                  <div className="p-4 bg-white rounded-[8px]">
                    <p className="text-[#7C8D7E] text-sm font-semibold mb-4">
                      Transaction Details
                    </p>

                    <div className="mb-4">
                      <p className="text-[#7C8D7E] text-sm font-normal mb-1">
                        Payer Name
                      </p>
                      <p className="text-[#344335] text-sm font-medium mb-1">
                        {loanRecoveryDetails?.transaction_details?.paid_by ||
                          "N/A"}
                      </p>
                    </div>

                    <div className="mb-4">
                      <p className="text-[#7C8D7E] text-sm font-normal mb-1">
                        Channel
                      </p>
                      <p className="font-medium text-sm bg-[#ECEEEC] py-1 px-1 mt-1 w-[fit-content] rounded-[4px] text-[#344335] capitalize">
                        Bank Transfer
                      </p>
                    </div>

                    <div className="mb-4">
                      <p className="text-[#7C8D7E] text-sm font-normal mb-1">
                        Type
                      </p>
                      <div className="flex gap-1 items-center mt-1">
                        <img alt="single icon" src={grouped_icon} />
                        <p className="text-[#5C715E] text-sm font-medium">
                          {"Grouped"}
                        </p>
                      </div>
                    </div>

                    <div className="mb-4">
                      <p className="text-[#7C8D7E] text-sm font-normal mb-1">
                        Date/TIme
                      </p>
                      <p className="text-[#344335] text-sm font-medium mb-1">
                        Feb. 14, 23, 1:45AM
                      </p>
                    </div>

                    <div className="mb-4">
                      <p className="text-[#7C8D7E] text-sm font-normal mb-1">
                        Agent’s Name
                      </p>
                      <p className="text-[#344335] text-sm font-medium mb-1">
                        {loanRecoveryDetails?.transaction_details?.agent_name ||
                          "N/A"}
                      </p>
                    </div>

                    <div className="mb-4">
                      <p className="text-[#7C8D7E] text-sm font-normal mb-1">
                        Cooperative
                      </p>
                      <p className="text-[#344335] text-sm font-medium mb-1">
                        {loanRecoveryDetails?.transaction_details
                          ?.cooperative || "N/A"}
                      </p>
                    </div>

                    <div className="flex items-center w-full h-full cursor-pointer gap-2">
                      <img
                        src={receipt_icon}
                        alt="receipt"
                        className="w-[18px] h-[18px]"
                      />
                      <p className="text-primary text-sm font-semibold">
                        {" "}
                        See Transaction Receipt
                      </p>
                    </div>
                  </div>

                  <div className="bg-white mt-4 rounded-lg py-4 px-4 max-h-[300px] overflow-y-scroll">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-[#7C8D7E] font-semibold text-sm">
                          Remark
                        </p>
                      </div>
                      <div
                        className="flex gap-2 cursor-pointer"
                        onClick={() => setIsRemarkClicked(true)}
                      >
                        <img src={add_icon} alt="add" />
                        <p className="text-primary font-medium text-sm">Add</p>
                      </div>
                    </div>

                    {isRemarkClicked ? (
                      <div className="mt-4" ref={wrapperRef}>
                        <textarea
                          id="message"
                          rows="8"
                          onChange={(event) => setRemark(event.target.value)}
                          className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                text-secondary1
                                text-sm
                                focus:border-[#ABB6AC]
                                focus:border outline-none
                                rounded-lg resize-none
                                bg-[#F7F7F7] py-3 px-4"
                          placeholder="Describe your remark"
                        ></textarea>

                        <div className="flex justify-end">
                          <button
                            type="submit"
                            onClick={handleAddRemark}
                            disabled={false}
                            className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        {loanRecoveryDetails?.remarks?.length === 0 ? (
                          <div className="flex flex-col justify-between mt-6">
                            <div className="flex justify-center">
                              <img
                                src={assignment_add_icon}
                                alt="assignment add icon"
                              />
                            </div>
                            <div className="mt-1 text-center max-w-[156px] mx-auto">
                              <p className="text-[#7C8D7E] font-medium text-sm">
                                You haven’t added any remarks yet
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            {loanRecoveryDetails?.remarks?.map(
                              (data, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="mt-4 bg-[#F7F7F7] rounded-lg py-4 px-4"
                                  >
                                    <h6 className="text-[#5C715E] text-sm">
                                      {data?.note}
                                    </h6>
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="w-[70%]">
                  <div className="col-span-2">
                    <div className="bg-white rounded-lg py-4 px-4">
                      <div className="flex justify-between">
                        <div>
                          <p className="text-[#7C8D7E] font-semibold text-sm">
                            Total Amount
                          </p>
                          <h4 className="text-primary font-semibold text-xl">
                            ₦
                            {
                              loanRecoveryDetails?.transaction_details
                                ?.amount_repaid
                            }
                          </h4>
                        </div>

                        <>
                          {loanRecoveryDetails?.transaction_details
                            ?.approval_status === "approved" ? (
                            <div className="flex gap-2">
                              <div className="flex items-center success-badge">
                                <p>Approved</p>
                              </div>
                            </div>
                          ) : loanRecoveryDetails?.transaction_details
                              ?.approval_status === "rejected" ? (
                            <div className="flex items-center error-badge">
                              <p>Rejected</p>
                            </div>
                          ) : (
                            <div className="flex gap-6">
                              <button
                                onClick={showModal}
                                className="bg-primary rounded-lg py-2 px-4 cursor-pointer"
                              >
                                <p className="text-white font-medium text-sm">
                                  Approve Payment
                                </p>
                              </button>

                              <button
                                onClick={showRejectModal}
                                className="bg-[#FEF0EC] rounded-lg py-2 px-4 cursor-pointer"
                              >
                                <p className="text-[#B92043] font-medium text-sm">
                                  Reject Payment
                                </p>
                              </button>
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg py-4 px-4 mt-4">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-[#5C715E] font-semibold text-base">
                          Farmers(6)
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg py-4 px-4 mt-4 mb-2">
                    <div className="flex mb-[12px] justify-between items-center ">
                      <div className="flex gap-[8px] items-center">
                        <div>
                          <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
                            <p className="font-medium text-base leading-6 ">
                              RT
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="">
                            <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                              Test Test
                            </p>
                            <div className="flex gap-[6px] mt-[4px] cursor-pointer">
                              <p
                                className={`  text-primary font-semibold leading-[18px] text-sm`}
                              >
                                09000
                              </p>
                              <ContentCopy
                                className={`  text-primary`}
                                style={{ fontSize: "18px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <p className="text-[#7C8D7E] font-[600] leading-[18px] text-sm">
                          Amount
                        </p>
                        <p
                          className={`  text-primary font-semibold leading-[30px] text-2xl`}
                        >
                          ₦22,000.00
                        </p>
                      </div>
                    </div>

                    {isSeeMore && (
                      <div className="flex gap-2 mt-[16px] transition-all">
                        <div className="bg-[#FFF] w-[40%] p-[16px] border-[#ECEEEC] border-[1px] rounded-[8px]">
                          <div className="max-h-[300px] overflow-y-scroll">
                            <div className="flex justify-between">
                              <div>
                                <p className="text-[#7C8D7E] font-semibold text-sm">
                                  Recovery History(
                                  {
                                    loanRecoveryDetails?.recovery_history
                                      ?.length
                                  }
                                  )
                                </p>
                              </div>
                            </div>

                            <>
                              {loanRecoveryDetails?.recovery_history?.length ===
                              0 ? (
                                <div className="flex flex-col justify-between mt-6">
                                  <div className="flex justify-center">
                                    <img
                                      src={history_toggle_off}
                                      alt="history icon"
                                    />
                                  </div>
                                  <div className="mt-1 text-center max-w-[156px] mx-auto">
                                    <p className="text-[#7C8D7E] font-medium text-sm">
                                      No attempt on loan recovery has been made
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {loanRecoveryDetails?.recovery_history?.map(
                                    (data, index) => {
                                      return (
                                        <div
                                          className="mt-4 bg-[#F7F7F7] rounded-lg py-4 px-4"
                                          key={index}
                                        >
                                          <div className="flex justify-between">
                                            <div>
                                              <h6 className="text-[#5C715E] text-sm">
                                                Amount
                                              </h6>
                                              <p className="text-[#344335] text-sm font-semibold">
                                                ₦{data.amount_repaid}
                                              </p>
                                            </div>
                                            <div>
                                              <p
                                                className={`border ${
                                                  data.approval_status ===
                                                  "approved"
                                                    ? "border-primary text-primary"
                                                    : data.approval_status ===
                                                      "pending"
                                                    ? "border-[#fff3ce] text-[#d5a407]"
                                                    : data.approval_status ===
                                                      "pending"
                                                    ? "border-[#b92043] text-[#b92043]"
                                                    : "border-[#ecefec] text-[#96a397]"
                                                }  bg-white py-1 px-1 rounded-[4px] text-xs  font-medium`}
                                              >
                                                {data.approval_status ===
                                                "approved"
                                                  ? "Approved"
                                                  : data.approval_status
                                                  ? "pending"
                                                    ? "Pending"
                                                    : data.approval_status
                                                    ? "Rejected"
                                                    : "Rejected"
                                                  : null}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="flex justify-between items-center mt-4">
                                            <p className="text-[#5C715E] text-xs font-medium capitalize">
                                              {data?.repayment_channel.replace(
                                                "_",
                                                " "
                                              ) || "N/A"}
                                            </p>
                                            <p className="text-[#5C715E] text-xs font-medium">
                                              {moment(
                                                data?.payment_date
                                              ).format("LL")}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </>
                          </div>
                        </div>
                        <div className="bg-[FFF] w-[60%] p-[16px] bg-white border-[#ECEEEC] border-[1px] rounded-[8px]">
                          <p className="text-[#7C8D7E] font-semibold text-sm">
                            Loan Info
                          </p>

                          <div className="flex flex-col gap-[15px]">
                            {loanRecoveryDetails?.transaction_details?.loan_info?.map(
                              (data, index) => {
                                return (
                                  <div key={index} className="">
                                    <div className="px-3 py-4 bg-[#F7F7F7]">
                                      <div className="flex justify-between mt-4 ">
                                        <div className="">
                                          <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                            Amount Expected
                                          </h6>
                                          <p className="font-bold text-sm text-[#344335]">
                                            {data?.amount_expected
                                              ? "₦" + data?.amount_expected
                                              : "N/A"}
                                          </p>
                                        </div>
                                        <div className="">
                                          <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                            Amount Recovered
                                          </h6>
                                          <p className="font-bold text-sm text-[#344335]">
                                            {data?.amount_recovered
                                              ? "₦" + data?.amount_recovered
                                              : "N/A"}
                                          </p>
                                        </div>
                                        <div className="">
                                          <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                            Amount Left
                                          </h6>
                                          <p className="font-bold text-sm text-[#344335]">
                                            {data?.amount_left !== null
                                              ? "₦" + data?.amount_left
                                              : "N/A"}
                                          </p>
                                        </div>
                                      </div>
                                      <p className="font-medium pt-[16px] text-xs text-[#5C715E]">
                                        {data?.loan_date
                                          ? moment(data?.loan_date).format(
                                              "lll"
                                            )
                                          : "N/A"}
                                      </p>
                                    </div>

                                    <div className="flex justify-between items-center gap-[8px] mt-4">
                                      <div>
                                        <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                          Loan Package
                                        </h6>
                                        <p className="font-medium text-sm text-[#344335] mt-1">
                                          {data?.loan_package || "N/A"}
                                        </p>
                                      </div>
                                      <div>
                                        <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                          Loan Tenure
                                        </h6>
                                        <p className="font-medium text-sm text-[#344335] mt-1">
                                          {data?.loan_tenure || "N/A"}
                                        </p>
                                      </div>
                                      <div>
                                        <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                          Loan Interest
                                        </h6>
                                        <p className="font-medium text-sm text-[#344335] mt-1">
                                          {data?.loan_interest
                                            ? data?.loan_interest + "%"
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div>
                                        <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                          Loan Status
                                        </h6>
                                        <div className="flex">
                                          <div
                                            className={
                                              data?.loan_status === "active"
                                                ? "success-badge"
                                                : data?.loan_status ===
                                                  "inactive"
                                                ? "error-badge"
                                                : "na-badge"
                                            }
                                          >
                                            <p className="capitalize">
                                              {" "}
                                              {data?.loan_status || "N/A"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <p
                      className="font-medium text-xs leading-5 text-primary mt-2 cursor-pointer"
                      onClick={() => setIsSeeMore(!isSeeMore)}
                    >
                      {isSeeMore ? "See Less" : "See More"}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          {loading ? (
            <div className="h-screen flex items-center justify-center">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              {/* Approve loan */}
              <ModalComponent
                show={show}
                showModal={showModal}
                title="Approve Loan Recovery Transaction"
                subtitle="Are you sure you want to approve this loan recovery transaction application?"
              >
                <div className="flex justify-end mt-8 mb-3">
                  <button
                    disabled={approveLoanLoading}
                    type="submit"
                    onClick={handleApproveLoan}
                    className="bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => setShow(false)}
                    type="submit"
                    className="bg-transparent
                    rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
                  >
                    Cancel
                  </button>
                </div>
              </ModalComponent>

              {/* Reject loan */}
              <ModalComponent
                show={showReject}
                showModal={showRejectModal}
                title="Reason for Rejection"
                subtitle="Rejecting the loan recovery transaction would mean the farmer won’t get the loan he/she applied for"
              >
                <div className="mt-4">
                  <p className="text-[#344335] text-sm">Reason for Rejection</p>
                  <textarea
                    id="reason"
                    rows="8"
                    values={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="appearance-none w-full
                        placeholder:text-[#96A397] mt-1 placeholder:text-sm
                        text-secondary1
                        text-sm border border-[#96A397]
                        focus:border-[#ABB6AC]
                        focus:border outline-none
                        rounded-lg resize-none
                        bg-[#FFFFFF] py-3 px-4"
                    placeholder="State the reason for rejection"
                  ></textarea>
                </div>

                <div className="mt-5 mb-3">
                  <button
                    onClick={handleRejectLoan}
                    disabled={reason === "" || approveLoanLoading}
                    type="submit"
                    className="bg-[#B92043] 
                    disabled:bg-[#B92043] disabled:bg-opacity-[0.7] w-full
                    rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-opacity-[0.9] hover:bg-[#B92043] transition-all"
                  >
                    Reject Loan Recovery
                  </button>
                </div>
              </ModalComponent>

              <GoBack />
              <div className="mt-4">
                <Header text="Loan Recovery" />
              </div>
              <div className="mt-4 bg-white rounded-lg py-4 px-4">
                <p className="text-[#7C8D7E] text-sm font-semibold">
                  Transaction Details
                </p>
                <div className="flex justify-between items-center mt-3">
                  <div className="flex items-center gap-[8px]">
                    {/* FARMER"S INITIALS */}
                    <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex items-center justify-center">
                      <p className="font-medium text-base leading-6 uppercase">
                        {loanRecoveryDetails?.transaction_details?.full_name?.charAt(
                          0
                        )}
                      </p>
                    </div>
                    <div className="">
                      <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                        {loanRecoveryDetails?.transaction_details?.full_name ||
                          "N/A"}
                      </p>
                      <div
                        className="flex gap-[6px] mt-[4px] cursor-pointer"
                        onClick={() =>
                          handleCopyNumber(
                            loanRecoveryDetails?.transaction_details
                              ?.phone_number
                          )
                        }
                      >
                        <p className="text-primary font-medium leading-[18px] text-sm">
                          {loanRecoveryDetails?.transaction_details
                            ?.phone_number || "N/A"}
                        </p>
                        <ContentCopy
                          className="text-primary"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center gap-[8px] mt-8">
                  <div>
                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                      Payer Name
                    </h6>
                    <p className="font-medium text-sm text-[#344335] mt-1">
                      {loanRecoveryDetails?.transaction_details?.paid_by ||
                        "N/A"}
                    </p>
                  </div>
                  <div>
                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                      Channel
                    </h6>
                    <p className="font-medium text-sm bg-[#ECEEEC] py-1 px-1 mt-1 rounded-[4px] text-[#344335] capitalize">
                      {loanRecoveryDetails?.transaction_details?.repayment_channel?.replace(
                        "_",
                        " "
                      ) || "N/A"}
                    </p>
                  </div>
                  <div>
                    <h6 className="font-[400] text-sm text-[#7C8D7E]">Type</h6>
                    <div className="flex gap-1 items-center mt-1">
                      <img
                        alt="single icon"
                        src={
                          loanRecoveryDetails?.transaction_details
                            ?.repayment_type === "single"
                            ? single_icon
                            : grouped_icon
                        }
                      />
                      <p className="text-[#5C715E] text-sm font-medium">
                        {loanRecoveryDetails?.transaction_details
                          ?.repayment_type === "single"
                          ? "Single"
                          : "Grouped"}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                      Date/Time
                    </h6>
                    <p className="font-medium text-sm text-[#344335] mt-1">
                      {moment(
                        loanRecoveryDetails?.transaction_details?.created
                      ).format("lll") || "N/A"}
                    </p>
                  </div>
                  <div>
                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                      Agent's Name
                    </h6>
                    <p className="font-medium text-sm text-[#344335] mt-1">
                      {loanRecoveryDetails?.transaction_details?.agent_name ||
                        "N/A"}
                    </p>
                  </div>

                  <div>
                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                      Cooperative
                    </h6>
                    <p className="font-medium text-sm text-[#344335] mt-1">
                      {loanRecoveryDetails?.transaction_details?.cooperative ||
                        "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5 grid grid-cols-3 gap-4">
                <div>
                  <div className="bg-white rounded-lg py-4 px-4 max-h-[300px] overflow-y-scroll">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-[#7C8D7E] font-semibold text-sm">
                          Recovery History(
                          {loanRecoveryDetails?.recovery_history?.length})
                        </p>
                      </div>
                    </div>

                    <>
                      {loanRecoveryDetails?.recovery_history?.length === 0 ? (
                        <div className="flex flex-col justify-between mt-6">
                          <div className="flex justify-center">
                            <img src={history_toggle_off} alt="history icon" />
                          </div>
                          <div className="mt-1 text-center max-w-[156px] mx-auto">
                            <p className="text-[#7C8D7E] font-medium text-sm">
                              No attempt on loan recovery has been made
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {loanRecoveryDetails?.recovery_history?.map(
                            (data, index) => {
                              return (
                                <div
                                  className="mt-4 bg-[#F7F7F7] rounded-lg py-4 px-4"
                                  key={index}
                                >
                                  <div className="flex justify-between">
                                    <div>
                                      <h6 className="text-[#5C715E] text-sm">
                                        Amount Recovered
                                      </h6>
                                      <p className="text-[#344335] text-sm font-semibold">
                                        ₦{data.amount_repaid}
                                      </p>
                                    </div>
                                    <div>
                                      <p
                                        className={`border ${
                                          data.approval_status === "approved"
                                            ? "border-primary text-primary"
                                            : data.approval_status === "pending"
                                            ? "border-[#fff3ce] text-[#d5a407]"
                                            : data.approval_status === "pending"
                                            ? "border-[#b92043] text-[#b92043]"
                                            : "border-[#ecefec] text-[#96a397]"
                                        }  bg-white py-1 px-1 rounded-[4px] text-xs  font-medium`}
                                      >
                                        {data.approval_status === "approved"
                                          ? "Approved"
                                          : data.approval_status
                                          ? "pending"
                                            ? "Pending"
                                            : data.approval_status
                                            ? "Rejected"
                                            : "Rejected"
                                          : null}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="flex justify-between items-center mt-4">
                                    <p className="text-[#5C715E] text-xs font-medium capitalize">
                                      {data?.repayment_channel.replace(
                                        "_",
                                        " "
                                      ) || "N/A"}
                                    </p>
                                    <p className="text-[#5C715E] text-xs font-medium">
                                      {moment(data?.payment_date).format("LL")}
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </>
                      )}
                    </>
                  </div>

                  <div className="bg-white mt-4 rounded-lg py-4 px-4 max-h-[300px] overflow-y-scroll">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-[#7C8D7E] font-semibold text-sm">
                          Remark
                        </p>
                      </div>
                      <div
                        className="flex gap-2 cursor-pointer"
                        onClick={() => setIsRemarkClicked(true)}
                      >
                        <img src={add_icon} alt="add" />
                        <p className="text-primary font-medium text-sm">Add</p>
                      </div>
                    </div>

                    {isRemarkClicked ? (
                      <div className="mt-4" ref={wrapperRef}>
                        <textarea
                          id="message"
                          rows="8"
                          onChange={(event) => setRemark(event.target.value)}
                          className="appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                text-secondary1
                                text-sm
                                focus:border-[#ABB6AC]
                                focus:border outline-none
                                rounded-lg resize-none
                                bg-[#F7F7F7] py-3 px-4"
                          placeholder="Describe your remark"
                        ></textarea>

                        <div className="flex justify-end">
                          <button
                            type="submit"
                            onClick={handleAddRemark}
                            disabled={false}
                            className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        {loanRecoveryDetails?.remarks?.length === 0 ? (
                          <div className="flex flex-col justify-between mt-6">
                            <div className="flex justify-center">
                              <img
                                src={assignment_add_icon}
                                alt="assignment add icon"
                              />
                            </div>
                            <div className="mt-1 text-center max-w-[156px] mx-auto">
                              <p className="text-[#7C8D7E] font-medium text-sm">
                                You haven’t added any remarks yet
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            {loanRecoveryDetails?.remarks?.map(
                              (data, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="mt-4 bg-[#F7F7F7] rounded-lg py-4 px-4"
                                  >
                                    <h6 className="text-[#5C715E] text-sm">
                                      {data?.note}
                                    </h6>
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="bg-white rounded-lg py-4 px-4">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-[#7C8D7E] font-semibold text-sm">
                          Amount
                        </p>
                        <h4 className="text-primary font-semibold text-xl">
                          ₦
                          {
                            loanRecoveryDetails?.transaction_details
                              ?.amount_repaid
                          }
                        </h4>
                      </div>

                      {loanRecoveryDetails?.transaction_details
                        ?.approval_status === "approved" ? (
                        <div className="flex gap-2">
                          <div className="flex items-center success-badge">
                            <p>Approved</p>
                          </div>
                        </div>
                      ) : loanRecoveryDetails?.transaction_details
                          ?.approval_status === "rejected" ? (
                        <div className="flex items-center error-badge">
                          <p>Rejected</p>
                        </div>
                      ) : (
                        <div className="flex gap-2">
                          <button
                            onClick={showModal}
                            className="bg-primary rounded-lg py-2 px-4 cursor-pointer"
                          >
                            <p className="text-white font-medium text-sm">
                              Approve Payment
                            </p>
                          </button>

                          <button
                            onClick={showRejectModal}
                            className="bg-[#FEF0EC] rounded-lg py-2 px-4 cursor-pointer"
                          >
                            <p className="text-[#B92043] font-medium text-sm">
                              Reject Payment
                            </p>
                          </button>
                        </div>
                      )}

                      {/* {loanRecoveryDetails?.transaction_details?.loan_info[0].loan_status === "approved" ?
                <div className='flex items-center success-badge'>
                  <p>Approved</p>
                </div>
                : loanRecoveryDetails?.transaction_details?.loan_info[0].loan_status === "rejected" ?
                  <div className='flex items-center error-badge'>
                    <p>Rejected</p>
                  </div> :
                  <div className='flex gap-2'>
                    <button
                      onClick={showModal}
                      disabled={approveLoanLoading}
                      className="bg-primary rounded-lg py-2 px-4 cursor-pointer"
                    >
                      <p className="text-white font-medium text-sm">
                        Approve Payment
                      </p>
                    </button>

                    <button
                      onClick={showRejectModal}
                      className="bg-[#FEF0EC] rounded-lg py-2 px-4 cursor-pointer"
                    >
                      <p className="text-[#B92043] font-medium text-sm">
                        Reject Payment
                      </p>
                    </button>
                  </div>} */}

                      {/* {loanRecoveryDetails?.transaction_details?.approval_status ? null :
                <div className='flex gap-2'>
                <button
                  onClick={showModal}
                  disabled={approveLoanLoading}
                  className="bg-primary rounded-lg py-2 px-4 cursor-pointer"
                >
                  <p className="text-white font-medium text-sm">
                    Approve Payment
                  </p>
                </button>

                <button
                  onClick={showRejectModal}
                  className="bg-[#FEF0EC] rounded-lg py-2 px-4 cursor-pointer"
                >
                  <p className="text-[#B92043] font-medium text-sm">
                    Reject Payment
                  </p>
                </button>
              </div>} */}
                    </div>
                  </div>

                  <div className="mt-4 ">
                    <div className="bg-white rounded-lg py-4 px-4">
                      <p className="text-[#7C8D7E] font-semibold text-sm">
                        Loan Info
                      </p>

                      <div className="flex flex-col gap-[15px]">
                        {loanRecoveryDetails?.transaction_details?.loan_info?.map(
                          (data, index) => {
                            return (
                              <div key={index} className="">
                                <div className="flex justify-between mt-4 px-3 py-4 bg-[#F7F7F7]">
                                  <div className="">
                                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                      Amount Expected
                                    </h6>
                                    <p className="font-bold text-sm text-[#344335]">
                                      {data?.amount_expected
                                        ? "₦" + data?.amount_expected
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div className="">
                                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                      Amount Recovered
                                    </h6>
                                    <p className="font-bold text-sm text-[#344335]">
                                      {data?.amount_recovered
                                        ? "₦" + data?.amount_recovered
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div className="">
                                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                      Amount Left
                                    </h6>
                                    <p className="font-bold text-sm text-[#344335]">
                                      {data?.amount_left !== null
                                        ? "₦" + data?.amount_left
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div className="">
                                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                      Date/Time
                                    </h6>
                                    <p className="font-medium text-xs text-[#5C715E]">
                                      {data?.loan_date
                                        ? moment(data?.loan_date).format("lll")
                                        : "N/A"}
                                    </p>
                                  </div>
                                </div>

                                <div className="flex justify-between items-center gap-[8px] mt-4">
                                  <div>
                                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                      Loan Package
                                    </h6>
                                    <p className="font-medium text-sm text-[#344335] mt-1">
                                      {data?.loan_package || "N/A"}
                                    </p>
                                  </div>
                                  <div>
                                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                      Loan Tenure
                                    </h6>
                                    <p className="font-medium text-sm text-[#344335] mt-1">
                                      {data?.loan_tenure || "N/A"}
                                    </p>
                                  </div>
                                  <div>
                                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                      Loan Interest
                                    </h6>
                                    <p className="font-medium text-sm text-[#344335] mt-1">
                                      {data?.loan_interest
                                        ? data?.loan_interest + "%"
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div>
                                    <h6 className="font-[400] text-sm text-[#7C8D7E]">
                                      Loan Status
                                    </h6>
                                    <div className="flex">
                                      <div
                                        className={
                                          data?.loan_status === "active"
                                            ? "success-badge"
                                            : data?.loan_status === "inactive"
                                            ? "error-badge"
                                            : "na-badge"
                                        }
                                      >
                                        <p className="capitalize">
                                          {" "}
                                          {data?.loan_status || "N/A"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>

                  {loanRecoveryDetails?.transaction_details?.payment_receipt ? (
                    <div className="bg-white rounded-lg py-4 px-4 mt-4">
                      <p className="text-[#7C8D7E] font-semibold text-sm">
                        Transaction Receipt
                      </p>
                      <div className="mt-4">
                        <Zoom>
                          <div>
                            {loanRecoveryDetails?.transaction_details
                              ?.payment_receipt ? (
                              <img
                                className=" max-h-[238px] max-w-[238px]"
                                src={
                                  loanRecoveryDetails?.transaction_details
                                    ?.payment_receipt
                                }
                                alt="receipt"
                              />
                            ) : null}
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default LoanRecoveryLogPage;
