import React, { useState } from "react";
import DrawerComponent from "../../../components/Drawers/drawers";
import PaginationComponent from "../../../components/Pagination/Pagination";
import SearchComponent from "../../../components/Search";
import moment from "moment";

const MemberInboundTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [requestInfo] = useState({});
  const [summaryValue, setSummaryValue] = useState("");

  const handleSummaryChange = (e) => {
    setSummaryValue(e.target.value);
  };

  const closeCallSummary = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
  };

  const toggleRequest = (id) => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      {/* call summary info drawer */}
      <DrawerComponent
        title="Call Summary"
        show={isDrawerOpen}
        showDrawer={closeCallSummary}
      >
        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Name</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.farmer
              ? `${requestInfo.farmer.first_name} ${requestInfo.farmer.last_name}`
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Phone Number</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.farmer
              ? `${requestInfo.farmer.phone_number}`
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Call Date</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.updated
              ? moment(requestInfo.updated).format("Do, MMM YYYY")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8">
          <h5 className="text-xs text-[#5C715E]">Call Time</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            {requestInfo && requestInfo.updated
              ? moment(requestInfo.updated).format("hh:mm:ss a")
              : "N/A"}
          </p>
        </div>

        <div className="mt-8 flex gap-6">
          <div className="flex items-center">
            <input
              id="default-radio-1"
              type="radio"
              value=""
              name="default-radio"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0"
            />
            <label
              for="default-radio-1"
              className="ml-2 text-sm font-medium text-[#5C715E]"
            >
              Call summary
            </label>
          </div>
          <div className="flex items-center">
            <input
              checked
              id="default-radio-2"
              type="radio"
              value=""
              name="default-radio"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-0 "
            />
            <label
              for="default-radio-2"
              className="ml-2 text-sm font-medium text-[#344335]"
            >
              Complaints
            </label>
          </div>
        </div>

        <div className="mt-8">
          <label
            htmlFor="message"
            className="block mb-2 text-xs font-medium text-secondary1"
          >
            Summary
          </label>
          <textarea
            id="message"
            rows="8"
            value={summaryValue}
            onChange={handleSummaryChange}
            className="appearance-none w-full
                placeholder:text-[#96A397] placeholder:text-sm
                    text-[#5C715E]
                    text-sm resize-none
                    focus:border-[#2B8C34] focus:outline-none  
                    focus:shadow-input rounded-lg border border-[#96A397]
                    bg-white py-3 px-4"
            placeholder="Leave a summary..."
          ></textarea>
        </div>

        <div className="flex justify-between mt-4 items-center">
          <div>
            <button
              disabled={false}
              type="button"
              className="text-white bg-primary py-3 px-6
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] font-medium
                         rounded-lg text-sm flex "
            >
              {" "}
              Save
            </button>
          </div>
          <div>
            <p className="text-[#96A397] text-xs font-semibold">
              Edited by <span className="text-[#2B8C34]">Celina Avong</span>
            </p>
            <p className="text-[#96A397] text-xs font-medium">
              02:20 PM | 10 Aug 22
            </p>
          </div>
        </div>
      </DrawerComponent>

      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  First Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Last Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Phone Number
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Date
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Time
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Call Summary
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                onClick={() => toggleRequest(1)}
                className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
              >
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Celine
                </th>
                <td className="py-4 px-4 text-[#5C715E]">Avong</td>
                <td className="py-4 px-4 text-[#5C715E]">+234706082563</td>

                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>View</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Celine
                </th>
                <td className="py-4 px-4 text-[#5C715E]">Avong</td>
                <td className="py-4 px-4 text-[#5C715E]">+234706082563</td>
                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>View</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]">
                <th
                  scope="row"
                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                >
                  Celine
                </th>
                <td className="py-4 px-4 text-[#5C715E]">Avong</td>
                <td className="py-4 px-4 text-[#5C715E]">+234706082563</td>

                <td className="py-4 px-4 text-[#5C715E]">Feb. 22, 2023</td>
                <td className="py-4 px-4 text-[#5C715E]">1:31 pm</td>
                <td className="py-4 px-4">
                  <div className="flex">
                    <div className="success-badge">
                      <p>View</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={200}
        />
      </div>
    </>
  );
};

export default MemberInboundTable;
