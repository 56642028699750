import React, { useEffect, useState } from "react";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { useSelector, useDispatch } from "react-redux";
import { getOverdueOrders } from "../../redux/Orders/ordersAction";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const OverdueOrdersTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { overdueOrderData, overdueOrderLoading, overdueOrderTotal } =
    useSelector((state) => state.orders);
  const [typeID, setTypeID] = useState(2);
  const [typeTitle, setTypeTitle] = useState("Input");
  const orderTypes = [
    {
      id: 2,
      title: "Input",
    },
    {
      id: 3,
      title: "Mechanization",
    },
    {
      id: 4,
      title: "Advisory",
    },
    {
      id: 5,
      title: "Insurance",
    },
  ];

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const toggleType = (id, title) => {
    setTypeID(id);
    setTypeTitle(title);
    dispatch(getOverdueOrders({ page: "1", orderType: title }));
  };

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(getOverdueOrders({ page: page, orderType: typeTitle }));
  };

  const viewOrder = (id, product_type) => {
    let productType;
    if (product_type === "crop_input" || product_type === "input") {
      productType = "Input";
    } else if (
      product_type === "livestock_input" ||
      product_type === "livestcok"
    ) {
      productType = "Livestock";
    } else if (
      product_type === "mechanization_input" ||
      product_type === "mechanization"
    ) {
      productType = "Mechanization";
    } else if (
      product_type === "advisory_input" ||
      product_type === "advisory"
    ) {
      productType = "Advisory";
    } else if (
      product_type === "insurance_input" ||
      product_type === "insurance"
    ) {
      productType = "Insurance";
    }
    navigate(`/order/${id}/${productType}`);
  };

  useEffect(() => {
    dispatch(getOverdueOrders({ page: "1", orderType: typeTitle }));
  }, [dispatch, typeTitle]);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="w-full">
            <SearchComponent
              placeholder="Search"
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {orderTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id, item.title)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : "bg-[#ECEEEC] rounded-[4px] py-1 px-1 text-[#96A397] font-semibold text-[10px]"
                  }
                >
                  {item.id === typeID ? overdueOrderTotal : "0"}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          {overdueOrderLoading ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <table className="w-full text-left">
              <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                <tr>
                  <th scope="col" className="px-4 py-4 font-semibold ">
                    Order ID
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold ">
                    Amount
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Date/Time
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Order Type
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Order Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {overdueOrderData?.map((data, index) => {
                  let orderStatus = "";
                  if (data?.order_status) {
                    orderStatus = data?.order_status?.at(-1);
                  } else {
                    orderStatus = "PENDING";
                  }

                  return (
                    <tr
                      key={index}
                      onClick={() =>
                        viewOrder(
                          data?.id,
                          data?.product_type || typeTitle?.toLowerCase()
                        )
                      }
                      className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                      >
                        {data?.id}
                      </th>
                      <td className="py-4 px-4 text-[#5C715E]">
                        N{data?.total_display_price || 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {moment(data?.created).format("lll")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {data?.purchase_type}
                      </td>
                      <td className="py-4 px-4">
                        <div className="flex">
                          <div
                            className={
                              orderStatus === "PENDING"
                                ? "error-badge"
                                : orderStatus === "PROCESSING"
                                ? "pending-badge"
                                : orderStatus === "DISPATCHED"
                                ? "dispatched-badge"
                                : orderStatus === "DELIVERED"
                                ? "success-badge"
                                : orderStatus === "CANCELLED"
                                ? "error-badge"
                                : "error-badge"
                            }
                          >
                            <p className="capitalize">
                              {orderStatus?.toLocaleLowerCase() || "Pending"}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={overdueOrderTotal}
        />
      </div>
    </>
  );
};

export default OverdueOrdersTable;
