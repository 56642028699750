import React, { useEffect } from 'react'
import { CardWithNoLink } from '../../components/Cards';
import Header from '../../components/Header';
import CallRequestTable from '../../tables/Call Request';
import { useSelector, useDispatch } from 'react-redux';
import { getCallRequestStatThisMonth } from '../../redux/CallRequest/requestActions';

const CallRequestPage = () => {
    const dispatch = useDispatch()
    const {total_requests_this_month} = useSelector((state) => state.request)

    useEffect(()=>{
        dispatch(getCallRequestStatThisMonth())
    },[dispatch])

    return (
        <>
            <Header text="Call Request" />
            <div className='mt-4 max-w-[260px]'>
                <CardWithNoLink title="Total Call Request This Month" subtitle={total_requests_this_month} />
            </div>

            <CallRequestTable />
        </>
      );
}
 
export default CallRequestPage;