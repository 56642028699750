import React from "react";
import Header from "../../components/Header";
import GoBack from "../../components/GoBack/index";
import CallsTable from "../../tables/Retail/calls";

const Calls = () => {
  return (
    <div>
      <div className="mb-5">
        <GoBack />
      </div>
      <div className="mb-4">
        <Header text="Calls" />
      </div>

      <CallsTable />
    </div>
  );
};

export default Calls;
