import React, { useEffect } from "react";
import GoBack from "../../../components/GoBack";
import Header from "../../../components/Header";
import {
  Bronze,
  Copper,
  Gold,
  Plantinum,
  Silver,
} from "../../../components/Ranks/Rank";
import { useDispatch, useSelector } from "react-redux";
import { Add, ContentCopy } from "@mui/icons-material";
import cogoToast from "cogo-toast";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import KYCModal from "./KYCModal";
import { getFarmerRecords } from "../../../redux/Farmers/farmersActions";
import Loading from "../../../components/Loading/Loading";
import EmptyState from "../../../components/EmptyState";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const KYC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { farmersProfileData, kycDocumentData, kycDocumentLoading } =
    useSelector((state) => state.farmer);
  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  useEffect(() => {
    dispatch(getFarmerRecords({ id }));
  }, [dispatch, id]);

  return (
    <div>
      <div className="mb-6">
        <GoBack />
      </div>
      <div className="mb-6">
        <Header text="Farmer KYC Documentation" />
      </div>

      <>
        {kycDocumentLoading ? (
          <div className="flex justify-center items-center mt-[5%]">
            <Loading />
          </div>
        ) : (
          <>
            <div className="flex w-full gap-4">
              <div className="w-[22%]">
                <div className="border border-[#ECEFEC] h-fit bg-white rounded-lg px-4 py-4">
                  <p className="font-semibold text-sm text-[#344335] leading-[18px] mb-4">
                    Farmer Info
                  </p>

                  <div className="flex items-center gap-[8px] mb-4">
                    {sessionStorage.setItem(
                      "phone_number",
                      farmersProfileData?.phone_number
                    )}
                    {/* FARMER"S INITIALS */}

                    {farmersProfileData?.rank === "Plantinum" ? (
                      <Plantinum>
                        {" "}
                        {farmersProfileData?.first_name.charAt(0)}
                        {farmersProfileData?.last_name.charAt(0)}
                      </Plantinum>
                    ) : farmersProfileData?.rank === "Gold" ? (
                      <Gold>
                        {" "}
                        {farmersProfileData?.first_name.charAt(0)}
                        {farmersProfileData?.last_name.charAt(0)}
                      </Gold>
                    ) : farmersProfileData?.rank === "Silver" ? (
                      <Silver>
                        {" "}
                        {farmersProfileData?.first_name.charAt(0)}
                        {farmersProfileData?.last_name.charAt(0)}
                      </Silver>
                    ) : farmersProfileData?.rank === "Bronze" ? (
                      <Bronze>
                        {" "}
                        {farmersProfileData?.first_name.charAt(0)}
                        {farmersProfileData?.last_name.charAt(0)}
                      </Bronze>
                    ) : farmersProfileData?.rank === "Copper" ? (
                      <Copper>
                        {" "}
                        {farmersProfileData?.first_name.charAt(0)}
                        {farmersProfileData?.last_name.charAt(0)}
                      </Copper>
                    ) : null}

                    <div className="">
                      <p className="text-[#344335] font-semibold leading-[18px] text-sm capitalize">
                        {farmersProfileData?.first_name}{" "}
                        {farmersProfileData?.last_name}
                      </p>
                      <div
                        className="flex gap-[6px] mt-[4px] cursor-pointer"
                        onClick={() =>
                          handleCopyNumber(farmersProfileData?.phone_number)
                        }
                      >
                        <p
                          className={`  ${
                            farmersProfileData?.rank === "Plantinum"
                              ? "text-[#4B4E68]"
                              : farmersProfileData?.rank === "Gold"
                              ? "text-[#F9B43B]"
                              : farmersProfileData?.rank === "Silver"
                              ? "text-[#4B7295]"
                              : farmersProfileData?.rank === "Bronze"
                              ? "text-[#E86454]"
                              : farmersProfileData?.rank === "Copper"
                              ? "text-[#6A1505]"
                              : null
                          }font-semibold leading-[18px] text-sm`}
                        >
                          {farmersProfileData?.phone_number}
                        </p>
                        <ContentCopy
                          className={`  ${
                            farmersProfileData?.rank === "Plantinum"
                              ? "text-[#4B4E68]"
                              : farmersProfileData?.rank === "Gold"
                              ? "text-[#F9B43B]"
                              : farmersProfileData?.rank === "Silver"
                              ? "text-[#4B7295]"
                              : farmersProfileData?.rank === "Bronze"
                              ? "text-[#E86454]"
                              : farmersProfileData?.rank === "Copper"
                              ? "text-[#6A1505]"
                              : null
                          }`}
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* SEX */}
                  <div className="mb-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Sex
                    </p>
                    <p className="font-medium text-sm leading-5 text-[#344335]">
                      {farmersProfileData?.gender === "m" ||
                      farmersProfileData?.gender?.toLowerCase() === "male"
                        ? "Male"
                        : farmersProfileData?.gender === "f" ||
                          farmersProfileData?.gender?.toLowerCase() === "female"
                        ? "Female"
                        : "N/A"}
                    </p>
                  </div>

                  {/* State */}
                  <div className="mb-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      State
                    </p>
                    <p className="font-medium text-sm leading-5 text-[#344335]">
                      {farmersProfileData?.state || "N/A"}
                    </p>
                  </div>

                  {/* LGA */}
                  <div className="mb-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      LGA
                    </p>
                    <p className="font-medium text-sm leading-5 text-[#344335]">
                      {farmersProfileData?.lga || "N/A"}
                    </p>
                  </div>

                  {/* DATE OF BIRTH */}
                  <div className="mb-4">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Date Of Birth
                    </p>
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {farmersProfileData?.date_of_birth
                        ? moment(farmersProfileData?.date_of_birth).format(
                            "DD/MM"
                          )
                        : "N/A"}
                    </p>
                  </div>

                  <p
                    onClick={() => navigate(`/edit-profile/${id}`)}
                    className="font-medium text-sm leading-5 text-primary cursor-pointer mb-2"
                  >
                    Edit Profile
                  </p>
                </div>
              </div>
              <div className="w-[78%]">
                <Header text="Report">
                  <div className="bg-primary flex transition-all cursor-pointer px-[16px] text-white items-center gap-1 rounded-lg h-[40px]">
                    <Add style={{ fontSize: "17px" }} />
                    <KYCModal id={id} />
                  </div>
                </Header>

                <div className="mt-5">
                  {kycDocumentData?.length === 0 ? (
                    <div className="flex justify-center items-center mt-[5%]">
                      <EmptyState text="No Document Uploaded Yet" />
                    </div>
                  ) : (
                    <>
                      {kycDocumentData?.map((document, index) => {
                        return (
                          <div
                            key={index}
                            className="bg-white rounded-lg p-4 w-full mb-5"
                          >
                            <div className="bg-[#ECEEEC] rounded-lg w-full  p-3 mb-4">
                              <p className="text-[#5C715E] text-sm font-semibold leading-[18px] pb-[6px]">
                                Note
                              </p>
                              <p className="text-[#344335] text-sm font-medium leading-5">
                                {document?.additional_note || "Nill"}
                              </p>
                            </div>
                            <div>
                              <p className="text-[#5C715E] text-sm font-semibold leading-[18px] pb-3">
                                Attachments
                              </p>

                              <div className="flex flex-wrap gap-3 items-center">
                                {document?.documents?.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="rounded-[4px] w-[192px] h-[108px] bg-[#D9D9D9]"
                                    >
                                      <Zoom>
                                        <img
                                          src={item?.link}
                                          alt="Sample"
                                          className="w-[192px] h-[108px] rounded-[4px]"
                                        />
                                      </Zoom>
                                      <p className="text-[#5C715E] font-medium text-xs leading-4 pt-1">
                                        {item?.type}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="flex gap-7 mt-[45px] items-center">
                              <div className="flex items-center gap-2">
                                <div className="rounded-full bg-primary text-white w-6 h-6 flex items-center justify-center">
                                  <p className="font-medium text-[10px] leading-4 uppercase">
                                    {document?.createdBy?.firstname?.charAt(0)}
                                    {document?.createdBy?.lastname?.charAt(0)}
                                  </p>
                                </div>

                                <p className="text-[#5C715E] text-sm font-semibold leading-[18px]">
                                  {document?.createdBy?.firstname}{" "}
                                  {document?.createdBy?.lastname}
                                </p>
                              </div>
                              <div>
                                <p className="text-[#344335] text-sm font-normal leading-[18px]">
                                  {moment(document?.create).format("ll")} |{" "}
                                  {moment(document?.create).format("LT")}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}

                  {/* <div className="bg-white rounded-lg p-4 w-full mb-5">
              <div className="bg-[#ECEEEC] rounded-lg w-full  p-3 mb-4">
                <p className="text-[#5C715E] text-sm font-semibold leading-[18px] pb-[6px]">
                  Note
                </p>
                <p className="text-[#344335] text-sm font-medium leading-5">
                  KYC process successfully completed, documents deemed genuine
                  with no red flags .Farmer's identity and information align
                  with KYC standards.
                </p>
              </div>

              <div className="flex gap-7 mt-4 items-center">
                <div className="flex items-center gap-2">
                  <div className="rounded-full bg-primary text-white w-6 h-6 flex items-center justify-center">
                    <p className="font-medium text-[10px] leading-4">CA</p>
                  </div>

                  <p className="text-[#5C715E] text-sm font-semibold leading-[18px]">
                    Celina Avong
                  </p>
                </div>
                <div>
                  <p className="text-[#344335] text-sm font-normal leading-[18px]">
                    12:32 AM | 12 Aug 22{" "}
                  </p>
                </div>
              </div>
            </div> */}
                  {/* 
            <div className="bg-white rounded-lg p-4 w-full mb-5">
              <div className="bg-[#ECEEEC] rounded-lg w-full  p-3 mb-4">
                <p className="text-[#5C715E] text-sm font-semibold leading-[18px] pb-[6px]">
                  Note
                </p>
                <p className="text-[#344335] text-center text-sm font-medium leading-5">
                  No Note
                </p>
              </div>
              <div>
                <p className="text-[#5C715E] text-sm font-semibold leading-[18px] pb-3">
                  Attachments
                </p>

                <div className="flex gap-2 items-center">
                  <div className="rounded-[4px] w-[56px] h-[56px] bg-[#D9D9D9]"></div>
                  <div className="rounded-[4px] w-[56px] h-[56px] bg-[#D9D9D9]"></div>
                  <div className="rounded-[4px] w-[56px] h-[56px] bg-[#D9D9D9]"></div>
                  <div className="rounded-[4px] w-[56px] h-[56px] bg-[#D9D9D9]"></div>
                </div>
              </div>
              <div className="flex gap-7 mt-4 items-center">
                <div className="flex items-center gap-2">
                  <div className="rounded-full bg-primary text-white w-6 h-6 flex items-center justify-center">
                    <p className="font-medium text-[10px] leading-4">CA</p>
                  </div>

                  <p className="text-[#5C715E] text-sm font-semibold leading-[18px]">
                    Celina Avong
                  </p>
                </div>
                <div>
                  <p className="text-[#344335] text-sm font-normal leading-[18px]">
                    12:32 AM | 12 Aug 22{" "}
                  </p>
                </div>
              </div>
            </div> */}
                </div>

                {/* EMPTY STATE */}
                {/* <div className="flex items-center justify-center mt-6">
            <div className="flex flex-col items-center">
              <Assignment
                style={{
                  color: "#7C8D7E",
                  fontSize: "26px",
                  marginBottom: "5px",
                }}
              />
              <p className="text-xs leading-4 text-center text-[#7C8D7E] w-[158px]">
                You haven’t added any remarks yet
              </p>
            </div>
          </div> */}
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default KYC;
