import React, { useEffect, useMemo, useState } from "react";
import TableBackground from "../../../components/TableBackground";
import cogoToast from "cogo-toast";
import {
  ContentCopy,
  Star,
  Wallpaper,
  Group,
  Assignment,
  Landscape,
  // ChevronRight,
  AccountBalance,
  AirlineSeatReclineExtra,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  generateAcctNo,
  getFarmersActivityLog,
  getFarmersProfile,
} from "../../../redux/Farmers/farmersActions";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import mapFrame from "../../../assets/images/Frame1.svg";
import passport_icon from "../../../assets/icons/passport_icon.svg";
// import government_icon from "../../../assets/icons/government_icon.svg";
// import utility_icon from "../../../assets/icons/utility_icon.svg";
// import signature_icon from "../../../assets/icons/signature_icon.svg";
import guarantor_icon from "../../../assets/icons/guarantor_icon.svg";
import passport_img from "../../../assets/images/Passport.png";
import guarantor_img from "../../../assets/images/Guarantor.png";
import moment from "moment";
import {
  Plantinum,
  Gold,
  Silver,
  Bronze,
  Copper,
} from "../../../components/Ranks/Rank";
import {
  CopperDescription,
  PlatinumDescription,
  GoldDescription,
  SilverDescription,
  BronzeDescription,
} from "../../../components/Ranks/RankDescription";
import ModalComponent from "../../../components/Modals/modal";
import DrawerComponent from "../../../components/Drawers/drawers";
import { FormatNumber } from "../../../components/FormatNumber";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { Polygon } from "../../../components/Polygon/polygon";

const FarmerAccountInformation = ({ id }) => {
  const dispatch = useDispatch();
  const { farmersProfileData, activityLogLoading, activityLogData } =
    useSelector((state) => state.farmer);
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const { is_team_lead } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [isShowAge, setIsShowAge] = useState(false);
  const [isShowBvn, setIsShowBvn] = useState(false);
  const [toggleAcctNum, setIsToggleAcctNum] = useState(false);
  const [toggleAcctNumGenerated, setIsToggleAcctNumGenerated] = useState(false);
  const [acctNo, setAcctNo] = useState("");
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setShow(!show);
  };

  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`${number} copied successfully`);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const closeGuarantorDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // const handleGuarantorInfo = (id) => {
  //   setIsDrawerOpen(!isDrawerOpen);
  // };

  const handleGenerateAcctNum = () => {
    setLoading(true);
    dispatch(generateAcctNo({ id: id })).then((res) => {
      if (res?.type === "generateAcctNo/rejected") {
        if (res?.payload?.errors) {
          cogoToast.error(res?.payload?.errors);
        } else {
          cogoToast.error("Something went wrong");
        }
        setLoading(false);
        setIsToggleAcctNum(false);
      } else if (res?.type === "generateAcctNo/fulfilled") {
        setAcctNo(res?.payload?.data?.account_number);
        setLoading(false);
        setIsToggleAcctNumGenerated(true);
        setIsToggleAcctNum(false);
        dispatch(getFarmersProfile({ id: id }));
      }
    });
  };

  const handleCopyAccountNumber = (acctNum) => {
    navigator.clipboard
      .writeText(acctNum)
      .then(() => {
        cogoToast.success(`Account number(${acctNum}) copied successfully`);
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        cogoToast.error("Failed to copy account number");
      });
  };

  const center = useMemo(() => {
    if (
      !farmersProfileData?.coordinates ||
      !farmersProfileData?.coordinates?.length
    )
      return { lat: 0, lng: 0 };

    const bounds = farmersProfileData?.coordinates.reduce(
      (acc, coord) => {
        acc.minLat = Math.min(acc.minLat, coord.lat);
        acc.maxLat = Math.max(acc.maxLat, coord.lat);
        acc.minLng = Math.min(acc.minLng, coord.lng);
        acc.maxLng = Math.max(acc.maxLng, coord.lng);
        return acc;
      },
      {
        minLat: Infinity,
        maxLat: -Infinity,
        minLng: Infinity,
        maxLng: -Infinity,
      }
    );

    return {
      lat: (bounds.minLat + bounds.maxLat) / 2,
      lng: (bounds.minLng + bounds.maxLng) / 2,
    };
  }, [farmersProfileData?.coordinates]);

  useEffect(() => {
    dispatch(getFarmersProfile({ id: id }));
    dispatch(getFarmersActivityLog({ id: id }));
  }, [dispatch, id]);

  return (
    <div>
      <div className="flex gap-4 mb-[50px]">
        <div className="w-[75%] ">
          <div>
            <div className="flex gap-4 justify-between w-full mb-4">
              {/* CURRENT BALANCE */}
              <div className="p-4 w-full bg-[#DBFFDF] rounded-lg border-[1px] border-[#A7EAAD] text-[#24752B]">
                <p className="text-sm font-semibold leading-[18px] ">
                  Current wallet balance
                </p>
                <p className="text-2xl font-semibold leading-[30px] pt-1">
                  ₦{FormatNumber(farmersProfileData?.wallet_amount) || 0}
                </p>
              </div>

              {/* AVAILABLE WALLET BALANCE */}
              <div className="p-4  w-full bg-[#FFF3CE] rounded-lg border-[1px] border-[#FFECAD] text-[#D4A407]">
                <p className="text-sm font-semibold leading-[18px] ">
                  Available wallet balance
                </p>
                <p className="text-2xl font-semibold leading-[30px] pt-1">
                  ₦
                  {FormatNumber(farmersProfileData?.available_wallet_balance) ||
                    0}
                </p>
              </div>

              {/* PENDING WALLET BALANCE */}
              <div className="p-4  w-full bg-[#FBB7AB] rounded-lg border-[1px] border-[#FBB7AB] text-[#B92020]">
                <p className="text-sm font-semibold leading-[18px] ">
                  Pending wallet debit
                </p>
                <p className="text-2xl font-semibold leading-[30px] pt-1">
                  ₦{FormatNumber(farmersProfileData?.pending_wallet_debit) || 0}
                </p>
              </div>

              {/* AMOUNT RESTRICTED */}
              <div className="p-4  w-full bg-[#ECEEEC] rounded-lg border-[1px] border-[#DDE2DE] text-[#96A397]">
                <p className="text-sm font-semibold leading-[18px] ">
                  Amount Restricted
                </p>
                <p className="text-2xl font-semibold leading-[30px] pt-1">
                  ₦{farmersProfileData?.amount_restricted || 0}
                </p>
              </div>
            </div>

            <TableBackground
              classname="px-4 p-4"
              style={{ borderRadius: "8px" }}
            >
              <div className="flex justify-between">
                <div className="flex items-center gap-[8px] mb-[24px]">
                  {sessionStorage.setItem(
                    "phone_number",
                    farmersProfileData?.phone_number
                  )}
                  {/* FARMER"S INITIALS */}

                  {farmersProfileData?.rank === "Plantinum" ? (
                    <Plantinum>
                      {" "}
                      {farmersProfileData?.first_name.charAt(0)}
                      {farmersProfileData?.last_name.charAt(0)}
                    </Plantinum>
                  ) : farmersProfileData?.rank === "Gold" ? (
                    <Gold>
                      {" "}
                      {farmersProfileData?.first_name.charAt(0)}
                      {farmersProfileData?.last_name.charAt(0)}
                    </Gold>
                  ) : farmersProfileData?.rank === "Silver" ? (
                    <Silver>
                      {" "}
                      {farmersProfileData?.first_name.charAt(0)}
                      {farmersProfileData?.last_name.charAt(0)}
                    </Silver>
                  ) : farmersProfileData?.rank === "Bronze" ? (
                    <Bronze>
                      {" "}
                      {farmersProfileData?.first_name.charAt(0)}
                      {farmersProfileData?.last_name.charAt(0)}
                    </Bronze>
                  ) : farmersProfileData?.rank === "Copper" ? (
                    <Copper>
                      {" "}
                      {farmersProfileData?.first_name.charAt(0)}
                      {farmersProfileData?.last_name.charAt(0)}
                    </Copper>
                  ) : null}

                  <div className="">
                    <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                      {farmersProfileData?.first_name}{" "}
                      {farmersProfileData?.last_name}
                    </p>
                    <div
                      className="flex gap-[6px] mt-[4px] cursor-pointer"
                      onClick={() =>
                        handleCopyNumber(farmersProfileData?.phone_number)
                      }
                    >
                      <p
                        className={`  ${
                          farmersProfileData?.rank === "Plantinum"
                            ? "text-[#4B4E68]"
                            : farmersProfileData?.rank === "Gold"
                            ? "text-[#F9B43B]"
                            : farmersProfileData?.rank === "Silver"
                            ? "text-[#4B7295]"
                            : farmersProfileData?.rank === "Bronze"
                            ? "text-[#E86454]"
                            : farmersProfileData?.rank === "Copper"
                            ? "text-[#6A1505]"
                            : null
                        }font-semibold leading-[18px] text-sm`}
                      >
                        {farmersProfileData?.phone_number}
                      </p>
                      <ContentCopy
                        className={`  ${
                          farmersProfileData?.rank === "Plantinum"
                            ? "text-[#4B4E68]"
                            : farmersProfileData?.rank === "Gold"
                            ? "text-[#F9B43B]"
                            : farmersProfileData?.rank === "Silver"
                            ? "text-[#4B7295]"
                            : farmersProfileData?.rank === "Bronze"
                            ? "text-[#E86454]"
                            : farmersProfileData?.rank === "Copper"
                            ? "text-[#6A1505]"
                            : null
                        }`}
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  {farmersProfileData?.rank === "Plantinum" ? (
                    <PlatinumDescription handleClick={() => setShow(true)} />
                  ) : farmersProfileData?.rank === "Gold" ? (
                    <GoldDescription handleClick={() => setShow(true)} />
                  ) : farmersProfileData?.rank === "Silver" ? (
                    <SilverDescription handleClick={() => setShow(true)} />
                  ) : farmersProfileData?.rank === "Bronze" ? (
                    <BronzeDescription handleClick={() => setShow(true)} />
                  ) : farmersProfileData?.rank === "Copper" ? (
                    <CopperDescription handleClick={() => setShow(true)} />
                  ) : null}
                </div>
              </div>
              <div className="flex gap-[20px]">
                <div className="w-1/5">
                  {/* Cooperative */}
                  <div className="mb-[24px]">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Cooperative
                    </p>
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {farmersProfileData?.cooperative || "N/A"}
                    </p>
                  </div>

                  {/* SEX */}
                  <div className="mb-[24px]">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Sex
                    </p>
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {farmersProfileData?.gender === "m" ||
                      farmersProfileData?.gender?.toLowerCase() === "male"
                        ? "Male"
                        : farmersProfileData?.gender === "f" ||
                          farmersProfileData?.gender?.toLowerCase() === "female"
                        ? "Female"
                        : "N/A"}
                    </p>
                  </div>

                  {/* LGA */}
                  <div className="">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      LGA
                    </p>
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {farmersProfileData?.lga || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="w-1/5">
                  {/* Cooperative */}
                  <div className="mb-6">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Date Joined
                    </p>
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {moment(farmersProfileData?.created).format("lll")}
                    </p>
                  </div>

                  {/* BVN */}
                  <div className="mb-[24px]">
                    <div className="flex items-center gap-4">
                      <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                        BVN
                      </p>
                      {is_team_lead && (
                        <>
                          {isShowBvn ? (
                            <Visibility
                              onClick={
                                is_team_lead
                                  ? () => setIsShowBvn(!isShowBvn)
                                  : null
                              }
                              style={{ fontSize: "18px" }}
                              className="text-[#7C8D7E] cursor-pointer"
                            />
                          ) : (
                            <VisibilityOff
                              onClick={
                                is_team_lead
                                  ? () => setIsShowBvn(!isShowBvn)
                                  : null
                              }
                              style={{ fontSize: "18px" }}
                              className="text-[#7C8D7E] cursor-pointer"
                            />
                          )}
                        </>
                      )}
                    </div>
                    {isShowBvn ? (
                      <p className="font-semibold text-sm leading-5 text-[#344335]">
                        {farmersProfileData?.bvn || "N/A"}
                      </p>
                    ) : (
                      <p className="font-medium text-sm leading-5 text-[#344335]">
                        {farmersProfileData?.bvn ? (
                          <>
                            {" "}
                            {farmersProfileData?.bvn?.charAt(0)}**********
                            {farmersProfileData?.bvn?.charAt(10)}
                          </>
                        ) : (
                          "N/A"
                        )}
                      </p>
                    )}
                  </div>

                  {/* Crop/Livestock */}
                  <div className="">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Crop/Livestock
                    </p>
                    {farmersProfileData?.crops?.length === 0 &&
                    farmersProfileData?.livestock?.length === 0 ? (
                      <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                        {" "}
                        N/A
                      </p>
                    ) : (
                      <div className="flex flex-wrap gap-1 max-h-[20px] overflow-y-scroll farmers_scroll">
                        {farmersProfileData &&
                        farmersProfileData.crops.length > 0
                          ? farmersProfileData.crops.map(
                              (data, index) =>
                                data !== null && (
                                  <div
                                    className="font-semibold text-sm leading-5 text-[#344335] "
                                    key={index}
                                  >
                                    {data.name}
                                  </div>
                                )
                            )
                          : null}

                        {/* check if the array has a null in it */}

                        {farmersProfileData &&
                        farmersProfileData.livestock.length > 0
                          ? farmersProfileData.livestock.map(
                              (data, index) =>
                                data !== null && (
                                  <div
                                    className="font-semibold text-sm leading-5 text-[#344335] "
                                    key={index}
                                  >
                                    {data.name}
                                  </div>
                                )
                            )
                          : null}
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-1/5">
                  {/* Cooperative */}
                  <div className="mb-6">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Last Seen
                    </p>
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {moment(farmersProfileData?.updated).format("lll")}
                    </p>
                  </div>
                  {/* SEX */}
                  <div className="mb-[24px]">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Account number
                    </p>
                    {farmersProfileData?.account_number ? (
                      <p className="font-semibold text-sm leading-5 text-[#344335]">
                        {farmersProfileData?.account_number || "N/A"}
                      </p>
                    ) : (
                      <div
                        className="bg-white border-[1px] border-[#2B8C34] rounded-lg flex justify-center items-center py-[1px] text-[#2B8C34] px-1 w-fit cursor-pointer"
                        onClick={() => setIsToggleAcctNum(true)}
                      >
                        <p className="text-sm font-medium leading-4">
                          Generate Acct. No
                        </p>
                      </div>
                    )}
                  </div>

                  {/* LGA */}
                  <div className="">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Land Size
                    </p>
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {farmersProfileData?.land_size || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="w-1/5">
                  {/* Registation Status */}
                  <div className="mb-6">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Registation Status
                    </p>
                    <div
                      className={
                        farmersProfileData?.status === "Complete"
                          ? "complete-badge"
                          : farmersProfileData?.status === "Incomplete"
                          ? "error-badge"
                          : null
                      }
                    >
                      <p className="font-semibold text-sm leading-5">
                        {farmersProfileData?.status || "N/A"}
                      </p>
                    </div>
                  </div>

                  {/* Bank */}
                  <div className="mb-[24px]">
                    <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                      Bank
                    </p>
                    <p className="font-semibold text-sm leading-5 text-[#344335]">
                      {farmersProfileData?.bank_name || "N/A"}
                    </p>
                  </div>

                  {/* Age */}

                  <div className="">
                    <div className="flex items-center gap-4">
                      <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                        Age
                      </p>
                      {is_team_lead && (
                        <>
                          {isShowAge ? (
                            <Visibility
                              onClick={
                                is_team_lead
                                  ? () => setIsShowAge(!isShowAge)
                                  : null
                              }
                              style={{ fontSize: "18px" }}
                              className="text-[#7C8D7E]"
                            />
                          ) : (
                            <VisibilityOff
                              onClick={
                                is_team_lead
                                  ? () => setIsShowAge(!isShowAge)
                                  : null
                              }
                              style={{ fontSize: "18px" }}
                              className="text-[#7C8D7E]"
                            />
                          )}
                        </>
                      )}
                    </div>
                    {isShowAge ? (
                      <p className="font-semibold text-sm leading-5 text-[#344335]">
                        {moment().diff(
                          farmersProfileData?.date_of_birth,
                          "years",
                          false
                        )}
                      </p>
                    ) : (
                      <p className="font-medium text-sm leading-5 text-[#344335]">
                        **
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-1/5">
                  {/* STatus */}
                  <div className="">
                    <div className="mb-6">
                      <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                        Status
                      </p>
                      <div className="">
                        <p className="font-semibold text-sm leading-5">
                          {farmersProfileData?.active_status || "N/A"}
                        </p>
                      </div>
                    </div>

                    {/* State */}
                    <div className="mb-[24px]">
                      <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                        State
                      </p>
                      <p className="font-semibold text-sm leading-5 text-[#344335]">
                        {farmersProfileData?.state || "N/A"}
                      </p>
                    </div>

                    {/* LGA */}
                    <div className="">
                      <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                        Date Of Birth
                      </p>
                      <p className="font-semibold text-sm leading-5 text-[#344335]">
                        {farmersProfileData?.date_of_birth
                          ? moment(farmersProfileData?.date_of_birth).format(
                              "DD/MM"
                            )
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </TableBackground>
          </div>
        </div>
        <div className="w-[25%] ">
          <div className="p-4 bg-white border-[1px] border-[#ECEFEC] rounded-lg h-full overflow-y-auto campaignScroll">
            <p className="text-sm font-semibold leading-[18px] text-[#344335] pb-4">
              Activity log
            </p>

            <div className="h-[325px]">
              {activityLogLoading ? (
                <div className="h-full flex justify-between items-center">
                  <p>Loading...</p>
                </div>
              ) : (
                <div className="flex flex-col h-full">
                  {activityLogData.length === 0 ? (
                    <div className="flex flex-col justify-center items-center  h-full">
                      <AirlineSeatReclineExtra
                        style={{ color: "#7C8D7E", fontSize: "32px" }}
                      />
                      <p className="text-sm pt-[15px] font-medium leading-[20px] text-[#5C715E] text-center w-[211px]">
                        No activity has been recorded with this account
                      </p>
                    </div>
                  ) : (
                    <div>
                      {activityLogData?.map((data, index) => {
                        return (
                          <div key={index} className="pb-[20px]">
                            <div className="flex items-center gap-1 pb-1">
                              <div className="flex justify-center items-center h-[16px] w-[16px] bg-primary text-white rounded-full">
                                <p className="text-[8px] font-medium leading-[12px] uppercase">
                                  {data?.performed_by?.first_name?.charAt(0) +
                                    data?.performed_by?.last_name?.charAt(0)}
                                </p>
                              </div>
                              <p className="text-sm font-semibold leading-[18px] text-[#344335]">
                                {data?.performed_by?.first_name +
                                  " " +
                                  data?.performed_by?.last_name}
                              </p>
                            </div>

                            <p className="text-xs font-semibold leading-[16px] text-[#344335] pb-1">
                              <span className="text-[#7C8D7E] capitalize">
                                {data?.action?.split(" ")[0]}
                              </span>{" "}
                              {data?.action?.substring(
                                data?.action?.indexOf(" ") + 1
                              )}
                            </p>
                            <p className="text-xs font-medium leading-[16px] text-[#344335] pb-1">
                              {moment(data?.created).format("LT")} |{" "}
                              {moment(data?.created).format("LL")}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="grid grid-cols-3 gap-4 mt-4">
          <div className="bg-white rounded-[8px] py-3 px-4">
            <p className="text-[#344335] text-sm font-semibold">Farm Details</p>

            {farmersProfileData?.coordinates?.length !== 0 &&
            farmersProfileData?.coordinates !== null ? (
              <div className="flex flex-col gap-4 mt-4">
                {/* state */}
                <div className="">
                  <p className="text-[#7C8D7E] text-sm font-norml">State</p>
                  <p className="text-[#344335] text-sm font-semibold">
                    {" "}
                    {farmersProfileData?.farm_state || "Nill"}
                  </p>
                </div>

                {/* lga */}
                <div className="">
                  <p className="text-[#7C8D7E] text-sm font-norml">
                    Local Government
                  </p>
                  <p className="text-[#344335] text-sm font-semibold">
                    {" "}
                    {farmersProfileData?.farm_lga || "Nill"}
                  </p>
                </div>

                {/* Farm size */}
                <div className="">
                  <p className="text-[#7C8D7E] text-sm font-norml">Size</p>
                  <p className="text-[#344335] text-sm font-semibold">
                    {farmersProfileData?.farm_size || 0}
                  </p>
                </div>

                {/* Coordinates */}
                <div className="">
                  <p className="text-[#7C8D7E] text-sm font-norml">
                    Location Coordinate
                  </p>
                  {farmersProfileData?.coordinates?.map((data, index) => {
                    return (
                      <p
                        key={index}
                        className="text-[#344335] text-sm font-semibold"
                      >
                        {data.lat} - {data.lng}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center min-h-[250px]">
                <Landscape className="text-[#7C8D7E]" />
                <p className="text-xs text-[#7C8D7E] font-medium mt-1 max-w-[150px] text-center">
                  Farm details hasn’t been captured yet
                </p>
              </div>
            )}
            {/*  No farm details  */}
          </div>
          <div className="col-span-2 bg-white rounded-[8px] py-3 px-4">
            {/*  No farm details  */}
            {farmersProfileData?.coordinates?.length !== 0 &&
            farmersProfileData?.coordinates !== null ? (
              <APIProvider apiKey={API_KEY}>
                <div className="h-[350px] rounded-[8px] w-full">
                  <Map
                    mapId="d10801052e62b784"
                    disableDefaultUI={true}
                    mapTypeId="satellite"
                    gestureHandling={"greedy"}
                    zoom={17}
                    center={center}
                  >
                    <Polygon
                      strokeWeight={3}
                      paths={farmersProfileData?.coordinates}
                      strokeColor={"#FFC508"}
                      strokeOpacity={1}
                      fillColor={"#FFFFFF66"}
                      fillOpacity={0.7}
                    />

                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        fontWeight: 500,
                        color: "white",
                        transform: "translate(-50%, -50%)",
                        fontSize: 12,
                        zIndex: 999,
                      }}
                    >
                      {/* {parseFloat(farmDetails?.size)?.toFixed(2) || 0} Ha */}
                    </div>
                  </Map>
                </div>
              </APIProvider>
            ) : (
              <div className="flex flex-col justify-center items-center min-h-[250px]">
                <Wallpaper className="text-[#7C8D7E]" />
                <p className="text-xs text-[#7C8D7E] font-medium mt-1 max-w-[150px] text-center">
                  Farm details hasn’t been captured yet
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="bg-white rounded-[8px] py-3 px-4">
            <p className="text-[#344335] text-sm font-semibold">Documents</p>

            {/*  No documents  */}
            <div className="flex flex-col justify-center items-center min-h-[250px]">
              <Assignment className="text-[#7C8D7E]" />
              <p className="text-xs text-[#7C8D7E] font-medium mt-1 max-w-[150px] text-center">
                No document has been added yet
              </p>
            </div>
          </div>
          <div className="bg-white rounded-[8px] py-3 px-4">
            <p className="text-[#344335] text-sm font-semibold">Guarantor(s)</p>

            {/*  No Guarantor  */}
            <div className="flex flex-col justify-center items-center min-h-[250px]">
              <Group className="text-[#7C8D7E]" />
              <p className="text-xs text-[#7C8D7E] font-medium mt-1 max-w-[150px] text-center">
                No Guarantor has been added yet
              </p>
            </div>
          </div>
        </div>
        {/* Details layout */}
        {/* <div className="grid grid-cols-3 gap-4 mt-4">
          <div className="bg-white rounded-[8px] py-3 px-4 h-fit">
            <p className="text-[#344335] text-sm font-semibold">Farm Details</p>

            <div className="mt-3">
              <h6 className="text-[#7C8D7E] text-sm">State</h6>
              <p className="text-[#344335] text-sm font-semibold">Oyo</p>
            </div>

            <div className="mt-3">
              <h6 className="text-[#7C8D7E] text-sm">Local Government</h6>
              <p className="text-[#344335] text-sm font-semibold">
                Ibadan North
              </p>
            </div>

            <div className="mt-3">
              <h6 className="text-[#7C8D7E] text-sm">Size</h6>
              <p className="text-[#344335] text-sm font-semibold">12,490 Ha</p>
            </div>

            <div className="mt-3">
              <h6 className="text-[#7C8D7E] text-sm">Location Coordinate</h6>
              <p className="text-[#344335] text-sm font-semibold">12,490 Ha</p>
            </div>
          </div>
          <div className="col-span-2 bg-white rounded-[8px]">
            <div className="">
              <img src={mapFrame} alt="map frame" />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="bg-white rounded-[8px] py-3 px-4">
            <p className="text-[#344335] text-sm font-semibold">Documents</p>

            <div className="flex justify-between items-center mt-5">
              <div className="flex gap-3 items-center">
                <div className="w-[48px] h-[48px] rounded-full">
                  <img src={passport_icon} alt="passport icon" />
                </div>
                <div>
                  <p className="text-[#344335] text-sm font-semibold">
                    Passport Paragraph
                  </p>
                  <p className="text-[#5C715E] text-xs font-medium mt-1">
                    12:32 AM | 12 Aug 22
                  </p>
                </div>
              </div>
              <div>
                <p className="text-[#5C715E] text-xs font-medium">20 KB</p>
              </div>
            </div>

            <div className="flex justify-between items-center mt-5">
              <div className="flex gap-3 items-center">
                <div className="w-[48px] h-[48px] rounded-full">
                  <img src={government_icon} alt="government icon" />
                </div>
                <div>
                  <p className="text-[#344335] text-sm font-semibold">
                    Government Issued ID
                  </p>
                  <p className="text-[#5C715E] text-xs font-medium mt-1">
                    12:32 AM | 12 Aug 22
                  </p>
                </div>
              </div>
              <div>
                <p className="text-[#5C715E] text-xs font-medium">40 KB</p>
              </div>
            </div>

            <div className="flex justify-between items-center mt-5">
              <div className="flex gap-3 items-center">
                <div className="w-[48px] h-[48px] rounded-full">
                  <img src={utility_icon} alt="utility icon" />
                </div>
                <div>
                  <p className="text-[#344335] text-sm font-semibold">
                    Utility Bill
                  </p>
                  <p className="text-[#5C715E] text-xs font-medium mt-1">
                    12:32 AM | 12 Aug 22
                  </p>
                </div>
              </div>
              <div>
                <p className="text-[#5C715E] text-xs font-medium">60 KB</p>
              </div>
            </div>

            <div className="flex justify-between items-center mt-5">
              <div className="flex gap-3 items-center">
                <div className="w-[48px] h-[48px] rounded-full">
                  <img src={signature_icon} alt="signature icon" />
                </div>
                <div>
                  <p className="text-[#344335] text-sm font-semibold">
                    Signature
                  </p>
                  <p className="text-[#5C715E] text-xs font-medium mt-1">
                    12:32 AM | 12 Aug 22
                  </p>
                </div>
              </div>
              <div>
                <p className="text-[#5C715E] text-xs font-medium">60 KB</p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-[8px] py-3 px-4 h-fit">
            <p className="text-[#344335] text-sm font-semibold">Guarantor(s)</p>

            <div className="bg-[#F7F7F7] rounded-lg py-3 px-3 mt-4">
              <div className="flex justify-between items-center">
                <div className="flex gap-3 items-center">
                  <div className="w-[40px] h-[40px] rounded-full bg-[#2B8C34] text-white text-sm font-medium flex justify-center items-center">
                    AJ
                  </div>
                  <div>
                    <p className="text-[#344335] text-sm font-semibold">
                      Abubakar Jesam
                    </p>
                    <div
                      className="flex gap-2 items-center cursor-pointer"
                      onClick={() => handleCopyNumber("07060825698")}
                    >
                      <p className="text-[#2B8C34] text-xs font-medium">
                        +2347060825698
                      </p>
                      <ContentCopy
                        className="text-primary"
                        style={{ fontSize: "13px" }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRight
                    onClick={() => handleGuarantorInfo("1")}
                    className="text-[#5C715E] cursor-pointer"
                  />
                </div>
              </div>

              <div className="flex justify-between items-center mt-4">
                <div className="flex gap-3 items-center">
                  <div className="w-[40px] h-[40px] rounded-full bg-[#2B8C34] text-white text-sm font-medium flex justify-center items-center">
                    AJ
                  </div>
                  <div>
                    <p className="text-[#344335] text-sm font-semibold">
                      Abubakar Jesam
                    </p>
                    <div
                      className="flex gap-2 items-center cursor-pointer"
                      onClick={() => handleCopyNumber("07060825698")}
                    >
                      <p className="text-[#2B8C34] text-xs font-medium">
                        +2347060825698
                      </p>
                      <ContentCopy
                        className="text-primary"
                        style={{ fontSize: "13px" }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRight
                    onClick={() => handleGuarantorInfo("2")}
                    className="text-[#5C715E] cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {/* GUARANTOR SIDE DRAWER */}
      <DrawerComponent
        title="Guarantor Info"
        show={isDrawerOpen}
        showDrawer={closeGuarantorDrawer}
      >
        <div className="mt-8">
          <div className="flex justify-between items-center">
            <div>
              <h5 className="text-xs text-[#5C715E]">Name</h5>
              <p className="font-medium text-[#5C715E] text-sm mt-1">
                Celina Avong
              </p>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Phone Number</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            +2348168791258
          </p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Sex</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">Male</p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Address</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            Almond Court, Ikolaba
          </p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Nearest Landmark</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            Jerado Hotel
          </p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">Local Government</h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">
            Ibadan North
          </p>
        </div>

        <div className="mt-6">
          <h5 className="text-xs text-[#5C715E]">State </h5>
          <p className="font-medium text-[#5C715E] text-sm mt-1">Oyo</p>
        </div>

        <div className="mt-8">
          <h5 className="text-[15px] font-semibold text-[#344335]">
            Documents
          </h5>

          <div className="mt-4 grid grid-cols-2 gap-4">
            <div className="flex gap-3">
              <div className="w-[32px] h-[32px] rounded-full">
                <img
                  src={passport_icon}
                  className="w-[32px] h-[32px]"
                  alt="passport icon"
                />
              </div>
              <div>
                <p className="text-sm text-[#344335] font-semibold">
                  Passport Photograph
                </p>
                <div className="mt-2">
                  <img src={passport_img} alt="passport" />
                </div>
                <div className="flex justify-between mt-2">
                  <p className="text-xs font-medium text-[#5C715E]">
                    12:32 AM | 12 Aug 22
                  </p>
                  <p className="text-xs font-medium text-[#5C715E]">20 KB</p>
                </div>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-[32px] h-[32px] rounded-full">
                <img
                  src={guarantor_icon}
                  className="w-[32px] h-[32px]"
                  alt="passport icon"
                />
              </div>
              <div>
                <p className="text-sm text-[#344335] font-semibold">
                  Guarantor Form
                </p>
                <div className="mt-2">
                  <img src={guarantor_img} alt="guarantor" />
                </div>
                <div className="flex justify-between mt-2">
                  <p className="text-xs font-medium text-[#5C715E]">
                    12:32 AM | 12 Aug 22
                  </p>
                  <p className="text-xs font-medium text-[#5C715E]">20 KB</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DrawerComponent>
      {/* End of drawer */}
      {/* RANK MODAL */}
      <ModalComponent
        show={show}
        showModal={showModal}
        title="Ranks"
        subtitle=""
      >
        <div className="my-[24px]">
          {/* Platinum */}
          <div className="mb-5">
            <div className="bg-[#4B4E68] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className="text-white leading-5 text-sm font-medium">
                Platinum
              </p>
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
            </div>
            <p className="text-xs font-medium leading-4 text-[#7C8D7E] pt-2">
              Average{" "}
              <span className="text-[#344335]">
                monthly transaction above ₦20,000
              </span>{" "}
              adding up to a{" "}
              <span className="text-[#344335]">
                minimum of ₦600,000 within a 12 month cycle
              </span>
            </p>
          </div>

          {/* Gold */}
          <div className="mb-5">
            <div className="bg-[#F9B43B] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className="text-white leading-5 text-sm font-medium">Gold</p>
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
            </div>
            <p className="text-xs font-medium leading-4 text-[#7C8D7E] pt-2">
              Average{" "}
              <span className="text-[#344335]">
                monthly transaction of ₦10,001 to ₦20,000
              </span>{" "}
              adding up to a{" "}
              <span className="text-[#344335]">
                minimum of ₦240,000 within a 12 month cycle
              </span>
            </p>
          </div>

          {/* Silver */}
          <div className="mb-5">
            <div className="bg-[#4B7295] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className="text-white leading-5 text-sm font-medium">Silver</p>
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
            </div>
            <p className="text-xs font-medium leading-4 text-[#7C8D7E] pt-2">
              Average{" "}
              <span className="text-[#344335]">
                monthly transaction of ₦5,001 to ₦10,000
              </span>{" "}
              adding up to a{" "}
              <span className="text-[#344335]">
                minimum of ₦120,000 within a 12 month cycle
              </span>
            </p>
          </div>

          {/* Bronxe */}
          <div className="mb-5">
            <div className="bg-[#E86454] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className="text-white leading-5 text-sm font-medium">Bronze</p>
              <Star
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              />
            </div>
            <p className="text-xs font-medium leading-4 text-[#7C8D7E] pt-2">
              Average{" "}
              <span className="text-[#344335]">
                monthly transaction of 0.00 to ₦5,000
              </span>{" "}
              adding up to a{" "}
              <span className="text-[#344335]">
                minimum of ₦60,000 within a 12 month cycle.
              </span>
            </p>
          </div>
        </div>
      </ModalComponent>

      {/* ACCT NO */}
      <ModalComponent
        show={toggleAcctNum}
        showModal={() => setIsToggleAcctNum(false)}
        title="Generate Account Number"
        subtitle=""
      >
        <div className="pt-[16px]">
          <p className="text-sm font-medium text-[#5C715E] leading-5 mb-[32px]">
            Are you sure you want to generate account number?
          </p>
          <div className=" flex justify-end gap-[40px] items-center mb-[12px] cursor-pointer">
            <p
              className="text-sm text-medium leading-5 text-[#5C715E]"
              onClick={() => setIsToggleAcctNum(false)}
            >
              No
            </p>
            <button
              disabled={loading}
              className="outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white disabled:bg-[#ABB6AC] disabled:cursor-not-allowed"
              onClick={handleGenerateAcctNum}
            >
              Yes
            </button>
          </div>
        </div>
      </ModalComponent>

      {/* ACCT NO GENERATED */}
      <ModalComponent
        show={toggleAcctNumGenerated}
        showModal={() => setIsToggleAcctNumGenerated(false)}
        title=""
        subtitle=""
      >
        <div className="pt-[16px] flex justify-center items-center flex-col gap-2">
          <AccountBalance
            className="text-[#2B8C34]"
            style={{ fontSize: "24px" }}
          />
          <p className="text-base font-semibold text-[#344335] leading-5">
            Account number has been generated
          </p>
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => handleCopyAccountNumber(acctNo)}
          >
            <p className="text-xl font-semibold text-[#2B8C34] leading-[32px]">
              {acctNo}
            </p>
            <ContentCopy
              className="text-[#2B8C34]"
              style={{ fontSize: "17px" }}
            />
          </div>

          <p
            className="my-5 cursor-pointer text-sm font-medium text-[#5C715E] leading-5"
            onClick={() => setIsToggleAcctNumGenerated(false)}
          >
            Close
          </p>
        </div>
      </ModalComponent>
    </div>
  );
};

export default FarmerAccountInformation;
