import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from 'cogo-toast'

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
}


export const getCallRequests = createAsyncThunk(
  'getCallRequests',
  async ({ page, status }, { rejectWithValue, dispatch }) => {
    try {
      if (status === 'all') {
        const res = await axios.get(`${apiUrl}call_requests?page=${page}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }
      else {
        const res = await axios.get(`${apiUrl}call_requests?page=${page}&status=${status}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }

    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const searchCallRequests = createAsyncThunk(
  'searchCallRequests',
  async ({ status, val }, { rejectWithValue, dispatch }) => {
    try {
      if (status === 'all') {
        const res = await axios.get(`${apiUrl}call_requests?search=${val}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }
      else {
        const res = await axios.get(`${apiUrl}call_requests?search=${val}&status=${status}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            },
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }

    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const getCallRequestStatThisMonth = createAsyncThunk(
  'getCallRequestStatThisMonth',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}call_requests/stats`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        return res.data
      }

    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const updateCallRequest = createAsyncThunk(
  'updateCallRequest',
  async ({ status, summary, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(`${apiUrl}call_requests/${id}`, {
        status: status,
        summary: summary
      },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        cogoToast.success("Call request successfully updated!")
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error('An error occurred!')
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

