import React, { useEffect, useState } from "react";
// import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRefunds } from "../../redux/Orders/ordersAction";
import moment from "moment";

const RefundsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refundsLoading, refundsData, refundsTotal } = useSelector(
    (state) => state.orders
  );
  const [typeID, setTypeID] = useState(1);
  const [typeTitle, setTypeTitle] = useState("All");
  const orderTypes = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Approved",
    },
    {
      id: 3,
      title: "Pending",
    },
    {
      id: 4,
      title: "Rejected",
    },
  ];

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const toggleType = (id, title) => {
    setTypeID(id);
    setTypeTitle(title);
    dispatch(getRefunds({ page: 1, orderType: title }));
  };

  //   const [currentPage, setCurrentPage] = useState(1);

  //   const onPageChange = (val) => {
  //     const { page } = val;
  //     setCurrentPage(page);
  //   };

  const viewOrder = (id) => {
    navigate(`/orders/refund/${id}`);
  };

  useEffect(() => {
    dispatch(getRefunds({ page: 1, orderType: "All" }));
  }, [dispatch]);

  console.log(typeTitle);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="w-full">
            <SearchComponent
              placeholder="Search"
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {orderTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id, item.title)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : "bg-[#ECEEEC] rounded-[4px] py-1 px-1 text-[#96A397] font-semibold text-[10px]"
                  }
                >
                  {item.id === typeID ? refundsTotal : "0"}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          {refundsLoading ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <table className="w-full text-left">
              <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                <tr>
                  <th scope="col" className="px-4 py-4 font-semibold ">
                    Order ID
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold ">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold ">
                    Phone number
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Date/Time
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Order Type
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Refund Amount
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Refund Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {refundsData?.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => viewOrder(data.id)}
                      className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                      >
                        {data?.order_id || "N/A"}
                      </th>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {data?.name || "N/A"}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {" "}
                        {data?.phone_number || "N/A"}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {moment(data.created).format("lll")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {data?.order_type}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        N{data?.refund_amount || "N/A"}
                      </td>
                      <td className="py-4 px-4">
                        <div className="flex">
                          <div
                            className={
                              data?.approval_status === "PENDING"
                                ? "pending-badge"
                                : data?.approval_status === "DENIED"
                                ? "error-badge"
                                : data?.approval_status === "APPROVED"
                                ? "success-badge"
                                : "n/a-badge"
                            }
                          >
                            <p>
                              {" "}
                              {data.approval_status === "APPROVED"
                                ? "Approved"
                                : data.approval_status === "DENIED"
                                ? "Rejected"
                                : data.approval_status === "PENDING"
                                ? "Pending"
                                : null}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {/* <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={200}
        />
      </div> */}
    </>
  );
};

export default RefundsTable;
