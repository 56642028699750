import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";

// const useCustomNavigate = (route) => {
//   const navigate = useNavigate();

//   const handleNavigate = () => {
//     navigate(route);
//   };

//   return handleNavigate;
// };

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

//////////////////////////////////////////// CAMPAIGN STATS
export const getCampaignStats = createAsyncThunk(
  "getCampaignStats",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}sales-campaigns/stats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////////////////////////// ALL CAMPAIGN SECTION
export const getCampaigns = createAsyncThunk(
  "getCampaigns",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}sales-campaigns`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////////////////////////// ALL CAMPAIGN SECTION
export const getCampaignLog = createAsyncThunk(
  "getCampaignLog",
  async ({ type }, { rejectWithValue, dispatch }) => {
    if (type === "ALL") {
      try {
        const res = await axios.get(`${apiUrl}sales-campaigns`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(`${apiUrl}/sales-campaigns?type=${type}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

//////////////////////////////////////////// ALL CAMPAIGN AGENTS SECTION
export const getCampaignAgents = createAsyncThunk(
  "getCampaignAgents",
  async ({ languages }, { rejectWithValue, dispatch }) => {
    if (languages) {
      try {
        const res = await axios.get(
          `${apiUrl}sales-agents?languages=${languages}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else {
      try {
        const res = await axios.get(`${apiUrl}sales-agents`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: 'auth/Logout' })
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const getFarmersCount = createAsyncThunk(
  "getFarmersCount",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}sales-campaigns/farmers/count`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const createCampaign = createAsyncThunk(
  "createCampaign",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}sales-campaigns`, value, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        cogoToast.success("Campaign created successfully");
        // useCustomNavigate("/dashboard/campaign");

        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error(error.response.data.error);
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////////////////////////// CAMPAIGN SUMMARY SECTION
export const getCampaignSummary = createAsyncThunk(
  "getCampaignSummary",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}sales-campaigns/${id}/details`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////////////////////////// CAMPAIGN SUMMARY STATS SECTION
export const getCampaignSummaryStats = createAsyncThunk(
  "getCampaignSummaryStats",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}sales-campaigns/${id}/stats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////////////////////////// CAMPAIGN SUMMARY
export const updateCampaignSummary = createAsyncThunk(
  "updateCampaignSummary",
  async ({ id, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}sales-campaigns/${id}/details`,
        { status: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Updated Successfully");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////////////////////////// CAMPAIGN SUMMARY STATS SECTION
export const getAvailableFarmersCount = createAsyncThunk(
  "getAvailableFarmersCount",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}sales-campaigns/${id}/available-farmers/count`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////////////////////////// CAMPAIGN SUMMARY
export const updateCampaignAgents = createAsyncThunk(
  "updateCampaignAgents",
  async ({ id, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}sales-campaigns/${id}/agents`,
        { agents: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Agent Added Successfully");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
