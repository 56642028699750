import { createSlice } from "@reduxjs/toolkit";
import {
  getCalls,
  getOrders,
  getRetail,
  getRetailSearch,
  getRetailStats,
  getSalesAgentCalls,
  getSalesAgentCampaign,
  getSalesAgentComplaints,
  getSalesAgentOrders,
  getSalesAgentStats,
} from "./retailAction";
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  retailData: [],
  retailTotal: 0,
  retailLoading: false,

  // RETAIL STATS STATE
  retailStatsData: {},
  retailStatsLoading: false,

  // CALLS STATE
  callsData: [],
  callsTotal: 0,
  callsLoading: false,

  // SALES AGENT CALLS STATE
  salesAgentCallsData: [],
  salesAgentCallsTotal: 0,
  salesAgentCallsLoading: false,

  // SALES AGENT STATS
  salesAgentStatsData: {},
  salesAgentStatsLoading: false,

  // SALES AGENT CAMPAIGN
  salesAgentCampaignData: [],
  salesAgentCampaignLoading: false,

  // SALES AGENT COMPLAINTS
  salesAgentComplaintData: [],
  salesAgentComplaintLoading: false,
  salesAgentComplaintTotal: 0,

  // GET ALL ORDERS
  ordersData: [],
  ordersTotal: 0,
  ordersLoading: false,

  // SALES AGENT ORDERS
  salesAgentOrdersData: [],
  salesAgentOrdersLoading: false,
  salesAgentOrdersTotal: 0,
};

const retailPersistConfig = {
  key: 'retail',
  storage,
}

export const retailSlice = createSlice({
  name: "retail",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
  extraReducers: {
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////// GET RETAIL  ----------
    [getRetail.pending]: (state) => {
      state.retailLoading = true;
    },
    [getRetail.fulfilled]: (state, { payload }) => {
      state.retailLoading = false;
      state.retailData = payload.data;
      state.retailTotal = payload.count;
    },
    [getRetail.rejected]: (state) => {
      state.retailLoading = false;
    },
    ////////////////////////////////////// GET RETAIL SEARCH ----------
    [getRetailSearch.pending]: (state) => {
      state.retailLoading = true;
    },
    [getRetailSearch.fulfilled]: (state, { payload }) => {
      state.retailLoading = false;
      state.retailData = payload.data;
      state.retailTotal = payload.count;
    },
    [getRetailSearch.rejected]: (state) => {
      state.retailLoading = false;
    },

    ////////////////////////////////////// GET RETAIL STATS  ----------
    [getRetailStats.pending]: (state) => {
      state.retailStatsLoading = true;
    },
    [getRetailStats.fulfilled]: (state, { payload }) => {
      state.retailStatsLoading = false;
      state.retailStatsData = payload.data;
    },
    [getRetailStats.rejected]: (state) => {
      state.retailStatsLoading = false;
    },

    ////////////////////////////////////// GET CALLS  ----------
    [getCalls.pending]: (state) => {
      state.callsLoading = true;
    },
    [getCalls.fulfilled]: (state, { payload }) => {
      state.callsLoading = false;
      state.callsData = payload.data;
      state.callsTotal = payload.count;
    },
    [getCalls.rejected]: (state) => {
      state.callsLoading = false;
    },

    ////////////////////////////////////// GET SALES AGENT STATS  ----------
    [getSalesAgentStats.pending]: (state) => {
      state.salesAgentStatsLoading = true;
    },
    [getSalesAgentStats.fulfilled]: (state, { payload }) => {
      state.salesAgentStatsLoading = false;
      state.salesAgentStatsData = payload;
    },
    [getSalesAgentStats.rejected]: (state) => {
      state.salesAgentStatsLoading = false;
    },

    ////////////////////////////////////// GET SALES AGENT CAMPAIGN  ----------
    [getSalesAgentCampaign.pending]: (state) => {
      state.salesAgentCampaignLoading = true;
    },
    [getSalesAgentCampaign.fulfilled]: (state, { payload }) => {
      state.salesAgentCampaignLoading = false;
      state.salesAgentCampaignData = payload.data;
    },
    [getSalesAgentCampaign.rejected]: (state) => {
      state.salesAgentCampaignLoading = false;
    },

    ////////////////////////////////////// GET SALES AGENT CALLS  ----------
    [getSalesAgentCalls.pending]: (state) => {
      state.salesAgentCallsLoading = true;
    },
    [getSalesAgentCalls.fulfilled]: (state, { payload }) => {
      state.salesAgentCallsLoading = false;
      state.salesAgentCallsData = payload.data;
      state.salesAgentCallsTotal = payload.count;
    },
    [getSalesAgentCalls.rejected]: (state) => {
      state.salesAgentCallsLoading = false;
    },

    ////////////////////////////////////// GET SALES AGENT COMPLAINTS  ----------
    [getSalesAgentComplaints.pending]: (state) => {
      state.salesAgentComplaintLoading = true;
    },
    [getSalesAgentComplaints.fulfilled]: (state, { payload }) => {
      state.salesAgentComplaintLoading = false;
      state.salesAgentComplaintData = payload.data;
      state.salesAgentComplaintTotal = payload.count;
    },
    [getSalesAgentComplaints.rejected]: (state) => {
      state.salesAgentComplaintLoading = false;
    },

    [getOrders.pending]: (state) => {
      state.ordersLoading = true;
    },
    [getOrders.fulfilled]: (state, { payload }) => {
      state.ordersLoading = false;
      state.ordersData = payload.data;
      state.ordersTotal = payload.count;
    },
    [getOrders.rejected]: (state) => {
      state.ordersLoading = false;
    },

    [getSalesAgentOrders.pending]: (state) => {
      state.salesAgentOrdersLoading = true;
    },
    [getSalesAgentOrders.fulfilled]: (state, { payload }) => {
      state.salesAgentOrdersLoading = false;
      state.salesAgentOrdersData = payload.data;
      state.salesAgentOrdersTotal = payload.count;
    },
    [getSalesAgentOrders.rejected]: (state) => {
      state.salesAgentOrdersLoading = false;
    },
  },
});

const retailReducer = persistReducer(retailPersistConfig, retailSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, decrement } = retailSlice.actions;

export default retailReducer;
