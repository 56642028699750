import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../components/Header";
import { getComplaints } from "../../redux/Complaints/complaintsAction";
import { ButtonPrimary } from "../../components/Button/index";
import FlaggedCooperativeTable from "../../tables/Cooperative/FlaggedCooperativeTable";

const FlaggedCooperative = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getComplaints({ page: 1, value: null }));
  }, [dispatch]);

  return (
    <div>
      <Header text="Flagged Cooperative">
        <ButtonPrimary>Create Cooperative</ButtonPrimary>
      </Header>

      {/* TABLE SECTION */}
      <section className="mt-8">
        <FlaggedCooperativeTable />
      </section>
    </div>
  );
};

export default FlaggedCooperative;
